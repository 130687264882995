import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadCourseById } from '../../../actions/courses';
import { appLoadToken } from '../../../actions/app';
import { withRouter } from 'react-router-dom';
import Course from '../../../components/courses/Course';
import { IconButton } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { ADMIN_COURSES_BLOCK, ADMIN_EDIT } from '../../../config/routes';
import { router } from '../../../config/routes';
import { goTo } from '../../../actions/navigator';

class AdminCoursesViewContainer extends Component {
  componentDidMount() {
    const { courseId, loadCourseById, appLoadToken } = this.props;
    appLoadToken().then(() => {
      loadCourseById(courseId, true);
    });
  }

  handleBack = () => {
    const { courseId } = this.props;

    this.props.goTo(
      router.getRoute(ADMIN_COURSES_BLOCK + ADMIN_EDIT, { id: courseId })
    );
  };

  render() {
    const { course, loading } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <IconButton
          onClick={() => this.handleBack()}
          style={{
            position: 'absolute',
            left: 16,
            height: 56,
            width: 56,
            top: 16,
            zIndex: 1000,
          }}
        >
          <BackIcon style={{ height: 32, width: 32, color: 'white' }} />
        </IconButton>
        <Course course={course} loading={loading} preview={true} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.courseReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadCourseById, appLoadToken, goTo }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminCoursesViewContainer)
);
