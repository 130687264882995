// /* eslint-disable react/prop-types */
// import React, { useEffect, useState } from 'react';
// import { withRouter } from 'react-router-dom';
// import { appLoadToken } from '../actions/app';
// import Layout from '../components/Layout';
// import AdminLayout from './admin/AdminLayout';
// import {isAdminLocation, isAdmin, isReporting} from '../utils/AdminHelper';
// import { getCurrent } from '../actions/user';
// import Loading from '../components/ui/Loading';
// import NewVersionDialog from './ui/NewVersionDialog';
// import { addSnackbar } from '../actions/snackbar';
// import { useDispatch, useSelector } from 'react-redux'

// const AppContainer = ({ location }) => {
//   const dispatch = useDispatch()
//   const [initialized, setInitialized] = useState(false)

//   const user = useSelector(state => state.userReducer)

//   useEffect(() => {
//     console.log('EFFECT/RENDER APP')
//     dispatch(appLoadToken()).then(res => {
//       setInitialized(true)
//       if (res) {
//         dispatch(getCurrent())
//       }
//     })
//   },[])

//   return (
//     <>
//       {(isAdminLocation(location) && !user.user) ? <Loading dark={false} key={'loading-admin-index'} /> :
//         (isAdminLocation(location) && (isAdmin(user.user) || isReporting(user.user))) ? (
//           initialized ? <AdminLayout me={user.user} /> :  <></>
//         ) : <Layout me={user.user} location={location} />
//       }
//       <NewVersionDialog />
//     </>
//   )
// }

// export default withRouter((AppContainer))

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appLoadToken } from '../actions/app';
import Layout from '../components/Layout';
import AdminLayout from './admin/AdminLayout';
import {isAdminLocation, isAdmin, isReporting} from '../utils/AdminHelper';
import { getCurrent } from '../actions/user';
import Loading from '../components/ui/Loading';
import NewVersionDialog from './ui/NewVersionDialog';
import { addSnackbar } from '../actions/snackbar';

class AppContainer extends Component {

  state = {
    initialized: false,
  };

  
  componentDidMount() {
    this.props.appLoadToken().then((res) => {
      this.setState({ initialized: true });
      if (res) {
        this.props.getCurrent();
      }
    });
  }

  resolveRender() {
    const { location, user } = this.props;

    const recaptchaElement = window.top.document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElement.length) {
      recaptchaElement[0].style.visibility = user ? 'hidden' : 'visible';
    }

    if (isAdminLocation(location) && !user)
      return <Loading dark={false} key={'loading-admin-index'} />;
    if (isAdminLocation(location) && (isAdmin(user) || isReporting(user) )) {
      if (this.state.initialized) {
        return <AdminLayout me={user} />
      }
      return null;
    }
    if (this.state.initialized) {
      return <Layout me={user} location={location} />
    }
    return null;
  }

  render() {
    return <>
        {this.resolveRender()}
        <NewVersionDialog />
      </>
  }

}

const mapStateToProps = (state) => ({
  ...state.userReducer
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ appLoadToken, getCurrent, addSnackbar }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppContainer),
);

