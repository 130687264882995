import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

class NewVersionDialog extends Component {

    updateServiceWorker = () => {
        const {registration} =  this.props;
        const registrationWaiting = registration && registration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    };

    render() {
        const {updated} =  this.props;
        return (
            <Dialog open={updated} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">NUEVA VERSIÓN</DialogTitle>
                <DialogContent>
                      <span>
                      {"Hay una nueva versión disponible de Academia. Hacé clic "}
                      </span>
                    <span style={{fontWeight: 'bold', cursor: 'pointer'}} onClick={() => this.updateServiceWorker() }> {"acá"}</span>
                    <span> {"para recargar la página y ver los últimos cambios!"}</span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.updateServiceWorker() } color="primary">Recargar</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {...state.serviceWorkerReducer};
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {  },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionDialog);
