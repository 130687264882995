import React, { Component } from 'react'
import Content from './ContentHome'
import { withStyles } from '@material-ui/core/styles'
import Header from './HeaderHome'
import Login from '../session/Login'
import { COURSES_EXPLORE } from '../../config/routes'

const styles = theme => {
  return {
    root: {
      backgroundColor: '#101010',
      width: '100%',
      maxWidth: 'calc(100vw - 0%)',
    },
    loginRoot: {
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  }
}

class Home extends Component {
  render() {
    const {
      classes,
      onShowCourse,
      onRemoveSharedCourse,
      me,
      onGoTo,
      courses,
      externalCourses,
      slides,
    } = this.props

    return me ? (
      <div className={classes.root}>
        {slides.loading ? null : <Header onGoTo={onGoTo} slides={slides} />}
        <Content
          onShowCourse={onShowCourse}
          onRemoveSharedCourse={onRemoveSharedCourse}
          onGoToExplore={() => onGoTo(COURSES_EXPLORE)}
          courses={courses}
          externalCourses={externalCourses}
        />
      </div>
    ) : (
      <div className={classes.loginRoot}>
        <Login />
      </div>
    )
  }
}
export default withStyles(styles)(Home)
