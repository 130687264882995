import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  category: {
    fontSize: 8,
    float: 'left',
    marginRight: 4,
    marginBottom: 4,
    borderRadius: 2,
    color: '#363636',
    fontWeight: 600,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
  },
  link: {
    cursor: 'pointer',
  },
})

class CategoryChip extends Component {
  render() {
    const { classes, label, color = '#ffcc38', className = false, onClick = null } = this.props
    return (
      <Typography
        component={'p'}
        role={'link'}
        onClick={onClick}
        className={
          (className ? className + ' ' + classes.category : classes.category) +
          ' ' +
          (onClick != null ? classes.link : '')
        }
        style={{ background: color }}
      >
        {label}
      </Typography>
    )
  }
}

export default withStyles(styles)(CategoryChip)
