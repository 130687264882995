import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { goToExploreMoreCareerPlans } from '../../actions/navigator'
import { COURSES_VIEW, router } from '../../config/routes'
import { goTo } from '../../actions/navigator'
import { loadPlanById } from '../../actions/careerPlans'
import CareerPlansDetail from '../../components/career/CareerPlansDetail'
import { deleteSharedCourse } from '../../actions/courses'
import { appLoadToken } from '../../actions/app'
import { addSnackbar } from '../../actions/snackbar'
import { SnackbarType } from '../../config/constants'
import DocumentTitle from '../../components/ui/DocumentTitle'

class CareerPlansDetailContainer extends Component {
  componentWillMount() {
    const { appLoadToken, planId, loadPlanById } = this.props
    appLoadToken()
    loadPlanById(planId, [])
  }

  showCourse = course => {
    const { goTo } = this.props
    goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
  }

  loadCarrerPlan = query => {
    const { loadPlanById, planId } = this.props
    if (query) {
      loadPlanById(planId, query)
    } else {
      loadPlanById(planId, [])
    }
  }

  removeSharedCourse = async courseId => {
    try {
      const { deleteSharedCourse, addSnackbar } = this.props
      await deleteSharedCourse(courseId)
      addSnackbar(`Recomendación descartada`, SnackbarType.Success)
      this.loadCarrerPlan()
    } catch (err) {}
  }

  render() {
    const { detailCareerPlans } = this.props
    return (
      <React.Fragment>
        <DocumentTitle title={'Ruta de aprendizaje | Academia'} />
        <CareerPlansDetail
          {...detailCareerPlans}
          loadCarrerPlan={this.loadCarrerPlan}
          onShowCourse={this.showCourse}
          onDeleteSharedCourse={this.removeSharedCourse}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ careerPlansCoursesReducers }) => {
  return {
    ...careerPlansCoursesReducers,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      goTo,
      loadPlanById,
      appLoadToken,
      goToExploreMoreCareerPlans,
      addSnackbar,
      deleteSharedCourse,
    },
    dispatch,
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CareerPlansDetailContainer))
