import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/navigator'
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource,
} from '../../../actions/admin'
import { ADMIN_EDIT, ADMIN_NEW, router, ADMIN_REFERENTS_PROVIDERS } from '../../../config/routes'
import AdminResourceIndex from '../AdminResourceIndex'
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType,
  ReferentProviderTypes,
} from '../../../config/constants'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ConfirmDialog from '../../../components/ui/ConfirmDialog'
import { addSnackbar } from '../../../actions/snackbar'

class AdminReferentsProvidersContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
  }

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props
    getResources(type, keyName, query, page, size, undefined)
  }

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      {
        title: 'Tipo',
        source: 'external_reference_type',
        type: AdminItemViewType.Conditional,
        conditions: ReferentProviderTypes.map(ft => ({
          condition: `${ft.id}`,
          result: ft.description,
        })),
      },
      {
        title: 'Nombre',
        source: 'name',
      },
      {
        title: 'Habilitado',
        source: 'enabled',
        type: AdminItemViewType.Boolean,
      },
    ]
  }

  onCreate = () => {
    this.props.goTo(ADMIN_REFERENTS_PROVIDERS + ADMIN_NEW)
  }

  onEditItem = item => {
    this.props.goTo(router.getRoute(ADMIN_REFERENTS_PROVIDERS + ADMIN_EDIT, { id: item.id }))
  }

  onDeleteItem = item => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.name,
    })
  }

  getActions = () => {
    return [
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
    ]
  }

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Select,
        name: 'externalReference.externalReferenceType',
        placeholder: 'Tipo',
        format: FiltersFormat.Plain,
        options: ReferentProviderTypes,
      },
      {
        type: EditInputType.Text,
        placeholder: 'Nombre',
        format: FiltersFormat.Like,
        name: 'name',
      },
    ]
  }

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props
    const item = this.state.deleteItem
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Referente/proveedor eliminado exitosamente.', SnackbarType.Success)
        refreshResources(this.getKeyName())
      })
      .catch(err => {
        addSnackbar(err.message, SnackbarType.Error)
      })
  }

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
  }

  getKeyName = () => {
    return 'admin-external-references'
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={'REFERENTES / PROVEEDORES'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        onCreateTopCourses={this.onCreateTopCourses}
      >
        <ConfirmDialog
          title={'Eliminar referencia'}
          open={this.state.confirmDeleteOpen}
          description={`¿Desea eliminar la referencia "${this.state.itemToDelete}"?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    )
  }
}

AdminReferentsProvidersContainer.defaultProps = {
  type: 'external_references',
}

const mapStateToProps = () => {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, refreshResources, deleteResource, goTo, addSnackbar, updateResource },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReferentsProvidersContainer)
