import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/navigator'
import {
  getResources,
  getClassesByCommisionId,
  deleteResource,
  refreshResources,
  updateResource,
  unpublishResource,
  publishResource,
} from '../../../actions/admin'
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  ADMIN_VIEW,
  router,
  ADMIN_EXTERNAL_COURSES,
  ADMIN_EXTERNAL_COURSES_COMMISSIONS,
} from '../../../config/routes'
import AdminResourceIndex from '../AdminResourceIndex'
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType,
  EditInputObjectModel,
  ExternalCourseStatusName,
  ExternalCourseStatus,
} from '../../../config/constants'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ConfirmDialog from '../../../components/ui/ConfirmDialog'
import CommissionsIcon from '@material-ui/icons/Event'
import { addSnackbar } from '../../../actions/snackbar'
import ClassesModal from '../../../components/admin/external_courses/ClassesModal/ClassesModal'
import UnpublishedIcon from '@material-ui/icons/GetApp'

class AdminExternalCoursesContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
    openClasses: false,
    commissionSelected: null,
    classItems: [],
    errorsDetail: '',
  }

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props
    getResources(type, keyName, query, page, size)
  }

  onFetchClasses = commisionId => {
    if (this.state.openClasses) {
      return getClassesByCommisionId(commisionId)
    }
  }

  onRenderCategories = item => {
    let categories = item.categories.map(c => c.name)
    return categories.join(', ')
  }

  onRenderNps = item => {
    return item.nps === null ? '-' : item.nps
  }

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Título', source: 'name', expanded: true },
      {
        title: 'Categoría/s',
        source: 'categories',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderCategories,
      },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(ExternalCourseStatusName).map(k => ({
          condition: k,
          result: ExternalCourseStatusName[k],
        })),
      },
      {
        title: 'Fecha de publicación',
        source: 'published_at',
        type: AdminItemViewType.Date,
      },
      /* {
        title: 'NPS',
        source: 'nps',
        type: AdminItemViewType.Number,
      }, */
      {
        title: 'NPS',
        source: 'nps',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderNps,
      },
      {
        title: 'Cantidad de comisiones',
        source: 'commission_count',
        type: AdminItemViewType.Number,
      },
    ]
  }

  onCreate = () => {
    this.props.goTo(ADMIN_EXTERNAL_COURSES + ADMIN_NEW)
  }

  onEditItem = item => {
    this.props.goTo(router.getRoute(ADMIN_EXTERNAL_COURSES + ADMIN_EDIT, { id: item.id }))
  }

  onViewItem = item => {
    this.props.goTo(
      router.getRoute(ADMIN_EXTERNAL_COURSES_COMMISSIONS, { externalCourseId: item.id }),
    )
  }

  onDeleteItem = item => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.name,
    })
  }

  onUnpublishItem = item => {
    const { unpublishResource, refreshResources, addSnackbar } = this.props
    unpublishResource('external_course', item.id)
      .then(() => {
        addSnackbar('Taller despublicado.', SnackbarType.Success)
        refreshResources(this.getKeyName())
      })
      .catch(err => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      })
  }

  onPublishItem = item => {
    const { publishResource, refreshResources, addSnackbar } = this.props
    publishResource('external_course', item.id)
      .then(() => {
        addSnackbar('Taller Publicado.', SnackbarType.Success)
        refreshResources(this.getKeyName())
      })
      .catch(err => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      })
  }

  onShowClasses = async item => {
    let result = await getClassesByCommisionId(item.id)
    this.setState({
      commissionSelected: item.id,
      classItems: result.data,
      openClasses: true,
    })
  }

  getActions = item => {
    const actions = [
      {
        title: 'EDITAR TALLER',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR TALLER',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
      {
        title: 'COMISIONES',
        action: this.onViewItem,
        icon: <CommissionsIcon />,
      },
    ]

    if (item.status !== ExternalCourseStatus.UnPublished) {
      actions.push({
        title: 'DESPUBLICAR',
        action: this.onUnpublishItem,
        icon: <UnpublishedIcon />,
      })
    }

    if (item.status === ExternalCourseStatus.UnPublished) {
      actions.push({
        title: 'PUBLICAR',
        action: this.onPublishItem,
        icon: <UnpublishedIcon style={{ transform: 'rotate(180deg)' }} />,
      })
    }

    return actions
  }

  onShowItem = item => {
    this.props.goTo(router.getRoute(ADMIN_EXTERNAL_COURSES + ADMIN_VIEW, { id: item.id }))
  }

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Number,
        name: 'externalCourse.id',
        placeholder: 'ID',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Text,
        name: 'externalCourse.name',
        placeholder: 'Título',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Categories,
        name: 'category.id',
        placeholder: 'Categoría',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Select,
        name: 'externalCourse.status',
        placeholder: 'Estado',
        format: FiltersFormat.Plain,
        options: Object.keys(ExternalCourseStatusName).map(k => ({
          id: k,
          description: ExternalCourseStatusName[k],
        })),
      },
    ]
  }

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props
    const item = this.state.deleteItem
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Taller eliminado.', SnackbarType.Success)
        refreshResources(this.getKeyName())
      })
      .catch(err => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      })
  }

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
  }

  getKeyName = () => {
    return 'admin-external-courses'
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={'TALLERES'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        onCreateTopCourses={this.onCreateTopCourses}
      >
        <ConfirmDialog
          title={'Eliminar Taller'}
          open={this.state.confirmDeleteOpen}
          description={`¿Estás seguro de eliminar el taller "${this.state.itemToDelete}"?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
        <ClassesModal
          open={this.state.openClasses}
          onClose={() => this.setState({ openClasses: false })}
          onFetch={this.state.classItems}
        />
      </AdminResourceIndex>
    )
  }
}

AdminExternalCoursesContainer.defaultProps = {
  type: 'external_courses',
}

const mapStateToProps = () => {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResources,
      getClassesByCommisionId,
      refreshResources,
      deleteResource,
      goTo,
      addSnackbar,
      updateResource,
      unpublishResource,
      publishResource,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminExternalCoursesContainer)
