import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ResourceFormInput from './ResourceFormInput';
import { Toolbar, Typography, Paper } from '@material-ui/core';
import ColorButton from './ColorButton';
import SaveIcon from '@material-ui/icons/Save';
import LoadingIcon from '../../assets/images/loader.gif';
import { getValue } from '../../utils/AdminHelper';

const styles = theme => ({
  root: {
    marginLeft: '20%',
    maxWidth: '60%',
    marginTop: theme.spacing(3),
  },
  form: {
    padding: 32,
    minWidth: 330,
    display: 'flex',
    flexWrap: 'wrap',
  },
  tableWrapper: {},
  toolbar: {
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '0 0 auto',
  },
  buttons: {
    marginTop: 16,
    textAlign: 'right',
    padding: 8,
  },
  saveButton: {
    backgroundColor: '#575454',
    borderRadius: 2.5,
    color: '#fff',
    textTransform: 'capitalize',
  },
  cancelButton: {
    marginLeft: '1em',
    border: '#575454 2px',
    borderRadius: 2.5,
    backgroundColor: '#fff',
    color: '#575454',
    textTransform: 'capitalize',
  },
});

class ResourceForm extends React.Component {
  render() {
    const {
      classes,
      attributes,
      form,
      keyName,
      onAttributeChange,
      onSubmit,
      onCancel,
      isValid,
      errors,
      customClasses,
      actionButtons,
      customActions,
      loadingButton,
      submitLabel = 'Guardar',
    } = this.props;

    return (
      <div className={customClasses ? customClasses.formRoot : classes.root}>
        <Paper className={customClasses && customClasses.formContainer} key={keyName}>
          {attributes.title && (
            <Toolbar className={classes.toolbar}>
              <div className={classes.title}>
                <Typography id="tableTitle">{attributes.title}</Typography>
              </div>
            </Toolbar>
          )}
          <div className={classes.tableWrapper}>
            <form
              className={customClasses ? customClasses.form : classes.form}
              noValidate
              autoComplete="off"
            >
              {attributes.keys.map((k, i) => {
                const editedValue = k.editConfig ? getValue(k.editConfig.name, form) : ''
                const error = k.editConfig ? getValue(k.editConfig.name, errors) : ''

                return (
                  <ResourceFormInput
                    key={'ResourceFormInput-' + i}
                    keyName={keyName + '-row-' + i}
                    template={k}
                    editedValue={editedValue}
                    error={error}
                    data={form}
                    onAttributeChange={onAttributeChange}
                    customClasses={customClasses}
                  />
                )
              })}
            </form>
          </div>
        </Paper>
        <div
          className={
            customClasses && customClasses.buttons ? customClasses.buttons : classes.buttons
          }
        >
          {!customActions &&
            (onSubmit && actionButtons === '2' ? (
              <React.Fragment>
                <ColorButton
                  disabled={!isValid}
                  label={submitLabel}
                  onClick={() => onSubmit()}
                  className={classes.saveButton}
                />
                <ColorButton
                  label={'Cancelar'}
                  onClick={() => onCancel()}
                  className={classes.cancelButton}
                />
              </React.Fragment>
            ) : (
              <ColorButton
                disabled={!isValid || loadingButton}
                label={submitLabel}
                onClick={() => onSubmit()}
                extraView={
                  loadingButton ? (
                    <img
                      src={LoadingIcon}
                      alt={'Loading Icon'}
                      style={{ height: 16, width: 16, transform: 'scale(2)' }}
                    />
                  ) : (
                    <SaveIcon style={{ height: 16, width: 16, marginTop: 2 }} />
                  )
                }
              />
            ))}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ResourceForm);
