import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { detectIE } from '../../utils/IEHelper'
const styles = theme => ({
  wrapper: {
    margin: theme.spacing(4),
  },
  ieFixOpen: {
    width: 'calc( 100% - 50px )',
  },
  ieFixClosed: {
    width: 'calc( 100% - 50px )',
  },
})

class AdminContentWrapper extends Component {
  render() {
    const { customClasses, classes, children, menuOpen, wrapperClass } = this.props
    const isIE = detectIE()
    return (
      <div
        className={
          customClasses
            ? customClasses.wrapper
            : classnames(
                wrapperClass ?? classes.wrapper,
                { [classes.ieFixOpen]: isIE && menuOpen },
                { [classes.ieFixClosed]: isIE && !menuOpen },
              )
        }
      >
        {children}
      </div>
    )
  }
}

export default withStyles(styles)(AdminContentWrapper)
