import React, { Component } from 'react';
import { EditInputType } from '../../../../config/constants';
import AdminResourceForm from '../../AdminResourceForm';
import styles from './ActivityModalStyles';
import { Typography, withStyles } from '@material-ui/core';
import FullScreenDialog from '../../../ui/FullScreenDialog';
import config from '../../../../config';
import { createContentH5p } from '../../../../actions/admin';

const stylesh5p = {
  ...styles,

  h5pButton: {
    height: 200,
    width: '100%',
    backgroundColor: '#eaeaea',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  h5pText: {
    color: '#000000',
  },
};

class ActivityModalH5pContent extends Component {
  state = {
    fullDialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.myIframe = React.createRef();
    this.loaded = false;
    this.locked = false;
  }

  getFormColumns = () => {
    return [
      {
        title: 'Título',
        source: 'name',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'name' },
      },
      {
        title: 'Descripción',
        source: 'description',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'description' },
      },
      {
        title: '',
        source: 'external',
        editable: true,
        editConfig: {
          type: EditInputType.Custom,
          name: 'external',
          renderItem: this.renderH5pButton,
        },
      },
    ];
  };

  onAttributeChange = (name, value) => {
    const { activity, onChange } = this.props;
    onChange({ ...activity, [name]: value });
  };

  onIframeLoad = () => {
    if (!this.loaded) {
      this.loaded = true;
    } else {
      this.loaded = false;
      this.onCloseH5pDialog(true);
    }
  };

  onEditH5p = async () => {
    if (!this.locked) {
      this.locked = true;
      const { activity, onChange } = this.props;
      if (!activity.external) {
        const h5pId = await createContentH5p(activity.type);
        if (h5pId) {
          onChange({ ...activity, external: h5pId });
        }
      } else {
        onChange({ ...activity });
      }
      this.setState({ fullDialogOpen: true }, () => {
        this.locked = false;
      });
    }
  };

  renderH5pButton = () => {
    const { classes } = this.props;
    return (
      <div className={classes.h5pButton} onClick={() => this.onEditH5p()}>
        <Typography className={classes.h5pText}>
          {'Editar contenido'}
        </Typography>
      </div>
    );
  };

  onCloseH5pDialog = (save) => {
    const { results } = this.props;
    if (save && results && results.isValid) {
      this.props.onSubmit();
      this.props.onCancel();
    }
    this.loaded = false;
    this.setState({ fullDialogOpen: false });
  };

  render() {
    const {
      activity,
      results,
      onSubmit,
      onCancel,
      actionButtons,
      title,
      classes,
    } = this.props;

    return (
      <div>
        <AdminResourceForm
          title={title}
          keyName={'admin-activity-modal-h5p-form'}
          form={activity}
          isValid={results && results.isValid}
          errors={results ? results.errors : []}
          loading={false}
          attributes={{ keys: this.getFormColumns() }}
          onAttributeChange={this.onAttributeChange}
          customClasses={classes}
          onSubmit={onSubmit}
          onCancel={onCancel}
          actionButtons={actionButtons}
        />
        <FullScreenDialog
          open={this.state.fullDialogOpen}
          onClose={() => this.onCloseH5pDialog(false)}
        >
          {activity.external > 0 && (
            <iframe
              allowFullScreen={'allowFullScreen'}
              ef={this.myIframe}
              title={'H5P Editor'}
              onLoad={() => this.onIframeLoad()}
              frameBorder="0"
              src={`${config.WP_ROOT}/wp-admin/admin-ajax.php?action=get_h5p&page=h5p_new&id=${activity.external}`}
              width={'100%'}
              height={'100%'}
              style={{ margin: 'auto', maxWidth: 960 }}
            />
          )}
        </FullScreenDialog>
      </div>
    );
  }
}

export default withStyles(stylesh5p)(ActivityModalH5pContent);
