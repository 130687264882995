import {
  MY_PROFILE_END,
  MY_PROFILE_START,
  MY_PROFILE_FAILED,
} from '../actions';

let initialState = {
  error: null,
  loading: false,
  profile: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MY_PROFILE_START:
      return {
        profile: null,
        error: null,
        loading: true,
      };
    case MY_PROFILE_END:
      // const { profileData } = ;
      return {
        loading: false,
        profile: action.payload,
        error: null,
      };
    case MY_PROFILE_FAILED:
      const { message } = action.payload;
      return {
        profile: null,
        error: message,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
