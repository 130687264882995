import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import config from '../../config/index'
import classNames from 'classnames'
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Popover,
  List,
  ListItemText,
  ListItem,
  Collapse,
} from '@material-ui/core'
import { FrontNavBarActionTypes } from '../../config/constants'
import LogoutContainer from '../../containers/session/Logout'
import { isAdmin, isReporting, hasDojoAccess } from '../../utils/AdminHelper'
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'
import PersonIcon from '@material-ui/icons/Person'
import MenuIcon from '@material-ui/icons/Menu'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { handleSubmenuOnTabKeyDown } from '../../utils/NavigationHelper'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    appBar: {
      backgroundColor: 'black',
      zIndex: theme.zIndex.drawer + 1,
      transitionTimingFunction: 'ease-in',
      transition: 'all 1s',
    },
    toolbar: {
      position: 'relative',
      padding: '0 90px',
      display: 'flex',
      justifyContent: 'space-between',
      [mediaLg]: {
        padding: '0',
      },
    },
    menuHandler: {
      display: 'none',
      [mediaLg]: {
        display: 'block',
      },
    },
    actions: {
      minHeight: 50,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    home: {
      position: 'relative',
      margin: '1em',
      padding: '0.5em',
      boxSizing: 'border-box',
      minWidth: 'unset',
      borderRadius: '100%',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    logoAcademia: {
      height: 36,
      [mediaLg]: {
        height: 36,
      },
    },
    exploreButton: {
      display: 'inline-flex',
      [mediaLg]: {
        display: 'none',
      },
    },
    flex: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
    },
    navIconHide: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    icon: {
      height: '2em',
      width: 0,
    },
    withoutBackground: {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: 'none',
    },
    action: {
      color: '#fff',
    },
    hideAction: {
      [mediaLg]: {
        display: 'none',
      },
    },
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    listItemText: {
      margin: 0,
    },
  }
}

class FrontNavBar extends React.Component {
  state = {
    scrolling: false,
    mainMenu: null,
    menuCareerPlan: false,
    menuSections: false,
  }
  constructor(props) {
    super(props)
    this.careerList = React.createRef()
    this.sectionsList = React.createRef()
  }

  handleDrawerToggle = () => {
    const { onToggleMenu } = this.props
    onToggleMenu()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  /** changes the scrolling state depending on the Y-position */
  handleScroll = () => {
    if (window.scrollY === 0) {
      this.setState({ scrolling: false })
    } else if (window.scrollY > 100 && !this.state.scrolling) {
      this.setState({ scrolling: true })
    }
  }

  handleClose = type => {
    const { onActionClick } = this.props
    this.setState({ mainMenu: null, menuCareerPlan: false, menuSections: false })
    onActionClick(type)
  }

  handleOpenMenu = event => {
    this.setState({ mainMenu: event.currentTarget })
  }

  toggleMenuCareerPlan = () => {
    this.setState({ menuCareerPlan: !this.state.menuCareerPlan })
  }

  toggleMenuSections = () => {
    this.setState({ menuSections: !this.state.menuSections })
  }

  handleScrollToSection = hash => {
    this.setState({ mainMenu: null, menuCareerPlan: false, menuSections: false })
    const anchor = document.querySelector(`#${hash}`)
    if (!anchor) {
      return
    }
    const headerOffset = document.querySelector('#main-nav').offsetHeight
    const elementPosition = anchor.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset
    return window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }

  handleNavEscapeKeyDown = e => {
    if (this.state.menuCareerPlan || this.state.menuSections) {
      this.setState({ menuCareerPlan: false, menuSections: false })
      const opener =
        e.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling
      if (opener) {
        opener.focus()
      }
    } else {
      this.handleClose()
    }
    return false
  }

  render() {
    const { scrolling, mainMenu, menuCareerPlan, menuSections } = this.state
    const { courses, externalCourses, classes, me, location, onActionClick } = this.props
    const inHome = location.pathname === '/'
    const admin = isAdmin(me)
    const reporting = isReporting(me)
    const dojoAccess = hasDojoAccess(me)
    const {
      required,
      topTen,
      variablePrice,
      inProgress,
      shared,
      finished,
      hidden,
      objectiveDplusI,
    } = courses
    const { nextDays, myPending } = externalCourses

    return (
      <AppBar
        id="main-nav"
        component={'nav'}
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.withoutBackground]: !scrolling && inHome,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            tabIndex={0}
            className={`${classes.action} ${classes.menuHandler}`}
            aria-label="abrir menú"
            onClick={this.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <Button
              role={'link'}
              tabIndex={0}
              className={classes.home}
              color="inherit"
              onClick={() => onActionClick(FrontNavBarActionTypes.Home)}
            >
              <img
                aria-hidden="true"
                alt="Logo Academia"
                onClick={() => onActionClick(FrontNavBarActionTypes.Home)}
                src={config.BASE_URL + 'img/ac_toolbar_icon.png'}
                className={classes.logoAcademia}
              />
            </Button>
            <Button
              tabIndex={0}
              className={`${classes.action} ${classes.exploreButton}`}
              aria-expanded={Boolean(mainMenu)}
              onClick={this.handleOpenMenu}
            >
              MENÚ
            </Button>
            <Popover
              id="main-menu"
              anchorEl={mainMenu}
              open={Boolean(mainMenu)}
              disableScrollLock={true}
              onClose={this.handleNavEscapeKeyDown}
            >
              <List role={'menu'} className={classes.root} disablePadding>
                <ListItem
                  button
                  role={'link'}
                  aria-label={'Categorías'}
                  onKeyDown={handleSubmenuOnTabKeyDown}
                  onClick={() => this.handleClose(FrontNavBarActionTypes.Explore)}
                >
                  <ListItemText className={classes.listItemText} primary="Categorías" />
                </ListItem>
                <ListItem
                  button
                  aria-label={'Rutas de Aprendizaje'}
                  aria-expanded={Boolean(menuCareerPlan)}
                  onKeyDown={handleSubmenuOnTabKeyDown}
                  onClick={this.toggleMenuCareerPlan}
                >
                  <ListItemText className={classes.listItemText} primary="Rutas de Aprendizaje" />
                  {menuCareerPlan ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={menuCareerPlan} timeout="auto">
                  <List component="div" disablePadding>
                    {me && me.subscribed_to_career_plans && (
                      <ListItem
                        button
                        role={'link'}
                        aria-label={'Mi Ruta'}
                        className={classes.nested}
                        onKeyDown={handleSubmenuOnTabKeyDown}
                        onClick={() => this.handleClose(FrontNavBarActionTypes.CareerPlans)}
                      >
                        <ListItemText className={classes.listItemText} secondary="Mi Ruta" />
                      </ListItem>
                    )}
                    <ListItem
                      button
                      role={'link'}
                      tabIndex={0}
                      aria-label={'Explorar otras rutas'}
                      className={classes.nested}
                      onKeyDown={handleSubmenuOnTabKeyDown}
                      onClick={() => this.handleClose(FrontNavBarActionTypes.ExploreCareerPlans)}
                    >
                      <ListItemText
                        className={classes.listItemText}
                        secondary="Explorar otras rutas"
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  button
                  role={'link'}
                  aria-label={'Próximos Talleres'}
                  onKeyDown={handleSubmenuOnTabKeyDown}
                  onClick={() => this.handleClose(FrontNavBarActionTypes.NextEvents)}
                >
                  <ListItemText className={classes.listItemText} primary="Próximos Talleres" />
                </ListItem>
                <ListItem
                  button
                  role={'link'}
                  onKeyDown={handleSubmenuOnTabKeyDown}
                  aria-label={'Listas de Aprendizaje'}
                  onClick={() => this.handleClose(FrontNavBarActionTypes.Playlists)}
                >
                  <ListItemText className={classes.listItemText} primary="Listas de Aprendizaje" />
                </ListItem>
                {dojoAccess && (
                  <ListItem
                    button
                    role={'link'}
                    aria-label={'Dojo'}
                    onKeyDown={handleSubmenuOnTabKeyDown}
                    onClick={e => {
                      e.preventDefault()
                      window.open(process.env.REACT_APP_DOJO_URL, '_blank')
                    }}
                  >
                    <ListItemText className={classes.listItemText} primary="Dojo" />
                  </ListItem>
                )}
                <ListItem
                  button
                  role={'link'}
                  aria-label={'Certificaciones Microsoft'}
                  onKeyDown={handleSubmenuOnTabKeyDown}
                  onClick={e => {
                    e.preventDefault()
                    window.open(process.env.REACT_APP_CERTIFICACIONES_MICROSOFT_URL)
                  }}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary="Certificaciones Microsoft"
                  />
                </ListItem>
                {inHome && (
                  <>
                    <ListItem
                      button
                      aria-label={'Secciones'}
                      aria-expanded={Boolean(menuSections)}
                      onKeyDown={handleSubmenuOnTabKeyDown}
                      onClick={this.toggleMenuSections}
                    >
                      <ListItemText className={classes.listItemText} primary="Secciones" />
                      {menuSections ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={menuSections} timeout="auto" unmountOnExit>
                      <List ref={this.sectionsList} component="div" disablePadding>
                        {hidden.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Cross Collaboration'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('cross-collaboration')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Cross Collaboration"
                            />
                          </ListItem>
                        )}
                        {required.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Mis requeridos pendientes'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('mis-requeridos-pendientes')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Mis requeridos pendientes"
                            />
                          </ListItem>
                        )}
                        {objectiveDplusI.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Objetivo D + I'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('objetivo-d-i')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Objetivo D + I"
                            />
                          </ListItem>
                        )}
                        {variablePrice.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Mis cursos para el PVI'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('mis-cursos-para-el-pvi')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Mis cursos para el PVI"
                            />
                          </ListItem>
                        )}
                        {myPending.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Mis próximos talleres'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('mis-proximos-talleres')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Mis próximos talleres"
                            />
                          </ListItem>
                        )}
                        {nextDays.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Talleres de los próximos 15 días'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() =>
                              this.handleScrollToSection('talleres-de-los-proximos-15-dias')
                            }
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Talleres de los próximos 15 días"
                            />
                          </ListItem>
                        )}
                        {inProgress.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Continuar viendo'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('continuar-viendo')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Continuar viendo"
                            />
                          </ListItem>
                        )}
                        {shared.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Me recomendaron…'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('me-recomendaron')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Me recomendaron…"
                            />
                          </ListItem>
                        )}
                        {topTen.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'TOP del mes'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('top-del-mes')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="TOP del mes"
                            />
                          </ListItem>
                        )}
                        {finished.items.length > 0 && (
                          <ListItem
                            button
                            role={'link'}
                            aria-label={'Finalizados'}
                            className={classes.nested}
                            onKeyDown={handleSubmenuOnTabKeyDown}
                            onClick={() => this.handleScrollToSection('finalizados')}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              secondary="Finalizados"
                            />
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </>
                )}
              </List>
            </Popover>
          </div>
          <div className={classes.flex}>
            <div className={classes.actions}>
              <IconButton
                role={'link'}
                tabIndex={0}
                className={classes.action}
                aria-label="Buscar cursos"
                onClick={() => onActionClick(FrontNavBarActionTypes.Search)}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                role={'link'}
                tabIndex={0}
                className={`${classes.action} ${classes.hideAction}`}
                aria-label="Perfil"
                onClick={() => onActionClick(FrontNavBarActionTypes.Profile)}
              >
                <PersonIcon />
              </IconButton>
              {(admin || reporting) && (
                <IconButton
                  role={'link'}
                  tabIndex={0}
                  aria-label="administrador"
                  className={`${classes.action} ${classes.hideAction}`}
                  onClick={() => onActionClick(FrontNavBarActionTypes.AdminHome)}
                >
                  <SettingsIcon />
                </IconButton>
              )}
              <div className={classes.hideAction} style={{ top: '1px', position: 'relative' }}>
                <LogoutContainer tabIndex={0} />
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}
export default withStyles(styles)(FrontNavBar)
