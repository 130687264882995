import validator from 'validator'
import { isBetweenValues } from '../../../../utils/ValidationHelpers'

const TITLE_LENGTH = {
  max: 80,
  min: 2,
}

const DESCRIPTION_LENGTH = {
  max: 600,
  min: 2,
}

const validateQuizTrivia = form => {
  let error = false
  const errors = {
    name: '',
    description: '',
    quizTrivia: '',
    passing_score: '',
    attemps: '',
  }

  if (!form.name || validator.isEmpty(form.name.trim())) {
    errors.name = 'Debes completar este campo'
    error = true
  } else if (
    !validator.isLength(form.name.trim(), {
      min: TITLE_LENGTH.min,
      max: TITLE_LENGTH.max,
    })
  ) {
    errors.name = `Este campo debe tener entre ${TITLE_LENGTH.min} y ${TITLE_LENGTH.max} caracteres.`
    error = true
  }

  if (!form.description || validator.isEmpty(form.description.trim())) {
    errors.description = 'Debes completar este campo'
    error = true
  } else if (
    !validator.isLength(form.description.trim(), {
      min: DESCRIPTION_LENGTH.min,
      max: DESCRIPTION_LENGTH.max,
    })
  ) {
    errors.description = `Este campo debe tener entre ${DESCRIPTION_LENGTH.min} y ${DESCRIPTION_LENGTH.max} caracteres.`
    error = true
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!form.hasOwnProperty('display_solution')) {
    errors.display_solution = 'Debes seleccionar una opción'
    error = true
  }

  if (!form.quizTrivia || form.quizTrivia.some(item => !!item.errors?.length)) {
    errors.quizTrivia = 'Debe completar la configuración de la trivia'
    error = true
  } else if (form.totalScore < 100) {
    errors.quizTrivia = `Tiene ${100 - form.totalScore} puntos pendientes de asignación`
    error = true
  }

  if (
    isNaN(form.passing_score) ||
    !Number.isInteger(Number(form.passing_score)) ||
    !isBetweenValues(Number(form.passing_score), { min: 0, max: 100 })
  ) {
    errors.passing_score = 'Debes ingresar un numero entero entre 0 y 100'
    error = true
  }

  if (isNaN(form.attemps) || !Number.isInteger(Number(form.attemps))) {
    errors.attemps =
      'Debes ingresar un número entero. Si no se debe limitar la cantidad de intentos, ingresar 0.'
    error = true
  }

  return { errors: errors, isValid: !error }
}

export default validateQuizTrivia
