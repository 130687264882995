import {
  LAZY_SELECT_INPUT_FETCH_END,
  LAZY_SELECT_INPUT_FETCH_FAIL,
  LAZY_SELECT_INPUT_FETCH_START,
  LAZY_SELECT_INPUT_INIT,
} from './index';

export const init = (keyName) => async (dispatch) => {
  dispatch({ type: LAZY_SELECT_INPUT_INIT, key: keyName });
};

export const fetchOptions = (keyName, optionsLoader) => async (dispatch) => {
  dispatch({ type: LAZY_SELECT_INPUT_FETCH_START, key: keyName });
  try {
    const options = await optionsLoader();
    dispatch({
      type: LAZY_SELECT_INPUT_FETCH_END,
      key: keyName,
      payload: { options },
    });
  } catch (e) {
    dispatch({
      type: LAZY_SELECT_INPUT_FETCH_FAIL,
      key: keyName,
      payload: { message: e.toString() },
    });
  }
};
