import React, { useEffect, useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { appLoadToken } from '../../actions/app'
import { loadPlaylist } from '../../actions/playlists'
import { addSnackbar } from '../../actions/snackbar'
import { goTo } from '../../actions/navigator'
import { COURSES_VIEW, PLAYLISTS, router } from '../../config/routes'
import DocumentTitle from '../../components/ui/DocumentTitle'
import Loading from '../../components/ui/Loading'
import Playlist from '../../components/playlists/Playlist'
import api from '../../api/api'
import { SnackbarType } from '../../config/constants'

const PlaylistContainer = ({
  playlistId,
  playlist,
  appLoadToken,
  loadPlaylist,
  goTo,
  addSnackbar,
  me,
}) => {
  useEffect(() => {
    appLoadToken().then(() => {
      loadPlaylist(playlistId)
    })
  }, [appLoadToken, loadPlaylist, playlistId])

  const showCourse = useCallback(
    course => {
      goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
    },
    [goTo],
  )

  const onDeletePlaylist = useCallback(async () => {
    try {
      await api.My.Playlists.delete(playlist.id)
      addSnackbar('Lista eliminada', SnackbarType.Success)
      goTo(PLAYLISTS)
    } catch (e) {
      console.log(e)
      addSnackbar('Ocurrió un error', SnackbarType.Error)
    }
  }, [goTo, playlist, addSnackbar])

  const silentReloadPlaylist = useCallback(() => {
    loadPlaylist(playlistId, true)
  }, [loadPlaylist, playlistId])

  const onFollowUnfollow = useCallback(async () => {
    try {
      await api.Playlists.followUnfollow(playlist.id, !playlist.followed)
      silentReloadPlaylist()
    } catch (e) {}
  }, [playlist, silentReloadPlaylist])

  const onUnlinkCourse = useCallback(
    async course => {
      try {
        await api.Playlists.unlink(playlist.id, course.id)
        silentReloadPlaylist()
      } catch (e) {}
    },
    [playlist, silentReloadPlaylist],
  )

  return (
    <React.Fragment>
      {playlist && <DocumentTitle title={playlist.name + ' | Lista | Academia'} />}
      {!playlist ? (
        <Loading fullSize={true} />
      ) : (
        <Playlist
          playlist={playlist}
          onShowCourse={showCourse}
          onDeletePlaylist={onDeletePlaylist}
          onFollowUnfollow={onFollowUnfollow}
          onUnlinkCourse={onUnlinkCourse}
          silentReloadPlaylist={silentReloadPlaylist}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    me: { ...state.userReducer },
    ...state.playlistReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      appLoadToken,
      goTo,
      loadPlaylist,
      addSnackbar,
    },
    dispatch,
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaylistContainer))
