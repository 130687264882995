import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import HeaderCareerPlansExpanded from './HeaderCareerPlansExpanded'
import Filters from '../ui/Filters'
import { Grid, Typography } from '@material-ui/core'
import ZoomGrid from '../ui/ZoomGrid'
import Loading from '../ui/Loading'
import {
  FiltersFormat,
  EditInputType,
  CourseMandatoriesOptions,
  CourseProgressStatus,
} from '../../config/constants'
import { minutesToDurationText } from '../../utils/FormatHelper'
import EventListenerWrapper from '../ui/EventListenerWrapper'
import MixedCourseItem from '../ui/MixedCourseItem'

const styles = {
  root: {
    marginBottom: 20,
  },
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sectionExpanded: {
    display: 'none',
  },
  isExpanded: {
    display: 'block',
  },
  triangleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '25px 25px 0 25px',
    borderColor: 'grey transparent transparent transparent',
  },

  // ESTILO AJENO

  containerMyCareerPlans: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    paddingTop: 32,
    paddingBottom: 50,
    paddingLeft: '5.5%',
    paddingRight: '5.5%',
  },
  inputByName: {
    width: '90%',
    border: ['solid', 0.5, '#838282'],
    opacity: 0.47,
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#707070',
  },
  textInput: {
    marginTop: 2,
  },

  wrapperButton: {
    marginTop: 25,
  },
  noData: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#7f7f7f',
    marginBottom: 10,
  },
  // #707070
  titleSection: {
    width: 350,
    paddingTop: 5,
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    margin: '0px 2px 20px',
  },
  title: {
    marginLeft: '15px',
    marginBottom: '2px',
    fontFamily: 'PlaylistScript',
    fontSize: 28,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#34353D',
  },
  durationTotalTitle: {
    marginLeft: '15px',
    marginBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 600,
    fontStyle: 'normal',
    color: '#34353D',
  },
  durationTotalValor: {
    marginBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#34353D',
  },
  iconTitle: {
    paddingRight: 5,
    paddingLeft: 10,
    verticalAlign: 'middle',
  },
  box: {
    paddingTop: 25,
  },
}

function CareerPlansExpandedWithStyles({
  classes,
  isExpanded,
  currentCareerPlans,
  onShowCourse,
  loadData,
  onDeleteSharedCourse,
}) {
  const [expanded, setExpanded] = useState(isExpanded)

  useEffect(() => {
    setFiltering(false)
    if (currentCareerPlans && localStorage.getItem('tabOpenSaved') === currentCareerPlans.id) {
      setExpanded(true)
    }
  }, [currentCareerPlans])

  const [, setFiltering] = useState(true)

  const getFilterConfig = () => {
    let scalesItem = []
    let filter = [
      {
        type: EditInputType.Text,
        name: 'course.title',
        classname: classes.textInput,
        placeholder: 'Nombre del curso',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Select,
        name: 'career_plan_course.mandatory',
        placeholder: 'Condición',
        format: FiltersFormat.Plain,
        options: Object.keys(CourseMandatoriesOptions).map(k => ({
          id: k,
          description: CourseMandatoriesOptions[k],
        })),
      },
      {
        type: EditInputType.Select,
        name: 'user_course.totalActivitiesDone',
        placeholder: 'Estado',
        format: FiltersFormat.Is,
        options: Object.keys(CourseProgressStatus).map(k => ({
          id: k,
          description: CourseProgressStatus[k],
        })),
      },
    ]

    if (currentCareerPlans.scales.length > 0) {
      scalesItem = currentCareerPlans.scales
      filter.push({
        type: EditInputType.Select,
        name: 'scale.id',
        placeholder: 'Escalas',
        format: FiltersFormat.Plain,
        options: scalesItem.map(uw => {
          return {
            id: uw.id,
            description: uw.name,
          }
        }),
      })
    }
    return filter
  }

  const onItemClick = item => {
    onShowCourse(item)
  }

  const deleteSharedCourse = item => {
    onDeleteSharedCourse(item)
  }

  const buildQuery = query => {
    loadData(query)
  }

  const handleClickExpanded = id => {
    setExpanded(!expanded)
    localStorage.setItem('tabOpenSaved', id)
  }

  return (
    <>
      {!currentCareerPlans && <Loading></Loading>}
      {currentCareerPlans && (
        <main id={'main'} tabIndex={-1} className={classnames({ [classes.root]: expanded })}>
          {currentCareerPlans && currentCareerPlans.name && (
            <>
              <EventListenerWrapper
                tabIndex={0}
                onClick={!isExpanded ? () => handleClickExpanded(currentCareerPlans.id) : null}
                onEnter={!isExpanded ? () => handleClickExpanded(currentCareerPlans.id) : () => {}}
              >
                <HeaderCareerPlansExpanded
                  name={currentCareerPlans.name}
                  channels={currentCareerPlans.channels}
                  total_courses_duration={currentCareerPlans.total_courses_duration}
                  mandatory_courses_duration={currentCareerPlans.mandatory_courses_duration}
                  suggested_courses_duration={currentCareerPlans.suggested_courses_duration}
                  map={currentCareerPlans.map}
                  currentPlan={currentCareerPlans}
                  user_progress={currentCareerPlans.user_progress} // TODO: Replace by two progress
                  titleButton="Ver Mapa"
                  tabIndex={0}
                />
              </EventListenerWrapper>

              {!isExpanded && !expanded && (
                <div className={classes.triangleContainer}>
                  <div className={classes.triangle}></div>
                </div>
              )}
            </>
          )}

          <div
            className={classnames(classes.sectionExpanded, {
              [classes.isExpanded]: expanded,
            })}
          >
            {currentCareerPlans && currentCareerPlans.scales && (
              <div className={classes.containerMyCareerPlans}>
                <Filters
                  key={'filters'}
                  keyName={'keyName'}
                  fetchData={buildQuery}
                  getFilterConfig={getFilterConfig}
                />
                {currentCareerPlans.scales.length > 0 &&
                  currentCareerPlans.scales.map(itemScales => {
                    return (
                      <Grid container className={classes.content}>
                        <div className={classes.titleSection}>
                          <Typography className={classes.title} component={'h3'}>
                            {itemScales.name}
                            {itemScales.mandatory_completed > 0 && (
                              <img
                                src={itemScales.stamp_mandatory}
                                height="40"
                                className={classes.iconTitle}
                              />
                            )}
                            {itemScales.suggested_completed > 0 && (
                              <img
                                src={itemScales.stamp_suggested}
                                height="40"
                                className={classes.iconTitle}
                              />
                            )}
                          </Typography>
                          <Typography className={classes.durationTotalTitle} component={'p'}>
                            Duración:{' '}
                            <span className={classes.durationTotalValor}>
                              {' '}
                              {minutesToDurationText(itemScales.courses_duration) + '.'}
                            </span>
                          </Typography>
                        </div>

                        <ZoomGrid
                          ariaLabel={'Listado de cursos y/o talleres del plan de carrera'}
                          items={itemScales.courses}
                          itemCreator={item => (
                            <MixedCourseItem
                              item={item}
                              tabIndex={0}
                              itemClick={item => onItemClick(item)}
                              deleteSharedCourse={item => deleteSharedCourse(item)}
                              showFlags={true}
                            />
                          )}
                          itemHeight={180}
                          itemWidth={320}
                          paddingHorizontal
                        />
                      </Grid>
                    )
                  })}

                {currentCareerPlans.scales.length < 1 && (
                  <Grid container className={classes.content}>
                    <Typography className={classes.noData} tabIndex={0}>
                      En esta Ruta no existen cursos según los filtros ingresados, por favor
                      reintente
                    </Typography>
                  </Grid>
                )}
              </div>
            )}
          </div>
        </main>
      )}
    </>
  )
}

CareerPlansExpandedWithStyles.defaultProps = {
  isExpanded: false,
}

const CareerPlansExpanded = withStyles(styles)(CareerPlansExpandedWithStyles)

export default CareerPlansExpanded
