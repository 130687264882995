import moment from 'moment'

export const blankQuestion = () => ({
  question_text: undefined,
  image: null,
  score: 0,
  questions_type: undefined,
  options: [],
  id: 'new' + moment(),
  ready: false,
})

export const setTrueOrFalse = value => [
  {
    options_text: 'Verdadero',
    correct_answer: !!value,
    mark: '1',
  },
  {
    options_text: 'Falso',
    correct_answer: !value,
    mark: '0',
  },
]

export const convertNumberToId = number => {
  var alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')

  if (number < alphabet.length) {
    return alphabet[number]
  } else {
    return (
      convertNumberToId(Math.floor(number / alphabet.length) - 1) +
      convertNumberToId(number % alphabet.length)
    )
  }
}

export const blankOption = (options_text = undefined, correct_answer = false, mark = null) => ({
  options_text,
  correct_answer,
  mark,
})

export const asterisksQuestionsBlankOption = blankOption(undefined, true)

export const defaultOptions = {
  MultipleChoice: [blankOption()],
  TrueOrFalse: [
    {
      options_text: 'Verdadero',
      correct_answer: undefined,
      mark: '1',
    },
    {
      options_text: 'Falso',
      correct_answer: undefined,
      mark: '0',
    },
  ],
  FillInTheBlanks: [asterisksQuestionsBlankOption],
  DragTheWords: [asterisksQuestionsBlankOption],
}

export const addOption = (question, option) => [...question.options, option]
export const removeOption = (question, index) =>
  question.options.filter((item, itemIndex) => index !== itemIndex)
export const editOption = (question, option, index = 0) =>
  question.options.map((item, itemIndex) => (index === itemIndex ? option : item))

export const getQuestionTypeType = (questionTypeId, questionTypes) =>
  questionTypes.find(({ id }) => id === questionTypeId).type
