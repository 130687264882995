import React, { Component } from 'react';

import SnackbarMessage from '../../components/ui/SnackbarMessage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeSnackbar } from '../../actions/snackbar';

class SnackbarMessageContainer extends Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.closeSnackbar();
  };

  render() {
    const { message, type, open } = this.props;
    return (
      <SnackbarMessage
        type={type}
        onClose={this.handleClose}
        open={open}
        message={message}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.snackbarMessageReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeSnackbar }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarMessageContainer);
