import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { login,  } from '../../actions/user'

/**
 * Login IdFw. Este login solo es para uso en develop
 * @return render login
 */

const IdFw = () => {
  const [user, setUser] = useState('')
  const dispach = useDispatch()

  /**
   * Cargar usuario
   * @param {object} e evento
   */
  const handleOnChange = e => {
    setUser(e.target.value)
  }

  /**
   * Enviar datos de usuario
   * @param {object} e evento
   * @return Enviar el ott al action login para realizar el logeo
   */
  const handleLogin = e => {
    e.preventDefault()
    dispach(login({ datos: user }))
  }

  return (
    <form onSubmit={handleLogin}>
      <input name="datos" value={user} onChange={handleOnChange} />
      <input type="submit" value="LOGIN" />
    </form>
  )
}

export default IdFw;
