import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import { isAdmin, isOpenBankUser } from '../../../utils/AdminHelper'
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  AdminItemViewType,
  CourseProgress,
  ActiveType,
  SnackbarType,
} from '../../../config/constants'

const KEY_NAME = 'reports/user_courses'

class AdminReportsContainer extends Component {

  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size, undefined);
  }

  getColumns = () => {
    const { me } = this.props
    let columns = [
      { title: 'Nombre', source: 'user.first_name' },
      { title: 'Apellido', source: 'user.last_name' },
      { title: 'Legajo', source: isOpenBankUser(me.user) ? 'user.record' : 'user.username' },
      { title: 'ID curso', source: 'course.id' },
      { title: 'Nombre de curso', source: 'course.title' },
      {
        title: 'Estado del curso',
        source: 'completed',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(CourseProgress).map(k => ({
          condition: k,
          result: CourseProgress[k],
        })),
      },
      { title: 'Fecha de finalización', source: 'completed_at', type: AdminItemViewType.Date },
      { title: 'Duración', source: 'course.duration' },
      {
        title: 'Gerencia Departamental',
        source: 'user.organizational_unit.departmental_management',
      },
      { title: 'Gerencia Principal', source: 'user.organizational_unit.main_management' },
      { title: 'Área cliente interno', source: 'user.organizational_unit.internal_area' },
      { title: isOpenBankUser(me.user) ? 'Area' : 'Centro de costos', source: 'user.organizational_unit.code' },
      { title: 'Puesto', source: 'user.job_position.description' },
    ]
    if (isAdmin(me.user))
      columns.push({ title: 'Activo', source: 'user.active', type: AdminItemViewType.Boolean })

    return columns
  }

  getFilterConfig = () => {
    const { me } = this.props

    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Categories,
        name: 'category.id',
        placeholder: 'Categoría',
        multiple: true,
        format: FiltersFormat.In,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Courses,
        name: 'course.id',
        placeholder: 'Curso',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'title',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.id',
        placeholder: isOpenBankUser(me.user) ? 'Area' : 'Centro de Costo',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'code',
      },
      {
        type: EditInputType.Text,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.departmentalManagement',
        placeholder: 'Gerencia Departamental',
        multiple: true,
        format: FiltersFormat.Like,
        displayAttribute: 'departmental_management',
      },
      {
        type: EditInputType.Text,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.mainManagement',
        placeholder: 'Gerencia Principal',
        multiple: true,
        format: FiltersFormat.Like,
        displayAttribute: 'main_management',
      },
      {
        type: EditInputType.Date,
        name: 'userCourse.completedAtFrom',
        placeholder: 'Fecha Desde',
        helpText: 'Fecha Desde',
        format: FiltersFormat.MayorIgual,
        queryName: 'userCourse.completedAt',
      },
      {
        type: EditInputType.Date,
        name: 'userCourse.completedAtTo',
        placeholder: 'Fecha Hasta',
        helpText: 'Fecha Hasta',
        format: FiltersFormat.MenorIgual,
        queryName: 'userCourse.completedAt',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.JobPosition,
        name: 'user.jobPosition',
        placeholder: 'Puesto',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'description',
      },
      {
        type: EditInputType.Text,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.internalArea',
        placeholder: 'Área Cliente Interno',
        multiple: true,
        format: FiltersFormat.Like,
        displayAttribute: 'internal_area',
      },
    ]

    if (isAdmin(me.user)) {
      response.push({
        type: EditInputType.Select,
        name: 'user.active',
        placeholder: 'Activo',
        format: FiltersFormat.Plain,
        options: Object.keys(ActiveType).map(k => ({
          id: k,
          description: ActiveType[k],
        })),
      })
    }
    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
		
		await processReportOffline(query, KEY_NAME)
			.then((response) => {
				addSnackbar(response.data, SnackbarType.Info)
			})
			.catch(message =>{
				addSnackbar(message.data, SnackbarType.Warning)
			})
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
			addSnackbar,
    } = this.props
		downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE ACTIVIDAD DE USUARIOS'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer
  if (paginatorState && paginatorState.user_courses && paginatorState.user_courses.items) {
    paginatorState.user_courses.items.map(course =>
      course.completed_at && course.completed_at.length
        ? (course.completed = CourseProgress.true)
        : (course.completed = CourseProgress.false),
    )
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getResources, processReportOffline, downloadReport, addSnackbar }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsContainer)
