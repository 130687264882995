import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../../actions/navigator';
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource
} from '../../../actions/admin';
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  ADMIN_VIEW,
  router,
  ADMIN_COURSES,
  ADMIN_COURSES_BLOCK,
  ADMIN_COURSES_BASIC,
  ADMIN_COURSES_TOPTEN,
} from '../../../config/routes';
import AdminResourceIndex from '../AdminResourceIndex';
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType,
  CourseStatus,
  EditInputObjectModel,

} from '../../../config/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { addSnackbar } from '../../../actions/snackbar';

class AdminCoursesContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
  };

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size, undefined)
  };

  onRenderCategories = (item) => {
    let categories = item.categories.map((c) => c.name);
    return categories.join(', ');
  };
  onRenderTags = (item) => {
    let tags = item.tags.map((t) => t.name);
    return tags.join(', ');
  };
  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Título', source: 'title', expanded: true },
      {
        title: 'Categoría/s',
        source: 'categories',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderCategories,
      },
      {
        title: 'Etiqueta/s',
        source: 'tags',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderTags,
      },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(CourseStatus).map((k) => ({
          condition: k,
          result: CourseStatus[k],
        })),
      },
      {
        title: 'Fecha de publicación',
        source: 'published_at',
        type: AdminItemViewType.Date,
      },
      {
        title: 'Requeridos',
        source: 'required',
        type: AdminItemViewType.Boolean,
      },
    ];
  };

  onCreate = () => {
    this.props.goTo(ADMIN_COURSES_BASIC + ADMIN_NEW);
  };

  onCreateTopCourses = () => {
    this.props.goTo(ADMIN_COURSES_TOPTEN);
  }

  onEditItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_COURSES_BASIC + ADMIN_EDIT, { id: item.id })
    );
  };

  onViewItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_COURSES_BLOCK + ADMIN_EDIT, { id: item.id })
    );
  };


  onDeleteItem = (item) => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.title,
    });
  };


  getActions = (item) => {
		if (item.status === 3) return;
      return [
        {
          title: 'EDITAR BLOQUES/ACTIVIDADES',
          action: this.onViewItem,
          icon: <ViewIcon />,
        },
        {
          title: 'EDITAR DATOS BASICOS',
          action: this.onEditItem,
          icon: <EditIcon />,
        },
        {
          title: 'ELIMINAR',
          action: this.onDeleteItem,
          icon: <DeleteIcon />,
        },
      ]
  };

  onShowItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_COURSES + ADMIN_VIEW, { id: item.id })
    );
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'course.title',
        placeholder: 'Título',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Categories,
        name: 'category.id',
        placeholder: 'Categoría',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Tags,
        name: 'tag.id',
        placeholder: 'Etiqueta',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Select,
        name: 'course.status',
        placeholder: 'Estado',
        format: FiltersFormat.Plain,
        options: Object.keys(CourseStatus).map((k) => ({
          id: k,
          description: CourseStatus[k],
        })),
      },
    ];
  };

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props;
    const item = this.state.deleteItem;
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Curso eliminado.', SnackbarType.Success);
        refreshResources(this.getKeyName());
      })
      .catch((err) => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error);
      });
  };

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
  };


  getKeyName = () => {
    return 'admin-courses';
  };

  render() {
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={'CURSOS'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        onCreateTopCourses={this.onCreateTopCourses}
      >
        <ConfirmDialog
          title={'Eliminar Curso'}
          open={this.state.confirmDeleteOpen}
          description={`¿Estás seguro de eliminar el curso "${this.state.itemToDelete}"?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    );
  }
}

AdminCoursesContainer.defaultProps = {
  type: 'courses',
};

const mapStateToProps = (state) => {
  return {
    me: { ...state.userReducer },
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, refreshResources, deleteResource, goTo, addSnackbar, updateResource },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCoursesContainer);
