import React  from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import config from "../../../config";

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    gradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background:
          'linear-gradient(315deg, #b32b52 0%, #922343 6%, #6b1931 10%, #4a1121 17%, #2f0b15 24%, #1a060b 32%, #0b0205 59%, #020001 76%, #000000 100%)',
      opacity: 0.49,
    },
    header: {
      position: 'relative',
      backgroundSize: 'cover',
      background: `center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      height: '10%',
      minHeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
    headerContent: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingTop: 50,
      position: 'relative',
      textAlign: 'center',
      margin: 'auto',
      maxWidth: '50%',
    },
    title: {
      fontFamily: 'PlaylistScript',
      fontSize: 50,
      fontWeight: 100,
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'center',
      color: '#ffffff',
      textShadowColor: 'rgba(0, 0, 0, 0.16)',
      textShadowOffset: {
        width: 0,
        height: 1.5,
      },
      textShadowRadius: '3px',
      [mediaLg]: {
        fontSize: 40,
      },
    },
    arrow: {
      transform: 'rotate(180deg)',
      height: 100,
    },
  }
}

const HeaderNextEvents = ({ classes }) => {
  return (
    <header className={classes.header}>
      <div className={classes.gradient} />
      <div className={classes.headerContent}>
        <img
            alt="Flecha Izquierda"
            src={config.BASE_URL + 'img/arrow_blue_right.png'}
            className={classes.arrow}
            aria-hidden="true"
        />

        <Typography component={'h1'} className={classes.title}>
          Próximos talleres
        </Typography>
        <img
            alt="Flecha Derecha"
            src={config.BASE_URL + 'img/arrow_pink_left.png'}
            className={classes.arrow}
            aria-hidden="true"
        />
    </div>
    </header>
  )
}
export default withStyles(styles)(HeaderNextEvents)
