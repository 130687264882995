import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CourseType } from '../../config/constants'
import ExternalCourseItem from './ExternalCourseItem'
import CourseItem from "./CourseItem";

class MixedCourseItem extends Component {
  render() {
    const { item } = this.props;
    return item && item.type === CourseType.ExternalCourse ? (
      <ExternalCourseItem  {...this.props} />
    ) : (
      <CourseItem  {...this.props} />
    )
  }
}

MixedCourseItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemClick: PropTypes.func,
  tabIndex: PropTypes.number,
}

export default MixedCourseItem;
