/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react'
import { Typography, Tooltip } from '@material-ui/core'
import ResolverWrapper from './components/ResolverWrapper'
import './styles.css'
import {
  blankFieldSetter,
  classEditableSpanFieldSetter,
} from '../../../../../utils/ActivitiesHelper'
import Loading from '../../../../ui/Loading'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import _ from 'lodash'

const wordsContainerName = 'words'

const stringIdSetter = (questionId, type, index) => `${type}-question-${questionId}-index-${index}`

const DragTheWordsResolver = ({
  question,
  payload,
  setPayload,
  activityState,
  correctlyAnswered,
}) => {
  const [strings, setStrings] = useState()
  const [correctAnswerStrings, setCorrectAnswerStrings] = useState()
  const [words, setWords] = useState()

  const handleOnDragEnd = ({ destination, draggableId, source }) => {
    if (destination && destination.droppableId !== source?.droppableId) {
      setStrings(state =>
        state.map(stringData => {
          if (stringData.id === draggableId) {
            return { ...stringData, destination: destination.droppableId }
          }
          if (stringData.destination === destination.droppableId) {
            return { ...stringData, destination: wordsContainerName }
          }
          return stringData
        }),
      )
    }
  }

  const optionBuilder = (value, index) =>
    index % 2 ? (
      <div
        key={'field-' + value + '-' + index}
        className={`field ${classEditableSpanFieldSetter(
          activityState.done,
          correctAnswerStrings ? correctAnswerStrings[index] === value : undefined,
          'droppable',
        )}`}
      >
        <div>
          <Tooltip title={activityState.done ? (value?.length === '' ? ' ' : value) : value}>
            <Typography className="draggable-word" style={{ display: 'inline-block' }}>
              {activityState.done ? (value?.length === '' ? ' ' : value) : value}
            </Typography>
          </Tooltip>
        </div>
      </div>
    ) : (
      !(!index && !value) && (
        <span key={'text-' + value + '-' + index} className="text">
          {value}
        </span>
      )
    )

  const dragTheWordsBuilder = ({ string, id, draggable }, index) => {
    const droppableId = index
    return draggable ? (
      <Droppable droppableId={droppableId} key={id} type="WORDS">
        {(provided, snapshot) => (
          <div
            className={`field ${classEditableSpanFieldSetter(
              activityState.done,
              correctAnswerStrings ? correctAnswerStrings[index] === string : undefined,
              'droppable',
            )}${snapshot.isDraggingOver ? ' dragging-over' : ''}`}
            {...provided.droppableProps}
            ref={provided.innerRef}
            {...snapshot}
          >
            {strings
              .filter(({ destination }) => destination === droppableId)
              ?.map(({ string, id }, wordIndex) => {
                return (
                  <Draggable
                    draggableId={id}
                    index={wordIndex}
                    key={id}
                    isDragDisabled={!!activityState.done}
                  >
                    {providedDrag => (
                      <div
                        {...providedDrag.dragHandleProps}
                        {...providedDrag.draggableProps}
                        ref={providedDrag.innerRef}
                      >
                        <Tooltip title={string}>
                          <Typography
                            className="draggable-word"
                            style={{ display: 'inline-block' }}
                          >
                            {string}
                          </Typography>
                        </Tooltip>
                      </div>
                    )}
                  </Draggable>
                )
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    ) : (
      !(!index && !string) && (
        <span key={'text-' + string + '-' + index} className="text">
          {string}
        </span>
      )
    )
  }

  useEffect(() => {
    const currentQuestion = payload.find(
      ({ question: questionOnPayload }) => question.id === questionOnPayload.id,
    ).question
    if (!!currentQuestion?.options && !!currentQuestion?.options[0]?.strings) {
      setStrings(currentQuestion.options[0].strings)
    } else {
      if (activityState.done || !!activityState.preview) {
        setStrings(
          question.options[0].options_text.split('*').map((string, index) => {
            return {
              draggable: !!(index % 2),
              string,
              id: stringIdSetter(question.id, index),
              destination: !!(index % 2) ? wordsContainerName : index,
            }
          }),
        )
      } else {
        let count = 0
        const getStringValue = () => question.options[0].words[count++]
        setStrings(
          question.options[0].options_text.split('*').map((string, index) => {
            return {
              draggable: !!(index % 2),
              string: !!(index % 2) ? getStringValue() : string,
              id: stringIdSetter(question.id, !!(index % 2) ? 'draggable' : 'string', index),
              destination: !!(index % 2) ? wordsContainerName : index,
            }
          }),
        )
      }
    }
    setPayload(state =>
      state.map(({ question: { id, options } }) => {
        return id === question.id
          ? {
              question: {
                id,
                options: blankFieldSetter({
                  id: question.options[0].id,
                  answered_text:
                    options[0]?.options_text ??
                    question.options[0].options_text
                      .split('*')
                      .map((string, index) => (!!(index % 2) ? '' : string))
                      .join('*'),
                }),
              },
            }
          : { question: { id, options } }
      }),
    )
    if (activityState.done) {
      setCorrectAnswerStrings(question.options[0].options_text.split('*'))
    }
  }, [])

  useEffect(() => {
    setWords(_.shuffle(strings).filter(item => item.destination === wordsContainerName))
    if (
      strings &&
      !!payload.find(({ question: questionOnPayload }) => question.id === questionOnPayload.id)
        ?.question?.options
    ) {
      setPayload(state =>
        state.map(({ question: { id, options } }) =>
          id === question.id
            ? {
                question: {
                  id,
                  options: [
                    {
                      id: options[0].id,
                      answered_text: strings
                        .map((item, index) => {
                          if (!item.draggable || item.destination === index) {
                            return item.string
                          } else {
                            const stringForThisField = strings.find(
                              ({ destination: destinationId }) => destinationId === index,
                            )?.string
                            return stringForThisField ?? ''
                          }
                        })
                        .join('*'),
                      strings,
                    },
                  ],
                },
              }
            : { question: { id, options } },
        ),
      )
    }
  }, [strings])

  return !words ? (
    <Loading />
  ) : (
    <>
      <ResolverWrapper
        className="resolver__drag-the-words"
        activityState={activityState}
        correctlyAnswered={correctlyAnswered}
        type="fields"
      >
        {!activityState.done ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="resolver__drag-the-words__content">
              <Droppable droppableId={wordsContainerName} type="WORDS">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`row draggable-words-container${
                      snapshot.isDraggingOver ? ' dragging-over' : ''
                    }`}
                  >
                    {words?.map(({ string, id }, wordIndex) => (
                      <Draggable draggableId={id} index={wordIndex} key={id}>
                        {providedDrag => (
                          <div
                            {...providedDrag.dragHandleProps}
                            {...providedDrag.draggableProps}
                            ref={providedDrag.innerRef}
                          >
                            <Tooltip title={string}>
                              <Typography className="draggable-word">{string}</Typography>
                            </Tooltip>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Typography
                id={`dragTheWords-${question.id}`}
                style={{
                  borderLeft: 'solid 1px #00000015',
                  paddingLeft: '0.75rem',
                }}
              >
                {strings.map(dragTheWordsBuilder)}
              </Typography>
            </div>
          </DragDropContext>
        ) : (
          <div className="resolver__drag-the-words__content">
            <Typography id={`dragTheWords-${question.id}`}>
              {activityState.answered_quiz
                .find(({ question: { id } }) => id === question.id)
                ?.question?.options.reverse()[0]
                ?.answered_text?.split('*')
                ?.map(optionBuilder) ?? ''}
            </Typography>
          </div>
        )}
      </ResolverWrapper>
      {!!activityState.done && !!correctAnswerStrings && !correctlyAnswered && (
        <div className="solution__drag-the-words">
          <Typography style={{ fontSize: '0.65rem', fontWeight: 500 }}>
            Respuesta correcta:
          </Typography>
          <Typography
            id={`dragTheWords-solution-${question.id}`}
            style={{
              lineHeight: '2.5rem',
            }}
          >
            {correctAnswerStrings.map(optionBuilder)}
          </Typography>
        </div>
      )}
    </>
  )
}

export default DragTheWordsResolver
