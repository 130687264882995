import React from 'react'
import { Typography } from '@material-ui/core'

const NoDisplaySolutionMsg = () => (
  <div className="col doing-trivia-question-container no-display-solution">
    <Typography style={{ fontWeight: 700, fontSize: '1.25rem' }}>
      Aún no se encuentran habilitadas las respuestas para su revisión.
    </Typography>
    <Typography style={{ fontWeight: 600, fontSize: '1.125rem' }}>
      ¡Te avisaremos a la brevedad!
    </Typography>
  </div>
)

export default NoDisplaySolutionMsg
