import React, { Component } from 'react'
import AutocompleteSearchSimpleObject from '../../components/ui/AutocompleteSearchSimpleObject'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { EditInputObjectModel } from '../../config/constants'
import {
  searchTags,
  searchCategories,
  searchCourses,
  searchUsers,
  searchProviders,
  searchUsersToShare,
  createTags,
  createCategories,
  searchOrganizationalUnit,
  searchJobPosition,
  searchFacilitators,
  searchPublicExternalCourses,
  searchPlaces,
  searchInstitutions,
  searchCommissionPlaces,
  searchExternalReferencesReferents,
  searchExternalReferencesProviders,
} from '../../actions/autocomplete'

class AutocompleteSearchSimpleObjectContainer extends Component {
  getOnFetchHanlder = () => {
    switch (this.props.model) {
      case EditInputObjectModel.Tags:
        return this.props.searchTags
      case EditInputObjectModel.Categories:
        return this.props.searchCategories
      case EditInputObjectModel.Courses:
        return this.props.searchCourses
      case EditInputObjectModel.Users:
        return this.props.searchUsers
      case EditInputObjectModel.Providers:
        return this.props.searchProviders
      case EditInputObjectModel.UsersToShare:
        return this.props.searchUsersToShare
      case EditInputObjectModel.OrganizationalUnit:
        return this.props.searchOrganizationalUnit
      case EditInputObjectModel.JobPosition:
        return this.props.searchJobPosition
      case EditInputObjectModel.Facilitators:
        return this.props.searchFacilitators
      case EditInputObjectModel.PublicExternalCourses:
        return this.props.searchPublicExternalCourses
      case EditInputObjectModel.Places:
        return this.props.searchPlaces
      case EditInputObjectModel.CommissionPlaces:
        return this.props.searchCommissionPlaces
      case EditInputObjectModel.ExternalReferencesProviders:
        return this.props.searchExternalReferencesProviders
      case EditInputObjectModel.ExternalReferencesReferents:
        return this.props.searchExternalReferencesReferents
      default:
        return null
    }
  }

  getOnCreateHanlder = () => {
    switch (this.props.model) {
      case EditInputObjectModel.Tags:
        return this.props.createTags
      case EditInputObjectModel.Categories:
        return this.props.createCategories
      default:
        return null
    }
  }

  render() {
    const {
      keyName,
      onItemSelected,
      placeholder,
      value,
      creatable,
      autoFocus,
      displayAttribute,
      customFilter,
      renderInputComponent,
    } = this.props

    return (
      <AutocompleteSearchSimpleObject
        keyName={keyName}
        onFetch={this.getOnFetchHanlder()}
        onCreate={creatable && this.getOnCreateHanlder()}
        placeholder={placeholder}
        onItemSelected={onItemSelected}
        renderInputComponent={renderInputComponent}
        value={value}
        autoFocus={autoFocus}
        displayAttribute={displayAttribute}
        customFilter={customFilter}
      />
    )
  }
}

const mapStateToProps = () => {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchTags,
      searchCategories,
      createTags,
      createCategories,
      searchCourses,
      searchUsers,
      searchProviders,
      searchUsersToShare,
      searchOrganizationalUnit,
      searchJobPosition,
      searchFacilitators,
      searchPublicExternalCourses,
      searchPlaces,
      searchInstitutions,
      searchCommissionPlaces,
      searchExternalReferencesReferents,
      searchExternalReferencesProviders,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteSearchSimpleObjectContainer)
