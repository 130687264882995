import React, { Component } from 'react'

import Logout from '../../components/session/Logout'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { logout } from '../../actions/user'

class LogoutContainer extends Component {
  handleLogout = () => {
    this.props.logout()
  }

  render() {
    return <Logout text={this.props.text} onLogoutClicked={this.handleLogout} />
  }
}

const mapStateToProps = state => {
  return {
    ...state.userReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer)
