import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from './HeaderHome'
import MyCurrentPlans from './MyCurrentPlans'

const styles = theme => {
  return {
    root: {
      position: 'relative',
      backgroundColor: '#101010',
      width: '100%',
      maxWidth: 'calc(100vw - 0%)',
      height: '100hv',
    },
    detail: {
      paddingTop: 50,
    },
  }
}

class CareerPlansDetail extends Component {
  render() {
    const {
      classes,
      detailCareerPlans,
      loadCarrerPlan,
      onShowCourse,
      onDeleteSharedCourse,
    } = this.props
    return (
      <div className={classes.root}>
        <Header
          title="Ruta de Aprendizaje"
          labelButton="Explorar otras rutas"
        />
        <main id="main" tabIndex={-1} className={classes.detail}>
          <MyCurrentPlans
            currentCareerPlans={detailCareerPlans}
            loadCarrerPlan={loadCarrerPlan}
            onShowCourse={onShowCourse}
            onDeleteSharedCourse={onDeleteSharedCourse}
          />
        </main>
      </div>
    )
  }
}

export default withStyles(styles)(CareerPlansDetail)
