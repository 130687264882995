import React from 'react'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import {
  goToSearch,
  goToUserProfile,
  goHome,
  goAdmin,
  goToExploreCategories,
  goToCareerPlans,
  goToExploreMoreCareerPlans,
  goToExternalCoursesNextEvents,
  goTo,
} from '../../actions/navigator'
import FrontNavBar from '../../components/ui/FrontNavBar'
import { FrontNavBarActionTypes } from '../../config/constants'
import { toggleMenu } from '../../actions/menuDrawer'
import { PLAYLISTS } from '../../config/routes'

const FrontNavBarContainer = props => {
  const { me, location } = props

  const onToggleMenu = () => {
    props.toggleMenu()
  }

  const handleActionClick = actionType => {
    switch (actionType) {
      case FrontNavBarActionTypes.Home:
        props.goHome()
        break
      case FrontNavBarActionTypes.Search:
        props.goToSearch()
        break
      case FrontNavBarActionTypes.Profile:
        props.goToUserProfile()
        break
      case FrontNavBarActionTypes.AdminHome:
        props.goAdmin()
        break
      case FrontNavBarActionTypes.Explore:
        props.goToExploreCategories()
        break
      case FrontNavBarActionTypes.CareerPlans:
        props.goToCareerPlans()
        break
      case FrontNavBarActionTypes.ExploreCareerPlans:
        props.goToExploreMoreCareerPlans()
        break
      case FrontNavBarActionTypes.NextEvents:
        props.goToExternalCoursesNextEvents()
        break
      case FrontNavBarActionTypes.Playlists:
        props.goTo(PLAYLISTS)
        break
      default:
        return false
    }
  }
  const externalCourses = useSelector(state => state.externalCoursesHomeReducer)
  const courses = {
    require: useSelector(state => state.courses.required),
    required: useSelector(state => state.courses.required),
    topTen: useSelector(state => state.courses.topTen),
    variablePrice: useSelector(state => state.courses.variablePrice),
    finished: useSelector(state => state.me.finished),
    inProgress: useSelector(state => state.me.inProgress),
    shared: useSelector(state => state.me.shared),
    featuredCategories: useSelector(state => state.featuredCategoriesReducer),
    hidden: useSelector(state => state.me.hidden),
    objectiveDplusI: useSelector(state => state.courses.objectiveDplusI),
  }

  return (
    <FrontNavBar
      onToggleMenu={onToggleMenu}
      onActionClick={handleActionClick}
      me={me.user}
      courses={courses}
      externalCourses={externalCourses}
      location={location}
    />
  )
}

const mapStateToProps = state => {
  return {
    me: { ...state.userReducer },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      goToSearch,
      goToUserProfile,
      goHome,
      goAdmin,
      toggleMenu,
      goToExploreCategories,
      goToCareerPlans,
      goToExploreMoreCareerPlans,
      goToExternalCoursesNextEvents,
      goTo,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontNavBarContainer)
