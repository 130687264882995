import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core'
import { itemAction, itemView } from '../../utils/AdminHelper'
import { AdminItemViewType } from '../../config/constants'
import classNames from 'classnames'
import Loading from '../ui/Loading'
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },

  actionsHeader: {
    minWidth: 180,
    maxWidth: 240,
    padding: 16,
    textAlign: 'center',
  },
  actionsCell: {
    padding: 16,
    textAlign: 'center',
    dispaly: 'flex',
    flexWrap: 'wrap',
    width: '10%',
  },
  colHeader: {
    minWidth: 80,
    padding: 16,
  },
  colHeaderExpanded: {
    minWidth: 320,
  },
  colCell: {
    textAlign: 'left',
    padding: 16,
  },
  colCellCenter: {
    textAlign: 'center',
  },
})

class AdminResourceTable extends Component {
  render() {
    const { classes, items, columns, getActions, forceNoActions, isLoading } = this.props
    return (
      <Paper className={classes.root}>
        {items && (
          <Table className={classes.table}>
            <TableHead key={'header'}>
              <TableRow>
                {columns.map((col, index) => {
                  const center = col.type === AdminItemViewType.Boolean
                  return (
                    <CustomTableCell
                      className={classNames(classes.colHeader, {
                        [classes.colHeaderExpanded]: col.expanded,
                        [classes.colCellCenter]: center,
                      })}
                      key={index}
                    >
                      {col.title}
                    </CustomTableCell>
                  )
                })}
                {forceNoActions ? null : (
                  <CustomTableCell className={classes.actionsHeader} key={'actions'}>
                    Acciones
                  </CustomTableCell>
                )}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <td colSpan="100%">
                <Loading dark fullWidth />
              </td>
            ) : (
              <TableBody>
                {items.map((item, index) => {
                  return (
                    <TableRow className={classes.row} key={index}>
                      {columns.map((col, subIndex) => {
                        const center = col.type === AdminItemViewType.Boolean
                        return (
                          <CustomTableCell
                            key={subIndex}
                            className={classNames(classes.colCell, {
                              [classes.colCellCenter]: center,
                            })}
                          >
                            {itemView(col, item)}
                          </CustomTableCell>
                        )
                      })}
                      {forceNoActions ? null : (
                        <CustomTableCell className={classes.actionsCell} key={'actions'}>
                          {getActions(item) &&
                            getActions(item).map((action, aidx) => {
                              return itemAction(item, action, aidx)
                            })}
                        </CustomTableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            )}
          </Table>
        )}
      </Paper>
    )
  }
}

export default withStyles(styles)(AdminResourceTable)
