import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    padding: '10px 0px',
  },
  wrapperSteps: {
    paddingBottom: 5,
    paddingTop: 40,
  },
  steps: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#ffffff',
  },
  percentage: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#ffffff',
  },
  progessBar: {
    width: '100%',
    height: 5,
    opacity: 0.42,
    backgroundColor: '#fff',
  },
  progessBarActive: {
    width: 0,
    //transition: 'width 300ms ease-out',
    position: 'relative',
    height: 5,
    bottom: 5,
  },
};

function ProgressStyles({
  percentage,
  steps: { current, total },
  classes,
  backgroundColorProgress,
}) {
  const stylePercentageActive = {
    width: `${percentage}%`,
    backgroundColor: backgroundColorProgress,
  };
  return (
    <Grid item xs={12} className={classes.container}>
      <Grid
        item
        xs={12}
        container
        justify="space-between"
        className={classes.wrapperSteps}
      >
        <Typography
          className={classes.steps}
        >{`${current}/${total}`}</Typography>
        <Typography
          className={classes.percentage}
        >{`Progreso ${Math.trunc(percentage)}%`}</Typography>
      </Grid>
      <div className={classes.progessBar}></div>
      <div
        className={classes.progessBarActive}
        style={stylePercentageActive}
      ></div>
    </Grid>
  );
}

ProgressStyles.propTypes = {
  percentage: PropTypes.number.isRequired,
  steps: PropTypes.shape({ current: PropTypes.number, total: PropTypes.number })
    .isRequired,
  classes: PropTypes.object.isRequired,
  backgroundColorProgress: PropTypes.string,
};

ProgressStyles.defaultProps = {
  backgroundColorProgress: '#ffffff',
};

const Progress = withStyles(styles)(memo(ProgressStyles, areEqual));

function areEqual(prevProps, nextProps) {
  /*
  retorna true si al pasar los nextProps a renderizar retorna
  el mismo resultado que al pasar los prevProps a renderizar,
  de otro modo retorna false
  */
  return false;
}

export default Progress;
