import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import { addSnackbar } from '../../../actions/snackbar'
import AdminResourceIndex from '../AdminResourceIndex'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  AdminItemViewType,
  CourseProgress,
	SnackbarType
} from '../../../config/constants'

const KEY_NAME = 'reports/nps_courses'

class AdminReportsNpsCoursesContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size, undefined)
  }

  getColumns = () => {
    let columns = [
      { title: 'ID curso', source: 'id' },
      { title: 'Nombre curso', source: 'name' },
      {
        title: 'Asociado a Ruta de Aprendizaje',
        source: 'career_plan',
        type: AdminItemViewType.Text,
      },
      { title: 'NPS score', source: 'nps_avg' },
      { title: 'Valoraciones totales', source: 'rates' },
      { title: 'Promotores', source: 'promoters' },
      { title: 'Neutros', source: 'neutral' },
      { title: 'Detractores', source: 'detractors' },
    ]

    return columns
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Courses,
        name: 'course.id',
        placeholder: 'Curso',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'title',
      },
      {
        title: 'Cantidad de valoraciones',
        type: EditInputType.Number,
        name: 'course.ratesCount',
        placeholder: 'Cantidad de valoraciones',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Number,
        name: 'course.npsScoreFrom',
        placeholder: 'NPS Desde',
        format: FiltersFormat.MayorIgual,
        queryName: 'course.npsScore',
      },
      {
        type: EditInputType.Number,
        name: 'course.npsScoreTo',
        placeholder: 'NPS Hasta',
        format: FiltersFormat.HastaNps,
        queryName: 'course.npsScore',
      },
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE NPS POR CURSO'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsNpsCoursesContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer
  if (paginatorState && paginatorState.user_courses && paginatorState.user_courses.items) {
    paginatorState.user_courses.items.map(course =>
      course.completed_at && course.completed_at.length
        ? (course.completed = CourseProgress.true)
        : (course.completed = CourseProgress.false),
    )
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsNpsCoursesContainer)
