import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import config from '../../config/index'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    footer: {
      backgroundColor: '#000000',
      minHeight: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      width: '100%',
      padding: '0 90px',
      [mediaLg]: {
        padding: '0 16px',
      },
    },
    divLine: {
      marginTop: 16,
      marginBottom: 16,
      height: 1,
      backgroundColor: '#333333',
      width: '100%',
    },
    grid: {
      width: '100%',
    },
    image: {
      float: 'right',
      marginTop: 8,
    },
    clickableImage: {
      width: '100%',
      float: 'left',
      marginTop: 8,
      cursor: 'pointer',
    },
    text: {
      fontSize: 12,
      color: 'white',
    },
  }
}

class Footer extends Component {
  render() {
    const { classes, goHome } = this.props

    return (
      <footer className={classes.footer} aria-hidden={true}>
        <div className={classes.content}>
          <Grid container className={classes.grid} spacing={8}>
            <Grid xs={6} item>
              <a className={classes.clickableImage} onClick={goHome} href={'#'} aria-label={'Inicio'}>
                <img
                  aria-hidden={true}
                  alt={'Inicio'}
                  src={config.BASE_URL + 'img/academia_white.png'}
                  width={100}
                />
              </a>
            </Grid>
            <Grid xs={6} item>
              <div className={classes.image}  aria-hidden={true}>
                <img
                  alt={'Efecto Santander'}
                  src={config.BASE_URL + 'img/efectosantander_white.png'}
                  width={120}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </footer>
    )
  }
}

export default withStyles(styles)(Footer)
