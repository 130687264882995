import React, { useRef, useState, useEffect, useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import HorizontalSliderArrow from './HorizontalSliderArrow'
import { HorizontalSlideArrowType } from '../../config/constants'
import classNames from 'classnames'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    title: {
      fontSize: 24,
      display: 'inline-block',
      color: '#fff',
      paddingTop: 16,
      [mediaLg]: {
        marginLeft: 16,
        fontSize: 16,
      },
    },
    zoomSlider: {
      gridColumn: '2 / 13',
      color: '#fff',
      '&__container::-webkit-scrollbar': {
        display: 'none',
      },
      '&_arrow': {},
      '&__container': {
        position: 'relative',
        scrollbarWidth: 'none',
        transition: 'transform 450ms',
        overflowY: 'hidden',
        overflowX: 'scroll',
        //whiteSpace: 'nowrap',
        display: 'flex',
        width: '100%',
        margin: 'auto',
        //gridTemplateColumns: 'repeat(20, 1fr)',
        columnGap: 0,
        '&:hover &--item': {
          opacity: 0.3,
          '&:hover': {
            transform: 'scale(1.25)',
            opacity: 1,
          },
        },
        '&:focus-within &--item': {
          opacity: 0.3,
          '&:focus-within': {
            transform: 'scale(1.25)',
            opacity: 1,
          },
        },
        '&:hover &--item:first-child:last-child': {
          paddingLeft: 125,
        },
        '&:hover &--item:first-child': {
          paddingLeft: 45,
        },
        '&:hover &--item:last-child': {
          paddingRight: 645,
        },
        '&:hover > *': {
          transform: 'translate3d(-45px, 0, 0)',
        },
        '&--item:hover ~ &--item': {
          transform: 'translate3d(45px, 0, 0)',
        },

        '&:focus-within &--item:first-child:last-child': {
          paddingLeft: 125,
        },
        '&:focus-within &--item:first-child': {
          paddingLeft: 45,
        },
        '&:focus-within &--item:last-child': {
          paddingRight: 645,
        },
        '&:focus-within > *': {
          transform: 'translate3d(-45px, 0, 0)',
        },
        '&--item:focus-within ~ &--item': {
          transform: 'translate3d(45px, 0, 0)',
        },
        '&--item': {
          position: 'relative',
          cursor: 'pointer',
          transition: 'all 450ms',
          transform: 'center left',
          paddingTop: 40,
          paddingBottom: 40,
          height: 180,
          minHeight: 0,
          [mediaLg]: {
            paddingTop: 16,
          },
          '&:not(:last-child)': {
            paddingRight: 4,
          },
          '&-image': {
            height: '100%',
          },
        },
      },
      [mediaLg]: {
        marginTop: 40,
      },
    },
    wrapper: {
      position: 'relative',
      '-ms-overflow-style': 'none',
    },
    arrow: {
      position: 'absolute',
    },
    arrowRight: {
      position: 'absolute',
      right: 0,
    },
  }
}

//t = current time
//b = start value
//c = change in value
//d = duration
const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}
const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

const HorizontalZoomSlider = ({
  key,
  items,
  title,
  customStyleTitle,
  icon = null,
  classes,
  itemCreator = () => null,
  itemWidth = 320,
  itemHeight = 180,
  ariaLabel,
  customHeader = null,
}) => {
  const slug = slugify(title)
  let myScrollContainerRef = useRef(null)
  const [arrowsState, updateArrowsState] = useState({ rightVisible: false, leftVisible: false })

  const updateArrows = useCallback(() => {
    const view = myScrollContainerRef.current
    const visibleWidth = view.clientWidth
    const totalWidth = view.scrollWidth
    const totalScrolled = view.scrollLeft
    const leftVisible = totalScrolled !== 0
    const rightVisible = visibleWidth + totalScrolled < totalWidth
    updateArrowsState({ rightVisible, leftVisible })
  }, [updateArrowsState, myScrollContainerRef])

  useEffect(() => {
    window.addEventListener('resize', updateArrows)
    return () => {
      window.removeEventListener('resize', updateArrows)
    }
  }, [updateArrows])

  useEffect(() => {
    const ref = myScrollContainerRef.current
    ref.addEventListener('scroll', updateArrows)
    return () => {
      ref.removeEventListener('scroll', updateArrows)
    }
  }, [updateArrows, myScrollContainerRef])

  useEffect(() => {
    if (items) {
      updateArrows()
    }
  }, [items, updateArrows])

  const getItems = useCallback(
    (items, key) => {
      return items.map((i, index) => {
        return (
          <div
            key={key + '-item-' + index}
            className={classes.zoomSlider + '__container--item'}
            style={{ width: itemWidth, minWidth: itemWidth, height: itemHeight }}
          >
            {itemCreator(i)}
          </div>
        )
      })
    },
    [classes, itemCreator, itemWidth, itemHeight],
  )

  const calcMaxDelta = useCallback(
    delta => {
      const view = myScrollContainerRef.current
      const totalScrolled = view.scrollLeft
      const variation = totalScrolled + delta
      const totalWidth = view.scrollWidth - view.clientWidth
      if (variation < 0) {
        return -totalScrolled
      } else if (variation > totalWidth) {
        return totalWidth - totalScrolled
      }
      return delta
    },
    [myScrollContainerRef],
  )

  const scrollLeft = useCallback(
    (element, change, duration) => {
      let start = element.scrollLeft,
        currentTime = 0,
        increment = 20
      const delta = calcMaxDelta(change)
      const animateScroll = () => {
        currentTime += increment
        element.scrollLeft = easeInOutQuad(currentTime, start, delta, duration)
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
      }
      animateScroll()
    },
    [calcMaxDelta],
  )

  const handleScrollRight = useCallback(() => {
    const view = myScrollContainerRef.current
    scrollLeft(view, 700, 400)
  }, [myScrollContainerRef, scrollLeft])

  const handleScrollLeft = useCallback(() => {
    const view = myScrollContainerRef.current
    scrollLeft(view, -500, 400)
  }, [myScrollContainerRef, scrollLeft])

  return (
    <section className={classes.zoomSlider} aria-label={ariaLabel} id={slug}>
      {(title || icon) && !customHeader && (
        <div>
          <Typography
            tabIndex={0}
            component={'h2'}
            className={`${classes.title} ${customStyleTitle}`}
            aria-label={'Carrusel: ' + title}
          >
            {icon}
            {title}
          </Typography>
        </div>
      )}
      {customHeader}
      <div className={classes.wrapper}>
        {arrowsState.leftVisible && (
          <HorizontalSliderArrow
            role={'button'}
            aria-label={'flecha izquierda'}
            className={classes.arrow}
            type={HorizontalSlideArrowType.left}
            onClick={handleScrollLeft}
          />
        )}
        {arrowsState.rightVisible && (
          <HorizontalSliderArrow
            role={'button'}
            aria-label={'flecha derecha'}
            className={classNames(classes.arrow, classes.arrowRight)}
            type={HorizontalSlideArrowType.right}
            onClick={handleScrollRight}
          />
        )}
        <div className={classes.zoomSlider + '__container'} ref={myScrollContainerRef}>
          {getItems(items, key)}
        </div>
      </div>
    </section>
  )
}

export default withStyles(styles)(HorizontalZoomSlider)
