import { removeDuplicates } from '../utils/OthersHelper'
import {
  INFINITE_INIT,
  INFINITE_PAGINATE_LOAD_NEXT,
  INFINITE_PAGINATE_END,
  INFINITE_PAGINATE_FAILED,
  INFINITE_PAGINATE_UPDATE_ITEM,
  INFINITE_RESET,
  INFINITE_PAGINATE_UPDATE_ITEMS,
} from './index'

export function init(key) {
  return async dispatch => {
    dispatch({ type: INFINITE_INIT, key })
  }
}

export function paginateNext(key, request, apply = a => a) {
  return async dispatch => {
    dispatch({
      type: INFINITE_PAGINATE_LOAD_NEXT,
      key,
    })
    try {
      const response = await request(dispatch)
      dispatch({
        type: INFINITE_PAGINATE_END,
        key,
        payload: {
          items: apply(response.data),
          meta: response.metadata,
        },
      })
    } catch (e) {
      dispatch({ type: INFINITE_PAGINATE_FAILED, key, payload: e })
    }
  }
}

export function resetPaginator(key) {
  return async dispatch => {
    dispatch({
      type: INFINITE_RESET,
      key,
    })
  }
}

export function updateItem(key, item) {
  return async dispatch => {
    dispatch({
      type: INFINITE_PAGINATE_UPDATE_ITEM,
      key,
      payload: { item },
    })
  }
}

export function updateItems(key, items) {
  return async dispatch => {
    dispatch({
      type: INFINITE_PAGINATE_UPDATE_ITEMS,
      key,
      payload: { items },
    })
  }
}
