import React, { Component } from 'react'
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Icon,
  Collapse,
} from '@material-ui/core'
import { menuItems } from './menuOptions'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { isOpenBankAdmin, isOpenBankReporting, isOpenBankUser } from './../../../utils/AdminHelper'

const drawerWidth = 230

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiListItemText: {
      root: {
        padding: '0px 0px',
      },
    },
  },
})

const styles = theme => ({
  docked: {
    height: '100%',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    height: '100%',
    minHeight: '100%',
    paddingTop: '16px',
  },

  drawerContainer: {
    height: '100%',
    minHeight: '100%',
  },

  toolbar: theme.mixins.toolbar,

  row: {
    display: 'flex',
    justifyContent: 'left',
  },

  avatar: {
    marginLeft: '24px',
    marginBottom: 16,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  submenuLabel: {
    paddingLeft: '5px !important',
  },
})

class MenuDrawer extends Component {
  state = {
    open: true,
  }

  handleClick = item => {
    if (item.submenu) {
      const { action } = item
      this.setState(state => ({ [action]: !state[action] }))
    } else {
      this.props.onMenuItemClick(item)
    }
  }

  handleDrawerToggle = () => {
    const { onToggleMenu } = this.props
    onToggleMenu()
  }

  isAllowed = item => {
    const { me } = this.props

    if (isOpenBankUser(me.user) && (
      (isOpenBankAdmin(me.user) && item.allowed_role_admin_ob) ||
      (isOpenBankReporting(me.user) && item.allowed_role_reporting_ob)
    )) return true;

    if (!isOpenBankUser(me.user) && (
      me.user.is_admin ||
      item.allowed_role_reporting && me.user.is_reporting
    )) return true

    return false
  }

  renderMenu = (item, level = 0, index = 0, parentKey = '') => {
    const state = this.state
    const itemKey = parentKey + '-i-' + index + '-l-' + level
    const header = this.isAllowed(item) ? (
      <MuiThemeProvider theme={theme} key={'list-item-theme' + index}>
        <ListItem
          button
          style={{ paddingLeft: 16 + 5 * level }}
          key={'list-item-' + itemKey}
          onClick={() => {
            this.handleClick(item)
          }}
        >
          {item.icon && (
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
          )}
          <ListItemText primary={level > 0 ? item.title : item.title.toUpperCase()} />
          {item.submenu ? state[item.action] ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItem>
      </MuiThemeProvider>
    ) : null

    const submenu = item.submenu ? (
      <Collapse
        in={state[item.action]}
        timeout="auto"
        unmountOnExit
        key={'list-item' + itemKey + '-collapse'}
      >
        <List component="div" disablePadding>
          {item.items.map((subItem, subIndex) =>
            this.renderMenu(subItem, level + 1, subIndex, itemKey),
          )}
        </List>
      </Collapse>
    ) : null

    return [header, submenu]
  }

  renderDrawer = () => {
    const { classes } = this.props
    return (
      <div className={classes.drawerContainer}>
        <div className={classes.toolbar} />
        <div className={(classes.row, classes.avatar)} />
        <List>{menuItems.items.map((item, index) => this.renderMenu(item, 0, index))}</List>
      </div>
    )
  }

  render() {
    const { classes, mobileOpen, customClasses, customContent } = this.props

    return (
      <div>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: (classes.drawerPaper, customClasses && customClasses.drawerPaper),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {customContent || this.renderDrawer()}
          </Drawer>
        </Hidden>
        <Hidden
          smDown
          className={(customClasses && customClasses.drawerContainer) || classes.drawerContainer}
          implementation={'css'}
        >
          <Drawer
            variant={mobileOpen ? 'permanent' : 'temporary'}
            anchor="left"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              docked: classes.docked,
              paper:
                customClasses && customClasses.drawerPaper
                  ? customClasses.drawerPaper
                  : classes.drawerPaper,
            }}
          >
            {customContent || this.renderDrawer()}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    me: { ...state.userReducer },
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(MenuDrawer)
