/* eslint-disable no-extra-boolean-cast */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextField } from '@material-ui/core'
import '../../../styles.css'

const trimOptionsText = value =>
  value
    ?.replaceAll('~', '-')
    .split('*')
    .map((string, index) => (!!(index % 2) ? string.trim() : string))
    .join('*')

const AsterisksQuestionOption = ({ question, options_text, handler, quizEditingBlocked }) => {
  const [editing, setEditing] = useState(false)
  const thisInput = useRef(null)

  const handleOptionEdit = useCallback(
    (options_text = undefined) => {
      handler(question, options_text)
    },
    [options_text],
  )

  useEffect(() => {
    if (!editing && options_text !== undefined) {
      thisInput.current.firstChild.firstChild.blur()
    }
  }, [editing])

  return (
    <div className="row">
      <div
        className="row fill-in-the-blanks"
        style={{
          backgroundColor: editing ? '#ececec' : undefined,
          height: 'fit-content',
        }}
      >
        <TextField
          fullWidth
          ref={thisInput}
          defaultValue={options_text}
          onClick={e => {
            e.stopPropagation()
          }}
          multiline
          value={editing ? undefined : options_text}
          placeholder="Complete el campo"
          readOnly={!editing || quizEditingBlocked}
          onFocus={() => {
            if (!quizEditingBlocked) setEditing(true)
          }}
          onBlur={({ target: { value } }) => {
            if (!quizEditingBlocked) {
              setEditing(false)
              handleOptionEdit(trimOptionsText(value))
            }
          }}
          onKeyDown={({ target: { value }, key }) => {
            if (!quizEditingBlocked) {
              if (key === 'Enter') {
                setEditing(false)
                handleOptionEdit(trimOptionsText(value))
              }
            }
          }}
        />
      </div>
    </div>
  )
}

export default AsterisksQuestionOption
