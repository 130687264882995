import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormLabel, FormHelperText, Link } from '@material-ui/core'
import { itemEdit, itemView } from '../../utils/AdminHelper'
import { generateFileObject } from '../../utils/ConvertToBase64'

const styles = () => ({
  formControl: {
    margin: 8,
    width: '100%',
  },
  formLink: {
    margin: 8,
    width: '100%',
    color: '#1ed1a8',
  },
  label: {
    color: '#3c3c3c',
    marginTop: 8,
    marginBottom: 8,
  },
  error: {
    color: '#9e0000',
  },
})

class ResourceFormInput extends Component {
  // getName = (url) => {
  //   let fileName = url.replace(/[\#\?].*$/, '');
  //   return fileName;
  // };

  handleOnChange = async (name, value) => {
    const { onAttributeChange } = this.props

    if (
      (name === 'image' || name === 'url') &&
      value &&
      value.acceptedFiles &&
      value.acceptedFiles.length
    ) {
      const updatedValue = await Promise.all(value.acceptedFiles.map(f => generateFileObject(f)))
      onAttributeChange(name, {
        ...value,
        acceptedFiles: updatedValue,
      })
    } else if (value == null || value === '') {
      onAttributeChange(name, '')
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      onAttributeChange(name, { ...value })
    } else {
      onAttributeChange(name, value)
    }
  }

  render() {
    const { template, classes, customClasses, editedValue, data, error } = this.props
    return (
      <FormControl
        id={template?.editConfig?.id}
        className={
          template && template.editConfig && template.editConfig.className
            ? template.editConfig.className
            : customClasses
            ? customClasses.resourceFormInputContainer
            : classes.formControl
        }
        style={{ paddingTop: '1rem' }}
      >
        {template.source == 'url' && data.url && (
          <Link className={classes.formLink} href={data.url}>
            Ver Archivo Actual
          </Link>
        )}

        {template.title && <FormLabel className={classes.label}>{template.title}</FormLabel>}

        {template.editable
          ? itemEdit(template.editConfig, editedValue, this.handleOnChange)
          : itemView(template, data)}
        {error && (
          <FormHelperText className={classes.error} id="description-error-text">
            {error}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}
export default withStyles(styles)(ResourceFormInput)
