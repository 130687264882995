import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core/'
import EventListenerWrapper from './EventListenerWrapper'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    tabItem: {
      display: 'inline-flex',
      paddingRight: 10,
      marginTop: 8,
      fontFamily: 'Open Sans',
      fontSize: 15,
      color: '#757575',
      '&:hover': {
        cursor: 'pointer',
        color: '#fff',
        textDecoration: 'underline',
        textDecorationColor: '#fff',
      },
      '&.active': {
        color: '#fff',
      },
      [mediaLg]: {
        marginLeft: '20px !important',
      },
    },
  }
}

function TabBar({ classes, tabs, onChageTab, tabProps, ...props }) {
  return (
    <Grid {...props} container justify="center">
      <Grid item xs={10} md={10} lg={10}>
        {tabs.map(({ title, Icon = false, active, visible }, index, array) => {
          const tabActive = array[index].active
          if (visible) {
            return (
              <EventListenerWrapper
                TagType={'p'}
                role={'link'}
                onEnter={() => onChageTab(index, array)}
                className={`${classes.tabItem} ${tabActive && 'active'}`}
                key={title}
                onClick={() => onChageTab(index, array)}
                {...tabProps}
              >
                {Icon && <Icon />}
                {title}
              </EventListenerWrapper>
            )
          } else {
            return null
          }
        })}
      </Grid>
    </Grid>
  )
}

TabBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TabBar)
