import React, {} from 'react';
import { withStyles } from '@material-ui/core/styles';
import FullScreenDialog from '../ui/FullScreenDialog'

const styles = () => ({
  iframeContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  hide: {
    display: 'none',
  },
});

const Iframe = ({ activity, open, onClose }) => {

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
    >
      <iframe
        width={'100%'}
        height={'100%'}
        title={activity.name}
        src={activity.url}
        tabIndex={0}
        allowFullScreen={'allowFullScreen'}
        frameBorder="0"
        style={{ display: 'block', margin: 'auto' }}
      />
    </FullScreenDialog>
  );
};

export default withStyles(styles)(Iframe);
