/* eslint-disable no-case-declarations */
import {
  CREATE_REQUEST_START,
  CREATE_REQUEST_END,
  CREATE_REQUEST_FAILED,
} from '../actions';

const initialState = {
  query: '',
  items: [],
  key: 'default',
};

const states = {};

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key];
  }
  states[key] = { ...initialState, key };
  return states[key];
}

export default (state = initialState, action) => {
  const key = action.key || state.key || 'default';
  const currentState = getStateFor(key);
  switch (action.type) {
    case CREATE_REQUEST_FAILED:
      states[key] = {
        ...currentState,
        items: [],
        query: '',
      };
      return states;
    case CREATE_REQUEST_START:
      const { query } = action.payload;
      states[key] = {
        ...currentState,
        items: [],
        query,
      };
      return states;
    case CREATE_REQUEST_END: {
      const { query, items } = action.payload;
      if (query === state[key].query) {
        states[key] = {
          ...currentState,
          items,
        };
      } else {
        states[key] = { ...currentState };
      }
      return states;
    }
    default:
      states[key] = { ...currentState };
      return states;
  }
};
