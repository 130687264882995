import validator from 'validator'

const TITLE_LENGTH = {
  max: 80,
  min: 2,
}

const DESCRIPTION_LENGTH = {
  max: 600,
  min: 2,
}

const validateScorm = form => {
  let error = false
  const errors = {
    name: '',
    description: '',
    external: '',
    target_type: '',
  }

  if (!form.external) {
    errors.external = 'Debes crear el contenido scorm'
    error = true
  }

  if (!form.name || validator.isEmpty(form.name.trim())) {
    errors.name = 'Debes completar este campo'
    error = true
  } else if (
    !validator.isLength(form.name.trim(), {
      min: TITLE_LENGTH.min,
      max: TITLE_LENGTH.max,
    })
  ) {
    errors.name = `Este campo debe tener entre ${TITLE_LENGTH.min} y ${TITLE_LENGTH.max} caracteres.`
    error = true
  }

  if (!form.description || validator.isEmpty(form.description.trim())) {
    errors.description = 'Debes completar este campo'
    error = true
  } else if (
    !validator.isLength(form.description.trim(), {
      min: DESCRIPTION_LENGTH.min,
      max: DESCRIPTION_LENGTH.max,
    })
  ) {
    errors.description = `Este campo debe tener entre ${DESCRIPTION_LENGTH.min} y ${DESCRIPTION_LENGTH.max} caracteres.`
    error = true
  }

  if (!form.target_type || validator.isEmpty(form.target_type.toString())) {
    errors.target_type = 'Debes seleccionar un item'
    error = true
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!form.hasOwnProperty('trackable')) {
    errors.trackable = 'Debes seleccionar un item'
    error = true
  }

  return { errors: errors, isValid: !error }
}

export default validateScorm
