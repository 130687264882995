/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  input: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    marginTop: 2,
    fontWeight: 400,
    fontSize: 14,
  },
  container: {
    width: '100%',
  },
  root: {
    border: '1px solid black',
  },
  helptext: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    marginTop: 2,
    fontWeight: 400,
    fontSize: 10,
  },
})

class TextInput extends Component {
  handleOnChange = event => {
    const { name, onChange } = this.props
    const value = event.target.value
    onChange(name, value)
  }

  render() {
    const {
      name,
      value,
      placeholder,
      label,
      type = 'text',
      classes,
      containerClass,
      multiline,
      autoFocus = false,
      helpText,
      inputRef,
      variant,
      onPaste,
      onKeyDown,
      InputProps,
      disabled = false,
      ...others
    } = this.props

    delete others['onChange']

    return (
      <div className={`${classes.container} ${containerClass}`}>
        <TextField
          inputProps={{
            className: classes.input,
            ...others,
          }}
          inputRef={inputRef}
          variant={variant || (multiline ? 'outlined' : 'standard')}
          rows={multiline || '1'}
          name={name}
          type={type}
          fullWidth={true}
          onChange={this.handleOnChange}
          value={value}
          placeholder={placeholder}
          label={label}
          multiline={!!multiline}
          autoFocus={autoFocus}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          InputProps={InputProps}
          disabled={disabled}
        />
        {helpText && <Typography className={classes.helptext}>{helpText}</Typography>}
      </div>
    )
  }
}

export default withStyles(styles)(TextInput)
