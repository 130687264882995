import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Progress from './Progress'
import EventListenerWrapper from '../ui/EventListenerWrapper'

const styles = () => {
  return {
    main: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      margin: '0px !important',
      width: '100%',
      justifyContent: 'center',
    },
    item: {
      position: 'relative',
      width: 216.3,
      height: 108.5,
      padding: 16,
      backgroundColor: '#838282',
      marginRight: 10,
      marginBottom: 10,

      transition: 'transform 300ms linear',
      transitionProperty: 'transform',
      transitionDuration: '300ms',
      transitionTimingFunction: 'linear',
      transitionDelay: '0s',
      '&:hover': {
        transform: 'scale(1.1)',
        cursor: 'pointer',
      },
    },
    titleHeaderMyCareerPlans: {
      position: 'absolute',
      marginBottom: 15,
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: '#ffffff',
    },
    flag: {
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderTopWidth: 0,
      borderRightWidth: 17,
      borderBottomWidth: 17,
      borderLeftWidth: 0,
      position: 'absolute',
      transition: 'all 300ms linear',
      top: 0,
      right: 0,
      borderColor: 'transparent',
    },
  }
}

const GridExplorerPlansProgressWithStyles = ({
  classes,
  plans,
  bgButtonActive,
  onClickNavigateAllCareerPlans,
}) => {
  let porcentaje = (current, total) => {
    return (current / total) * 100
  }
  return (
    <section className={classes.main}>
      {plans.map((item, index) => {
        return (
          <EventListenerWrapper
            tabIndex={0}
            role={'link'}
            aria-label={item.career_plan.name}
            className={classes.item}
            key={`${item.titleHeaderMyCareerPlans}-${index}`}
            onClick={() => onClickNavigateAllCareerPlans(item.career_plan.id)}
            onEnter={() => onClickNavigateAllCareerPlans(item.career_plan.id)}
          >
            <div className={classes.flag} style={{ borderRightColor: bgButtonActive }}  />

            <Typography  component={'h2'} className={classes.titleHeaderMyCareerPlans}>
              {item.career_plan.name}
            </Typography>
            <Progress
              percentage={porcentaje(item.user_courses_done, item.career_plan.total_courses)}
              steps={{ current: item.user_courses_done, total: item.career_plan.total_courses }}
              backgroundColorProgress={bgButtonActive}
            />
          </EventListenerWrapper>
        )
      })}
    </section>
  )
}

const GridExplorerPlansProgress = withStyles(styles)(GridExplorerPlansProgressWithStyles)

export default GridExplorerPlansProgress
