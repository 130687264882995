import React from 'react';
import Autosuggest from 'react-autosuggest';
import { TextField, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function defaultRenderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      autoFocus={true}
      className={classes.textField}
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        type: 'search',
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

const styles = (theme) => ({
  container: {
    position: 'relative',
    float: 'left',
    width: '100%',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 50,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    backgroundColor: '#ffffff',
    maxHeight: 300,
    overflow: 'scroll',
  },
  divider: {
    height: theme.spacing(2),
  },
  input: {
    color: '#7f7f7f',
    float: 'left',
    padding: 8,
    width: '100%',
    fontWeight: 400,
    fontFamily: 'Open Sans',
    fontSize: 14,
    height: '16px',
  },

  inputContainer: {
    minWidth: '100%',
  },

  componentContainer: {
    float: 'left',
    flexGrow: 1,
    width: '100%',
  },
});

class AutocompleteSearch extends React.Component {

  handleChange = (event, { newValue, method }) => {
    const { keyName, model, onChange, customFilter } = this.props;
    if(newValue && method === 'type') {
      onChange(newValue, customFilter);
    }
  };

  render() {
    const {
      keyName,
      placeholder,
      classes,
      onSuggestionSelected,
      onSuggestionsFetchRequested,
      onSuggestionHighlighted,
      onSuggestionsClearRequested,
      renderSuggestion,
      getSuggestionValue,
      query,
      suggestions,
      autoFocus,
      renderInputComponent = defaultRenderInputComponent
    } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: suggestions,
      onSuggestionsFetchRequested: onSuggestionsFetchRequested,
      onSuggestionsClearRequested: onSuggestionsClearRequested,
      onSuggestionHighlighted: onSuggestionHighlighted,
      onSuggestionSelected: onSuggestionSelected,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <div className={classes.componentContainer}>
        <Autosuggest
          key={keyName}
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: placeholder,
            value: query,
            onChange: this.handleChange,
            autoFocus: autoFocus,
          }}
          theme={{
            input: classes.inputContainer,
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AutocompleteSearch);
