import { createMuiTheme } from '@material-ui/core';

export const login_theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#e2001a',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: 16,
        color: '#ffffff',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 200,
        color: '#ffffff',
        '&$focused': {
          color: '#ffffff',
        },
      },
    },
    MuiInputBase: {
      input: {
        color: '#fff',
      },
      root: {
        color: '#fff',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 300,
        borderBottom: '0.5px solid rgba(255, 255, 255,0.7)',
        '&$focused': {
          borderBottom: '0.5px solid rgba(255, 255, 255,0.7)',
        },
        "&$disabled": {
          color: '#fff',  
        }
      },
    },
    MuiIconButton: {
      root: {
        color: '#fff',
      },
    },
  },
});
