export const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = function () {
    const encoded = reader.result.replace(/^data:(.*,)?/, '');
    resolve(encoded);
  };
  reader.onerror = function (error) {
    reject(error);
  };
  reader.readAsDataURL(file);
});

export const getFileAsDataUrl = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = function () {
    const encoded = reader.result;
    resolve(encoded);
  };
  reader.onerror = function (error) {
    reject(error);
  };
  reader.readAsDataURL(file);
});

export const getUrlAsDataUrl = (url) => new Promise((resolve, reject) => {
  const request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.responseType = 'blob';
  request.onload = function () {
    const reader = new FileReader();
    reader.readAsDataURL(request.response);
    reader.onload = function (e) {
      resolve(e.target.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  };
  request.send();
});
