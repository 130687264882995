import React, { Suspense, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { loadAllActivities } from '../../../../actions/activities';
import { appLoadToken } from '../../../../actions/app';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';

const styles = {
  root: {
    padding: '2em 0',
    position: 'absolute'
  },
  wrapperButtons: {

    display: 'flex',
    flexDirection: 'row',
    justifySelf: 'center',
    marginTop: '2em'

  },
  text: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    marginBottm: '2em'
  }


};

class ActivityModalSelect extends Component {

  state = {
    disabled: true,
    setOpen: true,
    actividad: 'No hay una actividad Seleccionada'
  };

  componentDidMount() {
    const { loadAllActivities, appLoadToken } = this.props;
    appLoadToken().then(() => {
      loadAllActivities(this.props.activity.blockId);
    });
  }

  handleRowClick = (rowIndex, { dataIndex: i }) => {
    this.props.results.isValid = true;
    this.setState({ disabled: false });
    this.setState({ actividad: `Actividad Seleccionada: ${this.props.items[i].name}` })
    this.props.onChange({
      ...this.props.activity,
      ...this.props.items[i],
      type: this.props.activity.type,
      selectedType: this.props.items[i].type,
      activityId: this.props.items[i].id,
    });
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: '#FFFFFF',
          },
          paper: {
            boxShadow: 'none',
          },
        },
      },
    });

  render() {
    const { classes } = this.props;
    const _data = this.props.items;
    const columns = [
      { label: 'Id', name: 'id' },
      { label: 'Nombre', name: 'name' },
      { label: 'Cursos', name: 'courses' },
    ];
    const options = {
      selectableRows: false,
      selectableRowsHeader: true,
      download: false,
      print: false,
      filter: false,
      viewColumns: false,
      onRowClick: this.handleRowClick,
      textLabels: {
        body: {
          noMatch: 'No se cargaron actividades',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        pagination: {
          next: 'Siguiente página',
          previous: 'Anterior página',
          rowsPerPage: 'registros por página:',
          displayRows: 'of',
        },
        toolbar: {
          search: 'buscar',
          downloadCsv: 'Download CSV',
          print: 'Print',
          viewColumns: 'View Columns',
          filterTable: 'Filter Table',
        },
        filter: {
          all: 'Todos',
          title: 'Filtros',
          reset: 'Borrar',
        },
        viewColumns: {
          title: 'Mostrar Columnas',
          titleAria: 'Mostrar/Ocultar Columnas',
        },
        selectedRows: {
          text: 'Actidad/es Seleccionada/as',
          delete: 'borrar',
          deleteAria: 'Borrar selección',
        },
      },
    };

    return (
      <>
        <Suspense>
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={'Seleccionar Actividad'}
              data={_data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
          <Grid container className={classes.root}>
            <Grid item={true} xs={4} className={classes.text}>{this.state.actividad}</Grid>
            <Grid item={true} xs={4} className={classes.wrapperButtons}>
              <Button
                type="button"
                className={classes.marginBoton}
                variant="contained"

                disabled={this.state.disabled}
                color="primary"
                onClick={() => this.props.onSubmit()}
              >
                GUARDAR
            </Button>
              <Button type="button" variant="contained"
                onClick={() => this.props.onCancel()}
              >
                CANCELAR
            </Button>
            </Grid>
          </Grid>
        </Suspense>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activitiesReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadAllActivities, appLoadToken }, dispatch);
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ActivityModalSelect);
