/* eslint-disable no-case-declarations */
import {
  CALENDAR_READ_END, CALENDAR_READ_FAILED,
  CALENDAR_READ_START,
} from '../actions';

const initialState = {
  error: null,
  loading: false,
  events: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CALENDAR_READ_END:
      const { events } = action.payload;
      return {
        loading: false,
        events,
        error: null,
      };
    case CALENDAR_READ_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case CALENDAR_READ_FAILED:
      const { message } = action.payload;
      return {
        events: [],
        error: message,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
