import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core';
import AdminResourceTable from '../AdminResourceTable';
import {
  AdminItemViewType,
  ActivitiesTypesList,
} from '../../../config/constants';

const styles = {
  activityName: {
    color: '#434343',
    fontWeight: 700,
    fontSize: 24,
  },
  description: {
    color: '#434343',
    maxWidth: '64%',
    textAlign: 'center',
  },
  headerMainContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#434343',
  },
  smallTitle: {
    color: '#434343',
    fontWeight: 700,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  paper: {
    width: '80%',
    maxWidth: 'none',
  },
};

class MilestoneModal extends Component {
  state = {
    content: null,
    results: {
      isValid: false,
      errors: {},
    },
    requiredActivities: [],
  };

  getColumns = () => {
    const { requiredActivities } = this.state;
    return [
      {
        title: (
          <Switch
            value="requiredAll"
            onChange={this.handleSelectAll}
            checked={requiredActivities.length > 0}
          />
        ),
        source: 'required',
        type: AdminItemViewType.Custom,
        renderItem: (item) => (
          <Switch
            value="required"
            checked={requiredActivities.indexOf(item.id) > -1}
            onChange={() => this.updateRequired(item)}
          />
        ),
      },
      {
        title: 'Actividad',
        source: 'name',
      },
      { title: 'Bloque', source: 'block' },
      { title: 'Tipo', source: 'type' },
    ];
  };

  componentWillReceiveProps(newProps) {
    if (
      newProps.milestoneActivities &&
      newProps.milestoneActivities.required_activities &&
      newProps.milestoneActivities.required_activities.length
    ) {
      const requiredActivities = newProps.milestoneActivities.required_activities.reduce(
        (accum, value) => {
          if (value.required) accum.push(value.id);
          return accum;
        },
        []
      );
      this.setState({ requiredActivities });
    }
  }

  updateRequired = (item) => {
    let { requiredActivities } = this.state;
    if (requiredActivities.indexOf(item.id) > -1) {
      requiredActivities = requiredActivities.filter((id) => id !== item.id);
    } else {
      requiredActivities.push(item.id);
    }
    this.setState({ requiredActivities });
  };

  handleSelectAll = () => {
    let { requiredActivities } = this.state;
    const { milestoneActivities } = this.props;

    if (requiredActivities.length > 0) {
      this.setState({ requiredActivities: [] });
    } else {
      milestoneActivities &&
        milestoneActivities.required_activities &&
        milestoneActivities.required_activities.map((activity) => {
          return requiredActivities.push(activity.id);
        });
      this.setState({ requiredActivities });
    }
  };

  handleSubmit = () => {
    const { milestoneActivities } = this.props;
    this.props.onSubmit({
      requiredActivities: this.state.requiredActivities,
      id: milestoneActivities.id,
    });
    this.props.onClose();
  };

  handleCancel = () => {
    this.props.onClose();
  };

  getMilestoneActivities = () => {
    const { milestoneActivities } = this.props;
    if (milestoneActivities && milestoneActivities.required_activities) {
      return milestoneActivities.required_activities.map((activity) => {
        return {
          id: activity.id,
          name: activity.name,
          block: activity.block_activities[0].block.title,
          // Add a .join('') to remove commas from array returned
          type: ActivitiesTypesList.map((item) =>
            activity.type === item.type ? item.description : null
          ).join(''),
        };
      });
    }
    return [];
  };

  render() {
    const { classes, milestone } = this.props;

    return (
      <Dialog
        open={milestone !== null}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle>
          <div className={classes.topBar}>
            <Typography className={classes.smallTitle}>CREAR HITO</Typography>
            <Tooltip title="CERRAR">
              <IconButton onClick={this.handleCancel}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.headerMainContentContainer}>
            <Typography className={classes.activityName}>{`Actividad: ${
              milestone ? milestone.name : ''
              }`}</Typography>
            <Typography className={classes.description}>
              Marca las actividades que quieres que complete el usuario antes de
              realizar esta actividad
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <AdminResourceTable
            items={this.getMilestoneActivities()}
            forceNoActions
            columns={this.getColumns()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit}>Guardar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MilestoneModal);
