import { Radio, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { COLOR_KEYS } from '../../../../../../config/constants'

const TrueOrFalseOption = ({ option, handler, question, key, quizEditingBlocked }) => {
  const [value, setValue] = useState()
  const radioColor = value ? COLOR_KEYS.CORRECT : COLOR_KEYS.INCORRECT

  useEffect(() => {
    setValue(option.options_text === 'Verdadero')
  }, [option])

  return (
    <div
      key={key}
      className="row"
      style={{ alignItems: 'center', cursor: quizEditingBlocked ? 'unset' : 'pointer' }}
      onClick={() => {
        if (!quizEditingBlocked) handler(question, option.options_text === 'Verdadero')
      }}
    >
      <Radio
        checked={!!option.correct_answer}
        style={{ color: radioColor, cursor: quizEditingBlocked ? 'unset' : 'pointer' }}
      />
      <Typography>{option.options_text}</Typography>
    </div>
  )
}

export default TrueOrFalseOption
