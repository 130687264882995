import { KEY_KEYS } from '../constants'

const onEnterKey = (event, action) => {
  if (event.key === KEY_KEYS.ENTER) {
    event.preventDefault()
    event.stopPropagation()
    action()
  }
}

export default onEnterKey
