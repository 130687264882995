import React, { Component } from 'react'
import { Chip } from '@material-ui/core'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { text_chip_theme } from '../../themes/text_chip_theme'
import Avatar from '@material-ui/core/Avatar'
import EventListenerWrapper from './EventListenerWrapper'

const styles = theme => ({
  root: {
    border: 'none',
  },
  tag: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 28,
    marginTop: 4,
    marginRigth: 4,
    padding: 9,
  },
  textGd: {
    fontSize: 9,
  },
})
class TextUserChip extends Component {
  render() {
    const { text, className, onDeleteChip, classes, avatarText } = this.props
    return (
      <MuiThemeProvider theme={text_chip_theme}>
        <EventListenerWrapper
          className={classes.tag}
          onEnter={() => onDeleteChip()}
          tabIndex={0}
          aria-label={text + ' presiona enter para eliminar el elemento'}
        >
          <Chip
            aria-hidden={true}
            variant="outlined"
            avatar={<Avatar aria-hidden={true}>{avatarText}</Avatar>}
            classes={{ root: classes.root }}
            label={text}
            onDelete={() => onDeleteChip()}
            className={className}
          />
        </EventListenerWrapper>
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(TextUserChip)
