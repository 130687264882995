/* eslint-disable react/no-deprecated */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CourseFormBlockContent } from '../../../components/admin/course'
import { addSnackbar } from '../../../actions/snackbar'
import { withRouter } from 'react-router-dom'
import Loading from '../../../components/ui/Loading'

import {
  createActivity,
  createActivityBlock,
  createResource,
  updateResource,
  getResource,
  getResources,
  silentGetResources,
  deleteResource,
  createQuizTrivia,
  updateQuizTrivia,
} from '../../../actions/admin'

import { SnackbarType, CourseStatusValue, ActivityType } from '../../../config/constants'
import { goTo } from '../../../actions/navigator'
import { ADMIN_COURSES, ADMIN_VIEW, router } from '../../../config/routes'
import { loadQuestionsTypes } from '../../../actions/quizTrivia'
import { appLoadToken } from '../../../actions/app'

const typesForFileUpdate = [ActivityType.File]

class AdminCoursesBlockFormContainer extends Component {
  state = {
    newId: -1,
    basicData: {},
    loading: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {}
    if (!this.props.item && nextProps.item) {
      newState.basicData = nextProps.item
    }
    if (Object.keys(newState).length) {
      this.setState(newState)
    }
  }

  fetchCourse = async () => {
    const { type, getResource, getResources, keyName, params } = this.props
    getResource(keyName, type, params.id)
    getResources(`${type}/${params.id}/blocks`, keyName)
  }

  onLoadQuestionsTypes = () => {
    const { loadQuestionsTypes, appLoadToken } = this.props
    appLoadToken().then(() => {
      loadQuestionsTypes()
    })
  }

  componentDidMount() {
    this.fetchCourse()
    this.fetchBlocksList()
    this.onLoadQuestionsTypes()
  }

  fetchBlocksList = async () => {
    const { type, silentGetResources, keyName, params } = this.props
    return silentGetResources(`${type}/${params.id}/blocks`, keyName)
  }

  fetchActivitiesList = async id => {
    const { getResource, keyName, params } = this.props
    getResource(
      `${keyName}-milestone-activities`,
      'course',
      `${params.id}/activities/${id}/required_activities`,
    )
  }

  handleSubmitBlock = async data => {
    const { addSnackbar, createResource, updateResource, type } = this.props
    const { basicData } = this.state

    try {
      if (data.id) {
        await updateResource(`${type}/${basicData.id}/blocks`, data.id, data)
        await this.fetchBlocksList()
        addSnackbar('Bloque actualizado exitosamente.', SnackbarType.Success)
      } else {
        await createResource(`${type}/${basicData.id}/blocks`, data)
        await this.fetchBlocksList()
        addSnackbar('Bloque creado exitosamente.', SnackbarType.Success)
      }
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    }
  }

  handleRemoveBlock = async data => {
    const { addSnackbar, deleteResource, type } = this.props
    const { basicData } = this.state

    try {
      await deleteResource(`${type}/${basicData.id}/blocks`, data.id)
      await this.fetchBlocksList()
      addSnackbar('Bloque eliminado exitosamente.', SnackbarType.Success)
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    }
  }

  handleSubmitActivity = async data => {
    const {
      addSnackbar,
      createActivity,
      createActivityBlock,
      updateResource,
      type: pageType,
    } = this.props

    const { basicData } = this.state
    try {
      if (data.content) {
        if (data.content.url) {
          if (typeof data.content.url === 'object') {
            data.content.file_extension = data.content.url.file_extension
              ? data.content.url.file_extension
              : data.content.url.name.split('.').pop()
            if (data.content.type === ActivityType.Image) {
              data.content.url = data.content.url.file
            }
          } else {
            data.content.file_extension = data.content.url.file_extension
              ? data.content.url.file_extension
              : data.content.url.split('.').pop()
          }
        }
        data.content.type = data.type
      }

      if (data.id) {
        // eslint-disable-next-line no-unused-vars
        const { quizTrivia, quiz, quiz_editing_blocked, ...restData } = data.content
        let response
        try {
          response = await updateResource(
            `${pageType}/${basicData.id}/blocks/${data.blockId}/activities`,
            data.id,
            quiz_editing_blocked
              ? {
                  name: restData.name,
                  description: restData.description,
                  display_solution: restData.display_solution,
                  required: restData.required,
                }
              : restData,
            typesForFileUpdate.includes(data.type),
          )
        } catch (err) {
          addSnackbar(
            'Error: ' +
              (err.log?.includes('QUIZ_EDITING_BLOCKED') || err.message === 'QUIZ_EDITING_BLOCKED'
                ? 'El formulario ya se encuentra iniciado por un usuario, no se puede realizar la edición.'
                : err.message),
            SnackbarType.Error,
          )
          this.fetchBlocksList()
        }
        if (response?.id && data.type === ActivityType.QuizTrivia && !quiz_editing_blocked) {
          try {
            await updateQuizTrivia(response.id, {
              question: quizTrivia.map(item => {
                if (item.link && !item.link.length) {
                  // eslint-disable-next-line no-unused-vars
                  const { link, ...rest } = item
                  item = rest
                }
                if (item.image && item.image.includes(';base64,')) {
                  const [file_extension, file] = item.image.split(';base64,')
                  return {
                    ...item,
                    image: file,
                    file_extension: file_extension.replace('data:image/', ''),
                  }
                  // eslint-disable-next-line no-extra-boolean-cast
                }
                return item
              }),
            })
          } catch (err) {
            addSnackbar('Error: ' + err.message, SnackbarType.Error)
          }
        } else {
          console.error('Se produjo un error al actualizar la actividad')
        }
        await this.fetchBlocksList()
        addSnackbar('Actividad actualizada exitosamente.', SnackbarType.Success)
      } else {
        if (data.type === 'existingActivity') {
          await createActivityBlock(
            `${pageType}/${basicData.id}/blocks/${data.blockId}/activities/${data.content.id}/add`,
            {
              ...data.content,
            },
          )
        } else {
          try {
            const { quizTrivia, ...rest } = data.content
            const response = await createActivity(
              `${pageType}/${basicData.id}/blocks/${data.blockId}/activities`,
              {
                type: data.type,
                ...rest,
              },
            )
            if (response?.id && data.type === ActivityType.QuizTrivia) {
              try {
                await createQuizTrivia(response.id, {
                  question: quizTrivia.map(item => {
                    if (item.link && !item.link.length) {
                      // eslint-disable-next-line no-unused-vars
                      const { link, ...rest } = item
                      item = rest
                    }
                    if (item.image && item.image.includes(';base64,')) {
                      const [file_extension, file] = item.image.split(';base64,')
                      return {
                        ...item,
                        image: file,
                        file_extension: file_extension.replace('data:image/', ''),
                      }
                      // eslint-disable-next-line no-extra-boolean-cast
                    }
                    return item
                  }),
                })
              } catch (err) {
                addSnackbar('Error: ' + err.message, SnackbarType.Error)
              }
            } else {
              console.error('Se produjo un error al crear la actividad')
            }
          } catch (err) {
            addSnackbar('Error: ' + err.message, SnackbarType.Error)
          }
        }

        await this.fetchBlocksList()
        addSnackbar('Actividad creada exitosamente.', SnackbarType.Success)
      }
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    }
  }

  handleSubmitMilestone = async data => {
    const {
      addSnackbar,
      updateResource,
      params: { id: courseId },
    } = this.props

    await updateResource(`activities/${data.id}/required_activities`, null, {
      required_activities: data.requiredActivities,
      course_id: courseId,
    })
    addSnackbar('Hito guardado exitosamente.', SnackbarType.Success)
  }

  handleRemoveActivity = async data => {
    const { addSnackbar, deleteResource, type } = this.props
    const { basicData } = this.state

    try {
      await deleteResource(`${type}/${basicData.id}/blocks/${data.blockId}/activities`, data.id)
      await this.fetchBlocksList()
      addSnackbar('Actividad eliminada exitosamente.', SnackbarType.Success)
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    }
  }

  handleUpdateRequiredActivity = async (data, isRequired) => {
    const { addSnackbar, updateResource, type } = this.props
    const { basicData } = this.state

    try {
      await updateResource(`${type}/${basicData.id}/blocks/${data.blockId}/activities`, data.id, {
        required: isRequired ? '1' : '0',
      })
      await this.fetchBlocksList()
      addSnackbar('Actividad actualizada exitosamente.', SnackbarType.Success)
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    }
  }

  handleSubmitBlockOrder = async blocks => {
    const { addSnackbar, updateResource, type } = this.props
    const { basicData } = this.state

    //Controlo que si esta publicado no quede un bloque vacio de actividades
    let activitiesEmpty = false

    blocks.forEach(item => {
      if (item.activities.length == 0) {
        activitiesEmpty = true
      }
    })

    if (basicData.status == 2 && activitiesEmpty) {
      addSnackbar(
        'Los cambios de orden no fueron aplicados ya que el curso se encuentra publicado y no pueden quedar un bloques sin actividades',
        SnackbarType.Warning,
      )
    } else {
      try {
        await updateResource(`${type}/${basicData.id}/blocks`, null, { blocks })
        await this.fetchBlocksList()
        addSnackbar('Ordenamiento de bloques actualizado exitosamente.', SnackbarType.Success)
      } catch (err) {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      }
    }
  }

  handlePublishCourse = async data => {
    const { type, updateResource, addSnackbar } = this.props

    try {
      this.setState({ loading: true })
      await updateResource(type, data.id, {
        status: data.status ? data.status : CourseStatusValue.Published,
      })

      addSnackbar('Curso pendiente de publicación.', SnackbarType.Success)
      this.setState({ loading: false })
      this.props.history.push('/admin')
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
      this.props.history.push('/admin')
    }
  }

  handleDraftCourse = async data => {
    const { type, updateResource, addSnackbar } = this.props
    try {
      await updateResource(type, data.id, {
        status: data.status ? data.status : CourseStatusValue.Published,
      })

      addSnackbar('Curso despublicado exitosamente.', SnackbarType.Success)
      window.location.reload(false)
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
      window.location.reload(false)
    }
  }

  handlePreview = () => {
    this.props.goTo(router.getRoute(ADMIN_COURSES + ADMIN_VIEW, { id: this.props.params.id }))
  }

  render() {
    if (this.state.loading) return <Loading dark={true} />

    return (
      <CourseFormBlockContent
        {...this.props}
        {...this.state}
        blocks={this.props.paginator.items}
        milestone={this.props.milestone}
        handleRemoveBlock={this.handleRemoveBlock}
        handleSubmitBlock={this.handleSubmitBlock}
        handleRemoveActivity={this.handleRemoveActivity}
        handleUpdateRequiredActivity={this.handleUpdateRequiredActivity}
        handleSubmitActivity={this.handleSubmitActivity}
        handleSubmitMilestone={this.handleSubmitMilestone}
        handlePublishCourse={this.handlePublishCourse}
        handleDraftCourse={this.handleDraftCourse}
        handleSubmitBlockOrder={this.handleSubmitBlockOrder}
        handlePreview={this.handlePreview}
        fetchActivitiesList={this.fetchActivitiesList}
      />
    )
  }
}

AdminCoursesBlockFormContainer.defaultProps = {
  type: 'courses',
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer
  const resourceStates = state.admin.resource
  return {
    ...resourceStates[ownProps.keyName],
    paginator: { ...paginatorState[ownProps.keyName] },
    milestone: {
      ...resourceStates[`${ownProps.keyName}-milestone-activities`],
    },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResource,
      getResources,
      silentGetResources,
      createActivity,
      createActivityBlock,
      createResource,
      updateResource,
      deleteResource,
      addSnackbar,
      goTo,
      loadQuestionsTypes,
      appLoadToken,
    },
    dispatch,
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminCoursesBlockFormContainer),
)
