import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex';
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  CourseProgress,
  CourseStatus,
  //CareerPlanTypeNames,
	SnackbarType
} from '../../../config/constants'

const KEY_NAME = 'reports/career_plan_courses'

class AdminReportsCareerPlanCoursesContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  getColumns = () => {
    let columns = [
      { title: 'Ruta', source: 'name_career_plan' },
			/* { title: 'Tipo de ruta', source: 'type_career_plan' }, */
			{ title: 'ID Curso', source: 'course_id' },
      { title: 'Nombre de Curso', source: 'course_title' },
      { title: 'Duración de Curso (min)', source: 'course_duration' },
      { title: 'Escala', source: 'course_scale' },
      { title: 'Condición', source: 'condicion' },
      { title: 'Estado del curso', source: 'status' },
    ]
    return columns
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Text,
        name: 'careerPlan.name',
        placeholder: 'Ruta',
        multiple: false,
        format: FiltersFormat.Like,
        displayAttribute: 'name',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Courses,
        name: 'careerPlanCourse.course',
        placeholder: 'Curso',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'title',
      },
      {
        type: EditInputType.Select,
        name: 'course.status',
        placeholder: 'Estado del curso',
        format: FiltersFormat.Plain,
        options: Object.keys(CourseStatus).map(k => ({
          id: k,
          description: CourseStatus[k],
        })),
      },
      /* {
        type: EditInputType.Select,
        name: 'careerPlan.type',
        placeholder: 'Tipo de ruta',
        format: FiltersFormat.Plain,
        options: Object.keys(CareerPlanTypeNames).map(k => ({
          id: k,
          description: CareerPlanTypeNames[k],
        })),
      }, */
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'CURSOS POR RUTAS DE APRENDIZAJE'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsCareerPlanCoursesContainer.defaultProps = {
  type: KEY_NAME,
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  if (
    paginatorState &&
    paginatorState.user_courses &&
    paginatorState.user_courses.items
  ) {
    paginatorState.user_courses.items.map((course) =>
      course.completed_at && course.completed_at.length ?
        (course.completed = CourseProgress.true) :
        (course.completed = CourseProgress.false)
    );
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsCareerPlanCoursesContainer);
