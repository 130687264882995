import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import { isAdmin } from '../../../utils/AdminHelper'
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  AdminItemViewType,
  CourseProgress,
  ActiveType,
  SnackbarType,
  AudiencesDescription,
  ModalitiesDescription,
} from '../../../config/constants'

const KEY_NAME = 'reports/external_courses_roadmap'

class AdminReportsExternalCoursesRoadmapContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  onRenderQuotaWithoutUse = item => {
    return item.quota_without_use === null ? '-' : item.quota_without_use
  }

  onRenderQuotaWithoutUsePercentage = item => {
    return item.quota_without_use_percentage === null
      ? '-'
      : item.quota_without_use_percentage + ' %'
  }

  onRenderPresentism = item => {
    return item.presentism === null ? '-' : item.presentism + ' %'
  }

  getColumns = () => {
    const { me } = this.props

    let columns = [
      { title: 'Id Taller', source: 'external_course_id' },
      { title: 'Nombre del Taller', source: 'external_course_name' },
      { title: 'ID Comisión', source: 'id' },
      { title: 'Fecha de la comisión', source: 'date' },
      { title: 'Audiencia', source: 'audience' },
      { title: 'Cupo máximo', source: 'maximun_capacity' },
      { title: 'Cupo mínimo', source: 'minimum_capacity' },
      { title: 'Total Inscriptos', source: 'total_subscribed' },
      { title: 'Status actual: Inscripto', source: 'subscribed' },
      { title: 'Status actual: Inscripción Cancelada', source: 'subscription_cancelled' },
      { title: 'Status actual: Presente', source: 'present' },
      { title: 'Status actual: Ausente', source: 'absent' },
      { title: 'Status actual: Baja Justificada', source: 'justified_desert' },
      { title: 'Status actual: Abandonado', source: 'deserted' },
      { title: 'Status actual: Comisión cancelada', source: 'canceled' },
      {
        title: 'Cupo sin usar',
        source: 'quota_without_use',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderQuotaWithoutUse,
      },
      {
        title: '% de cupo sin usar',
        source: 'quota_without_use_percentage',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderQuotaWithoutUsePercentage,
      },
      {
        title: '% Presentismo',
        source: 'presentism',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderPresentism,
      },
    ]

    return columns
  }

  getFilterConfig = () => {
    const { me } = this.props

    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.PublicExternalCourses,
        name: 'externalCourse.name',
        placeholder: 'Nombre del Taller',
        format: FiltersFormat.In,
        displayAttribute: 'name',
        multiple: true,
      },
      {
        type: EditInputType.Select,
        displayAttribute: 'name',
        name: 'commission.audience',
        placeholder: 'Audiencia',
        format: FiltersFormat.Plain,
        options: AudiencesDescription.map(csd => ({
          id: csd.id,
          description: csd.description,
        })),
      },
      {
        type: EditInputType.Select,
        name: 'commission.modality',
        placeholder: 'Modalidad',
        multiple: true,
        format: FiltersFormat.In,
        options: ModalitiesDescription.map(csd => ({
          id: csd.id,
          description: csd.description,
        })),
      },
      {
        type: EditInputType.Date,
        name: 'commissionDate.startDateFrom',
        placeholder: 'Fecha inicio de comisión desde',
        helpText: 'Fecha inicio de comisión desde',
        format: FiltersFormat.MayorIgual,
        queryName: 'commissionDate.startDate',
      },
      {
        type: EditInputType.Date,
        name: 'commissionDate.startDateTo',
        placeholder: 'Fecha inicio de comisión hasta',
        helpText: 'Fecha inicio de comisión hasta',
        format: FiltersFormat.MenorIgual,
        queryName: 'commissionDate.startDate',
      },
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME).then(() => {
      addSnackbar(
        'Esta descarga pudo ser limitada debido a la cantidad de registros',
        SnackbarType.Info,
      )
    })
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE ROADMAP'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsExternalCoursesRoadmapContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer
  if (paginatorState && paginatorState.user_courses && paginatorState.user_courses.items) {
    paginatorState.user_courses.items.map(course =>
      course.completed_at && course.completed_at.length
        ? (course.completed = CourseProgress.true)
        : (course.completed = CourseProgress.false),
    )
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsExternalCoursesRoadmapContainer)
