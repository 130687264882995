import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Activity from '../../containers/courses/Activity'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    root: {
      height: '100%',
      width: '100%',
    },
    activities: {
      width: '80%',
      minWidth: 768,
      maxWidth: '100%',
      margin: 'auto',
      backgroundColor: 'rgba(190,190,190,0.4)',
      paddingTop: 32,
      paddingBottom: 32,
      marginBottom: 64,
      [mediaLg]: {
        paddingTop: 1,
        width: '100%',
        minWidth: 'unset',
      },
    },
  }
}

class CourseBlockContent extends Component {
  render() {
    const { classes, block, onActivityDone, courseId, preview, reloadBlock } = this.props
    return (
      <section className={classes.root}>
        {block && (
          <div className={classes.activities}>
            {block.block_activities.map(({ activity, id }, index) => {
              activity.color = block.color
              return (
                <Activity
                  preview={preview}
                  onActivityDone={onActivityDone}
                  courseId={courseId}
                  activity={activity}
                  key={`block-${id}-activity-${index}`}
                  reloadBlock={reloadBlock}
                />
              )
            })}
          </div>
        )}
      </section>
    )
  }
}
export default withStyles(styles)(CourseBlockContent)
