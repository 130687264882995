/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropsTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'

const styles = () => ({
  zoomGrid: {
    gridColumn: '2 / 13',
    color: '#fff',

    '&__heading': {
      paddingTop: 16,
      paddingLeft: 128,
    },

    '&__container::-webkit-scrollbar': {
      display: 'none',
    },

    '&__container': {
      '&:hover &--item': {
        '&:hover': {
          transform: 'scale(1.25)',
          opacity: 1,
          zIndex: 1000,
        },
      },
      '&:focus-within &--item': {
        '&:focus-within': {
          transform: 'scale(1.25)',
          opacity: 1,
          zIndex: 1000,
        },
      },
      '&--item': {
        cursor: 'pointer',
        transition: 'all 450ms',
        transform: 'center left',
        minHeight: 0,
        zIndex: 1,
        position: 'relative',
        padding: 4,
        '&-image': {
          height: '100%',
        },
      },
    },
  },
})

function ZoomGrid({
  classes,
  itemCreator,
  itemClick,
  itemWidth,
  itemHeight,
  items,
  withActive,
  paddingHorizontal,
  ariaLabel,
}) {
  const [activeColor, setActiveColor] = useState(false)

  const handleSetActiveColor = color => {
    if (withActive && color) {
      setActiveColor(color)
    }
  }

  const handleClickItem = item => {
    itemClick(item)
    handleSetActiveColor(item.color)
  }

  const getItems = itemsList =>
    itemsList.map((item, index) => (
      <Grid item key={'zg-item-' + index.toString()}>
        <div
          onClick={() => handleClickItem(item)}
          className={`${classes.zoomGrid}__container--item`}
          style={{
            width: itemWidth,
            height: itemHeight,
          }}
        >
          {itemCreator(item, () => handleClickItem(item))}
        </div>
      </Grid>
    ))

  return (
    <section className={classes.zoomGrid} aria-label={ariaLabel}>
      <Grid
        justify="center"
        container
        className={`${classes.zoomGrid}__container`}
        style={{
          paddingLeft: paddingHorizontal,
          paddingRight: paddingHorizontal,
        }}
      >
        {getItems(items)}
      </Grid>
    </section>
  )
}

ZoomGrid.prototypes = {
  classes: PropsTypes.object.isRequired,
  itemCreator: PropsTypes.func,
  itemClick: PropsTypes.func,
  itemWidth: PropsTypes.number,
  itemHeight: PropsTypes.number,
  items: PropsTypes.shape({
    color: PropsTypes.string,
  }).isRequired,
  withActive: PropsTypes.bool,
  paddingHorizontal: PropsTypes.number,
}

ZoomGrid.defaultProps = {
  itemCreator: () => null,
  itemClick: () => {},
  itemWidth: 320,
  itemHeight: 180,
  withActive: false,
  paddingHorizontal: 60,
}

export default withStyles(styles)(ZoomGrid)
