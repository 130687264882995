import React, { useState, useCallback, useEffect } from 'react'
import GenericDialog from '../../components/ui/GenericDialog'
import ColorButton from '../../components/ui/ColorButton'
import { Button, Typography, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/es/TextField/TextField'
import validator from 'validator'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import Slider from '@material-ui/core/Slider'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      // minHeight: 550,
      margin: 'auto',
      alignItems: 'center',
      [mediaLg]: {
        marginBottom: 10,
      },
    },

    nps: {
      marginTop: 24,
      marginBottom: 10,
      width: '80%',
      margin: 'auto',
      textAlign: 'center',
      [mediaLg]: {
        width: '90%',
      },
    },

    text: {
      textAlign: 'center',
      color: '#575757',
    },

    actions: {
      margin: 'auto',
      marginTop: 18,
      marginBottom: 18,
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
    },

    actionSave: {
      backgroundColor: '#575454',
      marginRight: 16,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'rgba(87,84,84,0.91)',
      },
    },

    actionCancel: {
      color: '#575454',
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },

    buttonValorar: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
    },
    actionValorarSolo: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
      margin: 'auto',
      marginTop: 25,
      marginBottom: 25,
    },

    switchDiv: {
      fontSize: 14,
      [mediaLg]: {
        marginTop: 2,
      },
    },

    autorizo: {
      fontSize: 14,
      marginTop: 10,
      width: '70%',
      margin: 'auto',
      [mediaLg]: {
        marginTop: 2,
      },
    },

    buttonLabel: {
      textTransform: 'none',
    },

    buttonLabelSave: {
      textTransform: 'none',
      color: '#f0f0f0f0',
    },

    inputRoot: {
      fontSize: 14,
      width: 430,
      marginBottom: -7,
      [mediaLg]: {
        width: 'auto',
      },
      label: {
        fontSize: 25,
      },
    },

    textField: {
      marginBottom: 7,
      margin: 'auto',
      [mediaLg]: {
        width: '100%',
      },
    },

    Label: {
      fontSize: 14,
      textAlign: 'justify',
      marginLeft: -25,
      paddingRight: 25,
      color: '#575454',
    },

    titleValorar: {
      fontSize: 15,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      color: '#575454',
    },

    titleComment: {
      fontSize: 15,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      width: 450,
      paddingLeft: 35,
      color: '#575454',
    },

    valorar: {
      marginTop: 15,
      textAlign: 'right',
    },

    actionValorar: {
      width: 203,
      height: 58,
    },

    share: {
      marginTop: 15,
      textAlign: 'left',
    },

    actionShare: {
      height: 58,
      color: '#ffffff',
    },

    LabelFirstText: {
      fontSize: 16.5,
      textAlign: 'center',
      fontWeight: 600,
      paddingLeft: 40,
      paddingRight: 40,
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },

    LabelSecondText: {
      fontSize: 12,
      textAlign: 'center',
      width: '60%',
      margin: 'auto',
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },
    labelAutorizo: {
      fontSize: 12,
      color: '#575454',
    },

    error: {
      fontSize: 10,
      color: 'red',
    },
    switch: {
      marginLeft: '14%',
      width: '70%',
      display: 'inline-flex',
      [mediaLg]: {
        marginLeft: 'unset',
        width: '100%',
      },
    },
    contentValorar: {
      margin: 2,
    }
  }
}

const initialState = {
  isValid: false,
  validRating: false,
  stars: 0,
  comment: '',
  bad_comment: '',
  errors: { comment: '', bad_comment: '', nps: '' },
}

const CreateExternalCourseReviewDialog = ({ onSubmit, classes, open, onDismiss, headerColor, initialRating }) => {
  const [dialogState, updateDialogState] = useState(initialState)

  useEffect(()=>{
    updateDialogState({...initialState, stars: initialRating})
  },[initialRating, updateDialogState])

  const resetDialog = useCallback(() => {
    updateDialogState({ ...initialState })
  }, [updateDialogState])

  const handleChange = useCallback(
    event => {
      updateDialogState({...dialogState, [event.target.name]: event.target.value })
    },
    [dialogState,updateDialogState],
  )

  const handleSlider = useCallback(
    valor => {
      if (valor !== dialogState.stars) {
        updateDialogState({ ...dialogState, stars: valor, validRating: true })
      }
    },
    [updateDialogState, dialogState],
  )

  const validateForm = useCallback(() => {
    let error = false
    let errors = {
      comment: '',
      bad_comment: '',
      nps: '',
    }

    if (!validator.isLength(dialogState.comment.trim(), { max: 300 })) {
      errors.comment = 'Este campo debe tener máximo 300 caracteres.'
      error = true
    }

    if (!validator.isLength(dialogState.bad_comment.trim(), { max: 300 })) {
      errors.bad_comment = 'Este campo debe tener máximo 300 caracteres.'
      error = true
    }

    if (dialogState.stars === false) {
      errors.nps = 'Este campo es obligatorio'
      error = true
    }

    updateDialogState({...initialState, errors: errors, isValid: !error })
    return !error
  }, [updateDialogState, dialogState])

  const handleSubmit = useCallback(() => {
    if (validateForm()) {
      onSubmit({
        rating: dialogState.stars,
        good_review: dialogState.comment.trim(),
        bad_review: dialogState.bad_comment.trim(),
      })
    }
  }, [validateForm, onSubmit, dialogState])

  const renderContent = useCallback(() => {
    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      },
      {
        value: 10,
        label: '10',
      },
    ]

    return (
      <div className={classes.content}>
        <Grid container spacing={3} className={classes.contentValorar}>
          <div className={classes.nps}>
            <Typography className={classes.titleValorar} id={'review-slider-label'}>
              {'Del 0 al 10, ¿cuánto recomendarías este curso a un colega?'}
            </Typography>

            <Slider
              className={classes.nps}
              defaultValue={0}
              onChangeCommitted={(e, value) => handleSlider(value)}
              getAriaValueText={v => v}
              aria-labelledby="review-slider-label"
              valueLabelDisplay={dialogState.validRating ? 'on' : 'off'}
              step={1}
              style={{ color: headerColor }}
              marks={marks}
              min={0}
              max={10}
            />

            <Typography className={classes.error}>{dialogState.errors.nps}</Typography>
          </div>
          <div className={classes.nps}>
            <Typography className={classes.titleComment} id={'titleComment'}>
              {'Contanos qué te gustó'}
            </Typography>
            <TextField
              id="comment"
              name="comment"
              placeholder={'Mínimo 40 caracteres'}
              value={dialogState.comment}
              margin="normal"
              variant="outlined"
              type="text"
              multiline={true}
              rows={4}
              rowsMax={6}
              onChange={handleChange}
              className={classes.textField}
              InputProps={{
                'aria-labelledby': 'titleComment',
                classes: { root: classes.inputRoot },
              }}
            />
            <Typography className={classes.error}>{dialogState.errors.comment}</Typography>
          </div>
          <div className={classes.nps}>
            <Typography className={classes.titleComment} id={'titleComment'}>
              {'¿Qué mejorarías? Tu feedback nos ayuda!'}
            </Typography>
            <TextField
              id="bad_comment"
              name="bad_comment"
              placeholder={'Mínimo 40 caracteres'}
              value={dialogState.bad_comment}
              margin="normal"
              variant="outlined"
              type="text"
              multiline={true}
              rows={4}
              rowsMax={6}
              onChange={handleChange}
              className={classes.textField}
              InputProps={{
                'aria-labelledby': 'titleComment',
                classes: { root: classes.inputRoot },
              }}
            />
            <Typography className={classes.error}>{dialogState.errors.bad_comment}</Typography>
          </div>

          <Grid item xs={12} className={classes.actions}>
            <ColorButton
              color={'#3abf91'}
              className={classes.actionSave}
              label={'Guardar'}
              onClick={handleSubmit}
              disabled={!dialogState.validRating}
            />

            <Button
              variant={'outlined'}
              className={classes.actionCancel}
              classes={{ label: classes.buttonLabel }}
              onClick={onDismiss}
            >
              {'CANCELAR'}
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }, [classes, onDismiss, headerColor, handleSubmit, handleSlider, dialogState])

  return (
    <GenericDialog
      open={open}
      onDismiss={onDismiss}
      name={'VALORAR EL TALLER'}
      icon={<StarBorderOutlinedIcon style={{ fontSize: 55 }} />}
      title={'Del 0 al 10'}
      title2={'¿cuánto recomendarías este taller a un colega?'}
      onExit={resetDialog}
    >
      {renderContent()}
    </GenericDialog>
  )
}

export default withStyles(styles)(CreateExternalCourseReviewDialog)
