import React from 'react'
import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import MultipleChoiceOption from './components/MultipleChoiceOption'
import { addOption, blankOption } from '../../helpers'
import moment from 'moment'

const QuestionMultipleChoice = ({ handler, question, quizEditingBlocked }) => (
  <div className="col" style={{ gap: '2rem' }}>
    <div className="col" style={{ gap: '1rem' }}>
      {question?.options?.map((option, index) => {
        const momentKey = moment().unix()
        return (
          <MultipleChoiceOption
            key={`${option.id ?? `new-${option.options_text}`}-multiple_choice_option-${
              momentKey + index
            }-${index}`}
            {...{ ...option, handler, question, index }}
            quizEditingBlocked={quizEditingBlocked}
          />
        )
      })}
    </div>
    {!quizEditingBlocked && (
      <Button
        style={{ backgroundColor: '#f3f3f3' }}
        variant="contained"
        onClick={() =>
          handler(question, addOption(question, blankOption(undefined, false, undefined)))
        }
      >
        <Add />
      </Button>
    )}
  </div>
)

export default QuestionMultipleChoice
