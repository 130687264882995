import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex';
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  CourseProgress,
	SnackbarType
} from '../../../config/constants'
import { isOpenBankUser } from './../../../utils/AdminHelper'

const KEY_NAME = 'reports/user_career_plans'

class AdminReportsUserCareerPlansContainer extends Component {

  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size, undefined,  isOpenBankUser(me.user));
  }

  getColumns = () => {
    const { me } = this.props

    let columns = [
      { title: 'Usuario', source: 'user' },
      { title: 'Apellido y Nombre', source: 'fullname' },
      { title: 'Ruta', source: 'plan_name' },
      { title: 'Estado', source: 'plan_status' },
      { title: 'Puesto', source: 'job_position' },
      { title: isOpenBankUser(me.user) ? 'Area' : 'Centros de Costos', source: 'center_cost' },
      { title: 'Área Cliente Interno', source: 'internal_area' },
      { title: '% Cumplimiento Total', source: 'total_percentage_completed' },
      { title: '% Cumplimiento Necesarios', source: 'total_percentage_necesary_completed' },
      {
        title: '% Cumplimiento Complementarios',
        source: 'total_percentage_complementary_completed',
      },
    ]
    return columns
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Text,
        name: 'careerPlan.name',
        placeholder: 'Ruta',
        multiple: false,
        format: FiltersFormat.Like,
        displayAttribute: 'name',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.JobPosition,
        name: 'user.jobPosition',
        placeholder: 'Puesto del Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'description',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'user.organizationalUnit',
        placeholder: 'Centro de Costos',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'code',
      },
      {
        type: EditInputType.Text,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.internalArea',
        placeholder: 'Área Cliente Interno',
        multiple: true,
        format: FiltersFormat.Like,
        displayAttribute: 'internal_area',
      },
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME).then(() => {
      addSnackbar('Esta descarga pudo ser limitada debido a la cantidad de registros', SnackbarType.Info)
    })
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'RUTAS DE APRENDIZAJE ASIGNADAS POR USUARIO'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsUserCareerPlansContainer.defaultProps = {
  type: KEY_NAME,
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  if (
    paginatorState &&
    paginatorState.user_courses &&
    paginatorState.user_courses.items
  ) {
    paginatorState.user_courses.items.map((course) =>
      course.completed_at && course.completed_at.length ?
        (course.completed = CourseProgress.true) :
        (course.completed = CourseProgress.false)
    );
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsUserCareerPlansContainer);
