import {
  NEW_CAREER_PLANS_CHANNELS_READ_START,
  NEW_CAREER_PLANS_CHANNELS_READ_END,
  NEW_CAREER_PLANS_CHANNELS_READ_FAILED,
  NEW_CAREER_PLANS_SCALES_READ_START,
  NEW_CAREER_PLANS_SCALES_READ_END,
  NEW_CAREER_PLANS_SCALES_READ_FAILED,
  GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_START,
  GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_END,
  GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_FAILED,
  GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_START,
  GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_END,
  GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_FAILED,
  GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_START,
  GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_END,
  GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_FAILED,
} from '.';
import api from '../api/api';

export function loadChannelsForCareerPlan() {
  return async (dispatch) => {
    try {
      dispatch({ type: NEW_CAREER_PLANS_CHANNELS_READ_START });
      const { data } = await api.withDispatch(dispatch).CareerPlan.getChannels();
      dispatch({
        type: NEW_CAREER_PLANS_CHANNELS_READ_END,
        channels: data.map((element) => ({ ...element, selected: false })),
      });
    } catch (error) {
      dispatch({ type: NEW_CAREER_PLANS_CHANNELS_READ_FAILED, error });
    }
  };
}

export function loadScalesForCareerPlan() {
  return async (dispatch) => {
    try {
      dispatch({ type: NEW_CAREER_PLANS_SCALES_READ_START });
      const { data } = await api.withDispatch(dispatch).CareerPlan.getScales();
      dispatch({
        type: NEW_CAREER_PLANS_SCALES_READ_END,
        scales: data.map((element) => ({ ...element, selected: false })),
      });
    } catch (error) {
      dispatch({ type: NEW_CAREER_PLANS_SCALES_READ_FAILED, error });
    }
  };
}

export function loadCoursesForCareerPlan() {
  return async (dispatch) => {
    dispatch({ type: GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_START });
    try {
      const { data } = await api
        .withDispatch(dispatch)
        .CareerPlan.getAssociateCourseToCareerPlan();

      dispatch({
        type: GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_END,
        courses: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_FAILED,
        error,
      });
    }
  };
}

export function loadJobPositionsForCareerPlan() {
  return async (dispatch) => {
    dispatch({ type: GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_START });
    try {
      const { data } = await api
        .withDispatch(dispatch)
        .CareerPlan.getJobPositions();
      dispatch({
        type: GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_END,
        jobPositions: data,
      });
    } catch (error) {
      dispatch({
        type: GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_FAILED,
        error,
      });
    }
  };
}

export function loadOrganizationUnitsForCareerPlan() {
  return async (dispatch) => {
    dispatch({ type: GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_START });
    try {
      const { data } = await api
        .withDispatch(dispatch)
        .CareerPlan.getOrganizationalUnits();
      dispatch({
        type: GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_END,
        organizationalUnits: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_FAILED,
        error,
      });
    }
  };
}
