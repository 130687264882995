import { uuidv4 } from './utils';

export default [
  {
    id: uuidv4(),
    name: 'Business',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
    color: '#838282',
    visible: true,
    sort: 1,
    icon:
      'https://academia-dev.s3-us-west-1.amazonaws.com/categories/Group_154.png',
    featured: null,
  },
  {
    id: uuidv4(),
    name: 'Business',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
    color: '#838282',
    visible: true,
    sort: 1,
    icon:
      'https://academia-dev.s3-us-west-1.amazonaws.com/categories/Group_154.png',
    featured: null,
  },
  {
    id: uuidv4(),
    name: 'Business',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
    color: '#838282',
    visible: true,
    sort: 1,
    icon:
      'https://academia-dev.s3-us-west-1.amazonaws.com/categories/Group_154.png',
    featured: null,
  },
  {
    id: uuidv4(),
    name: 'Business',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
    color: '#838282',
    visible: true,
    sort: 1,
    icon:
      'https://academia-dev.s3-us-west-1.amazonaws.com/categories/Group_154.png',
    featured: null,
  },
  {
    id: uuidv4(),
    name: 'Business',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
    color: '#838282',
    visible: true,
    sort: 1,
    icon:
      'https://academia-dev.s3-us-west-1.amazonaws.com/categories/Group_154.png',
    featured: null,
  },
];
