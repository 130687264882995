import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import validator from 'validator';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Loading from '../../../components/ui/Loading';
import { addSnackbar } from '../../../actions/snackbar';
import { SnackbarType, ExternalCourseStatus } from '../../../config/constants';
import { goTo } from '../../../actions/navigator';
import { createResource, updateResource, getResource, } from '../../../actions/admin';
import { loadCourses, loadExternalCourses } from '../../../actions/externalCourseAdmin';
import api from '../../../api/api';
import { appLoadToken } from '../../../actions/app';
import StepOne from '../../../components/admin/external_courses/StepOne';
import StepTwo from '../../../components/admin/external_courses/StepTwo';
import { ADMIN_EXTERNAL_COURSES } from '../../../config/routes';


const steps = ['Datos Básicos', 'Requisitos'];
const [firstStep, secondStep, lastStep] = steps.map((_, id) => id);

const styles = (theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    padding: '1em 8em',
    boxSizing: 'border-box',
    background: '#454545',
  },
  titleHeader: {
    color: '#fff',
    fontSize: 20,
  },
  root: {
    width: '100%',
    margin: '50px 0 20px 0',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sectionBlock: {
    root: {
      color: '#FF00FF11',
    },
  },
});

class AdminExternalCoursesStepperContainer extends Component {

  state = {
    loading: false,
    isPublished: false,
    activeStep: 0,
    stepOneHasChanged: false,
    isValid: false,
    form: {
      id: false,
      name: '',
      code: '',
      description: '',
      type: '',
      image: '',
      generate_certificate: '',
      status: false,
      categories: false,
      tags_list: false,
      required_courses: false,
      required_external_courses: false,
    },
    img: "",
    errors: {},
    coursesSelected: [],
    externalCoursesSelected: [],
  };

  componentDidMount = async () => {
    const {
      appLoadToken,
      loadCourses,
      loadExternalCourses,
    } = this.props;

    try {
      if (this.props.isEdit) {
        this.onLoadData();
        this.setState({ isValid: true });
      }

      appLoadToken().then(() => {
        loadCourses();
        loadExternalCourses();
      });
    }
    catch (error) {
      console.error(error)
    }
  }

  onLoadData = (id) => {
    const { getResource, params, keyName } = this.props;
    getResource(keyName, 'external_courses', id || params.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.item && nextProps.item) {
      this.setState(
        {
          isPublished: (nextProps.item.status === ExternalCourseStatus.Published),
          form: {
            ...nextProps.item,
            image:
              nextProps.item &&
                nextProps.item.image_url
                ? nextProps.item.image_url
                : '',
            tags_list:
              nextProps.item &&
                nextProps.item.tags &&
                nextProps.item.tags.length
                ? nextProps.item.tags.map((model) => ({
                  id: model.id,
                  name: model.name,
                }))
                : [],
            categories:
              nextProps.item &&
                nextProps.item.categories &&
                nextProps.item.categories.length
                ? nextProps.item.categories.map((model) => ({
                  id: model.id,
                  name: model.name,
                }))
                : [],
            required_courses:
              nextProps.item &&
                nextProps.item.required_courses &&
                nextProps.item.required_courses.length
                ? nextProps.item.required_courses.map((model) => ({
                  id: model.id
                }))
                : [],
            required_external_courses:
              nextProps.item &&
                nextProps.item.required_external_courses &&
                nextProps.item.required_external_courses.length
                ? nextProps.item.required_external_courses.map((model) => ({
                  id: model.id
                }))
                : [],
          },
        },
        this.validateForm
      );
    }

    if (this.state.form.id && !isEmpty(this.props.externalCourseMetadata.externalCourses)) {
      this.setState({
        loading: false
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.externalCourseMetadata !== this.props.externalCourseMetadata && this.state.form.id !== undefined && this.state.form.id !== '') {
      this.updateRequirements()
    }
  }

  updateRequirements = () => {
    if (!this.props.externalCourseMetadata.externalCourses) return;

    if (!isEmpty(this.props.externalCourseMetadata.courses) && this.state.form.required_courses && this.state.form.required_courses.length) {
      this.selectedCourses(this.state.form.required_courses, this.props.externalCourseMetadata.courses);
    }

    if (!isEmpty(this.props.externalCourseMetadata.externalCourses) && this.state.form.required_external_courses && this.state.form.required_external_courses.length) {
      this.selectedExternalCourses(this.state.form.required_external_courses, this.props.externalCourseMetadata.externalCourses);
    }
  }

  selectedCourses = (formCourses, courses) => {
    let coursesSelected = courses.data
      .filter((e1) => formCourses.find(e2 => e1.id === e2.id));

    this.setState((prevState) => ({
      coursesSelected: coursesSelected
    }));
  }

  selectedExternalCourses = (formExternalCourses, externalCourses) => {
    let externalCoursesSelected = externalCourses.data
      .filter((e1) => formExternalCourses.find(e2 => e1.id === e2.id));

    this.setState((prevState) => ({
      externalCoursesSelected: externalCoursesSelected
    }));
  }

  increment = (prevState) => {
    this.setState((prevState) => ({
      activeStep: this.state.activeStep + 1
    }));
  }
  decrement = (prevState) => {
    this.setState((prevState) => ({
      activeStep: this.state.activeStep - 1
    }));
  }

  handleNextStep = async () => {
    const { addSnackbar, isEdit } = this.props;
    if (firstStep === this.state.activeStep && this.state.stepOneHasChanged) {
      const callbackSuccess = () => {
        let message = (this.state.form.status == ExternalCourseStatus.Published) ? 'Taller' : 'Borrador';
        let action = (isEdit) ? 'modificado' : 'guardado';
        addSnackbar(`${message} ${action} con éxito`, SnackbarType.Success);
      };
      const callbackError = (error) => {
        this.handleBackStep()
        addSnackbar(error.message, SnackbarType.Warning);
      }

      this.updateOrCreateWorkshop(callbackSuccess, callbackError);
    }
    this.increment(this.state.activeStep);
  };

  handleSaveRequirements = async () => {
    const { addSnackbar, isEdit } = this.props;
    const payload = this.createPayload();

    const callbackSuccess = () => {
      let message = (this.state.form.status == ExternalCourseStatus.Published) ? 'Taller' : 'Borrador';
      let action = (isEdit) ? 'modificado' : 'guardado';
      addSnackbar(`${message} ${action} con éxito`, SnackbarType.Success);
      this.props.goTo(ADMIN_EXTERNAL_COURSES);
    };
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);

    try {
      await api.AdminExternalCourse.setRequirements(this.state.form.id, payload);
      callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  }

  handleChangeCourse = (value) => {
    this.setState(() => ({
      coursesSelected: value,
    }));
  }

  handleChangeExternalCourse = (value) => {
    this.setState(() => ({
      externalCoursesSelected: value,
    }));
  }

  createPayload = () => {
    return {
      courses: this.state.coursesSelected.map((element) => element.id),
      externalCourses: this.state.externalCoursesSelected.map((element) => element.id),
    };
  };

  handleSaveRequirementsAndPublish = async () => {
    const { addSnackbar } = this.props;
    const payload = this.createPayload();
    const callbackSuccess = () => {
      addSnackbar('Taller publicado', SnackbarType.Success);
      this.props.goTo(ADMIN_EXTERNAL_COURSES);
    };
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);

    try {
      this.setState({ loading: true });
      await api.AdminExternalCourse.setRequirements(this.state.form.id, payload);
      await api.AdminExternalCourse.publish(this.state.form.id);
      this.setState({ loading: false });
      callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  };

  handleBackStep = () => {
    if (firstStep === this.state.activeStep) {
      this.setState({ activeStep: 0 });
      this.props.goTo(ADMIN_EXTERNAL_COURSES);
      return;
    }

    if (secondStep === this.state.activeStep) {
      this.decrement(this.state.activeStep);
      return;
    }

    if (lastStep === this.state.activeStep) {
      this.decrement(this.state.activeStep);
      return;
    }
  };

  updateOrCreateWorkshop = async (callbackSuccess, callbackError) => {
    const { isEdit } = this.props;
    const payload = {
      name: this.state.form.name,
      code: this.state.form.code,
      description: this.state.form.description,
      categories: this.state.form.categories,
      tags: (this.state.form.tags_list) ? this.state.form.tags_list.map((model) => model.name) : [],
      imageUrl: this.state.form.image || false,
      generate_certificate: this.state.form.generate_certificate || false
    };

    try {
      this.setState({ loading: true });

      const {
        data: { id, data },
      } = (isEdit || this.state.form.id) ? await api.AdminExternalCourse.edit(this.state.form.id, payload) : await api.AdminExternalCourse.create(payload);
      await this.setState((prevState) => ({
        stepOneHasChanged: false
      }));

      const externalCourseId = id ? id : this.state.form.id;

      if (!isEdit) {
        this.setState({
          form: {
            id: externalCourseId
          }
        });
        //this.onLoadData(externalCourseId);
      }

      callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
    this.setState({ loading: false });
  };

  validateBasicForm = async () => {
    const { form } = this.state;
    const { isEdit } = this.props;
    let error = false;
    const errors = {
      image: '',
      title: '',
      description: '',
      duration: '',
      categories: '',
    };

    this.setState({ errors: errors, isValid: !error });

    if (!form.image) {
      errors.image = 'Debes completar este campo';
      error = true;
    }
    if (!form.name || validator.isEmpty(form.name.trim())) {
      errors.name = 'Debes completar este campo';
      error = true;
    } else if (!validator.isLength(form.name.trim(), { min: 2, max: 80 })) {
      errors.name = 'Este campo debe tener entre 2 y 80 caracteres.';
      error = true;
    }

    if (!form.description || validator.isLength(form.description.trim())) {
      errors.description = 'Debes completar este campo';
      error = true;
    } else if (
      !validator.isLength(form.description.trim(), {
        min: 2,
        max: 300,
      })
    ) {
      errors.description = 'Este campo debe tener entre 2 y 300 caracteres.';
      error = true;
    }

    if (!(form.categories && form.categories.length)) {
      errors.categories = 'Debes completar este campo';
      error = true;
    }

    if (form.name && !isEdit){
      const exists = await this.validateName(form.name)
      if (exists.data) {
        errors.name = 'El nombre para este taller ya está registrado';
        error = true;
      }
    }

    this.setState({ errors: errors, isValid: !error });
  };


  onChange = (form) => {
    this.setState({ form }, this.validateBasicForm);
  };

  validateName = async (name) =>{
    try {
      return await api.AdminExternalCourse.exists(name);
    } catch (error) { console.log(error) }
  }

  handleStepOneChange = () => {
    this.setState({
      stepOneHasChanged: true
    });
  }
  render() {
    const { classes, ...otherProps } = this.props;
    const { form, ...results } = this.state;
    const props = {
      onSubmit: this.handleSubmit,
      onAttributeChange: this.onChange,
      results,
      form,
      onGoBack: this.onGoBack,
      ...otherProps,
    };
    if ((this.state.loading) || props.isEdit && (
      !this.state.form.id ||
      (this.state.activeStep === firstStep && (isEmpty(props.externalCourseMetadata.courses)) || !this.state.coursesSelected) ||
      (this.state.activeStep === secondStep && (isEmpty(props.externalCourseMetadata.courses) || isEmpty(props.externalCourseMetadata.externalCourses)))
    )
    ) {
      return <Loading dark={true} />;
    }

    Stepper.propTypes = {
      classes: PropTypes.object,
    };
    return (
      <Grid container justify="center">
        <div className={classes.header}>
          {props.isEdit ? (
            <Typography className={classes.titleHeader}>EDITAR TALLER "{this.state.form.name}"</Typography>
          ) : (
            <Typography className={classes.titleHeader}>CREAR TALLER 1.1</Typography>
          )}
        </div>
        <Grid container className={classes.root} justify="center">
          <Grid item xs={10}>
            <Stepper activeStep={this.state.activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} className={classes.sectionBlock}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {this.state.activeStep === firstStep && (
              <StepOne
                {...props}
                handleBack={this.handleBackStep}
                handleNext={this.handleNextStep}
                disableNextButton={this.state.isValid}
                handleStepOneChange={this.handleStepOneChange}
              />
            )}
            {this.state.activeStep === secondStep && (
              <StepTwo
                {...props}
                handleBack={this.handleBackStep}
                isPublished={this.state.isPublished}
                handleChangeCourse={this.handleChangeCourse}
                handleChangeExternalCourse={this.handleChangeExternalCourse}
                handleSaveRequirements={this.handleSaveRequirements}
                handleSaveRequirementsAndPublish={this.handleSaveRequirementsAndPublish}
                coursesSelected={this.state.coursesSelected}
                externalCoursesSelected={this.state.externalCoursesSelected}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    externalCourseMetadata: state.externalCoursesAdminReducer,
    ...states[ownProps.keyName],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadCourses,
      loadExternalCourses,
      getResource,
      createResource,
      updateResource,
      addSnackbar,
      goTo,
      appLoadToken,
    },
    dispatch
  );
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AdminExternalCoursesStepperContainer)
  )
);
