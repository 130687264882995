import React from 'react'
import { ACTIVITY_TYPE_COMPONENTS } from './ActivityTypeModal'

const ActivityModalContent = ({ activity, title, ...props }) => {
  const ActivityModalComponent =
    activity && activity.type && ACTIVITY_TYPE_COMPONENTS[activity.type]

  return ActivityModalComponent ? (
    <ActivityModalComponent
      activity={activity}
      results={activity.results}
      title={title}
      {...props}
    />
  ) : null
}

export default ActivityModalContent
