import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  ADMIN_ACTIVITIES,
  ADMIN_CATEGORIES,
  ADMIN_CAREER_PLAN,
  ADMIN_LOTS,
  ADMIN_COURSES,
  ADMIN_COURSES_BASIC,
  ADMIN_COURSES_TOPTEN,
  ADMIN_COURSES_BLOCK,
  ADMIN_EDIT,
  ADMIN_HOME,
  ADMIN_NEW,
  ADMIN_TAGS,
  ADMIN_USERS,
  ADMIN_VIEW,
  ADMIN_REPORTS,
  ADMIN_REPORTS_NPS_COURSES,
  ADMIN_REPORTS_CROSS,
  ADMIN_REPORTS_NPS_COMMISSIONS,
  ADMIN_REPORTS_EXTERNAL_COURSES,
  ADMIN_REPORTS_EXTERNAL_COURSES_ROADMAP,
  ADMIN_REPORTS_COMMISSIONS,
  ADMIN_REPORTS_CAREER_PLANS,
  ADMIN_REPORTS_CAREER_PLAN_COURSES,
  ADMIN_REPORTS_USER_CAREER_PLANS,
  ADMIN_REPORTS_USER_GLOBAL_CAREER_PLANS,
  ADMIN_REPORTS_ONLINE_COURSES,
  ADMIN_REPORTS_NPS_COMMENTS,
  ADMIN_REPORTS_NPS_EXTERNAL_COURSES_COMMENTS,
  ADMIN_JOBS,
  ADMIN_EXTERNAL_COURSES,
  ADMIN_EXTERNAL_COURSES_COMMISSIONS,
  ADMIN_FACILITATORS,
  ADMIN_COMMISSIONS_USERS,
  ADMIN_SLIDES,
  ADMIN_REPORTS_ONLINE_COURSES_QUESTIONNAIRE,
  ADMIN_DUPLICATE,
  ADMIN_SCORMS,
  ADMIN_REFERENTS_PROVIDERS,
  ADMIN_REPORTS_COURSE_ACTIVITIES,
  ADMIN_REPORTS_EXTERNAL_FORMATIONS,
  ADMIN_REPORTS_QUIZ_TRIVIA_ACTIVITIES,
} from '../../config/routes'
import AdminTagsContainer from '../../containers/admin/tags/AdminTags'
import AdminTagsViewContainer from '../../containers/admin/tags/AdminTagsView'
import AdminTagsFormContainer from '../../containers/admin/tags/AdminTagsForm'
import AdminCategoriesContainer from '../../containers/admin/categories/AdminCategories'
import AdminCategoriesFormContainer from '../../containers/admin/categories/AdminCategoriesForm'
import AdminCareerPlanContainer from '../../containers/admin/career_plans/AdminCareerPlan'
import AdminCareerPlansStepperContainer from '../../containers/admin/career_plans/AdminCareerPlansStepperContainer'
import AdminExternalCoursesStepperContainer from '../../containers/admin/external_courses/AdminExternalCoursesStepperContainer'
import AdminUsersContainer from '../../containers/admin/users/AdminUsers'
import AdminUsersViewContainer from '../../containers/admin/users/AdminUsersView'
import AdminUsersFormContainer from '../../containers/admin/users/AdminUsersForm'
import AdminUsersFormOpenBankContainer from '../../containers/admin/users/AdminUsersFormOpenBank'
import AdminActivitiesContainer from '../../containers/admin/activities/AdminActivities'
import AdminCoursesContainer from '../../containers/admin/courses/AdminCourses'
import AdminCoursesBasicFormContainer from '../../containers/admin/courses/AdminCoursesBasicForm'
import AdminCoursesTopTenFormContainer from '../../containers/admin/courses/AdminCoursesTopTenForm'
import AdminCoursesBlockFormContainer from '../../containers/admin/courses/AdminCoursesBlockForm'
import AdminActivitiesFormContainer from '../../containers/admin/activities/AdminActivitiesForm'
import AdminCoursesViewContainer from '../../containers/admin/courses/AdminCoursesViewContainer'
import AdminReportsContainer from '../../containers/admin/reports/AdminReports'
import AdminReportsNpsCommissionsContainer from '../../containers/admin/reports/AdminReportsNpsCommissions'
import AdminReportsNpsCoursesContainer from '../../containers/admin/reports/AdminReportsNpsCourses'
import AdminReportsExternalCoursesContainer from '../../containers/admin/reports/AdminReportsExternalCourses'
import AdminReportsExternalCoursesRoadmapContainer from '../../containers/admin/reports/AdminReportsExternalCoursesRoadmap'
import AdminReportsCommissionsContainer from '../../containers/admin/reports/AdminReportsCommissions'
import AdminReportsOnlineCoursesContainer from '../../containers/admin/reports/AdminReportsOnlineCourses'
import AdminReportsCareerPlansContainer from '../../containers/admin/reports/AdminReportsCareerPlans'
import AdminReportsCareerPlanCoursesContainer from '../../containers/admin/reports/AdminReportsCareerPlanCourses'
import AdminReportsUserCareerPlansContainer from '../../containers/admin/reports/AdminReportsUserCareerPlans'
import AdminReportsUserGlobalCareerPlansContainer from '../../containers/admin/reports/AdminReportsUserGlobalCareerPlans'
import AdminReportsNpsCommentsContainer from '../../containers/admin/reports/AdminReportsNpsComments'
import AdminReportsNpsExternalCoursesCommentsContainer from '../../containers/admin/reports/AdminReportsNpsExternalCoursesComments'
import AdminLots from '../../containers/admin/lots/AdminLots'
import AdminLotsStepperContainer from '../../containers/admin/lots/AdminLotsStepperContainer'
import AdminJobs from '../../containers/admin/jobs/AdminJobs'
import AdminJobsForm from '../../containers/admin/jobs/AdminJobsForm'
import AdminSlidesFormContainer from '../../containers/admin/slides/AdminSlidesForm'
import AdminReportsExternalFormationsContainer from '../../containers/admin/reports/AdminReportsExternalFormations'

import * as ReactGA from 'react-ga'
import AdminRoute from './ui/AdminRoute'
import AdminOrReportingRoute from './ui/AdminOrReportingRoute'
import AdminExternalCoursesContainer from '../../containers/admin/external_courses/AdminExternalCourses'
import AdminExternalCoursesCommissionsContainer from '../../containers/admin/external_courses/AdminExternalCoursesCommissions'
import AdminFacilitatorsContainer from '../../containers/admin/facilitators/AdminFacilitators'
import AdminFacilitatorsFormContainer from '../../containers/admin/facilitators/AdminFacilitatorsForm'
import AdminReferentsProvidersContainer from '../../containers/admin/referents_providers/AdminReferentsProviders'
import AdminReferentsProvidersFormContainer from '../../containers/admin/referents_providers/AdminReferentsProvidersForm'
import AdminCommissionsUsers from '../../containers/admin/commissions/AdminCommissionsUsers'
import AdminSlidesContainer from '../../containers/admin/slides/AdminSlides'
import AdminExternalCoursesCommissionsStepperContainer from '../../containers/admin/external_courses/AdminExternalCoursesCommissionsStepperContainer'
import AdminExternalCoursesCommissionsStepperContainerDuplicate from '../../containers/admin/external_courses/AdminExternalCoursesCommissionsStepperContainerDuplicate'
import AdminReportsCrossCollaboration from '../../containers/admin/reports/AdminReportsCrossCollaboration'
import { isOpenBankAdmin, isOpenBankReporting } from '../../utils/AdminHelper'
import handleBot from '../bot/bot'
import './index.css'
import AdminScormConfigPanel from '../../containers/admin/scorms/AdminScormConfigPanel'
import AdminReportsCourseActivities from '../../containers/admin/reports/AdminReportsCourseActivities'
import AdminReportsQuizTriviaActivities from '../../containers/admin/reports/AdminReportsQuizTriviaActivities'

const styles = theme => ({
  content: {
    display: 'block',
    minWidth: 0, // So the Typography noWrap works,
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f7f7f7',
    maxWidth: 'calc(100vw - 0%)',
  },
  toolbar: theme.mixins.toolbar,
})

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
function AdminMain(props) {
  const { classes } = props
  ReactGA.pageview(window.location.pathname + window.location.search)

  const resolveAdminRoot = () => {
    const { me } = props
    if (me.is_admin) {
      return <AdminCoursesContainer />
    }

    if (isOpenBankAdmin(me)) {
      return <AdminUsersContainer />
    }

    if (me.is_reporting || isOpenBankReporting(me)) {
      return <AdminReportsContainer />
    }
  }

  const resolveAdminUserEditType = match => {
    const { me } = props

    if (isOpenBankAdmin(me))
      return (
        <AdminUsersFormOpenBankContainer
          keyName={'admin-users-edit'}
          params={match.params}
          isEdit={true}
        />
      )

    return (
      <AdminUsersFormContainer keyName={'admin-users-edit'} params={match.params} isEdit={true} />
    )
  }

  useEffect(() => {
    handleBot()
  }, [])

  return (
    <div className={classes.content}>
      <div className={classes.toolbar} />

      <Switch>
        <AdminOrReportingRoute exact path={ADMIN_HOME} component={() => resolveAdminRoot()} />
        <AdminRoute exact path={ADMIN_CAREER_PLAN} component={() => <AdminCareerPlanContainer />} />
        <AdminRoute
          exact
          path={ADMIN_CAREER_PLAN + ADMIN_NEW}
          component={() => (
            <AdminCareerPlansStepperContainer keyName={'admin-career-plan-edit'} isEdit={false} />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_CAREER_PLAN + ADMIN_EDIT}
          component={({ match }) => (
            <AdminCareerPlansStepperContainer
              keyName={'admin-career-plan-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute exact path={ADMIN_LOTS} component={() => <AdminLots />} />
        <AdminRoute
          exact
          path={ADMIN_LOTS + ADMIN_NEW}
          component={() => <AdminLotsStepperContainer keyName={'admin-lot-new'} isEdit={false} />}
        />
        <AdminRoute
          exact
          path={ADMIN_LOTS + ADMIN_EDIT}
          component={({ match }) => (
            <AdminLotsStepperContainer
              keyName={'admin-lot-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute exact path={ADMIN_CATEGORIES} component={() => <AdminCategoriesContainer />} />
        <AdminRoute
          exact
          path={ADMIN_CATEGORIES + ADMIN_EDIT}
          component={({ match }) => (
            <AdminCategoriesFormContainer
              keyName={'admin-categories-new'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_CATEGORIES + ADMIN_NEW}
          component={() => (
            <AdminCategoriesFormContainer keyName={'admin-categories-edit'} isEdit={false} />
          )}
        />
        <AdminRoute exact path={ADMIN_SLIDES} component={() => <AdminSlidesContainer />} />
        <AdminRoute
          exact
          path={ADMIN_SLIDES + ADMIN_EDIT}
          component={({ match }) => (
            <AdminSlidesFormContainer
              keyName={'admin-slides-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_SLIDES + ADMIN_NEW}
          component={() => <AdminSlidesFormContainer keyName={'admin-slides-new'} isEdit={false} />}
        />
        <AdminRoute exact path={ADMIN_USERS} component={() => <AdminUsersContainer />} />
        <AdminRoute
          exact
          path={ADMIN_USERS + ADMIN_VIEW}
          component={({ match }) => (
            <AdminUsersViewContainer keyName={'admin-users-view'} params={match.params} />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_USERS + ADMIN_NEW}
          component={() => (
            <AdminUsersFormOpenBankContainer
              keyName={'admin-users-new'}
              isEdit={false}
              title={'Nuevo usuario'}
              type={'users/open-bank'}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_USERS + ADMIN_EDIT}
          component={({ match }) => resolveAdminUserEditType(match)}
        />
        <AdminRoute exact path={ADMIN_TAGS} component={() => <AdminTagsContainer />} />
        <AdminRoute
          exact
          path={ADMIN_TAGS + ADMIN_VIEW}
          component={({ match }) => (
            <AdminTagsViewContainer keyName={'admin-tags-view'} params={match.params} />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_TAGS + ADMIN_EDIT}
          component={({ match }) => (
            <AdminTagsFormContainer
              keyName={'admin-tags-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_TAGS + ADMIN_NEW}
          component={() => <AdminTagsFormContainer keyName={'admin-tags-edit'} isEdit={false} />}
        />
        <AdminRoute exact path={ADMIN_ACTIVITIES} component={() => <AdminActivitiesContainer />} />
        <AdminRoute
          exact
          path={ADMIN_ACTIVITIES + ADMIN_NEW}
          component={() => (
            <AdminActivitiesFormContainer keyName={'admin-activities-edit'} isEdit={false} />
          )}
        />
        <AdminRoute exact path={ADMIN_COURSES} component={() => <AdminCoursesContainer />} />
        <AdminRoute
          exact
          path={ADMIN_COURSES_BASIC + ADMIN_EDIT}
          component={({ match }) => (
            <AdminCoursesBasicFormContainer
              keyName={'admin-course-content-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_COURSES_BASIC + ADMIN_NEW}
          component={() => (
            <AdminCoursesBasicFormContainer keyName={'admin-course-content-edit'} isEdit={false} />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_COURSES_TOPTEN}
          component={() => (
            <AdminCoursesTopTenFormContainer keyName={'admin-courses-top'} isEdit={true} />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_COURSES_BLOCK + ADMIN_EDIT}
          component={({ match }) => (
            <AdminCoursesBlockFormContainer
              keyName={'admin-course-content-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_COURSES + ADMIN_VIEW}
          component={({ match }) => <AdminCoursesViewContainer courseId={match.params.id} />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS}
          component={() => <AdminReportsContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_NPS_COURSES}
          component={() => <AdminReportsNpsCoursesContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_COURSE_ACTIVITIES}
          component={() => <AdminReportsCourseActivities />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_CROSS}
          component={() => <AdminReportsCrossCollaboration />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_NPS_COMMISSIONS}
          component={() => <AdminReportsNpsCommissionsContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_EXTERNAL_COURSES}
          component={() => <AdminReportsExternalCoursesContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_EXTERNAL_COURSES_ROADMAP}
          component={() => <AdminReportsExternalCoursesRoadmapContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_COMMISSIONS}
          component={() => <AdminReportsCommissionsContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_ONLINE_COURSES}
          component={() => <AdminReportsOnlineCoursesContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_USER_CAREER_PLANS}
          component={() => <AdminReportsUserCareerPlansContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_USER_GLOBAL_CAREER_PLANS}
          component={() => <AdminReportsUserGlobalCareerPlansContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_CAREER_PLANS}
          component={() => <AdminReportsCareerPlansContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_CAREER_PLAN_COURSES}
          component={() => <AdminReportsCareerPlanCoursesContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_NPS_COMMENTS}
          component={() => <AdminReportsNpsCommentsContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_NPS_EXTERNAL_COURSES_COMMENTS}
          component={() => <AdminReportsNpsExternalCoursesCommentsContainer />}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_EXTERNAL_FORMATIONS}
          component={AdminReportsExternalFormationsContainer}
        />
        <AdminOrReportingRoute
          exact
          path={ADMIN_REPORTS_QUIZ_TRIVIA_ACTIVITIES}
          component={AdminReportsQuizTriviaActivities}
        />
        <AdminRoute exact path={ADMIN_JOBS} component={() => <AdminJobs />} />
        <AdminRoute
          exact
          path={ADMIN_JOBS + ADMIN_NEW}
          component={() => {
            return <AdminJobsForm title={'Nueva Carga'} type={'jobs'} isEdit={false} />
          }}
        />
        <AdminRoute
          exact
          path={ADMIN_EXTERNAL_COURSES}
          component={() => <AdminExternalCoursesContainer />}
        />
        <AdminRoute
          exact
          path={ADMIN_EXTERNAL_COURSES + ADMIN_NEW}
          component={({ match }) => (
            <AdminExternalCoursesStepperContainer
              keyName={'admin-external-courses-edit'}
              params={match.params}
              isEdit={false}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_EXTERNAL_COURSES + ADMIN_EDIT}
          component={({ match }) => (
            <AdminExternalCoursesStepperContainer
              keyName={'admin-external-courses-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_EXTERNAL_COURSES_COMMISSIONS}
          component={({ match }) => (
            <AdminExternalCoursesCommissionsContainer
              externalCourseId={match.params.externalCourseId}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_EXTERNAL_COURSES_COMMISSIONS + ADMIN_NEW}
          component={({ match }) => (
            <AdminExternalCoursesCommissionsStepperContainer
              externalCourseId={match.params.externalCourseId}
              isEdit={false}
              params={match.params}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_EXTERNAL_COURSES_COMMISSIONS + ADMIN_DUPLICATE}
          component={({ match }) => (
            <AdminExternalCoursesCommissionsStepperContainerDuplicate
              externalCourseId={match.params.externalCourseId}
              params={match.params}
              keyName={'admin-commission-duplicate'}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_EXTERNAL_COURSES_COMMISSIONS + ADMIN_EDIT}
          component={({ match }) => (
            <AdminExternalCoursesCommissionsStepperContainer
              externalCourseId={match.params.externalCourseId}
              isEdit={true}
              params={match.params}
              keyName={'admin-commission-edit'}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_COMMISSIONS_USERS}
          component={({ match }) => (
            <AdminCommissionsUsers
              commissionId={match.params.commissionId}
              keyName={'admin-commission-users'}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_FACILITATORS}
          component={() => <AdminFacilitatorsContainer />}
        />
        <AdminRoute
          exact
          path={ADMIN_FACILITATORS + ADMIN_NEW}
          component={() => <AdminFacilitatorsFormContainer isEdit={false} />}
        />
        <AdminRoute
          exact
          path={ADMIN_FACILITATORS + ADMIN_EDIT}
          component={({ match }) => (
            <AdminFacilitatorsFormContainer
              keyName={'admin-facilitator-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_REFERENTS_PROVIDERS}
          component={() => <AdminReferentsProvidersContainer />}
        />
        <AdminRoute
          exact
          path={ADMIN_REFERENTS_PROVIDERS + ADMIN_NEW}
          component={() => <AdminReferentsProvidersFormContainer isEdit={false} />}
        />
        <AdminRoute
          exact
          path={ADMIN_REFERENTS_PROVIDERS + ADMIN_EDIT}
          component={({ match }) => (
            <AdminReferentsProvidersFormContainer
              keyName={'admin-external-reference-edit'}
              params={match.params}
              isEdit={true}
            />
          )}
        />
        <AdminRoute
          exact
          path={ADMIN_SCORMS + ADMIN_EDIT}
          component={({ match }) => <AdminScormConfigPanel scormId={match.params.id} />}
        />
      </Switch>
    </div>
  )
}

export default withStyles(styles)(AdminMain)
