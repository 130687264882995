const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    card: {
      minHeight: 210,
      margin: 32,
      marginTop: 16,
      marginBottom: 16,
      borderRadius: 1,
      position: 'relative',
      display: 'flex',
      transition: 'all 450ms',
      '&-closed': {
        minHeight: 210,
      },
      '&-open': {
        height: '100%',
      },
      [mediaLg]: {
        margin: 16,
        flexDirection: 'column',
      },
    },
    cardContent: {
      padding: 32,
      paddingRight: 66,
      paddingTop: 24,
      flex: '1 0 auto',
    },
    cardContentWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    coverIcon: {
      color: 'white',
      fontSize: 100,
      textAlign: 'center',
      [mediaLg]: {
        fontSize: 32,
      },
    },
    activityType: {
      fontWeight: 700,
      fontSize: 16,
      color: '#434343',
      textTransform: 'uppercase',
      paddingBottom: 16,
      display: 'flex',
    },
    iconWrapped: {
      marginRight: 8,
      [mediaLg]: {},
    },
    title: {
      //fontMod
      fontWeight: 600,
      fontSize: 20,
      color: '#434343',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      paddingBottom: 10,
    },
    titleIcon: {
      marginBottom: -2,
      fontWeight: 700,
      fontSize: 16,
    },
    shortDescription: {
      fontSize: 18,
      lineHeight: 1.2,
      color: '#777777',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
    },
    content: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 0,
      paddingRight: 0,
      position: 'relative',
    },
    imageWrapper: {
      width: '100%',
      textAlign: 'center',
    },
    imageContent: {
      margin: 'auto',
      maxHeight: 300,
      [mediaLg]: {
        width: '100%',
      },
    },
    cardMedia: {
      width: '100%',
      height: '100%',
    },
    cardCover: {
      position: 'relative',
      marginTop: 30,
      width: 150,
      height: 150,
      display: 'flex',
      flexShrink: 0,
      [mediaLg]: {
        display: 'none',
      },
    },
    cardCoverIcon: {},
    embeddedContent: {
      width: '100%',
      maxHeight: 300,
      border: 'none',
      overflow: 'scroll',
    },
    embeddedContentPDF: {
      height: 1000, // establece una altura dinámica para el contenedor
      width: '100%',
      border: 'none',
      overflow: 'hidden', // oculta cualquier contenido que sobresalga del contenedor
    },
    iframeOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0)',
      zIndex: 1000,
    },
    textContentWrapper: {
      transition: 'max-height 0.8s ease-in',
    },
    text: {
      fontSize: 12,
      color: '#777777',
    },
    viewMore: {
      textAlign: 'center',
      width: '100%',
      color: '#000000',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    blocked: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(156,156,156,0.87)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1001,
      cursor: 'pointer',
    },
    lock: {
      textAlign: 'center',
      color: 'white',
      fontSize: 100,
    },
    lockDescription: {
      textAlign: 'center',
      color: 'white',
      fontSize: 14,
    },
    done: {
      position: 'absolute',
      top: 16,
      right: 34,
      color: '#34cb62',
      fontSize: 32,
      zIndex: 4,
    },
    fullscreen: {
      float: 'right',
    },
    buttonOpen: {
      position: 'absolute',
      bottom: 32,
      right: 66,
    },
    buttonView: {
      position: 'absolute',
      right: 66,
      fontWeight: '700',
      top: '104px',
    },
    buttonViewOpen: {
      position: 'absolute',
      right: 66,
      color: 'white',
      backgroundColor: '#1ed1a8',
      fontWeight: '700',
      top: '104px',
    },
    icon: {
      marginRight: 4,
      fontSize: 16,
    },
    buttonPlaceholder: {
      height: 48,
    },
    openNewTabButton: {
      float: 'right',
    },
    required: {
      color: '#1ed1a8',
      fontSize: 10,
    },
    iframe: {
      width: '100%',
      height: '1000px',
    },
  }
}

export default styles
