import React, { Component } from 'react'
import { Typography, LinearProgress, Tooltip } from '@material-ui/core'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import { inverted_progressbar_theme } from '../../themes/inverted_progressbar_theme'
import CheckCircleIcon from '../../assets/images/check_green.png'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Avatar from '@material-ui/core/Avatar'
import CourseSharedShowDialog from '../../containers/courses/CourseSharedShowDialog'
import EventListenerWrapper from './EventListenerWrapper'
import RequiredIcon from '../../assets/images/ico_necesario.png'
import ComplementaryIcon from '../../assets/images/ico_complementario.png'
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
  root: {
    height: '100%',
    backgroundColor: '#000000',
    display: 'block',
    position: 'relative',
    '&_content': {
      zIndex: 10,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      float: 'left',
      '&_bigContent': {
        opacity: 0,
        zIndex: 0,
      },
      '&_miniContent': {
        opacity: 1,
        transition: 'all 0.45s ease-out',
        transitionDelay: '300ms',
        backgroundImage: 'linear-gradient(rgba(84, 84, 84, 0),#000000)',
      },
    },
    '&:hover > &_content_bigContent': {
      opacity: 1,
      zIndex: 10,
      transition: 'all 0.45s ease-out',
      transitionDelay: '300ms',
      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0),#000000)',
    },
    '&:hover > &_content_miniContent': {
      opacity: 0,
      zIndex: 0,
    },
    '&:focus > &_content_bigContent': {
      opacity: 1,
      zIndex: 10,
      transition: 'all 0.45s ease-out',
      transitionDelay: '300ms',
      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0),#000000)',
    },
    '&:focus > &_content_miniContent': {
      opacity: 0,
      zIndex: 0,
    },
    '&--overlay': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: '#000000',
      opacity: 0.25,
      zIndex: 5,
      transition: 'all 0.45s ease-out',
    },
    '&:hover &--overlay': {
      opacity: 0,
      zIndex: 0,
    },
    '&:focus &--overlay': {
      opacity: 0,
      zIndex: 0,
    },
  },
  contentPadding: {
    padding: 8,
  },
  miniTitle: {
    color: '#ffffff',
    fontSize: 16,
    paddingBottom: 4,
  },
  bigTitle: {
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 4,
  },
  bigSubtitle: {
    color: '#ffffff',
    fontSize: 12,
  },
  npsScore: {
    color: '#ffffff',
    fontSize: 12,
    float: 'left',
  },
  star: {
    fontSize: 14,
    marginBottom: -2,
  },
  check: {
    position: 'absolute',
    bottom: 8,
    right: 12,
    fontWeight: 600,
    fontSize: 8,
    borderRadius: 2,
    backgroundImage: 'none !important',
  },
  image: {
    height: '100%',
  },
  progress: {
    margin: 'auto',
  },
  rating: {},
  ceilItem: {
    position: 'absolute',
    float: 'left',
    marginBottom: 3,
    top: 8,
    right: 0,
    zIndex: 11,
    backgroundImage: 'none !important',
  },
  childCeilItem: {
    display: 'flex',
    float: 'left',
    fontWeight: 400,
    color: '#000000',
    fontSize: 10,
    borderRadius: 40,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
    backgroundColor: 'rgba(255,255,255,0.7)',
    transform: 'scale(0.8)',
  },
  childCeilItemIcon: {
    display: 'flex',
    float: 'left',
    fontWeight: 700,
    color: '#fff',
    fontSize: 8,
    borderRadius: 2,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
    backgroundImage: 'none !important',
    cursor: 'pointer',
  },

  categories: {
    width: '100%',
    float: 'left',
  },
  category: {
    color: '#fff',
    fontSize: 10,
    float: 'left',
    marginRight: 4,
    marginBottom: 4,
  },
  tags: {
    marginTop: 2,
    marginBottom: 3,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  titleProgressContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progressNumber: {
    display: 'block',
    color: '#fff',
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: 8,
    background: '#000000',
    border: 'none',
  },
  avatarSection: {
    display: 'flex',
    float: 'left',
    marginBottom: 3,
  },
  avatarDefault: {
    width: 23,
    height: 23,
    fontSize: 8,
    border: 'none',
    background: '#ffffff',
    color: '#101010',
  },
  shared: {
    fontSize: 12,
    marginLeft: 5,
    marginTop: 3,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  mandatory: {
    position: 'absolute',
    opacity: 1,
    top: 0,
    left: 8,
    fontWeight: 700,
    color: '#fff',
    fontSize: 8,
    borderRadius: 2,
    paddingTop: 1,
    paddingBottom: 4,
    backgroundImage: 'none !important',
  },
})

class CourseItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openCourseShared: false,
    }
  }

  handleOpenModal = e => {
    e.stopPropagation()
    this.setState({ openCourseShared: true })
    return false
  }

  handleRemoveShared = e => {
    e.stopPropagation()
    const { item, onRemoveSharedCourse } = this.props
    onRemoveSharedCourse(item)
    return false
  }

  handleRemoveSharedAndDismiss = e => {
    e.stopPropagation()
    const { item, onRemoveSharedCourse } = this.props
    onRemoveSharedCourse(item)
    this.setState({ openCourseShared: false })
    return false
  }

  handleOpenCourse = (e, redirect = false) => {
    const { item, itemClick } = this.props
    if (redirect) {
      itemClick(item)
    }
  }

  render() {
    const { classes, item, showFlags = false } = this.props

    let userInfo = { total_activities_done: 0, progress: 0 }
    if (item && item.user_courses && item.user_courses.length === 1) {
      userInfo = item.user_courses[0]
      userInfo.progress = (userInfo.total_activities_done / (item.total_activities || 1)) * 100
    }

    return (
      <EventListenerWrapper
        TagType={'div'}
        className={classes.root}
        tabIndex={0}
        role={'link'}
        aria-label={item.title}
        onEnter={e => this.handleOpenCourse(e, true)}
      >
        <div
          className={classes.root + '--overlay'}
          onClick={e => {
            this.handleOpenCourse(e, true)
          }}
        />
        <div className={classes.imageWrapper} style={{ backgroundImage: `url('${item.image}')` }} />
        <div className={classes.root + '_content ' + classes.root + '_content_bigContent'}>
          <div className={classes.contentPadding}>
            {item.senders && item.senders.length > 0 && (
              <React.Fragment>
                <div className={classes.avatarSection}>
                  <AvatarGroup
                    aria-hidden={true}
                    max={3}
                    classes={{
                      avatar: classes.avatarDefault,
                    }}
                  >
                    {item.senders.map(user => {
                      if (!user.first_name) return
                      return (
                        <Avatar
                          style={{
                            height: 24,
                            width: 24,
                            background: '#000000',
                            border: 'none',
                            color: '#ffffff',
                          }}
                          key={uuidv4()}
                        >
                          <Typography style={{ fontSize: 10 }}>
                            {user.first_name.charAt(0).toUpperCase()}
                            {user.last_name.charAt(0).toUpperCase()}
                          </Typography>
                        </Avatar>
                      )
                    })}
                  </AvatarGroup>

                  {item.senders.length > 0 && (
                    <Typography className={classes.shared} onClick={e => this.handleOpenModal(e)}>
                      {item.senders.length > 1
                        ? item.senders.length + ' veces recomendado'
                        : '1 vez recomendado'}
                    </Typography>
                  )}
                </div>
              </React.Fragment>
            )}

            {item.comments_enabled && item.nps_score !== null && (
              <React.Fragment>
                <Typography className={classes.npsScore}>
                  <StarOutlinedIcon iconname={'ic_star_outline'} className={classes.star} />
                  NPS {parseInt(item.nps_score)}
                </Typography>
              </React.Fragment>
            )}

            {item.total_activities > 0 && (
              <>
                <div className={classes.titleProgressContainer}>
                  <Typography component={'h3'} className={classes.bigTitle}>
                    {item.title}
                  </Typography>
                  <Typography
                    aria-hidden={true}
                    className={classes.progressNumber}
                  >{`${userInfo.progress.toFixed(0)}%`}</Typography>
                </div>

                <MuiThemeProvider theme={inverted_progressbar_theme}>
                  <LinearProgress
                    className={classes.progress}
                    variant="determinate"
                    value={userInfo.progress}
                  />
                </MuiThemeProvider>
              </>
            )}
          </div>
        </div>
        <div className={classes.ceilItem + ' ' + classes.root + '_content_bigContent'}>
          <Typography className={classes.childCeilItem}>CURSO ONLINE</Typography>
          {item.senders && item.senders.length > 0 && (
            <Tooltip title={'Descartar recomendación'}>
              <Typography
                className={classes.childCeilItemIcon}
                onClick={e => this.handleRemoveShared(e)}
              >
                <HighlightOffTwoToneIcon iconname={'ic_star_outline'} className={classes.star} />
              </Typography>
            </Tooltip>
          )}
        </div>

        {showFlags && (
          <Typography className={classes.mandatory}>
            {item.required && (
              <img src={RequiredIcon} width="20px" height="28px" alt={'Necesario'} />
            )}
            {!item.required && (
              <img src={ComplementaryIcon} width="20px" height="28px" alt={'Complementario'} />
            )}
          </Typography>
        )}

        <div
          aria-hidden={true}
          className={classes.ceilItem + ' ' + classes.root + '_content_miniContent'}
        >
          <Typography className={classes.childCeilItem}>CURSO ONLINE</Typography>
        </div>
        <div
          aria-hidden={true}
          className={classes.root + '_content ' + classes.root + '_content_miniContent'}
        >
          <div className={classes.contentPadding}>
            <Typography className={classes.miniTitle}>{item.title}</Typography>
            <Typography className={classes.check}>
              {userInfo.progress === 100 && (
                <img src={CheckCircleIcon} width="30px" height="30px" alt={'Progress'} />
              )}
            </Typography>
          </div>
        </div>
        {item.senders && item.senders.length > 0 && (
          <CourseSharedShowDialog
            sharedCourses={item.senders}
            course={item}
            keyName={'course-reviews-dialog-' + item.id}
            onRemoveSharedCourse={this.handleRemoveSharedAndDismiss}
            headerColor={item.courseColor}
            open={this.state.openCourseShared}
            onDismiss={e => {
              this.setState({ openCourseShared: false })
            }}
          />
        )}
      </EventListenerWrapper>
    )
  }
}

export default withStyles(styles)(CourseItem)
