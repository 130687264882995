import React from 'react';
import {FormControlLabel, Switch} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const styles = {
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: '#3abf91',
    },
    '&$checked + $track': {
      backgroundColor: '#d5d5d5',
    },
  },
  checked: {},
  track: {},
};

const GreenSwitch = withStyles(styles)(Switch);

const SwitchInput = ({value, checked, onChange, label}) => {
  return (
    <FormControlLabel
      control={<GreenSwitch value={value} checked={checked} onChange={onChange} color="primary"/>}
      label={label}
    />
  );
};

export default SwitchInput;
