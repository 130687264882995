/**
 * @see https://reactjs.org/docs/javascript-environment-requirements.html
 */
/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
//import 'classlist.js'; // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/object';
import 'core-js/es7/array';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/react-responsive-carousel/lib/styles/carousel.min.css'
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { store, history } from './store';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import {SW_INIT, SW_UPDATE} from "./actions";
import * as ReactGA from "react-ga";
import config from '../src/config';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={config.RECAPTCHA_SECRET_KEY}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
    ,
  </GoogleReCaptchaProvider>,
  document.getElementById('root'),
);

serviceWorker.register({
    onSuccess: () => store.dispatch({ type: SW_INIT }),
    onUpdate: registration =>
        store.dispatch({ type: SW_UPDATE, payload: registration }),
});
