import React, { useEffect } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  PROFILE_ME,
  COURSES_EXPLORE,
  COURSES_EXPLORE_CATEGORY,
  COURSES_VIEW,
  HOME,
  SEARCH,
  TOKEN,
  IDFW,
  IDFWOB,
  CAREER_PLANS,
  EXPLORE_CAREER_PLANS,
  DETAILS_CAREER_PLANS,
  EXTERNAL_COURSES_VIEW,
  EXTERNAL_COURSES_NEXT_EVENTS,
  PLAYLISTS,
  PLAYLIST_VIEW,
} from '../config/routes'
import HomeContainer from '../containers/home/Home'
import CourseContainer from '../containers/courses/Course'
import CoursesExploreContainer from '../containers/explore/CoursesExploreContainer'
import CategoriesContainer from '../containers/categories/CategoriesContainer'
import Profile from '../containers/profile/ProfileContainer'
import CareerPlansContainer from '../containers/career/CareerPlansContainer'
import CareerPlansDetailContainer from '../containers/career/CareerPlansDetailContainer'
import ExplorePlans from '../containers/career/ExplorePlansContainer'
import Search from '../containers/search/SearchContainer'
import SetTokenContainer from '../containers/session/SetTokenContainer'
import IdFw from './session/IdFw'
import OpenBankLogin from './session/OpenBankLogin'
import * as ReactGA from 'react-ga'
import ExternalCourseContainer from '../containers/externalCourses/ExternalCourseContainer'
import NextEventsContainer from '../containers/externalCourses/NextEventsContainer'
import PlaylistsContainer from '../containers/playlists/PlaylistsContainer'
import PlaylistContainer from '../containers/playlists/PlaylistContainer'
import handleBot from './bot/bot'

const styles = theme => ({
  content: {
    flexGrow: 1,
    minWidth: 0, // So the Typography noWrap works,
    maxWidth: 'calc(100vw - 0%)',
  },
  toolbar: theme.mixins.toolbar,
})

function Main(props) {
  const { classes, me, location } = props

  useEffect(() => {
    if (me !== null) {
      handleBot()
    }
  }, [location, me])

  if (!window.location.pathname.includes(TOKEN) && !window.location.search.includes(TOKEN)) {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  return (
    <div className={classes.content}>
      <Switch>
        <Route
          exact
          path={TOKEN}
          component={({ location: { search } }) => <SetTokenContainer search={search} />}
        />
        <Route exact path={IDFWOB} component={() => <OpenBankLogin />} />
        <Route exact path={IDFW} component={() => <IdFw />} />
        <Route exact path={HOME} component={() => <HomeContainer user={me} />} />
        <Route
          exact
          path={COURSES_VIEW}
          component={({ match }) => <CourseContainer courseId={match.params.id} />}
        />
        <Route exact path={COURSES_EXPLORE} component={() => <CategoriesContainer />} />
        <Route
          exact
          path={COURSES_EXPLORE_CATEGORY}
          component={({ match }) => (
            <CoursesExploreContainer categoryId={match.params.categoryId} />
          )}
        />
        <Route
          exact
          path={PROFILE_ME}
          component={({ location: { search } }) => <Profile search={search} />}
        />
        <Route exact path={SEARCH} component={() => <Search />} />

        <Route exact path={CAREER_PLANS} component={() => <CareerPlansContainer />} />
        <Route exact path={EXPLORE_CAREER_PLANS} component={() => <ExplorePlans />} />
        <Route
          exact
          path={DETAILS_CAREER_PLANS}
          component={({ match }) => <CareerPlansDetailContainer planId={match.params.planId} />}
        />

        <Route
          exact
          path={EXTERNAL_COURSES_VIEW}
          component={({ match }) => <ExternalCourseContainer externalCourseId={match.params.id} />}
        />

        <Route
          exact
          path={EXTERNAL_COURSES_NEXT_EVENTS}
          component={() => <NextEventsContainer />}
        />

        <Route exact path={PLAYLISTS} component={() => <PlaylistsContainer />} />

        <Route
          exact
          path={PLAYLIST_VIEW}
          component={({ match }) => <PlaylistContainer playlistId={match.params.id} />}
        />

        <Route path="*" component={() => <Redirect to={HOME} />} />
      </Switch>
    </div>
  )
}

export default withRouter(withStyles(styles)(Main))
