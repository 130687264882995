import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { isEmpty } from 'lodash';
import Loading from '../../../components/ui/Loading';
import { addSnackbar } from '../../../actions/snackbar';
import { SnackbarType, LotType, LotStatus } from '../../../config/constants';
import { goToAdminLot, goTo } from '../../../actions/navigator';
import { createResource, updateResource, getResource, } from '../../../actions/admin';
import { loadCoursesForLot, loadJobPositionsForLot, loadOrganizationUnitsForLot, loadCompanyForLot} from '../../../actions/lotAdmin';
import api from '../../../api/api';
import { appLoadToken } from '../../../actions/app';
import PropTypes from 'prop-types';
import StepOne from '../../../components/admin/lots/StepOne';
import StepTwo from '../../../components/admin/lots/StepTwo';
import StepThree from '../../../components/admin/lots/StepThree';
import StepFour from '../../../components/admin/lots/StepFour';
import { ADMIN_LOTS } from '../../../config/routes';
import StepUserAssignationComponent from '../../../components/admin/lots/StepUserAssignationComponent';

const steps = ['Datos Básicos', 'Cursos asociados', 'Asignación'];
const [firstStep, secondStep, lastStep] = steps.map((_, id) => id);

const styles = (theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    padding: '1em 8em',
    boxSizing: 'border-box',
    background: '#454545',
  },
  titleHeader: {
    color: '#fff',
    fontSize: 20,
  },
  root: {
    width: '100%',
    margin: '50px 0 20px 0',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  step: {
    root: {
      color: '#FF00FF11',
    },
  },
});

class AdminLotsStepperContainer extends Component {

  state = {
    loading: true,
    isValid: false,
    lotProps: {
      id: false,
      name: '',
      type: '',
      category: '',
      courses: [],
      users: [],
      status: null,
      company: [],
      assignations: {
        all_organizational_units: 0,
        job_position: [],
        organizational_unit: [],
      }
    },
    activeStep: 0,
    coursesSelected: false,
    usersSelected: [],
    jobPositionsSelected: [],
    companySelected: [],
    organizationalUnitsSelected: [],
    allOrganizationalUnitsSelected: false,
    stepOneHasChanged: false,
    stepTwoHasChanged: false,
    stepThreeHasChanged: false,
    stepFourHasChanged: false
  };

  componentDidMount = async () => {
    const {
      appLoadToken,
      loadCoursesForLot,
      loadJobPositionsForLot,
      loadCompanyForLot,
      loadOrganizationUnitsForLot
    } = this.props;

    try {
      if (this.props.isEdit) this.onLoadData();

      appLoadToken().then(() => {
        loadCoursesForLot();
        loadJobPositionsForLot();
        loadCompanyForLot();
        loadOrganizationUnitsForLot();
      });
      if (!this.props.isEdit) this.setState({ loading: false });
    }
    catch (error) {
      console.error(error)
    }
  }

  onLoadData = () => {
    const { getResource, params, keyName } = this.props;
    getResource(keyName, 'lots', params.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.item && nextProps.item) {
      const {
        id,
        name,
        type,
        category,
        courses,
        users,
        status,
        assignations,
        company
      } = nextProps.item;

      this.setState({
        lotProps: {
          id: id || false,
          name: name || '',
          type: type || '',
          category: category || '',
          courses: courses || [],
          users: users || [],
          company: company || [],
          status: status || null,
          assignations: {
            all_organizational_units: assignations.all_organizational_units || false,
            job_position: assignations.job_position || [],
            organizational_unit: assignations.organizational_unit || [],
          }
        },
        coursesSelected: [],
        usersSelected: users || []

      });
      this.updateAssignations();
    }

    if (this.state.lotProps.id) {
      this.setState({
        loading: false
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.lotMetadata !== this.props.lotMetadata && this.state.lotProps.id !== undefined && this.state.lotProps.id !== '') {
      this.updateAssignations()
    }
  }

  updateAssignations = () => {
    if (!this.state.lotProps.assignations) return;

    if (!isEmpty(this.props.lotMetadata.users) && this.state.lotProps.users.length) {
      this.selectedUsers(this.state.lotProps.users, this.props.lotMetadata.users);
    }
    if (!isEmpty(this.props.lotMetadata.courses) && this.state.lotProps.courses.length) {
      this.selectedCourses(this.state.lotProps.courses, this.props.lotMetadata.courses);
    }
    if (!isEmpty(this.props.lotMetadata.jobPositions) && this.state.lotProps.assignations.job_position.length) {
      this.selectedJobPositions(this.state.lotProps.assignations.job_position, this.props.lotMetadata.jobPositions);
    }
    if (!isEmpty(this.props.lotMetadata.company) && this.state.lotProps.company.length) {
      this.selectedCompany(this.state.lotProps.company, this.props.lotMetadata.company);
    }
    if (!isEmpty(this.props.lotMetadata.organizationalUnits) && this.state.lotProps.assignations.organizational_unit.length) {
      this.selectedOrganizationalUnits(this.state.lotProps.assignations.organizational_unit, this.props.lotMetadata.organizationalUnits);
    }

    // TODO: ver acá qué hacer con users
    if (!isEmpty(this.props.lotMetadata.courses)) {
      this.setState({
        disableFirstNextButton: false
      });
    }

    if (this.state.lotProps.id && this.state.lotProps.assignations !== undefined && this.state.lotProps.assignations.all_organizational_units !== 0) {
      this.setState({
        allOrganizationalUnitsSelected: this.state.lotProps.assignations.all_organizational_units
      });
    }
  }

  increment = (prevState) => {
    this.setState((prevState) => ({
      activeStep: this.state.activeStep + 1
    }));
  }
  decrement = (prevState) => {
    this.setState((prevState) => ({
      activeStep: this.state.activeStep - 1
    }));
  }

  selectedUsers = (lotUsers, users) => {
    this.setState((prevState) => ({
      usersSelected: lotUsers
    }));
  }

  selectedCourses = (lotCourses, courses) => {
    let coursesSelected = courses.data
      .filter((e1) => lotCourses.find(e2 => e1.id === e2.id))
      .map((e1) => {
        let lotCourseData = lotCourses.find(e2 => e1.id === e2.id)
        e1.mandatory = lotCourseData.mandatory;
        return e1;
      });

    this.setState((prevState) => ({
      coursesSelected: coursesSelected
    }));
  }

  selectedJobPositions = (lotJobPositions, jobPositions) => {
    let jobPositionsSelected = jobPositions.data.filter((e1) => lotJobPositions.includes(e1.id));
    this.setState((prevState) => ({
      jobPositionsSelected: jobPositionsSelected
    }));
  }

  selectedCompany = (lotCompany, company) => {
    let companySelected = company.data.filter((e1) => lotCompany.includes(e1.id));
    this.setState((prevState) => ({
      companySelected: companySelected
    }));
  }

  selectedOrganizationalUnits = (lotOrganizationalUnits, organizationalUnits) => {
    let organizationalUnitsSelected = organizationalUnits.data.filter((e1) => lotOrganizationalUnits.includes(e1.id));
    this.setState((prevState) => ({
      organizationalUnitsSelected: organizationalUnitsSelected
    }));
  }

  handleNextStep = async () => {
    const { addSnackbar } = this.props;
    if (firstStep === this.state.activeStep && this.state.stepOneHasChanged) {
      const callbackSuccess = () => {
        addSnackbar('Grupo editado con éxito', SnackbarType.Success);
        this.increment(this.state.activeStep);
      };
      const callbackError = (error) =>
        addSnackbar(error.message, SnackbarType.Warning);

      return this.updateOrCreateLot(callbackSuccess, callbackError);
    }

    if (secondStep === this.state.activeStep && this.state.stepTwoHasChanged) {
      const callbackSuccess = () => {
        addSnackbar('Cursos asociados con éxito', SnackbarType.Success);
        this.increment(this.state.activeStep);
      };
      const callbackError = (error) =>
        addSnackbar(error, SnackbarType.Warning);

      return this.updateLotCourses(callbackSuccess, callbackError);
    }

    this.increment(this.state.activeStep);
  };

  createPayloadToDraft = () => {
    return {
      all_organizational_units: this.state.allOrganizationalUnitsSelected,
      job_position: this.state.jobPositionsSelected.map((element) => element.id),
      company: this.state.companySelected.map((element) => element.id),
      organizational_unit: this.state.organizationalUnitsSelected.map((element) => element.id),
      lot_status: LotStatus.Draft  // lot draft status
    };
  };

  validatePayloadToDraft = (payload) => {
    return ((payload.company) || (payload.all_organizational_units && payload.job_position.length > 0) || (!payload.all_organizational_units && (payload.job_position.length > 0 && payload.organizational_unit.length > 0)));
  }

  handleSaveAssignations = async () => {
    const { addSnackbar } = this.props;
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);
    const callbackSuccess = () => {
      addSnackbar('Borrador guardado con éxito', SnackbarType.Success);
      this.props.goTo(ADMIN_LOTS);
      //goToAdminLot();
    };

    const payload = this.createPayloadToDraft();
    if (!this.validatePayloadToDraft(payload)) {
      callbackError({ message: 'Debe ingresar puesto y centro de costo.' });
      return;
    }

    try {
      await api.Lot.setLotAssignations(this.state.lotProps.id, payload);
      await callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  }

  handleSaveCompany = async () => {
    const { addSnackbar } = this.props;
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);
    const callbackSuccess = () => {
      addSnackbar('Borrador guardado con éxito', SnackbarType.Success);
      this.props.goTo(ADMIN_LOTS);
      //goToAdminLot();
    };

    const payload = this.createPayloadToDraft();
    if (!this.validatePayloadToDraft(payload)) {
      callbackError({ message: 'Debe ingresar empresa.' });
      return;
    }

    try {
      await api.Lot.setLotCompany(this.state.lotProps.id, payload);
      await callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  }


  handlePublish = async () => {
    const { addSnackbar } = this.props;
    const callbackSuccess = () => {
      addSnackbar('Grupo publicado', SnackbarType.Success);
      this.props.goTo(ADMIN_LOTS);
    };
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);

    const payload = this.createPayloadToDraft();
    if (!this.validatePayloadToDraft(payload)) {
      callbackError({ message: 'Debe ingresar puesto y centro de costo.' });
      return;
    }

    payload['lot_status'] = LotStatus.Published; // lot published status


    try {
      await api.Lot.setLotAssignations(this.state.lotProps.id, payload);
      callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  };

  handleCompanyPublish = async () => {
    const { addSnackbar } = this.props;
    const callbackSuccess = () => {
      addSnackbar('Grupo publicado', SnackbarType.Success);
      this.props.goTo(ADMIN_LOTS);
    };
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);

    const payload = this.createPayloadToDraft();

    if (!this.validatePayloadToDraft(payload)) {
      callbackError({ message: 'Debe ingresar una empresa.' });
      return;
    }

    payload['lot_status'] = LotStatus.Published; // lot published status

    try {
      await api.Lot.setLotCompany(this.state.lotProps.id, payload);
      callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  };

  handleBackStep = () => {
    if (firstStep === this.state.activeStep) {
      this.setState({ activeStep: 0 });
      this.props.goTo(ADMIN_LOTS);
      return;
    }

    if (secondStep === this.state.activeStep) {
      this.decrement(this.state.activeStep);
      return;
    }

    if (lastStep === this.state.activeStep) {
      this.decrement(this.state.activeStep);
      return;
    }
  };

  updateOrCreateLot = async (callbackSuccess, callbackError) => {
    const { isEdit } = this.props;

    const payload = {
      name: this.state.lotProps.name,
      type: this.state.lotProps.type,
      category: this.state.lotProps.category,
    };

    try {
      let lotId = (this.state.lotProps.id) ? this.state.lotProps.id : false;
      let lot = await api.Lot.createOrUpdateLot(isEdit, payload, lotId);

      lotId = lot.data.id

      await this.setState((prevState) => ({
        lotProps: {
          id: lotId,
          name: payload.name,
          type: payload.type,
          category: payload.category,
        },
        stepOneHasChanged: false
      }));

      if (!isEdit) {
        await getResource('admin-lot-edit', 'lots', lotId);
      }

      callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  };

  updateLotCourses = async (callbackSuccess, callbackError) => {
    const payload = {
      courses_ids: this.state.coursesSelected.map((element) => ({
        id: element.id,
      })),
    };

    try {
      await api.Lot.associateCourseToLot(this.state.lotProps.id, payload);
      callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
    this.setState((prevState) => ({
      stepTwoHasChanged: false
    }));
  };

  handleSaveUserAssignationsDraft = async () => {
    const { addSnackbar } = this.props;
    const payload = {
      lot_status: LotStatus.Draft,
      users_ids: this.state.usersSelected.map((element) => ({
        id: element.id,
      })),
    };

    const callbackSuccess = () => {
      addSnackbar('Borrador guardado con éxito', SnackbarType.Success);
      this.props.goTo(ADMIN_LOTS);
    };
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);

    try {
      await api.Lot.associateUserToLot(this.state.lotProps.id, payload);
      await callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  }

  handleSaveUserAssignationsPublished = async () => {
    const { addSnackbar } = this.props;
    const payload = {
      lot_status: LotStatus.Published,
      users_ids: this.state.usersSelected.map((element) => ({
        id: element.id,
      })),
    };

    const callbackSuccess = () => {
      addSnackbar('Grupo publicado', SnackbarType.Success);
      this.props.goTo(ADMIN_LOTS);
    };
    const callbackError = (error) =>
      addSnackbar(error.message, SnackbarType.Warning);

    try {
      await api.Lot.associateUserToLot(this.state.lotProps.id, payload);
      await callbackSuccess();
    } catch (error) {
      callbackError(error);
    }
  }

  handleChangeLotName = (value) => {
    this.setState((prevState) => ({
      lotProps: {
        ...this.state.lotProps,
        name: value,
      },
      stepOneHasChanged: true
    }));
  }

  handleChangeLotTypes = (value) => {
    this.setState((prevState) => ({
      lotProps: {
        ...this.state.lotProps,
        type: value
      },
      stepOneHasChanged: true
    }));
  }

  handleChangeLotCategory = (value) => {
    this.setState({
      lotProps: {
        ...this.state.lotProps,
        category: value,
      },
      stepOneHasChanged: true
    });
  }

  handleChangeLotUsers = (value) => {
    this.setState((prevState) => ({
      usersSelected: value,
      stepTwoHasChanged: true
    }));
  }

  handleChangeLotCourses = (value) => {
    this.setState((prevState) => ({
      coursesSelected: value,
      stepTwoHasChanged: true
    }));
  }

  handleChangeJobPosition = (value) => {
    this.setState(() => ({
      jobPositionsSelected: value,
      stepThreeHasChanged: true
    }));
  }

  handleChangeCompany = (value) => {
    this.setState(() => ({
      companySelected: value,
      stepFourHasChanged: true
    }));
  }

  handleChangeOrganizationalUnit = (value) => {
    this.setState(() => ({
      organizationalUnitsSelected: value,
      stepThreeHasChanged: true
    }));
  }

  handleSelectAllOu = (allSelected) => {
    if (allSelected) {
      this.setState((prevState) => ({
        allOrganizationalUnitsSelected: true,
        organizationalUnitsSelected: [],
        stepThreeHasChanged: true
      }));
    } else {
      this.setState((prevState) => ({
        allOrganizationalUnitsSelected: false,
        stepThreeHasChanged: true
      }));
    }

  }

  render() {

    const { isEdit, classes, lotMetadata } = this.props;

    if ((isEdit && this.state.loading) ||
      (isEdit && !this.state.coursesSelected) ||
      (isEdit && !this.state.lotProps.id)) {
      return <Loading dark={true} />;
    }

    Stepper.propTypes = {
      classes: PropTypes.object,
    };
    return (
      <Grid container justify="center">
        <div className={classes.header}>
          {isEdit ? (
            <Typography className={classes.titleHeader}>
              EDITAR GRUPO "{this.state.lotProps.name}"
            </Typography>
          ) : (
            <Typography className={classes.titleHeader}>
              NUEVO GRUPO "{this.state.lotProps.name}"
            </Typography>
          )}
        </div>
        <Grid container className={classes.root} justify="center">
          <Grid item xs={10}>
            <Stepper activeStep={this.state.activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} className={classes.step}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>

            {this.state.activeStep === firstStep && (
              <StepOne
                handleBack={this.handleBackStep}
                handleNext={this.handleNextStep}
                lotProps={this.state.lotProps}
                types={lotMetadata.types}
                handleChangeLotName={this.handleChangeLotName}
                handleChangeLotTypes={this.handleChangeLotTypes}
                handleChangeLotCategory={this.handleChangeLotCategory}
              />
            )}
            {this.state.activeStep === secondStep && (
              <StepTwo
                courses={lotMetadata.courses}
                coursesSelected={this.state.coursesSelected}
                handleChangeLotCourses={this.handleChangeLotCourses}
                handleBack={this.handleBackStep}
                handleNext={this.handleNextStep}
              />
            )}
            {this.state.activeStep === lastStep &&
              parseInt(this.state.lotProps.type) === LotType.JpCc && (
                <StepThree
                  jobPositions={lotMetadata.jobPositions}
                  jobPositionsSelected={this.state.jobPositionsSelected}
                  handleChangeJobPosition={this.handleChangeJobPosition}
                  organizationalUnits={lotMetadata.organizationalUnits}
                  organizationalUnitsSelected={this.state.organizationalUnitsSelected}
                  handleChangeOrganizationalUnit={this.handleChangeOrganizationalUnit}
                  handleSelectAllOu={this.handleSelectAllOu}
                  allOrganizationalUnitsSelected={this.state.allOrganizationalUnitsSelected}
                  handleBack={this.handleBackStep}
                  isPublished={this.state.lotProps.status === LotStatus.Published}
                  handlePublish={this.handlePublish}
                  handleSaveAssignations={this.handleSaveAssignations}
                  stepThreeHasChanged={this.state.stepThreeHasChanged}
                />
              )}
            {this.state.activeStep === lastStep &&
              parseInt(this.state.lotProps.type) === LotType.Users && (
                <StepUserAssignationComponent
                  lotId={this.state.lotProps.id}
                  usersSelected={this.state.usersSelected}
                  handleChangeLotUsers={this.handleChangeLotUsers}
                  handleBack={this.handleBackStep}
                  handleSaveUserAssignations={this.handleSaveUserAssignationsDraft}
                  isPublished={this.state.lotProps.status === LotStatus.Published}
                  handlePublish={this.handleSaveUserAssignationsPublished}
                />
              )}
            {
              this.state.activeStep === lastStep &&
              parseInt(this.state.lotProps.type) === LotType.Company && (
                <StepFour
                  company={lotMetadata.company}
                  companySelected={this.state.companySelected}
                  handleChangeCompany={this.handleChangeCompany}
                  handleBack={this.handleBackStep}
                  isPublished={this.state.lotProps.status === LotStatus.Published}
                  handleCompanyPublish={this.handleCompanyPublish}
                  handleSaveCompany={this.handleSaveCompany}
                  stepFourHasChanged={this.state.stepFourHasChanged}
                />
              )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    lotMetadata: state.lotAdminReducer,
    ...states[ownProps.keyName],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadCoursesForLot,
      loadJobPositionsForLot,
      loadCompanyForLot,
      loadOrganizationUnitsForLot,
      goToAdminLot,
      getResource,
      createResource,
      updateResource,
      addSnackbar,
      goTo,
      appLoadToken,
    },
    dispatch
  );
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AdminLotsStepperContainer)
  )
);
