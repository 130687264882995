import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Add } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { COLOR_KEYS, STYLE_KEYS } from '../../../config/constants'
import { blankQuestion, defaultOptions, getQuestionTypeType } from './helpers'
import { Slider, Typography } from '@material-ui/core'
import QuizTriviaTab from './QuizTriviaTab'
import { store } from '../../../store'
import QuestionSetup from './components/QuestionSetup'

const tabProps = index => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
})

const scoreLimitMsg = 'No quedan puntos disponibles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'fit-content',
    margin: '1.25rem 0',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '15.25rem',
  },
  tab: { '& .MuiTab-wrapper': { alignItems: 'unset' } },
  activeTabPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '1rem',
  },
  select: { fontWeight: 600, fontSize: '1rem', color: 'black' },
  true: {},
  dropzone: {
    height: '100%',
  },
  container: {
    maxHeight: '13rem',
    minWidth: '13rem',
    flex: 1,
    background: '#efefef',
    borderRadius: '0.375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1em',
    fontSize: '0.75rem',
    textAlign: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      background: '#dcdcdc',
    },
  },
}))

const QuizTriviaTabs = ({
  questions,
  setQuestions,
  totalScore,
  setTotalScore,
  quizEditingBlocked,
}) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)

  const [questionTypes, setQuestionTypes] = useState()

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleEditQuestion = editedQuestion => {
    if (!quizEditingBlocked) {
      setQuestions(state =>
        state.map(question => (question.id === editedQuestion.id ? editedQuestion : question)),
      )
    }
  }

  useEffect(() => {
    if (questions) {
      setTotalScore(questions.reduce((score, currentQuestion) => score + currentQuestion.score, 0))
    }
  }, [questions])

  useEffect(() => {
    setQuestionTypes(store.getState().questionsTypesReducer?.items)
  }, [])

  return (
    <div className="col">
      <div className="col" style={{ marginTop: '1.5rem' }}>
        <div className="row" style={{ justifyContent: 'space-between' }}>
          <Typography style={STYLE_KEYS.QUESTIONS_LABEL}>
            Puntaje asignado: <span style={{ fontWeight: 600 }}>{totalScore} de 100</span>
          </Typography>
          {totalScore < 100 ? (
            <Typography style={STYLE_KEYS.QUESTIONS_LABEL}>
              Pendiente de asignación:{' '}
              <span
                style={{
                  fontWeight: 600,
                  color: COLOR_KEYS.CORRECT,
                  filter: 'brightness(50%)',
                }}
              >
                {100 - totalScore} puntos
              </span>
            </Typography>
          ) : (
            <Typography
              style={{
                ...STYLE_KEYS.QUESTIONS_LABEL,
                fontWeight: 600,
                color: COLOR_KEYS.INCORRECT,
                filter: 'brightness(50%)',
              }}
            >
              {scoreLimitMsg}
            </Typography>
          )}
        </div>
        <Slider
          disabled
          value={totalScore}
          style={{
            transition: 'all ease-in-out 300ms',
            color: totalScore < 100 ? COLOR_KEYS.PRIMARY : COLOR_KEYS.CORRECT,
            filter: totalScore < 100 ? 'brightness(50%)' : 'brightness(80%)',
          }}
        />
      </div>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleChange}
          className={classes.tabs}
          indicatorColor="primary"
        >
          {questions?.map(({ questions_type, errors }, index) => {
            return (
              <Tab
                className={classes.tab}
                key={`tab-${index}`}
                style={{
                  textAlign: 'left',
                  transition: STYLE_KEYS.DEFAULT_TRANSITION,
                  backgroundColor:
                    questionTypes.find(type => type.id === questions_type)?.color + '80' ??
                    undefined,
                }}
                label={
                  <QuizTriviaTab
                    {...{
                      questionType:
                        questionTypes?.find(type => type.id === questions_type) ?? undefined,
                      errors,
                      index,
                      questionTypes,
                    }}
                  />
                }
                {...tabProps(index)}
              />
            )
          })}
          {!quizEditingBlocked && (
            <Tab
              disabled={!(totalScore < 100)}
              style={!(totalScore < 100) ? { fontSize: '0.75rem' } : undefined}
              label={totalScore < 100 ? <Add /> : scoreLimitMsg}
              onClick={() => {
                if (totalScore < 100) {
                  setQuestions(state => [...state, blankQuestion()])
                }
              }}
            />
          )}
        </Tabs>
        {questions?.map((question, index) => (
          <QuestionSetup
            key={`tab-panel-${index}-${question.id}`}
            question={question}
            index={index}
            selectedTab={selectedTab}
            classes={classes}
            handleDeleteQuestion={(idToDelete, index) => {
              if (index + 1 === questions.length) {
                setSelectedTab(state => state - 1)
              }
              setQuestions(state => state.filter(({ id }) => id !== idToDelete))
            }}
            atLeastOneQuestion={questions.length > 1}
            scoreSetter={(value, questionIndex) => {
              const actualScore = questions.reduce(
                (score, currentQuestion, index) =>
                  questionIndex !== index ? score + currentQuestion.score : score,
                0,
              )
              const scoreLeft = 100 - actualScore
              return value > scoreLeft ? scoreLeft : value
            }}
            editFields={(entries, question) => handleEditQuestion({ ...question, ...entries })}
            resetQuestion={(key, questionTypeId, question) => {
              handleEditQuestion({
                ...question,
                [key]: questionTypeId,
                options: defaultOptions[getQuestionTypeType(questionTypeId, questionTypes)],
              })
            }}
            setQuestions={setQuestions}
            quizEditingBlocked={quizEditingBlocked}
          />
        ))}
      </div>
    </div>
  )
}

export default QuizTriviaTabs
