import React, { Component } from 'react';
import config from '../../../config';
import {
  withStyles,
  Dialog,
  DialogContent,
  Radio,
  IconButton,
} from '@material-ui/core';
import validator from 'validator';
import CloseIcon from '@material-ui/icons/Close';
import { EditInputType } from '../../../config/constants';
import AdminResourceForm from '../AdminResourceForm';
import styles from './ActivityModal/ActivityModalStyles';

const TITLE_LENGTH = {
  min: 2,
  max: 80,
};

const DESCRIPTION_LENGTH = {
  min: 2,
  max: 300,
};

const LAYOUT_IMAGES = {
  1: 'right',
  2: 'center',
  3: 'left',
};

const getFormColumns = (classes) => [
  {
    source: 'layout',
    editable: true,
    editConfig: {
      type: EditInputType.RadioGroup,
      name: 'layout',
      title: 'Seleccionar tipo de Bloque',
      renderControl: (option) => (
        <Radio
          color="default"
          icon={
            <img
              src={`${config.BASE_URL}img/block_type_${
                LAYOUT_IMAGES[option.id]
                }.png`}
              alt={`Estilo de bloque ${option.id}`}
              className={classes.imgBlockType}
            />
          }
          checkedIcon={
            <img
              src={`${config.BASE_URL}img/block_type_${
                LAYOUT_IMAGES[option.id]
                }.png`}
              alt={`Estilo de bloque ${option.id}`}
              className={classes.imgBlockTypeSelected}
            />
          }
        />
      ),
      row: true,
      options: [{ id: 1 }, { id: 2 }, { id: 3 }],
    },
  },
  {
    title: 'Título',
    source: 'title',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'title' },
  },
  {
    title: 'Descripción',
    source: 'description',
    editable: true,
    editConfig: {
      type: EditInputType.Text,
      name: 'description',
      multiline: 4,
    },
  },
];

class BlockModal extends Component {
  state = {
    content: null,
    results: {
      isValid: false,
      errors: {},
    },
  };

  handleSubmit = () => {
    const data = { ...this.props.block, ...this.state.content };
    if (data.id < 0) delete data.id;
    this.props.onSubmit(data);
    this.setState({
      content: null,
      results: {
        isValid: false,
        errors: {},
      },
    });
    this.props.onClose();
  };

  validateForm = () => {
    const { content } = this.state;
    const { block } = this.props;

    const data = { ...block, ...content };

    let error = false;
    const errors = {
      title: '',
      description: '',
      layout: '',
    };

    if (!data.title || validator.isEmpty(data.title.trim())) {
      errors.title = 'Debes completar este campo';
      error = true;
    } else if (
      !validator.isLength(data.title.trim(), {
        min: TITLE_LENGTH.min,
        max: TITLE_LENGTH.max,
      })
    ) {
      errors.title = `Este campo debe tener entre ${TITLE_LENGTH.min} y ${TITLE_LENGTH.max} caracteres.`;
      error = true;
    }

    if (!data.description || validator.isEmpty(data.description.trim())) {
      errors.description = 'Debes completar este campo';
      error = true;
    } else if (
      !validator.isLength(data.description.trim(), {
        min: DESCRIPTION_LENGTH.min,
        max: DESCRIPTION_LENGTH.max,
      })
    ) {
      errors.description = `Este campo debe tener entre ${DESCRIPTION_LENGTH.min} y ${DESCRIPTION_LENGTH.max} caracteres.`;
      error = true;
    }

    if (!data.layout || validator.isEmpty(data.layout.toString())) {
      errors.layout = 'Debes seleccionar un item';
      error = true;
    }

    this.setState({
      results: {
        errors: errors,
        isValid: !error,
      },
    });
  };

  handleCancel = () => {
    this.setState({
      content: {},
      results: {
        isValid: false,
        error: {},
      },
    });
    this.props.onClose();
  };

  handleChange = (name, value) => {
    const data = { ...this.props.block, ...this.state.content };
    this.setState(
      {
        content: {
          ...data,
          [name]: value,
        },
      },
      this.validateForm
    );
  };

  render() {
    const { classes, block, onClose } = this.props;
    const { content, results } = this.state;
    const data = { ...block, ...content };


    return (
      <Dialog
        open={block !== null}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <AdminResourceForm
            title={
              <div className={classes.header}>
                {`${data && data.id > 0 ? 'Editar' : 'Nuevo'} Bloque`}
                <IconButton
                  className={classes.closeIcon}
                  onClick={this.handleCancel}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            }
            keyName="admin-activity-modal-extern-form"
            form={data}
            isValid={results && results.isValid}
            errors={results ? results.errors : []}
            loading={false}
            attributes={{ keys: getFormColumns(classes) }}
            onAttributeChange={this.handleChange}
            customClasses={classes}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
            actionButtons="2"
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(BlockModal);
