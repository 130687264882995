import React, { useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import HorizontalZoomSlider from '../ui/HorizontalZoomSlider'
import HorizontalTopTen from '../ui/HorizontalTopTen'
import CourseItemTopTen from '../ui/CourseItemTopTen'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import RequiredIcon from '@material-ui/icons/ErrorOutline'
import FinishedIcon from '@material-ui/icons/CheckCircleOutline'
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined'
import ParallaxBackground from '../ui/ParallaxBackground'
import MixedCourseItem from '../ui/MixedCourseItem'
import EventIcon from '@material-ui/icons/Event'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import Loading from '../ui/Loading'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    root: {
      backgroundColor: '#101010',
      display: 'flex',
      flexFlow: 'column',
      maxWidth: 'calc(100vw - 0%)',
      paddingTop: 24,
      paddingBottom: 50,
      paddingRight: 90,
      paddingLeft: 90,
      position: 'relative',
      [mediaLg]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    categoryIcon: {
      marginRight: 4,
      width: 20,
      height: 20,
      borderRadius: 14,
      padding: 4,
      marginBottom: -5,
    },
    icon: {
      marginBottom: -2,
      fontSize: 20,
      color: '#fff',
      marginRight: 8,
    },
    exploreButton: {
      margin: 'auto',
      marginTop: 16,
      color: 'white',
      width: 200,
      borderColor: 'white',
    },
  }
}

const ContentHome = ({
  classes,
  onGoToExplore,
  onShowCourse,
  onRemoveSharedCourse,
  courses: {
    required,
    topTen,
    variablePrice,
    inProgress,
    shared,
    finished,
    featuredCategories,
    hidden,
    objectiveDplusI
  },
  externalCourses: { nextDays, myPending },
}) => {
  const onCreateItemCourse = useCallback(
    item => {
      return <MixedCourseItem item={item} itemClick={onShowCourse} />
    },
    [onShowCourse],
  )

  const onCreateItemCourseShared = useCallback(
    item => {
      return (
        <MixedCourseItem
          item={item}
          itemClick={onShowCourse}
          onRemoveSharedCourse={onRemoveSharedCourse}
        />
      )
    },
    [onRemoveSharedCourse, onShowCourse],
  )

  const onCreateItemCourseTopTen = useCallback(
    item => {
      return <CourseItemTopTen item={item} onItemClick={() => onShowCourse(item.course)} />
    },
    [onShowCourse],
  )

  const children = useMemo(() => {
    const items = []
    if (hidden.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-hidden'}
          icon={<ControlPointIcon className={classes.icon} />}
          title={'Cross Collaboration'}
          itemCreator={item => onCreateItemCourse(item)}
          items={hidden.items}
        />,
      )
    }
    if (required.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-required'}
          icon={<RequiredIcon className={classes.icon} />}
          title={'Mis requeridos pendientes'}
          itemCreator={item => onCreateItemCourse(item)}
          items={required.items}
        />,
      )
    }
    if (objectiveDplusI.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-objectiveDplusI'}
          icon={<RequiredIcon className={classes.icon} />}
          title={'Objetivo D + I'}
          itemCreator={item => onCreateItemCourse(item)}
          items={objectiveDplusI.items}
        />,
      )
    }
    if (variablePrice.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-variablePrice'}
          icon={<FlagOutlinedIcon className={classes.icon} />}
          title={'Mis cursos para el PVI'}
          itemCreator={item => onCreateItemCourse(item)}
          items={variablePrice.items}
        />,
      )
    }

    if (myPending.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-myPending'}
          icon={<EventIcon className={classes.icon} />}
          title={'Mis próximos talleres'}
          itemCreator={item => onCreateItemCourse(item)}
          items={myPending.items}
        />,
      )
    }

    if (nextDays.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-nextDays'}
          icon={<EventAvailableIcon className={classes.icon} />}
          title={'Talleres de los próximos 15 días'}
          itemCreator={item => onCreateItemCourse(item)}
          items={nextDays.items}
        />,
      )
    }

    if (featuredCategories.items.length > 0) {
      featuredCategories.items.forEach((fc, index) => {
        items.push(
          <HorizontalZoomSlider
            key={'hzs-featuredCategories-' + index}
            icon={
              <img
                src={fc.icon}
                alt={fc.name}
                className={classes.categoryIcon}
                style={{ backgroundColor: fc.color }}
              />
            }
            title={fc.name}
            itemCreator={item => onCreateItemCourse(item)}
            items={fc.courses}
          />,
        )
      })
    }
    if (inProgress.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-inProgress'}
          icon={<PlayIcon className={classes.icon} />}
          title={'Continuar viendo'}
          itemCreator={item => onCreateItemCourse(item)}
          items={inProgress.items}
        />,
      )
    }
    if (shared.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-shared'}
          icon={<ControlPointIcon className={classes.icon} />}
          title={'Me recomendaron…'}
          itemCreator={item => onCreateItemCourseShared(item)}
          items={shared.items}
        />,
      )
    }
    if (topTen.items.length > 0) {
      items.push(
        <HorizontalTopTen
          key={'hzs-topTen'}
          icon={<WhatshotIcon className={classes.icon} />}
          title={'TOP del mes'}
          itemCreator={item => onCreateItemCourseTopTen(item)}
          items={topTen.items}
          itemClick={item => onShowCourse(item.course)}
        />,
      )
    }

    if (finished.items.length > 0) {
      items.push(
        <HorizontalZoomSlider
          key={'hzs-finished'}
          icon={<FinishedIcon className={classes.icon} />}
          title={'Finalizados'}
          itemCreator={item => onCreateItemCourse(item)}
          items={finished.items}
        />,
      )
    }

    if (items.length === 0) {
      items.push(<Loading />)
    }
    return items
  }, [
    required,
    variablePrice,
    myPending,
    nextDays,
    featuredCategories,
    inProgress,
    shared,
    topTen,
    finished,
    onCreateItemCourse,
    onCreateItemCourseTopTen,
    onCreateItemCourseShared,
    objectiveDplusI
  ])

  return (
    <main id="main" className={classes.root} tabIndex={-1}>
      <ParallaxBackground />

      {children}

      <Button
        role={'link'}
        className={classes.exploreButton}
        variant={'outlined'}
        onClick={() => onGoToExplore()}
      >
        {'EXPLORAR CURSOS'}
      </Button>
    </main>
  )
}

export default withStyles(styles)(ContentHome)
