import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import CourseBlock from '../../components/courses/CourseBlock'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { init, updateItems } from '../../actions/infinite_paginator'
import {
  paginateCourseBlocks,
  silentUpdateCourseById,
  resetPagitation,
} from '../../actions/courses'
import { bindActionCreators } from 'redux'
import InfiniteScroll from 'react-infinite-scroller'
import ParallaxBackground from '../../components/ui/ParallaxBackground'
import { loadQuestionsTypes } from '../../actions/quizTrivia'
import { appLoadToken } from '../../actions/app'
import { removeDuplicates } from '../../utils/OthersHelper'

const styles = () => ({
  root: {
    minHeight: 600,
    display: 'flex',
    flexFlow: 'column',
  },
})

class ContentCourseContainer extends Component {
  constructor(props) {
    super(props)
    props.init(props.keyName)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items && !this.props.preview) {
      const { course, silentUpdateCourseById, handleCourseDone } = this.props
      silentUpdateCourseById(course.id)
      handleCourseDone()
    }
  }
  onLoadQuestionsTypes = () => {
    const { loadQuestionsTypes, appLoadToken } = this.props
    appLoadToken().then(() => {
      loadQuestionsTypes()
    })
  }

  componentDidMount() {
    if (window.location.href.includes('admin/courses/view')) {
      this.onLoadQuestionsTypes()
    }
  }

  onFetchMore = () => {
    const { keyName, course, paginateCourseBlocks, page, size, preview } = this.props
    paginateCourseBlocks(course.id, keyName, page, size, preview)
  }

  onActivityDone = (activityId, updatedBlocks) => {
    const { keyName, updateItems, handleCourseDone, silentUpdateCourseById, course } = this.props
    updateItems(keyName, updatedBlocks)
    silentUpdateCourseById(course.id).then(() => {
      handleCourseDone()
    })
  }

  reload = async () => {
    const { keyName, resetPagitation } = this.props
    await resetPagitation(keyName)
  }

  componentWillUnmount() {
    this.reload()
  }

  render() {
    const { classes, course, hasMore, items = [], loading, courseColor, preview } = this.props

    return (
      course && (
        <main id="main" className={classes.root} tabIndex={-1}>
          <ParallaxBackground />
          <InfiniteScroll
            pageStart={0}
            loadMore={this.onFetchMore}
            hasMore={hasMore && !loading}
            loader={
              <div className="loader" key={0}>
                Cargando...
              </div>
            }
          >
            {removeDuplicates(items, 'id').map((block, i) => {
              return (
                <CourseBlock
                  key={'block-' + i}
                  courseId={course.id}
                  block={block}
                  onActivityDone={this.onActivityDone}
                  courseColor={courseColor}
                  preview={preview}
                  reloadBlock={this.reload}
                />
              )
            })}
          </InfiniteScroll>
        </main>
      )
    )
  }
}

const mapStateToProps = (state, props) => {
  const infiniteState = state.infiniteScrollPaginatorReducer
  return {
    ...infiniteState[props.keyName],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      init,
      paginateCourseBlocks,
      updateItems,
      silentUpdateCourseById,
      resetPagitation,
      loadQuestionsTypes,
      appLoadToken,
    },
    dispatch,
  )
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentCourseContainer)),
)
