import {
  PAGINATOR_UPDATE_ITEM,
  PAGINATOR_PAGINATE_NEXT,
  PAGINATOR_PAGINATE_PREV,
  PAGINATOR_PAGINATE_START,
  PAGINATOR_PAGINATE_END,
  PAGINATOR_PAGINATE_FAILED,
  PAGINATOR_CHANGE_QUERY,
  PAGINATOR_INIT,
  PAGINATOR_PAGINATE_TO,
  PAGINATOR_REFRESH,
} from './index';

export function init(key) {
  return async (dispatch) => {
    dispatch({ type: PAGINATOR_INIT, key });
  };
}
export function paginateNext(key) {
  return async (dispatch) => {
    dispatch({ type: PAGINATOR_PAGINATE_NEXT, key });
  };
}

export function paginatePrev(key) {
  return async (dispatch) => {
    dispatch({ type: PAGINATOR_PAGINATE_PREV, key });
  };
}
export function paginateTo(key, page) {
  return async (dispatch) => {
    dispatch({
      type: PAGINATOR_PAGINATE_TO,
      key,
      payload: { page },
    });
  };
}

export function changeQuery(key, query) {
  return async (dispatch) => {
    dispatch({
      type: PAGINATOR_CHANGE_QUERY,
      key,
      payload: { query },
    });
  };
}

export function paginate(key, query, request, apply = (a) => a) {
  return async (dispatch) => {
    dispatch({
      type: PAGINATOR_PAGINATE_START,
      key,
      payload: { query },
    });
    try {
      const response = await request(dispatch);
      dispatch({
        type: PAGINATOR_PAGINATE_END,
        key,
        payload: {
          items: apply(response.data),
          query,
          meta: response.metadata,
        },
      });
    } catch (e) {
      dispatch({ type: PAGINATOR_PAGINATE_FAILED, key, payload: e });
    }
  };
}

export function silentPaginate(key, query, request, apply = (a) => a) {
  return async (dispatch) => {
    try {
      const response = await request(dispatch);
      dispatch({
        type: PAGINATOR_PAGINATE_END,
        key,
        payload: {
          items: apply(response.data),
          query,
          meta: response.metadata,
        },
      });
    } catch (e) {
      dispatch({ type: PAGINATOR_PAGINATE_FAILED, key, payload: e });
    }
  };
}

export function updateItem(key, itemId, apply = (i) => i) {
  return async (dispatch) => {
    dispatch({
      type: PAGINATOR_UPDATE_ITEM,
      key,
      payload: { itemId, apply },
    });
  };
}

export function refresh(key) {
  return async (dispatch) => {
    dispatch({ type: PAGINATOR_REFRESH, key });
  };
}
