import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    header: {
      position: 'relative',
      backgroundSize: 'cover',
      height: '10%',
      minHeight: 320,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
    headerContent: {
      paddingTop: 25,
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 24,
      flexGrow: 1
    },
    title: {
      fontSize: 48,
      color: '#fff',
      maxWidth: "70%",
      [mediaLg]: {
        height: 'auto',
        maxWidth: '80%',
      },
    },
  }
}

const HeaderExternalCourse = ({ classes, course }) => {
  return (
    <header
      className={classes.header}
      style={{
        backgroundImage:
          course && course.image_url
            ? `linear-gradient(0deg, rgba(7, 7, 7, 0.7), rgba(0, 0, 0, 0)), url("${course.image_url}")`
            : `#000000`,
        backgroundSize: 'cover'
      }}
    >
      <div className={classes.gradient} />
      {course && (
        <div className={classes.headerContent}>
          <Typography component={'h1'} className={classes.title}>
            {course.name}
          </Typography>
        </div>
      )}
    </header>
  )
}
export default withStyles(styles)(HeaderExternalCourse)
