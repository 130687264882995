import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CourseBlockHeader from './CourseBlockHeader';
import CourseBlockContent from './CourseBlockContent';

const styles = () => ({
  root: {
    width: '100%',
  },
});

class CourseBlock extends Component {
  render() {
    const {
      classes,
      block,
      onActivityDone,
      courseColor,
      courseId,
      preview,
      reloadBlock,
    }
     = this.props;
    block.color = courseColor;
    return (
      <section className={classes.root}>
        <CourseBlockHeader  block={block} />
        <CourseBlockContent
          block={block}
          onActivityDone={onActivityDone}
          courseId={courseId}
          preview={preview}
          reloadBlock={reloadBlock}
        />
      </section>
    );
  }
}
export default withStyles(styles)(CourseBlock);
