/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import {
  ADMIN_RESOURCE_READ_START,
  ADMIN_RESOURCE_READ_END,
  ADMIN_RESOURCE_READ_FAILED,
  ADMIN_FILES_DOWNLOAD_START,
  ADMIN_FILES_DOWNLOAD_END,
  ADMIN_FILES_DOWNLOAD_FAILED,
  TOPTEN_COURSES_READ_START_ADMIN,
  TOPTEN_COURSES_READ_END_ADMIN,
  TOPTEN_COURSES_READ_FAILED_ADMIN,
  FILTER_TEMPLATE_READ_START,
  FILTER_TEMPLATE_READ_END,
  FILTER_TEMPLATE_READ_FAILED,
  EXTERNAL_COURSE_CLASSES_READ_END,
  EXTERNAL_COURSE_CLASSES_READ_START,
  EXTERNAL_COURSE_CLASSES_READ_FAILED,
} from './index'
import api from '../api/api'
import { paginate, refresh, silentPaginate } from './paginator'
import {
  FiltersFormat,
  EditInputType,
  ActivityTypeToH5pLibrary,
  ActivityType,
} from '../config/constants'
import XLSX from 'xlsx'
import { isObject } from 'lodash'
//import { identity } from 'lodash'
import { isOpenBankUser } from '../utils/AdminHelper'
//import { getCurrent } from './user'

export function getResources(
  resourceType,
  paginatorKey,
  query,
  page,
  size,
  apply = a => a,
  user = false,
) {
  return paginate(
    paginatorKey,
    query,
    dispatch => api.withDispatch(dispatch).Admin.getResources(resourceType, page, size, query),
    apply,
  )
}

export function getClassesByCommisionId(id) {
  return api.AdminExternalCourse.getClassesByCommissionId(id)
}

export function silentGetResources(resourceType, paginatorKey, query, page, size, apply = a => a) {
  return silentPaginate(
    paginatorKey,
    query,
    dispatch => api.withDispatch(dispatch).Admin.getResources(resourceType, page, size, query),
    apply,
  )
}

export function refreshResources(paginatorKey) {
  return refresh(paginatorKey)
}

export function getResource(keyName, resourceType, id, type = null, apply = a => a) {
  return async dispatch => {
    try {
      dispatch({ type: ADMIN_RESOURCE_READ_START, key: keyName })
      const response = await api.withDispatch(dispatch).Admin.getResource(resourceType, id)
      const { data } = response
      dispatch({
        type: ADMIN_RESOURCE_READ_END,
        payload: { item: apply(data) },
        key: keyName,
      })
    } catch (e) {
      dispatch({ type: ADMIN_RESOURCE_READ_FAILED, payload: e, key: keyName })
    }
  }
}

export function createResource(resourceType, data) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.createResource(resourceType, data)
    return response.data
  }
}

export function updateResource(resourceType, id, data, isFile = false) {
  return async dispatch => {
    let response = null
    if (isFile) {
      response = await api.withDispatch(dispatch).Admin.updateFileActivity(resourceType, id, data)
    } else {
      response = await api.withDispatch(dispatch).Admin.updateResource(resourceType, id, data)
    }
    return response.data
  }
}

export function unblockResource(resourceType, id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.unblockResource(resourceType, id)
    return response.data
  }
}

export function cleanResource(resourceType, id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.cleanResource(resourceType, id)
    return response.data
  }
}

export function downResource(resourceType, id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.downResource(resourceType, id)
    return response.data
  }
}

export function activeResource(resourceType, id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.activeResource(resourceType, id)
    return response.data
  }
}

export function importUsersFromCSV(data) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.importUsersFromCSV(data)
    return response.data
  }
}

export function createActivity(resourceType, data) {
  return async dispatch => {
    const response = await (data.type === ActivityType.File
      ? api.Admin.createFileActivity(resourceType, data)
      : api.withDispatch(dispatch).Admin.createResource(resourceType, data))
    return response.data
  }
}

export const createQuizTrivia = async (activityId, data) => {
  const response = await api.Admin.createQuizTriviaTemplate(activityId, data)
  return response.data
}

export const updateQuizTrivia = async (activityId, data) => {
  const response = await api.Admin.updateQuizTriviaTemplate(activityId, data)
  return response.data
}

export const answerQuizTrivia = async (activityId, data) => {
  const response = await api.Admin.answerQuizTrivia(activityId, data)
  return response.data
}

export function createActivityBlock(resourceType, data) {
  return async dispatch => {
    const response = await api.Admin.createSelectActivityBlock(resourceType, data)
    return response.data
  }
}

export function deleteResource(resourceType, id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.deleteResource(resourceType, id)
    return response.data
  }
}

export function unpublishResource(resourceType, id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.unpublishResource(resourceType, id)
    return response.data
  }
}

export function publishResource(resourceType, id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.publishResource(resourceType, id)
    return response.data
  }
}

function getValueFromFilter(filter, config) {
  switch (config.type) {
    case EditInputType.Select:
    case EditInputType.Text:
    case EditInputType.Date:
    case EditInputType.Number:
      return filter[config.name]
    case EditInputType.Object:
      if (config.multiple) {
        if (
          Array.isArray(filter[config.name]) &&
          filter[config.name].length &&
          config?.name === 'externalCourse.name' &&
          config?.displayAttribute === 'name'
        ) {
          return typeof filter[config.name][0] === 'object'
            ? filter[config.name].map(value => value.name)
            : filter[config.name]
        }
        if (Array.isArray(filter[config.name]) && filter[config.name].length) {
          return typeof filter[config.name][0] === 'object'
            ? filter[config.name].map(value => value.id)
            : filter[config.name]
        }
        if (config?.name === 'externalCourse.name' && config?.displayAttribute === 'name') {
          return filter[config.name].name
        }
        return filter[config.name]
      }
      return filter[config.name].id

    case EditInputType.LazySelect:
      if (config.multiple) {
        if (Array.isArray(filter[config.name]) && filter[config.name].length) {
          return typeof filter[config.name][0] === 'object'
            ? filter[config.name].map(value => value.id)
            : filter[config.name]
        }
        return filter[config.name]
      }
      return filter[config.name].id
    case EditInputType.Fixed:
      return config.fixedValue
    case EditInputType.Boolean:
      return filter[config.name]
    default:
      return null
  }
}

export function makeQuery(filter, filtersConfig, orderingConfig) {
  let query = ''

  const conditions = []
  if (!filter) {
    return query
  }
  filtersConfig.forEach(fc => {
    const name = fc.queryName ? fc.queryName : fc.name
    if (filter[fc.name] && filter[fc.name] !== '') {
      switch (fc.format) {
        case FiltersFormat.Plain:
          conditions.push(`filters[${name}]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.PlainOR:
          conditions.push(`filters[OR][${name}]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.In:
          const valueFromFilter = getValueFromFilter(filter, fc)
          if (!isObject(valueFromFilter)) {
            conditions.push(`filters[${name}]=${valueFromFilter}`)
            break
          }
          conditions.push(
            getValueFromFilter(filter, fc)
              .map((value, index) => `filters[${name}][IN][${index}]=${value}`)
              .join('&'),
          )
          break
        case FiltersFormat.Like:
          conditions.push(`filters[${name}][LIKE]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.Fecha:
          conditions.push(`filters[${name}][FECHA]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.Is:
          conditions.push(`filters[${name}][IS]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.MayorIgual:
          conditions.push(`filters[${name}][MayorIgual]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.MenorIgual:
          conditions.push(`filters[${name}][MenorIgual]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.Desde:
          conditions.push(`filters[${name}][Desde]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.Hasta:
          conditions.push(`filters[${name}][Hasta]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.DesdeNps:
          conditions.push(`filters[${name}][Desde]=${getValueFromFilter(filter, fc)}`)
          break
        case FiltersFormat.HastaNps:
          let hasta = parseInt(getValueFromFilter(filter, fc), 10) + 1
          conditions.push(`filters[${name}][Hasta]=` + hasta)
          break
        case FiltersFormat.FullSearch:
          fc.fields.forEach(field => {
            conditions.push(
              `filters[OR][${fc.fsModel}.${field}][LIKE]=${getValueFromFilter(filter, fc)}`,
            )
          })
          break
        case FiltersFormat.Null:
          const val = getValueFromFilter(filter, fc)

          conditions.push(
            `filters[${name}]${val === null || val === 'NULL' ? '[IS]=NULL' : `=${val}`}`,
          )
          break
        default:
          break
      }
    } else if (fc.type === EditInputType.Fixed) {
      conditions.push(`filters[${name}]=${getValueFromFilter(filter, fc)}`)
    }
  })
  if (orderingConfig.length > 0) {
    orderingConfig.forEach(oc => {
      conditions.push(`ordering[${oc.name}]=${oc.order}`)
    })
  }
  query = conditions.join('&')
  return query
}

export function getTemplateFromModel(model) {
  return async dispatch => {
    dispatch({ type: FILTER_TEMPLATE_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Admin.getFiltersTemplate(model)
      dispatch({ type: FILTER_TEMPLATE_READ_END, payload: { filterTemplates: response.data } })
    } catch (e) {
      dispatch({ type: FILTER_TEMPLATE_READ_FAILED, payload: { message: e } })
    }
  }
}

export function saveTemplateFromModel(data) {
  return async dispatch => {
    const response = await api.Admin.createFiltersTemplate(data)
    return response.data
  }
}

export function deleteFilterTemplate(id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.deleteTemplate(id)
    return response.data
  }
}

export function createContentH5p(type) {
  const library = ActivityTypeToH5pLibrary[type]
  if (library) {
    return api.Wordpress.createContent({ library }).then(response => {
      if (response.success) {
        return response.data.id
      }
      return false
    })
  }
  return Promise.resolve(false)
}

export function createContentScorm() {
  return api.Wordpress.createScormContent().then(response => {
    if (response.success) {
      return response.data.scorm_id
    }
    return false
  })
}

export function downloadReport(filters, type = 'user_courses', fileName = 'report') {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Admin.downloadReport(filters, type)
    let data = response.data

    const ws = XLSX.utils.aoa_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Report')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${fileName}.xlsx`)
  }
}

export function processReportOffline(filters, type = 'user_courses', fileName = 'report') {
  return async dispatch => {
    return await api.withDispatch(dispatch).Admin.processReportOffline(filters, type)
  }
}

export function downloadFile(fileId, filename) {
  return async dispatch => {
    try {
      dispatch({ type: ADMIN_FILES_DOWNLOAD_START, payload: { filename: filename } })
      const response = await api.withDispatch(dispatch).Admin.downloadFile(fileId)
      dispatch({ type: ADMIN_FILES_DOWNLOAD_END, payload: { file: response } })
    } catch (e) {
      dispatch({ ADMIN_FILES_DOWNLOAD_FAILED, payload: e })
    }
  }
}

export function loadTopTenAdmin() {
  return async dispatch => {
    dispatch({ type: TOPTEN_COURSES_READ_START_ADMIN })
    try {
      const response = await api.withDispatch(dispatch).Admin.getTopTen()
      dispatch({
        type: TOPTEN_COURSES_READ_END_ADMIN,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: TOPTEN_COURSES_READ_FAILED_ADMIN,
        payload: { message: e.toString() },
      })
    }
  }
}

export const generateEvents = async scormId => {
  const response = await api.Admin.generateEvents(scormId)

  return response
}

export const getEvents = async scormId => {
  const response = await api.Admin.getEvents(scormId)

  return response
}
export const updateEvents = async scormId => {
  const response = await api.Admin.updateEvents(scormId)

  return response
}

export const editEvent = async (eventId, payload) => {
  const response = await api.Admin.editEvent(eventId, payload)

  return response
}

export const getUserActivities = async (userFile, courseId) => {
  const response = await api.Admin.getUserActivities(userFile, courseId)

  return response
}

export const getUserCoursesQuiz = async filters => {
  const response = await api.Admin.getUserCoursesQuiz(filters)

  return response
}

export const getCourseQuizActivities = async courseId => {
  const response = await api.Admin.getCourseQuizActivities(courseId)

  return response
}

export const submitActivityCompleteness = async payload => {
  const response = await api.Admin.submitActivityCompleteness(payload)

  return response
}

export const updateActivityCompleteness = async payload => {
  const response = await api.Admin.updateActivityCompleteness(payload)

  return response
}

export const getActivityScormEvents = async (userId, scormId) => {
  const response = await api.Admin.getActivityScormEvents(userId, scormId)

  return response
}

export const updateCourseCompleteness = async payload => {
  const response = await api.Admin.updateCourseCompleteness(payload)

  return response
}
