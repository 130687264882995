import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MixedCourseItem from '../ui/MixedCourseItem'
import ZoomGrid from '../ui/ZoomGrid'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    root: {
      backgroundColor: '#101010',
      display: 'flex',
      flexFlow: 'column',
      maxWidth: 'calc(100vw - 0%)',
      paddingTop: 24,
      paddingBottom: 50,
      paddingRight: 90,
      paddingLeft: 90,
      position: 'relative',
      [mediaLg]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    courses: {
      marginTop: 32,
      marginBottom: 50,
    },
  }
}

const MyPlaylistCourseWrapper = ({ item, onUnlinkCourse, children }) => {
  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <IconButton
        aria-label={'quitar curso de la lista'}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onUnlinkCourse(item)
        }}
        style={{ position: 'absolute', zIndex: 40000, top: -40, right: -16, color: 'white' }}
      >
        <DeleteIcon />
      </IconButton>
      {children}
    </div>
  )
}

const ContentPlaylists = ({ classes, playlist, onShowCourse, onUnlinkCourse }) => {
  const onCreateItemCourse = useCallback(
    item => {
      return playlist.mine ? (
        <MyPlaylistCourseWrapper item={item} onUnlinkCourse={onUnlinkCourse}>
          <MixedCourseItem item={item} itemClick={onShowCourse} />
        </MyPlaylistCourseWrapper>
      ) : (
        <MixedCourseItem item={item} itemClick={onShowCourse} />
      )
    },
    [onShowCourse, playlist],
  )

  return (
    <main id="main" className={classes.root} tabIndex={-1}>
      <div className={classes.courses}>
        <ZoomGrid
          ariaLabel={'Cursos de la lista'}
          items={playlist.courses}
          itemCreator={onCreateItemCourse}
          itemHeight={165}
          itemWidth={290}
          itemClick={onShowCourse}
        />
      </div>
    </main>
  )
}
export default withStyles(styles)(ContentPlaylists)
