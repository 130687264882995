import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setActivityDone } from '../../actions/activities'
import { withRouter } from 'react-router-dom'
import Activity from '../../components/courses/Activity'
import { ActivityType } from '../../config/constants'

class ActivityContainer extends Component {
  state = {
    done: false,
  }

  markActivityAsDone = () => {
    const { onActivityDone, setActivityDone, activity, courseId, preview } = this.props
    if (!preview) {
      setActivityDone(courseId, activity.id).then(updatedBlocks => {
        if (updatedBlocks) {
          onActivityDone(activity.id, updatedBlocks)
        } else {
          this.lockClick = false
        }
      })
    } else {
      this.setState({ done: true }, () => {
        this.lockClick = false
      })
    }
  }

  markActivityDone = (updateH5PContent = false) => {
    if (!this.lockClick || updateH5PContent) {
      this.lockClick = true
      const { activity } = this.props

      if (
        [
          ActivityType.Trivia,
          ActivityType.Video,
          ActivityType.Audio,
          ActivityType.Presentation,
          ActivityType.Timeline,
          activity.trackable ? ActivityType.Scorm : '',
          ActivityType.QuizTrivia,
        ].includes(activity.type) &&
        !updateH5PContent
      ) {
        return
      }

      this.markActivityAsDone()
    }
  }

  resolveActivityDone = activity => {
    const { preview } = this.props
    if (preview) {
      return this.state.done
    }
    return [ActivityType.QuizTrivia, ActivityType.Scorm].includes(activity.type)
      ? !!activity.done
      : activity &&
          activity.user_activities &&
          activity.user_activities.length > 0 &&
          activity.user_activities[0].done !== 0
  }

  render() {
    const { activity, loading, reloadBlock } = this.props
    activity.done = this.resolveActivityDone(activity)
    return (
      <Activity
        activity={activity}
        loading={loading}
        onClick={this.markActivityDone}
        reloadBlock={reloadBlock}
        markActivityAsDone={this.markActivityAsDone}
      />
    )
  }
}

const mapStateToProps = () => {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setActivityDone }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityContainer))
