import React, { Component } from 'react'
import AdminContentWrapper from '../../containers/admin/AdminContentWrapperContainer'
import AdminResourceTable from './AdminResourceTable'
import AdminFilters from './inputs/AdminFilters'
import { itemView } from '../../utils/AdminHelper'
import ResourcesPaginator from '../../containers/admin/ResourcesPaginator'
import Loading from '../ui/Loading'
import ColorButton from '../../components/ui/ColorButton'
import SnackbarMessage from '../ui/SnackbarMessage'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/Reorder'
import DownloadIcon from '@material-ui/icons/GetApp'
import SvgIcon from '@material-ui/core/SvgIcon'
import MailIcon from '@material-ui/icons/MailOutline'
import XLSX from 'xlsx'

class AdminResourceIndex extends Component {
  state = {
    showMessage: false,
  }

  handleDownload = () => {
    const { onDownload, filters, disableDownloadButton } = this.props
    if (disableDownloadButton) return
    return onDownload(Object.keys(filters))
  }

  handleMessageDisableDownload = () => {
    this.setState({ showMessage: true })
  }

  handleGenerateReport = () => {
    const { onGenerateReport, filters } = this.props
    return onGenerateReport(Object.keys(filters))
  }

  handleDownloadXls = () => {
    const { items, columns, keyName } = this.props
    let result = items.map(item => {
      return columns.map(col => {
        const a = itemView(col, item)
        return a.props ? a.props.children : a
      })
    })
    result.unshift(columns.map(col => col.title))

    const ws = XLSX.utils.aoa_to_sheet(result)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Report')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${keyName}.xlsx`)
  }

  render() {
    const {
      keyName,
      children,
      items,
      loading,
      getActions,
      columns,
      title,
      customTop,
      customBottom,
      onFetch,
      onFiltersChange,
      onSaveNewTemplate,
      onDeleteFilterTemplate,
      filters,
      getFilterConfig,
      onGetFilterTemplates,
      onCreate = null,
      onCreateTopCourses = null,
      sort,
      forceNoActions,
      onDownload,
      onGenerateReport,
      onDownloadXls,
      filterTemplates,
      addSnackbar,
      enableDownloadXls,
      disableDownloadButton,
      wrapperClass,
    } = this.props

    return (
      <AdminContentWrapper wrapperClass={wrapperClass}>
        <div style={{ minHeight: 45, display: 'flex' }}>
          <h1
            style={{
              float: 'left',
              marginLeft: 16,
              display: 'flex',
              flexGrow: 1,
            }}
          >
            {title}
          </h1>
          {sort && (
            <div style={{ float: 'right', height: 56, margin: '24px 16px 0 0' }}>
              <ColorButton
                color="#868686"
                label="Ordenar"
                onClick={sort}
                extraView={<ListIcon style={{ height: 20, width: 20, marginTop: 2 }} />}
              />
            </div>
          )}
          {onCreateTopCourses != null ? (
            <div style={{ float: 'right', height: 56, margin: '24px 16px 0 0' }}>
              <ColorButton
                color={'#3abf91'}
                label={'TOP del mes'}
                onClick={onCreateTopCourses}
                extraView={<AddIcon style={{ height: 20, width: 20, marginTop: 2 }} />}
              />
            </div>
          ) : null}
          {onCreate != null ? (
            <div style={{ float: 'right', height: 56, marginTop: 24 }}>
              <ColorButton
                color={'#3abf91'}
                label={'Nuevo'}
                onClick={onCreate}
                extraView={<AddIcon style={{ height: 20, width: 20, marginTop: 2 }} />}
              />
            </div>
          ) : null}
          {onGenerateReport != null ? (
            <div style={{ float: 'right', height: 56, marginTop: 24, marginRight: 5 }}>
              <ColorButton
                color={'#3abf91'}
                label={'Enviar reporte'}
                title={'Se procesará el reporte y se le enviará por mail'}
                onClick={this.handleGenerateReport}
                extraView={<MailIcon style={{ height: 20, width: 20, marginTop: 2 }} />}
              />
            </div>
          ) : null}
          {onDownloadXls && enableDownloadXls ? (
            <div style={{ float: 'right', height: 56, marginTop: 24, marginRight: 5 }}>
              <ColorButton
                color={'#3abf91'}
                style={{ minWidth: 60 }}
                toolTip={'Descargar xls'}
                onClick={this.handleDownloadXls}
                extraView={
                  <SvgIcon>
                    <path
                      d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5l2.9 4.5M13 9V3.5L18.5 9H13z"
                      fill="currentColor"
                    />
                  </SvgIcon>
                }
              />
            </div>
          ) : null}
          {onDownload != null ? (
            <div style={{ float: 'right', height: 56, marginTop: 24 }}>
              <ColorButton
                color="#3abf91"
                label={'Descargar'}
                onClick={
                  disableDownloadButton ? this.handleMessageDisableDownload : this.handleDownload
                }
                extraView={<DownloadIcon style={{ height: 20, width: 20, marginTop: 2 }} />}
              />
            </div>
          ) : null}
        </div>
        {[
          <div key={'customTop'}>{customTop}</div>,
          <>
            {!!getFilterConfig()?.length && (
              <AdminFilters
                key={'filters'}
                keyName={keyName}
                filters={filters}
                onFiltersChange={onFiltersChange}
                onSaveNewTemplate={onSaveNewTemplate}
                getFilterConfig={getFilterConfig}
                getTemplatesConfig={onGetFilterTemplates}
                filterTemplates={filterTemplates}
                onDeleteFilterTemplate={onDeleteFilterTemplate}
                addSnackbar={addSnackbar}
              />
            )}
          </>,
          loading ? (
            <Loading dark={true} key={'loading-admin-index'} />
          ) : (
            <AdminResourceTable
              key={'resource-table'}
              items={items}
              columns={columns}
              getActions={getActions}
              forceNoActions={forceNoActions}
            />
          ),
          <div key={'customBottom'}>{customBottom}</div>,
        ]}
        {onFetch && <ResourcesPaginator keyName={keyName} onFetch={onFetch} />}
        {[children]}
        <SnackbarMessage
          type={3}
          open={this.state.showMessage}
          onClose={() => this.setState({ showMessage: false })}
          message={`El reporte supera el límite de ${
            process.env.REACT_APP_REPORT_DOWNLOAD_MAX_SIZE || 100
          } páginas admitido para descargas directas.`}
        />
      </AdminContentWrapper>
    )
  }
}

export default AdminResourceIndex
