import React, { useState, useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import GenericDialog from '../../ui/GenericDialog'
import ColorButton from '../../ui/ColorButton'
import { Button, Typography, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'
import CheckIcon from '@material-ui/icons/Check'
import { getUserCommission, setPresent } from '../../../actions/commissions'
import { appLoadToken } from '../../../actions/app'
import { isEmpty } from 'lodash'
import Divider from '@material-ui/core/Divider'
import moment from 'moment'
import { addSnackbar } from '../../../actions/snackbar'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      // minHeight: 550,
      margin: 'auto',
      alignItems: 'center',
      [mediaLg]: {
        marginBottom: 10,
      },
    },
    divider: {
      margin: 10,
      width: '100%',
    },
    inactiveRow: {
      color: '#bdbdbd !important',
      fontWeight: '200 !important',
    },
    text: {
      textAlign: 'center',
      color: '#575757',
    },
    actions: {
      margin: 'auto',
      marginTop: 18,
      marginBottom: 18,
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
    },
    actionSave: {
      fontSize: 11,
      marginRight: 16,
      color: '#3abf91',
      border: '2px solid #3abf91',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#e5e2e2',
      },
    },
    inactiveButton: {
      marginRight: 16,
      color: '#bdbdbd',
      border: '2px solid #bdbdbd',
      backgroundColor: 'transparent !important',
      fontSize: 11,
    },
    actionCancel: {
      color: '#575454',
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    buttonLabel: {
      textTransform: 'none',
    },
    textField: {
      marginBottom: 7,
      margin: 'auto',
      [mediaLg]: {
        width: '100%',
      },
    },
    classTitle: {
      fontSize: 15,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      color: '#575454',
    },
    inactiveclassTitle: {
      fontSize: 15,
      fontWeight: 200,
      marginBottom: 8,
      marginTop: 8,
      color: '#bdbdbd',
    },
    classDate: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 8,
      marginTop: 8,
      color: '#575454',
    },
    inactiveclassClassDate: {
      fontSize: 12,
      fontWeight: 100,
      marginBottom: 8,
      marginTop: 8,
      color: '#bdbdbd',
    },
    error: {
      fontSize: 10,
      color: 'red',
    },
    contentValorar: {
      margin: 2,
      padding: 40,
    },
  }
}

const initialState = {
  isValid: false,
  stars: 0,
  comment: '',
  bad_comment: '',
  errors: { comment: '', bad_comment: '', nps: '' },
}

const CreatePresentDialog = ({
  classes,
  open,
  onDismiss,
  headerColor,
  data: { commissionId },
  appLoadToken,
  userCommission,
  getUserCommission,
  onReview,
}) => {
  const [dialogState, updateDialogState] = useState(initialState)

  useEffect(() => {
    if (commissionId) {
      appLoadToken().then(() => {
        getUserCommission(commissionId)
      })
    }
  }, [commissionId, appLoadToken, getUserCommission])

  const resetDialog = useCallback(() => {
    updateDialogState({ ...initialState })
  }, [updateDialogState])

  const handlePresent = useCallback(
    dateId => {
      appLoadToken().then(() => {
        setPresent(dateId)
        setTimeout(() => onDismiss(), 850)
      })
    },
    [appLoadToken, onDismiss],
  )

  const handleReview = useCallback(
    dateId => {
      onDismiss()
      onReview(dateId)
    },
    [onDismiss, onReview],
  )

  const renderContent = useCallback(() => {
    return (
      userCommission && (
        <div className={classes.content}>
          <Grid container spacing={3} className={classes.contentValorar}>
            {!isEmpty(userCommission.user_commission_dates) &&
              userCommission.user_commission_dates.map((ucd, index) => {
                const formatedDate = ucd.commission_date.start_date
                  ? moment(ucd.commission_date.start_date)
                  : null
                const { disabled, rateable } = ucd.commission_date

                return (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={disabled ? classes.inactiveRow : ''}
                    key={ucd.commission_date.id}
                  >
                    <Grid item md={10} xs={12}>
                      <Typography
                        className={disabled ? classes.inactiveclassTitle : classes.classTitle}
                        id={'review-slider-label'}
                      >
                        {'CLASE ' + (index + 1)}
                      </Typography>
                      <Typography
                        className={disabled ? classes.inactiveclassClassDate : classes.classDate}
                        id={'review-slider-label-date'}
                      >
                        {formatedDate.format('DD/MM/YY')} {ucd.commission_date.duration}min
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      {rateable ? (
                        <ColorButton
                          color={'#3abf91'}
                          disabled={disabled}
                          className={disabled ? classes.inactiveButton : classes.actionSave}
                          label={'VALORAR'}
                          onClick={() => handleReview(ucd.id)}
                        />
                      ) : (
                        <ColorButton
                          color={'#3abf91'}
                          disabled={disabled}
                          className={disabled ? classes.inactiveButton : classes.actionSave}
                          label={'¡DAR PRESENTE!'}
                          onClick={() => handlePresent(ucd.commission_date.id)}
                        />
                      )}
                    </Grid>
                    <Divider className={classes.divider} />
                  </Grid>
                )
              })}

            <Grid item xs={12} className={classes.actions}>
              <Button
                variant={'outlined'}
                className={classes.actionCancel}
                classes={{ label: classes.buttonLabel }}
                onClick={onDismiss}
              >
                {'CERRAR'}
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    )
  }, [classes, onDismiss, userCommission, handlePresent, handleReview])

  if (!userCommission) return <></>

  return (
    userCommission && (
      <GenericDialog
        open={open}
        onDismiss={onDismiss}
        name={null}
        icon={
          <div>
            <SupervisorAccountOutlinedIcon style={{ fontSize: 55 }} />{' '}
            <CheckIcon style={{ fontSize: 45, marginLeft: '-20px', marginBottom: '-10px' }} />
          </div>
        }
        title={'Confirma tu asistencia al taller'}
        title2={userCommission.commission.external_course.name}
        onExit={resetDialog}
      >
        {renderContent()}
      </GenericDialog>
    )
  )
}

const mapStateToProps = (state, ownProps) => {
  return { ...state.userCommissionReducer }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getUserCommission, appLoadToken, addSnackbar }, dispatch)
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePresentDialog)),
)
