import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AdminResourceForm from '../AdminResourceForm'
import {
  EditInputType,
  EditInputObjectModel,
  ReferentProviderTypes,
  ReferentProviderType,
} from '../../../config/constants'
import { limitIntEntry, preventEvent } from '../../../utils/AdminHelper'

const MAX_SIZE = 10

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainContentContainer: {
    width: '92%',
    display: 'flex',
    flexDirection: 'column',
  },
  // Form classes
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    boxShadow: 'none',
  },
  input: {
    margin: '1em 4em',
    boxSizing: 'border-box',
  },
  switchInput: {
    marginLeft: '4em',
    boxSizing: 'border-box',
  },
}

const getColumns = (form, classes) => {
  const commonExternalReferenceFields = {
    source: 'external_reference',
    editable: true,
  }

  const commonExternalReferenceEditConfig = {
    type: EditInputType.Object,
    className: classes.input,
    name: 'external_reference',
    multiple: true,
  }

  const externalReferenceSelectType = {
    [ReferentProviderType.Referent]: {
      ...commonExternalReferenceFields,
      title: 'Usuarios referentes',
      editConfig: {
        ...commonExternalReferenceEditConfig,
        model: EditInputObjectModel.ExternalReferencesReferents,
      },
    },
    [ReferentProviderType.Provider]: {
      ...commonExternalReferenceFields,
      title: 'Proveedor',
      editConfig: {
        ...commonExternalReferenceEditConfig,
        model: EditInputObjectModel.ExternalReferencesProviders,
        multiple: true,
      },
    },
  }

  const keys = [
    {
      source: 'image',
      editable: true,
      editConfig: {
        type: EditInputType.Dropzone,
        name: 'image',
        dropDetail: 'JPG, PNG',
        accept: 'image/png, image/jpg, image/jpeg',
        multiple: false,
        maxSize: MAX_SIZE,
      },
    },
    {
      title: 'Título',
      source: 'title',
      editable: true,
      editConfig: {
        type: EditInputType.Text,
        name: 'title',
        className: classes.input,
      },
    },
    {
      title: 'Descripción',
      source: 'description',
      editable: true,
      editConfig: {
        type: EditInputType.Text,
        name: 'description',
        className: classes.input,
      },
    },
    {
      title: 'Inversión',
      source: 'investment',
      editable: true,
      editConfig: {
        type: EditInputType.Number,
        name: 'investment',
        className: classes.input,
        onKeyDown: limitIntEntry,
        onPaste: preventEvent,
      },
    },
    {
      title: 'Tipo de referencia',
      source: 'external_reference_type',
      editable: true,
      editConfig: {
        className: classes.input,
        type: EditInputType.Select,
        options: ReferentProviderTypes,
        name: 'external_reference_type',
      },
    },
    {
      title: 'Duración (minutos)',
      source: 'duration',
      editable: true,
      editConfig: {
        type: EditInputType.Number,
        name: 'duration',
        className: classes.input,
      },
    },
    {
      source: 'required',
      editable: true,
      value: 'required',
      editConfig: {
        name: 'required',
        type: EditInputType.Switch,
        label: 'Requerido',
        className: classes.switchInput,
      },
    },
    {
      source: 'generate_certificate',
      editable: true,
      value: 'generate_certificate',
      editConfig: {
        name: 'generate_certificate',
        type: EditInputType.Switch,
        label: 'Emite certificado',
        className: classes.switchInput,
      },
    },
    {
      source: 'comments_enabled',
      editable: true,
      value: 'comments_enabled',
      editConfig: {
        name: 'comments_enabled',
        type: EditInputType.Switch,
        label: 'Habilitar NPS',
        className: classes.switchInput,
      },
    },
    {
      source: 'not_visible_for_reporting',
      editable: true,
      value: 'not_visible_for_reporting',
      editConfig: {
        name: 'not_visible_for_reporting',
        type: EditInputType.Switch,
        label: 'Ocultar a usuarios de reportería',
        className: classes.switchInput,
      },
    },
    {
      source: 'hidden',
      editable: true,
      value: 'hidden',
      editConfig: {
        name: 'hidden',
        type: EditInputType.Switch,
        label: 'Seleccionar usuarios',
        className: classes.switchInput,
      },
    },
    {
      source: 'categories',
      editable: true,
      value: 'Categoría',
      editConfig: {
        name: 'categories',
        type: EditInputType.Object,
        model: EditInputObjectModel.Categories,
        placeholder: 'Categoría',
        multiple: true,
        className: classes.input,
        customFilter: {
          types: 1,
        },
      },
    },
    {
      source: 'tags_list',
      editable: true,
      value: 'Etiqueta',
      editConfig: {
        name: 'tags_list',
        type: EditInputType.Object,
        model: EditInputObjectModel.Tags,
        placeholder: 'Etiqueta',
        multiple: true,
        creatable: true,
        className: classes.input,
      },
    },
  ]

  const externalReferentProviderIndex =
    keys.findIndex(input => {
      return input.source === 'external_reference_type'
    }) + 1

  if (form.external_reference_type) {
    keys.splice(
      externalReferentProviderIndex,
      0,
      externalReferenceSelectType[form.external_reference_type],
    )
  }

  return keys
}

const CourseFormBasicDataStepOne = ({
  classes,
  form,
  results,
  onSubmit,
  onAttributeChange,
  title,
  loading,
  onGoBack,
}) => {
  const handleFormChanges = async (name, value) => {
    if (name === 'external_reference_type') {
      onAttributeChange({
        ...form,
        external_reference: undefined,
        [name]: value,
      })
    } else {
      onAttributeChange({
        ...form,
        [name]: name === 'image' ? value.acceptedFiles[0] : value,
        fileError:
          name === 'image' && value && value.failedFiles && value.failedFiles.length
            ? `Debes subir un archivo menor a ${MAX_SIZE} mb`
            : undefined,
      })
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.mainContentContainer}>
        <AdminResourceForm
          title={title}
          keyName={'admin-course-basic-data-form'}
          form={{
            ...form,
            image: form.image ? (form.image.preview ? form.image.preview : form.image) : '',
          }}
          isValid={results.isValid}
          errors={results.errors}
          loading={loading}
          attributes={{ keys: getColumns(form, classes) }}
          onSubmit={onSubmit}
          onGoBack={onGoBack}
          onAttributeChange={handleFormChanges}
          customClasses={classes}
          customActions={true}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(CourseFormBasicDataStepOne)
