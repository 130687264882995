import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { isEmpty } from 'lodash';

const styles = (theme) => ({
  root: {
    marginBottom: 30,
  },
  tableJobPositions: {
    marginTop: 20,
  },
  formControl: {
    width: '100%',
    margin: '20px 0',
    overflow: 'hidden',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  wrapperBoton: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  boton: {
    display: 'flex inline',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  autosuggest: {
    marginTop: 50,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  button: {
    marginRight: theme.spacing(1),
  }
});

function StepFour({
  classes,
  company,
  companySelected,
  handleChangeCompany,
  handleBack,
  handleCompanyPublish,
  handleSaveCompany,
  isPublished,
}) {
  const { data: dataCompany, columns: columnsCompany } = company;

  const [searchCompany, setSearchCompany] = useState();



  const handleClickCompany = (Company) => {
    handleChangeCompany(Company);
  };

  const disabledBtnPublish = [
    (companySelected),
  ].every((position) => !isEmpty(position));

  const setCompanyRowsSelected = () => {
    return dataCompany
      .map((item, idx) => {
        if (companySelected.filter(e2 => item.id === e2.id).length > 0) {
          return idx;
        }
        return undefined;
      })
      .filter(item => item !== undefined);
  }

  const optionsCompany = () => ({
    download: false,
    print: false,
    rowsPerPage: 10,
    viewColumns: false,
    sortOrder: {
      name: "id",
      direction: "asc"
    },
    sort: true,
    filter: false,
    fixedHeader: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    searchText: searchCompany,
    customSearch: (searchQuery, currentRow, columns) => {
      setSearchCompany(searchQuery);
      let isFound = false;
      currentRow.forEach(col => {
        if (col !== null && col !== undefined && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
    onSearchClose: () => {
      setSearchCompany(false)
    },
    onRowsSelect: (currentRowSelected, allRowsSelected) => {
      const companyItem = allRowsSelected.map(item => {
        return dataCompany[item.dataIndex];
      });
      handleClickCompany(companyItem);
    },

    customSort: (data, colIndex, order) => {
      let rowsSelected = setCompanyRowsSelected();
      return data.sort((a, b) => {
        var dir = order === "desc" ? 1 : -1;
        var isASelected = dataCompany.find(elem => {
          return rowsSelected.indexOf(a.index) !== -1;
        });
        var isBSelected = dataCompany.find(elem => {
          return rowsSelected.indexOf(b.index) !== -1;
        });
        if (isASelected && !isBSelected) return 1 * dir;
        if (!isASelected && isBSelected) return -1 * dir;
        return (a.data[colIndex] > b.data[colIndex] ? -1 : 1) * dir;
      });
    },
    rowsSelected: setCompanyRowsSelected(),
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay registros",
        toolTip: "Orden",
        columnHeaderTooltip: column => `Orden para ${column.label}`
      },
      selectedRows: {
        text: "centro(s) de costos seleccionado(s)",
        delete: "Borrar",
        deleteAria: "Borrar seleccion",
      },
    }
  })

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.tableCompany}>
          <MUIDataTable
            title={'Empresas'}
            data={dataCompany}
            columns={columnsCompany}
            options={optionsCompany()}
          />
        </Grid>
      </Grid>

      <Grid className={classes.wrapperBoton}>
        <Button onClick={handleBack} className={classes.button}>
          Atrás
        </Button>

        {
          isPublished &&
          <Button
              disabled={!disabledBtnPublish}
              onClick={handleCompanyPublish}
              className={classes.button}
              variant="contained"
              color="primary"
          >
            Guardar cambios
          </Button>
        }
        {(!isPublished) &&
        <>
          <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleSaveCompany}
          >
            Guardar Borrador
          </Button>

          <Button
              disabled={!disabledBtnPublish}
              onClick={handleCompanyPublish}
              className={classes.button}
              variant="contained"
              color="primary"
          >
            Publicar
          </Button>
        </>
        }
      </Grid>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(StepFour);
