import React, { useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import { Grid, TableRow, TableCell, Checkbox, Button } from '@material-ui/core'
import Loading from '../../../components/ui/Loading'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { appLoadToken } from './../../../actions/app'
import { loadUsersForLot } from './../../../actions/lotAdmin'
import { importUsersFromCSV } from '../../../actions/admin'
import { uniqBy, differenceBy } from 'lodash'
import FileInput from '../inputs/FileInput'
import { getSelectedUsers } from '../../../actions/user'

const styles = theme => ({
  container: {
    marginTop: '2%',
  },
  button: {
    marginRight: '1rem',
    marginTop: '1rem',
  },
})

class CourseFormBasicDataStepTwo extends React.Component {
  state = {
    page: 0,
    count: 1,
    rowsPerPage: 20,
    data: [],
    columns: [],
    isLoading: false,
    usersSelected: [],
    searchText: '',
    typing: false,
    typingTimeout: 0,
    firstLoad: true,
    showUserImport: false,
    userResponse: [],
  }

  componentDidMount = nextProps => {
    const { course, getSelectedUsers } = this.props

    this.onLoadData(this.state.page)

    if (course?.item?.users?.length) {
      getSelectedUsers(course.item?.users)
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.state.firstLoad && this.state.usersSelected !== nextProps.form.users) {
      this.setState({
        usersSelected: (nextProps.form?.users?.length > 0) ? nextProps.form.users.map(id => ({ id })): [],
        firstLoad: false,
      })
      this.props.onAttributeChange({
        ...this.props.form,
        users: nextProps.form.users,
      })
    }

    if (this.props.users !== nextProps.users) {
      const { users, prevSelectedUsers } = nextProps
      const { page, userResponse, searchText } = this.state

      const data = [...(prevSelectedUsers?.items || []), ...(users?.data || [])]

      this.setState({
        data:
          page === 0 && !searchText
            ? uniqBy([...userResponse, ...data], 'id')
            : differenceBy(users?.data || [], prevSelectedUsers?.items || [], 'id'),
        columns: users.columns || [],
      })

      if (users.metadata) {
        this.setState({
          isLoading: false,
          count: parseInt(users.metadata.pagination.total) || 1,
        })
      }
    }
  }

  handleClick = user => {
    let hasUser = this.isRowSelected(user.id)
    var newUsersSelected = this.state.usersSelected
    if (hasUser) {
      newUsersSelected = newUsersSelected.filter(el => el.id !== user.id)
    } else {
      newUsersSelected.push(user)
    }
    this.setState({ usersSelected: newUsersSelected })
    this.props.onAttributeChange({
      ...this.props.form,
      users: newUsersSelected.map(({ id }) => id),
    })
  }

  changePage = page => {
    this.onLoadData(page)
    this.setState({ page: page })
  }

  onLoadData = async (page = 0, query = false) => {
    const { rowsPerPage } = this.state
    const { loadUsersForLot, appLoadToken } = this.props

    try {
      this.setState({ page, isLoading: true })
      var filters = ''
      query = query || this.state.searchText

      appLoadToken().then(() => {
        if (query)
          filters += `filters[OR][user.id][LIKE]=${query}&filters[OR][user.firstName][LIKE]=${query}&filters[OR][user.lastName][LIKE]=${query}&filters[OR][user.username][LIKE]=${query}&filters[OR][user.email][LIKE]=${query}&`

        filters += `pagination[size]=${rowsPerPage}&pagination[page]=${page + 1}`

        loadUsersForLot(encodeURI(filters))
      })
    } catch (error) {
      console.error(error)
    }
  }

  isRowSelected = id => {
    const { usersSelected } = this.state
    if (usersSelected?.length == 0) return false
    return !!usersSelected?.find(element => element.id === id)
  }

  onTextSearchChange = searchQuery => {
    const self = this

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }

    self.setState({
      searchText: searchQuery,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.onLoadData()
      }, 850),
    })
  }

  handleShowImport = () => {
    this.setState({
      showUserImport: true,
    })
  }

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader()
        reader.onload = function () {
          resolve(reader.result)
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.readAsDataURL(file)
      } catch (e) {
        console.log(e)
      }
    })
  }

  onImportUsers = async (_, file) => {
    const reader = await this.getBase64(file[0])
    const response = await this.props.importUsersFromCSV({ file: [reader] })
    const userResponse = JSON.parse(response.users)
    const newUsers = userResponse.map(el => ({ id: el.id }))
    const usersSelected = uniqBy([...this.state.usersSelected, ...newUsers], 'id')

    this.setState({
      usersSelected,
      userResponse: uniqBy([...userResponse, ...this.state.userResponse], 'id'),
      data: uniqBy([...userResponse, ...this.state.data], 'id'),
    })
    this.props.onAttributeChange({
      ...this.props.form,
      users: usersSelected.map(({ id }) => id),
    })
  }

  render() {
    const { classes } = this.props
    const { count, rowsPerPage, data, columns } = this.state

    const customRowRender = row => {
      let [id, username, firstName, lastName, email] = row
      let isSelected = this.isRowSelected(id)

      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isSelected}
          tabIndex={-1}
          key={'user' + id}
          selected={isSelected}
        >
          <TableCell padding="checkbox" onClick={() => this.handleClick({ id })}>
            <Checkbox checked={isSelected} color={'primary'} />
          </TableCell>
          <TableCell align="left">{id}</TableCell>
          <TableCell align="left">{username}</TableCell>
          <TableCell align="left">{firstName}</TableCell>
          <TableCell align="left">{lastName}</TableCell>
          <TableCell align="left">{email}</TableCell>
        </TableRow>
      )
    }

    const options = () => ({
      download: false,
      print: false,
      viewColumns: false,
      sortOrder: {
        name: 'id',
        direction: 'asc',
      },
      sort: false,
      filter: false,
      fixedHeader: true,
      selectableRowsHeader: false,
      selectToolbarPlacement: 'none',
      searchText: this.state.searchText,
      serverSide: true,
      count: count,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [],
      textLabels: {
        body: {
          noMatch: this.state.isLoading ? <Loading dark={true} /> : 'No se encontraron resultados',
        },
      },
      onSearchChange: (searchQuery, currentRow, columns) => {
        this.onTextSearchChange(searchQuery)
      },
      onSearchClose: () => {
        this.setState({
          searchText: false,
        })
      },
      onTableChange: (action, tableState) => {
        if (action === 'changePage') this.changePage(tableState.page)
      },
      customRowRender,
    })

    return (
      <div className={classes.container}>
        <Grid item xs={12}>
          <MUIDataTable
            title={'Usuarios asociados'}
            data={data}
            columns={columns}
            options={options()}
          />
          <Button
            disabled={this.state.showUserImport}
            variant="contained"
            color="primary"
            onClick={() => this.handleShowImport()}
            className={classes.button}
          >
            {'Importar usuarios'}
          </Button>
          {this.state.showUserImport && (
            <FileInput
              tabIndex={0}
              name="file"
              accept=".csv"
              onChange={this.onImportUsers}
              style={{ marginTop: '3%' }}
            />
          )}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const states = state.admin.resource
  return {
    users: state.lotAdminReducer.users,
    course: states['admin-course-content-edit'],
    prevSelectedUsers: state.selectedUsersReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { loadUsersForLot, appLoadToken, importUsersFromCSV, getSelectedUsers },
    dispatch,
  )
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseFormBasicDataStepTwo)),
)
