import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex';
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  CourseProgress,
  //CareerPlanTypeNames,
	SnackbarType
} from '../../../config/constants'

const KEY_NAME = 'reports/career_plans'

class AdminReportsCareerPlansContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  getColumns = () => {
    return [
      { title: 'Nombre', source: 'name' },
      /* { title: 'Tipo de ruta', source: 'type' }, */
      { title: 'Estado', source: 'status' },
      { title: 'Cursos Totales', source: 'total_courses' },
      { title: 'Cursos Necesarios', source: 'total_courses_needed' },
      { title: 'Cursos Complementarios', source: 'total_courses_complementary' },
      { title: 'Duración Total (min)', source: 'total_duration' },
      { title: 'Duración Necesarios (min)', source: 'total_duration_needed' },
      { title: 'Duración Complementarios (min)', source: 'total_duration_complementary' },
      { title: 'Categorías', source: 'categories' },
      { title: 'Puestos', source: 'job_positions' },
      { title: 'Centros de Costos', source: 'cost_centers' },
    ]
  }

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'careerPlan.name',
        placeholder: 'Nombre del Plan',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.JobPosition,
        name: 'jobPositions.jobPosition',
        placeholder: 'Puesto',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'description',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnits.organizationalUnit',
        placeholder: 'Centro de Costos',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'code',
      },
      /* {
        type: EditInputType.Select,
        name: 'careerPlan.type',
        placeholder: 'Tipo de ruta',
        format: FiltersFormat.Plain,
        options: Object.keys(CareerPlanTypeNames).map(k => ({
          id: k,
          description: CareerPlanTypeNames[k],
        })),
      }, */
    ]
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE DETALLE RUTAS DE APRENDIZAJE'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsCareerPlansContainer.defaultProps = {
  type: KEY_NAME,
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  if (
    paginatorState &&
    paginatorState.user_courses &&
    paginatorState.user_courses.items
  ) {
    paginatorState.user_courses.items.map((course) =>
      course.completed_at && course.completed_at.length ?
        (course.completed = CourseProgress.true) :
        (course.completed = CourseProgress.false)
    );
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsCareerPlansContainer);
