import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MenuDrawer from '../../../components/admin/menu/MenuDrawer';
import { toggleMenu } from '../../../actions/menuDrawer';
import { goTo } from '../../../actions/navigator';

class MenuDrawerContainer extends Component {
  onMenuItemClick = (item) => {
    this.props.goTo(item.route);
  };

  render() {
    const {
      loading,
      menu: { mobileOpen },
      toggleMenu,
    } = this.props;
    return (
      <MenuDrawer
        onToggleMenu={toggleMenu}
        isLoading={loading}
        mobileOpen={mobileOpen}
        onMenuItemClick={this.onMenuItemClick}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: { ...state.menuDrawerReducer },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ toggleMenu, goTo }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuDrawerContainer);
