/* eslint-disable no-case-declarations */
import {
  COURSE_READ_END,
  COURSE_READ_FAILED,
  COURSE_READ_START, COURSE_UPDATE_END,
} from '../actions';

const initialState = {
  error: null,
  loading: false,
  course: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COURSE_READ_END:
      const { course } = action.payload;
      return {
        loading: false,
        course,
        error: null,
      };
    case COURSE_READ_START:
      return {
        course: null,
        error: null,
        loading: true,
      };
    case COURSE_READ_FAILED:
      const { message } = action.payload;
      return {
        course: null,
        error: message,
        loading: false,
      };
    case COURSE_UPDATE_END:
      const { updatedCourse } = action.payload;
      return {
        loading: false,
        course: {...updatedCourse},
        error: null,
      };
    default:
      return { ...state, error: null };
  }
};
