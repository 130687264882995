import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from "prop-types";

class ConfirmDialog extends React.Component {
  render() {
    const { title, description, onContinue, onCancel, open, cancelText = "CANCELAR" } = this.props;
    const buttonStyle = {
      color: '#000',
    };
    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={buttonStyle}
            onClick={() => {
              onCancel();
            }}
            color="primary"
          >
            {cancelText}
          </Button>
          <Button
            style={buttonStyle}
            onClick={() => {
              onContinue();
            }}
            color="primary"
            autoFocus
          >
            CONTINUAR
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmDialog.propType = {
  title: PropTypes.string,
  description: PropTypes.string,
  onContinue: PropTypes.fn,
  onCancel: PropTypes.fn,
  open: PropTypes.bool,
  cancelText: PropTypes.string
};

export default ConfirmDialog;
