import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  TableRow,
  TableCell,
  Checkbox,
  Button,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import {CourseStatus} from '../../../config/constants';

const styles = (theme) => ({
  root: {
    padding: '20px 0',
  },
  opcionalSelect: {},
  button: {
    marginRight: theme.spacing(1),
  },
});

function StepTwo({
  classes,
  courses,
  coursesSelected,
  handleChangeLotCourses,
  handleBack,
  handleNext,
}) {
  if (!coursesSelected) coursesSelected = [];
  const { columns, data } = courses;

  const [searchText, setSearchText] = useState('');

  const handleClick = (course) => {
    let hasCourse = isRowSelected(course.id);
    if (hasCourse) {
      coursesSelected = coursesSelected.filter((el) => el.id !== course.id);
    } else {
      coursesSelected.push(course);
    }
    handleChangeLotCourses(coursesSelected);
  };

  const isRowSelected = (id) => {
    if (coursesSelected === undefined || !coursesSelected) return false;
    return !!(coursesSelected.find(element => element.id === id));
  };

  const disableNextStepButton = () => {
    if (isEmpty(coursesSelected)) return true;
  }

  const getStatusName = (status) => {
    return CourseStatus[status];
  }

  const customRowRender = (row) => {
    let [id, title, categories, status] = row;
    let isSelected = isRowSelected(id);

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        key={'course' + id}
        selected={isSelected}
      >
        <TableCell
          padding="checkbox"
          onClick={() => handleClick({ id, title, categories })}
        >
          <Checkbox checked={isSelected} color={'primary'} />
        </TableCell>
        <TableCell align="left">{id}</TableCell>
        <TableCell align="left">{title}</TableCell>
        <TableCell align="left">{categories}</TableCell>
        <TableCell align="left">{getStatusName(status)}</TableCell>
      </TableRow >
    );
  };

  const options = () => ({
    download: false,
    print: false,
    rowsPerPage: 10,
    viewColumns: false,
    sortOrder: {
      name: "id",
      direction: "asc"
    },
    sort: true,
    filter: false,
    fixedHeader: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    searchText: searchText,
    customSearch: (searchQuery, currentRow, columns) => {
      setSearchText(searchQuery);
      return (currentRow && currentRow[1].toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0)
    },
    onSearchClose: () => {
      setSearchText(false)
    },
    customSort: (sortData, colIndex, order) => {
      return sortData.sort((a, b) => {
        var dir = order === "asc" ? 1 : -1;
        var isASelected = data.find(elem => {
          return (coursesSelected.find(element => element.id === a.data[0])) ? false : true;
        });
        var isBSelected = data.find(elem => {
          return (coursesSelected.find(element => element.id === b.data[0])) ? false : true;
        });
        if (isASelected && !isBSelected) return 1 * dir;
        if (!isASelected && isBSelected) return -1 * dir;
        return (a[colIndex] > b[colIndex] ? -1 : 1) * dir;
      });
    },
    customRowRender,
  });

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MUIDataTable
            title={'Cursos asociados'}
            data={[...data]}
            columns={[...columns]}
            options={options()}
          />
        </Grid>
      </Grid>
      <Grid className={classes.wrapperBoton}>
        <Button onClick={handleBack} className={classes.button}>
          Atrás
        </Button>
        <Button
          disabled={disableNextStepButton()}
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          Continuar
        </Button>
      </Grid>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(StepTwo);
