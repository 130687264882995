import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/navigator'
import { getResources, refreshResources, downloadFile } from '../../../actions/admin'
import { ADMIN_JOBS, ADMIN_NEW } from '../../../config/routes'
import AdminResourceIndex from '../AdminResourceIndex'
import {
  AdminItemViewType,
  FiltersFormat,
  EditInputType,
  FilterType,
  ImportJobsStatus,
  JobTypes,
} from '../../../config/constants'
import { addSnackbar } from '../../../actions/snackbar'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import FileSaver from 'file-saver'
import JobsErrorsDialog from '../../../components/admin/jobs/JobsErrorsDialog'

class AdminJobsContainer extends Component {
  state = {
    form: { keyName: '', query: '', page: '', size: '', isValid: false },
    errorsDialogOpen: false,
    errorsDetail: '',
  }

  resetTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const {
          form: { keyName, query, page, size, isValid },
        } = this.state
        if (isValid) {
          this.onFetch(keyName, query, page, size)
        }
      }, 10000)
    } else {
      this.timeout = setTimeout(() => {
        const {
          form: { keyName, query, page, size, isValid },
        } = this.state
        if (isValid) {
          this.onFetch(keyName, query, page, size)
        }
      }, 10000)
    }
  }

  componentWillUnmount() {
    this.forceDownload = false
    clearTimeout(this.timeout)
  }

  handleFetchFile = item => {
    this.forceDownload = true
    this.props
      .downloadFile(item.id, item.description)
      .then(() => {})
      .catch(() => {
        this.forceDownload = false
      })
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { file, filename } = nextProps

    if (file != null && this.forceDownload) {
      this.forceDownload = false
      FileSaver.saveAs(file, filename)
    }
  }

  setShouldRefresh = shouldRefresh => {
    if (!this.shouldRefresh && shouldRefresh) {
      this.resetTimeout()
    }
    this.shouldRefresh = shouldRefresh
  }

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props
    const context = this
    this.setShouldRefresh(false)
    this.setState({ form: { keyName, query, page, size, isValid: true } }, () => {
      getResources(type, keyName, query, page, size, jobs => {
        const pending = jobs.filter(job => '' + job.status === '0' || '' + job.status === '1')
        context.setShouldRefresh(pending.length > 0)
        return jobs
      })
    })
  }

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props
    const context = this
    this.setShouldRefresh(false)
    this.setState({ form: { keyName, query, page, size, isValid: true } }, () => {
      getResources(type, keyName, query, page, size, jobs => {
        const pending = jobs.filter(job => '' + job.status === '0' || '' + job.status === '1')
        context.setShouldRefresh(pending.length > 0)
        return jobs
      })
    })
  }

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Fecha', source: 'created_at', type: AdminItemViewType.Date },
      {
        title: 'Tipo',
        source: 'type',
        type: AdminItemViewType.Conditional,
        conditions: JobTypes.map(r => {
          return { condition: '' + r.value, result: r.title }
        }),
      },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Conditional,
        conditions: ImportJobsStatus.map(r => {
          return { condition: '' + r.id, result: r.description }
        }),
      },
      { title: 'Errores', source: 'lines_errors', type: AdminItemViewType.Text },
    ]
  }

  onCreate = () => {
    this.props.goTo(ADMIN_JOBS + ADMIN_NEW)
  }

  onShowErrors = item => {
    this.setState({ errorsDialogOpen: true, errorsDetails: item.errors_report })
  }

  actionsEnabled = () => {
    return true
  }

  getActions = item => {
    const actions = []
    //Si tiene más de un archivo, corrsponde a alta de ciclo->no se permite descargar los archivos
    if (item.job_files.length > 0) {
      item.job_files.forEach(element => {
        actions.push({
          title: element.description,
          action: () => this.onDownloadItem(element),
          icon: <DownloadIcon />,
        })
      })
      //actions.push({ title: 'DESCARGAR ARCHIVO', action: this.onDownloadItem, icon: <DownloadIcon /> });
    }
    if (item.lines_errors > 0) {
      actions.push({ title: 'VER ERRORES', action: this.onShowErrors, icon: <ErrorIcon /> })
    }
    return actions
  }

  onDownloadItem = item => {
    if (item.id) {
      this.handleFetchFile(item)
    }
  }

  getFilterConfig = () => {
    return [
      {
        title: 'Estado',
        name: 'job.status',
        type: EditInputType.Select,
        placeholder: 'Estado',
        options: ImportJobsStatus.map(r => {
          return { id: '' + r.id, description: r.description }
        }),
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Date,
        name: 'job.createdAt',
        placeholder: 'Fecha',
        format: FiltersFormat.Fecha,
      },
    ]
  }

  getOrderingConfig = () => {
    return [{ type: FilterType.Fixed, name: 'job.id', order: 'DESC' }]
  }

  getKeyName = () => {
    return 'admin-jobs'
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={'CARGA DE DATOS'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        getOrderingConfig={this.getOrderingConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.actionsEnabled() ? this.onCreate : null}
        forceNoActions={false}
      >
        <JobsErrorsDialog
          open={this.state.errorsDialogOpen}
          errors={this.state.errorsDetails}
          onDismiss={() => this.setState({ errorsDialogOpen: false, errorsDetail: '' })}
        />
      </AdminResourceIndex>
    )
  }
}

AdminJobsContainer.defaultProps = {
  type: 'jobs',
}

const mapStateToProps = state => {
  return {
    ...state.admin.files,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, refreshResources, goTo, addSnackbar, downloadFile },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminJobsContainer)
