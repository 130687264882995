import React, { Component } from 'react';
import AdminContentWrapper from './AdminContentWrapper';
import ResourceForm from '../../components/ui/ResourceForm';
import Loading from '../ui/Loading';
import BackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const styles = {
  wrapperStyles: {
    minHeight: 45,
    display: 'flex',
  },
};

class AdminResourceForm extends Component {
  render() {
    const {
      form,
      loading,
      loadingButton,
      attributes,
      title,
      children,
      keyName,
      onSubmit,
      onCancel,
      onGoBack,
      onAttributeChange,
      isValid,
      errors,
      customClasses,
      actionButtons,
      classes,
      customActions,
      submitLabel
    } = this.props;
    return (
      <AdminContentWrapper customClasses={customClasses}>
        {(onGoBack || title) && (
          <div
            className={
              customClasses && customClasses.wrapperStyles
                ? customClasses.wrapperStyles
                : classes.wrapperStyles
            }
          >
            {onGoBack != null ? (
              <IconButton
                style={{
                  float: 'left',
                  height: 56,
                  width: 56,
                  marginTop: 12,
                }}
                onClick={onGoBack}
              >
                <BackIcon style={{ height: 32, width: 32 }} />
              </IconButton>
            ) : null}
            <h1
              style={{
                float: 'left',
                marginLeft: 16,
                display: 'flex',
                flexGrow: 1,
              }}
            >
              {title}
            </h1>
          </div>
        )}
        {loading ? (
          <Loading dark={true} />
        ) : (
          [
            <ResourceForm
              key={keyName}
              keyName={keyName}
              form={form}
              attributes={attributes}
              onAttributeChange={onAttributeChange}
              onSubmit={onSubmit}
              onCancel={onCancel}
              isValid={isValid}
              errors={errors}
              loadingButton={loadingButton}
              customClasses={customClasses}
              actionButtons={actionButtons}
              customActions={customActions || false}
              submitLabel={submitLabel}
            />,
            children,
          ]
        )}
      </AdminContentWrapper>
    );
  }
}

export default withStyles(styles)(AdminResourceForm);
