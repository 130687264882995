
import api from '../api/api';
import {
  PLAYLIST_READ_END, PLAYLIST_READ_FAILED,
  PLAYLIST_READ_START,
  PLAYLISTS_READ_END,
  PLAYLISTS_READ_FAILED,
  PLAYLISTS_READ_START
} from "./index";
import {PlaylistGroup} from "../config/constants";


export function loadPlaylist(id, silent = false) {
  return async (dispatch) => {
    if(!silent) {
      dispatch({type: PLAYLIST_READ_START});
    }
    try {
      const response = await api.withDispatch(dispatch).Playlists.getById(id);
      dispatch({
        type: PLAYLIST_READ_END,
        payload: { playlist: response.data },
      });
    } catch (e) {
      dispatch({
        type: PLAYLIST_READ_FAILED,
        payload: { message: e.toString() },
      });
    }
  };
}

export function loadMyPlaylists(silent=false) {
  return async (dispatch) => {
    if(!silent) {
      dispatch({key: PlaylistGroup.MyPlaylists, type: PLAYLISTS_READ_START});
    }
    try {
      const response = await api.withDispatch(dispatch).My.Playlists.get();
      dispatch({
        key: PlaylistGroup.MyPlaylists,
        type: PLAYLISTS_READ_END,
        payload: { items: response.data },
      });
    } catch (e) {
      dispatch({
        key: PlaylistGroup.MyPlaylists,
        type: PLAYLISTS_READ_FAILED,
        payload: { message: e.toString() },
      });
    }
  };
}

export function loadPlaylistsByUser(silent = false, userId) {
  return async dispatch => {
    if (!silent) {
      dispatch({ key: PlaylistGroup.TopFollowedPlaylists, type: PLAYLISTS_READ_START })
    }
    try {
      if(userId) {
        const response = await api.withDispatch(dispatch).Playlists.getByUser(userId)
        dispatch({
          key: PlaylistGroup.TopFollowedPlaylists,
          type: PLAYLISTS_READ_END,
          payload: {items: response.data},
        })
      }else{
        dispatch({
          key: PlaylistGroup.TopFollowedPlaylists,
          type: PLAYLISTS_READ_END,
          payload: {items: []},
        })
      }
    } catch (e) {
      dispatch({
        key: PlaylistGroup.TopFollowedPlaylists,
        type: PLAYLISTS_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadTopFollowedPlaylists(silent=false) {
  return async (dispatch) => {
    if(!silent) {
      dispatch({key: PlaylistGroup.TopFollowedPlaylists, type: PLAYLISTS_READ_START});
    }
    try {
      const response = await api.withDispatch(dispatch).Playlists.top();
      dispatch({
        key: PlaylistGroup.TopFollowedPlaylists,
        type: PLAYLISTS_READ_END,
        payload: { items: response.data },
      });
    } catch (e) {
      dispatch({
        key: PlaylistGroup.TopFollowedPlaylists,
        type: PLAYLISTS_READ_FAILED,
        payload: { message: e.toString() },
      });
    }
  };
}

export function loadMyFollowedPlaylists(silent = false) {
  return async dispatch => {
    if (!silent) {
      dispatch({ key: PlaylistGroup.MyFollowedPlaylists, type: PLAYLISTS_READ_START })
    }
    try {
      const response = await api.withDispatch(dispatch).My.Playlists.following()
      dispatch({
        key: PlaylistGroup.MyFollowedPlaylists,
        type: PLAYLISTS_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        key: PlaylistGroup.MyFollowedPlaylists,
        type: PLAYLISTS_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}
