import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button
} from '@material-ui/core';
import classNames from 'classnames';
import { EditInputType, EditInputObjectModel } from '../../../config/constants';
import AdminResourceForm from '../AdminResourceForm';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '20px 0',
    background: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  textField: {
    width: '100%',
  },
  selects: {
    marginTop: '15px',
  },
  inputFile: {
    marginTop: '30px',
    display: 'none',
  },
  uploadIcon: {
    marginLeft: 10,
  },
  iconSmall: {
    fontSize: 20,
  },
  savedIcon: {
    marginLeft: 10,
  },
  wrapperButtonPdf: {
    marginTop: 10,
  },
  buttonViewPdfSelected: {
    marginRight: 10,
  },
  wrapperIconValuePdf: {
    position: 'relative',
  },
  buttonDeletePdf: {
    position: 'absolute',
    right: 0,
    bottom: 12,
  },
  footerButtons: {
    paddingTop: '3em'
  },
  button: {
    margin: '1em',
    padding: '1em 2em',
    borderRadius: '5px',
    border: '1px solid #575454',
    maxWidth: '16em',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '4em 0',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
  },
  containerTextInput: {
    maxWidth: '60%',
  },
  epigraph: {
    color: '#ada9a9',
    margin: '1em auto',
  },
  mainContentContainer: {
    width: '92%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    padding: '0 8rem',
  },
  mainImg: {
    width: '100%',
    height: '16em',
    background: '#969696',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'start',
    width: '16em',
    marginTop: '2em',
  },
  switchContainer: {
    margin: '2em 0',
  },
  textInput: {
    marginTop: '2em',
    padding: '1.4em 0',
    boxSizing: 'border-box',
  },
  // Form classes
  form: {
    padding: '1em 8em',
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    boxShadow: 'none',
  },
  input: {
    margin: '2em 4em',
    boxSizing: 'border-box',
  },
  switchInput: {
    marginLeft: '4em',
    boxSizing: 'border-box',
  },
});

const StepOne = ({
  classes,
  isEdit,
  disableNextButton,
  handleBack,
  handleNext,
  handleStepOneChange,
  form,
  results,
  onSubmit,
  onAttributeChange,
  title,
  loading,
  onGoBack,
}) => {

  const disableNextStepButton = () => {
    if (!disableNextButton) return true;

    return !!(isEdit && form.id === undefined);
  }

  const MAX_SIZE = 10;
  const getColumns = classes => [
    {
      source: 'image',
      editable: true,
      editConfig: {
        type: EditInputType.Dropzone,
        name: 'image',
        dropDetail: 'JPG, PNG',
        accept: 'image/png, image/jpg, image/jpeg',
        multiple: false,
        maxSize: MAX_SIZE,
      },
    },
    {
      title: 'Título',
      source: 'name',
      editable: true,
      editConfig: {
        type: EditInputType.Text,
        name: 'name',
        className: classes.input,
      },
    },
    {
      title: 'Descripción',
      source: 'description',
      editable: true,
      editConfig: {
        type: EditInputType.Text,
        name: 'description',
        className: classes.input,
      },
    },
    {
      title: 'Categorías',
      source: 'categories',
      editable: true,
      value: 'Categoría',
      editConfig: {
        name: 'categories',
        type: EditInputType.Object,
        model: EditInputObjectModel.Categories,
        multiple: true,
        className: classes.input,
        customFilter: {
          types: 2,
        },
      },
    },
    {
      title: 'Etiquetas',
      source: 'tags_list',
      editable: true,
      value: 'Etiqueta',
      editConfig: {
        name: 'tags_list',
        type: EditInputType.Object,
        model: EditInputObjectModel.Tags,
        placeholder: 'Etiqueta',
        multiple: true,
        creatable: true,
        className: classes.input,
      },
    },
    {
      source: 'generate_certificate',
      editable: true,
      value: 'generate_certificate',
      editConfig: {
        name: 'generate_certificate',
        type: EditInputType.Switch,
        label: 'Emite certificado',
        className: classes.switchInput,
      },
    },
  ]

  const handleFormChanges = async (name, value) => {
    onAttributeChange({
      ...form,
      [name]: name === 'image' ? value.acceptedFiles[0] : value,
      fileError:
        name === 'image' &&
          value &&
          value.failedFiles &&
          value.failedFiles.length
          ? `Debes subir un archivo menor a ${MAX_SIZE} mb`
          : undefined,
    });
    handleStepOneChange();
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.mainContentContainer}>
            <AdminResourceForm
              title={title}
              keyName={'admin-course-basic-data-form'}
              form={{
                ...form,
                image: form.image ? form.image.preview ? form.image.preview : form.image : '',
              }}
              isValid={results.isValid}
              errors={results.errors}
              loading={loading}
              attributes={{ keys: getColumns(classes) }}
              onSubmit={onSubmit}
              onGoBack={onGoBack}
              onAttributeChange={handleFormChanges}
              customClasses={classes}
              customActions={true}
            />
          </div>
        </div>
        <Grid container spacing={3} className={classNames(classes.form, classes.footerButtons)}>
          <Grid className={classes.wrapperBoton}>
            <Button onClick={handleBack} className={classes.button}>
              Atrás
            </Button>
            <Button
              disabled={disableNextStepButton()}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

StepOne.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StepOne);
