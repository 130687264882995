import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  SnackbarType,
  ModalitiesDescription,
  AdminItemViewType,
  UserCommissionAssistanceName,
  ModalitiesName,
  UserCommissionAssistanceDescription,
} from '../../../config/constants'

const KEY_NAME = 'reports/user_formations_external'

class AdminReportsExternalFormationsContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props
    getResources(type, keyName, query, page, size, undefined)
  }

  getColumns = () => {
    let columns = [
      { title: 'Usuario', source: 'user_name' },
      { title: 'Nombre del usuario', source: 'full_name' },
      { title: 'Nombre del curso', source: 'course_name' },
      { title: 'Duración total (min)', source: 'duration' },
      { title: 'Fecha de inicio', source: 'start_date', type: AdminItemViewType.Date },
      { title: 'Fecha de finalización', source: 'end_date', type: AdminItemViewType.Date },
      { title: 'Inversión', source: 'investment' },
      { title: 'Institución', source: 'institution' },
      {
        title: 'Modalidad',
        source: 'modality',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(ModalitiesName).map(k => ({
          condition: k,
          result: ModalitiesName[k],
        })),
      },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(UserCommissionAssistanceName).map(k => ({
          condition: k,
          result: UserCommissionAssistanceName[k],
        })),
      },
    ]
    return columns
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
      },
      {
        type: EditInputType.Text,
        name: 'course_name',
        placeholder: 'Nombre del curso',
        multiple: false,
        format: FiltersFormat.Like,
        displayAttribute: 'course_name',
        queryName: 'ef.courseName',
      },
      {
        type: EditInputType.Date,
        name: 'startDateFrom',
        placeholder: 'Fecha inicio Desde',
        format: FiltersFormat.MayorIgual,
        helpText: 'Fecha inicio Desde',
        queryName: 'ef.startDate',
      },
      {
        type: EditInputType.Date,
        name: 'startDateTo',
        placeholder: 'Fecha inicio Hasta',
        format: FiltersFormat.MenorIgual,
        helpText: 'Fecha inicio Hasta',
        queryName: 'ef.startDate',
      },
      {
        type: EditInputType.Select,
        name: 'status',
        placeholder: 'Estado',
        format: FiltersFormat.Plain,
        options: UserCommissionAssistanceDescription,
        queryName: 'ef.status',
      },
      {
        type: EditInputType.Select,
        name: 'modality',
        placeholder: 'Modalidad',
        format: FiltersFormat.Plain,
        options: ModalitiesDescription,
        queryName: 'ef.modality',
      },
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME).then(() => {
      addSnackbar(
        'Esta descarga pudo ser limitada debido a la cantidad de registros',
        SnackbarType.Info,
      )
    })
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE FORMACIONES EXTERNAS'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsExternalFormationsContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = ({ userReducer, paginatorReducer }) => ({
  me: userReducer,
  paginator: paginatorReducer[KEY_NAME],
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsExternalFormationsContainer)
