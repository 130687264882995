import React from 'react'
import Header from './HeaderExternalCourse'
import Content from './ContentExternalCourse'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: 'calc(100vw - 0%)',
    paddingBottom: 50,
  },
})

const ExternalCourse = ({ classes, course, loading, onShowCourse, onSubscribe, onUnsubscribe }) => {

  let coursesFiltered = undefined;
  if(course){
    const comissionsFiltered = course?.commissions?.filter(commision => commision.visible);
    coursesFiltered = {...course, commissions:comissionsFiltered};
  }
  
  return (
    <div className={classes.root}>
      <Header
        course={coursesFiltered ? coursesFiltered : course}
        loading={loading}
      />
      {course && (
        <Content
          keyName={'content-external-course-' + course.id}
          course={coursesFiltered ? coursesFiltered : course}
          onShowCourse={onShowCourse}
          onSubscribe={onSubscribe}
          onUnsubscribe={onUnsubscribe}
        />
      )}
    </div>
  )
}
export default withStyles(styles)(ExternalCourse)
