import React, { useEffect, useRef, useState } from 'react'
import ColorButton from '../../../components/ui/ColorButton'
import AdminContentWrapper from '../../../containers/admin/AdminContentWrapperContainer'
import {
  AdminItemViewType,
  COLOR_KEYS,
  ONLY_NUMBER_LETTER_REGEX_KEY,
  SnackbarType,
  DoneOptions,
  EditInputType,
  ActivityType,
} from '../../../config/constants'
import {
  submitActivityCompleteness,
  getUserActivities,
  updateActivityCompleteness,
  updateCourseCompleteness,
} from '../../../actions/admin'
import AdminResourceTable from '../../../components/admin/AdminResourceTable'
import ConfirmDialog from '../../../components/ui/ConfirmDialog'
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { KEY_KEYS } from '../../../constants'
import { itemEdit } from '../../../utils/AdminHelper'
import VisibilityIcon from '@material-ui/icons/Visibility'
import moment from 'moment'
import Loading from '../../../components/ui/Loading'
import useNotificate from '../../../hooks/useNotificate'
import ScormListModal from '../../../components/admin/reports/ScormListModal'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const today = moment().endOf('d').format('YYYY-MM-DD')

const completionStyles = {
  container: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
    textAlign: 'center',
    minHeight: '3rem',
    alignItems: 'center',
  },
  doneSelect: { width: '7rem', paddingRight: '1rem' },
  seeScorm: { maxWidth: '3rem', minWidth: '3rem', justifyContent: 'center' },
  datePicker: { width: '7rem', paddingRight: '1rem' },
  submitButton: { width: '6rem' },
  headerDefaults: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
}

const CompletionHeader = () => (
  <div style={completionStyles.container}>
    <div style={{ ...completionStyles.headerDefaults, ...completionStyles.doneSelect }}>Estado</div>
    <div style={{ ...completionStyles.headerDefaults, ...completionStyles.datePicker }}>Fecha</div>
    <div style={{ ...completionStyles.headerDefaults, ...completionStyles.submitButton }}>
      Acción
    </div>
  </div>
)

const SelectAssistance = ({
  done,
  id,
  completeness_at,
  setSelectedActivityId,
  selectedActivityId,
  setChangeStatusConfirmDialogOpen,
  selectedDate,
  setSelectedDate,
  editing,
}) => {
  const [value, setValue] = useState(done)
  const [completedAt, setCompletedAt] = useState()
  const [date, setDate] = useState()
  const [loading, setLoading] = useState()

  const onDateChange = (_, b) => {
    setDate(b)
  }

  const onSubmit = () => {
    setSelectedActivityId(id)
    setSelectedDate(date)
  }

  useEffect(() => {
    if (selectedActivityId && selectedDate) {
      setChangeStatusConfirmDialogOpen(true)
    }
  }, [selectedActivityId, selectedDate])

  useEffect(() => {
    setLoading(editing && selectedActivityId === id)
  }, [editing, selectedActivityId, id])

  useEffect(() => {
    setCompletedAt(moment(completeness_at).format('YYYY-MM-DD'))
  }, [completeness_at])

  useEffect(() => {
    setDate(completedAt)
  }, [completedAt])

  return loading ? (
    <div style={{ ...completionStyles.container, justifyContent: 'center' }}>
      <CircularProgress size={16} style={{ marginLeft: '1rem', color: 'GrayText' }} />
    </div>
  ) : (
    <div style={completionStyles.container}>
      <Select
        disabled={done === 1}
        value={value}
        onChange={({ target }) => {
          if (target.value == 0) {
            setDate(undefined)
          }
          setValue(target.value)
        }}
        input={<Input id="select-multiple-checkbox" />}
        MenuProps={MenuProps}
        style={{ ...completionStyles.headerDefaults, width: '8rem' }}
        disableUnderline
      >
        {DoneOptions.map(item => {
          return (
            <MenuItem key={item.id} value={item.id}>
              <ListItemText primary={item.description} />
            </MenuItem>
          )
        })}
      </Select>
      <div style={{ ...completionStyles.headerDefaults, width: '8rem' }}>
        {value === 1 &&
          itemEdit(
            {
              type: EditInputType.Date,
              name: `date-${id}`,
              placeholder: 'Fecha',
              max: today,
            },
            date,
            onDateChange,
          )}
      </div>
      <div style={{ ...completionStyles.headerDefaults, ...completionStyles.submitButton }}>
        {value === 1 && (
          <Button disabled={!(value && date) || completedAt === date} onClick={onSubmit}>
            Guardar
          </Button>
        )}
      </div>
    </div>
  )
}

const fileRegex = new RegExp(ONLY_NUMBER_LETTER_REGEX_KEY)

const { PRIMARY } = COLOR_KEYS

const btnStyles = {
  height: '3rem',
  padding: '1rem',
  justifyContent: 'center',
  alignItems: 'center',
}

const AdminReportsCourseActivities = () => {
  const fileInput = useRef(null)
  const courseIdInput = useRef(null)
  const searchButton = useRef(null)
  const { notificate } = useNotificate()
  const [data, setData] = useState()
  const [dataIsLoading, setDataIsLoading] = useState()
  const [editing, setEditing] = useState(false)
  const [changeStatusConfirmDialogOpen, setChangeStatusConfirmDialogOpen] = useState(false)
  const [changeCourseDateConfirmDialogOpen, setChangeCourseDateConfirmDialogOpen] = useState(false)
  const [selectedActivityId, setSelectedActivityId] = useState()
  const [selectedActivity, setSelectedActivity] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const [currentCourse, setCurrentCourse] = useState()
  const [courseDate, setCourseDate] = useState()
  const [updatingCourseDate, setUpdatingCourseDate] = useState()
  const [scormIdToGetEvents, setScormIdToGetEvents] = useState()

  const [userFile, setUserFile] = useState('')
  const [courseId, setCourseId] = useState('')
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)

  const reset = () => {
    setSelectedActivityId(undefined)
    setSelectedActivity(undefined)
    setSelectedDate(undefined)
    setScormIdToGetEvents(undefined)
  }

  const onCourseDateChange = (_, b) => {
    setCourseDate(b)
  }

  const handleUpdateCourseDate = () => {
    setChangeCourseDateConfirmDialogOpen(true)
  }

  const removeScormIdToGetEvents = () => {
    setScormIdToGetEvents(undefined)
  }

  const handleInputKeyPress = (e, inputRef, valueToCheck) => {
    const { key, target } = e
    if (key === KEY_KEYS.ENTER) {
      if (target.value) {
        if (valueToCheck) {
          searchButton.current.props.onClick()
        } else {
          inputRef.current.firstChild.focus()
        }
      }
    }
  }

  const handleGetActivities = async () => {
    setDataIsLoading(true)
    try {
      const response = await getUserActivities(userFile, courseId)
      setData(response.data)
      if (response.data.blocks.length) {
        notificate('Datos obtenidos correctamente')
      } else {
        notificate('El usuario no tiene actividades', SnackbarType.Warning)
      }
    } catch (error) {
      setData(undefined)
      setDataIsLoading(false)
      const errorMessage = error.message ? error.message : 'Error al obtener datos'
      notificate(errorMessage, SnackbarType.Error)
    }
  }

  const changeStatus = async ({ onSuccessMsg, onErrorMsg }, done) => {
    setEditing(true)
    try {
      const payload = {
        user_id: data.user.id,
        activity_id: selectedActivity.id,
        course_id: currentCourse,
        completeness_at: selectedDate,
      }
      const response = done
        ? await updateActivityCompleteness(payload)
        : await submitActivityCompleteness(payload)
      reset()
      setData(response.data)
      notificate(onSuccessMsg)
    } catch {
      reset()
      setEditing(false)
      notificate(onErrorMsg, SnackbarType.Error)
    }
  }

  const updateCourseDate = async () => {
    setUpdatingCourseDate(true)
    try {
      const payload = {
        id: data.user_course_completeness.id,
        course_id: courseId,
        user_id: data.user.id,
        date: courseDate,
      }
      await updateCourseCompleteness(payload)
      setData(state => ({
        ...state,
        user_course_completeness: {
          ...state.user_course_completeness,
          date: courseDate,
        },
      }))
      notificate('Se ha actualizado la fecha de completitud del curso para el usuario seleccionado')
      setUpdatingCourseDate(false)
    } catch {
      setUpdatingCourseDate(false)
      notificate('No ha sido posible actualizar la fecha', SnackbarType.Error)
    }
  }

  const columns = [
    {
      title: 'ID',
      source: 'id',
      type: AdminItemViewType.Number,
    },
    {
      title: 'Nombre (actividad)',
      source: 'name',
      type: AdminItemViewType.Text,
    },
    {
      title: 'Tipo',
      source: 'type',
      type: AdminItemViewType.Custom,
      renderItem: item => (
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          {item.type}
          {item.type === ActivityType.Scorm && (
            <IconButton onClick={() => setScormIdToGetEvents(item.scorm_id)}>
              <VisibilityIcon />
            </IconButton>
          )}
        </div>
      ),
    },
    {
      title: <CompletionHeader />,
      source: 'status',
      type: AdminItemViewType.Custom,
      renderItem: item => (
        <SelectAssistance
          {...item}
          setSelectedActivityId={setSelectedActivityId}
          selectedActivityId={selectedActivityId}
          setChangeStatusConfirmDialogOpen={setChangeStatusConfirmDialogOpen}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          editing={editing}
        />
      ),
    },
  ]

  useEffect(() => {
    if (data && (dataIsLoading || editing)) {
      setDataIsLoading(false)
      setEditing(false)
    }
    if (data?.user_course_completeness?.date) {
      setCourseDate(moment(data.user_course_completeness.date).format('YYYY-MM-DD'))
    } else {
      setCourseDate(undefined)
    }
  }, [data])

  useEffect(() => {
    if (selectedActivityId && data?.blocks) {
      setSelectedActivity(data.blocks.find(({ id }) => id === selectedActivityId))
    }
  }, [selectedActivityId])

  useEffect(() => {
    if (!dataIsLoading) setCurrentCourse(courseId)
  }, [dataIsLoading])

  useEffect(() => {
    const isSearchButtonDisabled = !userFile || !courseId
    setSearchButtonDisabled(isSearchButtonDisabled)
  }, [userFile, courseId])

  return (
    <AdminContentWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '2rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <h1 style={{ marginLeft: 16 }}>Actividades de cursos</h1>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '2rem',
          }}
        >
          <Input
            ref={fileInput}
            placeholder="Legajo"
            name="file"
            value={userFile}
            style={{ width: '5rem' }}
            inputProps={{
              style: { textAlign: 'center' },
            }}
            onChange={({ target }) => setUserFile(target.value)}
            onKeyPress={e => {
              const { key } = e
              if (!fileRegex.test(key)) {
                e.preventDefault()
              }
              handleInputKeyPress(e, courseIdInput, courseId)
            }}
          />
          <Input
            ref={courseIdInput}
            placeholder="Curso"
            name="courseId"
            value={courseId}
            style={{ width: '4rem' }}
            inputProps={{
              style: { textAlign: 'center' },
            }}
            onChange={({ target }) => setCourseId(target.value)}
            onKeyPress={e => {
              const { key } = e
              if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(key)) {
                e.preventDefault()
              }
              handleInputKeyPress(e, fileInput, userFile)
            }}
          />
          <ColorButton
            ref={searchButton}
            color={PRIMARY}
            label="Buscar"
            style={btnStyles}
            onClick={handleGetActivities}
            disabled={searchButtonDisabled}
          />
        </div>
      </div>
      <div style={{ display: 'flex', gap: '0.75rem', height: '2rem', paddingLeft: '1rem' }}>
        {data?.user?.full_name ? (
          <>
            <Typography>Usuario:</Typography>
            <Typography style={{ fontWeight: 600 }}>{data.user.full_name}</Typography>
          </>
        ) : (
          <Typography>Ingrese Legajo de usuario y ID de Curso</Typography>
        )}
      </div>
      <div style={{ display: 'flex', gap: '0.75rem', height: '2rem', paddingLeft: '1rem' }}>
        {data?.user_course_completeness?.course_title && (
          <>
            <Typography>Curso:</Typography>
            <Typography style={{ fontWeight: 600 }}>
              {data.user_course_completeness.course_title}
            </Typography>
          </>
        )}
      </div>
      {dataIsLoading ? (
        <Loading dark fullWidth />
      ) : (
        <>
          {data?.blocks && (
            <AdminResourceTable
              key="resource-table"
              items={data.blocks}
              columns={columns}
              forceNoActions
              isLoading={dataIsLoading}
            />
          )}
          {courseDate && (
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
                marginTop: '1.5rem',
                paddingLeft: '1rem',
              }}
            >
              <Typography style={{ whiteSpace: 'nowrap' }}>
                El usuario ha completado el curso el dia
              </Typography>
              <div style={{ width: '8rem' }}>
                {itemEdit(
                  {
                    type: EditInputType.Date,
                    name: 'date',
                    placeholder: 'Fecha',
                    max: today,
                  },
                  courseDate,
                  onCourseDateChange,
                )}
              </div>
              <div
                style={{
                  width: '6rem',
                  visibility: data?.user_course_completeness?.date.includes(courseDate)
                    ? 'hidden'
                    : 'unset',
                }}
              >
                <Button
                  disabled={
                    data?.user_course_completeness?.date.includes(courseDate) || updatingCourseDate
                  }
                  onClick={handleUpdateCourseDate}
                  style={{ height: '2.5rem' }}
                >
                  {updatingCourseDate ? <CircularProgress size={20} /> : 'Modificar'}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <ConfirmDialog
        title={'ATENCIÓN - Cambiar estado'}
        open={changeStatusConfirmDialogOpen}
        description={`Desea marcar la actividad ID ${
          selectedActivity?.id
        } como completa en la fecha ${moment(selectedDate).format('DD/MM/YYYY')}`}
        onContinue={() => {
          changeStatus(
            {
              onSuccessMsg: 'La actividad se ha marcado como completa',
              onErrorMsg:
                'No ha sido posible al modificar el estado de completitud de la actividad',
            },
            !!selectedActivity.done,
          )
          setChangeStatusConfirmDialogOpen(false)
        }}
        onCancel={() => setChangeStatusConfirmDialogOpen(false)}
      />
      <ConfirmDialog
        title={'ATENCIÓN - Modificar fecha de completitud'}
        open={changeCourseDateConfirmDialogOpen}
        description={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Typography>El curso:</Typography>
              <Typography style={{ fontWeight: 600 }}>
                {data?.user_course_completeness?.course_title}
              </Typography>
            </div>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Typography>Se marcará como completo el día:</Typography>
              <Typography style={{ fontWeight: 600 }}>
                {moment(courseDate).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
        }
        onContinue={() => {
          updateCourseDate()
          setChangeCourseDateConfirmDialogOpen(false)
        }}
        onCancel={() => setChangeCourseDateConfirmDialogOpen(false)}
      />
      {data?.user && scormIdToGetEvents && (
        <ScormListModal
          removeScormIdToGetEvents={removeScormIdToGetEvents}
          scormIdToGetEvents={scormIdToGetEvents}
          user={data?.user}
        />
      )}
    </AdminContentWrapper>
  )
}

export default AdminReportsCourseActivities
