/* eslint-disable no-case-declarations */
import {
  MY_SELECTED_USERS_START,
  MY_SELECTED_USERS_END,
  MY_SELECTED_USERS_FAILED,
} from '../actions'

const initialState = {
  error: null,
  loading: false,
  token: null,
}

// user reducer: manage login & logout state changes
export default (state = initialState, action) => {
  switch (action.type) {
    case MY_SELECTED_USERS_START:
      return {
        items: [],
        loading: true,
        error: null,
      }
    case MY_SELECTED_USERS_FAILED:
      const { message } = action.payload
      return {
        items: [],
        loading: false,
        error: message,
      }
    case MY_SELECTED_USERS_END: {
      return {
        items: action.payload,
        error: null,
        loading: false,
      }
    }
    default:
      return { ...state, error: null }
  }
}
