import { KEY_KEYS } from '../constants'

const handleSubmenuOnTabKeyDown = event => {
  if (event.key === KEY_KEYS.TAB && !event.currentTarget.nextSibling) {
    event.preventDefault()
    event.stopPropagation()
    event.currentTarget.parentElement.firstChild.focus()
  }
}

export { handleSubmenuOnTabKeyDown }
