/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './ActivityModalStyles';
import AdminResourceForm from '../../AdminResourceForm';
import { EditInputType } from '../../../../config/constants';

const FORM_COLUMNS = [
  {
    title: 'Nombre',
    source: 'name',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'name' },
  },
  {
    title: 'Descripción',
    source: 'description',
    editable: true,
    editConfig: {
      type: EditInputType.Text,
      name: 'description',
      multiline: 4,
    },
  },
  {
    source: 'url',
    editable: true,
    editConfig: {
      type: EditInputType.File,
      name: 'url',
      placeholder: 'PDF, DOC, DOCX, XLS, XLSX, PPT, PPS, PPTX o PPSX',
      accept:
        'application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.slideshow, ,application/pdf, application/x-pdf',
      multiple: false,
    },
  },
];

const ActivityModalFileContent = ({
  activity,
  results,
  onChange,
  classes,
  onSubmit,
  actionButtons,
  onCancel,
  title,
}) => {
  const onAttributeChange = async (name, value) => {
    onChange({
      ...activity,
      [name]: name === 'url' ? value[0] : value,
    });
  };
  return (
    <>
      <AdminResourceForm
        title={title}
        keyName={'admin-activity-modal-image-form'}
        form={{
          ...activity,
          url: activity.url ? activity.url.preview ? activity.url.preview : activity.url : "",
        }}
        isValid={results && results.isValid}
        errors={results ? results.errors : []}
        loading={false}
        attributes={{ keys: FORM_COLUMNS }}
        onAttributeChange={onAttributeChange}
        customClasses={classes}
        onSubmit={onSubmit}
        onCancel={onCancel}
        actionButtons={actionButtons}
      />
    </>
  );
};

export default withStyles(styles)(ActivityModalFileContent);
