import ActivityModalTextContent from './ActivityModalTextContent'
import ActivityModalImageContent from './ActivityModalImageContent'
import ActivityModalExternContent from './ActivityModalExternContent'
import { ActivityType } from '../../../../config/constants'
import ActivityModalH5pContent from './ActivityModalH5pContent'
import ActivityModalNewScormContent from './ActivityModalScormContent'
import ActivityModalFileContent from './ActivityModalFileContent'
import ActivityModalSelect from './ActivityModalSelect'
import ActivityModalQuizTriviaContent from './ActivityModalQuizTriviaContent'

export const ACTIVITY_TYPE_COMPONENTS = {
  [ActivityType.Text]: ActivityModalTextContent,
  [ActivityType.Image]: ActivityModalImageContent,
  [ActivityType.External]: ActivityModalExternContent,
  [ActivityType.Scorm]: ActivityModalNewScormContent,
  [ActivityType.Video]: ActivityModalH5pContent,
  [ActivityType.Audio]: ActivityModalH5pContent,
  [ActivityType.Presentation]: ActivityModalH5pContent,
  [ActivityType.Trivia]: ActivityModalH5pContent,
  [ActivityType.Timeline]: ActivityModalH5pContent,
  [ActivityType.File]: ActivityModalFileContent,
  [ActivityType.Select]: ActivityModalSelect,
  [ActivityType.QuizTrivia]: ActivityModalQuizTriviaContent,
}
