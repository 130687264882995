/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SettingsIcon from '@material-ui/icons/Settings'
import KeyIcon from '@material-ui/icons/VpnKey'
import CheckIcon from '@material-ui/icons/Check'
import BlockIcon from '@material-ui/icons/Block'
import { activityTypeIcon } from '../../../../utils/ActivitiesHelper'
import { useDispatch } from 'react-redux'
import { goTo } from '../../../../actions/navigator'
import { ADMIN_EDIT, ADMIN_SCORMS, router } from '../../../../config/routes'
import Activity from '../../../courses/Activity'
import { ActivityType } from '../../../../config/constants'

const styles = {
  activityHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    height: '3em',
    width: '100%',
  },
}

const ActivityIcon = ({ activity }) => {
  return activityTypeIcon(activity, '')
}

const ActivityModuleHeader = ({
  onEdit,
  onRemove,
  onUpdateRequired,
  onMilestone,
  classes,
  activity,
  unableActionsCreateDelete,
  availableMilestone,
}) => {
    const dispatch = useDispatch()
    const openScormSettings =  useCallback(() => {
      dispatch(goTo(router.getRoute(ADMIN_SCORMS + ADMIN_EDIT, { id: activity.external })))
    }, [goTo, dispatch])
    return (
      <div className={classes.activityHeader}>
        {unableActionsCreateDelete !== true && (
          <Tooltip title={activity.required ? 'MARCAR COMO NO REQUERIDA' : 'MARCAR COMO REQUERIDA'}>
            <IconButton onClick={() => onUpdateRequired(activity, !activity.required)}>
              {activity.required ? <BlockIcon /> : <CheckIcon />}
            </IconButton>
          </Tooltip>
        )}
        {availableMilestone && (
          <Tooltip title="HITOS">
            <IconButton onClick={onMilestone}>
              <KeyIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="EDITAR">
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {activity.type === ActivityType.Scorm && (
          <Tooltip title="SCORM CONFIG">
            <IconButton onClick={openScormSettings}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        )}
        {unableActionsCreateDelete !== true && (
          <Tooltip title="ELIMINAR">
            <IconButton onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    )
    }

export default withStyles(styles)(ActivityModuleHeader)
