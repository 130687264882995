/* eslint-disable no-undef */
// Put environment configuration here!

function getBaseUrl() {
  let baseUrl = process.env.REACT_APP_BASE_URL || '/'
  if (baseUrl.charAt(baseUrl.length - 1) !== '/') {
    baseUrl += '/'
  }
  return baseUrl
}

export default {
  API_ROOT: process.env.REACT_APP_SERVER_URL || 'http://localhost:8000/api',
  API_BASE_ROOT:
    process.env.DEVELOPMENT_WP_URL || 'https://santander-academia-api.development.mag.dev',
  WP_ROOT: process.env.REACT_APP_SERVER_WP_URL || 'http://localhost:8000/wp',
  BASE_URL: getBaseUrl(),
  IDFW_IN: process.env.REACT_APP_IDFW_IN,
  IDFW_OUT: process.env.REACT_APP_IDFW_OUT,
  IDFW_OB: process.env.REACT_APP_IDFW_OB,
  RECAPTCHA_SECRET_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
}
