import React, { useCallback, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Tooltip, IconButton, Typography, DialogContent, Dialog } from '@material-ui/core'

const styles = theme => ({
  root: {
    width: '100%',
  },

  header: {
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBox: {
    width: '90%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  headerIconRight: {
    height: 32,
    width: 32,
    color: '#ffffff',
    position: 'absolute',
    top: 8,
    right: 8,
    padding: 0,
  },
  headerCloseIcon: {
    height: 16,
    width: 16,
  },
  headerName: {
    position: 'absolute',
    top: 16,
    left: 16,
    padding: 0,
    color: '#ffffff',
    fontSize: 12,
  },

  headerContent: {},
  headerText: {
    color: '#ffffff',
    width: '100%',
    fontSize: 20,
    textAlign: 'center',
  },
  congratulations: {
    color: '#ffffff',
    width: '100%',
    fontSize: 26,
    fontWeight: 600,
    textAlign: 'center',
  },

  headerSubtitle: {
    color: '#ffffff',
    width: '100%',
    fontSize: 14,
    textAlign: 'center',
  },

  headerIcon: {
    color: '#ffffff',
    width: '100%',
    textAlign: 'center',
  },

  paperDialog: {
    borderRadius: 0,
  },
  paperWidthSm: {
    maxWidth: 700,
  },
})

const GenericDialog = ({
  classes,
  miniHeader = false,
  name = false,
  title = false,
  title2 = false,
  congratulations = false,
  subtitle = false,
  icon,
  headerColor = '#1ed1a8',
  open,
  children,
  onExit = () => {},
  onDismiss,
}) => {
  let headerRef = useRef(null)

  useEffect(() => {
    if (headerRef.current) {
      setTimeout(() => headerRef.current && headerRef.current.focus(), 1000)
    }
  }, [headerRef])

  const handleClose = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  const renderHeader = useCallback(() => {
    return (
      <div
        ref={headerRef}
        tabIndex={-1}
        className={classes.header}
        style={{ background: headerColor, minHeight: miniHeader ? 50 : 200 }}
      >
        {name && (
          <Typography component={'h2'} className={classes.headerName} tabIndex={0}>
            {' '}
            {name}
          </Typography>
        )}
        <div className={classes.headerBox}>
          {icon && <div className={classes.headerIcon}> {icon}</div>}
          {title && (
            <Typography component={'h1'} className={classes.headerText} tabIndex={0}>
              {' '}
              {title}
            </Typography>
          )}
          {title2 && (
            <Typography component={'h2'} className={classes.headerText} tabIndex={0}>
              {' '}
              {title2}
            </Typography>
          )}
          {congratulations && (
            <Typography component={'h3'} className={classes.congratulations} tabIndex={0}>
              {' '}
              {congratulations}
            </Typography>
          )}
          {subtitle && (
            <Typography component={'h3'} className={classes.headerSubtitle} tabIndex={0}>
              {' '}
              {subtitle}
            </Typography>
          )}
        </div>
        <Tooltip title={'CERRAR'}>
          <IconButton
            tabIndex={0}
            aria-label={'cerrar'}
            className={classes.headerIconRight}
            onClick={handleClose}
          >
            <CloseIcon className={classes.headerCloseIcon} />
          </IconButton>
        </Tooltip>
      </div>
    )
  }, [headerRef, handleClose, classes, congratulations, headerColor, icon, miniHeader, name, subtitle, title, title2])

  return (
    <Dialog
      onEscapeKeyDown={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      className={classes.root}
      classes={{
        paper: classes.paperDialog,
        paperWidthSm: classes.paperWidthSm,
      }}
      open={open}
      onExit={onExit}
    >
      {renderHeader()}
      <div>
        <DialogContent>{children}</DialogContent>
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(GenericDialog)
