import React, { useState, useCallback } from 'react'
import Header from './HeaderPlaylists'
import Content from './ContentPlaylists'
import { withStyles } from '@material-ui/core/styles'
import TabBar from '../ui/TabBar'
import { EditInputObjectModel } from '../../config/constants'
import {Grid, TextField} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import AutocompleteSimpleObjectInput from "../admin/inputs/AutocompleteSimpleObjectInput";

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: '100vw',
  },
  playlistsFilter: {
    display: 'flex',
    justifyContent: 'center',
    margin: 16,
  },

  playlistsFilterInputWrapper: {
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '10px',
    minWidth: 400,
    maxWidth: '80%',
  },
  customSearchFormControlRoot: {
    backgroundColor: '#fff',
    borderRadius: '5px',
    minWidth: 'unset',
  },

  customSearchInputRoot: {
    '&:after': {
      border: 'none',
      transition: 'none',
    },
    '&:before': {
      border: 'none',
      transition: 'none',
      content: 'none',
    },
    '&:hover': {
      border: 'none',
    },
  },
})

const initialTabs = [
  { type: 0, title: 'Mis listas', active: true, visible: true },
  { type: 1, title: 'Listas que sigo ', active: false, visible: true },
  { type: 2, title: 'Otras listas', active: false, visible: true },
]

const SearchInputComponent = ({ classes, ...props }) => {
  return (
      <TextField
          tabIndex={-1}
          {...props}
          type="search"
          aria-hidden={true}
          placeholder={'Buscar por nombre, apellido o e-mail'}
          fullWidth={true}
          disableUnderline={true}
          classes={{
            root: classes.customSearchFormControlRoot,
          }}
          InputProps={{
            classes: {
              root: classes.customSearchInputRoot,
            },
            endAdornment: <SearchIcon />,
          }}
          inputProps={{
            tabIndex: -1,
            'aria-hidden': true,
          }}
      />
  )
}

const Filters = ({ filters = [], classes, updateFilters }) => {
  return (
      <div className={classes.playlistsFilter}>
        <div className={classes.playlistsFilterInputWrapper}>
          <AutocompleteSimpleObjectInput
              model={EditInputObjectModel.UsersToShare}
              style={{ minWidth: '50%' }}
              displayAttribute={'full_name'}
              name={'users'}
              creatable={false}
              value={filters}
              onChange={(name, value) => updateFilters(value)}
              multiple={true}
              autoFocus={false}
              reverse={true}
              renderInputComponent={inputProps => (
                  <SearchInputComponent {...inputProps} classes={classes} />
              )}
          />
        </div>
      </div>
  )
}

const Playlists = ({
  classes,
  myPlaylists,
  myFollowedPlaylists,
  onShowCourse,
  onShowPlaylist,
  onFollowUnfollow,
  onLoadPlayListByUser,
  topPlaylists,
}) => {
  const [tabs, updateTabs] = useState([...initialTabs])
  const [filters, updateFilters] = useState([])

  const onChageTab = useCallback(
    index => {
      updateTabs(
        initialTabs.map((t, i) => {
          t.active = i === index
          return t
        }),
      )
    },
    [updateTabs],
  )

  const onChange = items => {
    updateFilters(items)
    if(items && items.length>0) {
      onLoadPlayListByUser(items)
    }else{
      onLoadPlayListByUser(false)
    }
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Header />
        {tabs.filter(t => t.visible).length > 2 && (
          <TabBar
            style={{ textAlign: 'center', marginBottom: 30 }}
            tabProps={{
              style: {
                padding: 16,
              },
            }}
            tabs={tabs}
            onChageTab={onChageTab}
          />
        )}

        {topPlaylists && tabs[2].active && (
          <Grid container justify="center">
            <Grid className={classes.finderUsers} item xs={5} md={5} lg={5}>

              <Filters classes={classes} filters={filters} updateFilters={onChange}/>

            </Grid>
          </Grid>
        )}

        {myPlaylists && tabs[0].active && (
          <Content
            onShowCourse={onShowCourse}
            myPlaylists={myPlaylists}
            onShowPlaylist={onShowPlaylist}
            onFollowUnfollow={onFollowUnfollow}
          />
        )}
        {myFollowedPlaylists && tabs[1].active && (
          <Content
            onShowCourse={onShowCourse}
            myFollowedPlaylists={myFollowedPlaylists}
            onShowPlaylist={onShowPlaylist}
            onFollowUnfollow={onFollowUnfollow}
          />
        )}
        {topPlaylists && tabs[2].active && (
          <Content
            onShowCourse={onShowCourse}
            topPlaylists={topPlaylists}
            onShowPlaylist={onShowPlaylist}
            onFollowUnfollow={onFollowUnfollow}
          />
        )}
      </div>
    </React.Fragment>
  )
}
export default withStyles(styles)(Playlists)
