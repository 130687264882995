import { PlaylistGroup } from '../../config/constants'
import { PLAYLISTS_READ_END, PLAYLISTS_READ_FAILED, PLAYLISTS_READ_START } from '../../actions'

const initialState = {
  key: PlaylistGroup.Any,
  error: null,
  loading: false,
  items: [],
}

const states = {}

Object.keys(PlaylistGroup).forEach( pgk =>
    states[PlaylistGroup[pgk]] = {...initialState, key: PlaylistGroup[pgk]}
)

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key]
  }
  states[key] = { ...initialState, key }
  return states[key]
}

export default (state = states, action) => {
  const key = action.key || PlaylistGroup.Any
  const currentState = getStateFor(key)
  switch (action.type) {
    case PLAYLISTS_READ_START:
      states[key] = {
        ...currentState,
        items: [],
        error: null,
        loading: true,
      }
      break
    case PLAYLISTS_READ_END:
      {
        const { items = [] } = action.payload
        states[key] = {
          ...currentState,
          items: items,
          error: null,
          loading: false,
        }
      }
      break
    case PLAYLISTS_READ_FAILED:
      {
        const { message = 'ERROR' } = action.payload
        states[key] = {
          ...currentState,
          items: [],
          error: message,
          loading: false,
        }
      }
      break
  }
  return states
}
