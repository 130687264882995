import React, { Component } from 'react'
import { Select, MenuItem, Chip, Typography } from '@material-ui/core'
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import classnames from 'classnames'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '0px !important',
      },
    },
  },
})

const styles = () => ({
  select: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 0,
    fontWeight: 400,
    fontSize: 14,
  },
  selectField: {
    fontSize: 14,
  },
  placeholder: {
    opacity: 0.42,
  },
  input: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  helptext: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    marginTop: 2,
    fontWeight: 400,
    fontSize: 10,
  },
})

class SelectInput extends Component {
  state = {
    open: false,
  }

  setOpen = open => {
    this.setState({ open })
  }

  handleOnChange = event => {
    const { name, onChange, multiple = false, max = false } = this.props
    const value = event.target.value
    if (!multiple || !max || (max && value.length <= max)) {
      if (value !== '') onChange(name, value)
      if (max && value.length === max) {
        this.setOpen(false)
      }
    } else {
      this.setOpen(true)
    }
  }

  render() {
    const {
      name,
      value,
      placeholder,
      options = [],
      classes,
      multiple = false,
      tabIndex = 0,
      helperText,
      showEmpty = false,
      disabled = false,
      readOnly = false,
    } = this.props

    return (
      <MuiThemeProvider theme={theme}>
        <Select
          multiple={multiple}
          className={classes.select}
          open={this.state.open}
          onClose={() => this.setOpen(false)}
          onOpen={() => this.setOpen(true)}
          value={value}
          displayEmpty
          readOnly={readOnly}
          disabled={disabled}
          renderValue={v =>
            value !== null && (value === '' || (Array.isArray(value) && value.length === 0)) ? (
              placeholder
            ) : multiple ? (
              <div className={classes.chips}>
                {value.map(vitem => (
                  <Chip
                    key={vitem}
                    label={
                      (options.filter(o => o.id === vitem).pop() || { description: '-' })
                        .description
                    }
                    className={classes.chip}
                  />
                ))}
              </div>
            ) : (
              (options.filter(o => o.id === value).pop() || { description: '-' }).description
            )
          }
          onChange={this.handleOnChange}
          inputProps={{
            className: classnames(classes.input, {
              [classes.placeholder]: value === '',
            }),
            name: name,
            tabIndex: tabIndex,
          }}
          placeholder={placeholder}
        >
          {showEmpty && !multiple && (
            <MenuItem className={classes.selectField} key={'menu-option-empty'} value={false}>
              <em>Ninguno</em>
            </MenuItem>
          )}
          {options.map(option => (
            <MenuItem
              className={classes.selectField}
              key={'menu-option-' + option.id}
              value={option.id}
            >
              {option.description}
            </MenuItem>
          ))}
        </Select>
        {helperText && <Typography className={classes.helptext}>helperText</Typography>}
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(SelectInput)
