import React, { Component } from 'react'
import AutocompleteSearchSimpleObject from '../../../containers/ui/AutocompleteSearchSimpleObject'
import TextChip from '../../ui/TextChip'

class AutocompleteSimpleObjectInput extends Component {
  handleOnItemSelected = item => {
    const { name, onChange } = this.props
    onChange(name, item)
  }

  render() {
    const { multiple = false, ...props } = this.props
    return (
      <div key={'AutocompleteSimpleObjectInput'}>
        {multiple ? (
          <AutocompleteMultipleValues {...props} onChange={this.handleOnItemSelected} />
        ) : (
          <AutocompleteSingleValue {...props} onChange={this.handleOnItemSelected} />
        )}
      </div>
    )
  }
}

const AutocompleteSingleValue = ({
  value,
  name,
  model,
  placeholder,
  onChange,
  creatable,
  displayAttribute,
  customFilter,
  renderInputComponent,
}) => {
  if (value) {
    let textValue = ''
    if (value.title) {
      if (value.status) {
        let statusText = value.status == 1 ? 'Borrador' : 'Publicado'
        textValue = placeholder + ': ' + value.title + ' (' + statusText + ')'
      } else {
        textValue = placeholder + ': ' + value.title
      }
    } else {
      textValue = displayAttribute ? value[displayAttribute] : value.name
    }
    return <TextChip text={textValue} onDeleteChip={() => onChange(null)} />
  }

  return (
    <AutocompleteSearchSimpleObject
      model={model}
      keyName={'autocomplete-simple-object-input-' + name}
      onItemSelected={onChange}
      value={value}
      placeholder={placeholder}
      creatable={creatable}
      displayAttribute={displayAttribute}
      customFilter={customFilter}
      renderInputComponent={renderInputComponent}
    />
  )
}

const AutocompleteMultipleValues = ({
  value,
  name,
  model,
  placeholder,
  onChange,
  creatable,
  autoFocus,
  displayAttribute,
  customFilter,
  renderInputComponent,
    reverse = false
}) => (
  <div style={{display:'flex', flexDirection: 'column', flexFlow: reverse ? 'column-reverse' : 'column'}}>
    <div>
    {value &&
      value.length > 0 &&
      value.map(textValue => (
        <TextChip
          text={displayAttribute ? textValue[displayAttribute] : textValue.name}
          key={textValue.id}
          onDeleteChip={() => onChange(value.filter(item => item.id !== textValue.id))}
        />
      ))}
    </div>
    <AutocompleteSearchSimpleObject
      model={model}
      keyName={'autocomplete-simple-object-input-' + name}
      onItemSelected={selectedValue =>
        value ? onChange([...value, selectedValue]) : onChange([selectedValue])
      }
      value={value}
      placeholder={placeholder}
      multiple={true}
      creatable={creatable}
      autoFocus={autoFocus}
      displayAttribute={displayAttribute}
      customFilter={customFilter}
      renderInputComponent={renderInputComponent}
    />
  </div>
)

export default AutocompleteSimpleObjectInput
