import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import CourseFormBasicDataStepTwo from './CourseFormBasicDataStepTwo'
import CourseFormBasicDataStepOne from './CourseFormBasicDataStepOne'
import classNames from 'classnames'

const steps = ['Datos Básicos', 'Usuarios']

const styles = {
  button: {
    margin: '1em',
    padding: '1em 2em',
    borderRadius: '5px',
    border: '1px solid #575454',
    width: '120px',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerTextInput: {
    maxWidth: '60%',
  },
  // Form classes
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  footerButtons: {
    paddingTop: '1em',
    paddingRight: '2em',
  },
  formContainer: {
    boxShadow: 'none',
  },
  input: {
    margin: '2em 4em',
    boxSizing: 'border-box',
  },
  switchInput: {
    marginLeft: '4em',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    width: '100%',
    padding: '1em 8em',
    boxSizing: 'border-box',
    background: '#454545',
  },
  titleHeader: {
    color: '#fff',
    fontSize: 20,
  },
  root: {
    width: '100%',
    margin: '50px 0 20px 0',
  },
  sectionBlock: {
    root: {
      color: '#FF00FF11',
    },
  },
}

const CourseFormBasicData = ({
  classes,
  form,
  results,
  onSubmit,
  onAttributeChange,
  title,
  loading,
  onGoBack,
}) => {
  const [step, setStep] = useState(1)

  const handleBackStep = () => {
    if (step === 1) {
      setStep(0)
      onGoBack()
      return
    }

    if (step === 2) {
      setStep(1)
      return
    }
  }

  const handleNextStep = async () => {
    if (step === 1 && !form.hidden) {
      onSubmit()
    } else if (step === 2 && form.hidden) {
      onSubmit()
    } else setStep(prev => prev + 1)
  }

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={10}>
        <Stepper activeStep={step}>
          {steps.map(label => {
            return (
              <Step key={label} className={classes.sectionBlock}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        {step === 1 && (
          <CourseFormBasicDataStepOne
            form={form}
            results={results}
            onSubmit={onSubmit}
            onAttributeChange={onAttributeChange}
            title={title}
            loading={loading}
            onGoBack={onGoBack}
          />
        )}
        {step === 2 && (
          <CourseFormBasicDataStepTwo onAttributeChange={onAttributeChange} form={form} />
        )}
        <Grid container className={classNames(classes.buttonsContainer, classes.footerButtons)}>
          {step === 2 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleBackStep()}
              className={classes.button}
            >
              Atrás
            </Button>
          )}
          <Button
            disabled={!results.isValid || (step === 2 && !form?.users?.length)}
            variant="contained"
            color="primary"
            onClick={() => handleNextStep()}
            className={classes.button}
          >
            {step === 1 && form.hidden ? 'Continuar' : 'Guardar'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(CourseFormBasicData)
