import React, { useEffect, useRef } from 'react'
import Confetti from 'react-confetti'
import QuizTriviaInfoField from './QuizTriviaInfoField'
import { Button, Typography } from '@material-ui/core'
import ApprovalStatus from './ApprovalStatus'
import { COLOR_KEYS } from '../../../../config/constants'
import congratsSound from '../../../../assets/sounds/congrats.mp3'
import NoDisplaySolutionMsg from './noDisplaySolutionsMsg'

const ResultsView = ({ activityState, retry, showSolution, confettiColors }) => {
  const sound = useRef()
  useEffect(() => {
    if (sound?.current) {
      sound.current.volume = 0.2
    }
  }, [])
  return (
    <>
      {activityState.results.approved && (
        <>
          <audio ref={sound} src={congratsSound} autoPlay />
          <Confetti recycle={false} tweenDuration={1000} colors={confettiColors} />
        </>
      )}
      <div
        className="col"
        style={{
          gap: '0.5rem',
          width: '100%',
          marginBottom: '4rem',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <QuizTriviaInfoField
          label="Puntaje mínimo de aprobación"
          value={`${activityState.passing_score ?? 0} de 100`}
        />
        {!!activityState.display_solution && (
          <>
            <QuizTriviaInfoField
              label="Respuestas correctas"
              value={`${activityState.results.correct_questions?.length ?? 0} de ${
                activityState.quiz?.length ?? 0
              }`}
            />
            <QuizTriviaInfoField
              style={{
                color: ((activityState.results.score_obtained >= activityState.passing_score) || activityState.results.approved) ? COLOR_KEYS.CORRECT : COLOR_KEYS.INCORRECT,
              }}
              label="Puntaje obtenido"
              value={activityState.results.score_obtained ?? 0}
            />
          </>
        )}
        <ApprovalStatus activityState={activityState} />
      </div>
      <div className="col" style={{ gap: '1rem' }}>
        {activityState.results.approved ? (
          <>
            {activityState.display_solution ? (
              <Button variant="contained" onClick={showSolution}>
                Mostrar solución
              </Button>
            ) : (
              <NoDisplaySolutionMsg />
            )}
          </>
        ) : !activityState.attemps || activityState.attempts_count < activityState.attemps ? (
          <Button variant="contained" onClick={retry}>
            Reintentar
          </Button>
        ) : (
          <>
            <Typography style={{ textAlign: 'center' }}>Reintentos agotados</Typography>
            {activityState.display_solution ? (
              <Button variant="contained" onClick={showSolution}>
                Mostrar solución
              </Button>
            ) : (
              <NoDisplaySolutionMsg />
            )}
          </>
        )}
      </div>
      <div />
    </>
  )
}

export default ResultsView
