import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  AdminItemViewType
} from '../../../config/constants'

const KEY_NAME = 'reports/userDataScorm'

class AdminReportsCrossCollaboration extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  getColumns = () => {
    let columns = [
      { title: 'Legajo', source: 'user.record' },
      { title: 'Nombre', source: 'user.first_name' },
      { title: 'Apellido', source: 'user.last_name' },
      { title: 'Gerencia', source: 'management' },
      { title: 'Voto a', source: 'name' },
      { title: 'Puntaje', source: 'punctuation' },
      {
        title: 'Fecha',
        source: 'created_at',
        type: AdminItemViewType.Date,
      },
    ]

    return columns
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'u.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
      },
      {
        type: EditInputType.Text,
        name: 'UserDataScorm.name',
        placeholder: 'Voto a',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Text,
        name: 'UserDataScorm.management',
        placeholder: 'Gerencia',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Date,
        name: 'UserDataScorm.createdAtFrom',
        placeholder: 'Fecha Desde',
        format: FiltersFormat.MayorIgual,
        queryName: 'UserDataScorm.createdAt',
      },
      {
        type: EditInputType.Date,
        name: 'UserDataScorm.createdAtTo',
        placeholder: 'Fecha Hasta',
        format: FiltersFormat.MenorIgual,
        queryName: 'UserDataScorm.createdAt',
      },
    ]

    return response
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'CROSS COLLABORATION'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsCrossCollaboration.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer

  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
    { getResources, downloadReport },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsCrossCollaboration)
