import React, { Component } from 'react'
import AdminContentWrapper from '../../components/admin/AdminContentWrapper'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { appLoadToken } from '../../actions/app'
import { withRouter } from 'react-router-dom'

class AdminContentWrapperContainer extends Component {
  render() {
    const { children, mobileOpen, wrapperClass } = this.props
    return (
      <AdminContentWrapper wrapperClass={wrapperClass} menuOpen={mobileOpen}>
        {children}
      </AdminContentWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.menuDrawerReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ appLoadToken }, dispatch)
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminContentWrapperContainer),
)
