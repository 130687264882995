import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import Header from './HeaderHome'
import GridExplorerPlansProgress from './GridExplorerPlansProgress'
import Loading from '../ui/Loading'

const stylesCanalItem = {
  main: {
    width: 249.3,
    height: 68.4,
    paddingTop: 1.5,
    paddingBottom: 1.5,
  },
  button: {
    width: '100%',
    height: '68.4px',
    display: 'flex',
    //justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '0 !important',
    transition: 'transform 300ms linear',
    '&:hover': {
      zIndex: 10,
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  buttonBig: {
    width: '100%',
    height: '68.4px',
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '0 !important',
    transition: 'transform 300ms linear',
    zIndex: 10,
    cursor: 'pointer',
    transform: 'scale(1.2)',
  },

  title: {
    // paddingLeft: 15,
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    color: '#ffffff',
  },
  icon: {
    width: 30,
    height: 30,
    color: '#fff',
  },
}

const CanalItem = withStyles(stylesCanalItem)(
  ({ classes, title, backgroundColor, defaultColor, onClick, Filtrar, Id, Ultimo }) => {
    const verificarActivos = () => {
      let buttonActivo = document.getElementsByClassName(classes.buttonBig)
      if (buttonActivo.length > 0) {
        buttonActivo.forEach(element => {
          element.classList.replace(classes.buttonBig, classes.button)
        })
      }
    }

    const handleClick = () => {
      setClassButton(classes.buttonBig)
      if (Id === Ultimo) {
        Filtrar()
        onClick(defaultColor, title, 0)
        setClassButton(classes.button)
        setIsShown(false)
      } else {
        verificarActivos()
        if (isShown) {
          document.getElementById(Id).classList.replace(classes.button, classes.buttonBig)
        }
        onClick(backgroundColor, title, Id)
        setClassButton(classes.buttonBig)
        Filtrar(Id)
        setClassButton(classes.buttonBig)
        setIsShown(true)
      }
    }

    const [isShown, setIsShown] = useState(false)
    const [classButton, setClassButton] = useState(classes.button)
    return (
      <div className={classes.main} style={{ marginRight: '8px', marginBottom: '8px' }}>
        <Button
          role="link"
          className={classButton}
          id={Id}
          style={{ backgroundColor }}
          onClick={handleClick}
          tabIndex={0}
        >
          <Typography className={classes.title}>{title}</Typography>
        </Button>
      </div>
    )
  },
)

CanalItem.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.any.isRequired,
  Id: PropTypes.any.isRequired,
  Ultimo: PropTypes.any.isRequired,
  key: PropTypes.any.isRequired,
  Filtrar: PropTypes.any.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  lastPosition: PropTypes.bool.isRequired,
}

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      display: 'flex',
      justifyContent: 'center',
    },
    content: {
      maxWidth: '1034px',
      display: 'flex',
      flexDirection: 'column',
    },
    choices: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      //minHeight: 115,
      marginTop: 45,
      marginBottom: 35,
      justifyContent: 'center',
      [mediaLg]: {
        margin: '20px 0 !important',
      },
    },
  }
}

const ExplorePlans = ({
  classes,
  allCareerPlans,
  allChannels,
  onClickNavigateAllCareerPlans,
  loadChannels,
  filtrarCarrerPlans,
}) => {
  useEffect(() => {
    loadChannels()
    filtrarCarrerPlans()
  }, [loadChannels, filtrarCarrerPlans])

  const [filtering, setFiltering] = useState(false)
  const [bgButtonActive, setBgButtonActive] = useState('#ffffff')
  const defaultColor = '#ffffff'
  const [ultimo, setUltimo] = useState(0)

  const handleCanalActive = (backgroundColor, title, id) => {
    setFiltering(true)
    setUltimo(id)
    setBgButtonActive(backgroundColor)
    callingApi()
  }

  const callingApi = () => {
    setTimeout(function () {
      setFiltering(false)
    }, 1200)
  }

  return (
    <div className={classes.root}>
      <Header title="Explorá nuevas rutas" />
      <div className={classes.body}>
        <main id={'main'} tabIndex={-1} className={classes.content}>
          <section className={classes.choices}>
            {allChannels &&
              allChannels.map(({ id, description, logo, color }, position, array) => {
                const lastPosition = array.length - 1
                return (
                  <CanalItem
                    tabIndex={0}
                    title={description}
                    backgroundColor={color}
                    Filtrar={filtrarCarrerPlans}
                    Id={id}
                    Ultimo={ultimo}
                    defaultColor={defaultColor}
                    lastPosition={lastPosition === position}
                    key={`${description}${position}`}
                    onClick={handleCanalActive}
                  />
                )
              })}
          </section>

          {!filtering && allCareerPlans && allCareerPlans.length > 0 && (
            <GridExplorerPlansProgress
              plans={allCareerPlans}
              bgButtonActive={bgButtonActive}
              onClickNavigateAllCareerPlans={onClickNavigateAllCareerPlans}
            />
          )}

          {filtering && <Loading></Loading>}
        </main>
      </div>
    </div>
  )
}

export default withStyles(styles)(ExplorePlans);
