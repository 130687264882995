import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResource } from '../../../actions/admin';
import AdminResourceView from '../../../components/admin/AdminResourceView';
import { goTo } from '../../../actions/navigator';
import { ADMIN_USERS } from '../../../config/routes';
import { AdminItemViewType } from '../../../config/constants';
import { isOpenBankUser } from '../../../utils/AdminHelper';

class AdminUsersViewContainer extends Component {
  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  getTemplate = () => {
    const { item } = this.props
    const isOpenBank = (item && isOpenBankUser(item))
    
    return {
      keys: [
        { title: 'Usuario', source: isOpenBank ? 'record' : 'username' },
        { title: 'Nombre', source: 'first_name' },
        { title: 'Apellido', source: 'last_name' },
        { title: 'Email', source: 'email' },
        {
          title: 'Roles',
          source: 'roles_as_string',
          type: AdminItemViewType.Text,
        },
        { title: 'Código del Puesto', source: 'job_position_code' },
        { title: 'Nombre del Puesto', source: 'job_position_name' },
        {
          title: isOpenBank ? 'Código Area' : 'Código del Centro de Costos',
          source: 'org_unity_identifier',
        },
        { title: isOpenBank ? 'Area' : 'Centro de Costos', source: 'org_unity_name' },
      ],
    };
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_USERS);
  };

  componentDidMount = () => {
    this.onLoadData();
  };

  render() {
    const { item, loading } = this.props;
    return (
      <AdminResourceView
        title={'USUARIO'}
        item={item}
        loading={loading}
        attributes={this.getTemplate()}
        onGoBack={() => this.onGoBack()}
        onEdit={null}
      />
    );
  }
}

AdminUsersViewContainer.defaultProps = {
  type: 'users',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName],
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResource, goTo }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersViewContainer);
