/* eslint-disable no-case-declarations */
import {
  EXTERNAL_COURSE_HOME_READ_END, EXTERNAL_COURSE_HOME_READ_FAILED, EXTERNAL_COURSE_HOME_READ_START,
} from '../../actions'

const initialState = {
  key: '',
  error: null,
  loading: false,
  items: [],
}

const initialStates = {
  myPending: {...initialState, key: 'myPending'},
  nextDays:  {...initialState, key: 'nextDays'},
};

function getStateFor(state, key) {
  if (state[key] !== undefined) {
    return state[key];
  }
  state[key] = { ...initialState, key };
  return state[key];
}

export default (state = initialStates, action) => {
  const key = action.key || state.key || '';
  const currentState = getStateFor(state, key);
  switch (action.type) {
    case EXTERNAL_COURSE_HOME_READ_END:
      const { items } = action.payload
      state[key] = {
        ...currentState,
        loading: false,
        items,
        error: null,
      }
      break;
    case EXTERNAL_COURSE_HOME_READ_START:
      state[key] = {
        ...currentState,
        items: [],
        error: null,
        loading: true,
      }
      break;
    case EXTERNAL_COURSE_HOME_READ_FAILED:
      const { message } = action.payload
      state[key] = {
        ...currentState,
        items: [],
        error: message,
        loading: false,
      }
      break;
    default:
      state[key] = { ...currentState, error: null }
  }
  return state;
}
