import React from 'react'
import Header from './HeaderPlaylist'
import Content from './ContentPlaylist'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: '100vw',
  },
})

const Playlist = ({
  classes,
  playlist,
  onShowCourse,
  onDeletePlaylist,
  onFollowUnfollow,
  onUnlinkCourse,
  silentReloadPlaylist,
}) => {
  return (
    playlist && (
      <div className={classes.root}>
        <Header
          playlist={playlist}
          onDeletePlaylist={onDeletePlaylist}
          onFollowUnfollow={onFollowUnfollow}
          silentReloadPlaylist={silentReloadPlaylist}
        />
        <Content onShowCourse={onShowCourse} playlist={playlist} onUnlinkCourse={onUnlinkCourse} />
      </div>
    )
  )
}
export default withStyles(styles)(Playlist)
