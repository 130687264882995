import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  AdminItemViewType,
  CourseProgress,
  Audiences,
  Modalities,
  SnackbarType,
  UserCommissionStatusDescription,
  UserCommissionAssistanceDescription,
} from '../../../config/constants'
import { isOpenBankUser } from '../../../utils/AdminHelper'

const KEY_NAME = 'reports/external_courses'

class AdminReportsExternalCoursesContainer extends Component {

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props
    getResources(type, keyName, query, page, size, undefined);
  }

  onRenderFacilitador = item => {
    let facilitator = item.facilitators.map(c => {
      return c.name + ' (' + c.type + ')'
    })
    return facilitator.join(', ')
  }

  onRenderFacilitadorType = item => {
    let facilitator = item.facilitators.map(c => c.type)
    return facilitator.join(', ')
  }

  onRenderTextMotivo = item => {
    if (item.desertion === false) {
      return 'N/A'
    } else {
      return item.desertion
    }
  }

  getColumns = () => {
    const { me } = this.props

    let columns = [
      { title: 'Nombre', source: 'first_name' },
      { title: 'Apellido', source: 'last_name' },
      { title: 'Usuario', source: 'username' },
      { title: 'Email', source: 'email' },
      { title: 'Taller', source: 'external_course' },
      { title: 'Modalidad', source: 'modality' },
      { title: 'Fecha de Inicio', source: 'date_start' },
      { title: 'Duración total (min)', source: 'duration' },
      { title: 'Institución', source: 'place' },
      { title: 'Plataforma', source: 'platform' },
      {
        title: 'Facilitadores',
        source: 'facilitators',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderFacilitador,
      },
      { title: 'Audiencia', source: 'audience' },
      { title: 'Estado', source: 'status' },
      {
        title: 'Cantidad de clases',
        source: 'commission_dates_count',
        type: AdminItemViewType.Number,
      },
      {
        title: 'Cantidad de requeridas',
        source: 'assistance_required',
        type: AdminItemViewType.Number,
      },
    ]
    return columns
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.PublicExternalCourses,
        name: 'externalCourse.name',
        placeholder: 'Nombre del Taller',
        format: FiltersFormat.In,
        displayAttribute: 'name',
        multiple: true,
      },
      {
        type: EditInputType.Select,
        options: Modalities,
        name: 'commission.modality',
        placeholder: 'Modalidad',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'name',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.CommissionPlaces,
        displayAttribute: 'name_type',
        name: 'commission.site',
        placeholder: 'Lugar',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Date,
        name: 'commission.startDateFrom',
        placeholder: 'Fecha Desde',
        format: FiltersFormat.MayorIgual,
        helpText: 'Fecha Desde',
        queryName: 'commissionDates.startDate',
      },
      {
        type: EditInputType.Date,
        name: 'commission.startDateTo',
        placeholder: 'Fecha Hasta',
        format: FiltersFormat.MenorIgual,
        helpText: 'Fecha Hasta',
        queryName: 'commissionDates.startDate',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
      },
      {
        type: EditInputType.Select,
        displayAttribute: 'name',
        name: 'commission.audience',
        placeholder: 'Audiencia',
        format: FiltersFormat.Plain,
        options: Audiences,
      },
      {
        type: EditInputType.Select,
        name: 'userCommission.status',
        placeholder: 'Estado',
        format: FiltersFormat.PlainOR,
        options: UserCommissionStatusDescription,
      },
      {
        type: EditInputType.Select,
        name: 'userCommission.assistance',
        placeholder: 'Asistencia',
        format: FiltersFormat.PlainOR,
        options: UserCommissionAssistanceDescription,
      },
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME).then(() => {
      addSnackbar(
        'Esta descarga pudo ser limitada debido a la cantidad de registros',
        SnackbarType.Info,
      )
    })
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE USUARIOS POR TALLER'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsExternalCoursesContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer
  if (paginatorState && paginatorState.user_courses && paginatorState.user_courses.items) {
    paginatorState.user_courses.items.map(course =>
      course.completed_at && course.completed_at.length
        ? (course.completed = CourseProgress.true)
        : (course.completed = CourseProgress.false),
    )
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsExternalCoursesContainer)
