import React from 'react';
import { SketchPicker } from 'react-color';
import CloseButton from '@material-ui/icons/Close';

export default class ColorInput extends React.Component {
  state = {
    open: false,
  };

  handleOnChange = (color) => {
    const { name, onChange } = this.props;
    onChange(name, color.hex);
  };

  render() {
    const { value } = this.props;
    const { open } = this.state;
    return !open ? (
      <div
        style={{
          backgroundColor: value,
          width: 32,
          height: 32,
          borderStyle: 'solid',
          borderColor: 'black',
          borderRadius: 3,
          borderWidth: 2,
        }}
        onClick={() => this.setState({ open: true })}
      />
    ) : (
      <div style={{ position: 'relative', width: 200 }}>
        <CloseButton
          style={{
            position: 'absolute',
            top: -22,
            right: -44,
            zIndex: 1000,
            cursor: 'pointer',
          }}
          onClick={() => this.setState({ open: false })}
        />
        <SketchPicker color={value} onChangeComplete={this.handleOnChange} />
      </div>
    );
  }
}
