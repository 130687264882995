import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { getResource, importUsersFromCSV } from '../../../actions/admin'
import { appLoadToken } from './../../../actions/app'
import { loadUsersForLot } from './../../../actions/lotAdmin'
import Loading from '../../../components/ui/Loading'
import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'
import { Grid, TableRow, TableCell, Checkbox, Button } from '@material-ui/core'
import { isEmpty, uniqBy, differenceBy } from 'lodash'
import FileInput from '../inputs/FileInput'
import { getSelectedUsers } from '../../../actions/user'

const styles = theme => ({
  root: {
    padding: '20px 0',
  },
  opcionalSelect: {},
  button: {
    marginRight: theme.spacing(1),
  },
})

class StepUserAssignationComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    page: 0,
    count: 1,
    rowsPerPage: 20,
    data: [],
    columns: [],
    isLoading: false,
    usersSelected: [],
    searchText: '',
    typing: false,
    typingTimeout: 0,
    firstLoad: true,
    showUserImport: false,
    userResponse: [],
  }

  componentDidMount = nextProps => {
    const { course, getSelectedUsers } = this.props

    this.onLoadData(this.state.page)

    if (course.item?.users.length) {
      getSelectedUsers(course.item?.users.map(({ id }) => id))
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.state.firstLoad && this.state.usersSelected !== nextProps.usersSelected) {
      this.setState({
        usersSelected: nextProps.usersSelected,
        firstLoad: false,
      })
    }

    if (this.props.users !== nextProps.users) {
      const { users, prevSelectedUsers } = nextProps
      const { page, userResponse, searchText } = this.state

      const data = [...(prevSelectedUsers?.items || []), ...(users?.data || [])]

      this.setState({
        data:
          page === 0 && !searchText
            ? uniqBy([...userResponse, ...data], 'id')
            : differenceBy(users?.data || [], prevSelectedUsers?.items || [], 'id'),
        columns: users.columns || [],
      })

      if (users.metadata) {
        this.setState({
          isLoading: false,
          count: parseInt(users.metadata.pagination.total) || 1,
        })
      }
    }
  }

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader()
        reader.onload = function () {
          resolve(reader.result)
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.readAsDataURL(file)
      } catch (e) {
        console.log(e)
      }
    })
  }

  handleShowImport = () => {
    this.setState({
      showUserImport: true,
    })
  }

  onImportUsers = async (_, file) => {
    const reader = await this.getBase64(file[0])
    const response = await this.props.importUsersFromCSV({ file: [reader] })
    const userResponse = JSON.parse(response.users)
    const newUsers = userResponse.map(el => ({ id: el.id }))
    const usersSelected = uniqBy([...this.state.usersSelected, ...newUsers], 'id')

    this.setState({
      usersSelected,
      userResponse: uniqBy([...userResponse, ...this.state.userResponse], 'id'),
      data: uniqBy([...userResponse, ...this.state.data], 'id'),
    })
    this.props.handleChangeLotUsers(usersSelected)
  }

  //FUNCIONES ANTERIORES
  handleClick = user => {
    let hasUser = this.isRowSelected(user.id)
    var newUsersSelected = this.state.usersSelected
    if (hasUser) {
      newUsersSelected = newUsersSelected.filter(el => el.id !== user.id)
    } else {
      newUsersSelected.push(user)
    }
    this.setState({ usersSelected: newUsersSelected })
    this.props.handleChangeLotUsers(newUsersSelected)
  }

  disabledBtnPublish = () => {
    return !isEmpty(this.state.usersSelected)
  }

  //FUNCIONES NUEVAS
  onLoadData = async (page = 0, query = false) => {
    const { appLoadToken, loadUsersForLot, lotId } = this.props

    const { rowsPerPage } = this.state

    try {
      this.setState({ isLoading: true })
      var filters = lotId ? `lot=${lotId}&` : ''
      query = query || this.state.searchText
      appLoadToken().then(() => {
        if (query)
          filters += `filters[OR][user.id][LIKE]=${query}&filters[OR][user.firstName][LIKE]=${query}&filters[OR][user.lastName][LIKE]=${query}&filters[OR][user.username][LIKE]=${query}&filters[OR][user.email][LIKE]=${query}&`

        filters += `pagination[size]=${rowsPerPage}&pagination[page]=${page + 1}`

        loadUsersForLot(encodeURI(filters))
      })
    } catch (error) {
      console.error(error)
    }
  }

  onTextSearchChange = searchQuery => {
    const self = this

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }

    self.setState({
      searchText: searchQuery,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.onLoadData()
      }, 850),
    })
  }

  changePage = page => {
    this.onLoadData(page)
    this.setState({ page: page })
  }

  isRowSelected = id => {
    if (this.state.usersSelected.length == 0) return false
    return !!this.state.usersSelected.find(element => element.id === id)
  }

  render() {
    const { classes } = this.props
    const { data, count, rowsPerPage } = this.state

    const customRowRender = row => {
      let [id, username, firstName, lastName, email] = row
      let isSelected = this.isRowSelected(id)
      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isSelected}
          tabIndex={-1}
          key={'user' + id}
          selected={isSelected}
        >
          <TableCell padding="checkbox" onClick={() => this.handleClick({ id })}>
            <Checkbox checked={isSelected} color={'primary'} />
          </TableCell>
          <TableCell align="left">{id}</TableCell>
          <TableCell align="left">{username}</TableCell>
          <TableCell align="left">{firstName}</TableCell>
          <TableCell align="left">{lastName}</TableCell>
          <TableCell align="left">{email}</TableCell>
        </TableRow>
      )
    }

    const options = () => ({
      download: false,
      print: false,
      viewColumns: false,
      sortOrder: {
        name: 'id',
        direction: 'asc',
      },
      sort: true,
      filter: false,
      fixedHeader: true,
      selectableRowsHeader: false,
      selectToolbarPlacement: 'none',
      searchText: this.state.searchText,
      serverSide: true,
      count: count,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [],
      textLabels: {
        body: {
          noMatch: this.state.isLoading ? <Loading dark={true} /> : 'No se encontraron resultados',
        },
      },
      onTableChange: (action, tableState) => {
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page)
            break
          case 'sort':
            //this.sort(tableState.page);
            break
          default:
          //console.log('DEFAULT: table change: ' + action);
        }
      },
      onSearchChange: (searchQuery, currentRow, columns) => {
        this.onTextSearchChange(searchQuery)
      },
      onSearchClose: () => {
        this.setState({ searchText: false })
      },
      customSort: (sortData, colIndex, order) => {
        return sortData.sort((a, b) => {
          var dir = order === 'asc' ? 1 : -1
          var isASelected = data.find(elem => {
            return this.state.usersSelected.find(element => element.id === a.data[0]) ? false : true
          })
          var isBSelected = data.find(elem => {
            return this.state.usersSelected.find(element => element.id === b.data[0]) ? false : true
          })
          if (isASelected && !isBSelected) return 1 * dir
          if (!isASelected && isBSelected) return -1 * dir
          return (a[colIndex] > b[colIndex] ? -1 : 1) * dir
        })
      },
      customRowRender,
    })

    return (
      <>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <MUIDataTable
              title={'Usuarios asociados'}
              data={data}
              columns={this.state.columns}
              options={options()}
            />
            {this.state.showUserImport && (
              <FileInput
                tabIndex={0}
                name="file"
                accept=".csv"
                onChange={this.onImportUsers}
                style={{ marginTop: '3%' }}
              />
            )}
          </Grid>
        </Grid>
        <Grid className={classes.wrapperBoton}>
          <Button onClick={this.props.handleBack} className={classes.button}>
            Atrás
          </Button>

          {this.props.isPublished && (
            <Button
              disabled={!this.disabledBtnPublish()}
              onClick={this.props.handlePublish}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Guardar cambios
            </Button>
          )}
          {!this.props.isPublished && (
            <>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={this.props.handleSaveUserAssignations}
              >
                Guardar Borrador
              </Button>

              <Button
                disabled={!this.disabledBtnPublish()}
                onClick={this.props.handlePublish}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                Publicar
              </Button>
            </>
          )}
          <Button
            disabled={this.state.showUserImport}
            variant="contained"
            color="primary"
            onClick={() => this.handleShowImport()}
            className={classes.button}
          >
            {'Importar usuarios'}
          </Button>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource
  return {
    users: state.lotAdminReducer.users,
    course: states['admin-lot-edit'],
    prevSelectedUsers: state.selectedUsersReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { loadUsersForLot, appLoadToken, getResource, importUsersFromCSV, getSelectedUsers },
    dispatch,
  )
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(StepUserAssignationComponent)),
)
