import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { new_dialog_styles } from '../../../themes/new_feedback_dialog_style';

const styles = theme => {
  return {
    ...new_dialog_styles(theme),
    dialogHeader: {
      color: '#000000',
      backgroundColor: '#ffffff',
      paddingLeft: 32,
      paddingRight: 32,
      position: 'relative',
      zIndex: 10
    },

    dataBox: {
      marginRight: 16,
      marginLeft: 16,
      wordBreak: 'break-word'
    },

    actionRight: {
      marginTop: 8,
      textAlign: 'right'
    },
    title: {
      fontWeight: 700,
      paddingTop: 32,
    },
    headerContent: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center'
    }
  };

}

class JobsErrorsDialog extends Component {


  handleClose = () => {
    const { onDismiss } = this.props;
    onDismiss();
  };


  renderHeader = () => {
    const { classes } = this.props;
    return (<div className={classes.dialogHeader}>
      <div className={classes.headerContent}>
        <Typography className={classes.title}>{"DETALLE DE ERRORES"}</Typography>
      </div>
      <IconButton className={classes.headerIconRight} onClick={this.handleClose}>
        <CloseIcon className={classes.headerIcon} />
      </IconButton>
    </div>);
  };


  renderDialog = () => {
    const { errors, classes } = this.props;

    return (
      <div>
        {this.renderHeader()}
        <div className={classes.stepperContent}>
          <DialogContent className={classes.content}>
            <Typography className={classes.dataBox}>
              {errors.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </DialogContent>
        </div>
      </div>
    );
  };



  render() {
    const { classes, open } = this.props;
    return (
      <Dialog fullWidth={true} className={classes.root} aria-labelledby="form-dialog-title" open={open}>
        <div>
          {(open) ? this.renderDialog() : null}
        </div>
      </Dialog>)
  }
}

export default withStyles(styles)(JobsErrorsDialog);
