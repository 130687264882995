import {
 SLIDES_READ_END, SLIDES_READ_FAILED, SLIDES_READ_START,
} from './index';
import api from '../api/api';

export function loadSlides() {
  return async (dispatch) => {
    dispatch({ type: SLIDES_READ_START });
    try {
      const response = await api.withDispatch(dispatch).Slides.all();
      dispatch({
        type: SLIDES_READ_END,
        payload: { items: response.data },
      });
    } catch (e) {
      dispatch({ type: SLIDES_READ_FAILED, payload: { message: e } });
    }
  };
}
