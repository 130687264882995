import React, { useEffect, useCallback } from 'react'
import Home from '../../components/home/Home'
import { appLoadToken } from '../../actions/app'
import {
  loadFeaturedCategories,
  loadRequired,
  loadTopTen,
  loadVariablePrice,
  deleteSharedCourse,
  loadObjectiveDplusI,
} from '../../actions/courses'
import {
  loadMyFinished,
  loadMyInProgress,
  loadMySharedCourses,
  loadMyHidden,
} from '../../actions/user'
import {
  loadMyPendingExternalCourses,
  loadNextDaysExternalCourses,
} from '../../actions/externalCourses'
import { loadSlides } from '../../actions/slides'
import { withRouter } from 'react-router-dom'
import { goTo } from '../../actions/navigator'
import { addSnackbar } from '../../actions/snackbar'
import { COURSES_VIEW, EXTERNAL_COURSES_VIEW, router } from '../../config/routes'
import Loading from '../../components/ui/Loading'
import { CourseType, SnackbarType } from '../../config/constants'
import DocumentTitle from '../../components/ui/DocumentTitle'
import { useDispatch, useSelector } from 'react-redux'

const HomeContainer = ({ user }) => {
  const dispatch = useDispatch()

  const me = useSelector(state => state.userReducer)
  const courses = {
    require: useSelector(state => state.courses.required),
    required: useSelector(state => state.courses.required),
    topTen: useSelector(state => state.courses.topTen),
    variablePrice: useSelector(state => state.courses.variablePrice),
    objectiveDplusI: useSelector(state => state.courses.objectiveDplusI),
    finished: useSelector(state => state.me.finished),
    inProgress: useSelector(state => state.me.inProgress),
    shared: useSelector(state => state.me.shared),
    featuredCategories: useSelector(state => state.featuredCategoriesReducer),
    hidden: useSelector(state => state.me.hidden),
  }

  const externalCourses = useSelector(state => state.externalCoursesHomeReducer)
  const slides = useSelector(state => state.slidesReducer)

  const state = useSelector(state => state)

  useEffect(() => {
    dispatch(appLoadToken()).then(res => {
      if (res && user) {
        loadHome()
      }
    })
  }, [])

  const loadHome = useCallback(() => {
    dispatch(loadSlides())
    dispatch(loadTopTen())
    dispatch(loadVariablePrice())
    dispatch(loadRequired())
    dispatch(loadMyFinished())
    dispatch(loadMyInProgress())
    dispatch(loadMySharedCourses())
    dispatch(loadFeaturedCategories())
    dispatch(loadNextDaysExternalCourses())
    dispatch(loadMyPendingExternalCourses())
    dispatch(loadMyHidden())
    dispatch(loadObjectiveDplusI())
  }, [])

  const showCourse = course => {
    if (course.type === CourseType.ExternalCourse) {
      dispatch(goTo(router.getRoute(EXTERNAL_COURSES_VIEW, { id: course.id })))
    } else {
      dispatch(goTo(router.getRoute(COURSES_VIEW, { id: course.id })))
    }
  }

  const removeSharedCourse = async course => {
    try {
      await dispatch(deleteSharedCourse(course.id))
      dispatch(addSnackbar('Recomendación descartada', SnackbarType.Success))
      loadHome()
    } catch (err) { }
  }

  return me.loading ? (
    <Loading fullSize={true} />
  ) : (
    <>
      <DocumentTitle title={'Inicio | Academia'} />
      <Home
        onShowCourse={showCourse}
        onRemoveSharedCourse={removeSharedCourse}
        onGoTo={route => dispatch(goTo(route))}
        me={me.user}
        courses={courses}
        externalCourses={externalCourses}
        slides={slides}
      />
    </>
  )
}

export default withRouter((HomeContainer))
