import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { isNull } from 'lodash';
import config from '../../config';

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      height: '10%',
      backgroundSize: 'cover',
      backgroundImage: `url("${config.BASE_URL}img/ac_bg_home.png")`,
      minHeight: 400,
      zIndex: 1,
      [mediaLg]: {
        padding: 24,
      },
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      textAlign: 'center',
    },
    containerTitleBanner: {
      display: 'inline-flex',
      alignItems: 'center',
      marginTop: 50,
    },
    titleBanner: {
      fontFamily: 'PlaylistScript',
      fontSize: 50,
      fontWeight: 100,
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'center',
      color: '#ffffff',
      textShadowColor: 'rgba(0, 0, 0, 0.16)',
      textShadowOffset: {
        width: 0,
        height: 1.5,
      },
      textShadowRadius: '3px',
      [mediaLg]: {
        fontSize: 40,
      },
    },

    arrow: {
      height: 100,
    },

    bottonBanner: {
      marginTop: 16,
      borderRadius: '5px',
      shadowColor: 'rgba(0, 0, 0, 0.16)',
      shadowOffset: {
        width: 0,
        height: 1.5,
      },
      shadowRadius: '3px',
      shadowOpacity: '1px',
      borderColor: '#fff',
      color: '#fff',
    },
    gradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(135deg, #000000 0%, #000000 59%, #006c8a 78%, #00c4ff 93%, #00c4ff 100%)',
      opacity: 0.49,
      zIndex: -1,
    },
  };
};
// 'linear-gradient(135deg, #1a1400 0%, #000000 0%, #1a1400 63%, #332801 73%, #5c4700 80%, #8c6b00 86%, #c29800 91%, #ebb903 95%, #fdcb05 98%, #fdcb05 100%)',

class HeaderHome extends Component {
  render() {
    const { classes, title, labelButton, onClick } = this.props;
    return (
        <header className={classes.root}>
          <div className={classes.headerContent}>
            <div className={classes.containerTitleBanner}>
              <img
                alt="Flecha Izquierda"
                src={config.BASE_URL + 'img/arrow_pink_left.png'}
                className={classes.arrow}
                aria-hidden="true"
              />
              <Typography component={'h1'} className={classes.titleBanner}>{title}</Typography>
              <img
                alt="Flecha Derecha"
                src={config.BASE_URL + 'img/arrow_blue_right.png'}
                className={classes.arrow}
                aria-hidden="true"
              />
            </div>

            {!isNull(labelButton) && !isNull(onClick) && (
              <Button tabIndex={0}
                variant="outlined"
                className={classes.bottonBanner}
                onClick={onClick}
              >
                {labelButton}
              </Button>
            )}
          </div>
          <div className={classes.gradient} />
        </header>
    );
  }
}

HeaderHome.propTypes = {
  classes: Proptypes.object.isRequired,
  title: Proptypes.string.isRequired,
  labelButton: Proptypes.string,
  onClick: Proptypes.func,
};

HeaderHome.defaultProps = {
  labelButton: null,
  onClick: null,
};

export default withStyles(styles)(HeaderHome);
