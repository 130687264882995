/* eslint-disable no-case-declarations */
import {
 SLIDES_READ_END, SLIDES_READ_FAILED, SLIDES_READ_START,
} from '../actions';

const initialState = {
  error: null,
  loading: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SLIDES_READ_END:
      const { items } = action.payload;
      return {
        loading: false,
        items,
        error: null,
      };
    case SLIDES_READ_START:
      return {
        items: [],
        error: null,
        loading: true,
      };
    case SLIDES_READ_FAILED:
      const { message } = action.payload;
      return {
        items: [],
        error: message,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
