/* eslint-disable no-extra-boolean-cast */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ColorButton from '../../../components/ui/ColorButton'
import AdminContentWrapper from '../../../containers/admin/AdminContentWrapperContainer'
import {
  COLOR_KEYS,
  ONLY_NUMBER_LETTER_REGEX_KEY,
  SnackbarType,
  EditInputType,
  FiltersFormat,
  AdminItemViewType,
} from '../../../config/constants'
import { downloadReport, getCourseQuizActivities, getUserCoursesQuiz } from '../../../actions/admin'
import { Input, Typography, Tooltip } from '@material-ui/core'
import { KEY_KEYS } from '../../../constants'
import { itemEdit } from '../../../utils/AdminHelper'
import moment from 'moment'
import Loading from '../../../components/ui/Loading'
import useNotificate from '../../../hooks/useNotificate'
import AdminResourceTable from '../../../components/admin/AdminResourceTable'
import DownloadIcon from '@material-ui/icons/GetApp'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PaginatorComponent from '../../../components/ui/Paginator'
import SelectInput from '../../../components/admin/inputs/SelectInput'

const fileRegex = new RegExp(ONLY_NUMBER_LETTER_REGEX_KEY)
const headerMaxLength = 15
const { PRIMARY } = COLOR_KEYS
const keyName = 'reports/user_courses_quiz'

const btnStyles = {
  height: '3rem',
  padding: '1rem',
  justifyContent: 'center',
  alignItems: 'center',
}

const AdminReportsQuizTriviaActivities = ({ downloadReport }) => {
  const fileInput = useRef(null)
  const courseIdInput = useRef(null)
  const activityIdInput = useRef(null)
  const searchButton = useRef(null)
  const { notificate } = useNotificate()
  const [data, setData] = useState()
  const [activities, setActivities] = useState()
  const [dataIsLoading, setDataIsLoading] = useState()
  const [activitiesIsLoading, setActivitiesIsLoading] = useState()

  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [username, setUsername] = useState()
  const [courseId, setCourseId] = useState()
  const [activityId, setActivityId] = useState()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState()

  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true)

  const handleInputKeyPress = (e, inputRef, valueToCheck) => {
    const { key, target } = e
    if (key === KEY_KEYS.ENTER) {
      if (target.value) {
        if (valueToCheck) {
          searchButton.current.props.onClick()
        } else {
          inputRef.current.firstChild.focus()
        }
      }
    }
  }

  const getFilters = useCallback(
    toDownload => {
      const filters = {
        page: !toDownload ? `pagination%5Bpage%5D=${page ?? 1}` : undefined,
        size: !toDownload ? `pagination%5Bsize%5D=50` : undefined,
        activityId: activityId
          ? `filters%5Bactivity.id%5D%5BIN%5D%5B0%5D=${activityId}`
          : undefined,
        username: username ? `filters%5Buser.username%5D%5BIN%5D%5B0%5D=${username}` : undefined,
        dateFrom: dateFrom ? `filters%5Baq.createdAt%5D%5BMayorIgual%5D=${dateFrom}` : undefined,
        dateTo: dateTo ? `filters%5Baq.createdAt%5D%5BMenorIgual%5D=${dateTo}` : undefined,
      }

      return Object.values(filters)
        .filter(item => !!item)
        .join('&')
    },
    [username, activityId, dateFrom, dateTo, page],
  )

  const getCourseActivities = useCallback(async () => {
    setActivitiesIsLoading(true)
    try {
      const response = await getCourseQuizActivities(courseId)
      setActivities(response.data)
      if (!!response.data?.length) {
        notificate('Seleccione una actividad para solicitar el reporte')
      } else {
        notificate(
          'No hay actividades de tipo Formulario para el curso seleccionado',
          SnackbarType.Warning,
        )
      }
    } catch (error) {
      setActivities(undefined)
      setActivitiesIsLoading(false)
      const errorMessage = error.message ? error.message : 'Error al obtener datos'
      notificate(errorMessage, SnackbarType.Error)
    }
  }, [courseId])

  const getData = async () => {
    setDataIsLoading(true)
    try {
      const response = await getUserCoursesQuiz(getFilters())
      setData(response.data)
      setTotal(Math.ceil(response.metadata.pagination.total / response.metadata.pagination.size))
      if (!!response.data?.items?.length) {
        notificate('Datos obtenidos correctamente')
      } else {
        notificate('No hay datos para esta consulta', SnackbarType.Warning)
      }
    } catch (error) {
      setData(undefined)
      setDataIsLoading(false)
      const errorMessage = error.message ? error.message : 'Error al obtener datos'
      notificate(errorMessage, SnackbarType.Error)
    }
  }

  const handleApplayFilters = () => {
    page === 1 ? getData() : setPage(1)
  }

  useEffect(() => {
    if (activityId) {
      getData()
    }
  }, [page])

  useEffect(() => {
    if (data && dataIsLoading) {
      setDataIsLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (activities && activitiesIsLoading) {
      setActivitiesIsLoading(false)
    }
  }, [activities])

  useEffect(() => {
    setSearchButtonDisabled(!activityId)
  }, [activityId])

  useEffect(() => {
    setActivityId(undefined)
    setActivities(undefined)
  }, [courseId])


  useEffect(() => {
    setData(undefined)
  }, [activityId,dateFrom,dateTo,username])

  return (
    <AdminContentWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginBottom: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <h1 style={{ marginLeft: 16 }}>ACTIVIDADES DE TIPO FORMULARIO</h1>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '2rem',
            marginLeft: '2rem',
          }}
        >
          <div style={{ maxWidth: '7rem', flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography>ID de curso *</Typography>
            <Input
              ref={courseIdInput}
              placeholder="Requerido"
              name="courseId"
              value={courseId}
              style={{ minWidth: '5rem', flex: 1 }}
              inputProps={{
                style: { textAlign: 'center' },
              }}
              onChange={({ target }) => setCourseId(target.value)}
              onKeyPress={e => {
                const { key } = e
                if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(key)) {
                  e.preventDefault()
                }
                handleInputKeyPress(e, fileInput, username)
              }}
            />
          </div>
          <ColorButton
            color={PRIMARY}
            label="Solicitar"
            style={btnStyles}
            onClick={getCourseActivities}
          />
        </div>
      </div>
      {!!courseId && !!activities?.length && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: '2rem',
            backgroundColor: '#FFFFFF80',
            borderRadius: '0.25rem',
            boxShadow: '0.125rem 0.125rem 0.25rem #00000015',
            padding: '1.25rem 2rem 1.75rem 2rem',
            flexWrap: 'wrap',
            flexDirection: 'column',
            marginTop: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              gap: '2rem',
              flexWrap: 'wrap',
              flex: 1,
              width: '100%',
            }}
          >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography>Actividad (Formulario)</Typography>
              <SelectInput
                ref={activityIdInput}
                style={{ minWidth: '10rem', flex: 1 }}
                tabIndex={0}
                name="questions_type"
                value={activityId}
                placeholder="Requerido"
                onChange={(key, value) => {
                  setActivityId(value)
                }}
                options={activities}
              />
            </div>
            <div style={{ maxWidth: '6rem', display: 'flex', flexDirection: 'column' }}>
              <Typography>Legajo</Typography>
              <Input
                ref={fileInput}
                placeholder="Usuario"
                name="file"
                value={username}
                style={{ minWidth: '5rem' }}
                inputProps={{
                  style: { textAlign: 'center' },
                }}
                onChange={({ target }) => setUsername(target.value)}
                onKeyPress={e => {
                  const { key } = e
                  if (!fileRegex.test(key)) {
                    e.preventDefault()
                  }
                  handleInputKeyPress(e, activityIdInput, activityId)
                }}
              />
            </div>
            <div style={{ maxWidth: '8rem', flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography>Fecha desde</Typography>
              {itemEdit(
                {
                  type: EditInputType.Date,
                  name: 'dateFrom',
                  placeholder: 'Fecha',
                  max: moment(dateTo).endOf('d').format('YYYY-MM-DD'),
                  format: FiltersFormat.Fecha,
                },
                dateFrom,
                (name, value) => {
                  setDateFrom(value)
                },
              )}
            </div>
            <div style={{ maxWidth: '8rem', flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography>Fecha hasta</Typography>
              {itemEdit(
                {
                  type: EditInputType.Date,
                  name: 'dateTo',
                  placeholder: 'Fecha',
                  min: dateFrom ? moment(dateFrom).endOf('d').format('YYYY-MM-DD') : undefined,
                  max: moment().endOf('d').format('YYYY-MM-DD'),
                  format: FiltersFormat.Fecha,
                },
                dateTo,
                (name, value) => {
                  setDateTo(value)
                },
              )}
            </div>
          </div>
          <div className="row" style={{ gap: '0.5rem' }}>
            <ColorButton
              ref={searchButton}
              color={PRIMARY}
              label="Aplicar filtros"
              style={{ ...btnStyles, boxShadow: 'unset' }}
              onClick={handleApplayFilters}
              disabled={searchButtonDisabled}
            />
            <ColorButton
              ref={searchButton}
              color={PRIMARY}
              label="Descargar"
              extraView={
                <DownloadIcon
                  style={{ paddingTop: '0.25rem', transform: 'translateX(-0.25rem)' }}
                />
              }
              style={{ ...btnStyles, boxShadow: 'unset' }}
              onClick={() => {
                getData()
                downloadReport(getFilters(/*toDownload: */ true), keyName, 'report')
              }}
              disabled={searchButtonDisabled || !data?.items?.length}
            />
          </div>
        </div>
      )}
      {dataIsLoading ? (
        <Loading dark fullWidth />
      ) : (
        data &&
        !!total && (
          <>
            <AdminResourceTable
              key="resource-table"
              items={data.items}
              columns={[
                { title: 'Legajo', source: 'username' },
                { title: 'Nombre', source: 'first_name' },
                { title: 'Apellido', source: 'last_name' },
                { title: 'Curso', source: 'course' },
                { title: 'Actividad', source: 'activity_name' },
                { title: 'Cantidad de intentos', source: 'attemps' },
                { title: 'Intento', source: 'attemp' },
                ...data.reference.map(({ id, question_text }, index) => ({
                  title: (
                    <Tooltip
                      title={
                        <Typography
                          style={{
                            fontSize: '1rem',
                          }}
                        >
                          {question_text}
                        </Typography>
                      }
                    >
                      <Typography style={{ cursor: 'pointer' }}>
                        {`${index + 1}: ${
                          question_text.length > headerMaxLength
                            ? `${question_text.substring(0, headerMaxLength - 1)}...`
                            : question_text
                        }
                    `}
                      </Typography>
                    </Tooltip>
                  ),
                  source: id.toString(),
                  type: AdminItemViewType.Custom,
                  renderItem: item => {
                    if (item[id.toString()].includes(' ~ ')) {
                      const answers = item[id.toString()].split(' | ')
                      return (
                        <div className="row" style={{ alignItems: 'center' }}>
                          {answers.map((answer, index) => {
                            const answerSplitted = answer.split(' ~ ')
                            return (
                              <>
                                <Tooltip
                                  key={answerSplitted[0]}
                                  title={
                                    <Typography
                                      style={{
                                        fontSize: '1rem',
                                      }}
                                    >
                                      {answer}
                                    </Typography>
                                  }
                                >
                                  <Typography
                                    style={{
                                      textDecoration: 'underline',
                                      cursor: 'help',
                                      padding: '0.25rem',
                                    }}
                                  >
                                    {answerSplitted[0]}
                                  </Typography>
                                </Tooltip>
                                {index !== answers.length - 1 && <Typography>|</Typography>}
                              </>
                            )
                          })}
                        </div>
                      )
                    } else {
                      return item[id.toString()]
                    }
                  },
                })),
                {
                  title: (
                    <div className="row" style={{ gap: '0.25rem', alignItems: 'center' }}>
                      Puntaje
                      <Tooltip
                        title={
                          <Typography
                            style={{
                              fontSize: '0.85rem',
                            }}
                          >
                            Puntaje total obtenido en la actividad
                          </Typography>
                        }
                      >
                        <InfoIcon style={{ cursor: 'pointer', fontSize: '1rem' }} />
                      </Tooltip>
                    </div>
                  ),
                  source: 'score_obtained',
                },
                { title: 'Resultado', source: 'result' },
                { title: 'Fecha', source: 'date' },
              ]}
              forceNoActions
              isLoading={dataIsLoading}
              classes={{}}
            />
            <PaginatorComponent
              keyName={keyName}
              page={page}
              total={total}
              nextEnabled={page < total}
              prevEnabled={page > 1}
              onFirst={() => setPage(1)}
              onLast={() => setPage(total)}
              onNext={() => {
                setPage(state => state + 1)
              }}
              onPrev={() => {
                setPage(state => state - 1)
              }}
            />
          </>
        )
      )}
    </AdminContentWrapper>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ downloadReport }, dispatch)
}

export default connect(null, mapDispatchToProps)(AdminReportsQuizTriviaActivities)
