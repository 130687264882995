import {
  NEW_CAREER_PLANS_CHANNELS_READ_START,
  NEW_CAREER_PLANS_CHANNELS_READ_END,
  NEW_CAREER_PLANS_CHANNELS_READ_FAILED,
  NEW_CAREER_PLANS_SCALES_READ_START,
  NEW_CAREER_PLANS_SCALES_READ_END,
  NEW_CAREER_PLANS_SCALES_READ_FAILED,
  GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_START,
  GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_END,
  GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_FAILED,
  GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_START,
  GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_END,
  GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_FAILED,
  GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_START,
  GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_END,
  GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_FAILED,
} from '../../actions';

let initialState = {
  error: null,
  loading: false,
  channels: [],
  scales: [],
  courses: {},
  jobPositions: {},
  organizationalUnits: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_CAREER_PLANS_CHANNELS_READ_END:
      return {
        ...state,
        loading: false,
        channels: action.channels,
        error: null,
      };
    case NEW_CAREER_PLANS_CHANNELS_READ_START:
      return {
        ...state,
        error: null,
        channels: [],
        loading: true,
      };
    case NEW_CAREER_PLANS_CHANNELS_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case NEW_CAREER_PLANS_SCALES_READ_END:
      return {
        ...state,
        loading: false,
        scales: action.scales,
        error: null,
      };
    case NEW_CAREER_PLANS_SCALES_READ_START:
      return {
        ...state,
        error: null,
        scales: [],
        loading: true,
      };
    case NEW_CAREER_PLANS_SCALES_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_START:
      return {
        ...state,
        courses: [],
        error: null,
        loading: true,
      };
    case GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_END:
      return {
        ...state,
        error: null,
        loading: false,
        courses: {
          columns: coursesColumns,
          data: action.courses.map((course) =>
            mapCourseData(course)
          ),
        },
      };
    case GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_END:
      return {
        ...state,
        loading: false,
        jobPositions: {
          columns: columnsAssociatedJobPositions,
          data: action.jobPositions.map((jobPositions) => {
            return createDataAssociateJobPositions(jobPositions)
          }
          ),
          error: null,
        },
      };
    case GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_END:
      return {
        ...state,
        loading: false,
        organizationalUnits: {
          columns: columnsAssociatedOrganizationsUnits,
          data: action.organizationalUnits.map((OrganizationsUnits) =>
            createDataAssociateOrganizationsUnits(OrganizationsUnits)
          ),
          error: null,
        },
      };
    case GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};

const coursesColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "title",
    label: "Título",
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: "categories",
    label: "Categoría",
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: "condition",
    label: "Estado",
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: "scale",
    label: "Escala",
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: "mandatory",
    label: "Obligatorio",
    options: {
      filter: true,
      sort: false,
    },
  },
];
const columnsAssociatedJobPositions = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "code",
    label: "Código",
    options: {
      filter: true,
      sort: false,
      sortDescFirst: false,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: true,
      sort: false,
      sortDescFirst: false,
    },
  },
];

const columnsAssociatedOrganizationsUnits = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "code",
    label: "Código",
    options: {
      filter: true,
      sort: false,
      sortDescFirst: false,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: true,
      sort: false,
      sortDescFirst: false,
    },
  },
];

//method to parsed data from services
function mapCourseData({ id, title, categories, condition, mandatory, scale }) {
  return {
    id,
    title,
    categories,
    condition,
    mandatory,
    scale: '',
  };
}

function createDataAssociateJobPositions({ id, code, description }) {
  return {
    id,
    code,
    description,
  };
}

function createDataAssociateOrganizationsUnits({ id, code, description }) {
  return {
    id,
    code,
    description,
  };
}
