import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goHome } from '../../../actions/navigator';
import AdminNavBar from '../../../components/admin/ui/AdminNavBar';
import { FrontNavBarActionTypes } from '../../../config/constants';
import { toggleMenu } from '../../../actions/menuDrawer';

class AdminNavBarContainer extends Component {
  onToggleMenu = () => {
    this.props.toggleMenu();
  };

  handleActionClick = (actionType) => {
    switch (actionType) {
      case FrontNavBarActionTypes.Home:
        this.props.goHome();
        break;
      default:
        return false;
    }
  };

  render() {
    return (
      <AdminNavBar
        onToggleMenu={this.onToggleMenu}
        onActionClick={this.handleActionClick}
      />
    );
  }
}

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goHome, toggleMenu }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNavBarContainer);
