import { useDispatch } from 'react-redux'
import { addSnackbar } from '../actions/snackbar'

const useNotificate = () => {
  const dispatch = useDispatch()

  const notificate = (message, type) => {
    addSnackbar(message, type)(dispatch)
  }

  return { notificate }
}

export default useNotificate
