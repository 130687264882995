import React, { Component } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  IconButton,
  Icon,
  Collapse,
} from '@material-ui/core';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import { menuItems } from './MenuOptions';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LogoutContainer from '../../containers/session/Logout';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 230;

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiListItemText: {
      root: {
        padding: '0px 10px',
      },
    },
  },
});

const styles = (theme) => ({
  docked: {
    height: '100%',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    height: '100%',
    minHeight: '100%',
    paddingTop: '16px',
  },
  drawerContainer: {
    height: '100%',
    minHeight: '100%',
  },
  toolbar: theme.mixins.toolbar,
  row: {
    display: 'flex',
    justifyContent: 'left',
  },
  avatar: {
    marginLeft: '24px',
    marginBottom: 16,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logoutButtonListItem: {
    paddingTop: 0,
  },
  logoutButtonContainer: {
    position: 'relative',
    right: 12,
  },
});

class MenuDrawer extends Component {
  handleClick = (item) => {
    if (item.submenu) {
      const { action } = item;
      this.setState((state) => ({ [action]: !state[action] }));
    } else {
      this.props.onMenuItemClick(item);
    }
  };

  handleDrawerToggle = () => {
    const { onToggleMenu } = this.props;
    onToggleMenu();
  };

  renderDrawer = () => {
    const { classes, me } = this.props;
    const state = this.state;
    return (
      <div className={classes.drawerContainer} tabIndex={0}>
        <div className={classes.toolbar}>
          <IconButton
            aria-label="abrir menú"
            onClick={this.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className={(classes.row, classes.avatar)} />
        <List>
          {menuItems.items.map((item, index) => {
            const submenu = item.submenu ? (
              <Collapse
                in={state[item.action]}
                timeout="auto"
                unmountOnExit
                key={'list-item-' + index + 'collapse'}
              >
                <List component="div" disablePadding>
                  {item.items.map((subItem, subIndex) => (
                    <ListItem
                      button
                      className={classes.nested}
                      key={'list-item-' + index + '-sub-' + subIndex}
                      onClick={() => {
                        this.handleClick(subItem);
                      }}
                    >
                      <ListItemIcon>
                        <Icon>{subItem.icon || 'label'}</Icon>
                      </ListItemIcon>
                      <ListItemText inset primary={subItem.title} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            ) : null;

            return [
              <MuiThemeProvider theme={theme} key={'list-item-theme-' + index}>
                {me && me.is_admin ? (
                  <ListItem
                    button
                    key={'list-item-' + index}
                    onClick={() => {
                      this.handleClick(item);
                    }}
                  >
                    <ListItemIcon>
                      <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    {item.submenu ? (
                      state[item.action] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null}
                  </ListItem>
                ) : (
                  item.icon !== 'settings' && (
                    <ListItem
                      button
                      key={'list-item-' + index}
                      onClick={() => {
                        this.handleClick(item);
                      }}
                    >
                      <ListItemIcon>
                        <Icon>{item.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                      {item.submenu ? (
                        state[item.action] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}
                    </ListItem>
                  )
                )}
              </MuiThemeProvider>,
              submenu,
            ];
          })}
          <ListItem className={classes.logoutButtonListItem}>
            <ListItemIcon className={classes.logoutButtonContainer}>
              <LogoutContainer text="Cerrar Sesión" />
            </ListItemIcon>
          </ListItem>
        </List>
      </div>
    );
  };

  render() {
    const { classes, mobileOpen, customClasses, customContent } = this.props;
    return (
      <div>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={!mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper:
                (classes.drawerPaper,
                customClasses && customClasses.drawerPaper),
            }}
            ModalProps={{
              keepMounted: false, // Better open performance on mobile.
            }}
          >
            {customContent || this.renderDrawer()}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(MenuDrawer);
