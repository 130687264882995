import React from 'react'
import TrueOrFalseOption from './components/TrueOrFalseOption'

const QuestionTrueOrFalse = ({ handler, question, quizEditingBlocked }) => (
  <div className="col">
    {question?.options?.map(option => (
      <TrueOrFalseOption
        key={option.options_text}
        option={option}
        handler={handler}
        question={question}
        quizEditingBlocked={quizEditingBlocked}
      />
    ))}
  </div>
)

export default QuestionTrueOrFalse
