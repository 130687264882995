/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createResource, updateResource, getResource } from '../../../actions/admin'
import {
  EditInputType,
  EditInputObjectModel,
  ModalityType,
  AudienceType,
  Audiences,
  Modalities,
  AudienceAssignationTypes,
  AssignationTypes,
} from '../../../config/constants'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import AdminResourceForm from '../../../components/admin/AdminResourceForm'
import { addSnackbar } from '../../../actions/snackbar'
import { goTo } from '../../../actions/navigator'
import { Button, Grid, IconButton, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import * as moment from 'moment'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import {
  searchPlaces,
  searchPlatforms,
  searchInstitutions,
  searchInstitutionsRemote,
  loadInstitutions,
  loadPlaces,
  loadPlace,
  loadPlatform,
} from '../../../actions/autocomplete'
import { appLoadToken } from '../../../actions/app'
import { limitIntEntry, preventEvent } from '../../../utils/AdminHelper'

const initialState = {
  registration_deadline: null,
  email_text: null,
  modality: null,
  place: null,
  place_url: null,
  audience: null,
  maximum_capacity: null,
  minimum_quota: null,
  display_time_course: true,
  facilitators: [],
  audience_assignation_type: null,
  assignation_type: null,
  classesDate: 1,
  investment: '0',
  platform: null,
  assistance_required: null,
  assignations: {
    all_organizational_units: false,
    job_positions: [],
    organizational_units: [],
    users: [],
  },
}

const errorsInitialState = {
  //start_date: '',
  registration_deadline: '',
  email_text: '',
  //duration: '',
  modality: '',
  place: '',
  place_url: '',
  platform: '',
  audience: '',
  maximum_capacity: '',
  minimum_quota: '',
  facilitators: '',
  display_time_course: '',
  audience_assignation_type: '',
  assignation_type: '',
}

const datePickerStyles = theme => ({
  form: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  root: {
    flexGrow: 1,
    width: '75%',
    background: '#00000',
  },

  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
  },

  footerButtons: {
    paddingTop: '3em',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '4em 0',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '300px',
    padding: '200px',
  },

  inputsContainer: {
    paddingLeft: '80em',
  },

  cardClasess: {
    padding: '1em 8em',
    display: 'flex',
    width: '100%',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    background: '#F6F6F6',
  },
  formContainer: {
    boxShadow: 'none',
  },
})

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '20px 0',
    background: '#fff',
  },

  rootClasses: {
    flexGrow: 1,
    width: '100%',
    background: '#fff',
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },

  footerButtons: {
    paddingTop: '3em',
  },
  button: {
    margin: '1em',
    padding: '1em 2em',
    borderRadius: '5px',
    border: '1px solid #575454',
    maxWidth: '16em',
  },
  roundButton: {
    margin: '1%',
    border: '1px solid #575454',
    borderRadius: '50%',
    height: 35,
    width: 35,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '4em 0',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
  },
  containerClasses: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  mainContentContainerClasses: {
    width: '75%',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
  },

  mainContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    padding: '0 8rem',
  },

  // Form classes
  form: {
    padding: '1em 8em',
    display: 'flex',
    flexDirection: 'column',
  },
  cardClasess: {
    padding: '1em 8em',
    display: 'flex',
    width: '75%',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    background: '#F6F6F6',
  },
  formContainer: {
    boxShadow: 'none',
  },
  dateButtonsContainer: {
    width: '88%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: -30,
  },
  switchInput: {
    marginLeft: '4em',
    boxSizing: 'border-box',
  },
})

class DatePicker extends Component {
  render() {
    const {
      form,
      isValid,
      errors,
      loading,
      getColumnsClasses,
      onSubmit,
      onAttributeChange,
      classes,
    } = this.props

    return (
      <AdminResourceForm
        title={''}
        keyName={'admin-commissionsClasses-form'}
        form={form}
        isValid={isValid}
        errors={errors}
        loading={loading}
        attributes={getColumnsClasses()}
        onSubmit={() => onSubmit()}
        onAttributeChange={onAttributeChange}
        onGoBack={null}
        customClasses={classes}
        customActions={true}
      />
    )
  }
}

const DatePickerWithStyles = withStyles(datePickerStyles)(DatePicker)

class AdminExternalCoursesCommissionsStepForm extends Component {
  state = {
    isValid: false,
    form: {
      ...initialState,
    },
    errors: {
      ...errorsInitialState,
    },
    classesDate: initialState.classesDate,
    places: [],
    platform: [],
  }

  componentDidMount = async () => {
    this.onLoadPlatforms()

    if (!this.props.prevState && this.props.isEdit) {
      this.onLoadData()
    } else if (this.props.prevState) {
      this.loadFormFromPrevState(this.props.prevState)
    }
  }

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props
    getResource(keyName, type, params.id)
  }

  /**
   * onLoadPlaces
   * @returns Obtiene la lista de las plataformas
   */
  onLoadPlatforms = () => {
    this.props.searchPlatforms().then(data => {
      this.setState({ platform: data })
    })
  }

  onLoadInstitution = () => {
    const { loadInstitutions, appLoadToken } = this.props
    appLoadToken().then(() => {
      loadInstitutions()
    })
  }

  onLoadPlace = idI => {
    const { loadPlaces, appLoadToken } = this.props
    appLoadToken().then(() => {
      loadPlaces(idI)
    })
  }

  onLoadPlatform = idI => {
    const { loadPlatform, appLoadToken } = this.props
    appLoadToken().then(() => {
      loadPlatform(idI)
    })
  }

  onLoadPlaceWhitParameters = (idI, idA, idC) => {
    const { loadPlace, appLoadToken } = this.props
    appLoadToken().then(() => {
      loadPlace(idI, idA, idC)
    })
  }

  onLoadInstitutionRemote = () => {
    const { loadInstitutions, appLoadToken } = this.props
    appLoadToken().then(() => {
      loadInstitutions()
    })
  }

  /**
   * loadFormFromPrevState
   * @param {{ Object }} prevState
   * @returns Chequea que se encuentren cargados correctamente todos los datos del form
   * en su lugar ese campo estará en null. Esos datos serán guardados en un estado y luego
   * llamará a las siguientes funciones
   * validateForm()
   * onLoadPlaces()
   */
  loadFormFromPrevState = prevState => {
    const {
      registration_deadline,
      email_text,
      modality,
      place_url,
      place,
      institution,
      audience,
      maximum_capacity,
      minimum_quota,
      audience_assignation_type,
      assignation_type,
      job_positions,
      organizational_units,
      users,
      assistance_required,
      commissions_dates,
      display_time_course,
      display_time_course_selected,
      facilitatorsSelected,
      institutionSelected,
      investment,
      classRoomSelected,
      addressSelected,
      place_site,
      platformSelected,
    } = prevState

    let basicForm = {
      registration_deadline: registration_deadline || null,
      email_text: email_text || null,
      modality: modality || null,
      place: place || null,
      place_url: place_url || '',
      institution: institutionSelected || null,
      platform: platformSelected || null,
      classRoom: classRoomSelected || null,
      address: addressSelected || null,
      audience: audience || null,
      maximum_capacity: maximum_capacity || null,
      minimum_quota: minimum_quota.toString() || null,
      display_time_course: display_time_course || false,
      facilitators: facilitatorsSelected
        ? facilitatorsSelected.map(f => ({
            id: f.id,
            description: f.description,
          }))
        : [],
      audience_assignation_type: audience_assignation_type || null,
      assignation_type: assignation_type || null,
      all_organizational_units: false,
      assignations: {
        job_positions,
        organizational_units,
        users,
      },
      assistance_required: assistance_required || null,
      classesDate: commissions_dates.length || 0,
      investment: investment || '0',
    }

    let commissionDatesForm = []
    commissions_dates.map((d, index) => {
      commissionDatesForm = {
        ...commissionDatesForm,
        ['start_date' + index]: d.start_date,
        ['duration' + index]: d.duration,
      }
    })

    this.setState(
      {
        form: {
          ...basicForm,
          ...commissionDatesForm,
        },
      },
      () => {
        this.validateForm()
      },
    )

    this.setState({
      classesDate: commissions_dates.length,
    })
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.item && nextProps.item) {
      const {
        dates,
        registration_deadline,
        email_text,
        modality,
        place,
        place_url,
        platform,
        audience,
        maximum_capacity,
        minimum_quota,
        display_time_course,
        facilitators,
        audience_assignation_type,
        assignation_type,
        assignations,
        assistance_required,
        investment,
      } = nextProps.item

      this.setState({
        classesDate: dates.length,
      })

      if (modality === ModalityType.Presential) {
        this.onLoadInstitution()
        this.props.searchPlaces(place[0]?.institution).then(data => {
          this.setState({ places: data })
        })
      } else {
        this.onLoadInstitutionRemote()
      }

      if (modality === ModalityType.Remote) {
        this.props.searchPlatforms().then(data => {
          this.setState({ platforms: data })
        })
      }

      let basicForm = {
        investment: investment || '0',
        assistance_required: assistance_required || null,
        registration_deadline: registration_deadline || null,
        email_text: email_text || null,
        modality: modality || null,
        place: place || null,
        institution: place.length > 0 ? place[0]?.institution : null,
        place_url: place_url || '',
        platform: platform?.length > 0 ? platform[0].id : null,
        classRoom: place.length > 0 ? place[0].classroom : null,
        address: place.length > 0 ? place[0].address : null,
        audience: audience || null,
        maximum_capacity: maximum_capacity || null,
        minimum_quota: minimum_quota.toString() || null,
        display_time_course: display_time_course || false,
        facilitators: facilitators || [],
        audience_assignation_type: audience_assignation_type || null,
        assignation_type: assignation_type || null,
        all_organizational_units: false,
        assignations: {
          job_positions: assignations ? assignations.job_positions.map(jpId => ({ id: jpId })) : [],
          organizational_units: assignations
            ? assignations.organizational_units.map(ouId => ({ id: ouId }))
            : [],
          users: assignations ? assignations.users.map(uId => ({ id: uId })) : [],
        },
        place_site: place.length > 0 ? place[0]?.id : null,
      }

      let commissionDatesForm = []
      dates.map((d, index) => {
        commissionDatesForm = {
          ...commissionDatesForm,
          ['start_date' + index]: d.start_date,
          ['duration' + index]: d.duration,
        }
      })

      this.setState(
        {
          form: {
            ...basicForm,
            ...commissionDatesForm,
          },
        },
        () => {
          this.validateForm()
        },
      )
    }
  }

  onAttributeChange = (name, value) => {
    const resetAddress = name === 'institution' ? { address: null, classRoom: null } : null
    const newState = Object.assign(
      {},
      {
        ...this.state.form,
        [name]: value,
      },
      resetAddress,
    )
    this.setState({ form: newState }, () => {
      this.validateForm()
      if (['modality'].includes(name)) {
        value === ModalityType.Presential
          ? this.onLoadInstitution()
          : this.onLoadInstitutionRemote()
      }
      if (
        ['institution', 'modality'].includes(name) &&
        this.state.form.modality === ModalityType.Presential
      ) {
        if (this.state.form.institution) {
          this.props.searchPlaces(this.state.form.institution).then(data => {
            this.setState({ places: data })
          })
        }
      }
    })
  }

  /**
   * getColumns
   * @returns Se genera un objeto en el array key por cada campo que tiene que cargar el usuario
   * esto va a depender de la configuración que el usuario quiera cargar
   */
  getColumns = () => {
    let change = false
    let institution_selected = this.props.institutions.find(
      ({ id }) => id === this.state.form.institution,
    )

    const keys = [
      {
        title: 'Modalidad',
        source: 'modality',
        editable: true,
        editConfig: { type: EditInputType.Select, options: Modalities, name: 'modality' },
      },
    ]
    if (this.state.form.modality) {
      keys.push({
        title: 'Institución',
        source: 'institution',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          name: 'institution',
          options: this.props.institutions.map(({ id, name }) => ({ id, description: name })),
        },
      })
    }

    if (
      this.state.form.modality &&
      this.state.form.institution &&
      this.state.form.modality !== ModalityType.Remote
    ) {
      let addressMap = this.state.places.map(item => {
        return [item.address.name, item.address]
      })

      if (addressMap.length === 0) {
        change = true
        addressMap = this.props?.places?.map(item => {
          return [item.address.name, item.address]
        })
      }

      var addressMapArr = new Map(addressMap)
      keys.push({
        title: 'Dirección',
        source: 'address',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          name: 'address',
          options: [...addressMapArr.values()].map(address => ({
            id: address.id,
            description: address.name,
          })),
        },
      })
    }

    const placesSelected = change
      ? this.props?.places.filter(place => place.address.id === this.props?.place[0]?.address?.id)
      : this.state.places.filter(place => place.address.id === this.state.form.address)

    if (placesSelected[0]?.classroom && this.state.form.modality !== ModalityType.Remote) {
      keys.push({
        title: 'Salas',
        source: 'classRoom',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          name: 'classRoom',
          options: placesSelected.map(place => ({
            id: place.classroom.id,
            description: place.classroom.name,
          })),
        },
      })
    }

    if (this.state.form.modality === ModalityType.Remote) {
      keys.push({
        title: 'Plataforma',
        source: 'platform',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          name: 'platform',
          options: this.state.platform.map(({ id, name_type }) => ({ id, description: name_type })),
        },
      })
      keys.push({
        title: 'Link',
        source: 'place_url',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'place_url' },
      })
    }

    keys.push({
      title: 'Fecha de Cierre',
      source: 'registration_deadline',
      editable: true,
      editConfig: { type: EditInputType.Datetime, name: 'registration_deadline' },
    })

    keys.push({
      title: 'Audiencia',
      source: 'audience',
      editable: true,
      editConfig: {
        type: EditInputType.Select,
        options: Audiences,
        name: 'audience',
        disabled: this.props.isEdit && !this.props.isDuplicate,
      },
    })

    if (this.state.form.audience === AudienceType.Aimed) {
      keys.push({
        title: 'Tipo de asignación',
        source: 'audience_assignation_type',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          options: AudienceAssignationTypes,
          name: 'audience_assignation_type',
          disabled: this.props.isEdit && !this.props.isDuplicate,
        },
      })
      keys.push({
        title: 'Acción',
        source: 'assignation_type',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          options: AssignationTypes,
          name: 'assignation_type',
          disabled: this.props.isEdit && !this.props.isDuplicate,
        },
      })
    }

    keys.push({
      title: 'Cupo Mínimo',
      source: 'minimum_quota',
      editable: true,
      editConfig: { type: EditInputType.Number, name: 'minimum_quota' },
    })

    keys.push({
      title: 'Cupo Máximo',
      source: 'maximum_capacity',
      editable: true,
      editConfig: { type: EditInputType.Number, name: 'maximum_capacity' },
    })

    keys.push({
      title: 'Inversión',
      source: 'investment',
      editable: true,
      editConfig: {
        type: EditInputType.Number,
        name: 'investment',
        onKeyDown: limitIntEntry,
        onPaste: preventEvent,
      },
    })

    keys.push({
      title: 'Asistencia Requerida',
      source: 'assistance_required',
      editable: true,
      editConfig: { type: EditInputType.Number, name: 'assistance_required' },
    })

    keys.push({
      title: 'Facilitadores',
      source: 'facilitators',
      editable: true,
      editConfig: {
        type: EditInputType.Object,
        model: EditInputObjectModel.Facilitators,
        name: 'facilitators',
        displayAttribute: 'description',
        multiple: true,
      },
    })

    keys.push({
      title: 'Texto adicional de email',
      source: 'email_text',
      editable: true,
      editConfig: {
        type: EditInputType.Text,
        name: 'email_text',
      },
    })
    keys.push({
      source: 'displayTimeCourse',
      editable: true,
      editConfig: {
        type: EditInputType.Switch,
        name: 'display_time_course',
        label: 'Mostrar duración del curso en certificado',
      },
    })
    return {
      keys,
    }
  }

  getColumnsClasses = () => {
    let keys = []
    for (let index = 0; index < this.state.classesDate; index++) {
      keys.push(
        {
          title: `Clase ${index + 1} - Fecha y Hora`,
          source: `start_date${index}`,
          editable: true,
          editConfig: { type: EditInputType.Datetime, name: `start_date${index}` },
        },
        {
          title: `Clase ${index + 1} - Duración (minutos)`,
          source: `duration${index}`,
          editable: true,
          editConfig: { type: EditInputType.Number, name: `duration${index}` },
        },
      )
    }
    return { keys }
  }

  getRequestDataFromForm = async () => {
    const { form } = this.state
    const maxClasses = this.state.classesDate
    let arrayClasses = []
    let keys = Object.keys(form)

    for (let index = 0; index < maxClasses; index++) {
      keys.forEach(element => {
        let newClass = {
          start_date: '',
          duration: '',
        }

        if (element.includes('start_date' + index)) {
          newClass.start_date = form[element]
          newClass.duration = form['duration' + index]
        }
        if (newClass.start_date !== '') {
          arrayClasses.push(newClass)
        }
      })
    }

    let facilitatorsID = []
    let facilitatorsSelected = []
    form.facilitators.forEach(element => {
      facilitatorsID.push({ id: element.id })
      facilitatorsSelected.push({ id: element.id, description: element.description })
    })
    let display_time_course_selected = form.display_time_course
    let institutionSelected = form.institution
    let addressSelected = form.address
    let classRoomSelected = form.classRoom
    let platformSelected = form.platform
    this.onLoadPlaceWhitParameters(institutionSelected, addressSelected, classRoomSelected)

    const place_site = await this.props.searchPlaces(form.institution, form.address, form.classRoom)
    this.onLoadPlace(form.institution)

    let formData = {
      external_course_id: this.props.externalCourseId,
      email_text: form.email_text,
      registration_deadline: form.registration_deadline,
      assistance_required: form.assistance_required,
      modality: form.modality,
      audience: form.audience,
      maximum_capacity: form.maximum_capacity,
      minimum_quota: form.minimum_quota,
      display_time_course: display_time_course_selected,
      facilitators: facilitatorsID,
      facilitatorsSelected: facilitatorsSelected,
      institutionSelected: institutionSelected,
      addressSelected: addressSelected,
      classRoomSelected: classRoomSelected,
      audience_assignation_type: form.audience_assignation_type,
      assignation_type: form.assignation_type,
      commissions_dates: arrayClasses,
      investment: form.investment,
      all_organizational_units: form.assignations.all_organizational_units,
      job_positions:
        this.props.isEdit && !this.props.isDuplicate ? form.assignations.job_positions : [],
      organizational_units:
        this.props.isEdit && !this.props.isDuplicate ? form.assignations.organizational_units : [],
      users: this.props.isEdit && !this.props.isDuplicate ? form.assignations.users : [],
      platform: form.modality === ModalityType.Remote ? form.platform : null,
      platformSelected: platformSelected,
      place_url: form.modality === ModalityType.Remote ? form.place_url : null,
      place_site: place_site[0].id,
    }

    return formData
  }

  addClassesDate = () => {
    this.setState({
      classesDate: this.state.classesDate + 1,
    })
  }

  deleteClassDate = () => {
    this.setState({
      classesDate: this.state.classesDate - 1,
    })
  }

  /**
   * validateForm
   * @returns válida que todos los datos que son requeridos estén cargados de lo contrario
   * sé mostrar un mensaje al usuario
   */
  validateForm = () => {
    const form = this.state.form
    let error = false
    let dateIsAfter = false
    const maxClasses = this.state.classesDate
    let errors = {
      ...errorsInitialState,
    }
    let currentClasses = 0

    for (let index = 0; index < maxClasses; index++) {
      if (!form['start_date' + index]) {
        errors['start_date' + index] = 'Debes seleccionar una fecha de inicio'
        error = true
      }
      if (!form['duration' + index]) {
        errors['duration' + index] = 'Debes ingresar la duración (en minutos)'
        error = true
      }
      if (form['start_date' + index] && form.registration_deadline) {
        if (form['start_date' + index] < form.registration_deadline) {
          errors.registration_deadline =
            'La fecha de cierre de inscripción debe ser anterior a la de inicio del taller'
          error = true
        }
      }
      if (form['start_date' + index]) {
        currentClasses = index + 1
      }
    }

    if (!form.registration_deadline) {
      errors.registration_deadline = 'Debes seleccionar una fecha de cierre de inscripción'
      error = true
    }

    if (!form.assistance_required) {
      errors.assistance_required = 'Debes ingresar el minimo de asistencia requerida'
      error = true
    }

    if (form.assistance_required) {
      if (form.assistance_required < 1) {
        errors.assistance_required = 'Debes tener al menos una clase requerida'
        error = true
      }
      if (form.assistance_required > currentClasses) {
        errors.assistance_required =
          'Debes ingresar un valor de asistencia requerida menor a la cantidad de clases'
        error = true
      }
    }

    if (!form.modality) {
      errors.modality = 'Debes seleccionar la modalidad'
      error = true
    }

    if (!form.institution) {
      errors.institution = 'Debes seleccionar la institución'
      error = true
    }

    if (
      form.modality === ModalityType.Presential &&
      !form.classRoom &&
      this.state.places[0]?.classroom
    ) {
      errors.classRoom = 'Debes seleccionar la sala'
      error = true
    }

    if (form.institution && !form.address && form.modality === ModalityType.Presential) {
      errors.address = 'Debes seleccionar la dirección'
      error = true
    }

    if (form.modality === ModalityType.Remote) {
      if (!form.place_url) {
        errors.place_url = 'Debes ingresar la url'
        error = true
      }

      if (!form.platform) {
        errors.platform = 'Debes ingresar la plataforma'
        error = true
      }
    }

    if (!form.audience) {
      errors.audience = 'Debes seleccionar la audiencia'
      error = true
    }

    if (form.audience === AudienceType.Aimed && !form.audience_assignation_type) {
      errors.audience_assignation_type = 'Debes seleccionar un tipo de asignación'
      error = true
    }

    if (form.audience === AudienceType.Aimed && !form.assignation_type) {
      errors.assignation_type = 'Debes seleccionar una acción'
      error = true
    }

    if (!form.minimum_quota) {
      errors.minimum_quota = 'Debes seleccionar el minimo de inscriptos'
      error = true
    } else if (form.minimum_quota < 0) {
      errors.minimum_quota = 'El minimo debe ser mayor o igual a 0'
      error = true
    }

    if (!form.maximum_capacity) {
      errors.maximum_capacity = 'Debes seleccionar la capacidad'
      error = true
    } else if (
      form.minimum_quota > 0 &&
      parseInt(form.minimum_quota) > parseInt(form.maximum_capacity)
    ) {
      errors.maximum_capacity = 'El máximo debe ser mayor o igual al mínimo'
      error = true
    } else if (parseInt(form.maximum_capacity) < 1) {
      errors.maximum_capacity = 'El máximo debe ser mayor a 0'
      error = true
    }

    if (form.investment % 1 !== 0) {
      errors.investment = 'Debes ingresar números enteros'
      error = true
    }

    if (!form.facilitators || form.facilitators.length === 0) {
      errors.facilitators = 'Debes seleccionar al menos 1 facilitador'
      error = true
    }

    for (let i = 0; i < currentClasses; i++) {
      for (let j = i; j < currentClasses; j++) {
        dateIsAfter = moment(form['start_date' + i]).isAfter(moment(form['start_date' + j]))
        if (dateIsAfter) {
          errors['start_date' + i] = 'La fecha no puede ser posterior a las siguientes'
          error = true
          break
        }
      }
    }
    this.setState({
      errors,
      isValid: !error,
    })
    return !error
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiFormControl: {
          root: {
            margin: 5,
            color: '#3c3c3c',
          },
        },
        MuiPaper: {
          elevation1: {
            boxShadow: 'none',
          },
        },
      },
    })

  onSubmit = async () => {
    const { onSubmit } = this.props
    if (onSubmit) {
      const data = await this.getRequestDataFromForm()
      onSubmit(data)
    }
  }

  renderClassesDate = (classes, errors) => {
    const { loading } = this.props

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <div className={classes.rootClasses}>
          <div className={classes.containerClasses}>
            <div className={classes.mainContentContainerClasses}>
              <DatePickerWithStyles
                form={this.state.form}
                isValid={this.state.isValid}
                errors={errors}
                loading={loading}
                getColumnsClasses={this.getColumnsClasses}
                onSubmit={() => this.onSubmit()}
                onAttributeChange={this.onAttributeChange}
              />
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    )
  }

  render() {
    const { loading, title, classes, handleBack } = this.props
    return (
      <>
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.mainContentContainer}>
              <AdminResourceForm
                title={title}
                keyName={'admin-commissions-form'}
                form={this.state.form}
                isValid={this.state.isValid}
                errors={this.state.errors}
                loading={loading}
                attributes={this.getColumns()}
                onSubmit={() => this.onSubmit()}
                onAttributeChange={this.onAttributeChange}
                onGoBack={null}
                customClasses={classes}
                customActions={true}
              />
            </div>
          </div>

          {this.renderClassesDate(classes, this.state.errors)}
          <div className={classes.dateButtonsContainer}>
            {this.state.classesDate > 1 && (
              <IconButton onClick={() => this.deleteClassDate()} className={classes.roundButton}>
                <RemoveIcon />
              </IconButton>
            )}
            <IconButton onClick={() => this.addClassesDate()} className={classes.roundButton}>
              <AddIcon />
            </IconButton>
          </div>

          <Grid container spacing={3} className={classNames(classes.form, classes.footerButtons)}>
            <Grid className={classes.wrapperBoton}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleBack()}
                className={classes.button}
              >
                Cancelar
              </Button>
              <Button
                disabled={!this.state.isValid}
                variant="contained"
                color="primary"
                onClick={() => this.onSubmit()}
                className={classes.button}
              >
                {this.state.form && this.state.form.audience === AudienceType.Aimed
                  ? 'Continuar'
                  : 'Guardar'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}

AdminExternalCoursesCommissionsStepForm.defaultProps = {
  type: 'commissions/check',
}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource
  return {
    ...states[ownProps.keyName],
    institutions: state.institutionReducer.items,
    places: state.placesReducer.items,
    platforms: state.platformReducer.items,
    place: state.placeReducer.items,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResource,
      createResource,
      updateResource,
      addSnackbar,
      goTo,
      searchPlaces,
      searchInstitutions,
      loadInstitutions,
      loadPlaces,
      loadPlace,
      loadPlatform,
      searchPlatforms,
      searchInstitutionsRemote,
      appLoadToken,
    },
    dispatch,
  )
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AdminExternalCoursesCommissionsStepForm),
)
