import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip, Typography } from '@material-ui/core'
import CalendarIcon from '@material-ui/icons/Event'
import ClockIcon from '@material-ui/icons/Schedule'
import moment from 'moment'
import {
  CommissionsActions,
  FacilitatorTypes,
  Modalities,
  UserCommissionWaitingListStatus,
} from '../../config/constants'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/FormatHelper'
import ColorButton from '../ui/ColorButton'
import SuggestedIcon from '@material-ui/icons/AssistantPhoto'
import DirectedIcon from '@material-ui/icons/Announcement'
import classNames from 'classnames'

const styles = props => ({
  root: {
    borderColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    minHeight: 140,
    width: 'calc( 100% - 32px)',
    padding: 16,
    position: 'relative',
  },

  highlighted: {
    borderColor: props => props.highlightColor,
    borderWidth: 5,
    borderStyle: 'solid',
    borderRadius: '10px',
    minHeight: 140,
    width: 'calc( 100% - 32px)',
    padding: 16,
    position: 'relative',
  },

  calendarIcon: {
    fontSize: 18,
    color: '#1ed1a8',
    marginBottom: -4,
    marginRight: 4,
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontWeight: 400,
  },
  dateText: {
    marginRight: 14,
  },
  section: {
    marginTop: 16,
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    position: 'relative',
    width: '100%',
    marginTop: 16,
  },
  tagStatus: {
    padding: 16,
    background: '#262626',
    color: '#ffffff',
    fontSize: 16,
  },

  tagDirected: {
    fontSize: 18,
    color: '#1ed1a8',
    marginBottom: -2,
    marginLeft: 4,
  },

  tagSuggested: {
    fontSize: 18,
    color: '#1ed1a8',
    marginBottom: -2,
    marginLeft: 4,
  },
})

const CommissionCard = ({
  classes,
  commission,
  actions,
  onSubscribe,
  onUnsubscribe,
  highlighted = false,
  highlightColor = '#f6bc4c',
}) => {
  const registrationDeadline = moment(commission.registration_deadline)
  const classifiedFacilitators = {}
  commission.facilitators.forEach(f => {
    if (classifiedFacilitators[f.type] === undefined) {
      classifiedFacilitators[f.type] = []
    }
    classifiedFacilitators[f.type].push(capitalizeTheFirstLetterOfEachWord(f.facilitator))
  })

  return (
    <article className={classNames(classes.root, { [classes.highlighted]: highlighted })}>
      {commission.dates.map((date, index) => {
        const from = date ? moment(date.start_date) : null
        const dateTime = new Date(date.start_date)
        const to = moment(
          new Date(dateTime.setMinutes(dateTime.getMinutes() + date.duration)),
        )

        return (
          <div className={classes.horizontal}>
            <Typography
              component={'h3'}
              className={classes.text}
              tabIndex={0}
              aria-label={from.format('[Fecha] DD/MM/YYYY [Horario de] HH:mm ') ?? ''}
            >
              <span className={classes.dateText}>Clase {index + 1}:</span>
              <CalendarIcon className={classes.calendarIcon} aria-label={' Fecha '} />
              <span className={classes.dateText}>
                {from.format('DD/MM/YYYY') ?? '--/--/--'}
              </span>
              <ClockIcon className={classes.calendarIcon} aria-label={' Horario '} />
              {from.format('HH:mm') ?? '--:--'}
              {' - '}
              {to.format('HH:mm') ?? '--:--'}

              {commission.is_directed && (
                <>
                  {commission.is_suggested ? (
                    <SuggestedIcon
                      className={classes.tagSuggested}
                      titleAccess={'Comisión sugerida'}
                    />
                  ) : (
                    <DirectedIcon
                      className={classes.tagDirected}
                      titleAccess={'Comisión dirigida'}
                    />
                  )}
                </>
              )}
            </Typography>

            <div className={classes.text} tabIndex={0} role="text">
              {Modalities.find(m => m.id === commission.modality).description}
            </div>
          </div>
        )
      })}
      <div className={classes.section}>
        {Object.keys(classifiedFacilitators).map(type => {
          const typeName = FacilitatorTypes.find(ft => '' + ft.id === '' + type)
          return (
            <Typography
              component={'p'}
              className={classes.text}
              tabIndex={0}
              key={`commission-card-facilitator-${type}`}
            >
              {classifiedFacilitators[type].length > 1 ? typeName.plural : typeName.description}
              {': '}
              {classifiedFacilitators[type].join(', ')}
            </Typography>
          )
        })}
      </div>
      <div className={classes.section}>
        <Typography component={'p'} className={classes.text} tabIndex={0}>
          Cierre de inscripción: {registrationDeadline.format('DD/MM/YYYY HH:mm')}
        </Typography>
      </div>
      <div className={classes.footer + ' ' + classes.horizontal}>
        {commission.is_directed && (
          <>
            {commission.is_suggested ? (
              <Typography component={'p'} className={classes.text} tabIndex={0}>
                Comisión sugerida para su perfil
              </Typography>
            ) : (
              <Typography component={'p'} className={classes.text} tabIndex={0}>
                Comisión dirigida para su perfil
              </Typography>
            )}
          </>
        )}
        {actions.includes(CommissionsActions.Subscribe) && (
          <ColorButton
            role={'button'}
            color={'#3abf91'}
            label={'Inscribirme'}
            onClick={() => {
              onSubscribe(commission)
            }}
            tabIndex={0}
          />
        )}
        {actions.includes(CommissionsActions.SubscribeOnWaitingList) && (
          <ColorButton
            role={'button'}
            color={'#3abf91'}
            label={'Inscribirme a la lista de espera'}
            onClick={() => {
              onSubscribe(commission, true)
            }}
            tabIndex={0}
          />
        )}
        {actions.includes(CommissionsActions.Unsubscribe) && (
          <ColorButton
            color={'#bf552c'}
            role={'button'}
            label={'Cancelar inscripción'}
            onClick={() => {
              onUnsubscribe(commission)
            }}
            tabIndex={0}
          />
        )}
        {actions.length === 0 &&
          ![UserCommissionWaitingListStatus.Waiting].includes(
            commission.user_commission_waiting_list_status,
          ) && (
            <Tooltip
              title={
                'Para poder inscribirte debés completar los requisitos que figuran en la descripción del taller.'
              }
            >
              <ColorButton
                color={'#777776'}
                label={'Inscribirme'}
                onClick={() => {}}
                tabIndex={0}
              />
            </Tooltip>
          )}

        {!actions.includes(CommissionsActions.Subscribe) &&
          [UserCommissionWaitingListStatus.Waiting].includes(
            commission.user_commission_waiting_list_status,
          ) && <div className={classes.tagStatus}>En lista de espera</div>}

        {actions.includes(CommissionsActions.Done) && (
          <div className={classes.tagStatus}>Presente</div>
        )}
      </div>
    </article>
  )
}
export default withStyles(styles)(CommissionCard)
