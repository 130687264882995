import React, { Component, useState, useCallback } from 'react'
import { Typography, Grid, Tooltip, FormLabel, FormGroup, TextField } from '@material-ui/core'
import { withStyles, createMuiTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import ViewIcon from '@material-ui/icons/Visibility'
import FilterListIcon from '@material-ui/icons/FilterList'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloseIcon from '@material-ui/icons/Close'
import Popover from '@material-ui/core/Popover'
import CancelIcon from '@material-ui/icons/Cancel'
import EventIcon from '@material-ui/icons/Event'
import StarsIcon from '@material-ui/icons/Stars'
import LinkIcon from '@material-ui/icons/Link'
import moment from 'moment'
import UserAvatar from '../ui/UserAvatar'
import ParallaxBackground from '../ui/ParallaxBackground'
import {
  AdminItemViewType,
  CalendarEventType,
  CourseType,
  ModalitiesDescription,
  UserCommissionAssistanceDescription,
  UserCommissionComplete,
  UserCommissionStatus,
  UserCommissionStatusName,
} from '../../config/constants'
import EventListenerWrapper from '../ui/EventListenerWrapper'
import Loading from '../ui/Loading'
import Menu from '@material-ui/core/Menu/Menu'
import { generateGoogleCalendarLink, generateOutlookCalendarLink } from '../../utils/CalendarHelper'
import { minutesToDurationText } from '../../utils/FormatHelper'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { downloadCertificate, downloadCertificateCourse } from '../../actions/user'
import { addSnackbar } from '../../actions/snackbar'
import { SnackbarType } from '../../config/constants'
import * as FileSaver from 'file-saver'
import ProfileTables from './ProfileTables'

const styles = theme => {
  const mediaLg = theme.breakpoints.up(theme.mediaQueries.lg)

  return {
    container: {
      flex: 1,
      marginTop: 64,
      minHeight: '100vh',
      height: '100%',
      backgroundColor: 'rgba(232,232,232,0.87)',
      width: '100%',
      //padding: 30,
      boxSizing: 'border-box',
      position: 'relative',
      zIndex: 1,
      margin: 'auto',
      maxWidth: '1920px',
      [mediaLg]: {
        marginTop: 80,
      },
    },
    containerLoading: {
      flex: 1,
      marginTop: '40%',
      minHeight: '100vh',
      height: '100%',
      maxWidth: 'unset',
      width: '100%',
      //padding: 30,
      boxSizing: 'border-box',
      position: 'relative',
      zIndex: 1,
      margin: 'auto',
      [mediaLg]: {
        maxWidth: '72%',
        //padding: 60,
        marginTop: 80,
      },
    },

    topDataContainer: {
      height: 105,
      backgroundColor: '#575454',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      paddingBottom: 10,
      borderBottom: '1px solid #a3a3a3',
    },
    nameEmailContainer: {
      textAlign: 'center',
      marginTop: 40,
    },
    profileImageContainer: {
      height: 80,
      width: 80,
      display: 'inline-block',
      marginBottom: -50,
    },
    infoContent: {
      padding: 45,
    },
    paperHrsCursos: {
      width: '100%',
      //height: 210,
      borderRadius: 0,
      boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
    },
    paperHrsCursosFormation: {
      marginTop: 8,
      width: '100%',
      height: '100%',
      borderRadius: 0,
      boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
    },
    titleCardFormationHr: {
      fontSize: 16,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      paddingTop: 8,
      paddingLeft: 16,
      letterSpacing: 'normal',
      color: ' #5C5C5C',
    },
    titleCardHr: {
      fontSize: 16,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      paddingTop: 16,
      paddingLeft: 16,
      letterSpacing: 'normal',
      color: ' #5C5C5C',
    },
    labelFilter: {
      marginLeft: 16,
    },
    selectedFilter: {
      marginRight: 16,
      fontSize: 12,
      textAlign: 'right',
      marginTop: 36,
    },
    filterHr: {
      fontSize: 16,
      marginTop: -4,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'right',
      color: ' #5C5C5C',
    },
    filterCloseButon: {
      fontSize: 12,
      marginTop: -12,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'right',
      color: ' #5C5C5C',
    },
    filterLimpiarButon: {
      fontSize: 12,
      marginTop: -5,
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      color: '#3f51b5',
    },
    titleFilterCard: {
      fontSize: 14,
      marginLeft: 16,
      '&hover': 'none',
    },
    menuFilter: {
      minWidth: 150,
      padding: 15,
    },
    headerFilter: {
      '&:focus': {
        outline: 'none',
      },
    },
    valueCardHr: {
      fontSize: 45,
      paddingTop: 10,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
      letterSpacing: 'normal',
      color: ' #5c5c5c',
    },
    titleCardCursos: {
      fontSize: 12,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
      paddingTop: 16,
      letterSpacing: 'normal',
      color: ' #5c5c5c',
    },

    restProfileDataContainer: {
      marginTop: 20,
    },
    root: {
      backgroundColor: '#000',
      display: 'block',
      position: 'relative',
      zIndex: 0,
      minHeight: '100%',
      width: '100%',
    },

    textName: {
      fontSize: 20,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: ' #5c5c5c',
    },
    textPositionCC: {
      fontSize: 16,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 2.87,
      letterSpacing: 'normal',
      color: '#5c5c5c',
    },
    textPositionCCLabel: {
      fontSize: 16,
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 2.87,
      letterSpacing: 'normal',
      color: '#5c5c5c',
    },
    text: {
      color: '#0000008a',
      '&.bold': {
        fontWeight: 500,
      },
    },
    label: {
      fontWeight: 600,
      color: '#0000008a',
    },
    title: {
      margin: 'auto',
      fontFamily: 'PlaylistScript',
      width: 210,
      height: 33,
      fontSize: 50,
      paddingLeft: 21,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.51,
      paddingTop: 12,
      marginBottom: 15,
      paddingBottom: 20,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#ffffff',
    },
    downloadExcel: {
      color: '#0000008a',
      fontSize: 20,
    },
    downloadExcelIcon: {
      fontSize: 22,
    },
    menuPaper: {
      padding: 16,
    },
    actionButton: {
      marginRight: 4,
    },

    tableTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      paddingTop: 16,
      marginLeft: -5,
      letterSpacing: 'normal',
      color: '#5C5C5C',
    },
    menuItem: {
      fontFamily: 'Open Sans',
    },
  }
}

const AddEventToCalendarMenu = ({ event, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleClick = useCallback(
    (e, type) => {
      const calendarEvent = {
        name: event.name,
        from: event.fh_start,
        to: event.fh_end,
        description: '',
      }
      const details = []
      if (event.place_url) {
        details.push(
          `Para ingresar al taller hacé clic en el siguiente enlace: <a href="${event.place_url}">${event.place_url}</a>`,
        )
      }
      if (event.email_text) {
        details.push(event.email_text)
      }
      calendarEvent.description = details.join('<br><br>')
      let eventUrl = ''
      if (type === CalendarEventType.Outlook) {
        eventUrl = generateOutlookCalendarLink(calendarEvent)
      } else if (type === CalendarEventType.Google) {
        eventUrl = generateGoogleCalendarLink(calendarEvent)
      }
      if (eventUrl) {
        window.open(eventUrl, '_blank')
      }
      handleClose()
    },
    [handleClose, event],
  )
  return (
    <>
      <Tooltip title={'Agregar al calendario'}>
        <IconButton
          className={classes.actionButton}
          onClick={e => {
            setAnchorEl(e.target)
          }}
          tabIndex={0}
        >
          <EventIcon />
        </IconButton>
      </Tooltip>
      <Menu tabIndex={0} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          className={classes.menuItem}
          role={'link'}
          tabIndex={0}
          key={'google'}
          onClick={e => handleClick(e, CalendarEventType.Google)}
        >
          Agendar en Google
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          tabIndex={0}
          role={'link'}
          onClick={e => handleClick(e, CalendarEventType.Outlook)}
        >
          Agendar en Outlook
        </MenuItem>
      </Menu>
    </>
  )
}

class ProfileContent extends Component {
  state = {
    formationHrs: null,
    anchorEl: null,
    categoryFilter: null,
    typeFilter: null,
    yearFilter: null,
    optionsYear: {},
    optionsCategory: {},
    totalGeneralYear: 0,
    filterLabel: '',
  }

  downloadCertificateCommission = id => {
    const { downloadCertificate, addSnackbar } = this.props
    downloadCertificate(id)
      .then(data => {
        if (data?.log) addSnackbar('Error: ' + data?.log, SnackbarType.Error)
        else {
          const blob = new Blob([data], { type: 'application/pdf;charset=utf-8' })
          FileSaver.saveAs(blob, `Certificado_Taller_${id}.pdf`)
          addSnackbar('PDF descargado exitosamente.', SnackbarType.Success)
        }
      })
      .catch(err => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      })
  }

  downloadCertificateCourse = id => {
    const { downloadCertificateCourse, addSnackbar } = this.props
    downloadCertificateCourse(id)
      .then(data => {
        if (data?.log) addSnackbar('Error: ' + data?.log, SnackbarType.Error)
        else {
          const blob = new Blob([data], { type: 'application/pdf;charset=utf-8' })
          FileSaver.saveAs(blob, `Certificado_Curso_${id}.pdf`)
          addSnackbar('PDF descargado exitosamente.', SnackbarType.Success)
        }
      })
      .catch(err => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      })
  }

  formatExternalCourseDataToXls = data => {
    return data.map(el => {
      return {
        id: el.id,
        name: el.name,
        fh_start: el.fh_start,
        duration: el.duration,
        place: el.place,
        status: UserCommissionStatusName[el.status],
      }
    })
  }

  getColumnsCareerPlans = data => {
    const { onShowCarrerPlan, classes } = this.props
    const setCellProps = () => {
      return { tabIndex: 0 }
    }
    const setCellHeaderProps = () => ({ tabIndex: 0 })
    return [
      {
        label: 'Nombre',
        name: 'name',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Canal',
        name: 'channels',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Avance total',
        name: 'total_done',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Avance necesarios',
        name: 'needed',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Avance complementarios',
        name: 'accomplishment',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Acciones',
        name: 'id',
        options: {
          filter: false,
          setCellHeaderProps,
          setCellProps,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                <Tooltip title={`Abrir Plan de Carrera "${data[tableMeta.rowIndex].name}"`}>
                  <IconButton
                    className={classes.actionButton}
                    onClick={() => {
                      onShowCarrerPlan(value)
                    }}
                    tabIndex={0}
                    aria-label={`Abrir Plan de Carrera "${data[tableMeta.rowIndex].name}"`}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          },
        },
      },
    ]
  }
  getHeaderCareerPlans = () => {
    return [
      'ID',
      'Nombre',
      'Avance total',
      'Avance complementarios',
      'Avance necesarios',
      'Canal',
      'Total de Cursos',
    ]
  }
  getColumnsCourses = data => {
    const { classes, onShowCourse } = this.props

    const setCellProps = () => {
      return { tabIndex: 0 }
    }
    const setCellHeaderProps = () => ({ tabIndex: 0 })

    return [
      { label: 'ID', name: 'id', options: { setCellHeaderProps, setCellProps } },
      {
        label: 'Nombre',
        name: 'name',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Categoría',
        name: 'category',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Duración (en minutos)',
        name: 'duration',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Fecha',
        name: 'date',
        options: {
          setCellHeaderProps,
          setCellProps,
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1] && this.state.ageFilterChecked) {
                return [`Desde: ${v[0]}`, `Hasta: ${v[1]}`]
              } else if (v[0] && v[1] && !this.state.ageFilterChecked) {
                return `Desde: ${v[0]}, Hasta: ${v[1]}`
              } else if (v[0]) {
                return `Desde: ${v[0]}`
              } else if (v[1]) {
                return `Hasta: ${v[1]}`
              }
              return false
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '')
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1)
              } else if (filterPos === -1) {
                filterList[index] = []
              }

              return filterList
            },
          },
          filterOptions: {
            names: [],
            logic(dateString, filters) {
              var newDateFormat = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1')
              var newDate = new Date(newDateFormat)
              var filter0 = filters[0]
                ? new Date(
                    parseInt(filters[0].substring(0, 4)),
                    parseInt(filters[0].substring(5, 7) - 1),
                    parseInt(filters[0].substring(8, 10)),
                  )
                : false
              var filter1 = filters[1]
                ? new Date(
                    parseInt(filters[1].substring(0, 4)),
                    parseInt(filters[1].substring(5, 7) - 1),
                    parseInt(filters[1].substring(8, 10)),
                  )
                : false

              if (filter0 && filter1) {
                return newDate < filter0 || newDate > filter1
              } else if (filter0) {
                return newDate < filter0
              } else if (filter1) {
                return newDate > filter1
              }
              return false
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Fecha (Desde - Hasta)</FormLabel>
                <FormGroup row>
                  <TextField
                    type="date"
                    label=""
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label=""
                    type="datetime-local"
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        label: 'Acciones',
        name: 'id',
        options: {
          filter: false,
          setCellHeaderProps,
          setCellProps,
          customBodyRender: (value, tableMeta) => {
            const certificationEnabled = data[tableMeta.rowIndex].emits_certificate
            return (
              <>
                <Tooltip title={`Abrir Curso "${data[tableMeta.rowIndex].name}"`}>
                  <IconButton
                    className={classes.actionButton}
                    onClick={() => {
                      onShowCourse({ type: CourseType.Course, id: value })
                    }}
                    tabIndex={0}
                    aria-label={`Abrir Curso "${data[tableMeta.rowIndex].name}"`}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
                {certificationEnabled && (
                  <Tooltip title={'Descargar certificado Curso'}>
                    <IconButton
                      aria-label="Descargar Certificado Curso"
                      onClick={() => this.downloadCertificateCourse(data[tableMeta.rowIndex].id)}
                      tabIndex={0}
                      color="inherit"
                    >
                      <CloudDownloadIcon className={classes.downloadExcelIcon} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )
          },
        },
      },
    ]
  }
  getHeaderCourses = () => {
    return ['ID', 'Nombre', 'Duración (en minutos)', 'Fecha', 'Categoría']
  }
  getColumnsExternalCourses = data => {
    const { onUnsusbcribeFromCommission, onRateCommission, onShowCourse, classes } = this.props

    const setCellProps = () => {
      return { tabIndex: 0 }
    }

    const setCellHeaderProps = () => ({ tabIndex: 0 })
    return [
      {
        label: 'ID',
        name: 'id',
        options: {
          display: 'excluded',
        },
      },
      {
        label: 'Nombre',
        name: 'name',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Lugar',
        name: 'site',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Duración (en minutos)',
        name: 'duration',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Fecha Inicio',
        name: 'fh_start',
        options: {
          setCellHeaderProps,
          setCellProps,
          customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm'),
        },
      },
      {
        label: 'Estado',
        name: 'status',
        type: AdminItemViewType.Text,
      },
      {
        label: 'Acciones',
        name: 'commission_id',
        options: {
          filter: false,
          setCellHeaderProps,
          setCellProps,
          customBodyRender: (value, tableMeta) => {
            const unsubscribeEnabled =
              data[tableMeta.rowIndex].unsubscribed &&
              data[tableMeta.rowIndex].status === UserCommissionStatus.Subscribed
            const certificationEnabled =
              data[tableMeta.rowIndex].complete === UserCommissionComplete.Complete &&
              data[tableMeta.rowIndex].emits_certificate
            const rated = data[tableMeta.rowIndex].rated
            const present = data[tableMeta.rowIndex].status === UserCommissionStatus.Present
            return (
              <>
                <Tooltip title={`Abrir Taller "${data[tableMeta.rowIndex].name}"`}>
                  <IconButton
                    className={classes.actionButton}
                    onClick={() => {
                      onShowCourse({
                        type: CourseType.ExternalCourse,
                        id: data[tableMeta.rowIndex].id,
                      })
                    }}
                    tabIndex={0}
                    aria-label={`Abrir Taller "${data[tableMeta.rowIndex].name}"`}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
                {certificationEnabled && (
                  <Tooltip title={'Descargar certificado Taller'}>
                    <IconButton
                      aria-label="Descargar Certificado Taller"
                      onClick={() =>
                        this.downloadCertificateCommission(data[tableMeta.rowIndex].commission_id)
                      }
                      tabIndex={0}
                      color="inherit"
                    >
                      <CloudDownloadIcon className={classes.downloadExcelIcon} />
                    </IconButton>
                  </Tooltip>
                )}
                {unsubscribeEnabled && (
                  <Tooltip title={'Cancelar inscripción'}>
                    <IconButton
                      className={classes.actionButton}
                      onClick={() => {
                        onUnsusbcribeFromCommission(value)
                      }}
                      tabIndex={0}
                      aria-label={'Cancelar inscripción'}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {unsubscribeEnabled && (
                  <AddEventToCalendarMenu classes={classes} event={data[tableMeta.rowIndex]} />
                )}
                {!rated && present && (
                  <Tooltip title={'Valorar el taller'}>
                    <IconButton
                      className={classes.actionButton}
                      onClick={() => {
                        onRateCommission(value)
                      }}
                      tabIndex={0}
                      aria-label={'Valorar el taller'}
                    >
                      <StarsIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )
          },
        },
      },
    ]
  }
  getHeaderExternalCourses = () => {
    return ['ID', 'Nombre', 'Fecha de Inicio', 'Duración (minutos)', 'Lugar', 'Estado']
  }
  getColumnsExternalFormations = () => {
    const setCellProps = () => {
      return { tabIndex: 0 }
    }
    const setCellHeaderProps = () => ({ tabIndex: 0 })

    return [
      {
        label: 'Nombre',
        name: 'course_name',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Duración (en minutos)',
        name: 'duration',
        options: { setCellHeaderProps, setCellProps },
      },
      {
        label: 'Fecha de inicio',
        name: 'start_date',
        options: {
          setCellHeaderProps,
          setCellProps,
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1] && this.state.ageFilterChecked) {
                return [`Desde inicio: ${v[0]}`, `Hasta inicio: ${v[1]}`]
              } else if (v[0] && v[1] && !this.state.ageFilterChecked) {
                return `Desde inicio: ${v[0]}, Hasta inicio: ${v[1]}`
              } else if (v[0]) {
                return `Desde inicio: ${v[0]}`
              } else if (v[1]) {
                return `Hasta inicio: ${v[1]}`
              }
              return false
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '')
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1)
              } else if (filterPos === -1) {
                filterList[index] = []
              }

              return filterList
            },
          },
          filterOptions: {
            names: [],
            logic(dateString, filters) {
              var newDateFormat = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1')
              var newDate = new Date(newDateFormat)
              var filter0 = filters[0]
                ? new Date(
                    parseInt(filters[0].substring(0, 4)),
                    parseInt(filters[0].substring(5, 7) - 1),
                    parseInt(filters[0].substring(8, 10)),
                  )
                : false
              var filter1 = filters[1]
                ? new Date(
                    parseInt(filters[1].substring(0, 4)),
                    parseInt(filters[1].substring(5, 7) - 1),
                    parseInt(filters[1].substring(8, 10)),
                  )
                : false

              if (filter0 && filter1) {
                return newDate < filter0 || newDate > filter1
              } else if (filter0) {
                return newDate < filter0
              } else if (filter1) {
                return newDate > filter1
              }
              return false
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Fecha Inicio (Desde - Hasta)</FormLabel>
                <FormGroup row>
                  <TextField
                    type="date"
                    label=""
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label=""
                    type="datetime-local"
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        label: 'Fecha de finalización',
        name: 'end_date',
        options: {
          setCellHeaderProps,
          setCellProps,
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1] && this.state.ageFilterChecked) {
                return [`Desde fin: ${v[0]}`, `Hasta fin: ${v[1]}`]
              } else if (v[0] && v[1] && !this.state.ageFilterChecked) {
                return `Desde fin: ${v[0]}, Hasta fin: ${v[1]}`
              } else if (v[0]) {
                return `Desde fin: ${v[0]}`
              } else if (v[1]) {
                return `Hasta fin: ${v[1]}`
              }
              return false
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '')
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1)
              } else if (filterPos === -1) {
                filterList[index] = []
              }

              return filterList
            },
          },
          filterOptions: {
            names: [],
            logic(dateString, filters) {
              var newDateFormat = dateString.replace(/(\d+[/])(\d+[/])/, '$2$1')
              var newDate = new Date(newDateFormat)
              var filter0 = filters[0]
                ? new Date(
                    parseInt(filters[0].substring(0, 4)),
                    parseInt(filters[0].substring(5, 7) - 1),
                    parseInt(filters[0].substring(8, 10)),
                  )
                : false
              var filter1 = filters[1]
                ? new Date(
                    parseInt(filters[1].substring(0, 4)),
                    parseInt(filters[1].substring(5, 7) - 1),
                    parseInt(filters[1].substring(8, 10)),
                  )
                : false

              if (filter0 && filter1) {
                return newDate < filter0 || newDate > filter1
              } else if (filter0) {
                return newDate < filter0
              } else if (filter1) {
                return newDate > filter1
              }
              return false
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Fecha Fin (Desde - Hasta)</FormLabel>
                <FormGroup row>
                  <TextField
                    type="date"
                    label=""
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label=""
                    type="datetime-local"
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value
                      onChange(filterList[index], index, column)
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      { label: 'Inversión', name: 'investment', options: { setCellHeaderProps, setCellProps } },
      { label: 'Institución', name: 'institution', options: { setCellHeaderProps, setCellProps } },
      {
        label: 'Modalidad',
        name: 'modality',
        options: {
          setCellHeaderProps,
          setCellProps,
          customBodyRender: value =>
            ModalitiesDescription.find(item => item.id === value)?.description ?? '-',
        },
      },
      {
        label: 'Estado',
        name: 'status',
        options: {
          setCellHeaderProps,
          setCellProps,
          customBodyRender: value =>
            UserCommissionAssistanceDescription.find(item => item.id === value)?.description ?? '-',
        },
      },
    ]
  }
  getHeaderExternalFormations = () => {
    return [
      'Nombre',
      'Duración',
      'Fecha de inicio',
      'Fecha de fin',
      'Inversión',
      'Institución',
      'Modalidad',
      'Estado',
    ]
  }
  getActions = () => {
    return [
      {
        title: 'VER',
        action: this.onViewItem,
        icon: <ViewIcon />,
      },
    ]
  }
  optionsCourses = () => {
    const { onDownloadXls } = this.props
    return {
      download: true,
      print: false,
      rowsPerPage: 10,
      viewColumns: false,
      selectableRowsHideCheckboxes: false,
      selectableRows: 'none',
      search: false,
      sort: true,
      fixedHeader: true,
      selectableRowsHeader: false,
      customSort: (data, dataIndex, rowIndex) => {
        if (dataIndex === 4) {
          return data.sort((a, b) => {
            const dateA = new Date(a.data[dataIndex]).getTime()
            const dateB = new Date(b.data[dataIndex]).getTime()
            return (dateA < dateB ? -1 : 1) * (rowIndex === 'desc' ? 1 : -1)
          })
        } else {
          return data.sort((a, b) => {
            return (
              (a.data[dataIndex].length < b.data[dataIndex].length ? -1 : 1) *
              (rowIndex === 'desc' ? 1 : -1)
            )
          })
        }
      },
      textLabels: {
        body: {
          noMatch: 'Lo sentimos, no hay registros',
          toolTip: 'Orden',
          columnHeaderTooltip: column => `Orden para ${column.label}`,
        },
        selectedRows: {
          text: 'Item seleccionado',
          delete: 'Borrar',
          deleteAria: 'Borrar seleccion',
        },
        pagination: {
          next: 'Siguiente',
          previous: 'Anterior',
          rowsPerPage: 'Items por página',
          displayRows: 'de',
        },
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Descargar XLS',
          print: 'Imprimir',
          viewColumns: 'Ver Columnas',
          filterTable: 'Filtrar',
        },
        filter: {
          all: 'Todos',
          title: 'Filtros',
          reset: 'Limpiar',
        },
      },

      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        let customData = []
        data.forEach(element => {
          let course = []
          course['id'] = element.data[0]
          course['name'] = element.data[1]
          course['duration'] = element.data[3]
          course['date'] = element.data[4]
          course['category'] = element.data[2]
          customData.push({ ...course })
        })
        onDownloadXls(customData, this.getHeaderCourses())
        return false
      },
    }
  }
  optionsCareerPlans = data => {
    const { onShowCarrerPlan, onDownloadXls, classes } = this.props
    const setRowProps = rowData => {
      return {
        component: EventListenerWrapper,
        TagType: 'tr',
        onEnter: () => onShowCarrerPlan(rowData[5]),
      }
    }
    return {
      download: false,
      filter: false,
      print: false,
      pagination: false,
      rowsPerPage: 10,
      viewColumns: false,
      selectableRowsHideCheckboxes: false,
      selectableRows: 'none',
      search: false,
      sort: true,
      fixedHeader: true,
      selectableRowsHeader: false,
      setRowProps,
      textLabels: {
        body: {
          noMatch: 'Lo sentimos, no hay registros',
          toolTip: 'Orden',
          columnHeaderTooltip: column => `Orden para ${column.label}`,
        },
        selectedRows: {
          text: 'Item seleccionado',
          delete: 'Borrar',
          deleteAria: 'Borrar seleccion',
        },
        pagination: {
          next: 'Siguiente',
          previous: 'Anterior',
          rowsPerPage: 'Items por página',
          displayRows: 'de',
        },
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Descargar CSV',
          print: 'Imprimir',
          viewColumns: 'Ver Columnas',
          filterTable: 'Filtrar',
        },
        filter: {
          all: 'Todos',
          title: 'Filtros',
          reset: 'Limpiar',
        },
      },
      customToolbar: () => {
        return (
          <Tooltip title={'Descargar'}>
            <IconButton
              aria-label="Descargar reporte de Rutas de Aprendizaje"
              classes={{ root: classes.downloadExcel }}
              onClick={() => {
                onDownloadXls(data, this.getHeaderCareerPlans())
              }}
              tabIndex={0}
              color="inherit"
            >
              <CloudDownloadIcon className={classes.downloadExcelIcon} />
            </IconButton>
          </Tooltip>
        )
      },
    }
  }
  optionsExternalCourses = () => {
    const { onDownloadXls } = this.props

    return {
      download: true,
      print: false,
      rowsPerPage: 10,
      viewColumns: false,
      selectableRowsHideCheckboxes: false,
      selectableRows: 'none',
      search: false,
      sort: true,
      fixedHeader: true,
      selectableRowsHeader: false,
      textLabels: {
        body: {
          noMatch: 'Lo sentimos, no hay registros',
          toolTip: 'Orden',
          columnHeaderTooltip: column => `Orden para ${column.label}`,
        },
        selectedRows: {
          text: 'Item seleccionado',
          delete: 'Borrar',
          deleteAria: 'Borrar seleccion',
        },
        pagination: {
          next: 'Siguiente',
          previous: 'Anterior',
          rowsPerPage: 'Items por página',
          displayRows: 'de',
        },
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Descargar XLS',
          print: 'Imprimir',
          viewColumns: 'Ver Columnas',
          filterTable: 'Filtrar',
        },
        filter: {
          all: 'Todos',
          title: 'Filtros',
          reset: 'Limpiar',
        },
      },

      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        let customData = []
        data.forEach(element => {
          let externalCourse = []
          externalCourse['id'] = element.data[5]
          externalCourse['name'] = element.data[0]
          externalCourse['date'] = element.data[3]
          externalCourse['duration'] = element.data[2]
          externalCourse['place'] = element.data[1]
          externalCourse['state'] = element.data[4]
          customData.push({ ...externalCourse })
        })
        onDownloadXls(customData, this.getHeaderExternalCourses())
        return false
      },
    }
  }
  optionsExternalFormations = () => {
    const { onDownloadXls } = this.props

    return {
      download: true,
      print: false,
      rowsPerPage: 10,
      viewColumns: false,
      selectableRowsHideCheckboxes: false,
      selectableRows: 'none',
      search: false,
      sort: true,
      fixedHeader: true,
      selectableRowsHeader: false,
      textLabels: {
        body: {
          noMatch: 'Lo sentimos, no hay registros',
          toolTip: 'Orden',
          columnHeaderTooltip: column => `Orden para ${column.label}`,
        },
        selectedRows: {
          text: 'Item seleccionado',
          delete: 'Borrar',
          deleteAria: 'Borrar seleccion',
        },
        pagination: {
          next: 'Siguiente',
          previous: 'Anterior',
          rowsPerPage: 'Items por página',
          displayRows: 'de',
        },
        toolbar: {
          search: 'Buscar',
          print: 'Imprimir',
          viewColumns: 'Ver Columnas',
          filterTable: 'Filtrar',
        },
        filter: {
          all: 'Todos',
          title: 'Filtros',
          reset: 'Limpiar',
        },
      },

      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        let customData = []
        data.forEach(element => {
          let course = []
          course['course_name'] = element.data[0]
          course['duration'] = element.data[1]
          course['start_date'] = element.data[2]
          course['end_date'] = element.data[3]
          course['investment'] = element.data[4]
          course['institution'] = element.data[5]
          course['modality'] = element.data[6]
          course['status'] = element.data[7]
          customData.push({ ...course })
        })
        onDownloadXls(customData, this.getHeaderExternalFormations())
        return false
      },
    }
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          h6: {
            fontSize: 16,
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            textAlign: 'left',
            paddingTop: 16,
            marginLeft: -5,
            letterSpacing: 'normal',
            color: '#5C5C5C',
          },
        },
        MUIDataTableHeadCell: {
          data: {
            color: '#ff8080',
          },
        },
        MUIDataTableBodyCell: {
          stackedCommon: {
            minWidth: 100,
          },
        },
        MUIDataTableToolbar: {},
        MuiToolbar: {},
        MuiTableRow: {
          root: {
            '&:focus-visible': {
              outline: 'auto',
            },
          },
        },
      },
    })

  handleClick = event => {
    this.setState({ anchorEl: event.target })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  totalFormationHr = () => {
    const { profile } = this.props
    let totalHr = 0
    Object.keys(profile.formation_hs.by_type).map(
      key => (totalHr += profile.formation_hs.by_type[key]),
    )
    return totalHr
  }

  handleClickMenuYearItem = (value, key) => {
    this.setState({ formationHrs: value })
    this.setState({ yearFilter: value })
    this.setState({ categoryFilter: null })
    this.setState({ typeFilter: null })
    this.setState({ filterLabel: 'Total por año ' + key })
  }

  handleClickMenuCategoryItem = (value, key) => {
    this.setState({ formationHrs: value.duration })
    this.setState({ categoryFilter: value.id })
    this.setState({ yearFilter: null })
    this.setState({ typeFilter: null })
    this.setState({ filterLabel: 'Filtro por categoría ' + key })
  }

  handleClickMenuTypeItem = (value, key) => {
    this.setState({ formationHrs: value })
    this.setState({ categoryFilter: null })
    this.setState({ yearFilter: null })
    this.setState({ typeFilter: key.id })
    this.setState({ filterLabel: 'Filtro por tipo ' + key.name })
  }

  handleLimpiar = () => {
    this.setState({ formationHrs: null })
    this.setState({ categoryFilter: '' })
    this.setState({ yearFilter: '' })
    this.setState({ typeFilter: '' })
  }

  render() {
    const {
      props,
      optionsCareerPlans,
      getColumnsCareerPlans,
      optionsCourses,
      getColumnsCourses,
      optionsExternalCourses,
      getColumnsExternalCourses,
      optionsExternalFormations,
      getColumnsExternalFormations,
      getMuiTheme,
    } = this
    const { classes, user, profile } = props
    const tablesData = profile
      ? [
          !!profile.career_plans?.length && {
            id: 'rutaAprendizaje',
            title: 'Rutas de Aprendizaje',
            columns: getColumnsCareerPlans(profile.career_plans),
            options: optionsCareerPlans(profile.career_plans),
            data: profile.career_plans,
          },
          !!profile.courses?.online?.length && {
            id: 'onlineCourses',
            title: 'Cursos Online Finalizados',
            columns: getColumnsCourses(profile.courses.online),
            options: optionsCourses(profile.courses.online),
            data: profile.courses.online,
          },
          !!profile.courses?.others?.length && {
            id: 'talleres',
            title: 'Talleres',
            columns: getColumnsExternalCourses(profile.courses.others),
            options: optionsExternalCourses(profile.courses.others),
            data: profile.courses.others,
          },
          !!profile.courses?.formations?.length && {
            id: 'formacionesExternas',
            title: 'Formaciones externas',
            columns: getColumnsExternalFormations(profile.courses.formations),
            options: optionsExternalFormations(profile.courses.formations),
            data: profile.courses.formations,
          },
        ].filter(item => !!item)
      : []
    const formation_hs_by_category = []
    if (profile) {
      profile.formation_hs.by_category.forEach(category => {
        const findItem = formation_hs_by_category.find(item => item.id === category.id)
        if (findItem) {
          findItem.duration += category.duration
        } else {
          formation_hs_by_category.push(category)
        }
      })
    }
    if (user && user.full_name && !user.last_name) {
      const names = user.full_name.split(',')
      if (names.length === 2) {
        user.first_name = names[1].trim()
        user.last_name = names[0].trim()
      }
    }
    return (
      <div className={classes.root}>
        {user && profile && (
          <>
            <ParallaxBackground />
            <div className={classes.container}>
              <div>
                <div>
                  <div className={classes.topDataContainer}>
                    <Typography component={'h1'} className={classes.title} tabIndex={0}>
                      Mi Perfil:
                    </Typography>
                    <div className={classes.profileImageContainer}>
                      <UserAvatar mini={false} user={user} />
                    </div>
                  </div>
                  <div className={classes.nameEmailContainer}>
                    <Typography className={`${classes.textName} bold`} tabIndex={0}>
                      {`${user.first_name} ${user.last_name}`}.
                    </Typography>
                    <Typography className={classes.textPositionCC} tabIndex={0}>
                      <span className={classes.textPositionCCLabel}>Puesto: </span>{' '}
                      {user?.job_position?.description}.{' '}
                      <span className={classes.textPositionCCLabel}>Centro de costos: </span>{' '}
                      {'(' +
                        user?.organizational_unit?.code +
                        ') ' +
                        user?.organizational_unit?.description +
                        '.'}
                    </Typography>
                  </div>
                </div>
                <div id="main" tabIndex="-1" className={classes.infoContent}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paperHrsCursosFormation}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6}>
                            <Typography
                              component={'h2'}
                              className={classes.titleCardFormationHr}
                              tabIndex={0}
                            >
                              {'Horas de Formación:'}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} className={classes.filterHr}>
                            <div>
                              <IconButton
                                tabIndex={0}
                                aria-label="desplegar filtro por categoría y año"
                                aria-controls="menu-appbar"
                                aria-haspopup="menu"
                                onClick={this.handleClick}
                                color="inherit"
                              >
                                <FilterListIcon />
                              </IconButton>
                              <div>
                                <Popover
                                  classes={{ paper: classes.menuPaper }}
                                  id="menu-appbar"
                                  anchorEl={this.state.anchorEl}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  // keepMounted
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={this.handleClose}
                                >
                                  <Grid
                                    role={'menu'}
                                    container
                                    spacing={1}
                                    className={classes.headerFilter}
                                  >
                                    <Grid item xs={2} sm={2}>
                                      <Typography className={classes.titleFilterCard}>
                                        {'Filtros'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2}>
                                      <Button
                                        tabIndex={0}
                                        onClick={this.handleLimpiar}
                                        className={classes.filterLimpiarButon}
                                      >
                                        {'LIMPIAR'}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={8} sm={8} className={classes.filterCloseButon}>
                                      <IconButton
                                        tabIndex={0}
                                        aria-label="Cerrar"
                                        onClick={this.handleClose}
                                        color="inherit"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  <FormControl className={classes.menuFilter}>
                                    <InputLabel
                                      shrink
                                      id="select-label-type"
                                      className={classes.labelFilter}
                                    >
                                      Tipo
                                    </InputLabel>
                                    <Select
                                      labelId="select-label-type"
                                      id="select-type"
                                      value={this.state.typeFilter}
                                      placeholder={'Por Tipo'}
                                    >
                                      {[
                                        { id: CourseType.Course.toLowerCase(), name: 'Curso' },
                                        {
                                          id: CourseType.ExternalCourse.toLowerCase(),
                                          name: 'Taller',
                                        },
                                      ].map((option, i) => (
                                        <MenuItem
                                          className={classes.selectField}
                                          key={'type-option-' + i}
                                          name={'type'}
                                          value={option.id}
                                          onClick={this.handleClickMenuTypeItem.bind(
                                            this,
                                            profile.formation_hs.by_type[option.id],
                                            option,
                                          )}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl className={classes.menuFilter}>
                                    <InputLabel
                                      shrink
                                      id="select-label-category"
                                      className={classes.labelFilter}
                                    >
                                      Categoría
                                    </InputLabel>
                                    <Select
                                      labelId="select-label-category"
                                      id="select-category"
                                      value={this.state.categoryFilter}
                                      placeholder={'Por Categoría'}
                                    >
                                      {formation_hs_by_category.map(category => {
                                        return (
                                          <MenuItem
                                            className={classes.selectField}
                                            key={category.id}
                                            value={category.id}
                                            onClick={this.handleClickMenuCategoryItem.bind(
                                              this,
                                              category,
                                              category.name,
                                            )}
                                          >
                                            {category.name}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormControl className={classes.menuFilter}>
                                    <InputLabel
                                      shrink
                                      id="select-label-year"
                                      className={classes.labelFilter}
                                    >
                                      Año
                                    </InputLabel>
                                    <Select
                                      labelId="select-label-year"
                                      id="select-year"
                                      value={this.state.yearFilter}
                                      placeholder={'Por Año'}
                                    >
                                      {Object.keys(profile.formation_hs.by_year).map(key => (
                                        <MenuItem
                                          className={classes.selectField}
                                          key={key}
                                          value={profile.formation_hs.by_year[key]}
                                          name={key}
                                          onClick={this.handleClickMenuYearItem.bind(
                                            this,
                                            profile.formation_hs.by_year[key],
                                            key,
                                          )}
                                        >
                                          {key}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Popover>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Typography className={classes.valueCardHr} tabIndex={0} aria-live="polite">
                          {/* {profile.formation_hs.by_year} */}
                          {this.state.formationHrs === null
                            ? minutesToDurationText(this.totalFormationHr())
                            : minutesToDurationText(this.state.formationHrs)}
                        </Typography>
                        {this.state.formationHrs === null && (
                          <Typography
                            component={'h2'}
                            className={classes.selectedFilter}
                            tabIndex={0}
                            aria-live="polite"
                          >
                            {'Horas totales '}
                          </Typography>
                        )}
                        {this.state.formationHrs !== null && (
                          <Typography
                            className={classes.selectedFilter}
                            tabIndex={0}
                            aria-live="polite"
                          >
                            {this.state.filterLabel}
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                    {profile && classes && !!tablesData?.length && (
                      <ProfileTables
                        tablesData={tablesData}
                        profile={profile}
                        classes={classes}
                        theme={getMuiTheme()}
                      />
                    )}
                  </Grid>
                </div>
              </div>
            </div>
          </>
        )}
        {(!user || !profile) && (
          <div className={classes.containerLoading}>
            <Loading></Loading>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      downloadCertificate,
      downloadCertificateCourse,
      addSnackbar,
    },
    dispatch,
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileContent)),
)
