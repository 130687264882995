import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AdminResourceForm from '../AdminResourceForm';
import { EditInputType, EditInputObjectModel } from '../../../config/constants';

const styles = {
  button: {
    margin: '1em',
    padding: '1em 2em',
    borderRadius: '5px',
    border: '1px solid #575454',
    maxWidth: '16em',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '4em 0',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerTextInput: {
    maxWidth: '60%',
  },
  epigraph: {
    color: '#ada9a9',
    margin: '1em auto',
  },
  mainContentContainer: {
    width: '92%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    padding: '0 8rem',
  },
  mainImg: {
    width: '100%',
    height: '16em',
    background: '#969696',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'start',
    width: '16em',
    marginTop: '2em',
  },
  switchContainer: {
    margin: '2em 0',
  },
  textInput: {
    marginTop: '2em',
    padding: '1.4em 0',
    boxSizing: 'border-box',
  },
  // Form classes
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    boxShadow: 'none',
  },
  input: {
    margin: '2em 4em',
    boxSizing: 'border-box',
  },
  switchInput: {
    marginLeft: '4em',
    boxSizing: 'border-box',
  },
};

const getColumns = (classes) => [
  {
    source: 'courses',
    editable: true,
    value: 'puesto1',
    editConfig: {
      name: 'puesto1',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      placeholder: 'Puesto 1',
      displayAttribute: ['title', 'status'],
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto2',
    editConfig: {
      name: 'puesto2',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 2',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto3',
    editConfig: {
      name: 'puesto3',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 3',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto4',
    editConfig: {
      name: 'puesto4',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 4',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto5',
    editConfig: {
      name: 'puesto5',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 5',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto6',
    editConfig: {
      name: 'puesto6',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 6',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto7',
    editConfig: {
      name: 'puesto7',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 7',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto8',
    editConfig: {
      name: 'puesto8',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 8',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto9',
    editConfig: {
      name: 'puesto9',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 9',
      className: classes.input,
    },
  },
  {
    source: 'courses',
    editable: true,
    value: 'puesto10',
    editConfig: {
      name: 'puesto10',
      type: EditInputType.Object,
      model: EditInputObjectModel.Courses,
      displayAttribute: 'title',
      placeholder: 'Puesto 10',
      className: classes.input,
    },
  },
];

const CourseFormTopTen = ({
  classes,
  form,
  results,
  onSubmit,
  onAttributeChange,
  title,
  loading,
  onGoBack,
}) => {

  const handleFormChanges = async (name, value) => {
    onAttributeChange({
      ...form,
      [name]: value,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContentContainer}>
        <AdminResourceForm
          title={title}
          keyName={'course-top'}
          form={{
            ...form,
          }}
          isValid={results.isValid}
          errors={results.errors}
          loading={loading}
          attributes={{ keys: getColumns(classes) }}
          onSubmit={onSubmit}
          onGoBack={onGoBack}
          onAttributeChange={handleFormChanges}
          customClasses={classes}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CourseFormTopTen);
