import {
  QUESTIONS_TYPES_READ_START,
  QUESTIONS_TYPES_READ_END,
  QUESTIONS_TYPES_READ_FAILED,
} from '../../actions'

let initialState = {
  error: null,
  loading: false,
  items: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case QUESTIONS_TYPES_READ_END:
      const { items } = action.payload
      return {
        loading: false,
        items,
        error: null,
      }
    case QUESTIONS_TYPES_READ_START:
      return {
        items: [],
        error: null,
        loading: true,
      }
    case QUESTIONS_TYPES_READ_FAILED:
      const { message } = action.payload
      return {
        items: [],
        error: message,
        loading: false,
      }
    default:
      return { ...state, error: null }
  }
}
