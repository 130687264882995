import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  SnackbarType,
  AdminItemViewType,
  CommissionStatusName,
  CommissionStatusDescription,
  Modalities,
} from '../../../config/constants'
import ListIcon from '@material-ui/icons/People'
import LinkIcon from '@material-ui/icons/Link'
import api from '../../../api/api'
import moment from 'moment'
import FileSaver from 'file-saver'
import SvgIcon from '../../../components/ui/SvgIcon'

const KEY_NAME = 'reports/commissions'

class AdminReportsCommissionsContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  onRenderFacilitador = item => {
    let facilitator = item.facilitators.map(c => {
      return c.description
    })
    return facilitator.join(', ')
  }

  onRenderSites = item => {
    let facilitator = item.place.map(c => {
      return c.institution
    })
    return facilitator.join(', ')
  }

  onRenderStatus = item => {
    return CommissionStatusName[item.status]
  }

  getColumns = () => {
    const { me } = this.props

    let columns = [
      { title: 'Taller', source: 'external_course.name' },
      { title: 'Id comisión', source: 'id' },
      { title: 'Fecha de inicio', source: 'start_date', type: AdminItemViewType.Datetime },
      { title: 'Fecha de fin', source: 'end_date', type: AdminItemViewType.Datetime },
      { title: 'Duración (min)', source: 'duration' },
      { title: 'Cupo mínimo', source: 'minimum_quota' },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderStatus,
      },
      {
        title: 'Modalidad',
        source: 'modality',
        type: AdminItemViewType.Conditional,
        conditions: Modalities.map(m => ({
          condition: `${m.id}`,
          result: m.description,
        })),
      },
      { title: 'Cantidad de inscriptos', source: 'registered_count' },
      {
        title: 'Institución',
        source: 'place',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderSites,
      },
      { title: 'Plataforma',
        source: 'platform.name_type'
      },
      {
        title: 'Facilitadores',
        source: 'facilitators',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderFacilitador,
      },
      {
        title: 'Cantidad de clases',
        source: 'commission_dates_count',
        type: AdminItemViewType.Number,
      },
      {
        title: 'Cantidad de requeridas',
        source: 'assistance_required',
        type: AdminItemViewType.Number,
      },
    ]
    return columns
  }

  getActions = item => {
    const actions = []

    actions.push({
      title: 'DESCARGAR INSCRIPTOS',
      action: this.onDownloadFacilitators,
      icon: <ListIcon />,
    })

    actions.push({
      title: 'COPIAR LINK',
      action: item =>
        navigator.clipboard.writeText(
          `${window.location.origin}/profile?showPresent=1&commissionId=${item.id}`,
        ),
      icon: <LinkIcon />,
    })

    actions.push({
      title: 'QR',
      action: this.onQRItem,
      icon: <SvgIcon iconName={'ic_qrcode'} style={{ fontSize: 16 }} aria-hidden={true} />,
    })
    return actions
  }

  onQRItem = async item => {
    var newDateFormat = item.start_date.replace(/(\d+[/])(\d+[/])/, '$2$1')
    var newDate = moment(new Date(newDateFormat)).format('DDMMYYYY HH:mm')
    await api.Admin.downloadCommissionQr(item.id).then(file =>
      FileSaver.saveAs(file, `QR ${item.external_course.name} ${newDate}.pdf`),
    )
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props

    downloadReport(query, KEY_NAME)
  }

  onDownloadFacilitators = async item => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(
      query,
      `${KEY_NAME}/${item.id}/users`,
      `Inscriptos-${item.external_course.name}-${item.id}`,
    )
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.PublicExternalCourses,
        displayAttribute: 'name',
        name: 'externalCourse.id',
        placeholder: 'Nombre del Taller',
        format: FiltersFormat.In,
        multiple: true,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.CommissionPlaces,
        displayAttribute: 'name_type',
        name: 'commission.site',
        placeholder: 'Lugar',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Facilitators,
        displayAttribute: 'description',
        name: 'facilitator.id',
        placeholder: 'Facilitador',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Date,
        name: 'commission.startDateFrom',
        placeholder: 'Fecha Desde',
        format: FiltersFormat.MayorIgual,
        helpText: 'Fecha inicio Desde',
        queryName: 'd.startDate',
      },
      {
        type: EditInputType.Date,
        name: 'commission.startDateTo',
        placeholder: 'Fecha Hasta',
        format: FiltersFormat.MenorIgual,
        helpText: 'Fecha inicio Hasta',
        queryName: 'd.startDate',
      },
      {
        type: EditInputType.Date,
        name: 'commission.registrationDeadline',
        placeholder: 'Fecha de cierre de inscripción',
        helpText: 'Fecha de cierre de inscripción',
        format: FiltersFormat.Fecha,
      },
      {
        type: EditInputType.Select,
        name: 'commission.status',
        placeholder: 'Estado',
        format: FiltersFormat.Plain,
        options: CommissionStatusDescription.map(csd => ({
          id: csd.id,
          description: csd.description,
        })),
      },
    ]

    return response
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE COMISIONES POR TALLER'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsCommissionsContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer

  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsCommissionsContainer)
