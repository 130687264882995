/* eslint-disable no-case-declarations */
import {
  USER_LOGIN_END,
  USER_LOGIN_FAILED,
  USER_LOGIN_START,
  USER_LOGIN_START_OB,
  USER_LOGIN_START_OB_QR,
  USER_LOGOUT_END,
  USER_LOGOUT_FAILED,
  USER_LOGOUT_START,
  USER_READ_START,
  USER_READ_END,
} from '../actions';

const initialState = {
  error: null,
  loading: false,
  user: null,
  token: null,
};

// user reducer: manage login & logout state changes
export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_START:
    case USER_LOGIN_START_OB:
    case USER_LOGIN_START_OB_QR:
    case USER_LOGOUT_START:
      return {
        ...state,
        user: null,
        loading: true,
        error: null,
      };
    case USER_LOGIN_FAILED:
    case USER_LOGOUT_FAILED:
      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        user: null,
        error: message,
      };
    case USER_LOGIN_END: {
      return {
        user: null,
        error: null,
        loading: false,
      };
    }
    case USER_LOGOUT_END:
      return {
        ...state,
        user: null,
        error: null,
        loading: false,
      };
    case USER_READ_START:
      return {
        ...state,
        user: null,
        error: null,
        loading: true,
      };
    case USER_READ_END:
      const { user } = action.payload;
      return {
        ...state,
        user,
        error: null,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
