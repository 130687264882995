/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'

const QuizTriviaTab = ({ errors, index, questionType }) => (
  <div className="row" style={{ justifyContent: 'space-between', gap: '0.5rem' }}>
    <Typography>
      <span style={{ fontWeight: 600 }}>{index + 1}</span> -{' '}
      {questionType?.description || 'Sin asignar'}
    </Typography>
    {errors !== undefined && !errors?.length && <CheckCircle />}
  </div>
)

export default QuizTriviaTab
