import {
  MY_CAREER_PLANS_START,
  MY_CAREER_PLANS_END,
  MY_CAREER_PLANS_FAILED,
  MY_PREVIOUS_CAREER_PLANS_START,
  MY_PREVIOUS_CAREER_PLANS_END,
  MY_PREVIOUS_CAREER_PLANS_FAILED,
  DETAIL_CAREER_PLANS_START,
  DETAIL_CAREER_PLANS_END,
  DETAIL_CAREER_PLANS_FAILED,
} from '../../actions';

//import MockCurrentCareerPlans from './mockCurrentCareerPlans';
const initialState = {
  loading: true,
  error: false,
  currentCareerPlans: {},
  previousCareerPlans: {},
};

function careerPlansCoursesReducers(state = initialState, action) {
  switch (action.type) {
    case MY_CAREER_PLANS_START: {
      // TODO: retrieves all data temporaly while has been created services get courses service 
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case MY_CAREER_PLANS_END: {
      // TODO: retrieves all data temporaly while has been finished services get courses service api
      return {
        ...state,
        loading: false,
        error: false,
        currentCareerPlans: action.payload,
      };
    }
    case MY_CAREER_PLANS_FAILED: {
      // TODO: retrieves all data temporaly while has been failed services get courses service api
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case MY_PREVIOUS_CAREER_PLANS_START: {
      // TODO: retrieves all data temporaly while has been created services get courses service api
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case MY_PREVIOUS_CAREER_PLANS_END: {
      // TODO: retrieves all data temporaly while has been finished services get courses service api
      return {
        ...state,
        loading: false,
        error: false,
        previousCareerPlans: action.payload,
      };
    }
    case MY_PREVIOUS_CAREER_PLANS_FAILED: {
      // TODO: retrieves all data temporaly while has been failed services get courses service api
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case DETAIL_CAREER_PLANS_START: {
      // TODO: retrieves all data temporaly while has been created services get courses service api
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case DETAIL_CAREER_PLANS_END: {
      // TODO: retrieves all data temporaly while has been finished services get courses service api
      return {
        ...state,
        loading: false,
        error: false,
        detailCareerPlans: action.payload,
      };
    }
    case DETAIL_CAREER_PLANS_FAILED: {
      // TODO: retrieves all data temporaly while has been failed services get courses service api
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
}

export default careerPlansCoursesReducers;
