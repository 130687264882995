import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { getResource, importUsersFromCSV } from '../../../actions/admin'
import { appLoadToken } from './../../../actions/app'
import { loadUsersForLot } from './../../../actions/lotAdmin'
import { getSelectedUsers } from '../../../actions/user'
import FileInput from '../../../components/admin/inputs/FileInput'
import Loading from '../../../components/ui/Loading'
import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'
import { Grid, TableRow, TableCell, Checkbox, Button } from '@material-ui/core'
import classNames from 'classnames'
import * as _ from 'lodash'
import UsersSelectedModal from '../../../components/admin/external_courses/UsersSelectedModal'

const styles = () => ({
  root: {
    padding: '20px 0',
  },
  opcionalSelect: {},
  footerButtons: {
    paddingTop: '3em',
  },
  button: {
    margin: '1em',
    padding: '1em 2em',
    borderRadius: '5px',
    border: '1px solid #575454',
    maxWidth: '16em',
  },
})

const AdminExternalCoursesCommissionsStepUserAssignation = ({
  classes,
  users,
  usersSelected,
  handleChangeUsers,
  appLoadToken,
  loadUsersForLot,
  importUsersFromCSV,
  lotId,
  onSubmit,
  handleBack,
  getSelectedUsers,
  course,
  prevSelectedUsers,
  isEdit,
}) => {
  const [state, updateState] = useState({
    page: 0,
    count: 1,
    rowsPerPage: 20,
    data: [],
    columns: [],
    isLoading: false,
    userResponse: [],
    showUserImport: false,
  })

  const [searchTimmer, updateSearchTimmer] = useState({ tempSearchInput: '', typingTimeout: 0 })
  const [searchText, setSearchText] = useState('')
  const [usersSelectedModalIsOpen, setUsersSelectedModalIsOpen] = useState(false)
  const [prevSelectedUsersDone, setPrevSelectedUsersDone] = useState(false)
  const [prevUsersSetted, setPrevUsersSetted] = useState(false)

  const handleOpenUsersSelectedModal = () => {
    setUsersSelectedModalIsOpen(true)
  }

  const handleCloseUsersSelectedModal = () => {
    setUsersSelectedModalIsOpen(false)
  }

  useEffect(() => {
    updateState({
      ...state,
      data:
        state.page === 0
          ? _.uniqBy([...state.userResponse, ...(users.data || [])], 'id')
          : users.data || [],
      columns: users.columns || [],
      count: users.metadata ? parseInt(users.metadata.pagination.total) || 1 : 1,
    })
  }, [users])

  useEffect(() => {
    if (!isEdit && !prevSelectedUsersDone && !prevUsersSetted) {
      handleChangeUsers([])
      setPrevUsersSetted(true)
      setPrevSelectedUsersDone(true)
    } else if (course?.item?.assignations?.users && !prevSelectedUsersDone) {
      if (course.item.assignations.users.length) {
        getSelectedUsers(course?.item?.assignations?.users)
      }
      setPrevSelectedUsersDone(true)
    }
  }, [course])

  useEffect(() => {
    if (
      prevSelectedUsers &&
      !!prevSelectedUsers?.items?.length &&
      prevSelectedUsersDone &&
      !prevUsersSetted
    ) {
      handleChangeUsers(prevSelectedUsers.items)
      setPrevUsersSetted(true)
    }
  }, [course])

  useEffect(() => {
    if (
      prevSelectedUsers &&
      !!prevSelectedUsers?.items?.length &&
      prevSelectedUsersDone &&
      !prevUsersSetted
    ) {
      handleChangeUsers(prevSelectedUsers.items)
      setPrevUsersSetted(true)
    }
  }, [prevSelectedUsers])

  //FUNCIONES ANTERIORES
  const handleClick = user => {
    let hasUser = isRowSelected(user.id)
    let newUsersSelected = [...usersSelected]
    if (hasUser) {
      newUsersSelected = newUsersSelected.filter(el => el.id !== user.id)
    } else {
      newUsersSelected.push(user)
    }
    handleChangeUsers(newUsersSelected)
  }

  //FUNCIONES NUEVAS
  const onLoadData = useCallback(
    async (page = 0, query = false) => {
      try {
        updateState({ ...state, page, isLoading: true })
        var filters = lotId ? `lot=${lotId}&` : ''
        query = query || searchText

        appLoadToken().then(() => {
          if (query)
            filters += `filters[OR][user.id][LIKE]=${query}&filters[OR][user.firstName][LIKE]=${query}&filters[OR][user.lastName][LIKE]=${query}&filters[OR][user.username][LIKE]=${query}&filters[OR][user.email][LIKE]=${query}&`

          filters += `pagination[size]=${state.rowsPerPage}&pagination[page]=${page + 1}`

          loadUsersForLot(encodeURI(filters))
        })
      } catch (error) {
        console.error(error)
      }
    },
    [searchText],
  )

  useEffect(() => {
    onLoadData(state.page)
  }, [onLoadData])

  const changePage = page => {
    onLoadData(page)
  }

  const onTextSearchChange = useCallback(
    searchQuery => {
      if (searchTimmer.typingTimeout) {
        clearTimeout(searchTimmer.typingTimeout)
      }
      updateSearchTimmer({
        tempSearchInput: searchQuery,
        typingTimeout: setTimeout(() => {
          setSearchText(searchQuery)
        }, 850),
      })
    },
    [searchTimmer],
  )

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader()
        reader.onload = function () {
          resolve(reader.result)
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.readAsDataURL(file)
      } catch (e) {
        console.log(e)
      }
    })
  }

  const handleShowImport = () => {
    updateState({
      ...state,
      showUserImport: true,
    })
  }

  const onImportUsers = async (name, file) => {
    const reader = await getBase64(file[0])
    const response = await importUsersFromCSV({ file: [reader] })
    const userResponse = JSON.parse(response.users)
    const newUsers = userResponse.map(el => el)
    const currentUsersSelected = _.uniqBy([...usersSelected, ...newUsers], 'id')
    handleChangeUsers(currentUsersSelected)
  }

  const isRowSelected = id => {
    if (usersSelected.length === 0) return false
    return !!usersSelected.find(element => element.id === id)
  }

  const customRowRender = row => {
    let [id, username, first_name, last_name, email] = row
    let isSelected = isRowSelected(id)

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        key={'user' + id}
        selected={isSelected}
      >
        <TableCell
          padding="checkbox"
          onClick={() => handleClick({ id, username, first_name, last_name, email })}
        >
          <Checkbox checked={isSelected} color={'primary'} />
        </TableCell>
        <TableCell align="left">{id}</TableCell>
        <TableCell align="left">{username}</TableCell>
        <TableCell align="left">{first_name}</TableCell>
        <TableCell align="left">{last_name}</TableCell>
        <TableCell align="left">{email}</TableCell>
      </TableRow>
    )
  }

  const options = options => ({
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: 'id',
      direction: 'asc',
    },
    sort: true,
    filter: false,
    fixedHeader: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: 'none',
    serverSide: true,
    count: state.count,
    rowsPerPage: state.rowsPerPage,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: state.isLoading ? <Loading dark={true} /> : 'No se encontraron resultados',
      },
    },
    onTableChange: (action, tableState) => {
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case 'changePage':
          changePage(tableState.page)
          break
        case 'sort':
          break
        default:
      }
    },
    onSearchChange: searchQuery => {
      onTextSearchChange(searchQuery)
    },
    onSearchClose: () => {
      setSearchText(false)
    },
    customSort: (sortData, colIndex, order) => {
      return sortData.sort((a, b) => {
        var dir = order === 'asc' ? 1 : -1
        var isASelected = state.data.find(() => {
          return !usersSelected.find(element => element.id === a.data[0])
        })
        var isBSelected = state.data.find(() => {
          return !usersSelected.find(element => element.id === b.data[0])
        })
        if (isASelected && !isBSelected) return dir
        if (!isASelected && isBSelected) return -1 * dir
        return (a[colIndex] > b[colIndex] ? -1 : 1) * dir
      })
    },
    customRowRender,
    ...options,
  })

  useEffect(() => {
    if (usersSelected.length < 1 && usersSelectedModalIsOpen) {
      handleCloseUsersSelectedModal()
    }
  }, [usersSelected])

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MUIDataTable
            title={'Usuarios asociados'}
            data={state.data}
            columns={state.columns}
            options={options()}
          />
          {state.showUserImport && (
            <FileInput
              tabIndex={0}
              name="file"
              accept=".csv"
              onChange={onImportUsers}
              style={{ marginTop: '3%' }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classNames(classes.form, classes.footerButtons)}>
        <Grid className={classes.wrapperBoton}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBack}
            className={classes.button}
          >
            Atrás
          </Button>
          <Button
            disabled={!usersSelected || usersSelected?.length === 0}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            className={classes.button}
          >
            Guardar
          </Button>
          <Button
            disabled={state.showUserImport}
            variant="contained"
            color="primary"
            onClick={handleShowImport}
            className={classes.button}
          >
            Importar usuarios
          </Button>
          <Button
            disabled={!usersSelected || usersSelected?.length === 0}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOpenUsersSelectedModal}
          >
            Ver seleccionados
          </Button>
        </Grid>
      </Grid>
      {!!usersSelected.length && (
        <UsersSelectedModal
          usersSelected={usersSelected}
          open={usersSelectedModalIsOpen}
          columns={state.columns}
          onClose={handleCloseUsersSelectedModal}
          options={options}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  const states = state.admin.resource
  return {
    users: state.lotAdminReducer.users,
    course: states['admin-commission-edit'],
    prevSelectedUsers: state.selectedUsersReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { loadUsersForLot, appLoadToken, getResource, importUsersFromCSV, getSelectedUsers },
    dispatch,
  )
}

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(AdminExternalCoursesCommissionsStepUserAssignation),
  ),
)
