import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ZoomGrid from '../ui/ZoomGrid'
import { withStyles } from '@material-ui/core/styles'
import Loading from '../ui/Loading'
import ParallaxBackground from '../ui/ParallaxBackground'
import MixedCourseItem from '../ui/MixedCourseItem'

const styles = () => ({
  root: {
    minHeight: 600,
    display: 'flex',
    flexFlow: 'column',
    paddingTop: 32,
    paddingBottom: 50,
  },
})

class SearchContent extends Component {
  onCreateItemCourse = item => {
    const { onShowCourse, onRemoveSharedCourse } = this.props
    return (
      <MixedCourseItem
        item={item}
        itemClick={onShowCourse}
        onRemoveSharedCourse={onRemoveSharedCourse}
      />
    )
  }

  render() {
    const { classes, items, loading } = this.props

    return (
      <main id="main" className={classes.root} tabIndex={-1}>
        <ParallaxBackground />
        {loading ? (
          <Loading />
        ) : (
          <ZoomGrid
            ariaLabel={'Resultados de búsqueda'}
            items={items}
            itemCreator={this.onCreateItemCourse}
            itemHeight={165}
            itemWidth={290}
          />
        )}
      </main>
    )
  }
}

SearchContent.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      required: PropTypes.bool,
      comments_enabled: PropTypes.bool,
      nps_score: PropTypes.number,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      published_at: PropTypes.string,
      status: PropTypes.number,
      rates_count: PropTypes.number,
      total_activities: PropTypes.number,
      duration: PropTypes.null,
      user_courses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          total_activities_done: PropTypes.number,
          completed: PropTypes.bool,
          completed_at: PropTypes.string,
        }),
      ),
      user_course_ratings: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          rating: PropTypes.number,
          comment: PropTypes.string,
          created_at: PropTypes.string,
        }),
      ),
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
  ),
  loading: PropTypes.bool.isRequired,
}

SearchContent.defaultProps = {
  items: [],
}

export default withStyles(styles)(SearchContent)
