import React, { useEffect, useCallback, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goTo } from '../../actions/navigator'
import Profile from '../../components/profile/Profile'
import { loadProfile, downloadReportXls } from '../../actions/user'
import { goToDetailsCareerPlans } from '../../actions/navigator'
import { appLoadToken } from '../../actions/app'
import { addSnackbar } from '../../actions/snackbar'
import {
  COURSES_VIEW,
  DETAILS_CAREER_PLANS,
  EXTERNAL_COURSES_VIEW,
  PROFILE_ME,
  router,
} from '../../config/routes'
import { CourseType, SnackbarType } from '../../config/constants'
import api from '../../api/api'
import CreateExternalCourseReviewDialog from '../../components/externalCourses/CreateExternalCourseReviewDialog'
import CreatePresentDialog from '../../components/profile/modals/CreatePresentDialog'
import DocumentTitle from '../../components/ui/DocumentTitle'

const ProfileContainer = ({
  appLoadToken,
  loadProfile,
  goTo,
  addSnackbar,
  downloadReportXls,
  user,
  profile,
  search,
}) => {
  const [dialogState, updateDialogState] = useState({ open: false, commissionId: null, rating: 0 })
  const [presentDialogState, updatePresentDialogState] = useState({
    open: false,
    commissionId: null,
  })

  useEffect(() => {
    appLoadToken().then(() => {
      loadProfile()
      const params = new URLSearchParams(search)
      const commissionId = params.get('commissionId')
      const showPresentDialog = params.get('showPresent') || 0
      const rating = params.get('rating') || 0

      if (commissionId && !showPresentDialog) {
        updateDialogState({ open: true, commissionId: commissionId, rating: rating })
      }
      if (commissionId) {
        updatePresentDialogState({ open: true, commissionId: commissionId })
      }
    })
  }, [appLoadToken, loadProfile, updateDialogState])

  const showCourse = useCallback(
    course => {
      if (course.type === CourseType.ExternalCourse) {
        goTo(router.getRoute(EXTERNAL_COURSES_VIEW, { id: course.id }))
      } else {
        goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
      }
    },
    [goTo],
  )

  const unsubscribeFromCommission = useCallback(
    async commissionId => {
      try {
        await api.ExternalCourses.unsubscribeCommission(commissionId)
        loadProfile()
        addSnackbar(
          `Inscripción cancelada, recordá eliminar la cita de tu calendario.`,
          SnackbarType.Success,
        )
      } catch (e) {
        addSnackbar('Error: ' + e.message, SnackbarType.Error)
      }
    },
    [loadProfile, addSnackbar],
  )

  const rateCommission = useCallback(
    async commissionId => {
      updateDialogState({ open: true, commissionId: commissionId, rating: 0 })
    },
    [updateDialogState],
  )

  const handleNavigateGoToAllCareerPlans = useCallback(id => {
    window.open(router.getRoute(DETAILS_CAREER_PLANS, { planId: id }))
  }, [])

  const handleDonwloadXls = useCallback(
    (data, header) => {
      downloadReportXls(data, header)
    },
    [downloadReportXls],
  )

  const onDismissDialog = useCallback(() => {
    updateDialogState({ open: false, commissionId: null, rating: 0 })
  }, [updateDialogState])

  const onDismissPresentDialog = useCallback(() => {
    updatePresentDialogState({ open: false, commissionId: null })
  }, [updatePresentDialogState])

  const onSubmitRating = useCallback(
    async data => {
      try {
        await api.My.ExternalCourses.rate(dialogState.commissionId, data)
        addSnackbar(`Valoraste el taller`, SnackbarType.Success)
      } catch (e) {
        addSnackbar(e.message, SnackbarType.Error)
      }
      onDismissDialog()
      goTo(PROFILE_ME)
    },
    [dialogState, updateDialogState, loadProfile, goTo, addSnackbar, onDismissDialog],
  )

  return (
    <React.Fragment>
      <DocumentTitle title={'Perfil | Academia'} />
      <Profile
        user={user}
        profile={profile}
        onShowCourse={showCourse}
        onShowCarrerPlan={handleNavigateGoToAllCareerPlans}
        onDownloadXls={handleDonwloadXls}
        onUnsusbcribeFromCommission={unsubscribeFromCommission}
        onRateCommission={rateCommission}
      />
      <CreateExternalCourseReviewDialog
        open={dialogState.open}
        onDismiss={onDismissDialog}
        onSubmit={onSubmitRating}
        initialRating={dialogState.rating}
      />
      <CreatePresentDialog
        open={presentDialogState.open}
        onDismiss={onDismissPresentDialog}
        onSubmit={onSubmitRating}
        data={presentDialogState}
        onReview={commissionId => updateDialogState({ open: true, commissionId: commissionId })}
      />
    </React.Fragment>
  )
}

const mapStateToProps = ({ userReducer, profileReducer }) => {
  return { ...userReducer, ...profileReducer }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { appLoadToken, loadProfile, goTo, goToDetailsCareerPlans, downloadReportXls, addSnackbar },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
