import React, { useState, useCallback, useRef } from 'react';
import { Typography, Dialog } from '@material-ui/core';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import EventListenerWrapper from '../ui/EventListenerWrapper';
import TextField from '@material-ui/core/TextField';
import { create_playlist_theme } from '../../themes/create_playlist_theme';
const styles = theme => ({
  root: {
    width: '100%',
  },
  paperDialog: {
    borderRadius: 0,
    backgroundColor: '#101010',
    paddingTop: 8,
    paddingBottom: 8,
  },
  paperWidthSm: {
    maxWidth: 700,
  },

  playlistRow: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: 'solid 0.5px #707070',
    cursor: 'pointer',
    paddingRight: 32,
    paddingLeft: 32,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  playlistName: {
    color: '#ffffff',
    fontSize: 16,
    flexGrow: 1,
  },

  checkIconChecked: {
    color: '#1ed1a8',
    fontSize: 20,
  },

  checkIconUnchecked: {
    display: 'none',
  },

  addIcon: {
    color: '#ffffff',
    fontSize: 20,
    marginRight: 8,
  },
});

const PlaylistItem = ({ classes, playlist, onClick = () => {} }) => {
  return (
    <EventListenerWrapper
      role="button"
      aria-label={playlist.checked ? 'Quitar de la lista' : 'Agregar a la lista'}
      tabIndex={0}
      key={'playlist-item-' + playlist.id}
      className={classes.playlistRow}
      onClick={() => onClick(playlist)}
      onEnter={() => onClick(playlist)}
    >
      <Typography className={classes.playlistName} key={'playlist-' + playlist.id} component={'p'}>
        {playlist.name}
      </Typography>

      <CheckCircleIcon
        className={playlist.checked ? classes.checkIconChecked : classes.checkIconUnchecked}
      />
    </EventListenerWrapper>
  );
};

const CreatePlaylistItem = ({ classes, onCreatePlaylist }) => {
  const [name, updateName] = useState('');
  const hiddenEl = useRef(null);
  const createPlaylist = useCallback(() => {
    if (name.trim().length > 0) {
      onCreatePlaylist(name);
      updateName('');
      hiddenEl.current.focus();
    }
  }, [name, onCreatePlaylist, updateName, hiddenEl]);
  return (
    <div className={classes.playlistRow}>
      <AddIcon className={classes.addIcon} />
      <ThemeProvider theme={create_playlist_theme}>
        <EventListenerWrapper
          TagType={TextField}
          label="CREAR LISTA DE APRENDIZAJE"
          fullWidth={true}
          value={name}
          onEnter={() => createPlaylist()}
          onChange={e => updateName(e.target.value)}
        />
        <div aria-hidden={true} ref={hiddenEl} tabIndex={-1}></div>
      </ThemeProvider>
      {name.trim().length > 0 && (
        <KeyboardReturnIcon onClick={() => createPlaylist()} className={classes.addIcon} />
      )}
    </div>
  );
};

const PlaylistCourseDialog = ({
  classes,
  playlists,
  open,
  handleClose,
  onExit,
  onPlaylistClicked,
  onCreatePlaylist,
}) => {
  return (
    <Dialog
      onBackdropClick={handleClose}
      onEscapeKeyDown={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      className={classes.root}
      classes={{
        paper: classes.paperDialog,
        paperWidthSm: classes.paperWidthSm,
      }}
      open={open}
      onExit={onExit}
    >
      <div>
        <CreatePlaylistItem
          key={'create-playlist-item'}
          classes={classes}
          onCreatePlaylist={onCreatePlaylist}
        />
        {Array.isArray(playlists) && playlists.map((playlist, pIndex) => (
          <PlaylistItem
            key={'playlist-item-' + pIndex}
            playlist={playlist}
            classes={classes}
            onClick={onPlaylistClicked}
          />
        ))}
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(PlaylistCourseDialog);

