import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  createResource,
  updateResource,
  getResource,
} from '../../../actions/admin';
import {
  EditInputType,
  SnackbarType,
  RolList,
} from '../../../config/constants';
import AdminResourceForm from '../../../components/admin/AdminResourceForm';
import { addSnackbar } from '../../../actions/snackbar';
import { ADMIN_USERS } from '../../../config/routes';
import { goTo } from '../../../actions/navigator';
import { isOpenBankUser } from './../../../utils/AdminHelper'

class AdminUsersFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      record: '',
      first_name: '',
      last_name: '',
      email: '',
      job_position_code: "",
      job_position_name:'',
      org_unity_identifier:'',
      org_unity_name:'',
      roles_as_string:''
    },
    errors: {
     record: '',
      first_name: '',
      last_name: '',
      email: '',
      job_position_code: "",
      job_position_name:'',
      org_unity_identifier:'',
      org_unity_name:'',
      roles_as_string:''
    },
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;

    getResource(keyName, type, params.id);

  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.item && nextProps.item) {
      const {
        record,
        first_name,
        last_name,
        email,
        job_position_code,
        job_position_name,
        org_unity_identifier,
        org_unity_name,
        roles_as_string,
        roles,
      } = nextProps.item;
      this.setState(
        {
          form: {
            record:record || '',
            first_name:first_name || '',
            last_name:last_name || '',
            email:email || '' ,
            job_position_code:job_position_code || '',
            job_position_name:job_position_name || '',
            org_unity_identifier:org_unity_identifier || '',
            org_unity_name:org_unity_name || '',
            roles_as_string:roles_as_string || '',
            roles:roles || [],
          },
        },
        () => {
          this.validateForm();
        }
      );
    }
  };

  onAttributeChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } }, () =>
      this.validateForm()
    );
  };

  getColumns = () => {
    const { me } = this.props

    const optionsRoles = isOpenBankUser(me.user)
      ? RolList.filter((option) => (option.value.includes('OPEN_BANK')))
      : RolList.filter((option) => (!option.value.includes('OPEN_BANK')))
  
    const columns = [
      {
        title: 'Usuario',
        source: 'record',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'record' },
      },
      {
        title: 'Nombre',
        source: 'first_name',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'first_name' },
      },
      {
        title: 'Apellido',
        source: 'last_name',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'last_name' },
      },
       {
        title: 'Email',
        source: 'email',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'email' },
      },
      {
        title: 'Código del Puesto',
        source: 'job_position_code',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'job_position_code' },
      },
      {
        title: 'Nombre del Puesto',
        source: 'job_position_name',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'job_position_name' },
      },
      {
        title: 'Código del Centro de Costos',
        source: 'org_unity_identifier',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'org_unity_identifier' },
      },
      {
        title: 'Centro de Costos',
        source: 'org_unity_name',
        editable: false,
        editConfig: { type: EditInputType.Text, name: 'org_unity_name' },
      },
      {
        title: 'Roles',
        source: 'roles',
        editable: true,
        editConfig: {
          type: EditInputType.MultipleSelect,
          name: 'roles',
          max:5,
          multiple:true,
          options: optionsRoles.map((t) => {
            return { id: t.value, description: t.title};
          }),
        },
      },
    ];

    return {
      keys: [
        ...columns,
      ]
    }
  };

  getRequestDataFromForm = async () => {
    const { form } = this.state;
    const { me } = this.props
    const data =  isOpenBankUser(me.user)
      ?{
        roles:form.roles,
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
      }
      :{
      name: form.name,
      record: form.record,
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      job_position_code: form.job_position_code,
      job_position_name:form.job_position_name,
      org_unity_identifier:form.org_unity_identifier,
      org_unity_name:form.org_unity_name,
      roles_as_string:form.roles_as_string,
      roles:form.roles
    }
    return data;
  };

  onSubmit = async () => {
    const {
      addSnackbar,
      goTo,
      createResource,
      updateResource,
      isEdit,
      item,
      type,
    } = this.props;

    const data = await this.getRequestDataFromForm();
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          addSnackbar('Usuario editado exitosamente.', SnackbarType.Success);
          goTo(ADMIN_USERS);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    } else {
      createResource(type, data)
        .then(() => {
          addSnackbar('Usuario creado exitosamente.', '4');
          goTo(ADMIN_USERS);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    }
  };

  validateForm = () => {
    let error = false;
    let errors = {
      record: '',
      first_name: '',
      last_name: '',
      email: '',
      job_position_code: "",
      job_position_name:'',
      org_unity_identifier:'',
      org_unity_name:'',
      roles_as_string:''
    };

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_USERS);
  };

  render() {
    const { loading, title } = this.props;
    return (
      <AdminResourceForm
        keyName={'admin-users-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    );
  }
}

AdminUsersFormContainer.defaultProps = {
  type: 'users',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;

  return {
    ...states[ownProps.keyName],
     me: { ...state.userReducer },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResource, createResource, updateResource, addSnackbar, goTo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersFormContainer);
