import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../../actions/user'
import Loading from '../ui/Loading'
import config from '../../config'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  Input,
  FormGroup,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { login_theme } from '../../themes/login_theme'
import validator from 'validator'
import { withRouter } from 'react-router-dom'
import { SnackbarType } from '../../config/constants'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SnackbarMessage from '../ui/SnackbarMessage'
import { loginOB, loginQr, changuePassOB } from '../../actions/user'
import { withGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(768)
  const mediaSm = theme.breakpoints.down(375)
  return {
    formContainer: {
      minWidth: 300,
      [mediaSm]: {
        minWidth: 200,
      },
    },
    formContainerChangePass: {
      minWidth: 300,
      [mediaSm]: {
        minWidth: 200,
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formGroupContainer: {
      width: 350,
    },
    login: {
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      background: `no-repeat center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    loginContent: {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '4em',
      justifyContent: 'center',
      flexGrow: 2,
    },
    logoAc: {
      width: '80%',
      maxWidth: 357,
    },

    buttonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 340,
      [mediaLg]: {
        flexDirection: 'column',
        minWidth: 300,
      },
      [mediaSm]: {
        minWidth: 200,
      },
      marginTop: 16,
    },
    button: {
      margin: '2px 16px',
      width: '110%',
      height: 56,
      borderRadius: 4,
      background: '#b7366c',
      boxShadow: '0 5px 8.2px 0 rgba(183, 54, 108, 0.25)',
      [mediaLg]: {
        marginTop: 16,
      },
      '&-disabled': {
        background: 'linear-gradient(150deg, #757070, #383232 100%)',
        boxShadow: 'none',
        color: '#444444 !important',
      },
    },
    buttonText: {
      color: '#fff',
      fontWeight: 700,
      fontSize: 20,
    },
    iconButton: {
      color: '#fff',
      marginRight: 16,
      fontSize: 40,
    },
    linkButton: {},
    linkButtonText: {
      color: '#fff',
      fontWeight: 500,
      fontSize: 16,
    },
    mesagge: {
      color: '#fff',
      fontWeight: 600,
      fontSize: 24,
      marginBottom: 20,
    },
    surveyButton: {
      backgroundColor: '#FFC300',
      '&:hover': {
        backgroundColor: '#cc9800',
      },
    },
    error: {
      color: '#ff0000',
    },
  }
}

/**
 * Login OpenBankLogin. Este login es para uso de OpenBankLogin
 * @return render login
 */

const ResetPassOB = ({
  classes,
  loading,
  login,
  changeToView,
}) => {
  const dispach = useDispatch()
  const [firstPassword, setFirstPassword] = useState('')
  const [secondPassword, setSecondPassword] = useState('')
  const [showFirstPassword, setShowFirstPassword] = useState(false)
  const [showSecondPassword, setShowSecondPassword] = useState(false)
  const [isValidChangePass, setIsValidChangePass] = useState(false)
  const [error, setError] = useState(false)
  const [notValidChangePassMessage, setNotValidChangePassMessage] = useState(false);

  useEffect(() => {
    const resultIsValid =
      firstPassword !== null &&
      !validator.isEmpty(firstPassword) &&
      validator.isLength(firstPassword, { min: 8, max: 16 }) &&
      !validator.isAlpha(firstPassword) &&
      validator.isAlphanumeric(firstPassword) &&
      /[A-Z]/.test(firstPassword) &&
      /[a-z]/.test(firstPassword) &&
      secondPassword !== null &&
      !validator.isEmpty(secondPassword) &&
      validator.isLength(secondPassword, { min: 8, max: 16 }) &&
      !validator.isAlpha(secondPassword) &&
      validator.isAlphanumeric(secondPassword) &&
      /[A-Z]/.test(secondPassword) &&
      /[a-z]/.test(secondPassword) &&
      firstPassword === secondPassword;
    setIsValidChangePass(resultIsValid)
  }, [firstPassword, secondPassword])


  const handleFirstPasswordChange = event => {
    setFirstPassword(event.target.value)
  }

  const handleSecondPasswordChange = event => {
    setSecondPassword(event.target.value)
  }

  const handleClickShowFirstPassword = () => {
    setShowFirstPassword(!showFirstPassword)
  }

  const handleClickShowSecondPassword = () => {
    setShowSecondPassword(!showSecondPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handelSubmitChangePass = event => {
    event.preventDefault();
    const response = dispach(changuePassOB({password: firstPassword}))
    response.then(data => {
      if(data){
        changeToView();
      }else{
        setNotValidChangePassMessage(true);
      }
    });
  }

  return (
    <div className={classes.login}>
        <div className={classes.loginContent}>
              <img
                src={config.BASE_URL + 'img/ic_banner_academia.svg'}
                alt="logo banner"
                className={classes.logoAc}
              />
              <div className={classes.formContainerChangePass}>
                <MuiThemeProvider theme={login_theme}>
                  <Typography className={classes.mesagge}>
                    Es necesario cambiar tu contraseña, debe tener mayúsculas, minúsculas y números
                  </Typography>

                  <form onSubmit={handelSubmitChangePass} id={'change-pass'} className={classes.formGroupContainer}>
                    <FormGroup>
                      <FormControl>
                        <InputLabel htmlFor="firstPassword">Introduce tu nueva contraseña</InputLabel>
                        <Input
                          disableUnderline={true}
                          name="firstPassword"
                          value={firstPassword}
                          type={showFirstPassword ? 'text' : 'password'}
                          onChange={handleFirstPasswordChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                color={'primary'}
                                aria-label="Cambiar visibilidad de la contraseña"
                                onClick={handleClickShowFirstPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showFirstPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <InputLabel htmlFor="secondPassword">Introduce nuevamente tu contraseña</InputLabel>
                        <Input
                          disableUnderline={true}
                          name="secondPassword"
                          value={secondPassword}
                          type={showSecondPassword ? 'text' : 'password'}
                          onChange={handleSecondPasswordChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                color={'primary'}
                                aria-label="Cambiar visibilidad de la contraseña"
                                onClick={handleClickShowSecondPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showSecondPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </FormGroup>
                  </form>
                </MuiThemeProvider>
                {notValidChangePassMessage && (
                <SnackbarMessage
                  type={1}
                  open={notValidChangePassMessage}
                  message='Contraseña inválida, debe contener mayúscula, minúscula, numero y mínimo 8 caracteres. No se deben repetir las ultimas 12 contraseñas utilizadas.'
                  onClose={()=>{setNotValidChangePassMessage(false)}}
                />
                )}
                <div className={classes.buttonsContainer} onClick={()=>{!isValidChangePass && setNotValidChangePassMessage(true)}}>
                  <Button
                    disabled={!isValidChangePass}
                    type={'submit'}
                    className={classes.button + (!isValidChangePass ? ' ' + classes.button + '-disabled' : '')}
                    variant="outlined"
                    form="change-pass"
                  >
                    <Typography className={classes.buttonText}>CAMBIAR CONTRASEÑA</Typography>
                  </Button>
                </div>
              </div> 
        </div> 
    </div>
  )
}

const mapStateToProps = (state) => {
  const {error, loading} = state.userReducer;
  return {error, loading};
};

export default connect(
  mapStateToProps,
)(withRouter(withStyles(styles)(ResetPassOB)));