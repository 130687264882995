import { createMuiTheme } from '@material-ui/core';

export const create_playlist_theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#ff0000',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: 16,
        color: '#ffffff',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 500,
        color: '#ffffff',
        '&$focused': {
          color: '#ffffff',
        },
      },
    },
    MuiInputBase: {
      input: {
        color: '#fff',
      },
      root: {
        color: '#fff',
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 500,
        borderBottom: 'none',
        '&$disabled': {
          color: '#fff',
        },
        '&focused': {
          borderBottom: 'none',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#fff',
      },
    },
  },
})
