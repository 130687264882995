import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ZoomGrid from '../ui/ZoomGrid'
import CategoryItem from '../ui/CategoryItem'
import ParallaxBackground from '../ui/ParallaxBackground'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
  },
  categories: {
    marginTop: 32,
    marginBottom: 50,
  },
})

class CategoriesContent extends Component {
  onCreateCategoryItem = (item, onItemClick) => {
    return (
      <CategoryItem item={item} onItemClick={onItemClick} />
    )
  }
  onItemClick = item => {
    const { onCategoryClick } = this.props
    onCategoryClick(item)
  }
  render() {
    const { classes, categories } = this.props

    return (
      <main id="main" className={classes.root}  tabIndex={-1}>
        <ParallaxBackground />
        <div className={classes.categories}>
          <ZoomGrid
            ariaLabel={'Listado de categorías'}
            items={categories}
            itemCreator={(item, itemClick) => this.onCreateCategoryItem(item, itemClick)}
            itemHeight={110}
            itemWidth={290}
            itemClick={this.onItemClick}
          />
        </div>
      </main>
    )
  }
}

CategoriesContent.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      color: PropTypes.string,
      visible: PropTypes.bool,
      sort: PropTypes.number,
      icon: PropTypes.string,
      featured: PropTypes.null,
    }),
  ),
}

CategoriesContent.defaultProps = {
  items: [],
}

export default withStyles(styles)(CategoriesContent)
