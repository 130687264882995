import React, { useCallback, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import ResolverWrapper from './components/ResolverWrapper'
import './styles.css'
import { blankFieldSetter } from '../../../../../utils/ActivitiesHelper'
import Loading from '../../../../ui/Loading'
import EditableSpan from './components/EditableSpan'
import { addSnackbar } from '../../../../../actions/snackbar'
import { useDispatch } from 'react-redux'
import { SnackbarType } from '../../../../../config/constants'

const FillInTheBlanksResolver = ({
  question,
  payload,
  setPayload,
  activityState,
  correctlyAnswered,
}) => {
  const notificated = sessionStorage.getItem('fillTheBlanksNotificated')
  const [strings, setStrings] = useState()
  const [correctAnswerStrings, setCorrectAnswerStrings] = useState()
  const dispatch = useDispatch()

  const notificate = (message, type) => {
    addSnackbar(message, type)(dispatch)
  }

  const valueSetter = (value, index) => {
    setStrings(state =>
      state.map((string, stringIndex) => (index === stringIndex ? value : string)),
    )
  }

  const optionBuilder = useCallback(
    (value, index) =>
      index % 2 ? (
        <EditableSpan
          key={'field-' + value + '-' + index}
          valueSetter={valueSetter}
          index={index}
          value={value}
          done={activityState.done}
          isCorrect={
            correctAnswerStrings
              ? !correctAnswerStrings[index].localeCompare(value, undefined, {
                  sensitivity: 'base',
                })
              : undefined
          }
          onFocus={() => {
            if (!notificated) {
              notificate(
                'Luego de rellenar un espacio presione la tecla Tab para ir al siguiente, o Enter',
                SnackbarType.Warning,
              )
              sessionStorage.setItem('fillTheBlanksNotificated', true)
            }
          }}
        />
      ) : (
        !(!index && !value) && (
          <span key={'text-' + value + '-' + index} className="text">
            {value}
          </span>
        )
      ),
    [notificated, correctAnswerStrings, activityState],
  )

  useEffect(() => {
    const currentQuestion = payload.find(
      ({ question: questionOnPayload }) => question.id === questionOnPayload.id,
    )
    if (currentQuestion?.question?.options[0]?.answered_text) {
      setStrings(currentQuestion.question.options[0].answered_text.split('*'))
    } else {
      setStrings(
        question.options[0].options_text.split('*').map((value, index) => (index % 2 ? '' : value)),
      )
    }
    if (activityState.done) {
      setCorrectAnswerStrings(question.options[0].options_text.split('*'))
    }
  }, [])
  useEffect(() => {
    if (strings) {
      setPayload(state =>
        state.map(({ question: { id, options } }) => {
          return id === question.id
            ? {
                question: {
                  id,
                  options: blankFieldSetter({
                    id: question.options[0].id,
                    answered_text: strings.join('*'),
                  }),
                },
              }
            : { question: { id, options } }
        }),
      )
    }
  }, [strings])

  return !strings ? (
    <Loading />
  ) : (
    <>
      <ResolverWrapper
        className="resolver__fill-in-the-blanks"
        activityState={activityState}
        correctlyAnswered={correctlyAnswered}
        type="fields"
      >
        <Typography
          id={`fillInTheBlanks-${question.id}`}
          style={{
            lineHeight: '2.5rem',
          }}
        >
          {activityState.done
            ? activityState.answered_quiz
                .find(({ question: { id } }) => id === question.id)
                ?.question?.options[0]?.answered_text.split('*')
                ?.map(optionBuilder) ?? ''
            : strings.map(optionBuilder)}
        </Typography>
      </ResolverWrapper>
      {!!activityState.done && !!correctAnswerStrings && !correctlyAnswered && (
        <div className="solution">
          <Typography style={{ fontSize: '0.65rem', fontWeight: 500 }}>
            Respuesta correcta:
          </Typography>
          <Typography
            id={`fillInTheBlanks-solution-${question.id}`}
            style={{
              lineHeight: '2.5rem',
            }}
          >
            {correctAnswerStrings.map(optionBuilder)}
          </Typography>
        </div>
      )}
    </>
  )
}

export default FillInTheBlanksResolver
