import React, { useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadCategoryById } from '../../actions/categories'
import CoursesExplore from '../../components/explore/CoursesExplore'
import { COURSES_VIEW, EXTERNAL_COURSES_VIEW, router } from '../../config/routes'
import { goTo } from '../../actions/navigator'
import { appLoadToken } from '../../actions/app'
import { CourseType } from '../../config/constants'
import DocumentTitle from '../../components/ui/DocumentTitle'

const CoursesExploreContainer = ({
  category,
  loading,
  categoryId,
  loadCategoryById,
  appLoadToken,
  goTo,
}) => {
  useEffect(() => {
    appLoadToken().then(() => {
      loadCategoryById(categoryId)
    })
  }, [categoryId, loadCategoryById, appLoadToken])

  const showCourse = useCallback(
    course => {
      if (course.type === CourseType.ExternalCourse) {
        goTo(router.getRoute(EXTERNAL_COURSES_VIEW, { id: course.id }))
      } else {
        goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
      }
    },
    [goTo],
  )

  return (
    <React.Fragment>
      {category && <DocumentTitle title={category.name + ' | Categorías | Academia'} />}
      <CoursesExplore category={category} loading={loading} onShowCourse={showCourse} />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    ...state.categoryReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goTo, appLoadToken, loadCategoryById }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesExploreContainer)
