import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import validator from 'validator';
import {
  createResource,
  updateResource,
  getResource,
} from '../../../actions/admin';
import {
  EditInputType,
  SnackbarType,
  FacilitatorTypes, FacilitatorType, EditInputObjectModel
} from '../../../config/constants';
import AdminResourceForm from '../../../components/admin/AdminResourceForm';
import { addSnackbar } from '../../../actions/snackbar';
import { ADMIN_FACILITATORS } from '../../../config/routes';
import { goTo } from '../../../actions/navigator';


class AdminFacilitatorsFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      facilitator_type: '',
      user: null,
      external_facilitator: '',
      enabled: true
    },
    errors: {
      facilitator_type: '',
      user: '',
      external_facilitator: '',
      enabled: '',
    },
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
    }
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.item && nextProps.item) {
      const {
        facilitator_type,
        user,
        external_facilitator,
        enabled,
      } = nextProps.item;
      this.setState(
        {
          form: {
            facilitator_type: facilitator_type || '',
            user: user || null,
            external_facilitator: external_facilitator || '',
            enabled: enabled,
          },
        },
        () => {
          this.validateForm();
        }
      );
    }
  };

  onAttributeChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } }, () => {
      this.validateForm();
    }
    );
  };

  getColumns = () => {
    const keys = [
      {
        title: 'Tipo',
        source: 'facilitator_type',
        editable: true,
        editConfig: { type: EditInputType.Select, name: 'facilitator_type', options: FacilitatorTypes },
      }
    ];

    if ([FacilitatorType.Speaker, FacilitatorType.Multiplier].includes(this.state.form.facilitator_type)) {
      keys.push({
        title: 'Usuario',
        source: 'user',
        editable: true,
        editConfig: { type: EditInputType.Object, model: EditInputObjectModel.Users, name: 'user', displayAttribute: 'full_name' },
      });
    }
    if ([FacilitatorType.ExternalInstitution, FacilitatorType.ExternalProvider].includes(this.state.form.facilitator_type)) {
      keys.push({
        title: 'Nombre',
        source: 'external_facilitator',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'external_facilitator' },
      });
    }
    if (this.props.isEdit) {
      keys.push({
        source: 'enabled',
        editable: true,
        value: 'enabled',
        editConfig: {
          name: 'enabled',
          type: EditInputType.Switch,
          label: 'Habilitado',
        },
      })
    }
    return {
      keys
    };
  };

  getRequestDataFromForm = async () => {
    const { form } = this.state;
    const data = {
      facilitator_type: form.facilitator_type,
      user_id: form.user ? form.user.id : null,
      external_facilitator: form.external_facilitator.trim(),
      enabled: form.enabled,
    };
    return data;
  };

  onSubmit = async () => {
    const {
      addSnackbar,
      goTo,
      createResource,
      updateResource,
      isEdit,
      item,
      type,
    } = this.props;

    const data = await this.getRequestDataFromForm();
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          addSnackbar('Facilitador editado exitosamente.', SnackbarType.Success);
          goTo(ADMIN_FACILITATORS);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    } else {
      createResource(type, data)
        .then(() => {
          addSnackbar('Facilitador creado exitosamente.', '4');
          goTo(ADMIN_FACILITATORS);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    }
  };

  validateForm = () => {
    const form = this.state.form;
    let error = false;
    let errors = {
      facilitator_type: '',
      user: '',
      external_facilitator: '',
    };

    if (!form.facilitator_type) {
      errors.facilitator_type = 'Debes seleccionar un tipo';
      error = true;
    } else if ([FacilitatorType.Speaker, FacilitatorType.Multiplier].includes(form.facilitator_type)) {
      if (!form.user) {
        errors.user = 'Debes seleccionar un usuario';
        error = true;
      }
    }
    if ([FacilitatorType.ExternalInstitution, FacilitatorType.ExternalProvider].includes(form.facilitator_type)) {
      if (validator.isEmpty(form.external_facilitator.trim())) {
        errors.external_facilitator = 'Debes ingresar un nombre para el facilitador';
        error = true;
      }
    }


    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_FACILITATORS);
  };

  render() {
    const { loading, title } = this.props;
    return (
      <AdminResourceForm
        keyName={'admin-facilitators-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    );
  }
}

AdminFacilitatorsFormContainer.defaultProps = {
  type: 'facilitators',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName],
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResource, createResource, updateResource, addSnackbar, goTo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminFacilitatorsFormContainer);
