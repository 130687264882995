import React from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import classnames from 'classnames'
import { SlidesTemplateType } from '../../config/constants'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    header: {
      position: 'relative',
      height: '20%',
      backgroundSize: 'cover',
      minHeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
    headerContent: {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 100,
    },
    headerContentWrapper: {
      padding: 32,
    },
    headerContentHorizontal: {
      width: '90%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    logoBanner: {
      maxHeight: 150,
      width: 'auto !important',
    },

    title: {
      fontFamily: 'PlaylistScript',
      padding: '0 8px',
      width: '100%',
      boxSizing: 'border-box',
      marginTop: 16,
      marginBottom: 8,
      fontSize: 64,
      color: '#fff',
      textAlign: 'center',
      opacity: 0,
      transition: 'all 0.9s ease-in',
      '&_show': {
        opacity: 1,
      },
      [mediaLg]: {
        padding: '0 6px',
        fontSize: 48,
        marginTop: 12,
        marginBottom: 6,
      },
    },
    subtitle: {
      padding: '0 8px',
      width: '100%',
      boxSizing: 'border-box',
      marginTop: 16,
      marginBottom: 8,
      fontSize: 16,
      color: '#fff',
      textAlign: 'center',
      opacity: 0,
      transition: 'all 0.9s ease-in',
      textTransform: 'uppercase',
      '&_show': {
        opacity: 1,
      },
      [mediaLg]: {
        padding: '0 8px',
        fontSize: 12,
        marginTop: 12,
        marginBottom: 6,
      },
    },
    button: {
      background: 'rgba(255,255,255,0.1)',
      margin: '8px 24px',
      color: '#fff',
      border: '2px solid #fff',
      borderRadius: 8,
      padding: '12px 32px',
      minWidth: 150,
      width: 230,
      [mediaLg]: {
        margin: '3px 9px',
        padding: '6px 12px',
        minWidth: 105,
        width: 175,
        fontSize: 12,
      },
    },
    image: {
      zIndex: 2,
    },
    buttonsContainer: {
      marginTop: 24,
      marginBottom: 16,
      minHeight: 40,
      display: 'flex',
      opacity: 0,
      transition: 'all 1s ease-in',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      '&_show': {
        opacity: 1,
      },
      [mediaLg]: {
        marginTop: 18,
        marginBottom: 12,
        minHeight: 30,
      },
    },

    buttonsContainerVertical: {
      flexDirection: 'column',
    },

    gradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(315deg, #b32b52 0%, #922343 6%, #6b1931 10%, #4a1121 17%, #2f0b15 24%, #1a060b 32%, #0b0205 59%, #020001 76%, #000000 100%)',
      opacity: 0.49,
    },

    leftBox: {
      width: '100%',
    },

    textToLeft: {
      textAlign: 'left',
    },
  }
}

const createLinkHandler = (link = '', openInNewTab, onGoTo) => {
  if (openInNewTab) {
    return () => window.open(link, '_blank')
  }
  if (link != null && link.startsWith('/')) {
    return () => onGoTo(link)
  } else {
    return () => (window.location = link)
  }
}

const SlideTemplate1 = ({ onGoTo, slide, classes }) => {
  return (
    <div className={classes.header} style={{ backgroundImage: `url("${slide.image}")` }}>
      <div className={classes.headerContent}>
        <img src={slide.logo} alt={'slide logo banner'} className={classes.logoBanner} aria-hidden={true} />
        <Typography
          component={'p'}
          className={classnames(classes.subtitle, {
            [classes.subtitle + '_show']: true,
          })}
        >
          {slide.description}
        </Typography>
        <div
          className={classnames(classes.buttonsContainer, {
            [classes.buttonsContainer + '_show']: true,
          })}
        >
          <React.Fragment>
            {slide.name_button_one !== '' && (
              <Button
                role={'link'}
                className={classes.button}
                variant="outlined"
                onClick={createLinkHandler(
                  slide.url_button_one,
                  slide.open_new_tab_button_one,
                  onGoTo,
                )}
              >
                {slide.name_button_one}
              </Button>
            )}
            {slide.name_button_two !== '' && (
              <Button
                role={'link'}
                className={classes.button}
                variant="outlined"
                onClick={createLinkHandler(
                  slide.url_button_two,
                  slide.open_new_tab_button_two,
                  onGoTo,
                )}
              >
                {slide.name_button_two}
              </Button>
            )}
          </React.Fragment>
        </div>
      </div>
      <div className={classes.gradient} />
    </div>
  )
}

const SlideTemplate2 = ({ onGoTo, slide, classes }) => {
  return (
    <div className={classes.header} style={{ backgroundImage: `url("${slide.image}")` }}>
      <div className={classes.headerContent}>
        {slide.title !== '' && (
          <Typography
            component={'h2'}
            className={classnames(classes.title, {
              [classes.title + '_show']: true,
            })}
          >
            {slide.title}
          </Typography>
        )}
        <div className={classes.lineGlow} />
        <Typography
          component={'p'}
          className={classnames(classes.subtitle, {
            [classes.subtitle + '_show']: true,
          })}
        >
          {slide.description}
        </Typography>
        <div
          className={classnames(classes.buttonsContainer, {
            [classes.buttonsContainer + '_show']: true,
          })}
        >
          <React.Fragment>
            {slide.name_button_one !== '' && (
              <Button
                className={classes.button}
                variant="outlined"
                role={'link'}
                onClick={createLinkHandler(
                  slide.url_button_one,
                  slide.open_new_tab_button_one,
                  onGoTo,
                )}
              >
                {slide.name_button_one}
              </Button>
            )}

            {slide.name_button_two !== '' && (
              <Button
                className={classes.button}
                variant="outlined"
                role={'link'}
                onClick={createLinkHandler(
                  slide.url_button_two,
                  slide.open_new_tab_button_two,
                  onGoTo,
                )}
              >
                {slide.name_button_two}
              </Button>
            )}
          </React.Fragment>
        </div>
      </div>
      <div className={classes.gradient} />
    </div>
  )
}

const SlideTemplate3 = ({ onGoTo, slide, classes }) => {
  return (
    <div className={classes.header} style={{ backgroundImage: `url("${slide.image}")` }}>
      <div className={classes.headerContent + ' ' + classes.headerContentHorizontal}>
        <div className={classes.leftBox}>
          {slide.title !== '' && (
            <Typography
              component={'h2'}
              className={classnames(classes.title, {
                [classes.title + '_show']: true,
                [classes.textToLeft]: true,
              })}
            >
              {slide.title}
            </Typography>
          )}
          <Typography
            component={'p'}
            className={classnames(classes.subtitle, {
              [classes.subtitle + '_show']: true,
              [classes.textToLeft]: true,
            })}
          >
            {slide.description}
          </Typography>
        </div>
        <div
          className={classnames(classes.buttonsContainer, {
            [classes.buttonsContainer + '_show']: true,
          })}
        >
          <div className={classes.buttonsContainerVertical}>
            {slide.name_button_one !== '' && (
              <Button
                role={'link'}
                style={{ display: 'block' }}
                className={classes.button}
                variant="outlined"
                onClick={createLinkHandler(
                  slide.url_button_one,
                  slide.open_new_tab_button_one,
                  onGoTo,
                )}
              >
                {slide.name_button_one}
              </Button>
            )}
            {slide.name_button_two !== '' && (
              <Button
                role={'link'}
                className={classes.button}
                variant="outlined"
                onClick={createLinkHandler(
                  slide.url_button_two,
                  slide.open_new_tab_button_two,
                  onGoTo,
                )}
              >
                {slide.name_button_two}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={classes.gradient} />
    </div>
  )
}


const SlideTemplate4 = ({ slide, classes }) => {
  return (
      <div className={classes.header} style={{ backgroundImage: `url("${slide.image}")` }}>
      </div>
  )
}


const HomeSlide = ({ slide, classes, onGoTo }) => {
  switch (slide.template) {
    case SlidesTemplateType.LogoTextActions:
      return <SlideTemplate1 slide={slide} onGoTo={onGoTo} classes={classes} />
    case SlidesTemplateType.TitleTextActionsVertical:
      return <SlideTemplate2 slide={slide} onGoTo={onGoTo} classes={classes} />
    case SlidesTemplateType.TitleTextActionsHorizontal:
      return <SlideTemplate3 slide={slide} onGoTo={onGoTo} classes={classes} />
     case SlidesTemplateType.ImageOnly:
      return <SlideTemplate4 slide={slide}  classes={classes} />
    default:
      return null
  }
}

export default withStyles(styles)(HomeSlide)
