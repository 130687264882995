import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadAllCareerPlan, loadCareerPlanByChannel } from '../../actions/careerPlans'
import { loadAllChannels } from '../../actions/careerPlans'
import { appLoadToken } from '../../actions/app'
import { goToDetailsCareerPlans } from '../../actions/navigator'
import ExplorePlans from '../../components/career/ExplorePlans'
import DocumentTitle from '../../components/ui/DocumentTitle'

class ExploreCareerPlansContainer extends Component {
  componentWillMount() {
    const { appLoadToken } = this.props
    appLoadToken().then(() => {})
  }

  handleNavigateGoToAllCareerPlans = id => {
    this.props.goToDetailsCareerPlans(id)
  }

  loadChannels = () => {
    const { loadAllChannels } = this.props
    loadAllChannels()
  }

  loadCareerPlans = id => {
    const { loadAllCareerPlan, loadCareerPlanByChannel } = this.props
    if (id !== undefined) {
      loadCareerPlanByChannel(id)
    } else {
      loadAllCareerPlan()
    }
  }

  render() {
    const { allCareerPlans, allChannels } = this.props
    return (
      <React.Fragment>
        <DocumentTitle title={'Explorar otras rutas | Rutas de aprendizaje | Academia'} />
        <ExplorePlans
          key={'explore-plans'}
          {...allCareerPlans}
          {...allChannels}
          loadChannels={this.loadChannels}
          filtrarCarrerPlans={this.loadCareerPlans}
          onClickNavigateAllCareerPlans={this.handleNavigateGoToAllCareerPlans}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ careerPlansExploreReducers }) => {
  return {
    ...careerPlansExploreReducers,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadAllCareerPlan,
      loadAllChannels,
      appLoadToken,
      loadCareerPlanByChannel,
      goToDetailsCareerPlans,
    },
    dispatch,
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExploreCareerPlansContainer))
