import React, { Component } from 'react';
import GenericDialog from '../../components/ui/GenericDialog';
import ColorButton from '../../components/ui/ColorButton';
import { Button, Typography, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/es/TextField/TextField';
import Switch from '@material-ui/core/Switch';
import validator from 'validator';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import Slider from '@material-ui/core/Slider';

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      // minHeight: 550,
      margin: 'auto',
      alignItems: 'center',
      [mediaLg]: {
        marginBottom: 10,
      },
    },

    nps: {
      marginTop: 24,
      marginBottom: 10,
      width: '80%',
      margin: 'auto',
      textAlign: 'center',
      [mediaLg]: {
        width: '90%',
      },
    },

    text: {
      textAlign: 'center',
      color: '#575757',
    },

    actions: {
      margin: 'auto',
      marginTop: 18,
      marginBottom: 18,
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
    },

    actionSave: {
      backgroundColor: '#575454',
      marginRight: 16,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'rgba(87,84,84,0.91)',
      },
    },

    actionCancel: {
      color: '#575454',
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },

    buttonValorar: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
    },
    actionValorarSolo: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
      margin: 'auto',
      marginTop: 25,
      marginBottom: 25,
    },

    switchDiv: {
      fontSize: 14,
      [mediaLg]: {
        marginTop: 2,
      },
    },

    autorizo: {
      fontSize: 14,
      marginTop: 10,
      width: '70%',
      margin: 'auto',
      [mediaLg]: {
        marginTop: 2,
      },
    },


    buttonLabel: {
      textTransform: 'none',
    },

    buttonLabelSave: {
      textTransform: 'none',
      color: '#f0f0f0f0',
    },

    inputRoot: {
      padding: '18.5px 14px',
      fontSize: 14,
      width: 430,
      marginBottom: -7,
      [mediaLg]: {
        width: 'auto',
      },
    },

    textField: {
      marginBottom: 7,
      margin: 'auto',
      [mediaLg]: {
        width: '100%',
      },
    },

    Label: {
      fontSize: 14,
      textAlign: 'justify',
      marginLeft: -25,
      paddingRight: 25,
      color: '#575454',
    },

    titleValorar: {
      fontSize: 15,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      color: '#575454',
    },

    titleComment:{
      fontSize: 15,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      width:450,
      paddingLeft: 35,
      color: '#575454',
    },

    valorar: {
      marginTop: 15,
      textAlign: 'right',
    },

    actionValorar: {
      width: 203,
      height: 58,
    },

    share: {
      marginTop: 15,
      textAlign: 'left',
    },

    actionShare: {
      height: 58,
      color: '#ffffff'
    },

    LabelFirstText: {
      fontSize: 16.5,
      textAlign: 'center',
      fontWeight: 600,
      paddingLeft: 40,
      paddingRight: 40,
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },

    LabelSecondText: {
      fontSize: 12,
      textAlign: 'center',
      width: '60%',
      margin: 'auto',
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },
    labelAutorizo: {
      fontSize: 12,
      color: '#575454',
    },

    error: {
      fontSize: 10,
      color: 'red',
    },
    switch: {
      marginLeft: '14%',
      width: '70%',
      display: 'inline-flex',
      [mediaLg]: {
        marginLeft: 'unset',
        width: '100%',
      },
    },
    contentValorar: {
      margin: 2
    }
  }
};

const initialState = {
  isValid: false,
  initialRating: null,
  stars: false,
  enable_publish: false,
  comment: '',
  errors: { comment: '', nps: '' },
};

class CreateReviewDialog extends Component {
  state = { ...initialState };

  static getDerivedStateFromProps(nextProps, prevState) {

    return { initialRating: nextProps.initialRating };
  }

  resetDialog = () => {
    this.setState({ ...initialState });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {

    });
  };

  handleSwitch = (event) => {

    this.setState({ [event.target.name]: event.target.checked }, () => {

    });
  };

  handleNps = (valor) => {
    this.setState({ stars: valor.target.ariaValueNow }, () => {

    });
  };

  handleSlider = (valor) => {
    if (valor != this.state.stars) {
      this.setState({ stars: valor }, () => {

      });
    }
    return valor;
  };

  validateForm = () => {
    let error = false;
    let errors = {
      comment: '',
      nps: '',
    };

    if (!validator.isLength(this.state.comment.trim(), { min: 40, max: 300 })) {
      errors.comment =
        'Este campo debe tener entre 40 y 300 caracteres.';
      error = true;
    }

    if (this.state.stars === false && this.state.initialRating == null) {
      errors.nps = 'Este campo es obligatorio';
      error = true;
    }


    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  handleSubmit =() =>{
  const { onSubmit } = this.props;
    if(this.validateForm()){
    onSubmit(
      {
        rating: this.state.stars,
        comment: this.state.comment.trim(),
        enable_publish: this.state.enable_publish,
      });
    }
  };

  renderContent = () => {
    const { classes, onDismiss, onSubmit, headerColor } = this.props;
    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      },
      {
        value: 10,
        label: '10',
      }];

    return (
      <div className={classes.content}>
        <Grid container spacing={3} className={classes.contentValorar}>
          <div className={classes.nps}>
            <Typography className={classes.titleValorar} id={'review-slider-label'}>
              {'Del 0 al 10, ¿cuánto recomendarías este curso a un colega?'}
            </Typography>

            <Slider
              className={classes.nps}
              defaultValue={0}
              getAriaValueText={this.handleSlider}
              aria-labelledby="review-slider-label"
              valueLabelDisplay="on"
              step={1}
              style={{ color: headerColor }}
              marks={marks}
              min={0}
              max={10}
            />


            <Typography className={classes.error}>
              {this.state.errors.nps}
            </Typography>
          </div>
          <div className={classes.nps}>
            <Typography className={classes.titleComment} id={'titleComment'}>
              {'Contanos qué te gustó y qué mejorarías? Tu feedback nos ayuda!'}
            </Typography>
            <TextField
              id="comment"
              name="comment"
              placeholder={'Mínimo 40 caracteres'}
              value={this.state.comment}
              margin="normal"
              variant="outlined"
              type="text"
              multiline={true}
              rows={4}
              rowsMax={6}
              onChange={this.handleChange}
              className={classes.textField}
              InputProps={{
                'aria-describedby': 'titleComment',
                classes: { root: classes.inputRoot },
              }}
            />
            <Typography className={classes.error}>
              {this.state.errors.comment}
            </Typography>
          </div>
          <Grid item xs={12}>
            <div className={classes.switch}>
              <Switch
                checked={this.state.enable_publish}
                onChange={this.handleSwitch}
                color="primary"
                name="enable_publish"
                inputProps={{ 'aria-labelledby': 'labelAutorizo' }}
              />
              <Typography className={classes.labelAutorizo} id={'labelAutorizo'}>
                {'Autorizo a que mi comentario sea identificado con nombre y apellido para ser contactado para dar feedback sobre el curso'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.actions}>
            <ColorButton
              color={'#3abf91'}
              className={classes.actionSave}
              label={'Guardar'}
              onClick = {this.handleSubmit}
            />

            <Button
              variant={'outlined'}
              className={classes.actionCancel}
              classes={{ label: classes.buttonLabel }}
              onClick={onDismiss}
            >
              {'CANCELAR'}
            </Button>

          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const { open, onDismiss, headerColor } = this.props;
    return (
      <GenericDialog
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        name={'VALORAR EL CURSO'}
        icon={
          <StarBorderOutlinedIcon style={{ fontSize: 55 }} />
        }
        title={'Del 0 al 10'}
        title2={'¿cuánto recomendarías este curso a un colega?'}
        onExit={this.resetDialog}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}

export default withStyles(styles)(CreateReviewDialog);
