import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../../actions/navigator';
import {
  getResources,
  deleteResource,
  refreshResources,
} from '../../../actions/admin';
import {
  ADMIN_TAGS,
  ADMIN_EDIT,
  ADMIN_NEW,
  ADMIN_VIEW,
  router,
} from '../../../config/routes';
import AdminResourceIndex from '../AdminResourceIndex';
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
} from '../../../config/constants';
import ViewIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { addSnackbar } from '../../../actions/snackbar';

class AdminTagsContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
  };

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size, undefined)
  };

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Nombre', source: 'name' },
    ];
  };

  onCreate = () => {
    this.props.goTo(ADMIN_TAGS + ADMIN_NEW);
  };

  onEditItem = (item) => {
    this.props.goTo(router.getRoute(ADMIN_TAGS + ADMIN_EDIT, { id: item.id }));
  };

  onDeleteItem = (item) => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.name,
    });
  };

  getActions = (item) => {
    return [
      {
        title: 'VER',
        action: this.onShowItem,
        icon: <ViewIcon />,
      },
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
    ];
  };

  onShowItem = (item) => {
    this.props.goTo(router.getRoute(ADMIN_TAGS + ADMIN_VIEW, { id: item.id }));
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'tag.name',
        placeholder: 'Nombre',
        format: FiltersFormat.Like,
      },
    ];
  };

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props;
    const item = this.state.deleteItem;
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Etiqueta eliminada.', SnackbarType.Success);
        refreshResources(this.getKeyName());
      })
      .catch((err) => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error);
      });
  };

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
  };

  getKeyName = () => {
    return 'admin-tags';
  };

  render() {
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={'ETIQUETAS'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
      >
        <ConfirmDialog
          title={'Eliminar Etiqueta'}
          open={this.state.confirmDeleteOpen}
          description={`¿Estás seguro de eliminar la etiqueta ${this.state.itemToDelete}?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    );
  }
}

AdminTagsContainer.defaultProps = {
  type: 'tags',
};

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, refreshResources, deleteResource, goTo, addSnackbar },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTagsContainer);
