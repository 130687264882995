import React, { Component } from 'react'
import { withStyles, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { AdminItemViewType } from '../../../../config/constants'
import CloseIcon from '@material-ui/icons/Close'
import AdminResourceTable from '../../AdminResourceTable'
import styles from '../../course/ActivityModal/ActivityModalStyles'

class ClassesModal extends Component {
  state = {
    content: null,
    results: {
      isValid: false,
      errors: {},
    },
  }

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Fecha y hora de inicio', source: 'start_date', type: AdminItemViewType.Datetime },
      { title: 'Duración (min)', source: 'duration' },
    ]
  }

  handleCancel = () => {
    this.setState({
      content: {},
      results: {
        isValid: false,
        error: {},
      },
    })
    this.props.onClose()
  }

  render() {
    const { classes, open, onClose, onFetch } = this.props

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md">
        <DialogContent>
          <IconButton className={classes.closeIcon} onClick={this.handleCancel}>
            <CloseIcon />
          </IconButton>
          <AdminResourceTable
            key={'comissions-date'}
            items={onFetch}
            columns={this.getColumns()}
            forceNoActions={true}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ClassesModal)
