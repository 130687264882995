import React, { Component } from 'react';
import GenericDialog from '../../components/ui/GenericDialog';
import ColorButton from '../../components/ui/ColorButton';
import { Button, Typography, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/es/TextField/TextField';
import { EditInputObjectModel } from '../../config/constants';
import UserFinder from '../../containers/ui/UserFinder';
import ShareIcon from '@material-ui/icons/Share';
import TextUserChip from '../ui/TextUserChip';


const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      minHeight: 350,
      margin: 'auto',
      alignItems: 'center',
      [mediaLg]: {
        marginBottom: 10,
      },
    },

    nps: {
      marginTop: 10,
      margin: 'auto',
      textAlign: 'center',
    },

    userTextChips: {
      marginTop: 25,
      width: '80%',
      margin: 'auto',
      textAlign: 'center',
    },
    finderUsers: {
      marginTop: 35,
      margin: 'auto',
      width: '80%',
      textAlign: 'center',
    },
    commentsUsers: {
      marginTop: 35,
      margin: 'auto',
      width: '100%',
      textAlign: 'center',
    },
    text: {
      textAlign: 'center',
      color: '#575757',
    },

    actions: {
      margin: 'auto',
      marginTop: 18,
      marginBottom: 18,
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
    },

    actionSave: {
      backgroundColor: '#575454',
      marginRight: 16,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'rgba(87,84,84,0.91)',
      },
    },

    actionCancel: {
      color: '#575454',
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },

    buttonValorar: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
    },
    actionValorarSolo: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
      margin: 'auto',
      marginTop: 25,
      marginBottom: 25,
    },

    switchDiv: {
      fontSize: 14,
      [mediaLg]: {
        marginTop: 2,
      },
    },

    autorizo: {
      fontSize: 14,
      marginTop: 10,
      width: '70%',
      margin: 'auto',
      [mediaLg]: {
        marginTop: 2,
      },
    },


    buttonLabel: {
      textTransform: 'none',
    },

    buttonLabelSave: {
      textTransform: 'none',
      color: '#f0f0f0f0',
    },

    inputRoot: {
      padding: '18.5px 14px',
      fontSize: 14,
      width: '100%',
      marginBottom: -7,
      [mediaLg]: {
        width: 'auto',
      },
    },

    textField: {
      marginBottom: 7,
      margin: 'auto',
      width:'80%'
    },

    Label: {
      fontSize: 14,
      textAlign: 'justify',
      marginLeft: -25,
      paddingRight: 25,
      color: '#575454',
    },

    titleValorar: {
      fontSize: 15,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      color: '#575454',
    },

    valorar: {
      marginTop: 15,
      textAlign: 'right',
    },

    actionValorar: {
      width: 203,
      height: 58,
    },

    share: {
      marginTop: 15,
      textAlign: 'left',
    },

    actionShare: {
      height: 58,
      marginTop: 56,
      color: '#ffffff'
    },

    LabelFirstText: {
      fontSize: 16.5,
      textAlign: 'center',
      fontWeight: 600,
      paddingLeft: 40,
      paddingRight: 40,
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },

    LabelSecondText: {
      fontSize: 12,
      textAlign: 'center',
      width: '60%',
      margin: 'auto',
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },
    labelAutorizo: {
      fontSize: 12,
      color: '#575454',
    },

    error: {
      fontSize: 10,
      color: 'red',
      margin: 'auto',
      width:'80%'
    },
    switch: {
      marginLeft: '14%',
      width: '70%',
      display: 'inline-flex',
    },
    contentValorar: {
      margin: 2
    }
  }
};

const initialState = {
  isValid: false,
  initialRating: null,
  stars: false,
  valueUserFinder: [],
  valueUserSelected: [],
  emailsToSend: [],
  enable_publish: false,
  comments: '',
  showValorar: false,
  showShare: false,
  errors: { comment: '', nps: '' },
};

const NOT_USER_SELECTED_MESSAGE = 'Seleccione un colega para compartirle este curso';

class CourseShareDialog extends Component {
  state = { ...initialState };


  static getDerivedStateFromProps(nextProps, prevState) {
    return { initialRating: nextProps.initialRating };
  }

  resetDialog = () => {
    this.setState({ ...initialState });
  };

  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  validateForm = () => {
    let error = false;
    let errors = {
      nps: '',
    };

    if(this.state.valueUserSelected.length < 1){
      errors.nps = NOT_USER_SELECTED_MESSAGE;
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };


  style = (value) => {
    const {  headerColor } = this.props;
    if (value) {
      return { backgroundColor: headerColor, border: 'solid 1.5px' + headerColor, color: '#fff' }
    } else {
      return { color: headerColor, border: 'solid 1.5px' + headerColor }
    }
  };


  onChange = (item) => {
    const { valueUserSelected } = this.state;

    let index = item.length;
    let found = valueUserSelected.find(element => element.email == item[index -1].email);
    if(!found){
      this.setState(prevState => ({
          valueUserSelected: item,
          errors : { ...prevState.errors, nps: '' }
        }));
    }
  };

  onChangeDelete = (item) => {
      this.setState(prevState => ({
          valueUserSelected: item,
          errors : { ...prevState.errors, nps: NOT_USER_SELECTED_MESSAGE }
      }));
  };

  handleSubmit = () => {
    const { course, onSubmit } = this.props;
    const { valueUserSelected,comments } = this.state;
    if(this.validateForm()) {
      let emails = [];
      valueUserSelected.forEach(element => {
        emails.push(element.email);
      });

      if (emails.length > 0) {
        let data = {
          course_id: course.id,
          emails: emails,
          comments:comments
        };

        onSubmit(data);
      }
    }
  };

  renderContent = () => {
    const { classes, onDismiss } = this.props;
    const { valueUserFinder, valueUserSelected } = this.state;

    return (
      <div className={classes.content}>
        <Grid className={classes.finderUsers} container spacing={3}>
          <UserFinder
            keyName={'autocomplete-simple-object-input-usersFinder'}
            value={valueUserFinder}
            model={EditInputObjectModel.UsersToShare}
            placeholder={'Ingresá el apellido y nombre de tu colega. Ejemplo: Perez, Juan'}
            displayAttribute={'first_name'}
            onItemSelected={(selectedValue) =>
              valueUserFinder ? this.onChange([...valueUserSelected, selectedValue]) : this.onChange([selectedValue])
            }
          />
          <Typography className={classes.error}>
            {this.state.errors.nps}
          </Typography>
        </Grid>


        <Grid className={classes.userTextChips} container spacing={3}>
          {valueUserSelected &&
            valueUserSelected.length > 0 &&
            valueUserSelected.map((textValue) => (
              <TextUserChip
                text={textValue.last_name+', '+ textValue.first_name}
                avatarText={textValue.first_name.charAt(0) + textValue.last_name.charAt(0)}
                key={textValue.email}
                gerencia={textValue.departmental_management ? textValue.departmental_management : null}
                onDeleteChip={() =>
                  this.onChangeDelete(valueUserSelected.filter((item) => item.email !== textValue.email))
                }
              />
            ))}
        </Grid>

        <Grid className={classes.commentsUsers} container spacing={3}>
          <TextField
                id="comments"
                name="comments"
                placeholder={'¿Por qué recomendás este curso?'}
                value={this.state.comments}
                margin="normal"
                variant="outlined"
                type="text"
                multiline={true}
                rows={4}
                rowsMax={6}
                onChange={this.handleChange}
                className={classes.textField}
                InputProps={{
                  'aria-label': '¿Por qué recomendás este curso?',
                  classes: { root: classes.inputRoot },
                }}
                inputProps={{
                  maxLength: 255,
                }}
              />
        </Grid>

        <Grid className={classes.userTextChips} container spacing={3}>
          <div className={classes.actions}>
            <ColorButton
              color={'#3abf91'}
              className={classes.actionSave}
              label={'Enviar'}
              onClick={this.handleSubmit}
            />
            <Button
              variant={'outlined'}
              className={classes.actionCancel}
              onClick={onDismiss}
            >
              {'CANCELAR'}
            </Button>
          </div>
        </Grid>

      </div>
    );
  };

  render() {
    const { open, onDismiss, headerColor } = this.props;
    return (
      <GenericDialog
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        name={'COMPARTIR EL CURSO'}
        icon={
          <ShareIcon style={{ fontSize: 55 }} />
        }
        title={'Compartí este curso con un colega!'}
        onExit={this.resetDialog}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}
export default withStyles(styles)(CourseShareDialog);
