import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createResource, updateResource, getResource } from '../../../actions/admin';
import {
  EditInputType,
  SnackbarType,
  JobTypes
} from '../../../config/constants';
import AdminResourceForm from '../../../components/admin/AdminResourceForm';
import { addSnackbar } from '../../../actions/snackbar';
import { ADMIN_JOBS } from '../../../config/routes';
import { goTo } from '../../../actions/navigator';
 
var JobType ={ };

JobTypes.forEach( object => { 
    JobType[object.value]=object;
});

class AdminJobsFormContainer extends Component {

  state = {
    isValid: false,
    form: {
      file: null,
      type:null,
    },
    errors: {
      file: '',
      type:''
    },
    loadingForm: false,
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
    }
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.item && nextProps.item) {
      const { name, active } = nextProps.item;
      //const { name, active, department } = nextProps.item;
      this.setState({ form: { name, active: ((active) ? '1' : '0') } }, () => this.validateForm())
      // this.setState({ form: { name, department, active: ((active) ? '1' : '0') } }, () => this.validateForm())
    }
  };

  onAttributeChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } }, () => this.validateForm());
    if (name === 'jobType') {
      if (value[0]) {
        return this.loadFileAsUrlData(value[0]);
      } else {
        this.setState({
          form: {
            ...this.state.form,
            type: this.state.form.type,
          },
        });
      }
    }
  };

  getColumns = () => { 

    const { isEdit } = this.props;
    const columns = [
      {
        title: 'Tipo',
        source: 'type',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          name: 'type',
          options: JobTypes.map((t) => {
            return { id: t.value, description: t.title };
          }),
        },
      },
    ];

    if( JobType[this.state.form.type] !== undefined )   
    { 
        columns.push(
        {
            title     : JobType[this.state.form.type].title , 
            source    : JobType[this.state.form.type].file.name, 
            editable  : !isEdit,
            editConfig: 
            { 
                type  : EditInputType.File, 
                name  : JobType[this.state.form.type].file.name, 
                accept: JobType[this.state.form.type].file.type 
            }
        });
    } 
 
    return {
      keys: [
        ...columns,
      ]
    };
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  getRequestDataFromForm = async () => {
    const { form } = this.state;
    try {

        if( JobType[form.type] !== undefined )   
        {  
            const file = await this.getBase64(form[JobType[form.type].file.name][0]);
            return {
              type: form.type,
              file: [file],
            };
        } 
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  onSubmit = async () => {
    this.setState({ loadingForm: true })
    const { addSnackbar, goTo, createResource, updateResource, isEdit, item, type } = this.props;
    const data = await this.getRequestDataFromForm();
    if (data) {
      if (isEdit) {
        updateResource(type, item.id, data).then(() => {
          addSnackbar("Tarea editada exitosamente.", SnackbarType.Success);
          goTo(ADMIN_JOBS);
        }).catch((err) => {
          this.setState({ loadingForm: false })
          addSnackbar(`Error:  ${err.message ?? `Tiempo de espera superado`}` , SnackbarType.Error);
        });
      } else {
        createResource(type, data).then(() => {
          addSnackbar("Tarea creada exitosamente.", SnackbarType.Success);
          goTo(ADMIN_JOBS);
        }).catch((err) => {
          this.setState({ loadingForm: false })
          addSnackbar(`Error: ${err.message ?? `Tiempo de espera superado`}`, SnackbarType.Error);
        });
      }
    }
  };

  validateForm = () => {
    const job = this.state.form;
    let error = false;
    let errors = {
      file: null 
    };

    if(   (JobType[job.type] !== undefined) && (!job[ JobType[job.type].file.name ])  )
    {
      errors.file = 'Debes seleccionar un archivo';
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });

    return error;
  }

  onGoBack = () => {
    this.props.goTo(ADMIN_JOBS);
  }

  render() {
    const { loadingForm } = this.state;
    const { loading, title } = this.props;
    return <AdminResourceForm
      keyName={'admin-import-jobs-form'}
      title={title}
      form={this.state.form}
      isValid={this.state.isValid}
      errors={this.state.errors}
      loading={loading}
      loadingButton={loadingForm}
      attributes={this.getColumns()}
      onSubmit={this.onSubmit}
      onAttributeChange={this.onAttributeChange}
      onGoBack={this.onGoBack}>
    </AdminResourceForm>;
  }
}


const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName]
  }
};


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResource, createResource, updateResource, addSnackbar, goTo }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminJobsFormContainer);
