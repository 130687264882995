import {
  SEARCH_REQUEST_START,
  SEARCH_REQUEST_END,
  SEARCH_REQUEST_FAILED,
  SEARCH_REQUEST_CHANGE_QUERY,
  SEARCH_REQUEST_INIT,
  SEARCH_REQUEST_CLEAR,
  CREATE_REQUEST_START,
  CREATE_REQUEST_END,
  CREATE_REQUEST_FAILED,
  ACTIVITY_READ_START,
  ACTIVITY_READ_END,
  ACTIVITY_READ_FAILED,
  INSTITUTION_READ_START,
  INSTITUTION_READ_END,
  INSTITUTION_READ_FAILED,
  PLACES_READ_START,
  PLACES_READ_END,
  PLACES_READ_FAILED,
  PLACE_READ_START,
  PLACE_READ_END,
  PLACE_READ_FAILED,
  PLATFORM_READ_START,
  PLATFORM_READ_END,
  PLATFORM_READ_FAILED,
} from './index'
import api from '../api/api'
import { getCurrent } from './user'
import { isOpenBankUser } from '../utils/AdminHelper'

export function init(key) {
  return async dispatch => {
    dispatch({ type: SEARCH_REQUEST_INIT, key })
  }
}
export function clear(key) {
  return async dispatch => {
    dispatch({ type: SEARCH_REQUEST_CLEAR, key })
  }
}

export function changeQuery(key, query, customFilter) {
  return async dispatch => {
    dispatch({
      type: SEARCH_REQUEST_CHANGE_QUERY,
      key,
      payload: { query, customFilter },
    })
  }
}

function onFetch(key, query, request, min = 3, extraData = [], customFilter = '', objetName) {
  return async dispatch => {
    dispatch({
      type: SEARCH_REQUEST_START,
      key,
      payload: { query, customFilter },
    })
    try {
      let response = { data: [] }
      if (query && query.trim().length >= min) {
        response = await request(dispatch)
      }
      dispatch({
        type: SEARCH_REQUEST_END,
        key,
        payload: {
          items: [...extraData, ...(objetName ? response.data[objetName] : response.data)],
          query,
          customFilter,
        },
      })
    } catch (e) {
      dispatch({ type: SEARCH_REQUEST_FAILED, key, payload: e })
    }
  }
}

function onCreate(key, value, request, min = 3, extraData = []) {
  return async dispatch => {
    dispatch({
      type: CREATE_REQUEST_START,
      key,
      payload: { query: value },
    })
    try {
      let response = { data: [] }
      if (value && value.trim().length >= min) {
        response = await request(dispatch)
      }
      dispatch({
        type: CREATE_REQUEST_END,
        key,
        payload: { items: [...extraData, response.data], query: value },
      })
    } catch (e) {
      dispatch({ type: CREATE_REQUEST_FAILED, key, payload: e })
    }
  }
}

export function searchTags(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).Filter.tags(query))
}

export function searchCategories(key, query, customFilter) {
  let customQuery = ''
  if (customFilter && Object.keys(customFilter).length) {
    customQuery = Object.keys(customFilter).map(key => {
      return `filters[category.${key}][LIKE]=${customFilter[key]}&`
    })
    customQuery = customQuery.join('')
  }
  return onFetch(key, query, dispatch =>
    api.withDispatch(dispatch).Filter.categories(query, customQuery),
  )
}

export function searchFacilitators(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).Filter.facilitators(query))
}

export function searchPlaces(institutionId, addressId, classroomId) {
  return async dispatch => {
    const response = await api
      .withDispatch(dispatch)
      .Filter.places({ institutionId, addressId, classroomId })
    return response.data
  }
}

export function searchInstitutions() {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Filter.institutions()
    return response.data
  }
}

export function loadInstitutions() {
  return async dispatch => {
    dispatch({ type: INSTITUTION_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Filter.institutions()
      dispatch({ type: INSTITUTION_READ_END, payload: { items: response.data } })
    } catch (e) {
      dispatch({ type: INSTITUTION_READ_FAILED, payload: { message: e } })
    }
  }
}

export function loadPlaces(institutionId) {
  return async dispatch => {
    dispatch({ type: PLACES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Filter.places({ institutionId })
      dispatch({ type: PLACES_READ_END, payload: { items: response.data } })
    } catch (e) {
      dispatch({ type: PLACES_READ_FAILED, payload: { message: e } })
    }
  }
}

export function loadPlatform(platformId) {
  return async dispatch => {
    dispatch({ type: PLATFORM_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Filter.platforms({ platformId })
      dispatch({ type: PLATFORM_READ_END, payload: { items: response.data } })
    } catch (e) {
      dispatch({ type: PLATFORM_READ_FAILED, payload: { message: e } })
    }
  }
}

export function loadPlace(institutionId, addressId, classroomId) {
  return async dispatch => {
    dispatch({ type: PLACE_READ_START })
    try {
      const response = await api
        .withDispatch(dispatch)
        .Filter.places({ institutionId, addressId, classroomId })
      dispatch({ type: PLACE_READ_END, payload: { items: response.data } })
    } catch (e) {
      dispatch({ type: PLACE_READ_FAILED, payload: { message: e } })
    }
  }
}

export function searchInstitutionsRemote() {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Filter.institutionsRemote()
    return response.data.map(datos => ({ id: datos.id, name: datos.institution.name }))
  }
}

export function searchPlatforms() {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Filter.platforms()
    return response.data
  }
}

export function searchCommissionPlaces(key, query) {
  return onFetch(
    key,
    query,
    dispatch => api.withDispatch(dispatch).Filter.commissionPlaces(query),
    undefined,
    undefined,
    undefined,
    'sites',
  )
}

export function searchUsers(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).Filter.users(query))
}

export function searchProviders(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).Filter.providers(query))
}

export function searchExternalReferencesProviders(key, query) {
  return onFetch(key, query, dispatch =>
    api.withDispatch(dispatch).Filter.externalReferences(query, 'provider.name'),
  )
}

export function searchExternalReferencesReferents(key, query) {
  return onFetch(key, query, dispatch =>
    api.withDispatch(dispatch).Filter.externalReferences(query, 'user.fullName'),
  )
}

export function searchUsersToShare(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).Filter.usersToShare(query))
}

export function searchCourses(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).Filter.courses(query))
}

export function searchOrganizationalUnit(key, query) {
  return onFetch(key, query, dispatch =>
    api.withDispatch(dispatch).Filter.organizationalUnit(query),
  )
}

export function searchJobPosition(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).Filter.jobPosition(query))
}

export function createTags(key, value) {
  return onCreate(key, value, dispatch => api.withDispatch(dispatch).Create.tags(value))
}

export function createCategories(key, value) {
  return onCreate(key, value, dispatch => api.withDispatch(dispatch).Create.categories(value))
}

export function searchPublicExternalCourses(key, query) {
  return onFetch(key, query, dispatch => api.withDispatch(dispatch).ExternalCourses.search(query))
}
