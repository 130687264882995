import {Box, Modal} from '@material-ui/core';
import React, {useState} from 'react'
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const ResizableImage = ({src, alt = '', className, imgClassName}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80vw',
    maxHeight: '80vh',
    boxShadow: '0 0 0.25rem #00000040',
    padding: '1rem',
    backgroundColor:'#3c3f41',
    borderRadius: '0.75rem'
  }

  return (
    <>
      <div className={className}
           onClick={src ? () => setModalOpen(true) : undefined}
      >
        <img
          src={src}
          className={imgClassName}
        />
        <ZoomInIcon style={{
          position: 'absolute',
          bottom: '0.125rem',
          right: '0.125rem',
          backgroundColor: '#00000040',
          color: 'white',
          padding: '0.25rem',
          borderRadius: '4rem'
        }}/>
      </div>
      {modalOpen && src && (
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box style={style}>
            <img
              style={{
                objectFit: 'contain',
                borderRadius: '0.25rem',
                cursor: 'pointer',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              alt={alt}
              src={src}
              onClick={() => setModalOpen(false)}
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default ResizableImage
