import React from 'react'
import Header from './HeaderNextEvents'
import { withStyles } from '@material-ui/core/styles'
import Content from "./ContentNextEvents";

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: 'calc(100vw - 0%)',
    paddingBottom: 50,
  },
})

const NextEvents = ({ classes, loading, events, currentDate, updateDate, openExternalCourse, updateFilters, filters }) => {
  return (
    <div className={classes.root}>
      <Header  loading={loading} />
      <Content events={events} currentDate={currentDate} updateDate={updateDate} updateFilters={updateFilters} filters={filters} openExternalCourse={openExternalCourse}/>
    </div>
  )
}
export default withStyles(styles)(NextEvents)
