import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { idForwardingLogin, wordpressLogin, getCurrent } from '../../actions/user';
import { goHome } from '../../actions/navigator';
import Loading from '../../components/ui/Loading';

class SetTokenContainer extends Component {
  componentDidMount() {
    const { search, idForwardingLogin, goHome, wordpressLogin, getCurrent } = this.props;
    let query = search;
    if (query.startsWith('?')) {
      query = query.substring(1, query.length);
    }
    query = query.split('&');
    const params = {};
    query.forEach((q) => {
      const kv = q.split('=');
      params[kv[0]] = kv[1];
    });
    
    if (params['oneTimeToken']) {
      idForwardingLogin(params['oneTimeToken']).then(() => {
        wordpressLogin();
        getCurrent();
        goHome();
      })
    } else {
      goHome();
    }
  }

  render() {
    return (
      <div
        style={{ minHeight: 300, minWidth: 500 }}
      >
        <Loading />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { idForwardingLogin, goHome, wordpressLogin, getCurrent },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SetTokenContainer);
