import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import validator from 'validator'
import { CourseFormBasicData } from '../../../components/admin/course'
import { addSnackbar } from '../../../actions/snackbar'
import {
  createResource,
  updateResource,
  getResource,
  getResources,
  deleteResource,
} from '../../../actions/admin'
import { goTo } from '../../../actions/navigator'
import { ADMIN_COURSES_BLOCK, ADMIN_EDIT, ADMIN_COURSES, router } from '../../../config/routes'
import { ReferentProviderType, SnackbarType } from '../../../config/constants'
import api from '../../../api/api'

class AdminCoursesBasicFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      required: false,
      comments_enabled: false,
      tags_list: false,
      hidden: false,
      users: [],
    },
    img: '',
    errors: {},
  }

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData()
      this.setState({ isValid: true })
    }
  }

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props
    getResource(keyName, type, params.id)
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = nextProps => {
    if (!this.props.item && nextProps.item) {
      this.setState(
        {
          form: {
            ...nextProps.item,
            tags_list:
              nextProps.item && nextProps.item.course_tags && nextProps.item.course_tags.length
                ? nextProps.item.course_tags.map(tag => ({
                    id: tag.tag.id,
                    name: tag.tag.name,
                  }))
                : [],
            categories:
              nextProps.item &&
              nextProps.item.course_categories &&
              nextProps.item.course_categories.length
                ? nextProps.item.course_categories.map(category => ({
                    id: category.category.id,
                    name: category.category.name,
                  }))
                : [],
            external_reference_type:
              nextProps.item.external_reference && nextProps.item.external_reference.length
                ? nextProps.item.external_reference[0].external_reference_type
                : undefined,
          },
          loaded: true,
        },
        this.validateBasicForm,
      )
    }
  }

  validateBasicForm = async () => {
    const { form } = this.state
    const { isEdit } = this.props
    const errors = {}
    this.setState({ errors: errors, isValid: false })
    if (!form.image) {
      errors.image = <span style={{ marginLeft: '4rem' }}>Debes ingresar una imagen</span>
    }

    if (!form.title || validator.isEmpty(form.title.trim())) {
      errors.title = 'Debes completar este campo'
    } else if (!validator.isLength(form.title.trim(), { min: 2, max: 80 })) {
      errors.title = 'Este campo debe tener entre 2 y 80 caracteres.'
    }

    if (!form.description || validator.isLength(form.description.trim())) {
      errors.description = 'Debes completar este campo'
    } else if (
      !validator.isLength(form.description.trim(), {
        min: 2,
        max: 300,
      })
    ) {
      errors.description = 'Este campo debe tener entre 2 y 300 caracteres.'
    }

    if (!form.external_reference_type) {
      errors.external_reference_type = 'Debes completar este campo'
    } else {
      if (
        form.external_reference_type === ReferentProviderType.Referent &&
        !form.external_reference?.length
      ) {
        errors.external_reference = 'Debes elegir al menos un referente'
      }
      if (
        form.external_reference_type === ReferentProviderType.Provider &&
        !form.external_reference?.length
      ) {
        errors.external_reference = 'Debes elegir al menos un proveedor'
      }
    }

    if (!form.duration) {
      errors.duration = 'Debes completar este campo con minutos'
    } else if (form.duration < 1 || form.duration > 99999) {
      errors.duration = 'La duración del curso debe ser de entre 1 y 99999 minutos'
    } else if (!validator.isDecimal(form.duration.toString())) {
      errors.duration = 'Debes ingresar la cantidad de minutos'
    }

    if (form.investment && form.investment !== '') {
      if (form.investment < 0) {
        errors.investment = 'No se admiten números negativos'
      } else if (
        !validator.isDecimal(form.investment.toString()) ||
        form.investment.includes('.') ||
        form.investment.includes(',')
      ) {
        errors.investment = 'Debes ingresar números enteros'
      }
    }

    if (!(form.categories && form.categories.length)) {
      errors.categories = 'Debes completar este campo'
    }

    if (form.title && !isEdit) {
      const exists = await this.validateName(form.title)
      if (exists.data) {
        errors.title = 'El nombre para éste curso ya está registrado'
      }
    }

    this.setState({ errors: errors, isValid: !Object.values(errors).length })
  }

  onChange = form => {
    this.setState({ form }, this.validateBasicForm)
  }

  validateName = async title => {
    try {
      return await api.Courses.exists(title)
    } catch (error) {
      console.log(error)
    }
  }

  handleSubmit = async () => {
    const { type, addSnackbar, createResource, isEdit, updateResource, item, goTo } = this.props
    const { form } = this.state

    const image = form.image && form.image.file ? form.image.file : undefined

    const { external_reference } = form

    const data = {
      ...form,
      image,
      external_reference: external_reference.map(ref => ref.id),
      file_extension: image ? form.image.file_extension : undefined,
      categories: form.categories.map(cat => cat.id),
      tags_list:
        form.tags_list && form.tags_list.length
          ? form.tags_list.map(tag => tag.name).join(',')
          : '',
    }
    this.setState({ isValid: false })
    try {
      const result = isEdit
        ? await updateResource(type, item.id, data)
        : await createResource(type, data)
      addSnackbar(`Curso ${isEdit ? 'editado' : 'creado'} exitosamente.`, SnackbarType.Success)
      goTo(router.getRoute(ADMIN_COURSES_BLOCK + ADMIN_EDIT, { id: result.id }))
    } catch (err) {
      addSnackbar(err.message, SnackbarType.Warning)
    }
  }

  onGoBack = () => {
    this.props.goTo(ADMIN_COURSES)
  }

  render() {
    const { form, loaded, ...results } = this.state
    const props = {
      onSubmit: this.handleSubmit,
      onAttributeChange: this.onChange,
      results,
      form,
      loaded,
      onGoBack: this.onGoBack,
      ...this.props,
    }
    return (
      <CourseFormBasicData {...props} title={this.props.isEdit ? 'EDITAR CURSO' : 'CREAR CURSO'} />
    )
  }
}

AdminCoursesBasicFormContainer.defaultProps = {
  type: 'courses',
}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource
  return { ...states[ownProps.keyName] }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResource,
      getResources,
      createResource,
      updateResource,
      deleteResource,
      addSnackbar,
      goTo,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCoursesBasicFormContainer)
