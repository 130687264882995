import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import TextInput from '../../components/admin/inputs/TextInput'
import config from '../../config/index'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)

  return {
    header: {
      position: 'relative',
      backgroundSize: 'cover',
      height: '10%',
      minHeight: 350,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
    headerContent: {
      top: '4em',
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontFamily: 'PlaylistScript',
      fontSize: 48,
      fontWeight: 100,
      color: '#ffffff',
      margin: 'auto -8px',
      [mediaLg]: {
        fontSize: 40,
      },
    },
    arrow: {
      height: 100,
    },
    input: {
      backgroundColor: '#fff',
      borderRadius: '5px',
      padding: '1em 2em',
      margin: '2em 0',
      minWidth: 'unset',
    },
    inputContainerClass: {
      width: '60%',
      minWidth: 300,
    },
  }
}

const SearchHeader = ({ classes, onQueryChange }) => {
  const [state, setState] = useState({
    searchText: '',
    typing: false,
    typingTimeout: 0,
    filter: '',
  })

  let searchRef = useRef(null)

  const onChange = useCallback(
    (name, value) => {
      if (state.typingTimeout) {
        clearTimeout(state.typingTimeout)
      }
      setState({
        searchText: value,
        typing: false,
        typingTimeout: setTimeout(function () {
          onQueryChange(state.searchText)
        }, 850),
      })
    },
    [onQueryChange, state, setState],
  )

  useEffect(() => {
    if (searchRef.current) {
      setTimeout(() => searchRef.current && searchRef.current.focus(), 1000)
    }
  }, [searchRef])

  return (
    <header className={classes.header}>
      <div className={classes.headerContent}>
        <div className={classes.titleContainer}>
          <img
            aria-hidden={true}
            alt="Flecha Izquierda"
            src={config.BASE_URL + 'img/arrow_yellow_left.png'}
            className={classes.arrow}
          />
          <Typography component={'h1'} className={classes.title}>
            Qué querés aprender hoy?
          </Typography>
          <img
            aria-hidden={true}
            alt="Flecha Derecha"
            src={config.BASE_URL + 'img/arrow_pink_right.png'}
            className={classes.arrow}
          />
        </div>
        <TextInput
          inputRef={searchRef}
          name="text"
          type="search"
          value={state.searchText}
          placeholder="Buscar"
          aria-label={'Ingresa el nombre de un curso, categoría o etiqueta para filtrar resultados'}
          onChange={onChange}
          className={classes.input}
          containerClass={classes.inputContainerClass}
          autoFocus={true}
        />
      </div>
    </header>
  )
}

export default withStyles(styles)(SearchHeader)
