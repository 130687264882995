/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react'
import onEnterKey from '../../../../../../utils/onEnterKey'
import { classEditableSpanFieldSetter } from '../../../../../../utils/ActivitiesHelper'

const EditableSpan = ({ key, valueSetter, index, value, done, isCorrect, onFocus }) => {
  const [writting, setWritting] = useState(false)

  const executeValueSetter = e => {
    valueSetter(e.currentTarget.innerText.replaceAll('~', '-').trim(), index)
  }

  return (
    <span
      key={key}
      className={`${!value && !writting ? 'empty' : ''} blank field ${classEditableSpanFieldSetter(
        done,
        isCorrect,
      )}`}
      onKeyDown={e => {
        onEnterKey(e, () => {
          setWritting(true)
          executeValueSetter(e)
        })
      }}
      onBlur={e => {
        setWritting(false)
        executeValueSetter(e)
      }}
      onFocus={onFocus}
      contentEditable={!done}
    >
      {done ? (value?.length === '' ? ' ' : value) : value}
    </span>
  )
}

export default EditableSpan
