import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  createResource,
  updateResource,
  getResource,
} from '../../../actions/admin';
import {
  AdminItemViewType,
  EditInputType,
  SnackbarType,
  CategoryTypes
} from '../../../config/constants';
import AdminResourceForm from '../../../components/admin/AdminResourceForm';
import { addSnackbar } from '../../../actions/snackbar';
import { ADMIN_CATEGORIES } from '../../../config/routes';
import { goTo } from '../../../actions/navigator';
import validator from 'validator';
import { getBase64, getFileAsDataUrl } from '../../../utils/FileHelper';

class AdminCategoriesFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      name: '',
      description: '',
      color: '',
      icon: null,
      originalIcon: null,
      iconData: '',
      visible: '0',
    },
    errors: {
      name: '',
      description: '',
      color: '',
      icon: '',
      order: '',
      visible: '',
    },
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
    }
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.item && nextProps.item) {
      const {
        name,
        description = '',
        color,
        visible,
        featured,
        icon = null,
        types = [],
      } = nextProps.item;
      this.setState(
        {
          form: {
            name: name || '',
            description: description || '',
            color: color || '',
            visible: visible ? '1' : '0',
            featured: featured ? '1' : '0',
            originalIcon: icon,
            types: types || [],
          },
        },
        () => {
          this.validateForm();
          if (icon !== null) {
            return this.loadImageUrlAsUrlData(icon);
          }
        }
      );
    }
  };

  onAttributeChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } }, () =>
      this.validateForm()
    );

    if (name === 'icon') {
      if (value[0]) {
        return this.loadFileAsUrlData(value[0]);
      } else {
        this.setState({
          form: {
            ...this.state.form,
            iconData: this.state.form.originalIcon,
            icon: null,
          },
        });
      }
    }
  };

  getColumns = () => {
    const columns = [
      {
        title: 'Nombre',
        source: 'name',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'name' },
      },
      {
        title: 'Descripción',
        source: 'description',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'description' },
      },
      {
        title: 'Color Fondo',
        source: 'color',
        editable: true,
        editConfig: { type: EditInputType.Color, name: 'color' },
      },
      {
        title: 'Icono',
        source: 'icon',
        editable: true,
        editConfig: { type: EditInputType.File, accept: '.png', name: 'icon' },
      },
    ];
    if (this.state.form.iconData) {
      columns.push({
        title: 'Vista Previa',
        source: 'iconData',
        editable: false,
        type: AdminItemViewType.Image,
      });
    }
    return {
      keys: [
        ...columns,
        {
          title: 'Visible',
          source: 'visible',
          editable: true,
          editConfig: { type: EditInputType.Boolean, name: 'visible' },
        },
        {
          title: 'Destacada',
          source: 'featured',
          editable: true,
          editConfig: { type: EditInputType.Boolean, name: 'featured' },
        },
        {
          title: 'Tipo/s',
          source: 'types',
          editable: true,
          editConfig: {
            type: EditInputType.MultipleSelect,
            name: 'types',
            max: 5,
            multiple: true,
            options: CategoryTypes.map((t) => {
              return { id: t.value, description: t.title };
            }),
          },
        },
      ],
    };
  };

  loadImageUrlAsUrlData = async (sourceUrl) => {
    this.setState({ form: { ...this.state.form, iconData: sourceUrl } });
  };

  loadFileAsUrlData = async (file) => {
    const data = await getFileAsDataUrl(file);
    this.setState({ form: { ...this.state.form, iconData: data } });
  };

  getRequestDataFromForm = async () => {
    const { form } = this.state;
    const data = {
      name: form.name,
      description: form.description,
      color: form.color,
      visible: form.visible === '1',
      featured: form.featured,
      types: form.types,
    };

    if (form.icon) {
      data.icon = await getBase64(form.icon[0]);
    }

    return data;
  };

  onSubmit = async () => {
    const {
      addSnackbar,
      goTo,
      createResource,
      updateResource,
      isEdit,
      item,
      type,
    } = this.props;

    const data = await this.getRequestDataFromForm();
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          addSnackbar('Categoría editada exitosamente.', SnackbarType.Success);
          goTo(ADMIN_CATEGORIES);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    } else {
      createResource(type, data)
        .then(() => {
          addSnackbar('Categoría creada exitosamente.', '4');
          goTo(ADMIN_CATEGORIES);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    }
  };

  validateForm = () => {
    const { isEdit } = this.props;
    const form = this.state.form;
    let error = false;
    let errors = {
      name: '',
      description: '',
      color: '',
      icon: '',
      visible: '',
    };

    if (validator.isEmpty(form.name.trim())) {
      errors.name = 'Debes completar este campo';
      error = true;
    } else if (!validator.isLength(form.name.trim(), { min: 3, max: 40 })) {
      errors.name = 'Debes completar entre 3 y 40 caracteres.';
      error = true;
    }

    if (!validator.isLength(form.description.trim(), { min: 0, max: 100 })) {
      errors.description = 'Debes completar hasta 100 caracteres.';
      error = true;
    }

    if (validator.isEmpty(form.color.trim())) {
      errors.color = 'Debes seleccionar un color de fondo';
      error = true;
    }

    if (!form.types || form.types.length === 0 ) {
      errors.types = 'Debes seleccionar al menos un tipo';
      error = true;
    }

    if (!isEdit && !form.icon) {
      errors.icon = 'Debes seleccionar un ícono';
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_CATEGORIES);
  };

  render() {
    const { loading, title } = this.props;
    return (
      <AdminResourceForm
        keyName={'admin-categories-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    );
  }
}

AdminCategoriesFormContainer.defaultProps = {
  type: 'categories',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName],
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResource, createResource, updateResource, addSnackbar, goTo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCategoriesFormContainer);
