import { ACTIVITY_READ_END, ACTIVITY_READ_FAILED, ACTIVITY_READ_START } from './index'

import api from '../api/api'

export function setActivityDone(courseId, id) {
  return async dispatch => {
    try {
      const response = await api.withDispatch(dispatch).My.Activities.setDone(courseId, id)
      return response.data
    } catch (e) {
      console.error(e)
    }
    return false
  }
}

export function loadAllActivities(blockId) {
  return async dispatch => {
    dispatch({ type: ACTIVITY_READ_START })
    try {
      const response = await api.withDispatch(dispatch).ActivitiesSelect.all(blockId)

      dispatch({ type: ACTIVITY_READ_END, payload: { items: response.data } })
    } catch (e) {
      dispatch({ type: ACTIVITY_READ_FAILED, payload: { message: e } })
    }
  }
}
