import {
  CATEGORIES_READ_END,
  CATEGORIES_READ_FAILED,
  CATEGORIES_READ_START,
  CATEGORY_READ_END,
  CATEGORY_READ_FAILED,
  CATEGORY_READ_START,
} from './index';
import api from '../api/api';

export function loadCategoryById(id) {
  return async (dispatch) => {
    dispatch({ type: CATEGORY_READ_START });
    try {
      const response = await api.withDispatch(dispatch).Categories.get(id);
      const category = { ...response.data };
      if (category.course_categories) {
        category.courses = category.course_categories.map((cc) => cc.course);
      }
      dispatch({ type: CATEGORY_READ_END, payload: { category } });
    } catch (e) {
      dispatch({ type: CATEGORY_READ_FAILED, payload: { message: e } });
    }
  };
}

export function loadAllCategories() {
  return async (dispatch) => {
    dispatch({ type: CATEGORIES_READ_START });
    try {
      const response = await api.withDispatch(dispatch).Categories.all();
      dispatch({
        type: CATEGORIES_READ_END,
        payload: { items: response.data },
      });
    } catch (e) {
      dispatch({ type: CATEGORIES_READ_FAILED, payload: { message: e } });
    }
  };
}
