import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, withStyles } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import classNames from 'classnames'
import { AudienceType } from '../../../config/constants'

const styles = theme => ({
  root: {
    marginBottom: 30,
  },
  tableJobPositions: {
    marginTop: 20,
  },
  organizationalUnits: {
    marginTop: 20,
  },
  formControl: {
    width: '100%',
    margin: '20px 0',
    overflow: 'hidden',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  wrapperBoton: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  boton: {
    display: 'flex inline',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  autosuggest: {
    marginTop: 50,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },

  footerButtons: {
    paddingTop: '3em'
  },
  button: {
    margin: '1em',
    padding: '1em 2em',
    borderRadius: '5px',
    border: '1px solid #575454',
    maxWidth: '16em',
  },
})

const AdminExternalCoursesCommissionsStepJpOrOuAssignation = ({
  classes,
  jobPositions,
  jobPositionsSelected,
  handleChangeJobPosition,
  organizationalUnits,
  organizationalUnitsSelected,
  handleChangeOrganizationalUnit,
  allOrganizationalUnitsSelected,
  handleBack,
  handleSelectAllOu,
  onSubmit,
}) => {
  const { data: dataJob, columns: columnsJob } = jobPositions
  const { data: dataOrg, columns: columnsOrg } = organizationalUnits

  let localSelectedOu = organizationalUnitsSelected
  let localAllOuSelected = allOrganizationalUnitsSelected

  const [searchJp, setSearchJp] = useState()
  const [searchOu, setSearchOu] = useState()

  const handleClickJp = jobPositions => {
    handleChangeJobPosition(jobPositions)
  }

  const handleClickOu = organizationalUnit => {
    localSelectedOu = organizationalUnit
    handleChangeOrganizationalUnit(localSelectedOu)
    if (localAllOuSelected) {
      onSelectAllChange(false)
    }
  }

  const onSelectAllChange = allRowsSelected => {
    if (allRowsSelected) {
      localSelectedOu = []
      handleChangeOrganizationalUnit([])
    }
    localAllOuSelected = allRowsSelected
    handleSelectAllOu(allRowsSelected)
  }

  const setJpRowsSelected = () => {
    return dataJob
      .map((item, idx) => {
        if (jobPositionsSelected.filter(e2 => item.id === e2.id).length > 0) {
          return idx
        }
        return undefined
      })
      .filter(item => item !== undefined)
  }

  const optionsJp = () => ({
    download: false,
    print: false,
    rowsPerPage: 10,
    viewColumns: false,
    sortOrder: {
      name: 'id',
      direction: 'asc',
    },
    sort: true,
    filter: false,
    fixedHeader: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: 'none',
    searchText: searchJp,
    customSearch: (searchQuery, currentRow, columns) => {
      setSearchJp(searchQuery)
      let isFound = false
      currentRow.forEach(col => {
        if (
          col !== null &&
          col !== undefined &&
          col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
        ) {
          isFound = true
        }
      })
      return isFound
    },
    onSearchClose: () => {
      setSearchJp(false)
    },
    customSort: (data, colIndex, order) => {
      let rowsSelected = setJpRowsSelected()
      return data.sort((a, b) => {
        var dir = order === 'desc' ? 1 : -1
        var isASelected = dataJob.find(elem => {
          return rowsSelected.indexOf(a.index) !== -1
        })
        var isBSelected = dataJob.find(elem => {
          return rowsSelected.indexOf(b.index) !== -1
        })
        if (isASelected && !isBSelected) return 1 * dir
        if (!isASelected && isBSelected) return -1 * dir
        return (a.data[colIndex] > b.data[colIndex] ? -1 : 1) * dir
      })
    },
    rowsSelected: setJpRowsSelected(),
    onRowSelectionChange: (currentRowSelected, allRowsSelected) => {
      const ouItem = allRowsSelected.map(item => {
        return dataJob[item.dataIndex]
      })
      handleClickJp(ouItem)
    },
    textLabels: {
      body: {
        noMatch: 'Lo sentimos, no hay registros',
        toolTip: 'Orden',
        columnHeaderTooltip: column => `Orden para ${column.label}`,
      },
      selectedRows: {
        text: 'centro(s) de costos seleccionado(s)',
        delete: 'Borrar',
        deleteAria: 'Borrar seleccion',
      },
    },
  })

  const setOuRowsSelected = () => {
    if (allOrganizationalUnitsSelected) return dataOrg.map((item, idx) => idx)

    return dataOrg
      .map((item, idx) => {
        if (organizationalUnitsSelected.filter(e2 => item.id === e2.id).length > 0) {
          return idx
        }
        return undefined
      })
      .filter(item => item !== undefined)
  }

  const optionsOu = () => ({
    download: false,
    print: false,
    rowsPerPage: 10,
    filter: false,
    selectableRows: 'multiple',
    viewColumns: false,
    sortOrder: {
      name: 'id',
      direction: 'asc',
    },
    sort: true,
    fixedHeader: true,
    selectableRowsHeader: true,
    selectToolbarPlacement: 'none',
    rowsSelected: setOuRowsSelected(),
    searchText: searchOu,
    customSearch: (searchQuery, currentRow, columns) => {
      setSearchOu(searchQuery)
      let isFound = false
      currentRow.forEach(col => {
        if (
          col !== null &&
          col !== undefined &&
          col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
        ) {
          isFound = true
        }
      })
      return isFound
    },
    onSearchClose: () => {
      setSearchOu(false)
    },
    onRowSelectionChange: (currentRowSelected, allRowsSelected) => {
      if (dataOrg.length === allRowsSelected.length) {
        onSelectAllChange(true)
        return
      }
      const ouItem = allRowsSelected.map(item => {
        return dataOrg[item.dataIndex]
      })
      handleClickOu(ouItem)
    },
    customSort: (data, colIndex, order) => {
      let rowsSelected = setOuRowsSelected()

      return data.sort((a, b) => {
        var dir = order === 'desc' ? 1 : -1
        var isASelected = dataOrg.find(elem => {
          return rowsSelected.indexOf(a.index) !== -1
        })
        var isBSelected = dataOrg.find(elem => {
          return rowsSelected.indexOf(b.index) !== -1
        })
        if (isASelected && !isBSelected) return 1 * dir
        if (!isASelected && isBSelected) return -1 * dir
        return (a.data[colIndex] > b.data[colIndex] ? -1 : 1) * dir
      })
    },
    textLabels: {
      body: {
        noMatch: 'Lo sentimos, no hay registros',
        toolTip: 'Orden',
        columnHeaderTooltip: column => `Orden para ${column.label}`,
      },
      selectedRows: {
        text: 'filas(s) seleccionadas',
      },
    },
  })


  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.tableJobPositions}>
          <MUIDataTable
            title={'Puestos'}
            data={dataJob}
            columns={columnsJob}
            options={optionsJp()}
          />
        </Grid>

        <Grid item xs={12} className={classes.organizationalUnits}>
          <MUIDataTable
            title={'Centros de costos'}
            data={dataOrg}
            columns={columnsOrg}
            options={optionsOu()}
          />
        </Grid>
      </Grid>
      <p>
        * Recuerde que si los usuarios cambiaron de Puesto y centro de costo aparecerán los actuales
      </p>
      <Grid container spacing={3} className={classNames(classes.form, classes.footerButtons)}>
        <Grid className={classes.wrapperBoton}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleBack()}
            className={classes.button}
          >
            Atrás
          </Button>
          <Button
            disabled={jobPositionsSelected.length === 0 && organizationalUnitsSelected.length === 0}
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            className={classes.button}
          >
            {'Guardar'}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(
  AdminExternalCoursesCommissionsStepJpOrOuAssignation,
)
