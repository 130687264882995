import {
  ADMIN_USER_COMMISSION_READ_END,
  ADMIN_USER_COMMISSION_READ_START,
  ADMIN_USER_COMMISSION_READ_FAILED,
  ADMIN_USER_COMMISSION_UPDATE_END,
} from './index'

import api from '../api/api'

export function getUserCommission(id) {
  return async dispatch => {
    dispatch({ type: ADMIN_USER_COMMISSION_READ_START })
    try {
      const { data } = await api.withDispatch(dispatch).AdminUserCommission.getByCommission(id)
      dispatch({
        type: ADMIN_USER_COMMISSION_READ_END,
        payload: { userCommission: data },
      })
    } catch (error) {
      dispatch({
        type: ADMIN_USER_COMMISSION_READ_FAILED,
        error,
      })
    }
  }
}

export const setPresent = async (commissionDateId) => {
	const response = await new api.AdminUserCommission.setPresent(commissionDateId)
	return response
}