import React, { Component } from 'react';
import UserFinder from '../../components/ui/UserFinder';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EditInputObjectModel } from '../../config/constants';
import { searchUsersToShare, searchUsers, searchCategories } from '../../actions/autocomplete'


class UserFinderContainer extends Component {
  getOnFetchHanlder = () => {
    switch (this.props.model) {
      case EditInputObjectModel.UsersToShare:
        return this.props.searchUsersToShare
      case EditInputObjectModel.Users:
        return this.props.searchUsers
      default:
        return null
    }
  };

  getOnCreateHanlder = () => {
    switch (this.props.model) {
      case EditInputObjectModel.Tags:
        return this.props.createTags;
      case EditInputObjectModel.Categories:
        return this.props.createCategories;
      default:
        return null;
    }
  };

  render() {
    const {
      keyName,
      onItemSelected,
      placeholder,
      value,
      creatable,
      autoFocus,
    } = this.props;
    return (
      <UserFinder
        keyName={keyName}
        onFetch={this.getOnFetchHanlder()}
        onCreate={creatable && this.getOnCreateHanlder()}
        placeholder={placeholder}
        onItemSelected={onItemSelected}
        value={value}
        autoFocus={autoFocus}
        displayAttribute={['last_name', 'first_name', 'departmental_management']}
      />
    );
  }
}

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ searchUsersToShare, searchCategories }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFinderContainer);
