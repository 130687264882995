/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, IconButton, Tooltip, Typography, Card, CardContent } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import DoneIcon from '@material-ui/icons/CheckCircleOutline'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { ActivityType, ExternActivityTargetType } from '../../config/constants'
import RequiredActivitiesDialog from '../../containers/courses/RequiredActivitiesDialog'
import { activityTypeIcon, activityTypeText } from '../../utils/ActivitiesHelper'
import FullScreenDialog from '../ui/FullScreenDialog'
import H5pIframe from './H5pIframe'
import Iframe from './Iframe'
import ScormIframe, { geScormURL } from './ScormIframe'
import ActivityModuleFileContent from '../admin/course/ActivityModule/ActivityModuleFileContent'
import EventListenerWrapper from '../ui/EventListenerWrapper'
import ColorButton from '../ui/ColorButton'

import styles from './Styles/ActivityStyles'
import QuizTriviaActivity from './QuizTrivia/QuizTriviaActivity'

class Activity extends Component {
  callOnClickActivy = () => {
    const { onClick } = this.props
    onClick(true)
  }

  renderActivityMedia = () => {
    const { classes, activity } = this.props
    let activityType = activityTypeText(activity.type)
    return (
      <section className={classes.cardCover} aria-label={`Actividad tipo: ${activityType}`}>
        <div
          className={classes.iconWrapper}
          style={{ background: activity.color }}
          aria-hidden={true}
        >
          {activityTypeIcon(activity.type, classes.coverIcon)}
        </div>
      </section>
    )
  }

  renderActivityHeader = () => {
    const { activity, classes } = this.props

    return (
      <React.Fragment>
        <div>
          <Typography
            key="title"
            className={classes.title}
            component="h3"
            style={{
              wordBreak: 'break-word',
            }}
          >
            {activity.name || ''}
            {!activity.required && <span className={classes.required}> Opcional</span>}
          </Typography>

          <Typography
            style={{
              wordBreak: 'break-word',
            }}
            key="description"
            className={classes.shortDescription}
            component="p"
          >
            {activity.description || ''}
          </Typography>
        </div>
        {!activity.blocked &&
          !this.state.open &&
          activity.type !== ActivityType.QuizTrivia &&
          (activity.type == ActivityType.File && this.state.dataLoaded ? (
            <ColorButton
              label={'VISUALIZAR'}
              extraView={<VisibilityIcon className={classes.icon} />}
              className={classes.buttonView}
              onEnter={() => this.handleFullscreenDialog(true)}
              onClick={() => this.handleFullscreenDialog(true)}
            />
          ) : activity.type !== ActivityType.File ? (
            <Button
              key="button-open"
              className={classes.buttonOpen}
              role="button"
              variant="outlined"
            >
              ABRIR
            </Button>
          ) : (
            <div className={classes.content} style={{ float: 'right' }}>
              <ActivityModuleFileContent content={activity} />
            </div>
          ))}
        {!this.state.open && !ActivityType.QuizTrivia && (
          <div className={classes.buttonPlaceholder} />
        )}
      </React.Fragment>
    )
  }

  renderActivityContent = () => {
    const { classes, activity } = this.props

    switch (activity.type) {
      case ActivityType.File:
        return this.state.dataLoaded ? (
          <div>
            {
              <React.Fragment>
                {activity.target_type !== ExternActivityTargetType.NewTab ? (
                  <ColorButton
                    label="VISUALIZAR"
                    extraView={<VisibilityIcon className={classes.icon} />}
                    className={classes.buttonViewOpen}
                    onEnter={() => this.handleFullscreenDialog(true)}
                    onClick={() => this.handleFullscreenDialog(true)}
                  />
                ) : null}
                {this.state.fullScreenOpen ? (
                  <Iframe
                    open={this.state.fullScreenOpen}
                    onClose={() => this.handleFullscreenDialog(false)}
                    activity={activity}
                  />
                ) : null}
              </React.Fragment>
            }
          </div>
        ) : (
          <div className={classes.content} style={{ float: 'right' }}>
            <ActivityModuleFileContent content={activity} color={activity.color} />
          </div>
        )
        break
      case ActivityType.Audio:
      case ActivityType.Video:
      case ActivityType.Trivia:
      case ActivityType.Presentation:
      case ActivityType.Timeline:
        return (
          <div className={classes.content}>
            <H5pIframe activity={activity} markH5PActivityDone={this.callOnClickActivy} />
          </div>
        )

      case ActivityType.Text:
        const html = activity.text
        const self = this
        return (
          <div className={classes.content}>
            <div
              className={classes.textContentWrapper}
              style={{
                maxHeight: this.state.expanded ? '10000px' : '200px',
                overflow: this.state.expanded ? 'scroll' : 'hidden',
              }}
            >
              {!this.state.expanded && (
                <HTMLEllipsis
                  tabIndex={0}
                  role={'text'}
                  className={classes.text}
                  unsafeHTML={html}
                  maxLine="7"
                  ellipsis="..."
                  basedOn="letters"
                  onReflow={htmlState => {
                    if (!self.state.showExpand && !this.state.reflowed) {
                      self.setState({
                        showExpand: htmlState.clamped,
                        reflowed: true,
                      })
                    }
                  }}
                />
              )}
              {this.state.expanded && (
                <div
                  role="text"
                  className={classes.text}
                  dangerouslySetInnerHTML={{ __html: html }}
                  tabIndex={0}
                />
              )}
            </div>
            {this.state.showExpand && (
              <Typography
                role="button"
                className={classes.viewMore}
                style={{ color: activity.color }}
                onClick={() => this.setState({ expanded: !this.state.expanded })}
              >
                {this.state.expanded ? 'Leer menos' : 'Leer más'}
              </Typography>
            )}
          </div>
        )
      case ActivityType.Scorm:
      case ActivityType.External: {
        const isScorm = activity.type === ActivityType.Scorm
        const done =
          activity &&
          activity.user_activities &&
          activity.user_activities.length > 0 &&
          activity.user_activities[0].done !== 0

        return (
          <div className={classes.content}>
            {activity.target_type === ExternActivityTargetType.NewTab ? (
              <Button
                tabIndex={0}
                className={classes.openNewTabButton}
                onClick={() => this.handleOpenTabUrl()}
                variant="outlined"
                aria-label="Abrir en nueva pestaña"
              >
                ABRIR
              </Button>
            ) : (
              <React.Fragment>
                {!done && <div className={classes.iframeOverlay} />}
                {activity.target_type === ExternActivityTargetType.Embedded &&
                  (isScorm ? (
                    <ScormIframe activity={activity} tabIndex={0} />
                  ) : (
                    <iframe
                      title={activity.name}
                      src={activity.url}
                      height={400}
                      className={classes.embeddedContent}
                      tabIndex={0}
                    />
                  ))}
                {activity.target_type !== ExternActivityTargetType.NewTab && done ? (
                  <Tooltip title="EXPANDIR">
                    <EventListenerWrapper
                      tabIndex={0}
                      onEnter={() => this.handleFullscreenDialog(true)}
                      onClick={() => this.handleFullscreenDialog(true)}
                    >
                      <IconButton
                        className={classes.fullscreen}
                        aria-label="abrir en pantalla completa"
                      >
                        <FullscreenIcon />
                      </IconButton>
                    </EventListenerWrapper>
                  </Tooltip>
                ) : null}
                <FullScreenDialog
                  open={this.state.fullScreenOpen}
                  onClose={() => this.handleFullscreenDialog(false)}
                >
                  {this.state.fullScreenOpen &&
                    (isScorm ? (
                      <ScormIframe activity={activity} tabIndex={0} />
                    ) : (
                      <iframe
                        width="100%"
                        height="100%"
                        title={activity.name}
                        src={activity.url}
                        tabIndex={0}
                      />
                    ))}
                </FullScreenDialog>
              </React.Fragment>
            )}
          </div>
        )
      }
      case ActivityType.Image:
        return (
          <div className={classes.content}>
            <figure className={classes.imageWrapper}>
              <img alt={activity.name} src={activity.url} className={classes.imageContent} />
            </figure>
          </div>
        )
      default:
        return null
    }
  }

  state = {
    buttonOn: false,
    dataLoaded: false,
    open: false,
    reflowed: false,
    showExpand: false,
    expanded: false,
    dialogOpen: false,
    fullScreenOpen: false,
    h5pTypes: [
      ActivityType.Audio,
      ActivityType.Presentation,
      ActivityType.Timeline,
      ActivityType.Video,
      ActivityType.Trivia,
    ],
  }

  onClickWrapper = () => {
    const { onClick, activity } = this.props
    if (!activity.done && activity.blocked === 0) {
      onClick()
      this.handleOpenActivity()
    } else if (activity.blocked === 0) {
      this.handleOpenActivity()
    }
  }

  handleOpenActivity = () => {
    if (!this.state.open) {
      this.setState({ open: true }, () => {
        const { activity } = this.props
        if (activity.type === ActivityType.External || activity.type === ActivityType.Scorm) {
          if (activity.target_type === ExternActivityTargetType.NewTab) {
            this.handleOpenTabUrl()
          } else if (activity.target_type === ExternActivityTargetType.FullScreen) {
            this.handleFullscreenDialog(true)
          }
        }
      })
    }
  }

  handleFullscreenDialog = async open => {
    this.setState({ fullScreenOpen: open })
    if (!open) {
      const { reloadBlock } = this.props
      await reloadBlock()
    }
  }

  handleOpenTabUrl = () => {
    const { activity } = this.props
    if (activity.type === ActivityType.Scorm) {
      window.open(geScormURL(activity), '_blank')
    } else {
      window.open(activity.url, '_blank')
    }
  }

  checkActivityH5p(val) {
    return this.state.h5pTypes.some(item => val === item)
  }

  componentDidMount() {
    const { activity } = this.props
    if (this.checkActivityH5p(activity.type)) {
      this.setState({ open: true })
    }

    if (activity.url && activity.url.endsWith && activity?.url.endsWith('.pdf')) {
      fetch(activity.url, {
        method: 'HEAD',
      })
        .then(response => {
          if (response.ok) {
            const contentType = response.headers.get('Content-Type')
            this.setState({ dataLoaded: contentType === 'application/pdf' })
          }
        })
        .catch(error => {
          console.log('Error al recuperar encabezados de archivo:', error)
          throw error
        })
    }
  }

  render() {
    const { classes, activity, markActivityAsDone } = this.props
    return (
      <EventListenerWrapper
        TagType="div"
        onEnter={this.onClickWrapper}
        onClick={this.onClickWrapper}
      >
        <Card
          className={
            classes.card + ' ' + classes.card + '-' + (this.state.open ? 'open' : 'closed')
          }
        >
          {this.renderActivityMedia()}
          <div className={classes.cardContentWrapper}>
            <CardContent className={classes.cardContent} component="section">
              {activity.blocked !== 0 && (
                <EventListenerWrapper
                  className={classes.blocked}
                  onClick={() => {
                    this.setState({ dialogOpen: true })
                  }}
                  onEnter={() => {
                    this.setState({ dialogOpen: true })
                  }}
                >
                  <LockIcon aria-label="actividad bloqueada" className={classes.lock} />
                </EventListenerWrapper>
              )}
              {!!activity.done && (
                <DoneIcon
                  className={classes.done}
                  aria-label="actividad completada"
                  aria-live="polite"
                />
              )}
              {this.renderActivityHeader()}
              {activity.type === ActivityType.QuizTrivia ? (
                <div style={{ position: 'static' }} className={classes.content}>
                  <QuizTriviaActivity
                    activity={
                      !!activity.preview
                        ? { ...activity, attempts_count: 0, results: undefined }
                        : activity
                    }
                    markActivityAsDone={markActivityAsDone}
                  />
                </div>
              ) : (
                this.state.open && this.renderActivityContent()
              )}
            </CardContent>
          </div>
          {activity.blocked !== 0 && (
            <RequiredActivitiesDialog
              activity={activity}
              tabIndex={0}
              open={this.state.dialogOpen}
              onDismiss={() => {
                this.setState({ dialogOpen: false })
              }}
              headerColor={activity.color}
            />
          )}
        </Card>
      </EventListenerWrapper>
    )
  }
}

export default withStyles(styles)(Activity)
