import React, { Component } from 'react'
import AdminResourceIndex from '../../components/admin/AdminResourceIndex'
import {
  makeQuery,
  getTemplateFromModel,
  saveTemplateFromModel,
  deleteFilterTemplate,
} from '../../actions/admin'
import { addSnackbar } from '../../actions/snackbar'
import { changeQuery } from '../../actions/paginator'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { appLoadToken } from '../../actions/app'

class AdminResourceIndexContainer extends Component {
  state = {
    filters: {},
    templateData: {},
    enableDownloadXls: false,
  }

  componentDidMount() {
    this.handleFetch(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = props => {
    const {
      fetch,
      filter,
      onFetch,
      keyName,
      page,
      size,
      getFilterConfig,
      getOrderingConfig = () => [],
    } = props

    if (fetch && filter) {
      onFetch(keyName, makeQuery(filter, getFilterConfig(), getOrderingConfig()), page, size)
    }
  }

  getFilterTemplates = () => {
    const { getTemplateFromModel, keyName } = this.props
    let model = keyName.replace('/', '_')
    return getTemplateFromModel(model)
  }

  applyFilter = filters => {
    const { keyName, changeQuery, getFilterConfig, getOrderingConfig = () => [] } = this.props
    this.setState({ filters: filters }, () =>
      changeQuery(keyName, makeQuery(filters, getFilterConfig(), getOrderingConfig())),
    )
  }

  deleteFilterTemplate = async templateId => {
    const { deleteFilterTemplate } = this.props

    await deleteFilterTemplate(templateId)
    this.getFilterTemplates()
  }

  saveNewTemplate = async data => {
    const { saveTemplateFromModel } = this.props

    await saveTemplateFromModel(data)
    this.getFilterTemplates()
  }

  render() {
    const {
      children,
      keyName,
      getActions,
      columns,
      title,
      customTop,
      customBottom,
      onFetch,
      paginator,
      customItems,
      getFilterConfig = () => false,
      filterTemplates = () => false,
      onCreate = null,
      onCreateTopCourses = null,
      sort,
      forceNoActions = false,
      onDownload,
      onGenerateReport,
      onDownloadXls,
      isBooleanDocuement = false,
      addSnackbar,
      wrapperClass,
    } = this.props
    return (
      <AdminResourceIndex
        keyName={keyName}
        onFilterChange={this.applyFilter}
        onGetFilterTemplates={this.getFilterTemplates}
        onFetch={onFetch}
        items={
          isBooleanDocuement && !!paginator.items
            ? paginator.items.map(el => {
                const newElement = el.map ? { ...el, map: 'Sí' } : { ...el, map: 'No' }
                return newElement
              })
            : customItems || paginator.items
        }
        loading={paginator.loading}
        getActions={getActions}
        columns={columns}
        title={title}
        customTop={customTop}
        customBottom={customBottom}
        onFiltersChange={this.applyFilter}
        onSaveNewTemplate={this.saveNewTemplate}
        onDeleteFilterTemplate={this.deleteFilterTemplate}
        filters={this.state.filters}
        getFilterConfig={getFilterConfig}
        filterTemplates={filterTemplates}
        onCreate={onCreate}
        onCreateTopCourses={onCreateTopCourses}
        sort={sort}
        forceNoActions={forceNoActions}
        onDownload={onDownload}
        onGenerateReport={onGenerateReport}
        onDownloadXls={onDownloadXls}
        addSnackbar={addSnackbar}
        enableDownloadXls={this.state.enableDownloadXls}
        disableDownloadButton={
          paginator.total >= (process.env.REACT_APP_REPORT_DOWNLOAD_MAX_SIZE || 100)
        }
        wrapperClass={wrapperClass}
      >
        {[children]}
      </AdminResourceIndex>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.paginatorReducer
  return {
    ...state.filterTemplateReducer,
    paginator: { ...states[ownProps.keyName] },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeQuery,
      appLoadToken,
      getTemplateFromModel,
      saveTemplateFromModel,
      deleteFilterTemplate,
      addSnackbar,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminResourceIndexContainer)
