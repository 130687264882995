import {
    SW_INIT,
    SW_UPDATE,
} from '../actions';

let initialState = {
    initialized: false,
    updated: false,
    registration: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SW_INIT:
            return {
                ...state,
                initialized: !state.initialized,
            };
        case SW_UPDATE:
            return {
                ...state,
                updated: !state.updated,
                registration: action.payload,
            };
        default:
            return {...state};
    }
};
