/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import MenuDrawer from '../menu/MenuDrawer';
import { IconButton, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@material-ui/icons/Close';
import { reorder } from '../course/DraggableUtils';

const styles = {
  drawerContainer: {
    position: 'absolute',
    right: 0,
    top: 64,
    height: '93%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1em',
    boxSizing: 'border-box',
  },
  activityTitle: {
    padding: '1em 0 1em 1em',
    boxSizing: 'border-box',
  },
  blockTitle: {
    fontWeight: 700,
    padding: '1em 0',
  },
  body: {
    padding: '0 1em',
    boxSizing: 'border-box',
    height: '100%',
  },
  drawerPaper: {
    width: 480,
    position: 'relative',
    height: '100%',
    minHeight: '100%',
    paddingTop: '16px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #dedede',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
};

const DrawerCustomContent = ({
  classes,
  sortedCourses,
  showDrawer,
  handleSubmitOrder,
  onDragEnd,
}) => {

  return (
    <React.Fragment>
      <div className={classes.toolbar}>
        <IconButton onClick={showDrawer}>
          <CloseIcon />
        </IconButton>
        <Button onClick={handleSubmitOrder}>GUARDAR</Button>
      </div>
      <div className={classes.body}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppableCoursesContainer"
            style={{ height: '100%' }}
          >
            {(containerProvided) => (
              <div ref={containerProvided.innerRef}>
                {/* style={{ height: '100%' }} */}
                {sortedCourses &&
                  sortedCourses.map((course, index) => {
                    return (
                      <Draggable
                        draggableId={course.id}
                        index={index}
                        key={course.type + '_' + course.id}
                      >
                        {(courseProvided) => (
                          <div
                            className={classes.row} //HERNAN
                            ref={courseProvided.innerRef}
                            {...courseProvided.draggableProps}
                            {...courseProvided.dragHandleProps}
                          >
                            <Typography className={classes.blockTitle}>
                              {course.name} {(course.type == 2) ? `(Taller en vivo)` : `(Curso online)`}
                            </Typography>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {containerProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </React.Fragment>
  );
};

const WrappedDrawerCustomContent = withStyles(styles)(DrawerCustomContent);

class CoursesDrawer extends Component {
  state = {
    sortedCourses: [],
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.courses && nextProps.courses) {
      this.setState({ sortedCourses: nextProps.courses });
    }
  };

  handleSubmitOrder = () => {
    this.props.onSubmit(this.state.sortedCourses);
  };

  onDragEnd = ({ source, destination }) => {
    const { sortedCourses } = this.state;

    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const items = reorder(sortedCourses, source.index, destination.index);
      this.setState({ sortedCourses: items });
    }
    return;
  };

  render() {
    const { classes, drawer, showDrawer } = this.props;
    const { sortedCourses } = this.state;

    return (
      <div>
        <MenuDrawer
          customClasses={classes}
          mobileOpen={drawer}
          customContent={
            <WrappedDrawerCustomContent
              sortedCourses={sortedCourses}
              showDrawer={showDrawer}
              handleSubmitOrder={this.handleSubmitOrder}
              onDragEnd={this.onDragEnd}
            />
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(CoursesDrawer);
