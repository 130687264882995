import {
  FEATURED_CATEGORIES_READ_END,
  FEATURED_CATEGORIES_READ_START,
  FEATURED_CATEGORIES_READ_FAILED,
} from '../../actions';

let initialState = {
  error: null,
  loading: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FEATURED_CATEGORIES_READ_END:
      const { items } = action.payload;

      const categories = [];
      items.forEach((course) => {
        course.categories.forEach((category) => {
          if (category.featured) {
            let exist = categories.find((c) => c.id === category.id);
            if (!exist) {
              const copyCat = { ...category };
              categories.push(copyCat);
              copyCat.courses = [];
              exist = copyCat;
            }
            exist.courses.push(course);
          }
        });
      });

      return {
        loading: false,
        items: categories,
        error: null,
      };
    case FEATURED_CATEGORIES_READ_START:
      return {
        items: [],
        error: null,
        loading: true,
      };
    case FEATURED_CATEGORIES_READ_FAILED:
      const { message } = action.payload;
      return {
        items: [],
        error: message,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
