/* eslint-disable no-undef */
import ImageIcon from '@material-ui/icons/CropOriginal'
import TextIcon from '@material-ui/icons/Subject'
import SchoolIcon from '@material-ui/icons/School'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import AudioIcon from '@material-ui/icons/Headset'
import QuestionIcon from '@material-ui/icons/HelpOutline'
import WorldIcon from '@material-ui/icons/Language'
import TimelineIcon from '@material-ui/icons/Timeline'
import SlideshowIcon from '@material-ui/icons/Dvr'
import FileIcon from '@material-ui/icons/Description'
import AddIcon from '@material-ui/icons/Add'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'

export const LOG_NETWORK = String(process.env.REACT_APP_LOG_NETWORK) === 'true'

export const Paginator = Object.freeze({
  PAGE_SIZE: 50,
})

export const FrontNavBarActionTypes = Object.freeze({
  Home: 'home',
  Search: 'search',
  Profile: 'profile',
  AdminHome: 'admin-home',
  Explore: 'explore',
  NextEvents: 'next-events',
  CareerPlans: 'me/career-plans',
  ExploreCareerPlans: 'explore-career-plans',
  Playlists: 'playlists',
})

export const AdminItemViewType = Object.freeze({
  Text: 'text',
  Number: 'number',
  Avatar: 'avatar',
  Image: 'image',
  Boolean: 'boolean',
  Fixed: 'fixed',
  Conditional: 'conditional',
  Formatted: 'formatted',
  Date: 'date',
  Datetime: 'datetime',
  Action: 'action',
  Background: 'background',
  Custom: 'custom',
})

export const EditInputType = Object.freeze({
  Color: 'input-color',
  Background: 'input-background',
  Text: 'input-text',
  Number: 'input-number',
  Password: 'input-password',
  Boolean: 'input-boolean',
  Object: 'input-object',
  Fixed: 'input-fixed',
  Formatted: 'input-formatted',
  Select: 'input-select',
  LazySelect: 'input-lazy-select',
  MultipleSelect: 'input-multiple-select',
  Date: 'input-date',
  Datetime: 'input-datetime',
  File: 'input-file',
  Dropzone: 'input-dropzone',
  TextEditor: 'input-text-editor',
  RadioGroup: 'input-radio-group',
  Switch: 'input-switch',
  Custom: 'input-custom',
})

export const EditInputObjectModel = Object.freeze({
  Tags: 'model-tags',
  Categories: 'model-categories',
  Users: 'model-users',
  Providers: 'model-providers',
  UsersToShare: 'model-users-to-share',
  Courses: 'model-courses',
  OrganizationalUnit: 'model-organizationalUnit',
  JobPosition: 'model-jobPosition',
  Facilitators: 'model-facilitators',
  PublicExternalCourses: 'model-public-external-courses',
  Places: 'model-places',
  CommissionPlaces: 'model-commission-places',
  ExternalReferencesProviders: 'model-externalReferencesProviders',
  ExternalReferencesReferents: 'model-externalReferencesReferents',
})

export const SnackbarType = Object.freeze({
  Generic: 0,
  Error: 1,
  Warning: 2,
  Info: 3,
  Success: 4,
})

export const FilterType = Object.freeze({
  Fixed: 'fixed',
  Dynamic: 'dynamic',
})

export const FiltersFormat = Object.freeze({
  Like: 'like',
  Is: 'IS',
  Null: 'null',
  Plain: 'plain',
  PlainOR: 'plain_or',
  In: 'in',
  MayorIgual: 'mayor_igual',
  MenorIgual: 'menor_igual',
  Desde: 'desde',
  Hasta: 'hasta',
  DesdeNps: 'desdeNps',
  HastaNps: 'hastaNps',
  FullSearch: 'full_search',
  Fecha: 'fecha',
})

export const ActivityType = Object.freeze({
  Text: 'Text',
  Image: 'Image',
  External: 'Extern',
  Scorm: 'Scorm',
  Audio: 'Audio',
  Video: 'Video',
  Trivia: 'Trivia',
  Timeline: 'TimeLine',
  Presentation: 'Presentation',
  File: 'File',
  Select: 'existingActivity',
  QuizTrivia: 'QuizTrivia',
})

export const Roles = Object.freeze({
  Admin: 'ROLE_ADMIN',
  Reporting: 'ROLE_REPORTING',
  OpenBankadmin: 'ROLE_ADMIN_OPEN_BANK',
  OpenBankGeneral: 'ROLE_GENERAL_OPEN_BANK',
  OpenBankReporing: 'ROLE_REPORTING_OPEN_BANK',
})

export const RolList = Object.freeze([
  {
    value: 'ROLE_ADMIN',
    title: 'Administrador',
  },
  {
    value: 'ROLE_REPORTING',
    title: 'Reportería',
  },
  {
    value: 'ROLE_ADMIN_OPEN_BANK',
    title: 'Administrador',
  },
  {
    value: 'ROLE_GENERAL_OPEN_BANK',
    title: 'General',
  },
  {
    value: 'ROLE_REPORTING_OPEN_BANK',
    title: 'Reportería',
  },
])

export const JobTypes = Object.freeze([
  {
    value: 1,
    title: 'Datos de centros de costos',
    file: { name: 'file', type: '.csv' },
  },
  /*
  {
    value: 2,
    title: 'Historial de Talleres',
    file:{ name:'user_commissions', type:'.csv' }
  },
  */
  {
    value: 3,
    title: 'Valoraciones de comisiones',
    file: { name: 'rating_commissions', type: '.csv' },
  },
  {
    value: 6,
    title: 'Importador de presentimos por clase',
    file: { name: 'user_commission_assist', type: '.csv' },
  },
  {
    value: 7,
    title: 'Importador de talleres',
    file: { name: 'import_external_courses', type: '.csv' },
  },
  {
    value: 8,
    title: 'Importador de comisiones por taller',
    file: { name: 'import_commission_by_external_course', type: '.csv' },
  },
  {
    value: 9,
    title: 'Importador de curso online',
    file: { name: 'import_online_course', type: '.csv' },
  },
  {
    value: 10,
    title: 'Importador formaciones externas',
    file: { name: 'import_exterbal_formations', type: '.csv' },
  },
])

export const CategoryTypes = Object.freeze([
  {
    value: 1,
    title: 'Cursos online',
  },
  {
    value: 2,
    title: 'Talleres',
  },
])

export const ExternalCourseTypes = Object.freeze([
  {
    value: 'Taller',
    title: 'Taller',
  },
  {
    value: 'Curso',
    title: 'Curso',
  },
  {
    value: 'Webinar/Charla/Conferencia',
    title: 'Webinar/Charla/Conferencia',
  },
])

export const ActivitiesTypesList = Object.freeze([
  {
    type: ActivityType.Text,
    description: 'Texto',
    icon: TextIcon,
    title: 'ACTIVIDAD DE LECTURA',
  },
  {
    type: ActivityType.Image,
    description: 'Imagen',
    icon: ImageIcon,
    title: 'ACTIVIDAD DE IMAGEN',
  },
  {
    type: ActivityType.External,
    description: 'Externa',
    icon: WorldIcon,
    title: 'ACTIVIDAD EXTERNA',
  },
  {
    type: ActivityType.Scorm,
    description: 'SCORM',
    icon: SchoolIcon,
    title: 'ACTIVIDAD SCORM',
  },
  {
    type: ActivityType.Audio,
    description: 'Audio',
    icon: AudioIcon,
    title: 'ACTIVIDAD DE AUDIO',
  },
  {
    type: ActivityType.Video,
    description: 'Video',
    icon: PlayIcon,
    title: 'ACTIVIDAD DE VIDEO',
  },
  {
    type: ActivityType.Trivia,
    description: 'Cuestionario',
    icon: QuestionIcon,
    title: 'ACTIVIDAD DE CUESTIONARIO',
  },
  {
    type: ActivityType.QuizTrivia,
    description: 'Formulario',
    icon: LiveHelpIcon,
    title: 'ACTIVIDAD DE FORMULARIO',
  },
  {
    type: ActivityType.Presentation,
    description: 'Presentación interactiva',
    icon: SlideshowIcon,
    title: 'ACTIVIDAD DE PRESENTACIÓN',
  },
  {
    type: ActivityType.Timeline,
    description: 'Línea temporal',
    icon: TimelineIcon,
    title: 'ACTIVIDAD DE LÍNEA DE TIEMPO',
  },
  {
    type: ActivityType.File,
    description: 'Archivo',
    icon: FileIcon,
    title: 'ACTIVIDAD DE ARCHIVO',
  },
  {
    type: ActivityType.Select,
    description: 'ACTIVIDAD PRE-EXISTENTE',
    icon: AddIcon,
    title: 'Seleccionar Actividad',
  },
])

export const ActivityTypeToH5pLibrary = Object.freeze({
  [ActivityType.Audio]: 'H5P.Audio 1.5',
  [ActivityType.Video]: 'H5P.InteractiveVideo 1.24',
  [ActivityType.Trivia]: 'H5P.QuestionSet 1.20',
  [ActivityType.Timeline]: 'H5P.Timeline 1.1',
  [ActivityType.Presentation]: 'H5P.CoursePresentation 1.24',
})

export const CourseStatusValue = Object.freeze({
  Draft: 1,
  Published: 2,
  Pending: 3,
})

export const CourseStatus = Object.freeze({
  [CourseStatusValue.Draft]: 'Borrador',
  [CourseStatusValue.Published]: 'Publicado',
  [CourseStatusValue.Pending]: 'Pendiente',
})

export const ExternalCourseStatus = Object.freeze({
  Draft: 1,
  Published: 2,
  UnPublished: 3,
})

export const ExternalCourseStatusName = Object.freeze({
  [ExternalCourseStatus.Draft]: 'Borrador',
  [ExternalCourseStatus.Published]: 'Publicado',
  [ExternalCourseStatus.UnPublished]: 'No Publicado',
})

export const CommissionStatus = Object.freeze({
  RegistrationOpen: 'OPEN_REGISTRATION',
  RegistrationClosed: 'CLOSED_REGISTRATION',
  InProcess: 'IN_PROCESS',
  Finished: 'FINISHED',
  Cancelled: 'CANCELLED',
})

export const CommissionStatusName = Object.freeze({
  [CommissionStatus.RegistrationOpen]: 'Inscripción abierta',
  [CommissionStatus.RegistrationClosed]: 'Inscripción cerrada',
  [CommissionStatus.InProcess]: 'En curso',
  [CommissionStatus.Finished]: 'Finalizada',
  [CommissionStatus.Cancelled]: 'Cancelada',
})

export const CommissionStatusDescription = Object.freeze(
  Object.keys(CommissionStatusName).map(k => ({ id: k, description: CommissionStatusName[k] })),
)

export const ScalesName = Object.freeze({
  ESCALA_1: 'Escala 1',
  ESCALA_2: 'Escala 2',
  ESCALA_3: 'Escala 3',
  ESCALA_4: 'Escala 4',
  ESCALA_5: 'Escala 5',
  ESCALA_6: 'Escala 6',
})

export const CourseProgressStatus = Object.freeze({
  NOT_STARTED: 'Pendientes',
  COMPLETED: 'Finalizados',
  IN_PROGRESS: 'En curso',
})

export const CourseMandatoriesOptions = Object.freeze({
  0: 'Complementarios',
  1: 'Necesarios',
})

export const CourseProgress = Object.freeze({
  true: 'Finalizado',
  false: 'Iniciado',
})

export const CourseProgressValue = Object.freeze({
  Completed: true,
  Incomplete: false,
})

export const ExternActivityTargetType = Object.freeze({
  Embedded: 1,
  FullScreen: 2,
  NewTab: 3,
})

export const HorizontalSlideArrowType = Object.freeze({
  right: 'right',
  left: 'left',
})

export const CareerPlanStatus = Object.freeze({
  1: 'Borrador',
  2: 'Publicado',
})

export const CareerPlanTypes = Object.freeze({
  Global: 1,
  Assigned: 2,
})

export const CareerPlanSources = Object.freeze({
  Autoassigned: 1,
  ChosenByUser: 2,
})

export const CareerPlanTypeNames = Object.freeze({
  [CareerPlanTypes.Global]: 'Global',
  [CareerPlanTypes.Assigned]: 'Asignado',
})

export const ImportJobsStatus = Object.freeze([
  { id: '-2', description: 'Ejecución fallida' },
  { id: '-1', description: 'Inactivo' },
  { id: '0', description: 'Pendiente' },
  { id: '1', description: 'En proceso' },
  { id: '2', description: 'Finalizado' },
])

export const rateRange = Object.freeze([
  { id: '0', description: '0' },
  { id: '1', description: '1' },
  { id: '2', description: '2' },
  { id: '3', description: '3' },
  { id: '4', description: '4' },
  { id: '5', description: '5' },
  { id: '6', description: '6' },
  { id: '7', description: '7' },
  { id: '8', description: '8' },
  { id: '9', description: '9' },
  { id: '10', description: '10' },
])

export const LotStatus = Object.freeze({
  Draft: 1,
  Published: 2,
})

export const LotStatusName = Object.freeze({
  [LotStatus.Draft]: 'Borrador',
  [LotStatus.Published]: 'Publicado',
})

export const LotType = Object.freeze({
  JpCc: 1,
  Users: 2,
  Company: 3,
})

export const LotTypes = Object.freeze({
  [LotType.JpCc]: 'JP/CC',
  [LotType.Users]: 'Usuarios',
  [LotType.Company]: 'Empresa',
})

export const LotCategory = Object.freeze({
  1: 'Requeridos',
  3: 'PVI',
  4: 'Objetivo D+I',
})

export const ActiveType = Object.freeze({
  0: 'NO',
  1: 'SÍ',
})

export const FacilitatorType = Object.freeze({
  Multiplier: 1,
  Speaker: 2,
  ExternalProvider: 3,
  ExternalInstitution: 4,
})
export const ReferentProviderType = Object.freeze({
  Provider: 1,
  Referent: 2,
})
export const FacilitatorTypes = Object.freeze([
  { id: FacilitatorType.Multiplier, description: 'Multiplicador', plural: 'Multiplicadores' },
  { id: FacilitatorType.Speaker, description: 'Speaker', plural: 'Speakers' },
  {
    id: FacilitatorType.ExternalProvider,
    description: 'Proveedor Externo',
    plural: 'Proveedores Externos',
  },
  {
    id: FacilitatorType.ExternalInstitution,
    description: 'Universidad/Instituto',
    plural: 'Universidades/Institutos',
  },
])

export const ReferentProviderTypes = Object.freeze([
  { id: ReferentProviderType.Provider, description: 'Proveedor', plural: 'Proveedores' },
  { id: ReferentProviderType.Referent, description: 'Referente', plural: 'Referentes' },
])

export const CourseType = Object.freeze({
  Course: 'COURSE',
  ExternalCourse: 'EXTERNAL_COURSE',
})

export const UserCommissionStatus = Object.freeze({
  Subscribed: 1,
  Unsubscribed: 2,
  Present: 3,
  Absent: 4,
  JustifiedDeserted: 5,
  Deserted: 6,
  Cancelled: 7,
})

export const UserCommissionComplete = Object.freeze({
  Complete: 1,
})

export const UserCommissionStatusName = Object.freeze({
  [UserCommissionStatus.Subscribed]: 'Inscripto',
  [UserCommissionStatus.Unsubscribed]: 'Inscripción cancelada',
  [UserCommissionStatus.Present]: 'Presente',
  [UserCommissionStatus.Absent]: 'Ausente',
  [UserCommissionStatus.JustifiedDeserted]: 'Baja justificada',
  [UserCommissionStatus.Deserted]: 'Abandonado',
  [UserCommissionStatus.Cancelled]: 'Comisión cancelada',
})

export const UserCommissionStatusDescription = Object.freeze([
  { id: UserCommissionStatus.Subscribed, description: 'Inscripto' },
  { id: UserCommissionStatus.Unsubscribed, description: 'Inscripción cancelada' },
  { id: UserCommissionStatus.Present, description: 'Presente' },
  { id: UserCommissionStatus.Absent, description: 'Ausente' },
  { id: UserCommissionStatus.JustifiedDeserted, description: 'Baja justificada' },
  { id: UserCommissionStatus.Deserted, description: 'Abandonado' },
  { id: UserCommissionStatus.Cancelled, description: 'Comisión cancelada' },
])

export const UserCommissionAssistanceType = Object.freeze({
  Complete: 1,
  Incomplete: 2,
  Unstarted: 3,
})

export const UserCommissionAssistanceName = Object.freeze({
  [UserCommissionAssistanceType.Complete]: 'Completo',
  [UserCommissionAssistanceType.Incomplete]: 'Incompleto',
  [UserCommissionAssistanceType.Unstarted]: 'No iniciado',
})

export const UserCommissionAssistanceDescription = Object.freeze(
  Object.keys(UserCommissionAssistanceName).map(k => ({
    id: parseInt(k),
    description: UserCommissionAssistanceName[k],
  })),
)

export const UserCommissionWaitingListStatus = Object.freeze({
  Waiting: 1,
  Assigned: 2,
  Cancelled: 3,
})

export const CommissionsActions = {
  Subscribe: 'Subscribe',
  Unsubscribe: 'Unsubscribe',
  SubscribeOnWaitingList: 'SubscribeOnWaitingList',
  Done: 'Done',
}

export const AudienceType = Object.freeze({
  Aimed: 1,
  Open: 2,
})

export const AudienceAssignationType = Object.freeze({
  CostCenter: 1,
  Users: 2,
})

export const AudienceAssignationTypes = Object.freeze([
  { id: AudienceAssignationType.CostCenter, description: 'Puestos de trabajo / Centro de costos' },
  { id: AudienceAssignationType.Users, description: 'Usuarios' },
])

export const AssignationType = Object.freeze({
  Subscribe: 1,
  Suggest: 2,
})
export const AssignationTypes = Object.freeze([
  { id: AssignationType.Subscribe, description: 'Inscribir' },
  { id: AssignationType.Suggest, description: 'Sugerir' },
])

export const Audiences = Object.freeze([
  { id: AudienceType.Aimed, description: 'Dirigido' },
  { id: AudienceType.Open, description: 'Abierto' },
])

export const AudiencesName = Object.freeze({
  [1]: 'Dirigido',
  [2]: 'Abierto',
})

export const AudiencesDescription = Object.freeze(
  Object.keys(AudiencesName).map(k => ({ id: k, description: AudiencesName[k] })),
)

export const ModalityType = Object.freeze({
  Remote: 1,
  Presential: 2,
})

export const ModalitiesName = Object.freeze({
  [ModalityType.Remote]: 'Remoto',
  [ModalityType.Presential]: 'Presencial',
})

export const Modalities = Object.freeze([
  { id: ModalityType.Remote, description: 'Remoto' },
  { id: ModalityType.Presential, description: 'Presencial' },
])

export const ModalitiesDescription = Object.freeze(
  Object.keys(ModalitiesName).map(k => ({ id: parseInt(k), description: ModalitiesName[k] })),
)

export const ReasonsType = Object.freeze({
  Retention: 1,
  RequiredByJp: 2,
  Development: 3,
  Interest: 4,
})

export const Reasons = Object.freeze([
  { id: ReasonsType.Retention, description: 'Retención' },
  { id: ReasonsType.RequiredByJp, description: 'Necesario para puesto' },
  { id: ReasonsType.Development, description: 'Desarrollo' },
  { id: ReasonsType.Interest, description: 'Interés' },
])

export const PlaceType = Object.freeze({
  Classroom: 1,
  Address: 2,
  Online: 3,
})

export const SlidesTemplateType = Object.freeze({
  LogoTextActions: 1,
  TitleTextActionsVertical: 2,
  TitleTextActionsHorizontal: 3,
  ImageOnly: 4,
})

export const SlidesTemplates = Object.freeze([
  { id: SlidesTemplateType.LogoTextActions, description: 'Logo, descripción y acciones centradas' },
  {
    id: SlidesTemplateType.TitleTextActionsVertical,
    description: 'Título, descripción y acciones centradas',
  },
  {
    id: SlidesTemplateType.TitleTextActionsHorizontal,
    description: 'Título y descripción con alineación izquierda y acciones a la derecha',
  },
  {
    id: SlidesTemplateType.ImageOnly,
    description: 'Solo imagen',
  },
])

export const CalendarEventType = Object.freeze({
  Google: 1,
  Outlook: 2,
})

export const PlaylistGroup = Object.freeze({
  Any: 'ANY',
  MyPlaylists: 'MY_PLAYLISTS',
  MyFollowedPlaylists: 'MY_FOLLOWED_PLAYLISTS',
  OtherPeoplePlaylists: 'OTHER_PEOPLE_PLAYLISTS',
  TopFollowedPlaylists: 'TOP_FOLLOWED_PLAYLISTS',
  UserPlaylists: 'USER_PLAYLISTS',
})

export const COLOR_KEYS = Object.freeze({
  PRIMARY: '#1ED1A8',
  BLACK: '#000000',
  WHITE: '#ffffff',
  CORRECT: '#1fd11fab',
  INCORRECT: '#d11f1fab',
  TEXT: 'rgba(0, 0, 0, 0.87)',
  ERROR: '#9e0000',
})

export const STYLE_KEYS = Object.freeze({
  DEFAULT_TRANSITION: 'all ease-in-out 300ms',
  QUESTIONS_LABEL: {
    fontSize: '0.75rem',
  },
  QUESTIONS_INPUT: {
    fontSize: '1rem',
    color: COLOR_KEYS.BLACK,
    fontWeight: 600,
  },
  QUESTIONS_VALIDATION: {
    fontSize: '0.75rem',
    color: COLOR_KEYS.ERROR,
  },
})

export const ONLY_NUMBER_LETTER_REGEX_KEY = '[A-z]|[0-9]'

export const DoneOptions = Object.freeze([
  { id: 0, description: 'Incompleto' },
  { id: 1, description: 'Completo' },
])
