import moment from 'moment'

const getMonthName = month =>{
  const names = ['','Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  return names[month];
}

const generateGoogleCalendarLink = event => {

  let eventLink = 'http://www.google.com/calendar/event?action=TEMPLATE&';
  const params = [];
  if (event.name) {
    params.push(`text=Taller: ${event.name}`);
  }
  if (event.from && event.to) {
    params.push(`dates=${moment(event.from).format('YYYYMMDDTHHmmss')}/${moment(event.to).format('YYYYMMDDTHHmmss')}`);
  }
  if (event.place) {
    params.push(`location=${event.place}`);
  }
  if (event.description) {
    params.push(`details=${event.description}`);
  }
  return eventLink + encodeURI(params.join('&'));
}

const generateOutlookCalendarLink = event => {

  let eventLink = 'https://outlook.office.com/calendar/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&';
  const params = [];

  if (event.name) {
    params.push(`subject=Taller: ${event.name}`);
  }
  if (event.place) {
    params.push(`location=${event.place}`);
  }
  if (event.from && event.to) {
    params.push(`startdt=${moment(event.from).format('YYYY-MM-DDTHH:mm:ss')}`);
    params.push(`enddt=${moment(event.to).format('YYYY-MM-DDTHH:mm:ss')}`);
  }

  if (event.description) {
    params.push(`body=${event.description}`);
  }
  return eventLink + encodeURI(params.join('&'));
}

export {
  getMonthName, generateGoogleCalendarLink, generateOutlookCalendarLink
}
