import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminResourceIndex from '../AdminResourceIndex';
import { downloadReport, getResources } from '../../../actions/admin';
import { goTo } from '../../../actions/navigator';
import {
  AdminItemViewType,
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  UserCommissionStatusDescription,
  UserCommissionAssistanceDescription,
} from '../../../config/constants'

const KEY_NAME = 'admin-commissions-users';

class AdminCommissionsUsers extends Component {
  onFetch = (keyName, query, page, size) => {
    const { getResources, commissionId } = this.props;
    getResources(`commissions/${commissionId}/users`, keyName, query, page, size);
  }

  getColumns = () => {
    return [
      {
        title: 'Nombre y Apellido',
        source: 'user.first_name user.last_name',
        type: AdminItemViewType.Formatted,
        format: '{1}, {0}',
      },
      {
        title: 'Usuario',
        source: 'user.record',
        type: AdminItemViewType.Text,
      },
      {
        title: 'Email',
        source: 'user.email',
        type: AdminItemViewType.Text,
      },
      { title: 'Puesto', source: 'user.job_position_name' },
      { title: 'Gerencia principal', source: 'user.organizational_unit.main_management' },
      { title: 'Área cliente interno', source: 'user.organizational_unit.internal_area' },
      {
        title: 'Centro de costos',
        source: 'user.org_unity_identifier',
        type: AdminItemViewType.Text,
      },
      {
        title: 'Estado',
        source: 'status_name',
        type: AdminItemViewType.Text,
      },
    ];
  }

  getActions = item => {
    return [];
  }

  onDownload = async () => {
    const {
      commissionId,
      downloadReport,
      paginator: { query },
    } = this.props;
    downloadReport(query, `reports/commissions/${commissionId}/users`);
  }

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        placeholder: 'Nombre, Apellido o Usuario',
        format: FiltersFormat.FullSearch,
        fsModel: 'user',
        fields: ['firstName', 'lastName', 'username'],
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.id',
        placeholder: 'Centro de Costo',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'code',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.JobPosition,
        name: 'user.jobPosition',
        placeholder: 'Puesto',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'description',
      },
      {
        type: EditInputType.Select,
        name: 'userCommission.status',
        placeholder: 'Estado',
        format: FiltersFormat.PlainOR,
        options: UserCommissionStatusDescription,
      },
      {
        type: EditInputType.Select,
        name: 'userCommission.assistance',
        placeholder: 'Asistencia',
        format: FiltersFormat.PlainOR,
        options: UserCommissionAssistanceDescription,
      },
    ]
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'INSCRIPTOS'}
        getFilterConfig={this.getFilterConfig}
        getActions={this.getActions}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={null}
        onDownload={this.onDownload}
        forceNoActions={true}
      />
    );
  }
}

AdminCommissionsUsers.defaultProps = {
  type: 'commissions_users',
};

const mapStateToProps = state => {
  return {
    paginator: { ...state.paginatorReducer[KEY_NAME] },
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResources, goTo, downloadReport }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCommissionsUsers);
