import React, { Component } from 'react';
import config from '../../config/index';

const STEP_OFFSET = 1500;
class ParallaxBackground extends Component {
  state = {
    offsetY: 0,
    steps: 1,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScrollEventListener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollEventListener);
  }

  onScrollEventListener = (e) => {
    this.setState({
      offsetY: window.pageYOffset,
      steps: Math.floor(window.pageYOffset / STEP_OFFSET) + 1,
    });
  };

  render() {
    const { offsetY, steps } = this.state;
    const images = [];
    for (let s = 0; s < steps; s++) {
      const stepOffset = s * STEP_OFFSET;
      const dY = offsetY - stepOffset;
      const par = s % 2 === 0;
      images.push({
        img: 'img/bg_arrow_left.png',
        x: par ? 'calc(20% - 150px)' : 'calc(20% - 50px)',
        y: stepOffset + dY * 0.35,
      });
      images.push({
        img: 'img/bg_arrow_right.png',
        x: par ? '60%' : 'calc(70% - 150px',
        y: stepOffset - 250 + dY * -0.25,
      });
      images.push({
        img: 'img/bg_arrow_right.png',
        x: -80 + (par ? -100 : -15),
        y: stepOffset + 650 + dY * -0.25,
      });
      images.push({
        img: par ? 'img/bg_arrow_up.png' : 'img/bg_arrow_down.png',
        x: 'calc(100% - 275px)',
        y: stepOffset + 450 + dY * (par ? -1 : 1) * 0.25,
      });
    }
    return (
      <div aria-hidden="true" style={{ width: '100%', height: '100%', position: 'relative' }}>
        {images.map((img, i) => (
          <img
            aria-hidden={true}
            key={'img-bg-' + i}
            src={config.BASE_URL + img.img}
            alt={'bg_arrow'}
            style={{ top: img.y, left: img.x, position: 'absolute' }}
          />
        ))}
      </div>
    );
  }
}
export default ParallaxBackground;
