import React from 'react'
import { COLOR_KEYS } from '../../../../config/constants'
import { Typography } from '@material-ui/core'

const ApprovalStatus = ({ activityState }) => {
  return (
    <div className="row" style={{ justifyContent: 'flex-end' }}>
      {activityState.results.approved ? (
        <Typography
          style={{
            fontWeight: 700,
            color: COLOR_KEYS.CORRECT,
          }}
        >
          Aprobado
        </Typography>
      ) : (
        <Typography
          style={{
            fontWeight: 700,
            color: COLOR_KEYS.INCORRECT,
          }}
        >
          Insuficiente
        </Typography>
      )}
    </div>
  )
}

export default ApprovalStatus
