import api from './api/api';
import {
  APP_LOAD_TOKEN,
  AUTH_ERROR,
  GENERIC_ERROR,
  USER_LOGIN_END,
  USER_LOGOUT_END, USER_LOGOUT_FAILED,
} from './actions';
import { push } from 'connected-react-router';
import { HOME, IDFW, TOKEN, IDFWOB } from './config/routes'

const allowedUrls = [HOME, IDFW, TOKEN, IDFW + '/', TOKEN + '/', IDFWOB, IDFWOB + '/']

const localStorageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case USER_LOGIN_END:
      if (!action.error) {
        window.localStorage.setItem('jwt', action.payload.token);
        window.sessionStorage.setItem('reset_pasword', action.payload.reset_pasword);
        api.setToken(action.payload.token);
        api.setReset_pasword(action.payload.reset_pasword);
        const redirect = window.localStorage.getItem('redirect');
        window.localStorage.setItem('redirect_lock', '');
        window.localStorage.setItem('redirect', HOME);
        window.location.replace(redirect)
      }
      break;
    case USER_LOGOUT_END:
    case USER_LOGOUT_FAILED:
      window.localStorage.setItem('redirect_lock', '');
      window.localStorage.setItem('jwt', '');
      window.localStorage.setItem('cookies', '');
      window.localStorage.setItem('redirect', HOME);
      api.setToken(null);
      break;
    case APP_LOAD_TOKEN:
      let token = window.localStorage.getItem('jwt');
      const currentPath = store.getState().router.location.pathname;
      if (!token && !allowedUrls.includes(currentPath)) {
        window.localStorage.setItem('redirect_lock', 'lock');
        window.localStorage.setItem('jwt', '');
        window.localStorage.setItem('cookies', '');
        window.localStorage.setItem('redirect', currentPath + store.getState().router.location.search);
        api.setToken(null);
        next(push(HOME));
      }
      api.setToken(token);
      break;
    case GENERIC_ERROR:
      console.log('GENERIC_ERROR:', action.payload);
      break;
    case AUTH_ERROR:
      {
        const currentPath = store.getState().router.location.pathname;
        if (window.localStorage.getItem('redirect_lock') === '') {
          window.localStorage.setItem('redirect_lock', 'lock');
          const redirect = currentPath;
          window.localStorage.setItem('jwt', '');
          window.localStorage.setItem('cookies', '');
          window.localStorage.setItem('redirect', redirect + store.getState().router.location.search );
          api.setToken(null);
          next(push(HOME));
        } else {
          if (currentPath !== HOME) {
            api.setToken(null);
            next(push(HOME));
          }
        }
      }
      break;
    default:
      break;
  }

  next(action);
};

export { localStorageMiddleware };
