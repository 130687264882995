import React, { Component } from 'react'
import Header from './HeaderCourse'
import ContentCourseContainer from '../../containers/courses/ContentCourse'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: 'calc(100vw - 0%)',
    paddingBottom: 50,
  },
})

class Course extends Component {
  render() {
    const {
      classes,
      course,
      loading,
      preview = false,
      handleCourseDone,
      courseDone,
      addSnackbar,
      onRemoveSharedCourse,
    } = this.props
    let courseColor = '#bababa'
    if (course && course.categories && course.categories.length > 0) {
      courseColor = course.categories[0].color
    }
    return (
      <div className={classes.root}>
        <Header
          course={course}
          loading={loading}
          courseDone={courseDone}
          courseColor={courseColor}
          preview={preview}
          addSnackbar={addSnackbar}
          onRemoveSharedCourse={onRemoveSharedCourse}
        />
        {course && (
          <ContentCourseContainer
            keyName={'content-course-' + course.id}
            course={course}
            courseColor={courseColor}
            preview={preview}
            handleCourseDone={() => handleCourseDone()}
          />
        )}
      </div>
    )
  }
}
export default withStyles(styles)(Course)
