import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { EditInputType } from '../../config/constants'
import _ from 'lodash'
import { itemEdit } from '../../utils/AdminHelper'
import { makeQuery } from '../../actions/admin'
import Button from '@material-ui/core/Button'
import { Grid, Typography } from '@material-ui/core'

const styles = theme => ({
  content: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  grid: {
    flexGrow: 1,
    flexDirection: 'row',
  },

  filter: {
    MuiInputBase: {
      input: {
        padding: '6px 0 9px',
      },
    },
  },

  filterChip: {
    marginTop: 0,
  },

  filterButton: {
    marginRight: 8,
    boxShadow: 'none',
  },
  wrapperButton: {
    marginTop: 10,
  },
  btnClear: {
    maxWidth: 80,
    maxHeight: 30,
    padding: '3px 9px',
    borderRadius: 15,
    backgroundColor: '#838282',
    fontFamily: 'Open Sans',
    fontSize: 12,
    textTransform: 'capitalize',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#ffffff',
    borderWidth: 0,
  },
  header: {
    position: 'relative',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    height: 60,
    backgroundColor: '#868686',
    color: '#fff',
  },
  headerClosed: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  headerIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#fff',
  },
  title: {
    color: '#fff',
    fontSize: 20,
    paddingTop: 16,
    paddingLeft: 16,
  },
  filterContent: {
    width: '100%',
    minHeight: 60,
    backgroundColor: '#fff',
  },
  sectionFilter: {
    paddingTop: '20px ',
    paddingLeft: '5.5%',
    paddingRight: '5.5%',
  },
  sectionFilters: {
    flexGrow: 1,
  },
  labelSectionFilters: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#707070',
    marginBottom: 10,
  },
})

class Filters extends Component {
  state = {
    open: false,
    filters: {},
  }

  getFilterByConfig = f => {
    return itemEdit(f, this.state.filters[f.name], this.handleOnChange)
  }

  componentDidMount = () => {
    this.loadFilters(this.props)
  }

  componentWillReceiveProps = nextProps => {
    const { filters = {} } = this.props
    if (!_.isEqual(filters, this.state.filters)) {
      this.loadFilters(nextProps)
    }
  }

  loadFilters = props => {
    const { filters = false, getFilterConfig } = props
    if (filters !== false) {
      const fixedFilters = getFilterConfig().filter(f => f.type === EditInputType.Fixed)
      fixedFilters.forEach(f => (filters[f.name] = f.fixedValue))
      this.setState({ filters: filters }, () => {
        this.applyFilters()
      })
    }
  }

  handleOnChange = (name, value) => {
    const { filters } = { ...this.state }
    const currentState = filters

    if (value == null || value === '') {
      delete currentState[name]
    } else if (Array.isArray(value)) {
      currentState[name] = value.length ? [...value] : undefined
    } else if (typeof value === 'object') {
      currentState[name] = { ...value }
    } else {
      currentState[name] = value
    }
    this.setState({ filters: currentState })
  }

  applyFilters = () => {
    const { fetchData } = this.props
    const { getFilterConfig } = this.props
    const getOrderingConfig = () => []
    let query = makeQuery(this.state.filters, getFilterConfig(), getOrderingConfig)
    if (query) {
      fetchData(query)
    } else {
      fetchData([])
    }
  }

  clearFilters = () => {
    this.setState({ filters: {} }, () => this.applyFilters())
  }

  render() {
    const { getFilterConfig, classes } = this.props // AGregado el tab Index
    const filtersConfig = getFilterConfig && getFilterConfig()

    const filtersComponent = filtersConfig && (
      <Grid container className={classes.sectionFilter}>
        <Grid container item xs={12}>
          <Typography component={'h1'} tabIndex={0} className={classes.labelSectionFilters}>
            FILTRAR
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            container
            className={classes.sectionFilters}
            item
            xs={12}
            lg={10}
            justify="space-between"
          >
            <Grid container spacing={10}>
              {filtersConfig
                .filter(f => f.type !== EditInputType.Fixed)
                .map(f => {
                  return (
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={4}
                      xs={6}
                      key={'key-filter-' + f.name}
                      className={classes.filter}
                    >
                      {this.getFilterByConfig(f, 0)}
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={1} justify="flex-end" className={classes.wrapperButton}>
            <Button
              tabIndex={0}
              arial-label="Aplicar filtros"
              variant="contained"
              size="small"
              component="span"
              onClick={this.applyFilters}
              className={classes.btnClear}
            >
              Aplicar
            </Button>
          </Grid>

          <Grid container item xs={12} lg={1} justify="flex-end" className={classes.wrapperButton}>
            <Button
              tabIndex={0}
              arial-label="Limpiar filtros"
              variant="contained"
              size="small"
              component="span"
              onClick={this.clearFilters}
              className={classes.btnClear}
            >
              Limpiar x
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )

    return filtersConfig && filtersComponent
  }
}
export default withStyles(styles)(Filters)
