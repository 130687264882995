import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createResource, updateResource, getResource} from '../../../actions/admin'
import {
  EditInputType,
  SnackbarType,
  ReferentProviderTypes,
  ReferentProviderType,
  EditInputObjectModel,
} from '../../../config/constants'
import AdminResourceForm from '../../../components/admin/AdminResourceForm'
import {addSnackbar} from '../../../actions/snackbar'
import {ADMIN_REFERENTS_PROVIDERS} from '../../../config/routes'
import {goTo} from '../../../actions/navigator'

class AdminReferentsProvidersFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      external_reference_type: '',
      user: null,
      provider: null,
      enabled: true,
    },
    errors: {
      external_reference_type: '',
      user: '',
      provider: '',
      enabled: '',
    },
  }

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData()
    }
  }

  onLoadData = () => {
    const {type, getResource, params, keyName} = this.props
    getResource(keyName, type, params.id)
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = nextProps => {
    if (!this.props.item && nextProps.item) {
      const {external_reference_type, user, external_reference, enabled, provider} =
        nextProps.item
      this.setState(
        {
          form: {
            external_reference_type: external_reference_type || '',
            user: user || null,
            provider: provider || null,
            external_reference: external_reference || '',
            enabled: enabled,
          },
        },
        () => {
          this.validateForm()
        },
      )
    }
  }

  onAttributeChange = (name, value) => {
    this.setState({form: {...this.state.form, [name]: value}}, () => {
      this.validateForm()
    })
  }

  getColumns = () => {
    const keys = [
      {
        title: 'Tipo',
        source: 'external_reference_type',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          name: 'external_reference_type',
          options: ReferentProviderTypes,
        },
      },
    ]

    if (ReferentProviderType.Referent === this.state.form.external_reference_type) {
      keys.push({
        title: 'Usuario',
        source: 'user',
        editable: true,
        editConfig: {
          type: EditInputType.Object,
          model: EditInputObjectModel.Users,
          name: 'user',
          displayAttribute: 'full_name',
        },
      })
    }
    if (ReferentProviderType.Provider === this.state.form.external_reference_type) {
      keys.push({
        title: 'Proveedor',
        source: 'provider',
        editable: true,
        editConfig: {
          type: EditInputType.Object,
          model: EditInputObjectModel.Providers,
          name: 'provider',
          displayAttribute: 'name',
        },
      })
    }
    if (this.props.isEdit) {
      keys.push({
        source: 'enabled',
        editable: true,
        value: 'enabled',
        editConfig: {
          name: 'enabled',
          type: EditInputType.Switch,
          label: 'Habilitado',
        },
      })
    }
    return {
      keys,
    }
  }

  getRequestDataFromForm = async () => {
    const {form} = this.state
    const commonData = {
      external_reference_type: form.external_reference_type,
      enabled: form.enabled,
    }
    if (form.external_reference_type === ReferentProviderType.Provider) {
      return {
        ...commonData,
        provider_id: form.provider.id,
      }
    }
    if (form.external_reference_type === ReferentProviderType.Referent) {
      return {
        ...commonData,
        user_id: form.user.id,
      }
    }
    addSnackbar('Error: ' + 'Revise el formulario', SnackbarType.Error)
  }

  onSubmit = async () => {
    const {addSnackbar, goTo, createResource, updateResource, isEdit, item, type} = this.props

    const data = await this.getRequestDataFromForm()
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          if (data?.external_reference_type == 1) {
            addSnackbar('El proveedor editado correctamente.', SnackbarType.Success)
          } else {
            addSnackbar('Referente editado exitosamente.', SnackbarType.Success)
          }
          goTo(ADMIN_REFERENTS_PROVIDERS)
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error)
        })
    } else {
      createResource(type, data)
        .then(() => {
          if (data?.external_reference_type == 1) {
            addSnackbar('Proveedor creado exitosamente.', '4')
          } else {
            addSnackbar('Referente creado exitosamente.', '4')
          }
          goTo(ADMIN_REFERENTS_PROVIDERS)
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error)
        })
    }
  }

  validateForm = () => {
    const form = this.state.form
    let error = false
    let errors = {
      external_reference_type: '',
      user: '',
      provider: '',
    }

    if (!form.external_reference_type) {
      errors.external_reference_type = 'Debes seleccionar un tipo'
      error = true
    } else if ([ReferentProviderType.Referent].includes(form.external_reference_type)) {
      if (!form.user) {
        errors.user = 'Debes seleccionar un usuario'
        error = true
      }
    }
    if ([ReferentProviderType.Provider].includes(form.external_reference_type)) {
      if (!form.provider) {
        errors.provider = 'Debes seleccionar un proveedor'
        error = true
      }
    }

    this.setState({errors: errors, isValid: !error})
    return !error
  }

  onGoBack = () => {
    this.props.goTo(ADMIN_REFERENTS_PROVIDERS)
  }

  render() {
    const {loading, title} = this.props
    return (
      <AdminResourceForm
        keyName={'admin-external-references-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    )
  }
}

AdminReferentsProvidersFormContainer.defaultProps = {
  type: 'external_references',
}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource
  return {
    ...states[ownProps.keyName],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {getResource, createResource, updateResource, addSnackbar, goTo},
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReferentsProvidersFormContainer)
