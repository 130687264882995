import React, { Component } from 'react';

export default class CssBackgroundPreview extends Component {
  render() {
    const { onClick = () => {}, value = '#ffffff' } = this.props;
    return (
      <div
        style={{
          background: value,
          width: 34,
          height: 34,
          borderStyle: 'solid',
          borderColor: '#554d5633',
          borderRadius: 2,
          borderWidth: 1,
        }}
        onClick={onClick}
      />
    );
  }
}
