import React, { Component } from 'react'
import { MuiThemeProvider, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { chips_theme } from '../../themes/chips_theme'

const styles = theme => ({
  chip: {
    marginTop: 3,
    marginBottom: 8,
    marginRight: 4,
    color: '#363636',
    fontWeight: 700,
    backgroundColor: 'rgba(255,255,255,0.65)',
    fontSize: 8,
    padding: 2,
    paddingRight: 6,
    paddingLeft: 6,
    borderRadius: 10,
    float: 'left',
  },
});

class TagChip extends Component {
  render() {
    const { classes, label, className = false, tabIndex = 0 } = this.props
    return (
      <MuiThemeProvider theme={chips_theme}>
        <Typography
          tabIndex={tabIndex}
          role={'text'}
          className={className ? className + ' ' + classes.chip : classes.chip}
        >
          {label}
        </Typography>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(TagChip)
