import React, { useState, useCallback, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ZoomGrid from '../ui/ZoomGrid'
import ParallaxBackground from '../ui/ParallaxBackground'
import MixedCourseItem from '../ui/MixedCourseItem'
import TabBar from '../ui/TabBar'
import { CourseType } from '../../config/constants'
const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    paddingTop: 32,
    paddingBottom: 50,
  },
})

const initialTabs = [
  { type: 'ALL', title: 'Todos', active: true, visible: true },
  { type: CourseType.Course, title: 'Cursos online', active: false, visible: true },
  { type: CourseType.ExternalCourse, title: 'Talleres en vivo', active: false, visible: true },
]

const CoursesExploreContent = ({ classes, onShowCourse, courses }) => {
  const [tabs, updateTabs] = useState([...initialTabs])

  const onItemClick = useCallback(
    item => {
      onShowCourse(item)
    },
    [onShowCourse],
  )

  const onCreateItemCourse = (item) => {
    return <MixedCourseItem item={item} itemClick={onItemClick} />
  }

  const onChageTab = useCallback(
    index => {
      updateTabs(
        initialTabs.map((t, i) => {
          t.active = i === index
          return t
        }),
      )
    },
    [updateTabs],
  )

  const filterCourses = useCallback(() => {
    const activeTab = tabs.find(t => t.active)
    const activeType = activeTab.type
    if (activeType !== 'ALL') {
      return courses.filter(c => c.type === activeType)
    }
    return courses
  }, [courses, tabs])

  const filterTabs = useCallback(() => {
      updateTabs(
      initialTabs.map(t => {
        t.visible = t.type === 'ALL' || courses.find(c => c.type === t.type) !== undefined
        return t
      }),
    )
  }, [courses, updateTabs])

  useEffect(() => {
    filterTabs()
  },[filterTabs])

  return (
    <main id="main" className={classes.root} tabIndex={-1}>
      <ParallaxBackground />
        {(tabs.filter(t => t.visible).length > 2 ) && <TabBar
            style={{textAlign: 'center', marginBottom: 30}}
            tabProps={{
                style: {
                    padding: 16,
                },
            }}
            tabs={tabs}
            onChageTab={onChageTab}
        />
        }
      <ZoomGrid
        ariaLabel={'Listado de cursos y/o talleres'}
        items={filterCourses()}
        itemCreator={onCreateItemCourse}
        itemHeight={165}
        itemWidth={290}
      />
    </main>
  )
}
export default withStyles(styles)(CoursesExploreContent)
