import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles, Typography, IconButton, Button, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleRounded'
import ListRoundedIcon from '@material-ui/icons/Reorder'
import VisibilityOutlinedIcon from '@material-ui/icons/Visibility'
import PublishIcon from '@material-ui/icons/Publish'
import CloseIcon from '@material-ui/icons/Close'
import ActivityModal from './ActivityModal/ActivityModal'
import BlockModal from './BlockModal'
import MilestoneModal from './MilestoneModal'
import BlockSection from './BlockSection'
import MenuDrawer from '../menu/MenuDrawer'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { reorder, move, orderArrayBySort } from './DraggableUtils'
import { CourseStatusValue } from '../../../config/constants'
import GetAppIcon from '@material-ui/icons/GetApp'

const styles = {
  addBlock: {
    marginBottom: '8em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blackFilter: {
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, .2)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  courseTitle: {
    color: '#fff',
    fontSize: 32,
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    padding: '1em',
    boxSizing: 'border-box',
  },
  contentContainer: {
    width: '72%',
  },
  divider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '2em',
    width: '100%',
  },
  header: {
    display: 'flex',
    width: '100%',
    padding: '1em 8em',
    boxSizing: 'border-box',
    background: '#454545',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '1em',

    '&:after': {
      // eslint-disable-next-line quotes
      content: "''",
      width: '100%',
      borderBottom: '1px solid #707070',
    },
  },
  mainImage: {
    display: 'flex',
    width: '100%',
    height: '16em',
    marginBottom: '2em',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#dedede',
    position: 'relative',
    boxSizing: 'border-box',
  },
  titleHeader: {
    color: '#fff',
    fontSize: 20,
  },
  topHorizontalMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#d6d6d6',
    width: '100%',
    height: '4em',
  },
  topHorizontalMenuItems: {
    color: '#707070',
    display: 'flex',
    flexDirection: 'row',
    margin: '0 1em',
    alignItems: 'center',
  },
  // Drawer custom classes:
  drawerContainer: {
    position: 'absolute',
    right: 0,
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1em',
    boxSizing: 'border-box',
    marginBottom: '2em',
  },
  activityTitle: {
    padding: '1em 0 1em 1em',
    boxSizing: 'border-box',
  },
  blockTitle: {
    fontWeight: 700,
    padding: '1em 0',
  },
  body: {
    padding: '0 1em',
    boxSizing: 'border-box',
    height: '100%',
  },
  drawerPaper: {
    width: 480,
    position: 'relative',
    height: '100%',
    minHeight: '100%',
    paddingTop: '16px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #dedede',
  },
}

const DrawerCustomContent = ({
  classes,
  blocksOrder,
  blocks,
  allActivities,
  showDrawer,
  handleSubmitOrder,
  onDragEnd,
  drawerContentKey,
}) => (
  <React.Fragment>
    <div className={classes.toolbar}>
      <IconButton onClick={showDrawer}>
        <CloseIcon />
      </IconButton>
      <Button onClick={handleSubmitOrder}>GUARDAR</Button>
    </div>
    <div className={classes.body}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableBlockContainer">
          {provided => (
            <div ref={provided.innerRef}>
              {blocks.length &&
                blocksOrder &&
                blocksOrder.length &&
                blocksOrder.map((blockOrdered, index) => {
                  const block = blocks.find(item => item.id === blockOrdered.id)
                  if (drawerContentKey === 'blocksDrawer') {
                    return (
                      <Draggable draggableId={block.id} index={index} key={block.id}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.row}
                          >
                            <Typography className={classes.blockTitle}>{block.title}</Typography>
                          </div>
                        )}
                      </Draggable>
                    )
                  } else if (drawerContentKey === 'activitiesDrawer') {
                    return (
                      <React.Fragment key={block.id}>
                        <div className={classes.row}>
                          <Typography className={classes.blockTitle}>{block.title}</Typography>
                        </div>
                        <Droppable
                          droppableId={`droppableBlock-${block.id}`}
                          style={{ height: '100%' }}
                          isCombineEnabled={true}
                        >
                          {provided => (
                            <div ref={provided.innerRef}>
                              {blockOrdered.activities &&
                                blockOrdered.activities.length &&
                                blockOrdered.activities.map((activityOrdered, index) => {
                                  const activity = allActivities.find(
                                    item => item.id === activityOrdered,
                                  )
                                  return (
                                    <Draggable
                                      draggableId={activity.id}
                                      index={index}
                                      key={activity.id}
                                    >
                                      {draggableActivityProvided => (
                                        <div
                                          ref={draggableActivityProvided.innerRef}
                                          className={classes.row}
                                          key={activity.id}
                                          {...draggableActivityProvided.draggableProps}
                                          {...draggableActivityProvided.dragHandleProps}
                                        >
                                          <Typography className={classes.activityTitle}>
                                            {activity.activity.name}
                                          </Typography>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </React.Fragment>
                    )
                  }
                  return null
                })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  </React.Fragment>
)

class CourseFormBlockContent extends Component {
  state = {
    selectedActivity: null,
    selectedBlock: null,
    drawer: false,
    drawerContentKey: '',
    selectedMilestone: null,
    newId: -1,
    blockList: [],
    blocksOrder: [],
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.blocks &&
      nextProps.blocks.length &&
      !_.isEqual(nextProps.blocks, this.props.blocks)
    ) {
      const blocksOrder = nextProps.blocks.sort(orderArrayBySort).map(block => ({
        id: block.id,
        activities: block.block_activities.sort(orderArrayBySort).map(activity => activity.id),
      }))
      this.setState({ blocksOrder })
    }
  }

  handleOpenActivityModal = (blockId, type, activityId = null) => {
    this.setState(prevState => ({
      selectedActivity: {
        type,
        blockId,
        id: activityId || prevState.newId - 1,
      },
      newId: prevState.newId - 1,
    }))
  }

  handleEditActivity = activity => {
    this.setState({
      selectedActivity: { ...activity },
    })
  }

  handleCloseActivity = () => {
    this.setState({ selectedActivity: null })
  }

  handleOpenBlockModal = () => {
    this.setState(prevState => ({
      selectedBlock: {
        id: prevState.newId - 1,
      },
      newId: prevState.newId - 1,
    }))
  }

  handleEditBlock = block => {
    this.setState({
      selectedBlock: { ...block },
    })
  }

  handleCloseBlock = () => {
    this.setState({ selectedBlock: null })
  }

  showDrawer = contentKey => {
    this.setState(prevState => ({
      drawer: !prevState.drawer,
      drawerContentKey: contentKey,
    }))
  }

  handleOpenMilestoneModal = activity => {
    this.props.fetchActivitiesList(activity.id)
    this.setState({
      selectedMilestone: { ...activity },
    })
  }

  handleCloseMilestone = () => {
    this.setState({ selectedMilestone: null })
  }

  getActivityList = droppableId => {
    const blockId = droppableId.split('-')[1]
    const block = this.state.blocksOrder.find(block => block.id === Number(blockId))
    return block ? block.activities : []
  }

  getUpdatedActivityOnState = (source, destination) => {
    const blockId = source.droppableId.split('-')[1]
    const items = reorder(this.getActivityList(source.droppableId), source.index, destination.index)

    return this.state.blocksOrder.map(block => {
      if (block.id === Number(blockId)) {
        block.activities = items
      }
      return block
    })
  }

  handleSubmitOrder = () => {
    this.props.handleSubmitBlockOrder(this.state.blocksOrder)
    this.showDrawer()
  }

  onDragEnd = ({ source, destination }) => {
    const { blocksOrder } = this.state

    // dropped outside the list
    if (!destination) return

    if (source.droppableId === destination.droppableId) {
      let items
      if (source.droppableId === 'droppableBlockContainer') {
        items = reorder(blocksOrder, source.index, destination.index)
      } else {
        items = this.getUpdatedActivityOnState(source, destination)
      }
      this.setState({ blocksOrder: items })
    } else if (
      // If destiny has 'droppableBlock'
      destination.droppableId.indexOf('droppableBlock') > -1 &&
      // And source isn't 'droppableBlockContainer'
      !source.droppableId.indexOf('droppableBlockContainer') > -1
    ) {
      if (destination.droppableId.indexOf('droppableBlockContainer') > -1) {
        return
      } else {
        const result = move(
          this.getActivityList(source.droppableId),
          this.getActivityList(destination.droppableId),
          source,
          destination,
        )
        const sourceId = source.droppableId.split('-')[1]
        const destinationId = destination.droppableId.split('-')[1]
        const blocks = blocksOrder.map(block => {
          if (block.id === Number(sourceId)) {
            block.activities = result[source.droppableId]
          }
          if (block.id === Number(destinationId)) {
            block.activities = result[destination.droppableId]
          }
          return block
        })
        this.setState({ blocksOrder: blocks })
      }
    }
    return
  }

  availableMilestone = () => {
    const { blocks } = this.props
    return (
      _.flatten(blocks.map(block => block.block_activities)).filter(activity => activity).length > 1
    )
  }

  render() {
    const {
      classes,
      basicData,
      handleSubmitBlock,
      handleRemoveBlock,
      handleUpdateRequiredActivity,
      handleSubmitActivity,
      handleRemoveActivity,
      blocks,
      handlePublishCourse,
      handleDraftCourse,
      handlePreview,
      handleSubmitMilestone,
      milestone,
    } = this.props

    const {
      selectedActivity,
      selectedBlock,
      selectedMilestone,
      drawer,
      drawerContentKey,
      blocksOrder,
    } = this.state

    const allActivities =
      blocks && blocks.length
        ? blocks.reduce((accum, block) => [...accum, ...block.block_activities], [])
        : []

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.titleHeader}>EDITAR CURSO</Typography>
        </div>
        <MenuDrawer
          customClasses={classes}
          mobileOpen={drawer}
          customContent={
            <DrawerCustomContent
              classes={classes}
              blocksOrder={blocksOrder}
              blocks={blocks}
              allActivities={allActivities}
              showDrawer={this.showDrawer}
              handleSubmitOrder={this.handleSubmitOrder}
              onDragEnd={this.onDragEnd}
              drawerContentKey={drawerContentKey}
            />
          }
        />
        <div className={classes.contentContainer}>
          <div className={classes.topHorizontalMenu}>
            <Button
              className={classes.topHorizontalMenuItems}
              onClick={() => this.showDrawer('blocksDrawer')}
            >
              <ListRoundedIcon style={{ marginRight: '.4em', color: '#707070' }} />
              BLOQUES
            </Button>
            <Button
              className={classes.topHorizontalMenuItems}
              onClick={() => this.showDrawer('activitiesDrawer')}
            >
              <ListRoundedIcon style={{ marginRight: '.4em', color: '#707070' }} />
              ACTIVIDADES
            </Button>
            <Button className={classes.topHorizontalMenuItems} onClick={() => handlePreview()}>
              <VisibilityOutlinedIcon style={{ marginRight: '.4em', color: '#707070' }} />
              PREVISUALIZAR
            </Button>

            <Button
              className={classes.topHorizontalMenuItems}
              onClick={() =>
                handlePublishCourse({ ...basicData, status: CourseStatusValue.Published })
              }
              disabled={
                !(
                  blocks &&
                  blocks.length &&
                  blocks[0].block_activities &&
                  blocks[0].block_activities.length
                ) ||
                basicData.status === CourseStatusValue.Published ||
                basicData.status === CourseStatusValue.Pending
              }
            >
              <PublishIcon
                style={{
                  color: basicData.status === CourseStatusValue.Published ? '#00000042' : '#707070',
                  position: 'relative',
                }}
              />
              {basicData.status === CourseStatusValue.Published ? 'PUBLICADO' : 'PUBLICAR'}
            </Button>
            <Button
              className={classes.topHorizontalMenuItems}
              onClick={() => handleDraftCourse({ ...basicData, status: CourseStatusValue.Draft })}
              disabled={
                !(
                  blocks &&
                  blocks.length &&
                  blocks[0].block_activities &&
                  blocks[0].block_activities.length
                ) ||
                basicData.status === CourseStatusValue.Draft ||
                basicData.status === CourseStatusValue.Pending
              }
            >
              <GetAppIcon
                style={{
                  color: basicData.status === CourseStatusValue.Draft ? '#00000042' : '#707070',
                  position: 'relative',
                }}
              />
              {basicData.status === CourseStatusValue.Draft ? 'DESPUBLICADO' : 'DESPUBLICAR'}
            </Button>
          </div>
          <div
            className={classes.mainImage}
            style={{
              backgroundImage: basicData.image && `url(${basicData.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Typography className={classes.courseTitle}>{basicData && basicData.title}</Typography>
            <div className={classes.blackFilter} />
          </div>
          {blocks &&
            blocks.map(block => (
              <BlockSection
                block={block}
                key={block.id}
                onBlockEdit={this.handleEditBlock}
                onBlockRemove={handleRemoveBlock}
                onActivityAdd={this.handleOpenActivityModal}
                onActivityRemove={handleRemoveActivity}
                onActivityUpdateRequired={handleUpdateRequiredActivity}
                onActivityEdit={this.handleEditActivity}
                onMilestone={this.handleOpenMilestoneModal}
                unableActionsCreateDelete={basicData.status !== CourseStatusValue.Draft}
                availableMilestone={this.availableMilestone()}
              />
            ))}
          {basicData.status === 1 && (
            <div className={classes.addBlock}>
              <div className={classes.divider}>
                <div className={classes.line} />
                <Tooltip title="AGREGAR BLOQUE">
                  <IconButton onClick={this.handleOpenBlockModal}>
                    <AddIcon style={{ color: '#1fd1a8', height: '2em', width: '2em' }} />
                  </IconButton>
                </Tooltip>
                <div className={classes.line} />
              </div>
            </div>
          )}
        </div>
        <BlockModal
          block={selectedBlock}
          onSubmit={handleSubmitBlock}
          onClose={this.handleCloseBlock}
        />
        <ActivityModal
          activity={selectedActivity}
          onSubmit={handleSubmitActivity}
          onClose={this.handleCloseActivity}
        />
        <MilestoneModal
          milestoneActivities={milestone ? milestone.item : {}}
          courseId={this.props.params.id}
          milestone={selectedMilestone}
          onClose={this.handleCloseMilestone}
          onSubmit={handleSubmitMilestone}
        />
      </div>
    )
  }
}

export default withStyles(styles)(CourseFormBlockContent)
