import React, { Component } from 'react'
import { ButtonBase, LinearProgress, Typography } from '@material-ui/core'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import { inverted_progressbar_theme } from '../../themes/inverted_progressbar_theme'
import Loading from '../ui/Loading'
import CategoryChip from '../../containers/ui/CategoryChip'
import TagChip from '../ui/TagChip'
import CreateReviewDialog from '../../containers/courses/CreateReviewDialog'
import CourseShareDialog from '../../containers/courses/CourseShareDialog'
import CourseReviewsDialogContainer from '../../containers/courses/CourseReviewsDialog'
import CourseSharedShowDialog from '../../containers/courses/CourseSharedShowDialog'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import ShareIcon from '@material-ui/icons/Share'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import EventListenerWrapper from '../ui/EventListenerWrapper'
import CourseDoneDialogContainer from '../../containers/courses/CourseDoneDialog'
import { minutesToDurationText } from '../../utils/FormatHelper'
import PlaylistCourseDialogContainer from '../../containers/playlists/PlaylistCourseDialogContainer'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    header: {
      position: 'relative',
      backgroundColor: '#434343',
      marginTop: 40,
      maxHeight: 800,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      maxWidth: 'calc(100vw - 0%)',
      [mediaLg]: {
        height: 'fit-content',
      },
    },
    headerContent: {
      paddingTop: 50,
      paddingBottom: 20,
      textAlign: 'center',
      display: 'block',
      [mediaLg]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 100,
      },
    },
    title: {
      fontSize: 40,
      paddingLeft: 60,
      paddingRight: 60,
      //fintMod
      fontWeight: 700,
      color: '#ffffff',
      [mediaLg]: {
        padding: '0 24px',
        fontSize: 24,
      },
    },
    description: {
      margin: 'auto',
      width: 600,
      fontSize: 16,
      color: '#ffffff',
      paddingBottom: 12,
      boxSizing: 'border-box',
      [mediaLg]: {
        width: '100%',
        padding: '0 24px',
        margin: '0 auto 24px',
      },
    },
    actions: {
      marginTop: 26,
      marginBottom: 26,
      margin: 'auto',
      width: '50%',
      color: '#ffffff',
      [mediaLg]: {
        marginTop: 25,
      },
    },
    icon: {
      fontSize: 60,
      cursor: 'pointer',
      '&_share': {
        fontSize: 39,
        paddingTop: 12,
        paddingRight: 7,
        cursor: 'pointer',
        '&:hover': {
          color: '#A9A9A9',
        },
      },
      '&_smaller': {
        fontSize: 46,
        paddingTop: 7,
        cursor: 'pointer',
        '&:hover': {
          color: '#A9A9A9',
        },
      },
      '&:hover': {
        color: '#A9A9A9',
      },
    },
    icon_active: {
      fontSize: 60,
      cursor: 'default',
      '&_smaller': {
        fontSize: 46,
        paddingTop: 7,
        cursor: 'default',
      },
      '&_share': {
        fontSize: 43,
        paddingTop: 9,
        paddingRight: 7,
        cursor: 'default',
      },
    },
    button: {
      color: '#434343',
      marginTop: 16,
    },
    rating: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    npsScoreTitle: {
      float: 'left',
      color: '#ffffff',
      marginLeft: 5,
      marginTop: 0,
      fontSize: 14,
    },
    ratingsCountLink: {
      float: 'left',
      color: '#ffffff',
      textDecoration: 'underline',
      marginLeft: 5,
      marginTop: 0,
      cursor: 'pointer',
      fontSize: 14,
    },
    sendersLink: {
      color: '#ffffff',
      textDecoration: 'underline',
      marginTop: 0,
      cursor: 'pointer',
      fontSize: 14,
    },
    stars: {
      float: 'left',
    },
    star: {
      fontSize: 20,
      color: 'white',
    },
    mandatory: {
      margin: 'auto',
      marginBottom: 16,
      backgroundColor: 'rgba(0,0,0,0,0)',
      borderColor: 'white',
      border: 'solid 1px',
      borderRadius: 6,
      padding: 6,
      maxWidth: 100,
      '&_label': {
        color: 'white',
        fontSize: 10,
      },
      [mediaLg]: {
        margin: '0 auto 16px',
      },
    },
    progress: {
      position: 'relative',
      width: '50%',
      maxWidth: 450,
      margin: 'auto',
      paddingTop: 32,
      display: 'block',
    },
    indicators: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      bottom: 16,
      justifyContent: 'space-between',
    },
    activityProgress: {
      fontSize: 14,
      position: 'relative',
      top: 8,
      color: 'white',
    },
    textProgress: {
      fontSize: 14,
      position: 'relative',
      top: 8,
      color: 'white',
    },
    categoriesAndTagsContainer: {
      width: '100%',
    },
    categoriesAndTagsWrapper: {
      maxWidth: 600,
      margin: 'auto',
      padding: 16,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    category: {
      fontSize: 12,
      height: '1.5em',
    },
    tag: {
      fontSize: 12,
      marginTop: 2,
    },
    textActions: {
      fontSize: 12,
      paddingLeft: 2,
      [mediaLg]: {
        paddingLeft: 4,
        fontSize: 10,
        marginRight: 8,
      },
    },
    textActionValorar: {
      fontSize: 12,
      paddingLeft: 3,
      display: 'block',
      paddingTop: 7,
      [mediaLg]: {
        paddingLeft: 5,
        fontSize: 10,
      },
    },
    textActionCompartir: {
      fontSize: 12,
      marginLeft: -5,
      display: 'block',
      paddingTop: 9,
      [mediaLg]: {
        paddingLeft: 5,
        fontSize: 10,
      },
    },

    textActionsValorado: {
      fontSize: 12,
      marginLeft: -2,
      display: 'block',
      paddingTop: 7,
      [mediaLg]: {
        paddingLeft: 5,
        fontSize: 10,
      },
    },
    wishList: {
      textAlign: 'center',
    },
    valoracion: {
      textAlign: 'center',
    },
    compartir: {
      textAlign: 'center',
    },
  }
}

class HeaderCourse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openCreateReview: false,
      openCourseDone: true,
      openCourseShare: false,
      openCourseReview: false,
      openCourseShared: false,
      openCoursePlaylists: false,
      reviewStars: null,
    }
  }

  handleShowCourseShare = () => {
    const { courseShare } = this.props
    if (courseShare) {
      this.setState({ openCourseShare: true })
      return courseShare
    }
  }

  render() {
    const { classes, course, courseColor, onRemoveSharedCourse, courseDone } = this.props

    let userInfo = { total_activities_done: 0, progress: 0 }

    if (course && course.user_courses && course.user_courses.length === 1) {
      userInfo = course.user_courses[0]
      userInfo.progress = (userInfo.total_activities_done / (course.total_activities || 1)) * 100
    }
    return (
      course != null && (
        <header
          className={classes.header}
          style={{
            backgroundImage: `url(${course.image})`,
            backgroundSize: 'cover',
            backgroundBlendMode: 'multiply',
          }}
        >
          {course != null ? (
            <div className={classes.headerContent}>
              {course.required && (
                <div className={classes.mandatory}>
                  <Typography className={classes.mandatory + '_label'}>REQUERIDO</Typography>
                </div>
              )}
              {course.shared_courses && course.shared_courses.length > 0 && (
                <React.Fragment>
                  <Typography
                    role={'button'}
                    tabIndex={0}
                    className={classes.sendersLink}
                    onClick={() => {
                      this.setState({ openCourseShared: true })
                    }}
                  >
                    {course.shared_courses.length > 1
                      ? `${course.shared_courses.length} veces recomendado`
                      : '1 vez recomendado'}
                  </Typography>
                </React.Fragment>
              )}
              {course.comments_enabled && (
                <div className={classes.rating} role={'subheading'}>
                  {course.rates_count > 0 && (
                    <React.Fragment>
                      <StarOutlinedIcon iconname={'ic_star_outline'} className={classes.star} />
                      <Typography tabIndex={0} className={classes.npsScoreTitle}>
                        NPS {parseInt(course.nps_score)}
                      </Typography>
                    </React.Fragment>
                  )}
                  {course.rates_count !== 0 && (
                    <Typography className={classes.npsScoreTitle}> | </Typography>
                  )}
                  <ButtonBase
                    tabIndex={0}
                    role={'button'}
                    onClick={() => {
                      if (course.rates_count !== 0) {
                        this.setState({ openCourseReview: true })
                      }
                    }}
                  >
                    <Typography
                      className={
                        course.rates_count > 0 ? classes.ratingsCountLink : classes.npsScoreTitle
                      }
                    >
                      {course.rates_count == 1
                        ? '1 valoración '
                        : course.rates_count > 1
                        ? `${course.rates_count} valoraciones `
                        : null}
                    </Typography>
                  </ButtonBase>
                </div>
              )}
              <Typography tabIndex={0} component={'h1'} className={classes.title}>
                {course.title}
              </Typography>
              {course.categories && (
                <div className={classes.categoriesAndTagsContainer}>
                  <div className={classes.categoriesAndTagsWrapper}>
                    {course.categories.map(c => (
                      <CategoryChip
                        role={'link'}
                        className={classes.category}
                        key={'c' + c.id}
                        category={c}
                      />
                    ))}
                    {course.tags.map(tag => (
                      <TagChip tabIndex={0} key={tag.id} label={tag.name} className={classes.tag} />
                    ))}
                  </div>
                </div>
              )}
              <Typography tabIndex={0} className={classes.description}>
                {course.description}
              </Typography>
              {course.referents && (
                <Typography tabIndex={0} className={classes.description}>
                  Referentes: {course.referents}
                </Typography>
              )}
              {course.external_reference && (
                <Typography tabIndex={0} className={classes.description}>
                  {course.external_reference[0].external_reference_type === 1
                    ? 'Proveedor:'
                    : 'Referente:'}
                  {course.external_reference.map((reference, index) => (
                    <a key={index}>
                      {' '}
                      {index !== 0 && <span>|</span>} {reference.name}
                    </a>
                  ))}
                </Typography>
              )}
              {course.duration > 0 && (
                <Typography tabIndex={0} className={classes.description}>
                  Duración estimada: {minutesToDurationText(course.duration)}
                </Typography>
              )}
              {course.total_activities > 0 && (
                <div className={classes.progress}>
                  <div className={classes.indicators}>
                    <Typography
                      tabIndex={0}
                      aria-label={`${userInfo.total_activities_done} actividades realizadas de ${course.total_activities}`}
                      className={classes.activityProgress}
                    >
                      {`${userInfo.total_activities_done}/${course.total_activities}`}
                    </Typography>
                    <Typography tabIndex={0} className={classes.textProgress}>
                      {'Progreso ' + userInfo.progress.toFixed(0) + '%'}
                    </Typography>
                  </div>
                  {userInfo && (
                    <MuiThemeProvider theme={inverted_progressbar_theme}>
                      <LinearProgress variant="determinate" value={userInfo.progress} />
                    </MuiThemeProvider>
                  )}
                </div>
              )}
              <div className={classes.actions}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs className={classes.wishList}>
                    <EventListenerWrapper
                      role="button"
                      aria-label={'mis listas'}
                      tabIndex={0}
                      onClick={() => this.setState({ openCoursePlaylists: true })}
                      onEnter={() => this.setState({ openCoursePlaylists: true })}
                      className={classes.whisListButton}
                    >
                      <AddIcon className={classes.icon} />
                      <Typography className={classes.textActions}>
                        {'Lista de aprendizaje'}
                      </Typography>
                    </EventListenerWrapper>
                  </Grid>
                  {course.comments_enabled && (
                    <Grid item xs className={classes.valoracion}>
                      {course.user_course_ratings.length === 0 && (
                        <EventListenerWrapper
                          role="button"
                          aria-label={'valorar'}
                          tabIndex={0}
                          className={classes.whisListButton}
                          onClick={() => {
                            this.setState({ openCreateReview: true })
                          }}
                          onEnter={() => {
                            this.setState({ openCreateReview: true })
                          }}
                        >
                          <StarBorderOutlinedIcon className={classes.icon + '_smaller'} />
                          <Typography className={classes.textActionValorar}>{'Valorar'}</Typography>
                        </EventListenerWrapper>
                      )}
                      {course.user_course_ratings.length > 0 && (
                        <div className={classes.whisListButton}>
                          <StarOutlinedIcon className={classes.icon_active + '_smaller'} />
                          <Typography className={classes.textActionsValorado}>
                            {'Valorado'}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  )}

                  <Grid item xs className={classes.compartir}>
                    <EventListenerWrapper
                      role="button"
                      aria-label={'compartir'}
                      tabIndex={0}
                      onEnter={() => this.setState({ openCourseShare: true })}
                      onClick={() => {
                        this.setState({ openCourseShare: true })
                      }}
                      className={classes.whisListButton}
                    >
                      <ShareIcon className={classes.icon + '_share'} />
                      <Typography className={classes.textActionCompartir}>{'Compartir'}</Typography>
                    </EventListenerWrapper>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <Loading />
          )}
          {courseDone && (
            <CourseDoneDialogContainer
              course={course}
              headerColor={courseColor}
              open={this.state.openCourseDone}
              //open={true}
              onDismiss={() => {
                this.setState({ openCourseDone: false })
              }}
              initialRating={this.state.reviewStars}
            />
          )}
          <CourseShareDialog
            course={course}
            headerColor={courseColor}
            open={this.state.openCourseShare}
            onDismiss={() => {
              this.setState({ openCourseShare: false })
            }}
            initialRating={this.state.reviewStars}
          />
          <CreateReviewDialog
            course={course}
            headerColor={courseColor}
            open={this.state.openCreateReview}
            onDismiss={() => {
              this.setState({ openCreateReview: false })
            }}
            initialRating={this.state.reviewStars}
          />
          {course && (
            <CourseReviewsDialogContainer
              course={course}
              keyName={'course-reviews-dialog-' + course.id}
              headerColor={courseColor}
              open={this.state.openCourseReview}
              onDismiss={() => {
                this.setState({ openCourseReview: false })
              }}
            />
          )}
          {course && course.shared_courses && course.shared_courses.length > 0 && (
            <CourseSharedShowDialog
              sharedCourses={course.shared_courses}
              onRemoveSharedCourse={onRemoveSharedCourse}
              course={course}
              keyName={'course-shared-Show-dialog-' + course.id}
              headerColor={courseColor}
              open={this.state.openCourseShared}
              onDismiss={() => {
                this.setState({ openCourseShared: false })
              }}
            />
          )}
          {course && (
            <PlaylistCourseDialogContainer
              course={course}
              open={this.state.openCoursePlaylists}
              onDismiss={() => {
                this.setState({ openCoursePlaylists: false })
              }}
            />
          )}
        </header>
      )
    )
  }
}

export default withStyles(styles)(HeaderCourse)
