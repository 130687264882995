import React, { Component } from 'react';
import Header from './CategoriesHeader';
import Content from './CategoriesContent';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../ui/Loading';

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: '100vw',
  },
});

class Categories extends Component {
  render() {
    const { classes, onCategoryClick, categories, loading } = this.props;

    return (
      <div className={classes.root}>
        <Header />
        {loading ? (
          <Loading />
        ) : (
          <Content onCategoryClick={onCategoryClick} categories={categories} />
        )}
      </div>
    );
  }
}
export default withStyles(styles)(Categories);
