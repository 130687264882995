import React from 'react'
import { Dialog, IconButton } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { CloseOutlined } from '@material-ui/icons'

const UsersSelectedModal = ({ usersSelected, open, onClose, columns, options }) => (
  <Dialog
    disableEnforceFocus={true}
    open={!!open}
    onClose={onClose}
    aria-labelledby="form-dialog-title"
    fullWidth={true}
    maxWidth="md"
    style={{ position: 'fixed' }}
  >
    <IconButton
      onClick={onClose}
      style={{ position: 'absolute', top: '0.5rem', right: '0.5rem', zIndex: 1 }}
    >
      <CloseOutlined />
    </IconButton>
    <MUIDataTable
      title={`${usersSelected.length} ${
        usersSelected.length > 1 ? ' Usuarios seleccionados' : ' Usuario seleccionado'
      }`}
      data={usersSelected}
      columns={columns}
      options={options({
        pagination: false,
        rowsPerPage: Infinity,
        search: false,
        sort: true,
        serverSide: false,
        customSort: undefined,
      })}
      pagination={false}
      hideFooter
    />
  </Dialog>
)

export default UsersSelectedModal
