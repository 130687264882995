import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/Person'
import { goToExploreMoreCareerPlans } from '../../actions/navigator'
import { COURSES_VIEW, router } from '../../config/routes'
import { goTo } from '../../actions/navigator'
import { loadMyCareerPlan, loadMyPreviousCareerPlan } from '../../actions/careerPlans'
import CareerPlansHome from '../../components/career/CareerPlansHome'
import { appLoadToken } from '../../actions/app'
import { deleteSharedCourse } from '../../actions/courses'
import { addSnackbar } from '../../actions/snackbar'
import { SnackbarType } from '../../config/constants'
import DocumentTitle from '../../components/ui/DocumentTitle'

class CareerPlansContainer extends Component {
  state = {
    tabActive: localStorage.getItem('tabActiveLS') ? localStorage.getItem('tabActiveLS') : 0,
    tabs: [],
  }

  componentWillMount() {
    this.props.appLoadToken().then(res => {
      if (res) {
        this.loadData()
      }
    })
  }

  loadData() {
    this.props.loadMyCareerPlan()
    this.props.loadMyPreviousCareerPlan()
    this.buildTabs()
  }

  buildTabs() {
    if (this.props.me.user) {
      let tabActiveValue = this.props.me.user.active_career_plan ? 0 : 1
      if (this.state.tabActive > 0) {
        this.setState({
          tabActive: 1,
          tabs: [
            {
              title: 'Mi ruta',
              Icon: PersonIcon,
              active: false,
              visible: !!this.props.me.user.active_career_plan,
            },
            {
              title: 'Rutas recorridas',
              Icon: AccessTimeIcon,
              active: true,
              visible: !!this.props.me.user.inactive_career_plans,
            },
          ],
        })
      } else {
        if (tabActiveValue === 1) {
          this.setState({
            tabActive: 1,
            tabs: [
              {
                title: 'Mi ruta',
                Icon: PersonIcon,
                active: false,
                visible: this.props.me.user.active_career_plan === true,
              },
              {
                title: 'Rutas recorridas',
                Icon: AccessTimeIcon,
                active: true,
                visible: this.props.me.user.inactive_career_plans === true,
              },
            ],
          })
        } else {
          this.setState({
            tabActive: 0,
            tabs: [
              {
                title: 'Mi ruta',
                Icon: PersonIcon,
                active: !this.props.me.user.active_career_plan ? false : true,
                visible: this.props.me.user.active_career_plan === true,
              },
              {
                title: 'Rutas recorridas',
                Icon: AccessTimeIcon,
                active: !this.props.me.user.active_career_plan ? true : false,
                visible: this.props.me.user.inactive_career_plans === true,
              },
            ],
          })
        }
      }
    }
  }

  showCourse = course => {
    const { goTo } = this.props
    goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
  }

  loadCarrerPlan = query => {
    const { loadMyCareerPlan } = this.props
    if (query) {
      loadMyCareerPlan(query)
    } else {
      loadMyCareerPlan()
    }
  }

  loadPreviousCareerPlan = query => {
    const { loadMyPreviousCareerPlan } = this.props
    if (query) {
      loadMyPreviousCareerPlan(query)
    } else {
      loadMyPreviousCareerPlan()
    }
  }

  handleChangeTab = (index, array) => {
    const newTabs = array.map((tab, indexTab) =>
      indexTab === index ? { ...tab, active: true } : { ...tab, active: false },
    )
    this.setState({ tabs: newTabs, tabActive: index }, () => {
      localStorage.setItem('tabActiveLS', index)
      localStorage.setItem('tabOpenSaved', 0)
    })
  }

  handleNavigateToMoreCareerPlans = () => {
    this.props.goToExploreMoreCareerPlans()
  }

  removeSharedCourse = async courseId => {
    try {
      const { deleteSharedCourse, addSnackbar } = this.props
      await deleteSharedCourse(courseId)
      addSnackbar(`Recomendación descartada`, SnackbarType.Success)
      this.loadData()
    } catch (err) {}
  }

  render() {
    const { currentCareerPlans, previousCareerPlans, explorePlans } = this.props
    const { tabActive, tabs } = this.state

    return (
      <React.Fragment>
        <DocumentTitle title={'Mi ruta | Rutas de aprendizaje | Academia'} />
        <CareerPlansHome
          tabActive={tabActive}
          tabs={tabs}
          onChageTab={this.handleChangeTab}
          currentCareerPlans={currentCareerPlans}
          previousCareerPlans={previousCareerPlans}
          loadCarrerPlan={this.loadCarrerPlan}
          loadPreviousCareerPlan={this.loadPreviousCareerPlan}
          explorePlans={explorePlans}
          onShowCourse={this.showCourse}
          applyFilter={this.applyFilter}
          onClickButtonBanner={this.handleNavigateToMoreCareerPlans}
          onDeleteSharedCourse={this.removeSharedCourse}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ careerPlansCoursesReducers, userReducer }) => {
  return {
    ...careerPlansCoursesReducers,
    me: { ...userReducer },
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      goTo,
      loadMyCareerPlan,
      loadMyPreviousCareerPlan,
      appLoadToken,
      goToExploreMoreCareerPlans,
      addSnackbar,
      deleteSharedCourse,
    },
    dispatch,
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CareerPlansContainer)
);
