import React, { Component } from 'react'
import config from '../../config'

export default class Loading extends Component {
  render() {
    const {
      height = 80,
      align = 'center',
      dark = false,
      fullSize = false,
      fullWidth = false,
    } = this.props
    const customStyle = fullSize
      ? {
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: align,
          paddingTop: 0,
        }
      : {}
    return (
      <div
        style={
          fullWidth
            ? {
                display: 'flex',
                justifyContent: 'center',
                padding: '2rem',
                ...customStyle,
              }
            : {
                textAlign: align,
                paddingTop: 32,
                ...customStyle,
              }
        }
        aria-label={'Cargando el contenido'}
      >
        <img
          src={config.BASE_URL + 'img/ac_loading' + (dark ? '_dark' : '') + '.gif'}
          height={height}
          alt={'Cargando'}
        />
      </div>
    )
  }
}
