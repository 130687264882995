import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './ActivityModalStyles'
import AdminResourceForm from '../../AdminResourceForm'
import { EditInputType } from '../../../../config/constants'

const FORM_COLUMNS = [
  {
    title: 'Nombre',
    source: 'name',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'name' },
  },
  {
    title: 'Descripción',
    source: 'description',
    editable: true,
    editConfig: {
      type: EditInputType.Text,
      name: 'description',
      multiline: 4,
    },
  },
  {
    source: 'text',
    editable: true,
    editConfig: { type: EditInputType.TextEditor, name: 'text' },
  },
]

const ActivityModalTextContent = ({
  activity,
  title,
  results,
  onChange,
  classes,
  onSubmit,
  onCancel,
  actionButtons,
}) => {
  const onAttributeChange = (name, value) => {
    onChange({ ...activity, [name]: value })
  }
  return (
    <AdminResourceForm
      title={title}
      keyName={'admin-activity-modal-text-form'}
      form={activity}
      isValid={results && results.isValid}
      errors={results ? results.errors : []}
      loading={false}
      attributes={{ keys: FORM_COLUMNS }}
      onAttributeChange={onAttributeChange}
      customClasses={classes}
      onSubmit={onSubmit}
      onCancel={onCancel}
      actionButtons={actionButtons}
    />
  )
}

export default withStyles(styles)(ActivityModalTextContent)
