import {
  COURSE_READ_END,
  COURSE_READ_FAILED,
  COURSE_READ_START,
  SEARCH_COURSES_READ_END,
  SEARCH_COURSES_READ_FAILED,
  SEARCH_COURSES_READ_START,
  REQUIRED_COURSES_READ_END,
  REQUIRED_COURSES_READ_FAILED,
  REQUIRED_COURSES_READ_START,
  FEATURED_CATEGORIES_READ_START,
  FEATURED_CATEGORIES_READ_END,
  FEATURED_CATEGORIES_READ_FAILED,
  SEARCH_COURSES_CLEAR,
  TOPTEN_COURSES_READ_START,
  TOPTEN_COURSES_READ_END,
  TOPTEN_COURSES_READ_FAILED,
  VARIABLE_PRICE_COURSES_READ_START,
  VARIABLE_PRICE_COURSES_READ_END,
  VARIABLE_PRICE_COURSES_READ_FAILED,
  OBJECTIVE_D_PLUS_I_COURSES_READ_START,
  OBJECTIVE_D_PLUS_I_COURSES_READ_END,
  OBJECTIVE_D_PLUS_I_COURSES_READ_FAILED,
} from './index'
import api from '../api/api'
import { paginateNext, resetPaginator, updateItem } from './infinite_paginator'

export function loadCourseById(id, preview = false) {
  return async dispatch => {
    dispatch({ type: COURSE_READ_START })
    try {
      const response = await (preview
        ? api.withDispatch(dispatch).Admin.getCoursePreview(id)
        : api.withDispatch(dispatch).Courses.get(id))
      dispatch({ type: COURSE_READ_END, payload: { course: response.data } })
    } catch (e) {
      dispatch({
        type: COURSE_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function silentUpdateCourseById(id) {
  return async dispatch => {
    try {
      const response = await api.withDispatch(dispatch).Courses.get(id)
      dispatch({ type: COURSE_READ_END, payload: { course: response.data } })
    } catch (e) {
      console.log(e)
    }
  }
}

export function searchCourses(query) {
  return async dispatch => {
    const timestamp = Date.now()
    dispatch({
      type: SEARCH_COURSES_READ_START,
      payload: { timestamp, query },
    })
    try {
      const response = await api.withDispatch(dispatch).Courses.searchByText(query)
      dispatch({
        type: SEARCH_COURSES_READ_END,
        payload: { items: response.data, timestamp, query },
      })
    } catch (e) {
      dispatch({
        type: SEARCH_COURSES_READ_FAILED,
        payload: { message: e.toString(), timestamp, query },
      })
    }
  }
}

export function clearSearchCourses() {
  return async dispatch => {
    dispatch({ type: SEARCH_COURSES_CLEAR })
  }
}

export function loadRequired() {
  return async dispatch => {
    dispatch({ type: REQUIRED_COURSES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Courses.getAllRequired()
      dispatch({
        type: REQUIRED_COURSES_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: REQUIRED_COURSES_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadObjectiveDplusI() {
  return async dispatch => {
    dispatch({ type: OBJECTIVE_D_PLUS_I_COURSES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Courses.getAllObjectiveDplusI()
      dispatch({
        type: OBJECTIVE_D_PLUS_I_COURSES_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: OBJECTIVE_D_PLUS_I_COURSES_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadVariablePrice() {
  return async dispatch => {
    dispatch({ type: VARIABLE_PRICE_COURSES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Courses.getVariablePrice()
      dispatch({
        type: VARIABLE_PRICE_COURSES_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: VARIABLE_PRICE_COURSES_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadTopTen() {
  return async dispatch => {
    dispatch({ type: TOPTEN_COURSES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Courses.getTopTen()
      dispatch({
        type: TOPTEN_COURSES_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: TOPTEN_COURSES_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadFeaturedCategories() {
  return async dispatch => {
    dispatch({ type: FEATURED_CATEGORIES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).Courses.getAllForFeaturedCategories()

      dispatch({
        type: FEATURED_CATEGORIES_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: FEATURED_CATEGORIES_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function deleteSharedCourse(id) {
  return async dispatch => {
    const response = await api.withDispatch(dispatch).Courses.removeShareCourse(id)
    return response.data
  }
}

export function updateCourseBlock(courseId, blockId, paginatorKey) {
  return async dispatch => {
    try {
      const response = await api.withDispatch(dispatch).Courses.getBlock(courseId, blockId)
      return updateItem(paginatorKey, response.data)
    } catch (e) {
      console.log(e)
    }
  }
}

export function paginateCourseBlocks(
  courseId,
  paginatorKey,
  page,
  size,
  preview = false,
  apply = a => a,
) {
  return paginateNext(
    paginatorKey,
    dispatch =>
      preview
        ? api.withDispatch(dispatch).Admin.getCoursePreviewBlocks(courseId, page, size)
        : api.withDispatch(dispatch).Courses.getBlocks(courseId, page, size),
    apply,
  )
}

export function resetPagitation(paginatorKey) {
  return resetPaginator(paginatorKey)
}

export function paginateCourseReviews(courseId, paginatorKey, page, size, apply = a => a) {
  return paginateNext(
    paginatorKey,
    dispatch => api.withDispatch(dispatch).Courses.getReviews(courseId, page, size),
    apply,
  )
}

export function rateCourse(courseId, data) {
  return async dispatch => {
    try {
      await api.withDispatch(dispatch).My.Courses.rate(courseId, data)
      await silentUpdateCourseById(courseId)(dispatch)
      return true
    } catch (e) {
      console.log(e)
    }
    return false
  }
}

export function shareCourse(data) {
  return async dispatch => {
    try {
      await api.withDispatch(dispatch).Courses.shareCourse(data)
      //await silentUpdateCourseById(courseId)(dispatch);
      return true
    } catch (e) {
      console.log(e)
    }
    return false
  }
}
