import React, { useEffect, useState } from 'react'
import { IconButton, Slider, Typography } from '@material-ui/core'
import { COLOR_KEYS, STYLE_KEYS } from '../../../../config/constants'
import CustomTabPanel from '../../../ui/CustomTabPanel'
import { DeleteOutline } from '@material-ui/icons'
import TextInput from '../../../admin/inputs/TextInput'
import SelectInput from '../../../admin/inputs/SelectInput'
import QuestionTrueOrFalse from './QuestionTrueOrFalse'
import QuestionMultipleChoice from './QuestionMultipleChoice'
import { convertNumberToId, getQuestionTypeType, setTrueOrFalse } from '../helpers'
import { store } from '../../../../store'
import QuestionWithAsterisks from './QuestionWithAsterisks'
import { limitIntEntry, preventEvent } from '../../../../utils/AdminHelper'
import { convertToBase64 } from '../../../../utils/ConvertToBase64'
import FormHelperText from '@material-ui/core/es/FormHelperText'
import Dropzone from '../../../admin/inputs/Dropzone'
import ImageOutlined from '@material-ui/icons/ImageOutlined'

const errorsSetter = prevErrors => error => {
  prevErrors.push(error)
}

const basicValidations = question => {
  const errors = []
  const addError = errorsSetter(errors)
  if (!question) {
    addError('No hay pregunta')
  }
  if (!question.score) {
    addError('Debe ingresar el puntaje de la pregunta')
  }
  if (!question.questions_type) {
    addError('Seleccione un tipo de pregunta')
  }
  if (!question.question_text) {
    addError('Debe ingresar el texto de la pregunta')
  }
  if (question.link) {
    if (!question.link.includes('.')) {
      addError('Debe ingresar un link válido')
    } else {
      if (question.link.includes('..')) {
        addError('No se admiten 2 puntos (.) consecutivos en el link')
      }
      if (question.link.startsWith('.')) {
        addError('No se admiten puntos en el inicio del link')
      }
      if (question.link.endsWith('.')) {
        addError('No se admiten puntos al final del link')
      }
    }
  }

  return errors
}

const validationForQuestionWithOptions = question => {
  const errors = basicValidations(question)
  const addError = errorsSetter(errors)

  if (!(question?.options?.length > 1)) {
    addError('Debe configurar al menos 2 opciones')
  }
  if (!question?.options?.every(({ options_text }) => options_text)) {
    addError('Es obligatorio ingresar texto en las opciones')
  }
  if (!question?.options?.some(({ correct_answer }) => correct_answer)) {
    addError('Al menos una opción debe ser marcada como correcta')
  }

  return errors
}

const validationForQuestionsWithAsterisks = question => {
  const errors = basicValidations(question)
  const addError = errorsSetter(errors)

  const asterisksLength = question?.options[0]?.options_text
    ?.split('')
    ?.filter(letter => letter === '*')?.length

  if (!(question.options[0]?.options_text?.length > 2)) {
    addError('El texto a resolver debe tener al menos 3 caracteres')
  }
  if (question.options[0]?.options_text?.includes('**')) {
    addError('No puede haber un par de asteriscos (**) sin contenido')
  }
  if (!(asterisksLength > 1)) {
    addError('Se requieren al menos 2 asteriscos (*)')
  }
  if (asterisksLength % 2) {
    addError('La cantidad de asteriscos (*) debe ser par')
  }

  return errors
}

const QuestionSetup = ({
  question,
  index,
  selectedTab,
  classes,
  key,
  handleDeleteQuestion,
  atLeastOneQuestion,
  editFields,
  scoreSetter,
  resetQuestion,
  setQuestions,
  quizEditingBlocked,
}) => {
  const [questionTypes, setQuestionTypes] = useState()
  const [type, setType] = useState()

  const handleEditOptions = (questionId, options) => {
    setQuestions(state =>
      state.map(question => (question.id === questionId ? { ...question, options } : question)),
    )
  }

  const trueOrFalseHandler = (question, value) =>
    handleEditOptions(question.id, setTrueOrFalse(value))

  const multipleChoiceHandler = (question, options) =>
    handleEditOptions(
      question.id,
      options.map((option, index) => ({ ...option, mark: convertNumberToId(index) })),
    )

  const questionsWithAsterisksHandler = (question, options_text) =>
    handleEditOptions(question.id, [{ options_text: options_text?.trim(), correct_answer: true }])

  const questionData = {
    TrueOrFalse: {
      Component: QuestionTrueOrFalse,
      handler: trueOrFalseHandler,
      validate: validationForQuestionWithOptions,
    },
    MultipleChoice: {
      Component: QuestionMultipleChoice,
      handler: multipleChoiceHandler,
      validate: validationForQuestionWithOptions,
    },
    FillInTheBlanks: {
      Component: QuestionWithAsterisks,
      handler: questionsWithAsterisksHandler,
      validate: validationForQuestionsWithAsterisks,
    },
    DragTheWords: {
      Component: QuestionWithAsterisks,
      handler: questionsWithAsterisksHandler,
      validate: validationForQuestionsWithAsterisks,
    },
  }

  const questionRender = (data, questionTypeType) => {
    const { Component, handler } = questionData[questionTypeType]
    return <Component {...{ handler, ...data }} />
  }
  const questionTextMaxLength = 5000
  const linkMaxLength = 255

  useEffect(() => {
    setQuestionTypes(store.getState().questionsTypesReducer?.items)
  }, [])

  useEffect(() => {
    editFields(
      {
        errors: type
          ? questionData[type].validate(question)
          : ['Debe seleccionar un tipo de pregunta'],
      },
      question,
    )
  }, [
    question.link,
    question.questions_type,
    question.question_text,
    question.options,
    question.score,
    questionTypes,
    type,
  ])

  useEffect(() => {
    if (question.questions_type && questionTypes) {
      setType(getQuestionTypeType(question.questions_type, questionTypes))
    }
  }, [question.questions_type, questionTypes])

  return (
    <CustomTabPanel
      key={key}
      value={selectedTab}
      index={index}
      className={selectedTab === index && classes.activeTabPanel}
      style={{ transition: STYLE_KEYS.DEFAULT_TRANSITION }}
    >
      <div className="col" style={{ gap: '3rem' }}>
        <div className="col" style={{ gap: '2rem' }}>
          <div
            className="row"
            style={{
              fontSize: '0.75rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            (
            <span
              style={{
                color: COLOR_KEYS.INCORRECT,
                fontSize: '0.75rem',
              }}
            >
              *
            </span>
            ) campos requeridos
          </div>
          <div
            className="row"
            style={{
              gap: '2rem',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '2rem',
            }}
          >
            <Typography style={{ fontWeight: 600, fontSize: '1.25rem', lineHeight: '1.25rem' }}>
              Configuración de pregunta {index + 1}
            </Typography>
            {atLeastOneQuestion && !quizEditingBlocked && (
              <IconButton
                style={{ padding: '0.25rem' }}
                onClick={() => handleDeleteQuestion(question.id, index)}
              >
                <DeleteOutline />
              </IconButton>
            )}
          </div>
          <div className="col" style={{ gap: '1.5rem' }}>
            <div className="row" style={{ gap: '2.25rem' }}>
              <div className="col" style={{ gap: '1.5rem', flex: 4 }}>
                <row className="row" style={{ gap: '1rem' }}>
                  <div className="col" style={{ flex: 5, justifyContent: 'flex-end' }}>
                    <Typography style={STYLE_KEYS.QUESTIONS_LABEL}>
                      Puntaje{' '}
                      <span
                        style={{
                          color: COLOR_KEYS.INCORRECT,
                          fontSize: '0.75rem',
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <Slider
                      step={5}
                      min={0}
                      max={100}
                      value={question.score}
                      onChange={(_, value) =>
                        editFields({ score: scoreSetter(value, index) }, question)
                      }
                      marks
                    />
                  </div>
                  <div className="col" style={{ flex: 1, height: '100%', minWidth: '4rem' }}>
                    <TextInput
                      style={{
                        ...STYLE_KEYS.QUESTIONS_INPUT,
                        textAlign: 'center',
                        cursor: 'normal',
                      }}
                      min={0}
                      type="number"
                      variant="standard"
                      max={100}
                      value={question.score}
                      placeholder="Auto"
                      onChange={(_, value) =>
                        editFields({ score: scoreSetter(Number(value), index) }, question)
                      }
                      onKeyDown={limitIntEntry}
                      onPaste={preventEvent}
                    />
                  </div>
                </row>
                <div className="col">
                  <Typography style={STYLE_KEYS.QUESTIONS_LABEL}>
                    Tipo de pregunta{' '}
                    <span
                      style={{
                        color: COLOR_KEYS.INCORRECT,
                        fontSize: '0.75rem',
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <SelectInput
                    style={STYLE_KEYS.QUESTIONS_INPUT}
                    tabIndex={0}
                    name="questions_type"
                    value={question?.questions_type}
                    placeholder="Seleccionar tipo"
                    onChange={(key, value) => {
                      resetQuestion(key, value, question)
                    }}
                    options={questionTypes?.filter(option =>
                      Object.keys(questionData).includes(option.type),
                    )}
                    className={classes.select}
                    classes={classes}
                    readOnly={quizEditingBlocked}
                  />
                </div>
              </div>
              <div className="col" style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Typography
                  style={{
                    ...STYLE_KEYS.QUESTIONS_LABEL,
                    marginBottom: '0.125rem',
                    textAlign: 'right',
                  }}
                >
                  Imagen
                </Typography>
                <Dropzone
                  classes={classes}
                  style={{ minWidth: 'unset' }}
                  dropDetail={quizEditingBlocked ? undefined : 'JPG, JPEG o PNG'}
                  text={quizEditingBlocked ? 'No hay imagen cargada para esta pregunta' : undefined}
                  accept="image/png, image/jpg, image/jpeg"
                  maxSize={10}
                  multiple={false}
                  icon={<ImageOutlined />}
                  key="dropzone-file"
                  onDrop={
                    quizEditingBlocked
                      ? undefined
                      : async ([file]) => {
                          editFields(
                            {
                              image: await convertToBase64(file),
                            },
                            question,
                          )
                        }
                  }
                  onRemove={
                    quizEditingBlocked
                      ? undefined
                      : () => {
                          editFields({ image: null, file_extension: null }, question)
                        }
                  }
                  currentFiles={question?.image ?? ''}
                  disabled={quizEditingBlocked}
                />
              </div>
            </div>
            <div className="col">
              <Typography style={STYLE_KEYS.QUESTIONS_LABEL}>
                Texto{' '}
                <span
                  style={{
                    color: COLOR_KEYS.INCORRECT,
                    fontSize: '0.75rem',
                  }}
                >
                  *
                </span>
              </Typography>
              <TextInput
                maxLength={5000}
                style={STYLE_KEYS.QUESTIONS_INPUT}
                name="question_text"
                value={question.question_text}
                placeholder="La pregunta o texto correspondiente al item del formulario"
                onChange={(key, value) => editFields({ [key]: value }, question)}
                helpText={
                  quizEditingBlocked
                    ? undefined
                    : `${question.question_text?.length ?? 0} / ${questionTextMaxLength}`
                }
                multiline={10}
                variant="standard"
              />
            </div>
            <div className="col">
              <Typography style={STYLE_KEYS.QUESTIONS_LABEL}>Link</Typography>
              <TextInput
                maxLength={5000}
                style={{ ...STYLE_KEYS.QUESTIONS_INPUT, wordBreak: 'break-all' }}
                name="link"
                InputProps={{ readOnly: quizEditingBlocked }}
                value={question.link}
                placeholder={
                  quizEditingBlocked
                    ? undefined
                    : 'Un link de referencia sobre ésta pregunta (opcional)'
                }
                onChange={(key, value) => editFields({ [key]: value }, question)}
                helpText={
                  quizEditingBlocked
                    ? undefined
                    : `${question.link?.length ?? 0} / ${linkMaxLength}`
                }
                variant="standard"
              />
            </div>
          </div>
          {type && questionRender({ question, quizEditingBlocked }, type)}
        </div>
        <div className="col" style={{ gap: '0.5rem' }}>
          {question.errors?.map(error => (
            <FormHelperText key={error} style={STYLE_KEYS.QUESTIONS_VALIDATION}>
              {error}
            </FormHelperText>
          ))}
        </div>
      </div>
    </CustomTabPanel>
  )
}

export default QuestionSetup
