import React, { useState, useEffect, useRef } from 'react'
import {
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  IconButton,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/MoreVert'
import NoLiked from '@material-ui/icons/FavoriteBorder'
import Liked from '@material-ui/icons/Favorite'
import EditPlaylistDialogContainer from '../../containers/playlists/EditPlaylistDialogContainer'

const styles = (theme, palette2) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    header: {
      backgroundColor: 'rgba(0,0,0,0.99)',
      minHeight: 320,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 1,
    },
    headerContent: {
      padding: 32,
      maxWidth: '100%',
      marginTop: 50
    },
    title: {
      fontSize: 80,
      fontWeight: 10,
      color: '#ffffff',
      textShadow: '0 1.5px 3px rgba(0, 0, 0, 0.16)',
      fontFamily: 'Open Sans',
      lineHeight: 1.1,
      marginBottom: 20,
      [mediaLg]: {
        fontSize: 40,
      },
    },
    description: {
      float: 'left',
      marginTop: 9,
      fontSize: 24,
      fontWeight: 10,
      color: '#ffffff',
      textShadow: '0 1.5px 3px rgba(0, 0, 0, 0.16)',
      fontFamily: 'Open Sans',
      lineHeight: 1.1,
      marginBottom: 20,
    },
    paperDialog: {
      borderRadius: 0,
      backgroundColor: '#101010',
      paddingTop: 8,
      paddingBottom: 8,
    },
    menuItem: {
      color: '#ffffff',
      fontFamily: 'Open Sans',
    },
    action: {
      color: '#ffffff',
    },
  }
}

const HeaderPlaylist = ({
  classes,
  playlist,
  onDeletePlaylist,
  onFollowUnfollow,
  silentReloadPlaylist,
}) => {
  const [open, setOpen] = React.useState(false)

  const [openEditPlaylist, setOpenEditPlaylist] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <header className={classes.header}>
      <div className={classes.headerContent}>
        <Typography component={'h1'} className={classes.title}>
          {playlist.name}
          {playlist.mine && (
            <IconButton
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              className={classes.action}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{zIndex:10}}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper className={classes.paperDialog}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        className={classes.menuItem}
                        onClick={(e) => { setOpenEditPlaylist(true); handleClose(e); }}
                      >
                        Editar
                      </MenuItem>
                      <MenuItem className={classes.menuItem} onClick={(e) => { onDeletePlaylist(); handleClose(e); }}>
                        Eliminar
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <EditPlaylistDialogContainer
            playlist={playlist}
            open={openEditPlaylist}
            onDismiss={() => {
              setOpenEditPlaylist(false)
              silentReloadPlaylist()
            }}
          />
        </Typography>
        <Typography component={'p'} className={classes.description}>
          {playlist.followers_count} seguidores | {playlist.courses.length} cursos
        </Typography>
        <IconButton
          aria-label={playlist.followed ? 'DEJAR DE SEGUIR' : 'SEGUIR'}
          title={playlist.followed ? 'DEJAR DE SEGUIR' : 'SEGUIR'}
          className={classes.action}
          onClick={() => onFollowUnfollow()}
        >
          {playlist.followed ? <Liked /> : <NoLiked />}
        </IconButton>
      </div>
    </header>
  )
}
export default withStyles(styles)(HeaderPlaylist)
