import React, { Component } from 'react'
import MenuDrawer from '../menu/MenuDrawer'
import { IconButton, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CloseIcon from '@material-ui/icons/Close'
import { reorder, orderArrayBySort } from '../course/DraggableUtils'

const styles = {
  drawerContainer: {
    position: 'absolute',
    right: 0,
    top: 64,
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1em',
    boxSizing: 'border-box',
  },
  activityTitle: {
    padding: '1em 0 1em 1em',
    boxSizing: 'border-box',
  },
  blockTitle: {
    fontWeight: 700,
    padding: '1em 0',
  },
  body: {
    padding: '0 1em',
    paddingBottom: 150,
    boxSizing: 'border-box',
    height: '100%',
  },
  drawerPaper: {
    width: 480,
    position: 'relative',
    height: '100%',
    minHeight: '100%',
    paddingTop: '16px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #dedede',
  },
}

const DrawerCustomContent = ({
  classes,
  sortedSlides,
  slides,
  showDrawer,
  handleSubmitOrder,
  onDragEnd,
}) => (
  <React.Fragment>
    <div className={classes.toolbar}>
      <IconButton onClick={showDrawer}>
        <CloseIcon />
      </IconButton>
      <Button onClick={handleSubmitOrder}>GUARDAR</Button>
    </div>
    <div className={classes.body}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableSlidesContainer" style={{ height: '100%' }}>
          {(containerProvided, snapshot) => (
            <div ref={containerProvided.innerRef} style={{ height: '100%' }}>
              {sortedSlides &&
                sortedSlides.map((sortedSlide, index) => {
                  const slide = slides.find(item => item.id === sortedSlide)
                  return (
                    <Draggable draggableId={slide.id} index={index} key={slide.id}>
                      {(slideProvided, snapshot) => (
                        <div
                          className={classes.row}
                          ref={slideProvided.innerRef}
                          {...slideProvided.draggableProps}
                          {...slideProvided.dragHandleProps}
                        >
                          <Typography className={classes.blockTitle}>{slide.title ? slide.title : `Slide ID#${slide.id}`}</Typography>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              {containerProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  </React.Fragment>
)

const WrappedDrawerCustomContent = withStyles(styles)(DrawerCustomContent)

class SlidesDrawer extends Component {
  state = {
    sortedSlides: [],
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.slides && nextProps.slides) {
      const sortedSlides = nextProps.slides.sort(orderArrayBySort).map(slide => slide.id)
      this.setState({ sortedSlides })
    }
  }

  handleSubmitOrder = () => {
    this.props.onSubmit(this.state.sortedSlides)
  }

  onDragEnd = ({ source, destination }) => {
    const { sortedSlides } = this.state

    // dropped outside the list
    if (!destination) return

    if (source.droppableId === destination.droppableId) {
      const items = reorder(sortedSlides, source.index, destination.index)
      this.setState({ sortedSlides: items })
    }
    return
  }

  render() {
    const { classes, drawer, showDrawer, slides } = this.props
    const { sortedSlides } = this.state

    return (
      <div>
        <MenuDrawer
          customClasses={classes}
          mobileOpen={drawer}
          customContent={
            <WrappedDrawerCustomContent
              sortedSlides={sortedSlides}
              slides={slides}
              showDrawer={showDrawer}
              handleSubmitOrder={this.handleSubmitOrder}
              onDragEnd={this.onDragEnd}
            />
          }
        />
      </div>
    )
  }
}

export default withStyles(styles)(SlidesDrawer)
