import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config';

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    gradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(315deg, #b32b52 0%, #922343 6%, #6b1931 10%, #4a1121 17%, #2f0b15 24%, #1a060b 32%, #0b0205 59%, #020001 76%, #000000 100%)',
      opacity: 0.49,
    },
    header: {
      position: 'relative',
      backgroundSize: 'cover',
      background: `center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      height: '10%',
      minHeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
    headerContent: {
      paddingTop: 50,
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 24,
    },
    title: {
      maxWidth: 400,
      [mediaLg]: {
        height: 'auto',
        maxWidth: '80%',
      },
    },
    subtitle: {
      marginTop: 16,
      fontSize: 24,
      fontWeight: 100,
      color: '#fff',
      position: 'relative',
      [mediaLg]: {
        maxWidth: '90%',
        fontSize: 15,
      },
    },
    lineGlow: {
      content: `''`,
      display: 'block',
      width: '104%',
      border: '1px solid #ff7600',
      height: 0,
      [mediaLg]: {
        maxWidth: '90%',
        display: 'none',
      },
      '&:after': {
        content: `''`,
        display: 'block',
        width: '102%',
        marginLeft: '-1%',
        border: '1px solid #ff8d00',
        filter: 'blur(5.2px)',
        height: 0,
      },
    },
  };
};

class CategoriesHeader extends Component {
  render() {
    const { classes } = this.props;
    return (
      <header className={classes.header}>
        <div className={classes.gradient} />
        <div className={classes.headerContent}>
          <img
            role={'heading'}
            className={classes.title}
            alt="Explorá"
            src={config.BASE_URL + 'img/explora_white.png'}
          />
          <Typography component={'h1'} className={classes.subtitle}>
            <span aria-hidden="true">¡</span>CONOCÉ NUESTRAS CATEGORÍAS!
          </Typography>
          <div className={classes.lineGlow} />
        </div>
      </header>
    );
  }
}
export default withStyles(styles)(CategoriesHeader);
