import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { searchCourses, clearSearchCourses, deleteSharedCourse } from '../../actions/courses'
import { appLoadToken } from '../../actions/app'
import { goTo } from '../../actions/navigator'
import Search from '../../components/search/Search'
import { COURSES_VIEW, EXTERNAL_COURSES_VIEW, router } from '../../config/routes'
import { addSnackbar } from '../../actions/snackbar'
import { CourseType, SnackbarType } from '../../config/constants'
import DocumentTitle from '../../components/ui/DocumentTitle'

class SearchContainer extends Component {
  componentDidMount() {
    this.props.appLoadToken().then(() => {
      this.onQueryChange('')
    })
  }

  showCourse = course => {
    const { goTo } = this.props
    if (course.type === CourseType.ExternalCourse) {
      goTo(router.getRoute(EXTERNAL_COURSES_VIEW, { id: course.id }))
    } else {
      goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
    }
    this.props.clearSearchCourses()
  }

  removeSharedCourse = async course => {
    try {
      const { deleteSharedCourse, addSnackbar } = this.props
      await deleteSharedCourse(course.id)
      addSnackbar(`Recomendación descartada`, SnackbarType.Success)
      this.props.appLoadToken().then(() => {
        this.onQueryChange('')
      })
    } catch (err) {}
  }

  onQueryChange = query => {
    this.props.searchCourses(query)
  }

  render() {
    const { items, loading, query } = this.props
    return (
      <React.Fragment>
        <DocumentTitle title={'Buscar Cursos | Academia'} />
        <Search
          items={items}
          loading={loading}
          onShowCourse={this.showCourse}
          onRemoveSharedCourse={this.removeSharedCourse}
          onQueryChange={this.onQueryChange}
          query={query}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.courses.search,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { searchCourses, appLoadToken, goTo, clearSearchCourses, addSnackbar, deleteSharedCourse },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)
