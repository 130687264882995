import {
  EXTERNAL_COURSE_HOME_READ_END,
  EXTERNAL_COURSE_HOME_READ_FAILED, EXTERNAL_COURSE_HOME_READ_START,
  EXTERNAL_COURSE_READ_END,
  EXTERNAL_COURSE_READ_FAILED,
  EXTERNAL_COURSE_READ_START,
} from './index'
import api from '../api/api'
import moment from 'moment'

export function loadExternalCourseById(id, silent = false) {
  return async dispatch => {
    if(!silent) dispatch({ type: EXTERNAL_COURSE_READ_START })
    try {
      const response = await api.withDispatch(dispatch).ExternalCourses.get(id)
      dispatch({ type: EXTERNAL_COURSE_READ_END, payload: { course: response.data } })
    } catch (e) {
      dispatch({
        type: EXTERNAL_COURSE_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadNextDaysExternalCourses() {
  const key = 'nextDays'
  return async dispatch => {
    dispatch({ type: EXTERNAL_COURSE_HOME_READ_START, key  })
    try {
      const response = await api
        .withDispatch(dispatch)
        .ExternalCourses.nextDays(moment().format('YYYY-MM-DD HH:mm'), moment().add(15, 'days').format('YYYY-MM-DD 23:59'));
      dispatch({ type: EXTERNAL_COURSE_HOME_READ_END, key,  payload: { items: response.data } })
    } catch (e) {
      dispatch({
        type: EXTERNAL_COURSE_HOME_READ_FAILED,
        key,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadMyPendingExternalCourses() {
  const key = 'myPending'
  return async dispatch => {
    dispatch({ type: EXTERNAL_COURSE_HOME_READ_START, key })
    try {
      const response = await api.withDispatch(dispatch).My.ExternalCourses.pending()
      dispatch({ type: EXTERNAL_COURSE_HOME_READ_END, key, payload: { items: response.data } })
    } catch (e) {
      dispatch({
        type: EXTERNAL_COURSE_HOME_READ_FAILED,
        key,
        payload: { message: e.toString() },
      })
    }
  }
}
