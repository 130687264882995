import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CourseFormTopTen } from '../../../components/admin/course';
import { addSnackbar } from '../../../actions/snackbar';
import {
  createResource,
  updateResource,
  getResource,
  getResources,
  loadTopTenAdmin,
  deleteResource,
} from '../../../actions/admin';
import { goTo } from '../../../actions/navigator';
import {
  ADMIN_COURSES,
} from '../../../config/routes';
import { loadTopTen } from '../../../actions/courses';
import { SnackbarType } from '../../../config/constants';

class AdminCoursesTopTenFormContainer extends Component {
  state = {
    isValid: true,
    form: {
    },
    errors: {},
  };

  componentDidMount = async () => {
    if (this.props.isEdit) {
      this.props.loadTopTenAdmin();
      this.setState({ isValid: true });
    }
  };

  statusText = (status) => {
    if (status === 1) {
      return 'Borrador';
    }
    if (status === 2) {
      return 'Publicado';
    }
  }

  componentWillReceiveProps = (nextProps) => {
    let formData = [];
    if (!this.props.item && nextProps.items) {
      nextProps.items.forEach(element => {
        formData['puesto' + element.sort] = {
          id: element.course.id,
          title: element.course.title + '  (' + this.statusText(element.course.status) + ')',
        };
      });
      this.setState(
        {
          form: {
            ...nextProps.items,
            ...formData,
          }
        });
    }
  };

  validarRepetido = (item) => {
    const { form } = this.state;
    let count = 0;
    Object.entries(form).forEach(([key, value]) => {
      if (!value.course && value.id == item.id) {
        count = count + 1;
      }
    });
    if (count > 1) {
      return true;
    }
  }


  validateBasicForm = () => {
    const { form } = this.state;
    let error = false;
    const errors = {
      puesto1: '',
      puesto2: '',
      puesto3: '',
      puesto4: '',
      puesto5: '',
      puesto6: '',
      puesto7: '',
      puesto8: '',
      puesto9: '',
      puesto10: '',
    };

    if (form.puesto1 && this.validarRepetido(form.puesto1)) {
      errors.puesto1 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto2 && this.validarRepetido(form.puesto2)) {
      errors.puesto2 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto3 && this.validarRepetido(form.puesto3)) {
      errors.puesto3 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto4 && this.validarRepetido(form.puesto4)) {
      errors.puesto4 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto5 && this.validarRepetido(form.puesto5)) {
      errors.puesto5 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto6 && this.validarRepetido(form.puesto6)) {
      errors.puesto6 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto7 && this.validarRepetido(form.puesto7)) {
      errors.puesto7 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto8 && this.validarRepetido(form.puesto8)) {
      errors.puesto8 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto9 && this.validarRepetido(form.puesto9)) {
      errors.puesto9 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }
    if (form.puesto10 && this.validarRepetido(form.puesto10)) {
      errors.puesto10 = 'Este curso ya forma parte en el Top del Mes';
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
  };

  onChange = (form) => {
    this.setState({ form }, this.validateBasicForm);
  };

  handleSubmit = async () => {
    const {
      type,
      addSnackbar,
      createResource,
      isEdit,
      goTo,
    } = this.props;
    const { form } = this.state;

    const data = {
      ...form,
    };
    this.setState({ isValid: false });
    let dataForm = [];

    try {
      if (isEdit) {

        if (data.puesto1) {
          dataForm.push({
            course_id: data.puesto1.id,
            sort: 1
          });
        }
        if (data.puesto2) {
          dataForm.push({
            course_id: data.puesto2.id,
            sort: 2
          });
        }
        if (data.puesto3) {
          dataForm.push({
            course_id: data.puesto3.id,
            sort: 3
          });
        }
        if (data.puesto4) {
          dataForm.push({
            course_id: data.puesto4.id,
            sort: 4
          });
        }
        if (data.puesto5) {
          dataForm.push({
            course_id: data.puesto5.id,
            sort: 5
          });
        }
        if (data.puesto6) {
          dataForm.push({
            course_id: data.puesto6.id,
            sort: 6
          });
        }
        if (data.puesto7) {
          dataForm.push({
            course_id: data.puesto7.id,
            sort: 7
          });
        }
        if (data.puesto8) {
          dataForm.push({
            course_id: data.puesto8.id,
            sort: 8
          });
        }
        if (data.puesto9) {
          dataForm.push({
            course_id: data.puesto9.id,
            sort: 9
          });
        }
        if (data.puesto10) {
          dataForm.push({
            course_id: data.puesto10.id,
            sort: 10
          });
        }
      }

      createResource(type, dataForm)
        .then(() => {
          addSnackbar(`TOP del mes ${isEdit ? 'configurado' : 'creado'} exitosamente.`, SnackbarType.Success);
          goTo(ADMIN_COURSES);
        });
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_COURSES);
  };

  render() {
    const { form, ...results } = this.state;
    const props = {
      onSubmit: this.handleSubmit,
      onAttributeChange: this.onChange,
      results,
      form,
      onGoBack: this.onGoBack,
      ...this.props,
    };

    return (
      <CourseFormTopTen
        {...props}
        title='TOP del mes'
      />
    );
  }
}

AdminCoursesTopTenFormContainer.defaultProps = {
  type: 'courses-top',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.adminTopTenReducer;
  return {
    ...states
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResource,
      getResources,
      createResource,
      loadTopTen,
      loadTopTenAdmin,
      updateResource,
      deleteResource,
      addSnackbar,
      goTo,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCoursesTopTenFormContainer);
