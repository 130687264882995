import React, { Component } from 'react';
import GenericDialog from '../../components/ui/GenericDialog';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import InfiniteScroll from 'react-infinite-scroller';
import Moment from 'moment';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';

const styles = () => ({
  content: {
    minHeight: 150,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    margin: 'auto',
    padding: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  leftCol: {
    float: 'left',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  comment: {
    fontSize: 12,
    color: '#707070',
    fontStyle: 'italic',
    marginBottom: 15
  },
  date: {
    fontSize: 12,
    color: '#9a9a9a',
    paddingLeft: 15
  },
  userInfo: {
    marginBottom: 8,
  },
  userAvatar: {
    float: 'left',
  },
  userName: {
    float: 'left',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 14,
  },
  rating: {
    marginTop: 18,
  },
  totals: {
    minHeight: 50,
    marginTop: 10,
  },
  bigStars: {
    fontSize: 24,
    float: 'left',
  },
  stars: {
    float: 'left',
  },
  star: {
    fontSize: 16,
    float: 'left',
    marginTop: 0,
    marginRight: 3
  },
  npsScoreTitle: {
    color: '#a1a1a1',
    float: 'left',
    fontSize: 12,
    marginTop: 1,
  },
  ratingsCount: {
    marginLeft: 11,
    color: '#a1a1a1',
    float: 'left',
    fontSize: 12,
    marginTop: 0,
    textDecoration: 'underline',
  },
  userNpsScore: {
    fontSize: 12,
    color: '#9a9a9a',
    marginTop: 18,
  },
  contentTitle: {
    color: '#777777',
    paddingBottom: 11,
    borderBottom: '1px solid #a1a1a1',
    fontSize: 16,
    fontWeight: 700,
  },
  buttonRate: {
    color: '#a1a1a1',
    borderWidth: 1,
    borderColor: '#a1a1a1',
    borderStyle: 'solid',
    fontSize: 12,
  }
});

const initialState = {
  stars: 1,
  comment: '',
};

class CreateReviewDialog extends Component {
  state = { ...initialState };

  resetDialog = () => {
    this.setState({ ...initialState });
  };

  renderReviewItem = (item) => {
    const { classes } = this.props;
    return (
      <div key={item.id} className={classes.row}>
        <div className={classes.leftCol}>
          <Typography className={classes.comment}>"{item.comment}"</Typography>
        </div>
        <div className={classes.rightCol}>
          <Typography className={classes.date}>{Moment(item.created_at).format("DD/MM/YYYY")}</Typography>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const {
      classes,
      course,
      hasMore,
      loading,
      items = [],
      onFetchMore,
    } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.row}>
          <div className={classes.leftCol}>
            <div className={classes.totals}>
              <Typography className={classes.npsScoreTitle}>
                <StarOutlinedIcon className={classes.star} />
                NPS {parseInt(course.nps_score)}
              </Typography>

              <Typography className={classes.ratingsCount}>
                {course.rates_count === 1
                  ? '1 valoración'
                  : `${course.rates_count} valoraciones`}
              </Typography>
            </div>
          </div>
          <div className={classes.rightCol}>

          </div>
        </div>

        <InfiniteScroll
          pageStart={0}
          loadMore={onFetchMore}
          hasMore={hasMore && !loading}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          {items.map((review) => this.renderReviewItem(review))}
        </InfiniteScroll>
      </div>
    );
  };

  render() {
    const { open, onDismiss, headerColor } = this.props;
    return (
      <GenericDialog
        miniHeader={true}
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        name={'VALORACIÓN DEL CURSO'}
        icon={null}
        title={null}
        onExit={this.resetDialog}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}

export default withStyles(styles)(CreateReviewDialog);
