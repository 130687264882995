import React, { useCallback, useEffect, useState } from 'react'
import ColorButton from '../../../components/ui/ColorButton'
import AdminContentWrapper from '../../../containers/admin/AdminContentWrapperContainer'
import DownloadIcon from '@material-ui/icons/GetApp'
import { addSnackbar } from '../../../actions/snackbar'
import { AdminItemViewType, COLOR_KEYS, SnackbarType } from '../../../config/constants'
import { editEvent, generateEvents, updateEvents } from '../../../actions/admin'
import { ReplayOutlined } from '@material-ui/icons'
import AdminResourceTable from '../../../components/admin/AdminResourceTable'
import ApprovedInput from '../../../components/admin/scorm/ApprovedInput'
import ActionCheckbox from '../../../components/admin/scorm/ActionCheckbox'
import { useDispatch } from 'react-redux'
import ConfirmDialog from '../../../components/ui/ConfirmDialog'
import GoBackButton from '../../ui/GoBackButton'

const { PRIMARY } = COLOR_KEYS

const btnStyles = {
  height: '3rem',
  padding: '1rem 1rem 1rem 0.5rem',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const AdminScormConfigPanel = ({ scormId }) => {
  const [data, setData] = useState()
  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const dispatch = useDispatch()
  const [generateEventsConfirmDialogOpen, setGenerateEventsConfirmDialogOpen] = useState(false)

  const notificate = (message, type) => {
    addSnackbar(message, type)(dispatch)
  }

  const handleGenerateEvents = useCallback(() => {
    if (!data || !data.length) {
      regenerateEvents()
    } else {
      setGenerateEventsConfirmDialogOpen(true)
    }
  }, [data])

  const regenerateEvents = async () => {
    setDataIsLoading(true)
    try {
      const response = await generateEvents(scormId)
      setData(response.data)
      if (response.data.length) {
        notificate('Tabla generada satisfactoriamente')
      } else {
        notificate('No se encontraron eventos para la actividad', SnackbarType.Warning)
      }
    } catch {
      notificate(
        'Ocurrió un error al intentar generar la tabla de eventos. Reintente',
        SnackbarType.Error,
      )
    }
    setDataIsLoading(false)
  }

  const handleGetEvents = async () => {
    setDataIsLoading(true)
    try {
      const response = await updateEvents(scormId)
      if (response.data.length) {
        setData(response.data)
        notificate('Datos obtenidos correctamente')
      } else {
        notificate(
          'Se requiere la acción de generar la tabla de eventos con el botón "Eventos"',
          SnackbarType.Warning,
        )
      }
    } catch {
      notificate('Error al obtener datos', SnackbarType.Error)
    }
    setDataIsLoading(false)
  }

  const handleEditEvent = async (id, payload, { onSuccessMsg, onErrorMsg }) => {
    setEditing(true)
    try {
      const response = await editEvent(id, payload)
      setData(response.data)
      notificate(onSuccessMsg)
    } catch {
      notificate(onErrorMsg, SnackbarType.Error)
    }
    setEditing(false)
  }

  const columns = [
    {
      title: 'ID',
      source: 'id',
      type: AdminItemViewType.Number,
    },
    {
      title: 'Evento',
      source: 'verb',
      type: AdminItemViewType.Text,
    },
    {
      title: 'Target',
      source: 'target',
      type: AdminItemViewType.Text,
    },
    {
      title: 'Resultado',
      source: 'result',
      type: AdminItemViewType.Number,
    },
    {
      title: 'Aprobado',
      source: 'approved',
      type: AdminItemViewType.Custom,
      renderItem: item => {
        const { id, approved, select_event } = item
        return (
          <ApprovedInput
            initialValue={approved}
            saveNewValue={value =>
              handleEditEvent(
                id,
                {
                  approved: value,
                  select_event: value && !select_event ? true : null,
                },
                {
                  onSuccessMsg: 'Puntaje de aprobación modificado',
                  onErrorMsg: 'Error al modificar puntaje de aprobación. Reintente',
                },
              )
            }
            editing={editing}
          />
        )
      },
    },
    {
      title: 'Seleccionar evento',
      source: 'select_event',
      type: AdminItemViewType.Custom,
      renderItem: item => {
        const { id, select_event, select_target } = item
        return (
          <ActionCheckbox
            checked={!!select_event}
            onClick={async () =>
              await handleEditEvent(
                id,
                {
                  select_event: !select_event,
                  select_target: select_event && select_target ? false : null,
                  approved: select_event ? 0 : null,
                },
                {
                  onSuccessMsg: 'Evento seleccionado',
                  onErrorMsg: 'Error al seleccionar el evento. Reintente',
                },
              )
            }
            editing={editing}
          />
        )
      },
    },
    {
      title: 'Seleccionar target',
      source: 'select_target',
      type: AdminItemViewType.Custom,
      renderItem: item => {
        const { id, select_target } = item
        return (
          <ActionCheckbox
            checked={!!select_target}
            onClick={async () =>
              await handleEditEvent(
                id,
                {
                  select_target: !select_target,
                  select_event: select_target ? null : true,
                  approved: select_target ? 0 : null,
                },
                {
                  onSuccessMsg: 'Target seleccionado',
                  onErrorMsg: 'Error al seleccionar el target. Reintente',
                },
              )
            }
            editing={editing}
          />
        )
      },
    },
  ]

  useEffect(() => {
    handleGetEvents()
  }, [])

  return (
    <AdminContentWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '2rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <GoBackButton />
          <h1 style={{ marginLeft: 16 }}>Configuracion de Scorm #{scormId}</h1>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '2rem',
          }}
        >
          <ColorButton
            color={PRIMARY}
            label="Eventos"
            style={btnStyles}
            onClick={handleGenerateEvents}
            extraView={<DownloadIcon />}
            extraViewWithoutContainer
          />
          <ColorButton
            color={PRIMARY}
            label="Actualizar"
            style={btnStyles}
            onClick={handleGetEvents}
            extraView={<ReplayOutlined />}
            extraViewWithoutContainer
          />
        </div>
      </div>
      <AdminResourceTable
        key="resource-table"
        items={data ?? []}
        columns={columns}
        forceNoActions
        isLoading={dataIsLoading}
      />
      <ConfirmDialog
        title={'ATENCIÓN - Regenerar datos'}
        open={generateEventsConfirmDialogOpen}
        description={
          'Se borrará la configuración existente. Ésta acción no se puede deshacer ¿Seguro que desea regenerar la tabla y sus datos?'
        }
        onContinue={() => {
          regenerateEvents()
          setGenerateEventsConfirmDialogOpen(false)
        }}
        onCancel={() => setGenerateEventsConfirmDialogOpen(false)}
      />
    </AdminContentWrapper>
  )
}

export default AdminScormConfigPanel
