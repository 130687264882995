import React, { Component } from 'react'
import { EditInputType } from '../../../../config/constants'
import AdminResourceForm from '../../AdminResourceForm'
import styles from './ActivityModalStyles'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FullScreenDialog from '../../../ui/FullScreenDialog'
import config from '../../../../config'
import { createContentScorm } from '../../../../actions/admin'

const stylesScorm = {
  ...styles,

  scormButton: {
    height: 200,
    width: '100%',
    backgroundColor: '#eaeaea',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  scormText: {
    color: '#000000',
  },
}

class ActivityModalScormContent extends Component {
  state = {
    fullDialogOpen: false,
  }

  constructor(props) {
    super(props)
    this.myIframe = React.createRef()
    this.loaded = false
    this.locked = false
  }

  getFormColumns = () => {
    return [
      {
        title: 'Título',
        source: 'name',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'name' },
      },
      {
        title: 'Descripción',
        source: 'description',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'description' },
      },
      {
        title: 'Es trackeable',
        source: 'trackable',
        editable: true,
        editConfig: {
          type: EditInputType.RadioGroup,
          name: 'trackable',
          row: true,
          options: [
            {
              id: true,
              description: 'Si',
            },
            {
              id: false,
              description: 'No',
            },
          ],
        },
      },
      {
        title: '',
        source: 'external',
        editable: true,
        editConfig: {
          type: EditInputType.Custom,
          name: 'external',
          renderItem: this.renderScormButton,
        },
      },
      {
        title: 'Mostrar',
        source: 'target_type',
        editable: true,
        editConfig: {
          type: EditInputType.RadioGroup,
          name: 'target_type',
          row: true,
          options: [
            {
              id: 1,
              description: 'En la actividad',
              helperContent:
                'Por restricciones de navegación, es posible que en ocasiones esta opción no se aplique y sea necesario mostrar el contenido en una ventana nueva.',
            },
            {
              id: 2,
              description: 'A pantalla completa',
              helperContent:
                'Por restricciones de navegación, es posible que en ocasiones esta opción no se aplique y sea necesario mostrar el contenido en una ventana nueva.',
            },
            {
              id: 3,
              description: 'En ventana nueva',
            },
          ],
        },
      },
    ]
  }

  onAttributeChange = (name, value) => {
    const { activity, onChange } = this.props
    onChange({ ...activity, [name]: value })
  }

  onIframeLoad = () => {
    if (!this.loaded) {
      this.loaded = true
    } else {
      this.loaded = false
      this.onCloseScormDialog(true)
    }
  }

  onEditScorm = async () => {
    if (!this.locked) {
      this.locked = true
      const { activity, onChange } = this.props
      if (!activity.external) {
        const scormId = await createContentScorm(activity.type)
        if (scormId) {
          onChange({ ...activity, external: scormId })
        }
      } else {
        onChange({ ...activity })
      }
      this.setState({ fullDialogOpen: true }, () => {
        this.locked = false
      })
    }
  }

  renderScormButton = () => {
    const { classes } = this.props
    return (
      <div className={classes.scormButton} onClick={() => this.onEditScorm()}>
        <Typography className={classes.scormText}>{'Editar contenido'}</Typography>
      </div>
    )
  }

  onCloseScormDialog = save => {
    const { results } = this.props
    if (save && results && results.isValid) {
      this.props.onSubmit()
      this.props.onCancel()
    }
    this.loaded = false
    this.setState({ fullDialogOpen: false })
  }

  render() {
    const { activity, results, onSubmit, onCancel, actionButtons, title, classes } = this.props

    return (
      <div>
        <AdminResourceForm
          title={title}
          keyName={'admin-activity-modal-scorm-form'}
          form={activity}
          isValid={results && results.isValid}
          errors={results ? results.errors : []}
          loading={false}
          attributes={{ keys: this.getFormColumns() }}
          onAttributeChange={this.onAttributeChange}
          customClasses={classes}
          onSubmit={onSubmit}
          onCancel={onCancel}
          actionButtons={actionButtons}
        />
        <FullScreenDialog
          open={this.state.fullDialogOpen}
          onClose={() => this.onCloseScormDialog(false)}
        >
          {activity.external > 0 && (
            <iframe
              allowFullScreen={'allowFullScreen'}
              ref={this.myIframe}
              title={'SCORM Editor'}
              onLoad={() => this.onIframeLoad()}
              frameBorder="0"
              src={`${config.WP_ROOT}/wp-admin/admin-ajax.php?action=update_scorm_form&scorm_id=${activity.external}`}
              width={'100%'}
              height={'100%'}
              style={{ margin: 'auto', maxWidth: 960 }}
            />
          )}
        </FullScreenDialog>
      </div>
    )
  }
}

export default withStyles(stylesScorm)(ActivityModalScormContent)
