import React, { useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { appLoadToken } from '../../actions/app'
import {
  loadMyPlaylists,
  loadTopFollowedPlaylists,
  loadMyFollowedPlaylists,
  loadPlaylistsByUser,
} from '../../actions/playlists'
import { goTo } from '../../actions/navigator'
import { COURSES_VIEW, PLAYLIST_VIEW, router } from '../../config/routes'
import { PlaylistGroup } from '../../config/constants'
import DocumentTitle from '../../components/ui/DocumentTitle'
import Playlists from '../../components/playlists/Playlists'
import Loading from '../../components/ui/Loading'
import api from '../../api/api'

const PlaylistsContainer = ({
  appLoadToken,
  loadMyPlaylists,
  loadTopFollowedPlaylists,
  loadMyFollowedPlaylists,
  loadPlaylistsByUser,
  goTo,
  myPlaylists,
  myFollowedPlaylists,
  topPlaylists,
  me,
}) => {
  const loadLists = useCallback(
    (silent = false) => {
      loadMyPlaylists(silent)
      loadTopFollowedPlaylists(silent)
      loadMyFollowedPlaylists(silent)
    },
    [loadMyPlaylists, loadTopFollowedPlaylists, loadMyFollowedPlaylists],
  )

  useEffect(() => {
    appLoadToken().then(() => {
      loadLists()
    })
  }, [appLoadToken, loadLists])

  const showCourse = useCallback(
    course => {
      goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
    },
    [goTo],
  )

  const showPlaylist = useCallback(
    playlist => {
      goTo(router.getRoute(PLAYLIST_VIEW, { id: playlist.id }))
    },
    [goTo],
  )

  const onFollowUnfollow = useCallback(
    async playlist => {
      try {
        await api.Playlists.followUnfollow(playlist.id, !playlist.followed)
        loadLists(true)
      } catch (e) {}
    },
    [loadLists],
  )

  const onLoadPlayListByUser = useCallback(
    users => {
      if (users) {
        loadPlaylistsByUser(true, users.map(u => u.id).join(','))
      } else {
        loadPlaylistsByUser(true, false)
      }
    },
    [loadPlaylistsByUser],
  )

  return (
    <React.Fragment>
      <DocumentTitle title={'Listas | Academia'} />
      {me.loading ? (
        <Loading fullSize={true} />
      ) : (
        <Playlists
          myPlaylists={myPlaylists}
          myFollowedPlaylists={myFollowedPlaylists}
          topPlaylists={topPlaylists}
          onShowCourse={showCourse}
          onShowPlaylist={showPlaylist}
          onFollowUnfollow={onFollowUnfollow}
          onLoadPlayListByUser={onLoadPlayListByUser}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const states = state.playlistsReducer
  return {
    me: { ...state.userReducer },
    myPlaylists: { ...states[PlaylistGroup.MyPlaylists] },
    topPlaylists: { ...states[PlaylistGroup.TopFollowedPlaylists] },
    myFollowedPlaylists: { ...states[PlaylistGroup.MyFollowedPlaylists] },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      appLoadToken,
      goTo,
      loadMyPlaylists,
      loadTopFollowedPlaylists,
      loadMyFollowedPlaylists,
      loadPlaylistsByUser,
    },
    dispatch,
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaylistsContainer))
