import {
  EXPLORE_CAREER_PLANS_START,
  EXPLORE_CAREER_PLANS_END,
  EXPLORE_CAREER_PLANS_FAILED,
  EXPLORE_CAREER_PLANS_CHANNELS_START,
  EXPLORE_CAREER_PLANS_CHANNELS_END,
  EXPLORE_CAREER_PLANS_CHANNELS_FAILED,
  EXPLORE_CAREER_PLANS_BY_CHANNEL_START,
  EXPLORE_CAREER_PLANS_BY_CHANNEL_END,
  EXPLORE_CAREER_PLANS_BY_CHANNEL_FAILED
} from '../../actions';

const initialState = {
  loading: true,
  error: false,
  allCareerPlans: {},
  allChannels: {},
};

function careerPlansExploreReducers(state = initialState, action) {
  switch (action.type) {
    case EXPLORE_CAREER_PLANS_START: {
      // TODO: retrieves all data temporaly while has been created services get courses service 
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case EXPLORE_CAREER_PLANS_END: {
      // TODO: retrieves all data temporaly while has been finished services get courses service api
      return {
        ...state,
        loading: false,
        error: false,
        allCareerPlans: action.payload,
      };
    }
    case EXPLORE_CAREER_PLANS_FAILED: {
      // TODO: retrieves all data temporaly while has been failed services get courses service api
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case EXPLORE_CAREER_PLANS_BY_CHANNEL_START: {
      // TODO: retrieves all data temporaly while has been created services get courses service 
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case EXPLORE_CAREER_PLANS_BY_CHANNEL_END: {
      // TODO: retrieves all data temporaly while has been finished services get courses service api
      return {
        ...state,
        loading: false,
        error: false,
        allCareerPlans: action.payload,
      };
    }
    case EXPLORE_CAREER_PLANS_BY_CHANNEL_FAILED: {
      // TODO: retrieves all data temporaly while has been failed services get courses service api
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case EXPLORE_CAREER_PLANS_CHANNELS_START: {
      // TODO: retrieves all data temporaly while has been created services get courses service api
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case EXPLORE_CAREER_PLANS_CHANNELS_END: {
      // TODO: retrieves all data temporaly while has been finished services get courses service api      
      return {
        ...state,
        loading: false,
        error: false,
        allChannels: action.payload,
      };
    }
    case EXPLORE_CAREER_PLANS_CHANNELS_FAILED: {
      // TODO: retrieves all data temporaly while has been failed services get courses service api
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
}

export default careerPlansExploreReducers;
