import React, { Component } from 'react';
import { Avatar, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

class UserAvatar extends Component {
  render() {
    const { user, clear, mini, className, classes, onClick } = this.props;
    const style = {
      backgroundColor: clear ? '#ffffff' : '#101010',
    };

    if (user != null) {
      return (
        <Avatar
          style={style}
          className={classNames(
            clear ? classes.clear : classes.dark,
            mini ? classes.mini : classes.big,
            className
          )}
          onClick={onClick}
        >
          <Typography
            aria-hidden="true"
            variant={null}
            className={mini ? classes.charsMini : className}
          >
            {user.first_name.charAt(0).toUpperCase()}
            {user.last_name.charAt(0).toUpperCase()}
          </Typography>
        </Avatar>
      );
    } else {
      return null;
    }
  }
}

const styles = {
  clear: {
    color: '#ed1818',
  },
  mini: {
    width: 48,
    height: 48,
  },
  big: {
    width: 72,
    height: 72,
    fontSize: 30,
  },

  chars: {
    color: '#fff',
    fontSize: 30,
  },

  charsMini: {
    color: '#fff',
    fontSize: 20,
  },
  dark: {
    boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#101010',
    color: '#fff',
  },
};
export default withStyles(styles)(UserAvatar);
