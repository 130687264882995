import { createMuiTheme } from '@material-ui/core'

export const calendar_table_theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTable: {
      root: {
        borderCollapse: 'collapse',
      },
    },
    MuiTableCell: {
      root: {
        padding: 4,
        border: '1px solid rgba(224, 224, 224, 1)',
        width: '14.2857%',
        minWidth: 90,
        maxWidth: 1,
        verticalAlign: 'top',
      },
      head: {
        padding: 16,
        textAlign: 'center',
        borderRight: 'none',
        borderLeft: 'none',
        color: '#70757a',
        textTransform: 'uppercase',
      },
    },
  },
})
