const minutesToDurationText = minutes => {
    let hrs = Math.floor(minutes / 60)
    let min = minutes % 60
    let result = ''
    if (hrs > 0) {
        result = result.concat(hrs)
        hrs > 1 ? (result = result.concat(' horas ')) : (result = result.concat(' hora '))
        if (min > 0) {
            result = result.concat(min)
            min > 1 ? (result = result.concat(' minutos ')) : (result = result.concat(' minuto '))
        }
    } else {
        result = result.concat(minutes)
        minutes > 1 ? (result = result.concat(' minutos ')) : (result = result.concat(' minuto '))
    }
    return result.trim()
}


const capitalizeTheFirstLetterOfEachWord = (words) => {
    var separateWord = words.toLowerCase().split(' ');
    for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
    }
    return separateWord.join(' ');
}

export { minutesToDurationText, capitalizeTheFirstLetterOfEachWord }
