import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { rateCourse, shareCourse } from '../../actions/courses';
import { addSnackbar } from '../../actions/snackbar';
import CourseShareDialog from '../../components/courses/CourseShareDialog';
import { SnackbarType } from '../../config/constants';

class CourseShareDialogContainer extends Component {

  handleSubmit = (data) => {
    const {
      onDismiss = () => { },
      shareCourse,
      addSnackbar,
    } = this.props;

    shareCourse(data)
      .then((r) => {
        addSnackbar('Curso compartido exitosamente!', SnackbarType.Success);
      })
      .catch((e) => { });
    onDismiss();
  };

  //enable_publish
  render() {
    const {
      open,
      onDismiss = () => { },
      initialRating,
      course,
      headerColor,
    } = this.props;
    return (
      <CourseShareDialog
        headerColor={headerColor}
        course={course}
        initialRating={initialRating}
        open={open}
        onDismiss={onDismiss}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ rateCourse, addSnackbar, shareCourse }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CourseShareDialogContainer)
);
