import React from 'react'
import { Typography } from '@material-ui/core'
import { COLOR_KEYS } from '../../../../config/constants'

const TRIVIA_INFO = {
  fontSize: '1rem',
}

const QuizTriviaInfoField = ({ label, value, style }) => {
  return (
    <div className="row trivia-info-header" style={style}>
      <Typography style={{ ...TRIVIA_INFO, fontSize: 'inherit', color: COLOR_KEYS.TEXT }}>
        {label}
      </Typography>
      <Typography
        style={{
          ...TRIVIA_INFO,
          fontWeight: 700,
          fontSize: 'inherit',
          color: 'inherit',
          textAlign: 'right',
        }}
      >
        {value}
      </Typography>
    </div>
  )
}

export default QuizTriviaInfoField
