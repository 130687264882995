import React, { useEffect, useState, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { addSnackbar } from '../../actions/snackbar'
import { SnackbarType } from '../../config/constants'
import PlaylistCourseDialog from '../../components/playlists/PlaylistCourseDialog'
import api from '../../api/api'

const initialState = { playlists: [] }

const PlaylistCourseDialogContainer = ({ course, addSnackbar, open, onDismiss }) => {
  const [state, updateState] = useState(initialState)
  const [shouldUpdate, setShouldUpdate] = useState(false)

  useEffect(() => {
    updateState({ playlists: course.playlists })
  }, [course])

  const onClose = useCallback(async () => {
    try {
      if(shouldUpdate) {
        setShouldUpdate(false)
        await api.My.Courses.updatePlaylists(
            course.id,
            state.playlists.filter(p => p.checked).map(p => p.id),
        )
        addSnackbar('Listas actualizadas', SnackbarType.Success)
      }
    } catch (e) {
      console.log(e)
    }
    onDismiss()
  }, [onDismiss, addSnackbar, state, shouldUpdate])

  const onCreatePlaylist = useCallback(
    async name => {
      try {
        const response = await api.Playlists.new(name)
        const playlist = response.data
        updateState({
          playlists: [
            { id: playlist.id, name: playlist.name, checked: true },
            ...state.playlists,
          ],
        })
        setShouldUpdate(true)
        addSnackbar('Lista creada', SnackbarType.Success)
      } catch (e) {
        console.log(e)
        addSnackbar('Ocurrió un error al crear la lista', SnackbarType.Error)
      }
    },
    [addSnackbar, state, updateState, setShouldUpdate],
  )

  const onExit = useCallback(() => {
  }, [])

  const onPlaylistClicked = useCallback(
    async playlist => {
      try {
        updateState({
          playlists: state.playlists.map(p => {
            if (p.id === playlist.id) {
              p.checked = !p.checked
            }
            return p
          }),
        })
        setShouldUpdate(true)
      } catch (e) {}
    },
    [state, updateState, setShouldUpdate],
  )

  return (
    <PlaylistCourseDialog
      playlists={state.playlists}
      open={open}
      handleClose={onClose}
      onExit={onExit}
      onPlaylistClicked={onPlaylistClicked}
      onCreatePlaylist={onCreatePlaylist}
    />
  )
}

const mapStateToProps = state => {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addSnackbar }, dispatch)
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlaylistCourseDialogContainer),
)
