import React, { useCallback, useRef, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import HorizontalZoomSlider from '../ui/HorizontalZoomSlider'
import MixedCourseItem from '../ui/MixedCourseItem'
import { IconButton, Typography, Popper, Grow, Paper, MenuList, ClickAwayListener, MenuItem} from '@material-ui/core'
import NoLiked from '@material-ui/icons/FavoriteBorder'
import Liked from '@material-ui/icons/Favorite'
import MenuIcon from '@material-ui/icons/MoreVert'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    root: {
      backgroundColor: '#101010',
      display: 'flex',
      flexFlow: 'column',
      maxWidth: 'calc(100vw - 0%)',
      paddingTop: 24,
      paddingBottom: 50,
      paddingRight: 90,
      paddingLeft: 90,
      position: 'relative',
      [mediaLg]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    playlistTitle: {
      fontSize: 24,
      display: 'block',
      color: '#fff',
      paddingTop: 16,
      paddingRight: 16,
      cursor: 'pointer',
      [mediaLg]: {
        marginLeft: 16,
        fontSize: 16,
      },
    },
    playlistSubHeader: {
      float: 'left',
      flexDirection: 'row',
      alignItems: 'center',
    },
    action: {
      color: '#ffffff',
    },
    paperDialog: {
      borderRadius: 0,
      backgroundColor: '#101010',
      paddingTop: 8,
      paddingBottom: 8,
    },
    menuItem: {
      color: '#ffffff',
      fontFamily: 'Open Sans',
    },
  }
}

const PlaylistCustomHeader = ({ playlist, classes, onShowPlaylist, onFollowUnfollow, isOwnList=false }) => {

  const [open, setOpen] = React.useState(false)


  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div>
      <Typography
        tabIndex={0}
        component={'h2'}
        className={classes.playlistTitle}
        aria-label={'Carrusel Lista: ' + playlist.name}
        
      >
        {playlist.name}
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.action}
        >
          <MenuIcon />
        </IconButton>
      </Typography>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={classes.paperDialog}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >                  
                  <MenuItem className={classes.menuItem} onClick={() => onShowPlaylist(playlist)}>
                    Abrir lista
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {isOwnList && (
        <p className={classes.playlistSubHeader}>
          {playlist.followers_count} seguidores | {playlist.courses.length} cursos
        </p>
      )}

      {!isOwnList && (
        <React.Fragment>
          <p className={classes.playlistSubHeader}>
            {playlist.followers_count} seguidores | {playlist.courses.length} cursos | creada por{' '}
            {playlist.owner.full_name}
          </p>
          <IconButton
              aria-label={playlist.followed ? 'DEJAR DE SEGUIR' : 'SEGUIR'}
              title={playlist.followed ? 'DEJAR DE SEGUIR' : 'SEGUIR'}
              className={classes.action}
              onClick={() => onFollowUnfollow(playlist)}
          >
            {playlist.followed ? <Liked /> : <NoLiked />}
          </IconButton>
        </React.Fragment>
      )}
    </div>
  )
}

const ContentPlaylists = ({
  classes,
  myPlaylists,
  myFollowedPlaylists,
	topPlaylists,
  onShowCourse,
  onShowPlaylist,
  onFollowUnfollow
}) => {

  const onCreateItemCourse = useCallback(
    item => {
      return <MixedCourseItem item={item} itemClick={onShowCourse} />
    },
    [onShowCourse],
  )

  const children = []

  if (myPlaylists && myPlaylists.items.length > 0) {
    myPlaylists.items.forEach((playlist, index) => {
      if (playlist.courses.length > 0) {
        children.push(
          <HorizontalZoomSlider
            key={'hzs-my-playlists-' + index}
            title={playlist.name}
            itemCreator={item => onCreateItemCourse(item)}
            items={playlist.courses}
            customHeader={
              <PlaylistCustomHeader
                playlist={playlist}
                classes={classes}
                onShowPlaylist={onShowPlaylist}
                onFollowUnfollow={onFollowUnfollow}
                isOwnList={true}
              />
            }
          />,
        )
      }
    })
  }

	if (myFollowedPlaylists && myFollowedPlaylists.items.length > 0) {
    myFollowedPlaylists.items.forEach((playlist, index) => {
      if (playlist.courses.length > 0) {
        children.push(
          <HorizontalZoomSlider
            key={'hzs-top-playlists-' + index}
            title={playlist.name}
            titleClick={() => onShowPlaylist(playlist)}
            itemCreator={item => onCreateItemCourse(item)}
            items={playlist.courses}
            customHeader={
              <PlaylistCustomHeader
                playlist={playlist}
                classes={classes}
                onShowPlaylist={onShowPlaylist}
                onFollowUnfollow={onFollowUnfollow}
              />
            }
          />,
        )
      }
    })
  }

  if (topPlaylists && topPlaylists.items.length > 0) {
		topPlaylists.items.forEach((playlist, index) => {
      if (playlist.courses.length > 0) {
        children.push(
          <HorizontalZoomSlider
            key={'hzs-top-playlists-' + index}
            title={playlist.name}
            titleClick={() => onShowPlaylist(playlist)}
            itemCreator={item => onCreateItemCourse(item)}
            items={playlist.courses}
            customHeader={
              <PlaylistCustomHeader
                playlist={playlist}
                classes={classes}
                onShowPlaylist={onShowPlaylist}
                onFollowUnfollow={onFollowUnfollow}
              />
            }
          />,
        )
      }
    })
  }

  return (
    <main id="main" className={classes.root} tabIndex={-1}>
      {children}
    </main>
  )
}
export default withStyles(styles)(ContentPlaylists)
