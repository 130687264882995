/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react'

import MUIDataTable from 'mui-datatables'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import HorizontalTabPanel from '../ui/HorizontalTabPanel'
import Paper from '@material-ui/core/Paper'
import { STYLE_KEYS } from '../../config/constants'

const tabProps = index => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

const ProfileTables = ({ classes, theme, tablesData }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <div
      className="col"
      style={{ maxWidth: '100%', overflow: 'auto', width: '100%', padding: '0 12px' }}
    >
      <Tabs
        variant="scrollable"
        value={selectedTab}
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor="transparent"
      >
        {!!tablesData?.length &&
          tablesData.map((item, index) => (
            <Tab
              className={classes.tab}
              key={`tab-${index}`}
              style={{
                textAlign: 'left',
                transition: STYLE_KEYS.DEFAULT_TRANSITION,
                backgroundColor: '#fff',
                margin: '0 0.25rem',
                borderRadius: '0.35rem 0.35rem 0 0',
              }}
              label={<Typography>{item.title}</Typography>}
              {...tabProps(index)}
            />
          ))}
      </Tabs>
      <Paper className={classes.paper}>
        <MuiThemeProvider theme={theme}>
          {!!tablesData?.length &&
            tablesData.map(({ id, columns, options, data }, index) => (
              <HorizontalTabPanel
                value={selectedTab}
                index={index}
                key={id}
                className={selectedTab === index && classes.activeTabPanel}
                style={{ transition: STYLE_KEYS.DEFAULT_TRANSITION }}
              >
                <MUIDataTable id={id} data={data} columns={columns} options={options} />
              </HorizontalTabPanel>
            ))}
        </MuiThemeProvider>
      </Paper>
    </div>
  )
}

export default ProfileTables
