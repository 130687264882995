import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ComplementarioIco from '../../assets/images/ico_complementario.png';
import NecesarioIco from '../../assets/images/ico_necesario.png';
import {minutesToDurationText} from "../../utils/FormatHelper";

const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 0,
    marginTop: 18,
  },
  wrapper: {
    width: 500,
    marginRight: 10,
  },
  icon: {
    position: 'relative',
    top: 2,
    marginRight: 5,
  },
  duration: {
    marginLeft: 2,
    marginTop: 6,
    width: '100%',
  },
  durationTitle: {
    fontFamily: 'Open Sans',
    fontSize: 15,
    fontWeight: 600,
    fontStyle: 'normal',
    color: '#ffffff'
  },
  durationValor: {
    fontFamily: 'Open Sans',
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#ffffff'
  },
  steps: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#ffffff',
  },
  titleProgress: {
    position: 'relative',
    zIndex: 10,
    bottom: '-24px',
    color: '#fff',
    left: 5,
  },
  percentage: {
    fontFamily: 'Open Sans',
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#ffffff',
  },
  progessBar: {
    width: '100%',
    height: 25,
    opacity: 0.42,
    backgroundColor: '#fff',
  },
  progessBarActive: {
    width: 0,
    transition: 'width 300ms ease-out',
    position: 'relative',
    height: 25,
    bottom: 25,
  },
  percentageTotally: {
    color: '#fff',
    height: 25,
    fontWeight: 600,
    minWidth: 55,
  },
};

function ProgressStylesWithIcon({
  done,
  total,
  title,
  duration,
  classes,
}) {
  const percentaje = `${total}` === 0 ? 0 : Math.trunc((`${done}` / `${total}`) * 100);
  const percentajePaint = `${total}` === 0 ? 0 : (`${done}` / `${total}`) * 100;

  const backColorProgress = title === 'Necesarios' ? '#cb1b4a' : '#29aa9e';
  const stylePercentageActive = {
    width: `${percentajePaint}%`,
    backgroundColor: backColorProgress,
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7} className={classes.container}>
          <Grid className={classes.icon}>
            {title === 'Necesarios' &&
              <img aria-hidden="true" src={NecesarioIco} width="20px" height="28" alt={'Necesarios'} />
            }
            {title === 'Complementarios' &&
              <img aria-hidden="true" src={ComplementarioIco} width="20px" height="28" alt={'Complementarios'} />
            }
          </Grid>
          <Grid className={classes.wrapper}>
            <Typography
              className={classes.titleProgress}
            >{`${title} (${done}/${total})`}</Typography>
            <div className={classes.progessBar} />
            <div
              className={classes.progessBarActive}
              style={stylePercentageActive}
            />
          </Grid>
          <Grid item xs={2}>
            {!percentaje
              ? <Typography className={classes.percentageTotally}>
                0%.
            </Typography>
              : <Typography className={classes.percentageTotally}>
                {percentaje}%.
            </Typography>
            }
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} className={classes.duration}>
          <Typography className={classes.durationTitle}>
            Duración: <span className={classes.durationValor}>{minutesToDurationText(duration) + '.'}</span>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

ProgressStylesWithIcon.propTypes = {
  done: PropTypes.number,
  total: PropTypes.number,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

ProgressStylesWithIcon.defaultProps = {
  backgroundColorProgress: '#1ed1a8',
  title: 'Título progreso',
};

const ProgressWithIcon = withStyles(styles)(ProgressStylesWithIcon);

export default ProgressWithIcon;
