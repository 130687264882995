import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../../actions/navigator';
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource,
} from '../../../actions/admin';
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  router,
  ADMIN_CAREER_PLAN,
} from '../../../config/routes';
import AdminResourceIndex from '../AdminResourceIndex';
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType,
  CareerPlanStatus,
  CareerPlanTypeNames
} from '../../../config/constants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { addSnackbar } from '../../../actions/snackbar';

const KEY_NAME = 'admin-career-plans';
const DEFAULT_CATEGORIES_ORDER = [
  {
    name: 'careerPlan.id',
    order: 'ASC',
  },
];

class AdminCategoriesContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
    drawer: false,
    careerPlanSelected: [],
    careerPlanIdSelected: null,
  };

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size, undefined)
  };

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Nombre', source: 'name' },
      { title: 'Mapa', source: 'map' },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(CareerPlanStatus).map((k) => ({
          condition: k,
          result: CareerPlanStatus[k],
        })),
      },
      /* {
        title: 'Tipo',
        source: 'type',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(CareerPlanTypeNames).map((k) => ({
          condition: k,
          result: CareerPlanTypeNames[k],
        })),
      }, */
    ];
  };

  onCreate = () => {
    this.props.goTo(ADMIN_CAREER_PLAN + ADMIN_NEW);
  };

  onEditItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_CAREER_PLAN + ADMIN_EDIT, { id: item.id })
    );
  };

  onDeleteItem = (item) => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.name,
    });
  };

  getActions = (item) => {
    return [
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
    ];
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'careerPlan.name',
        placeholder: 'Nombre',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Text,
        name: 'careerPlan.map',
        placeholder: 'Documento',
        format: FiltersFormat.Like,
      },
    ];
  };

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props;
    const item = this.state.deleteItem;
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Plan de carrera eliminado.', SnackbarType.Success);
        refreshResources(KEY_NAME);
      })
      .catch((err) => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error);
      });
  };

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
  };

  showDrawer = () => {
    this.setState((prevState) => ({ drawer: !prevState.drawer }));
  };


  render() {
    const {
      confirmDeleteOpen,
      itemToDelete
    } = this.state;

    return (
      <AdminResourceIndex
        isBooleanDocuement
        keyName={KEY_NAME}
        title={'RUTAS DE APRENDIZAJE'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        getOrderingConfig={() => DEFAULT_CATEGORIES_ORDER}
      >
        <ConfirmDialog
          title={'Eliminar Plan de Carrera'}
          open={confirmDeleteOpen}
          description={`¿Estás seguro de eliminar el plan de carrera ${itemToDelete}?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    );
  }
}

AdminCategoriesContainer.defaultProps = {
  type: 'career-plans',
};

const mapStateToProps = (state) => {
  const states = state.paginatorReducer;
  return {
    paginator: { ...states[KEY_NAME] },
    ...state.menuDrawerReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResources,
      refreshResources,
      deleteResource,
      goTo,
      addSnackbar,
      updateResource,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCategoriesContainer);
