import React, { useEffect, useCallback, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadExternalCourseById } from '../../actions/externalCourses'
import { appLoadToken } from '../../actions/app'
import { withRouter } from 'react-router-dom'
import { addSnackbar } from '../../actions/snackbar'
import ExternalCourse from '../../components/externalCourses/ExternalCourse'
import { CourseType, SnackbarType } from '../../config/constants'
import { COURSES_VIEW, EXTERNAL_COURSES_VIEW, router } from '../../config/routes'
import { goTo } from '../../actions/navigator'
import api from '../../api/api'
import ExternalCourseInscriptionDialog from '../../components/externalCourses/ExternalCourseInscriptionDialog'
import DocumentTitle from '../../components/ui/DocumentTitle'

const ExternalCourseContainer = ({
  externalCourseId,
  course,
  loading,
  addSnackbar,
  loadExternalCourseById,
  appLoadToken,
  goTo,
}) => {
  const [subscriptionDialogState, updateSubscriptionDialogState] = useState({
    open: false,
    commission: null,
  })

  const loadCourse = useCallback(() => {
    appLoadToken().then(() => {
      loadExternalCourseById(externalCourseId)
    })
  }, [appLoadToken, loadExternalCourseById, externalCourseId])

  const onShowCourse = useCallback(
    course => {
      if (course.type === CourseType.ExternalCourse) {
        goTo(router.getRoute(EXTERNAL_COURSES_VIEW, { id: course.id }))
      } else {
        goTo(router.getRoute(COURSES_VIEW, { id: course.id }))
      }
    },
    [goTo],
  )

  const onSubscribe = useCallback(
    async (commission, suscribeToWaiting) => {
      try {
        if (suscribeToWaiting) {
          await api.ExternalCourses.subscribeCommissionToWaitingList(commission.id)
          addSnackbar('Te inscribiste en la lista de espera de la comisión', SnackbarType.Success)
        } else {
          await api.ExternalCourses.subscribeCommission(commission.id)
          updateSubscriptionDialogState({ open: true, commission: commission })
        }
        loadExternalCourseById(externalCourseId, true)
      } catch (e) {
        addSnackbar(e.message || 'ERROR', SnackbarType.Error)
      }
    },
    [addSnackbar, loadExternalCourseById, externalCourseId, updateSubscriptionDialogState],
  )

  const onUnsubscribe = useCallback(
    async commission => {
      try {
        await api.ExternalCourses.unsubscribeCommission(commission.id)
        loadExternalCourseById(externalCourseId, true)
        addSnackbar(
          'Inscripción cancelada, recordá eliminar la cita de tu calendario.',
          SnackbarType.Success,
        )
      } catch (e) {
        addSnackbar(e.message || 'ERROR', SnackbarType.Error)
      }
    },
    [addSnackbar, loadExternalCourseById, externalCourseId],
  )

  const onDismiss = useCallback(() => {
    updateSubscriptionDialogState({ open: false, commission: null })
  }, [updateSubscriptionDialogState])

  useEffect(() => {
    loadCourse()
  }, [loadCourse])

  return (
    <>
      {course && <DocumentTitle title={course.name + ' | Talleres | Academia'} />}
      <ExternalCourse
        course={course}
        loading={loading}
        onShowCourse={onShowCourse}
        onSubscribe={onSubscribe}
        onUnsubscribe={onUnsubscribe}
      />
      <ExternalCourseInscriptionDialog
        open={subscriptionDialogState.open}
        commission={subscriptionDialogState.commission}
        course={course}
        onDismiss={onDismiss}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    ...state.externalCourseReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadExternalCourseById,
      appLoadToken,
      addSnackbar,
      goTo,
    },
    dispatch,
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExternalCourseContainer))
