import {
  CAREER_PLANS_CATEGORIES_READ_START,
  CAREER_PLANS_CATEGORIES_READ_END,
  CAREER_PLANS_CATEGORIES_READ_FAILED,
} from '../../actions';

import MockCategories from './mockCategories';

const initialState = {
  loading: false,
  error: false,
  careerCategories: [...MockCategories],
};

function careerPlansCategoriesReducers(state = initialState, action) {
  switch (action.type) {
    case CAREER_PLANS_CATEGORIES_READ_START: {
      // TODO: retrieves all data temporaly while has been created
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case CAREER_PLANS_CATEGORIES_READ_END: {
      // TODO: retrieves all data temporaly while has been finished
      return {
        ...state,
        loading: false,
        error: false,
        careerCategories: action.payload,
      };
    }
    case CAREER_PLANS_CATEGORIES_READ_FAILED: {
      // TODO: retrieves all data temporaly while has been failed
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
}

export default careerPlansCategoriesReducers;
