import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  createResource,
  updateResource,
  getResource,
} from '../../../actions/admin';
import { EditInputType, SnackbarType } from '../../../config/constants';
import AdminResourceForm from '../../../components/admin/AdminResourceForm';
import { addSnackbar } from '../../../actions/snackbar';
import { ADMIN_TAGS } from '../../../config/routes';
import { goTo } from '../../../actions/navigator';
import validator from 'validator';
class AdminTagsFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      name: '',
    },
    errors: {
      name: '',
    },
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
    }
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.item && nextProps.item) {
      const { name } = nextProps.item;
      this.setState({ form: { name } }, () => this.validateForm());
    }
  };

  onAttributeChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } }, () =>
      this.validateForm()
    );
  };

  getColumns = () => {
    return {
      keys: [
        {
          title: 'Nombre',
          source: 'name',
          editable: true,
          editConfig: { type: EditInputType.Text, name: 'name' },
        },
      ],
    };
  };

  getRequestDataFromForm = () => {
    const { form } = this.state;
    return {
      name: form.name,
    };
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_TAGS);
  };

  onSubmit = () => {
    const {
      addSnackbar,
      goTo,
      createResource,
      updateResource,
      isEdit,
      item,
      type,
    } = this.props;
    const data = this.getRequestDataFromForm();
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          addSnackbar('Etiqueta editada exitosamente.', SnackbarType.Success);
          goTo(ADMIN_TAGS);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    } else {
      createResource(type, data)
        .then(() => {
          addSnackbar('Etiqueta creada exitosamente.', SnackbarType.Success);
          goTo(ADMIN_TAGS);
        })
        .catch((err) => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    }
  };

  validateForm = () => {
    const form = this.state.form;
    let error = false;
    let errors = {
      name: '',
    };

    if (validator.isEmpty(form.name.trim())) {
      errors.name = 'Debes completar este campo';
      error = true;
    } else if (!validator.isLength(form.name.trim(), { min: 2, max: 140 })) {
      errors.name = 'Debes completar entre 2 y 140 caracteres.';
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_TAGS);
  };

  render() {
    const { loading, title } = this.props;
    return (
      <AdminResourceForm
        keyName={'admin-tags-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    );
  }
}

AdminTagsFormContainer.defaultProps = {
  type: 'tags',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName],
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResource, createResource, updateResource, addSnackbar, goTo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTagsFormContainer);
