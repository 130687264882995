import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { addSnackbar } from '../../../actions/snackbar'
import { goTo } from '../../../actions/navigator'
import { resetSelectedUsers } from '../../../actions/user'
import { createResource, updateResource } from '../../../actions/admin'
import { loadJobPositionsForLot, loadOrganizationUnitsForLot } from '../../../actions/lotAdmin'
import { appLoadToken } from '../../../actions/app'
import PropTypes from 'prop-types'
import { AudienceAssignationType, AudienceType, SnackbarType } from '../../../config/constants'
import { ADMIN_EXTERNAL_COURSES_COMMISSIONS, router } from '../../../config/routes'
import AdminExternalCoursesCommissionsStepForm from './AdminExternalCoursesCommissionsStepForm'
import AdminExternalCoursesCommissionsStepJpOrOuAssignation from './AdminExternalCoursesCommissionsStepJpOrOuAssignation'
import AdminExternalCoursesCommissionsStepUserAssignation from './AdminExternalCoursesCommissionsStepUserAssignation'

const steps = ['Datos Básicos', 'Asignación']

const styles = theme => ({
  header: {
    display: 'flex',
    width: '100%',
    padding: '1em 8em',
    boxSizing: 'border-box',
    background: '#454545',
  },
  titleHeader: {
    color: '#fff',
    fontSize: 20,
  },
  root: {
    width: '100%',
    margin: '50px 0 20px 0',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  step: {
    root: {
      color: '#FF00FF11',
    },
  },
})

Stepper.propTypes = {
  classes: PropTypes.object,
}

const Steps = Object.freeze({
  Form: 0,
  Assignation: 1,
})

const initialState = {
  usersSelected: [],
  jobPositionsSelected: [],
  organizationalUnitsSelected: [],
  allOrganizationalUnitsSelected: false,
  stepTwoHasChanged: false,
}

const AdminExternalCoursesCommissionsStepperContainer = ({
  classes,
  appLoadToken,
  keyName,
  params,
  externalCourseId,
  isEdit,
  addSnackbar,
  goTo,
  createResource,
  updateResource,
  loadJobPositionsForLot,
  loadOrganizationUnitsForLot,
  lotMetadata,
  resetSelectedUsers,
}) => {
  const [formData, updateFormData] = useState(null)

  const [state, updateState] = useState(initialState)

  const [step, changeStep] = useState(Steps.Form)

  const handleBackToStepOne = () => {
    changeStep(Steps.Form)
  }

  const onSaveCommission = useCallback(async data => {
    data.facilitators = [...new Set(data.facilitators.map(f => f.id))]
    if (isEdit) {
      updateResource('commissions', params.id, data)
        .then(() => {
          addSnackbar('Comisión editada exitosamente.', SnackbarType.Success)
          goTo(router.getRoute(ADMIN_EXTERNAL_COURSES_COMMISSIONS, { externalCourseId }))
        })
        .catch(err => {
          addSnackbar(err.message, SnackbarType.Error)
        })
      resetSelectedUsers()
    } else {
      createResource('commissions', data)
        .then(() => {
          addSnackbar('Comisión creada exitosamente.', SnackbarType.Success)
          goTo(router.getRoute(ADMIN_EXTERNAL_COURSES_COMMISSIONS, { externalCourseId }))
        })
        .catch(err => {
          addSnackbar(err.message, SnackbarType.Error)
        })
      resetSelectedUsers()
    }
  }, [])

  const onSubmitStep = useCallback(
    (step, data) => {
      if (step === Steps.Form) {
        updateFormData(data)
        updateState({
          jobPositionsSelected: data.job_positions,
          organizationalUnitsSelected: data.organizational_units,
          stepTwoHasChanged: false,
        })
        if (data.audience === AudienceType.Aimed) {
          if (data.audience_assignation_type === AudienceAssignationType.CostCenter) {
            appLoadToken().then(() => {
              loadJobPositionsForLot('commissions')
              loadOrganizationUnitsForLot('commissions')
            })
          }
          changeStep(Steps.Assignation)
        } else {
          appLoadToken().then(() =>
            onSaveCommission({
              ...data,
              job_positions: [],
              organizational_units: [],
              users: [],
            }),
          )
        }
      } else {
        const payload = {
          ...data,
          job_positions:
            data.audience_assignation_type === AudienceAssignationType.CostCenter
              ? state.jobPositionsSelected.map(element => element.id)
              : [],
          organizational_units:
            data.audience_assignation_type === AudienceAssignationType.CostCenter
              ? state.organizationalUnitsSelected.map(element => element.id)
              : [],
          users:
            data.audience_assignation_type === AudienceAssignationType.Users
              ? state.usersSelected.map(element => element.id)
              : [],
        }
        appLoadToken().then(() => onSaveCommission(payload))
      }
    },
    [onSaveCommission, state],
  )

  const handleSelectAllOrganizationalUnit = allSelected => {
    updateState({ ...state, allOrganizationalUnitsSelected: allSelected, stepTwoHasChanged: true })
  }

  const handleChangeJobPosition = value => {
    updateState({ ...state, jobPositionsSelected: value, stepTwoHasChanged: true })
  }

  const handleChangeOrganizationalUnit = value => {
    updateState({ ...state, organizationalUnitsSelected: value, stepTwoHasChanged: true })
  }

  const handleChangeUsers = value => {
    updateState({ ...state, usersSelected: value, stepTwoHasChanged: true })
  }

  const handleBack = () => {
    goTo(router.getRoute(ADMIN_EXTERNAL_COURSES_COMMISSIONS, { externalCourseId }))
  }

  useEffect(() => {
    resetSelectedUsers()
    updateState(initialState)
  }, [])

  return (
    <Grid container justify="center">
      <div className={classes.header}>
        {isEdit ? (
          <Typography className={classes.titleHeader}>EDITAR COMISIÓN #{params.id}</Typography>
        ) : (
          <Typography className={classes.titleHeader}>NUEVA COMISIÓN</Typography>
        )}
      </div>
      <Grid container className={classes.root} justify="center">
        <Grid item xs={10}>
          <Stepper activeStep={step}>
            {steps.map(label => {
              return (
                <Step key={label} className={classes.step}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>

          {step === Steps.Form && (
            <AdminExternalCoursesCommissionsStepForm
              externalCourseId={externalCourseId}
              isEdit={isEdit}
              params={params}
              keyName={keyName}
              onSubmit={data => onSubmitStep(Steps.Form, data)}
              handleBack={handleBack}
              prevState={formData}
            />
          )}

          {step === Steps.Assignation &&
            formData &&
            formData.audience_assignation_type === AudienceAssignationType.CostCenter && (
              <AdminExternalCoursesCommissionsStepJpOrOuAssignation
                externalCourseId={externalCourseId}
                isEdit={isEdit}
                params={params}
                keyName={keyName}
                handleBack={handleBackToStepOne}
                onSubmit={() => onSubmitStep(Steps.Assignation, formData)}
                jobPositions={lotMetadata.jobPositions}
                jobPositionsSelected={state.jobPositionsSelected}
                handleChangeJobPosition={handleChangeJobPosition}
                organizationalUnits={lotMetadata.organizationalUnits}
                organizationalUnitsSelected={state.organizationalUnitsSelected}
                handleChangeOrganizationalUnit={handleChangeOrganizationalUnit}
                handleSelectAllOu={handleSelectAllOrganizationalUnit}
                allOrganizationalUnitsSelected={state.allOrganizationalUnitsSelected}
              />
            )}
          {step === Steps.Assignation &&
            formData &&
            formData.audience_assignation_type === AudienceAssignationType.Users && (
              <AdminExternalCoursesCommissionsStepUserAssignation
                isEdit={isEdit}
                usersSelected={state.usersSelected ?? []}
                handleChangeUsers={handleChangeUsers}
                handleBack={handleBackToStepOne}
                onSubmit={() => onSubmitStep(Steps.Assignation, formData)}
              />
            )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  return {
    lotMetadata: state.lotAdminReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadJobPositionsForLot,
      loadOrganizationUnitsForLot,
      createResource,
      updateResource,
      addSnackbar,
      goTo,
      appLoadToken,
      resetSelectedUsers,
    },
    dispatch,
  )
}

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminExternalCoursesCommissionsStepperContainer),
  ),
)
