import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { isEmpty } from 'lodash'
import Loading from '../../../components/ui/Loading'
import { addSnackbar } from '../../../actions/snackbar'
import { SnackbarType } from '../../../config/constants'
import { goToAdminCareerPlan, goTo } from '../../../actions/navigator'
import { createResource, updateResource, getResource } from '../../../actions/admin'
import {
  loadChannelsForCareerPlan,
  loadScalesForCareerPlan,
  loadCoursesForCareerPlan,
  loadJobPositionsForCareerPlan,
  loadOrganizationUnitsForCareerPlan,
} from '../../../actions/careerPlanAdmin'
import api from '../../../api/api'
import { appLoadToken } from '../../../actions/app'
import PropTypes from 'prop-types'
import StepOne from '../../../components/admin/career_plans/StepOne'
import StepTwo from '../../../components/admin/career_plans/StepTwo'
import StepThree from '../../../components/admin/career_plans/StepThree'
import { ADMIN_CAREER_PLAN } from '../../../config/routes'

const steps = ['Datos Básicos', 'Cursos asociados', 'Asignación']
const [firstStep, secondStep, lastStep] = steps.map((_, id) => id)

const styles = theme => ({
  header: {
    display: 'flex',
    width: '100%',
    padding: '1em 8em',
    boxSizing: 'border-box',
    background: '#454545',
  },
  titleHeader: {
    color: '#fff',
    fontSize: 20,
  },
  root: {
    width: '100%',
    margin: '50px 0 20px 0',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  step: {
    root: {
      color: '#FF00FF11',
    },
  },
})

class AdminCareerPlansStepperContainer extends Component {
  state = {
    loading: true,
    disableFirstNextButton: true,
    isValid: false,
    isPublished: false,
    careerPlanProps: {
      id: false,
      name: '',
      map: '',
      status: '',
      type: '',
      channels: [],
      courses: [],
      assignations: {
        all_organizational_units: 0,
        job_position: [],
        organizational_unit: [],
      },
    },
    activeStep: 0,
    pdfSelected: '',
    channelsSelected: [],
    coursesSelected: [],
    jobPositionsSelected: [],
    organizationalUnitsSelected: [],
    allOrganizationalUnitsSelected: false,
    stepOneHasChanged: false,
    stepTwoHasChanged: false,
    stepThreeHasChanged: false,
  }

  componentDidMount = async () => {
    const {
      appLoadToken,
      loadChannelsForCareerPlan,
      loadCoursesForCareerPlan,
      loadScalesForCareerPlan,
      loadJobPositionsForCareerPlan,
      loadOrganizationUnitsForCareerPlan,
    } = this.props
    try {
      if (this.props.isEdit) this.onLoadData()

      appLoadToken().then(() => {
        loadChannelsForCareerPlan()
        loadCoursesForCareerPlan()
        loadScalesForCareerPlan()
        loadJobPositionsForCareerPlan()
        loadOrganizationUnitsForCareerPlan()
      })
      if (!this.props.isEdit) this.setState({ loading: false })
    } catch (error) {
      console.error(error)
    }
  }

  onLoadData = () => {
    const { getResource, params, keyName } = this.props
    getResource(keyName, 'career-plans', params.id)
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.item && this.props.item !== nextProps.item) {
      const { id, name, map, status, type, channels, courses, assignations } = nextProps.item

      this.setState({
        isPublished: status === 2,
        careerPlanProps: {
          id: id || false,
          name: name || '',
          map: map || '',
          status: status || '',
          type: type || '',
          channels: channels || [],
          courses: courses || [],
          assignations: {
            all_organizational_units: assignations.all_organizational_units || false,
            job_position: assignations.job_position || [],
            organizational_unit: assignations.organizational_unit || [],
          },
        },
        coursesSelected: [],
      })
      this.updateAssignations()
    }

    if (this.state.careerPlanProps.id && !isEmpty(this.props.careerPlanMetadata.channels)) {
      this.setState({
        loading: false,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.careerPlanMetadata !== this.props.careerPlanMetadata &&
      this.state.careerPlanProps.id !== undefined &&
      this.state.careerPlanProps.id !== ''
    ) {
      this.updateAssignations()
    }
  }

  updateAssignations = () => {
    if (!this.state.careerPlanProps.assignations) return
    if (
      !isEmpty(this.props.careerPlanMetadata.channels) &&
      this.state.careerPlanProps.channels.length
    )
      this.selectedChannels(
        this.state.careerPlanProps.channels,
        this.props.careerPlanMetadata.channels,
      )

    if (
      !isEmpty(this.props.careerPlanMetadata.courses) &&
      this.state.careerPlanProps.courses.length
    ) {
      this.selectedCourses(
        this.state.careerPlanProps.courses,
        this.props.careerPlanMetadata.courses,
      )
    }

    if (
      !isEmpty(this.props.careerPlanMetadata.jobPositions) &&
      this.state.careerPlanProps.assignations.job_position.length
    )
      this.selectedJobPositions(
        this.state.careerPlanProps.assignations.job_position,
        this.props.careerPlanMetadata.jobPositions,
      )
    if (
      !isEmpty(this.props.careerPlanMetadata.organizationalUnits) &&
      this.state.careerPlanProps.assignations.organizational_unit.length
    )
      this.selectedOrganizationalUnits(
        this.state.careerPlanProps.assignations.organizational_unit,
        this.props.careerPlanMetadata.organizationalUnits,
      )

    if (!isEmpty(this.props.careerPlanMetadata.courses)) {
      this.setState({
        disableFirstNextButton: false,
      })
    }

    if (
      this.state.careerPlanProps.id &&
      this.state.careerPlanProps.assignations != undefined &&
      this.state.careerPlanProps.assignations.all_organizational_units != 0
    ) {
      this.setState({
        allOrganizationalUnitsSelected:
          this.state.careerPlanProps.assignations.all_organizational_units,
      })
    }
  }

  increment = prevState => {
    this.setState(prevState => ({
      activeStep: this.state.activeStep + 1,
    }))
  }
  decrement = prevState => {
    this.setState(prevState => ({
      activeStep: this.state.activeStep - 1,
    }))
  }

  selectedChannels = (careerPlanChannels, channels) => {
    var channelsSelected = channels.filter(function (el) {
      return careerPlanChannels.includes(el.id)
    })

    this.setState(prevState => ({
      channelsSelected: channelsSelected,
      loading: false,
    }))
  }

  selectedCourses = (careerPlanCourses, courses) => {
    let coursesSelected = courses.data
      .filter(e1 => careerPlanCourses.find(e2 => e1.id === e2.id))
      .map(e1 => {
        let careerPlanCourseData = careerPlanCourses.find(e2 => e1.id === e2.id)
        e1.mandatory = careerPlanCourseData.mandatory
        e1.scale = careerPlanCourseData.scale
        return e1
      })

    this.setState(prevState => ({
      coursesSelected: coursesSelected,
    }))
  }

  selectedJobPositions = (careerPlanJobPositions, jobPositions) => {
    let jobPositionsSelected = jobPositions.data.filter(e1 =>
      careerPlanJobPositions.includes(e1.id),
    )
    this.setState(prevState => ({
      jobPositionsSelected: jobPositionsSelected,
    }))
  }

  selectedOrganizationalUnits = (careerPlanOrganizationalUnits, organizationalUnits) => {
    let organizationalUnitsSelected = organizationalUnits.data.filter(e1 =>
      careerPlanOrganizationalUnits.includes(e1.id),
    )
    this.setState(prevState => ({
      organizationalUnitsSelected: organizationalUnitsSelected,
    }))
  }

  handleNextStep = async () => {
    const { addSnackbar } = this.props
    if (firstStep === this.state.activeStep && this.state.stepOneHasChanged) {
      const callbackSuccess = () => {
        addSnackbar('Plan curricular editado con éxito', SnackbarType.Success)
        this.increment(this.state.activeStep)
      }
      const callbackError = error => addSnackbar(error.message, SnackbarType.Warning)

      return this.updateOrCreateCareerPlan(callbackSuccess, callbackError)
    }

    if (secondStep === this.state.activeStep && this.state.stepTwoHasChanged) {
      const callbackSuccess = () => {
        addSnackbar('Cursos asociados con éxito', SnackbarType.Success)
        this.increment(this.state.activeStep)
      }
      const callbackError = error => addSnackbar(error.message, SnackbarType.Warning)

      return this.updateCarreerPlanCourses(callbackSuccess, callbackError)
    }

    this.increment(this.state.activeStep)
  }

  createPayloadToDraft = () => {
    return {
      all_organizational_units: this.state.allOrganizationalUnitsSelected,
      job_position: this.state.jobPositionsSelected.map(element => element.id),
      organizational_unit: this.state.organizationalUnitsSelected.map(element => element.id),
    }
  }

  handleSaveAssignations = async () => {
    const { addSnackbar } = this.props
    const payload = this.createPayloadToDraft()

    const callbackSuccess = () => {
      addSnackbar('Borrador guardado con éxito', SnackbarType.Success)
      this.props.goTo(ADMIN_CAREER_PLAN)
    }
    const callbackError = error => addSnackbar(error.message, SnackbarType.Warning)

    try {/*
      if (!this.validateAssignations(payload)) {
        addSnackbar('Ha superado el número máximo de relaciones (50)', SnackbarType.Warning)
        return
      }*/
      await api.CareerPlan.setCareerPlanAssignations(this.state.careerPlanProps.id, payload)
      await callbackSuccess()
    } catch (error) {
      callbackError(error)
    }
  }

  validateAssignations = payload => {
    if (
      payload.all_organizational_units &&
      (payload.job_position.length > 50 || payload.job_position.length < 1)
    )
      return false
    if (
      !payload.all_organizational_units &&
      (payload.job_position.length > 50 ||
        payload.job_position.length < 0 ||
        payload.organizational_unit.length > 50)
    )
      return false
    return true
  }

  handlePublish = async () => {
    const { addSnackbar } = this.props
    const payload = this.createPayloadToDraft()
    const callbackSuccess = () => {
      addSnackbar('Ruta de aprendizaje publicada', SnackbarType.Success)
      this.props.goTo(ADMIN_CAREER_PLAN)
    }
    const callbackError = error => addSnackbar(error.message, SnackbarType.Warning)

    try {/*
      if (!this.validateAssignations(payload)) {
        addSnackbar('Ha superado el número máximo de relaciones (50)', SnackbarType.Warning)
        return
      }*/
      this.setState({ loading: true })
      await api.CareerPlan.setCareerPlanAssignations(this.state.careerPlanProps.id, payload)
      await api.CareerPlan.publishCareerPlans(this.state.careerPlanProps.id)
      this.setState({ loading: false })
      callbackSuccess()
    } catch (error) {
      callbackError(error)
    }
  }

  handleBackStep = () => {
    if (firstStep === this.state.activeStep) {
      this.setState({ activeStep: 0 })
      this.props.goTo(ADMIN_CAREER_PLAN)
      return
    }

    if (secondStep === this.state.activeStep) {
      this.decrement(this.state.activeStep)
      return
    }

    if (lastStep === this.state.activeStep) {
      this.decrement(this.state.activeStep)
      return
    }
  }

  updateOrCreateCareerPlan = async (callbackSuccess, callbackError) => {
    const { isEdit } = this.props
    const payload = {
      name: this.state.careerPlanProps.name,
      map: this.state.careerPlanProps.map,
      channels: this.state.channelsSelected.map(channel => channel.id),
      type: this.state.careerPlanProps.type
    }

    try {
      let careerPlanId = this.state.careerPlanProps.id ? this.state.careerPlanProps.id : false
      const {
        data: { career_plan_id },
      } = await api.CareerPlan.createOrupdateCareerPlan(isEdit, payload, careerPlanId)

      await this.setState(prevState => ({
        careerPlanProps: {
          id: career_plan_id || careerPlanId,
          name: payload.name,
          map: payload.map,
          type: payload.type,
        },
        stepOneHasChanged: false,
      }))

      if (!isEdit) await getResource('admin-career-plan-edit', 'career-plans', career_plan_id)

      callbackSuccess()
    } catch (error) {
      callbackError(error)
    }
  }

  updateCarreerPlanCourses = async (callbackSuccess, callbackError) => {
    const { params } = this.props
    const payload = {
      courses: this.state.coursesSelected.map(element => ({
        id: element.id,
        mandatory: element.mandatory,
        scale: element.scale,
      })),
    }

    try {
      api.CareerPlan.associateCourseToCareerPlan(this.state.careerPlanProps.id, payload)
      callbackSuccess()
    } catch (error) {
      callbackError(error)
    }
    this.setState(prevState => ({
      stepTwoHasChanged: false,
    }))
  }

  handleChangeCareerPlanName = value => {
    this.setState(prevState => ({
      careerPlanProps: {
        ...prevState.careerPlanProps,
        name: value,
      },
      stepOneHasChanged: true,
    }))
  }

  handleChangeCareerPlanMap = value => {
    this.setState(prevState => ({
      careerPlanProps: {
        ...prevState.careerPlanProps,
        map: value,
      },
      stepOneHasChanged: true,
    }))
  }

  handleChangeCareerPlanType = value => {
    this.setState(prevState => ({
      careerPlanProps: {
        ...prevState.careerPlanProps,
        type: value,
      },
      stepOneHasChanged: true,
    }))
  }

  handleChangeCareerPlanChannels = value => {
    this.setState(prevState => ({
      channelsSelected: value,
      stepOneHasChanged: true,
    }))
  }

  handleChangeCareerPlanCourses = newCoursesSelected => {
    this.setState(prevState => ({
      coursesSelected: newCoursesSelected,
      stepTwoHasChanged: true,
    }))
  }

  handleChangeCareerPlanCourseMandatory = courseId => {
    let coursesSelectedModified = this.state.coursesSelected.map(element => {
      if (element.id === courseId) {
        element.mandatory = !element.mandatory
      }
      return element
    })

    this.setState(prevState => ({
      coursesSelected: coursesSelectedModified,
      stepTwoHasChanged: true,
    }))
  }

  handleChangeCareerPlanCourseScale = (courseId, scaleId) => {
    let coursesSelectedModified = this.state.coursesSelected.map(element => {
      if (element.id === courseId) {
        element.scale = scaleId
      }
      return element
    })

    this.setState(prevState => ({
      coursesSelected: coursesSelectedModified,
      stepTwoHasChanged: true,
    }))
  }

  handleChangeJobPosition = value => {
    this.setState(() => ({
      jobPositionsSelected: value,
      stepThreeHasChanged: true,
    }))
  }

  handleChangeOrganizationalUnit = value => {
    this.setState(() => ({
      organizationalUnitsSelected: value,
      stepThreeHasChanged: true,
    }))
  }

  handleSelectAllOu = allSelected => {
    if (allSelected) {
      this.setState(prevState => ({
        allOrganizationalUnitsSelected: true,
        organizationalUnitsSelected: [],
        stepThreeHasChanged: true,
      }))
      return
    }

    this.setState(prevState => ({
      allOrganizationalUnitsSelected: false,
      stepThreeHasChanged: true,
    }))
  }

  handleOnChangePdf = data => {
    this.setState(prevState => ({
      pdfSelected: data,
      stepOneHasChanged: true,
    }))
  }

  handleOnDeletePdf = async () => {
    const { addSnackbar } = this.props
    const callbackSuccess = () => {
      addSnackbar('Pdf eliminado con éxito', SnackbarType.Success)
    }
    const callbackError = error => addSnackbar(error.message, SnackbarType.Warning)

    try {
      await api.CareerPlan.deleteClientPdf(this.state.careerPlanProps.id)
      getResource('admin-career-plan-edit', 'career-plans', this.state.careerPlanProps.id)
      callbackSuccess()
    } catch (error) {
      callbackError()
    }
  }

  render() {
    const { isEdit, classes, careerPlanMetadata } = this.props

    if (
      this.state.loading ||
      (isEdit &&
        (!this.state.careerPlanProps.id ||
          (this.state.activeStep === secondStep &&
            isEmpty(this.props.careerPlanMetadata.courses)) ||
          !this.state.coursesSelected ||
          (this.state.activeStep === lastStep &&
            (isEmpty(this.props.careerPlanMetadata.organizationalUnits) ||
              isEmpty(this.props.careerPlanMetadata.jobPositions)))))
    ) {
      return <Loading dark={true} />
    }

    Stepper.propTypes = {
      classes: PropTypes.object,
    }

    return (
      <Grid container justify="center">
        <div className={classes.header}>
          {isEdit ? (
            <Typography className={classes.titleHeader}>
              EDITAR RUTA DE APRENDIZAJE "{this.state.careerPlanProps.name}"
            </Typography>
          ) : (
            <Typography className={classes.titleHeader}>
              NUEVA RUTA DE APRENDIZAJE "{this.state.careerPlanProps.name}"
            </Typography>
          )}
        </div>
        <Grid container className={classes.root} justify="center">
          <Grid item xs={10}>
            <Stepper activeStep={this.state.activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} className={classes.step}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>

            {this.state.activeStep === firstStep && (
              <StepOne
                handleBack={this.handleBackStep}
                handleNext={this.handleNextStep}
                careerPlanProps={this.state.careerPlanProps}
                channels={careerPlanMetadata.channels}
                channelsSelected={this.state.channelsSelected}
                handleChangeCareerPlanName={this.handleChangeCareerPlanName}
                handleChangeCareerPlanChannels={this.handleChangeCareerPlanChannels}
                handleChangeCareerPlanMap={this.handleChangeCareerPlanMap}
                handleChangeCareerPlanType={this.handleChangeCareerPlanType}
                handleOnChangePdf={this.handleOnChangePdf}
                handleOnDeletePdf={this.handleOnDeletePdf}
                pdfSelected={this.state.pdfSelected}
                isEdit={isEdit}
                disableNextButton={this.state.disableFirstNextButton}
              />
            )}
            {this.state.activeStep === secondStep && (
              <StepTwo
                scales={careerPlanMetadata.scales}
                courses={careerPlanMetadata.courses}
                coursesSelected={this.state.coursesSelected}
                handleChangeCareerPlanCourses={this.handleChangeCareerPlanCourses}
                handleChangeCareerPlanCourseMandatory={this.handleChangeCareerPlanCourseMandatory}
                handleChangeCareerPlanCourseScale={this.handleChangeCareerPlanCourseScale}
                handleBack={this.handleBackStep}
                handleNext={this.handleNextStep}
              />
            )}
            {this.state.activeStep === lastStep && (
              <StepThree
                careerPlanProps={this.state.careerPlanProps}
                jobPositions={careerPlanMetadata.jobPositions}
                jobPositionsSelected={this.state.jobPositionsSelected}
                handleChangeJobPosition={this.handleChangeJobPosition}
                organizationalUnits={careerPlanMetadata.organizationalUnits}
                organizationalUnitsSelected={this.state.organizationalUnitsSelected}
                handleChangeOrganizationalUnit={this.handleChangeOrganizationalUnit}
                handleSelectAllOu={this.handleSelectAllOu}
                allOrganizationalUnitsSelected={this.state.allOrganizationalUnitsSelected}
                handleBack={this.handleBackStep}
                isPublished={this.state.isPublished}
                handlePublish={this.handlePublish}
                handleSaveAssignations={this.handleSaveAssignations}
                stepThreeHasChanged={this.state.stepThreeHasChanged}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource
  return {
    careerPlanMetadata: state.careerPlanAdminReducer,
    ...states[ownProps.keyName],
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadChannelsForCareerPlan,
      loadCoursesForCareerPlan,
      loadScalesForCareerPlan,
      loadJobPositionsForCareerPlan,
      loadOrganizationUnitsForCareerPlan,
      goToAdminCareerPlan,
      getResource,
      createResource,
      updateResource,
      addSnackbar,
      goTo,
      appLoadToken,
    },
    dispatch,
  )
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminCareerPlansStepperContainer)),
)
