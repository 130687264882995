import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import EventListenerWrapper from './EventListenerWrapper'

const styles = theme => ({
  root: {
    height: 306,
    display: 'block',
    position: 'relative',
    '&_content': {
      zIndex: 10,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      float: 'left',
    },
  },
  contentPadding: {
    padding: 8,
  },
  miniTitle: {
    color: '#101010',
    whiteSpace: 'pre-line',
    fontSize: 16,
    bottom: -32,
    left: 90,
    paddingLeft: 5,
    paddingRight: 5,
    position: 'absolute',
    maxWidth: 140,
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    backgroundColor: 'rgba(255, 204, 56, 0.9)',
  },
  number: {
    width: 132,
    height: 306.5,
    fontFamily: 'Open Sans',
    fontSize: 305,
    marginTop: -60,
    fontWeight: 800,
    fontStretch: 'normal',
    fontStyle: '800',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#e4e4e4',
    position: 'absolute',
  },
  bigTitle: {
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 4,
  },
  bigSubtitle: {
    color: '#ffffff',
    fontSize: 12,
  },
  npsScore: {
    color: '#ffffff',
    fontSize: 12,
    marginBottom: 4,
  },
  image: {
    height: '100%',
  },
  progress: {
    margin: 'auto',
  },
  rating: {},
  star: {
    fontSize: 14,
  },
  mandatory: {
    position: 'absolute',
    top: 8,
    right: 8,
    fontWeight: 700,
    color: '#fff',
    fontSize: 8,
    borderRadius: 2,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
    backgroundImage: 'none !important',
  },
  info: {
    width: '100%',
    float: 'left',
  },
  categories: {
    width: '100%',
    float: 'left',
  },
  category: {
    color: '#fff',
    fontSize: 10,
    float: 'left',
    marginRight: 4,
    marginBottom: 4,
  },
  tags: {
    marginTop: 2,
  },
  imageWrapper: {
    width: '60%',
    height: '80%',
    marginLeft: 100,
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  titleProgressContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progressNumber: {
    display: 'block',
    color: '#fff',
  },
})

class CourseItemTopTen extends Component {
  getAriaLabel = (item, progress) => {
    let description = `Curso Online "${item.title}". `
   /*
    let status = 'No Iniciado'
    if (progress > 0 && progress < 100) {
      status = 'Iniciado'
    } else if (progress === 100) {
      status = 'Completado'
    }
    description += `Estado: ${status}`
    */
    return description
  }

  render() {
    const { classes, item } = this.props
    return (
        <Box className={classes.root} >
          <div className={classes.contentPadding}>
            <Typography className={classes.number}>{item.sort}</Typography>
          </div>
          <Box
            borderRadius="50%"
            className={classes.imageWrapper}
            style={{ backgroundImage: `url('${item.course.image}')` }}
          >
            <Typography className={classes.miniTitle}>
              <span>{item.course.title}</span>
            </Typography>
          </Box>
        </Box>
    )
  }
}

export default withStyles(styles)(CourseItemTopTen)
