export const admin_app_theme = () => ({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  mediaQueries: {
    xs: 320,
    sm: 375,
    md: 425,
    lg: 768,
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: '#3abf91',
          '& + $bar': {
            opacity: 0.5,
            backgroundColor: '#3abf91',
          },
        },
      },
      bar: {
        backgroundColor: '#bbb',
      },
      switchBase: {
        color: '#bbb',
      },
    },
  },
});
