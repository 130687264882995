import {
  SEARCH_COURSES_READ_START,
  SEARCH_COURSES_READ_FAILED,
  SEARCH_COURSES_READ_END,
  SEARCH_COURSES_CLEAR,
} from '../../actions';

let initialState = {
  query: '',
  error: null,
  loading: false,
  items: [],
  timestamp: 0,
};

export default (state = initialState, action) => {
  const { timestamp, query } = action.payload || { timestamp: 0, query: '' };
  switch (action.type) {
    case SEARCH_COURSES_CLEAR: {
      return { ...initialState };
    }
    case SEARCH_COURSES_READ_END:
      const { items } = action.payload;
      if (state.timestamp <= timestamp) {
        return {
          query: query,
          loading: false,
          items: items,
          error: null,
          timestamp: timestamp,
        };
      } else {
        return state;
      }
    case SEARCH_COURSES_READ_START:
      if (state.timestamp <= timestamp) {
        return {
          query: query,
          items: [],
          error: null,
          loading: true,
          timestamp: timestamp,
        };
      } else {
        return state;
      }
    case SEARCH_COURSES_READ_FAILED:
      const { message } = action.payload;
      if (state.timestamp <= timestamp) {
        return {
          query: query,
          items: [],
          error: message,
          loading: false,
          timestamp: timestamp,
        };
      } else {
        return state;
      }
    default:
      return { ...state, error: null };
  }
};
