import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  CourseProgress,
  rateRange
} from '../../../config/constants'
import { isAdmin } from '../../../utils/AdminHelper'

const KEY_NAME = 'reports/nps_external_courses_comments'

class AdminReportsNpsExternalCoursesCommentsContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  getColumns = () => {
		const { me } = this.props
    let columns = [
      { title: 'Legajo', source: 'record' },
      { title: 'Nombre y Apellido', source: 'name' },
      { title: 'Área cliente interno', source: 'internal_area' },
      { title: 'Puesto', source: 'job_position' },
      { title: 'Id taller', source: 'external_course_id' },
      { title: 'Nombre taller', source: 'external_course_name' },
      { title: 'Id comisión', source: 'commission_id' },
      { title: 'Fecha comisión', source: 'date_commission' },
      { title: 'Valoración', source: 'rating' },
      { title: 'Lo mejor', source: 'good_review' },
      { title: 'A mejorar', source: 'bad_review' },
      { title: 'Fecha valoración', source: 'date' },
      { title: 'Facilitadores', source: 'facilitator' },
    ]
		
		if (!isAdmin(me.user))
		{
			columns = columns.filter(function (obj) {
        return (
          obj.title !== 'Legajo' &&
          obj.title !== 'Nombre y Apellido' &&
          obj.title !== 'Área cliente interno' &&
          obj.title !== 'Puesto'
        )
      })
		}

    return columns
  }

  getFilterConfig = () => {
		const { me } = this.props

    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.PublicExternalCourses,
        name: 'externalCourse.id',
        placeholder: 'Taller',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'name',
      },
      {
        title: 'Valoración',
        name: 'userCommissionRating.rating',
        type: EditInputType.Select,
        placeholder: 'Valoración',
        options: rateRange.map(r => {
          return { id: '' + r.id, description: r.description }
        }),
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.JobPosition,
        name: 'user.jobPosition',
        placeholder: 'Puesto',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'description',
      },
      {
        type: EditInputType.Text,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.internalArea',
        placeholder: 'Área Cliente Interno',
        multiple: true,
        format: FiltersFormat.Like,
        displayAttribute: 'internal_area',
      },
      {
        type: EditInputType.Date,
        name: 'commissionDates.startDateFrom',
        placeholder: 'Fecha valoración Desde',
        format: FiltersFormat.MayorIgual,
        queryName: 'commissionDates.startDate',
      },
      {
        type: EditInputType.Date,
        name: 'commissionDates.startDateTo',
        placeholder: 'Fecha Hasta',
        format: FiltersFormat.MenorIgual,
        queryName: 'commissionDates.startDate',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Facilitators,
        name: 'facilitator.id',
        placeholder: 'Facilitador',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'description',
      },
    ]

		if (!isAdmin(me.user)) {
      response = response.filter(function (obj) {
        return (
          obj.name !== 'user.id' &&
          obj.name !== 'user.jobPosition' &&
          obj.name !== 'organizationalUnit.internalArea'
        )
      })
    }

    return response
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE NPS COMENTARIOS - COMISIONES'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsNpsExternalCoursesCommentsContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer
  if (paginatorState && paginatorState.user_courses && paginatorState.user_courses.items) {
    paginatorState.user_courses.items.map(course =>
      course.completed_at && course.completed_at.length
        ? (course.completed = CourseProgress.true)
        : (course.completed = CourseProgress.false),
    )
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsNpsExternalCoursesCommentsContainer);
