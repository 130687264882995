import React from 'react'
import { ActivitiesTypesList } from '../config/constants'

export const activityTypeText = type => {
  const result = ActivitiesTypesList.find(at => at.type === type)
  if (result) {
    return result.title
  }
  return null
}

export const activityTypeIcon = (type, className = null, ...extraProps) => {
  const result = ActivitiesTypesList.find(at => at.type === type)
  if (result) {
    const Icon = result.icon
    return <Icon className={className} {...extraProps} />
  }
  return null
}

export const optionsSetter = ({ options, optionId }) => {
  if (options.find(option => option.id === optionId)) {
    return options.filter(option => option.id !== optionId)
  }
  return [...options, { id: optionId }]
}

export const radioOptionsSetter = ({ optionId }) => {
  return [{ id: optionId }]
}

export const blankFieldSetter = option => {
  return [option]
}

export const classOptionSetter = (done, correct_answer) => {
  if (done) {
    return correct_answer ? 'correct' : 'incorrect'
  }
  return 'selectable'
}

export const classEditableSpanFieldSetter = (done, isCorrect, defaultClass = 'fillable') => {
  if (done) {
    return isCorrect ? 'correct' : 'incorrect'
  }
  return defaultClass
}
