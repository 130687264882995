import { encode } from 'querystring'
import request from './request'
import config from '../config'

import { isNull } from 'lodash'
import moment from 'moment'

let token = null
// eslint-disable-next-line no-unused-vars
let reset_pasword = null
let dispatch = null

const requests = {
  del: url =>
    request(
      {
        method: 'DELETE',
        url: `${config.API_ROOT}${url}`,
        token,
      },
      dispatch,
    ),
  get: url =>
    request(
      {
        method: 'GET',
        url: `${config.API_ROOT}${url}`,
        token,
      },
      dispatch,
    ),
  put: (url, body) =>
    request(
      {
        method: 'PUT',
        url: `${config.API_ROOT}${url}`,
        data: body,
        token,
      },
      dispatch,
    ),
  post: (url, body, withCredentials = false) =>
    request(
      {
        method: 'POST',
        url: `${config.API_ROOT}${url}`,
        data: body,
        token,
        withCredentials,
      },
      dispatch,
    ),
  file: url =>
    request(
      {
        method: 'GET',
        url: `${config.API_ROOT}${url}`,
        token,
        download: true,
        data: { download: true },
      },
      dispatch,
    ),
  multipart: (url, data, withCredentials = false) => {
    const form = new FormData()
    Object.keys(data).forEach(key => form.append(key, data[key]))
    return request(
      {
        method: 'POST',
        url: `${config.API_ROOT}${url}`,
        headers: {
          'content-type': 'multipart/form-data',
        },
        token,
        withCredentials,
        data: form,
      },
      dispatch,
    )
  },
}

const Wordpress = {
  login: () => requests.post('/users/authWP', {}, true),
  createContent: data =>
    request(
      {
        method: 'POST',
        url: `${config.WP_ROOT}/wp-admin/admin-ajax.php?action=create_new_h5p`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: encode(data),
        token,
        withCredentials: true,
      },
      dispatch,
    ),
  createScormContent: () =>
    request(
      {
        method: 'POST',
        url: `${config.WP_ROOT}/wp-admin/admin-ajax.php?action=create_new_scorm`,
        withCredentials: true,
      },
      dispatch,
    ),
}

const Auth = {
  session: data => requests.post('/users/session2', data),
  sessionOB: data => requests.post('/users/session3', data),
  changuePass: data => requests.post('/users/resetPasword', data),
  sessionOBQR: data => requests.post('/users/login/2af', data),
  getUserToken: oneTimeToken => requests.post('/users/login', { oneTimeToken }),
  getUserTokenOB: oneTimeToken => requests.post('/users/loginOpenBank', { oneTimeToken }),
  getLoggedUser: () => requests.get('/users/me'),
  logout: () => (token = null),
}

const Admin = {
  // TODO: agregar /admin a todos los paths
  getResources: (resourceType, page = 1, size = 20, query = '') => {
    const pagination = `pagination[page]=${page}&pagination[size]=${size}`
    return requests.get(
      `/admin/${resourceType}?${encodeURI(pagination + (query ? `&${query}` : ''))}`,
    )
  },

  getResource: (resourceType, id) => requests.get(`/admin/${resourceType}/${id}`),
  createResource: (resourceType, data) => requests.post(`/admin/${resourceType}`, data),
  updateResource: (resourceType, id, data) =>
    requests.put(`/admin/${resourceType}${id !== null ? `/${id}` : ''}`, data),
  importUsersFromCSV: data => requests.post('/admin/users/selected_csv', data),
  deleteResource: (resourceType, id) => requests.del(`/admin/${resourceType}/${id}`),
  downloadReport: (filters, type = 'user_courses') =>
    requests.get(`/admin/${type}/xls${filters ? `?${filters}` : ''}`),
  processReportOffline: (filters, type = 'reports/user_courses') =>
    requests.get(
      `/admin/reports/process/${type.replace(/reports\//g, '')}${filters ? `?${filters}` : ''}`,
    ),

  getCoursePreview: id => requests.get(`/admin/courses/${id}/preview`),
  getCoursePreviewBlocks: (id, page = 1, size = 1) =>
    requests.get(
      `/admin/courses/${id}/blocks/preview?pagination[size]=${size}&pagination[page]=${page}`,
    ),
  getTopTen: () => requests.get('/admin/courses-top'),
  createFileActivity: (resourceId, data) => requests.multipart(`/admin/${resourceId}`, data),
  updateFileActivity: (resourceId, id, data) =>
    requests.multipart(`/admin/${resourceId}${id !== null ? `/${id}` : ''}`, data),
  createSelectActivityBlock: (resourceId, data) => requests.post(`/admin/${resourceId}`, data),
  downloadFile: fileId => requests.file(`/admin/jobs/${fileId}/file`),
  getFiltersTemplate: model => requests.get(`/admin/filters-template/${model}`),
  createFiltersTemplate: data => requests.post('/admin/filters-template', data),
  deleteTemplate: id => requests.del(`/admin/filters-template/${id}`),
  downloadCommissionQr: commissionId => requests.file(`/admin/commissions/${commissionId}/qr`),
  unpublishResource: (resourceType, id) => requests.put(`/admin/${resourceType}/${id}/unpublish`),
  publishResource: (resourceType, id) => requests.put(`/admin/${resourceType}/${id}/publish`),
  unblockResource: (resourceType, id) => requests.put(`/admin/${resourceType}/unlock/${id}`),
  cleanResource: (resourceType, id) =>
    requests.put(`/admin/${resourceType}/passwordLaundering/${id}`),
  downResource: (resourceType, id) => requests.del(`/admin/${resourceType}/${id}/logical_erase`),
  activeResource: (resourceType, id) => requests.put(`/admin/${resourceType}/active/${id}`),
  createQuizTriviaTemplate: (activityId, data) =>
    requests.post(`/admin/activities/${activityId}/quiz`, data),
  updateQuizTriviaTemplate: (activityId, data) =>
    requests.put(`/admin/activities/${activityId}/quiz`, data),
  deleteQuizTriviaTemplate: activityId => requests.del(`/admin/activities/${activityId}/quiz`),
  generateEvents: scormId => requests.get(`/admin/wpdata_exchange/${scormId}`),
  getEvents: scormId => requests.get(`/admin/wpdata_exchange/list/${scormId}`),
  updateEvents: scormId => requests.get(`/admin/wpdata_exchange/update/${scormId}`),
  editEvent: (eventId, payload) => requests.put(`/admin/wpdata_exchange/${eventId}`, payload),
  getUserActivities: (userFile, courseId) =>
    requests.get(`/admin/user_activity_completeness/user/${userFile}/course/${courseId}`),
  submitActivityCompleteness: payload =>
    requests.post('/admin/user_activity_completeness', payload),
  updateActivityCompleteness: payload => requests.put('/admin/user_activity_completeness', payload),
  getActivityScormEvents: (userId, scormId) =>
    requests.get(`/admin/user_activity_completeness/list_event/user/${userId}/scorm/${scormId}`),
  updateCourseCompleteness: payload => requests.put('/admin/course_activity_completeness', payload),
  answerQuizTrivia: (activityId, data) => requests.post(`/activities/${activityId}/answered`, data),
  getUserCoursesQuiz: filters =>
    requests.get(`/admin/reports/user_courses_quiz${filters ? `?${filters}` : ''}`),
  getCourseQuizActivities: courseId =>
    requests.get(`/admin/reports/user_course_quiz_activities/${courseId}`),
}

const Filter = {
  tags: query =>
    requests.get(
      `/admin/tags?${encodeURI(
        `filters[tag.name][LIKE]=${query}&pagination[size]=50&pagination[page]=1`,
      )}`,
    ),
  commissionPlaces: query =>
    requests.get(
      `/admin/commissions/places?${encodeURI(
        `filters[site.nameType][LIKE]=${query}&pagination[size]=50&pagination[page]=1`,
      )}`,
    ),
  categories: (query, customFilter = '') =>
    requests.get(
      `/admin/categories?${encodeURI(
        `filters[category.name][LIKE]=${query}&${customFilter}pagination[size]=50&pagination[page]=1`,
      )}`,
    ),
  users: query => {
    const filters = ['username', 'firstName', 'lastName', 'email']
      .map(field => `filters[OR][user.${field}][LIKE]=${query}`)
      .join('&')
    const uri = encodeURI(`${filters}&pagination[size]=50&pagination[page]=1`)
    return requests.get(`/admin/users?${uri}`)
  },
  providers: query => {
    const filters = ['name', 'description']
      .map(field => `filters[OR][provider.${field}][LIKE]=${query}`)
      .join('&')
    const uri = encodeURI(`${filters}&pagination[size]=50&pagination[page]=1`)
    return requests.get(`/admin/providers?${uri}`)
  },
  externalReferences: (query, field) => {
    const uri = encodeURI(`filters[${field}][LIKE]=${query}&pagination[size]=50&pagination[page]=1`)
    return requests.get(`/admin/external_references/combo?${uri}`)
  },

  usersToShareAnterio: query => {
    const filters = ['username', 'firstName', 'lastName', 'email']
      .map(field => `filters[OR][user.${field}][LIKE]=${query}`)
      .join('&')
    const uri = encodeURI(`${filters}&pagination[size]=50&pagination[page]=1`)
    return requests.get(`/shared_courses/users?${uri}`)
  },

  usersToShare: query =>
    requests.get(
      `/shared_courses/users?${encodeURI(
        `search=${query}%&pagination[size]=50&pagination[page]=1`,
      )}`,
    ),

  courses: query =>
    requests.get(
      `/admin/courses?${encodeURI(
        `filters[course.title][LIKE]=${query}&pagination[size]=50&pagination[page]=1`,
      )}`,
    ),

  facilitators: query =>
    requests.get(
      `/admin/external_courses/list/facilitators?${encodeURI(
        `filters[facilitators.description][LIKE]=${query}&pagination[size]=50&pagination[page]=1`,
      )}`,
    ),

  organizationalUnit: query => {
    const filters = ['code', 'internalArea', 'description']
      .map(field => `filters[OR][organizationalUnit.${field}][LIKE]=${query}`)
      .join('&')
    const uri = encodeURI(`${filters}&pagination[size]=50&pagination[page]=1`)
    return requests.get(`/admin/organizational-units?${uri}`)
  },

  jobPosition: query =>
    requests.get(
      `/admin/job-positions?${encodeURI(
        `filters[jobPosition.description][LIKE]=${query}&pagination[size]=50&pagination[page]=1`,
      )}`,
    ),

  places: filters => {
    const { institutionId, addressId, classroomId } = filters

    const urlFiltersPlace = [
      institutionId && `filters[institution.id]=${institutionId}`,
      addressId && `filters[address.id]=${addressId}`,
      classroomId && `filters[classroom.id]=${classroomId}`,
    ]
      .filter(filterPlace => filterPlace ?? '')
      .join('&')

    return requests.get(`/admin/commissions/places?${urlFiltersPlace}`)
  },
  institutions: () =>
    requests.get('/admin/commissions/institutions?ordering%5Binstitution.name%5D=ASC'),
  institutionsRemote: () => requests.get('/admin/commissions/places_remote'),
  platforms: () => requests.get('/admin/commissions/platforms'),
}

const Create = {
  tags: name => requests.post('/admin/tags', { name }),
  categories: name => requests.post('/admin/categories', { name }),
}

const Categories = {
  all: () => requests.get('/categories'),
  get: id => requests.get(`/categories/${id}`),
}

const Slides = {
  update: (id, data) => requests.put('/admin/slides/' + id, data),
  all: () => requests.get('/user/slides'),
}

const Courses = {
  get: id => requests.get(`/courses/${id}`),
  allPublished: () => requests.get('/mixed/published'),
  all: () => requests.get('/courses'),
  getTopTen: () => requests.get('/courses-top'),
  getAllRequired: () => requests.get('/courses?filters[lot.category]=1&filters[course.required]=1'),
  getAllObjectiveDplusI: () => requests.get('/courses?filters[lot.category]=4'),
  getVariablePrice: () => requests.get('/courses?filters[lot.category]=3'),
  getBlocks: (id, page = 1, size = 1) =>
    requests.get(`/courses/${id}/blocks?pagination[size]=${size}&pagination[page]=${page}`),
  getBlock: (courseId, blockId) => requests.get(`/courses/${courseId}/blocks/${blockId}`),
  getReviews: (id, page = 1, size = 1) =>
    requests.get(`/courses/${id}/user_ratings?pagination[size]=${size}&pagination[page]=${page}`),
  getAllForFeaturedCategories: () =>
    requests.get(
      '/mixed/courses-by-category?filters[category.featured]=1&ordering[category.name]=asc',
    ),
  shareCourse: data => requests.post('/share_course', data),
  removeShareCourse: courseId => requests.get(`/shared_courses/${courseId}/inactive`),
  searchByText: query => requests.get(`/mixed/search?q=${encodeURI(query || '')}`),
  searchCoursesByCategory(id) {
    return requests.get(`/admin/mixed/category/${id}`)
  },
  updateOrderCourses(categoryId, data) {
    return requests.put(`/admin/mixed/category/${categoryId}`, { data })
  },
  exists: query => requests.post(`/admin/courses/exists?filters=${query}`),
}

const Playlists = {
  top: () => requests.get('/playlists/top'),
  getById: id => requests.get(`/playlists/${id}`),
  getByUser: id => requests.get(`/playlists/user/${id}`),
  followUnfollow: (id, follow) =>
    requests.post(`/playlists/${id}/${follow ? 'follow' : 'unfollow'}`),
  new: name => requests.post('/playlists', { name }),
  update: (id, name) => requests.put(`/playlists/${id}`, { name }),
  unlink: (id, course) => requests.post('/playlists/courses/unlink', { playlist: id, course }),
}

const My = {
  Playlists: {
    get: () => requests.get('/users/me/playlists'),
    following: () => requests.get('/users/me/followed/playlists'),
    delete: id => requests.del(`/users/me/playlists/${id}`),
  },
  Courses: {
    inProgress: () =>
      requests.get(
        '/users/me/courses?filters[userCourse.completed]=0&filters[userCourse.loadByProcess]=0',
      ),
    completed: () => requests.get('/mixed/me/courses-completed'),
    hidden: () =>
      requests.get('/users/me/courses?filters[course.hidden]=1&filters[userCourse.completed]=0'),
    rate: (id, data) => requests.post(`/users/me/course_ratings/${id}`, data),
    shared: () => {
      return requests.get('/users/me/shared_courses')
    },
    updatePlaylists: (courseId, playlistsIds) =>
      requests.put('/playlists/courses', { course: courseId, playlists: playlistsIds }),
    downloadCertificate: courseId =>
      requests.file(`/users/me/user_commission_certified/${courseId}`),
    downloadCertificateCourse: courseId =>
      requests.file(`/users/me/user_course_certified/${courseId}`),
    sendScormData: data => requests.post('/users/me/user_scorm_data', data),
    selectedUsers: data => requests.post('/admin/users/selected', { users: data }),
  },
  Profile: {
    get: () => {
      return requests.get('/users/me/profile')
    },
  },
  Activities: {
    setDone: (courseId, id) => requests.post(`/users/me/courses/${courseId}/activities/${id}`),
  },
  CareerPlan: {
    get: () => requests.get('/users/me/career-plan'),
    history: () => requests.get('/users/me/career-plans/history'),
  },
  ExternalCourses: {
    pending: () =>
      requests.get(
        `/me/external-courses?filters[userCommission.status]=1&filters[d.startDate][DESDE]=${moment().format(
          'YYYY-MM-DD HH:mm',
        )}&ordering[externalCourse.name]=asc`,
      ),
    rate: (commission_id, data) =>
      requests.post(`/users/me/user_commission_ratings/${commission_id}`, data),
  },
}

const ActivitiesSelect = {
  all: blockId => requests.get(`/admin/activities/block/${blockId}/with-courses`),
}

const CareerPlan = {
  all: () => requests.get('/admin/career-plans'),
  allCareerPlan: () => requests.get('/career_plans/me'),
  allCareerPlanByChannel: id => requests.get(`/career_plans/${id}/me`),
  getDetail: (id, filters) =>
    requests.get(`/users/me/career_plans/${filters ? `${id}?${filters}` : `${id}`}`),
  //Consultar si lo dejamos aca o en *MY*
  get: filters => requests.get(`/users/me/career-plan${filters ? `?${filters}` : ''}`),
  history: filters => requests.get(`/users/me/career-plans/history${filters ? `?${filters}` : ''}`),
  getChannelsToExplore() {
    return requests.get('/channels')
  },
  getChannels: () => requests.get('/admin/channels'),
  getScales: () => requests.get('/admin/scales'),
  getJobPositions() {
    return requests.get('/admin/job-positions')
  },
  getOrganizationalUnits() {
    return requests.get('/admin/organizational-units')
  },
  /**
   *
   * @param {*} payload {"name": “Mi primer plan de carrera”,"map?": null,"channels": [1,2]}
   */
  createOrupdateCareerPlan(isEdit, payload, careerPlanId) {
    if (isEdit || careerPlanId) {
      const urlEdit = `/admin/career-plans/${careerPlanId}`
      return requests.put(urlEdit, payload)
    } else {
      const urlCreate = '/admin/career-plans'
      return requests.post(urlCreate, payload)
    }
  },
  updateCareerPlan(payload, careerPlanId) {
    const urlEdit = `/admin/career-plans/${careerPlanId}`
    if (!isNull(payload.map)) {
      return requests.multipart(urlEdit, payload)
    }
    return requests.put(urlEdit, payload)
  },
  mergeChannelCreateNewCareerPlan(id, payload) {
    return requests.post(`/admin/career-plans/${id}/channel`, payload)
  },
  asociateChannelToCareerPlan(id) {
    return requests.post(`/admin/career-plans/${id}/channel`)
  },
  getAssociateCourseToCareerPlan() {
    return requests.get('/admin/courses?stepper=1')
  },
  associateCourseToCareerPlan(id, payload) {
    return requests.post(`/admin/career-plans/${id}/course`, payload)
  },
  associateJobPositionAndCostCenterToCareerPlan(id) {
    return requests.post(`/admin/career-plans/${id}/course`)
  },
  setCareerPlanAssignations(id, payload) {
    return requests.post(`/admin/career-plans/${id}/assignation`, payload)
  },
  publishCareerPlans(id) {
    return requests.put(`/admin/career-plans/${id}/publish`)
  },
  // request to edit career plans
  retrieveChannelSelected(id) {
    return requests.get(`/admin/career-plans/${id}/channel`)
  },
  retrieveAssociatedCoursed(id) {
    return requests.get(`/admin/career-plans/${id}/course`)
  },
  deleteClientPdf(id) {
    return requests.del(`/admin/career-plans/${id}/map`)
  },
}

const Lot = {
  all: () => requests.get('/admin/lots'),
  getAssociateCourseToLot() {
    return requests.get('/admin/courses?stepper=1')
  },
  getUsersForLot(filters) {
    return requests.get(`/admin/users${filters ? `?${filters}` : ''}`)
  },
  getJobPositions() {
    return requests.get('/admin/job-positions')
  },
  getCompany() {
    return requests.get('/admin/companies')
  },
  getOrganizationalUnits() {
    return requests.get('/admin/organizational-units')
  },
  setLotAssignations(id, payload) {
    return requests.post(`/admin/lots/${id}/assignation`, payload)
  },

  setLotCompany(id, payload) {
    return requests.post(`/admin/lots/${id}/company`, payload)
  },

  createOrUpdateLot(isEdit, payload, lotId) {
    if (isEdit || lotId) {
      const urlEdit = `/admin/lots/${lotId}`
      return requests.put(urlEdit, payload)
    } else {
      const urlCreate = '/admin/lots'
      return requests.post(urlCreate, payload)
    }
  },
  associateCourseToLot(id, payload) {
    return requests.post(`/admin/lots/${id}/courses`, payload)
  },
  associateUserToLot(id, payload) {
    return requests.post(`/admin/lots/${id}/users`, payload)
  },
}

const ExternalCourses = {
  calendar: (year, month) =>
    requests.get('/commissions/calendar?' + encodeURI(`year=${year}&month=${month}`)),
  get: id => requests.get(`/external-courses/${id}`),
  nextDays: (from, to) =>
    requests.get(
      '/external-courses?' +
        encodeURI(
          `filters[d.startDate][DESDE]=${from}&filters[d.startDate][HASTA]=${to}&filters[commission.registrationDeadline][DESDE]=${from}&ordering[externalCourse.name]=asc`,
        ),
    ),
  unsubscribeCommission: commissionId => requests.post(`/commission/${commissionId}/unsubscribe`),
  subscribeCommission: commissionId => requests.post(`/commission/${commissionId}/subscribe`),
  subscribeCommissionToWaitingList: commissionId =>
    requests.post(`/commission/${commissionId}/waiting-list`),
  search: query =>
    requests.get(
      `/admin/external-courses?${encodeURI(
        `filters[externalCourse.name][LIKE]=%${query}%&pagination[size]=50&pagination[page]=1&ordering[externalCourse.name]=asc`,
      )}`,
    ),
  tottleCommissionDateStatus: commissionDateId =>
    requests.post(`/commission/${commissionDateId}/subscribe`),
}

const AdminExternalCourse = {
  getClassesByCommissionId: id => requests.get(`/admin/commission/${id}/commission_date`),
  getCommission: id => requests.get(`/admin/commissions/${id}`),
  changeVisibility: id => requests.put(`/admin/commissions/${id}/visible`),
  cancel: id => requests.put(`/admin/commissions/${id}/cancel`),
  getAll: () => requests.get('/admin/external_courses?stepper=1'),
  create: payload => Admin.createResource('external_course', payload),
  edit: (id, payload) => Admin.updateResource('external_course', id, payload),
  getAssociateCourse: () => requests.get('/admin/courses?stepper=1'),
  setRequirements: (id, payload) =>
    requests.post(`/admin/external_course/${id}/requirements`, payload),
  publish: id => requests.put(`/admin/external_course/${id}/publish`),
  exists: query => requests.post(`/admin/external_courses/exists?filters=${query}`),
}

const AdminUserCommission = {
  getByCommission: commissionId => requests.get(`/user/commissions/${commissionId}/me`),
  setPresent(commissionDateId) {
    return requests.put(`/user-commission-date/${commissionDateId}/present`)
  },
}

const AdminScormConfig = {
  getByScormId: scormId => requests.get(`/admin/scrom_completion_config/${scormId}`),
  getEventsByScormId: scormId => requests.get(`/admin/scrom_completion_config/events/${scormId}`),
  update: (id, data) => requests.put(`/admin/scrom_completion_config/${id}`, data),
}

const QuizTriviaData = {
  questionsTypes: () => requests.get('/admin/questions_type/list'),
  userQuestionsTypes: () => requests.get('/questions_type/list'),
}

const api = {
  Wordpress,
  My,
  Courses,
  Categories,
  ActivitiesSelect,
  Create,
  Filter,
  Admin,
  Auth,
  CareerPlan,
  Lot,
  AdminExternalCourse,
  AdminUserCommission,
  AdminScormConfig,
  ExternalCourses,
  Slides,
  Playlists,
  setToken: _token => {
    token = _token
  },
  setReset_pasword: _reset_pasword => {
    reset_pasword = _reset_pasword
  },
  hasToken: () => Boolean(token),
  withDispatch: d => {
    dispatch = d
    return api
  },
  QuizTriviaData,
}

export default api
