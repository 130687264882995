import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
} from '@material-ui/core'
import { isEmpty } from 'lodash'

const styles = theme => ({
  root: {
    height: '300px',
    width: '100%',
    margin: '20px 0',
  },
  textField: {
    width: '100%',
  },
  selects: {
    marginTop: '15px',
  },
  inputFile: {
    marginTop: '30px',
    display: 'none',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  uploadIcon: {
    marginLeft: 10,
  },
  iconSmall: {
    fontSize: 20,
  },
  savedIcon: {
    marginLeft: 10,
  },
  wrapperButtonPdf: {
    marginTop: 10,
  },
  buttonViewPdfSelected: {
    marginRight: 10,
  },
  wrapperIconValuePdf: {
    position: 'relative',
  },
  buttonDeletePdf: {
    position: 'absolute',
    right: 0,
    bottom: 12,
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const StepOne = ({
  classes,
  handleBack,
  handleNext,
  careerPlanProps,
  channels,
  channelsSelected,
  handleChangeCareerPlanName,
  handleChangeCareerPlanMap,
  handleChangeCareerPlanChannels,
  handleOnChangePdf,
  handleOnDeletePdf,
  pdfSelected,
  isEdit,
  disableNextButton,
}) => {
  const disableNextStepButton = () => {
    if (isEmpty(channelsSelected) || disableNextButton) return true
    return !!(isEdit && careerPlanProps.id === undefined)
  }

  return (
    <>
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12}>
          <TextField
            id="name"
            label="Nombre"
            type="text"
            fullWidth
            value={careerPlanProps.name}
            onChange={event => handleChangeCareerPlanName(event.target.value)}
            className={classes.textField}
            margin="normal"
            variant="standard"
          />

          <FormControl className={classes.selects} fullWidth>
            <InputLabel htmlFor="select-multiple-checkbox">Canal</InputLabel>
            <Select
              multiple
              value={channelsSelected}
              onChange={event => handleChangeCareerPlanChannels(event.target.value)}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={selected => {
                return selected.map(element => element.description).join(', ')
              }}
              MenuProps={MenuProps}
            >
              {channels.map(value => {
                return (
                  <MenuItem key={value.id} value={value}>
                    <Checkbox checked={channelsSelected.includes(value)} />
                    <ListItemText primary={value.description} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <TextField
            id="map"
            label="Mapa"
            type="text"
            fullWidth
            value={careerPlanProps.map}
            onChange={event => handleChangeCareerPlanMap(event.target.value)}
            className={classes.textField}
            margin="normal"
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid className={classes.wrapperBoton}>
        <Button onClick={handleBack} className={classes.button}>
          Atrás
        </Button>
        <Button
          disabled={disableNextStepButton()}
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          Continuar
        </Button>
      </Grid>
    </>
  )
}

StepOne.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(StepOne)
