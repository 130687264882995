import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex'
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  AdminItemViewType,
  CourseProgress,
  ActiveType,
  rateRange,
	SnackbarType
} from '../../../config/constants'

const KEY_NAME = 'reports/nps_comments'

class AdminReportsNpsCommentsContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  getColumns = (admin) => {
    let columns = [
      { title: 'Id curso', source: 'course_id' },
      { title: 'Nombre curso', source: 'course_name' },
      { title: 'Valoración', source: 'rating' },
      { title: 'Comentario', source: 'comments' },
      { title: 'Fecha', source: 'date' },
    ]

    let columnsAdmin = admin ?
       [
          { title: 'Legajo', source: 'record' },
          { title: 'Nombre y Apellido', source: 'name' },
          { title: 'Área cliente interno', source: 'internal_area' },
          { title: 'Puesto', source: 'job_position' },
          { title: 'Aceptó mostrar su nombre', source: 'show_name', type: AdminItemViewType.Boolean },

      ]
    : []

    return columnsAdmin.concat(columns)
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Courses,
        name: 'course.id',
        placeholder: 'Curso',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'title',
      },
      {
        title: 'Valoración',
        name: 'userCourseRating.rating',
        type: EditInputType.Select,
        placeholder: 'Valoración',
        options: rateRange.map(r => {
          return { id: '' + r.id, description: r.description }
        }),
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Select,
        name: 'userCourseRating.enablePublish',
        placeholder: 'Acepta mostrar nombre',
        format: FiltersFormat.Plain,
        options: Object.keys(ActiveType).map(k => ({
          id: k,
          description: ActiveType[k],
        })),
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.JobPosition,
        name: 'user.jobPosition',
        placeholder: 'Puesto',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'description',
      },
      {
        type: EditInputType.Text,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'organizationalUnit.internalArea',
        placeholder: 'Área Cliente Interno',
        multiple: true,
        format: FiltersFormat.Like,
        displayAttribute: 'internal_area',
      },
      {
        type: EditInputType.Date,
        name: 'userCourseRating.createdAtFrom',
        placeholder: 'Fecha Desde',
        format: FiltersFormat.MayorIgual,
        queryName: 'userCourseRating.createdAt',
      },
      {
        type: EditInputType.Date,
        name: 'userCourseRating.createdAtTo',
        placeholder: 'Fecha Hasta',
        format: FiltersFormat.MenorIgual,
        queryName: 'userCourseRating.createdAt',
      },
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE NPS COMENTARIOS'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns(this.props.me.user.is_admin)}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsNpsCommentsContainer.defaultProps = {
  type: KEY_NAME,
}

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer
  if (paginatorState && paginatorState.user_courses && paginatorState.user_courses.items) {
    paginatorState.user_courses.items.map(course =>
      course.completed_at && course.completed_at.length
        ? (course.completed = CourseProgress.true)
        : (course.completed = CourseProgress.false),
    )
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsNpsCommentsContainer);
