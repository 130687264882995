import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/navigator'
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource,
} from '../../../actions/admin'
import { ADMIN_EDIT, ADMIN_NEW, router, ADMIN_SLIDES } from '../../../config/routes'
import AdminResourceIndex from '../AdminResourceIndex'
import {
  SnackbarType,
  AdminItemViewType,
  EditInputType,
  FiltersFormat,
  SlidesTemplates,
} from '../../../config/constants'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmDialog from '../../../components/ui/ConfirmDialog'
import { addSnackbar } from '../../../actions/snackbar'
import SlidesDrawer from '../../../components/admin/slides/SlidesDrawer'
import VisibilityOnIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import api from '../../../api/api'

const KEY_NAME = 'admin-slides'

const DEFAULT_SLIDE_ORDER = [
  {
    name: 'slide.sort',
    order: 'ASC',
  },
]

class AdminSlidesContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
    drawer: false,
    slidesSelected: [],
    slideIdSelected: null,
  }

  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      {
        title: 'Plantilla',
        source: 'template',
        type: AdminItemViewType.Conditional,
        conditions: SlidesTemplates.map(t => ({
          condition: `${t.id}`,
          result: t.description,
        })),
      },
      { title: 'Título', source: 'title' },
      { title: 'Descripción', source: 'description' },
      { title: 'Orden', source: 'sort' },
      { title: 'Visible', source: 'visible', type: AdminItemViewType.Boolean },
    ]
  }

  handleTypeDrawer = type => {
    this.setState({ drawerActive: type })
  }

  onCreate = () => {
    this.props.goTo(ADMIN_SLIDES + ADMIN_NEW)
  }

  onEditItem = item => {
    this.props.goTo(router.getRoute(ADMIN_SLIDES + ADMIN_EDIT, { id: item.id }))
  }

  onDeleteItem = item => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.title,
    })
  }

  getActions = item => {
    return [
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
      {
        title: item.visible ? 'OCULTAR' : 'MOSTRAR',
        action: this.onChangeVisibility,
        icon: item.visible ? <VisibilityOffIcon /> : <VisibilityOnIcon />,
      },
    ]
  }

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'slide.title',
        placeholder: 'Título',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Boolean,
        name: 'slide.visible',
        placeholder: 'Visible',
        format: FiltersFormat.Plain,
      },
    ]
  }

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props
    const item = this.state.deleteItem
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Slide eliminado.', SnackbarType.Success)
        refreshResources(KEY_NAME)
      })
      .catch(err => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      })
  }

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
  }

  onChangeVisibility = async item => {
    try {
      await api.Slides.update(item.id, { visible: !item.visible })
      this.props.addSnackbar('Slide actualizado.', SnackbarType.Success)
      this.props.refreshResources(KEY_NAME)
    } catch (e) {
      this.props.addSnackbar('Error: ' + e.message, SnackbarType.Error)
    }
  }

  showDrawer = () => {
    this.setState(prevState => ({ drawer: !prevState.drawer }))
  }

  handleDrawer = () => {
    this.showDrawer()
  }

  submitSlidesOrder = async slides => {
    const { updateResource, addSnackbar, type, paginator } = this.props
    await updateResource(`${type}`, null, { slides })
    addSnackbar('Ordenamiento de slides actualizado exitosamente.', SnackbarType.Success)
    this.showDrawer()
    this.onFetch(KEY_NAME, paginator.query, paginator.page, paginator.size)
  }

  render() {
    const { confirmDeleteOpen, itemToDelete, drawer } = this.state

    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'SLIDES'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        sort={() => this.handleDrawer()}
        getOrderingConfig={() => DEFAULT_SLIDE_ORDER}
      >
        <SlidesDrawer
          drawer={drawer}
          slides={this.props.paginator.items}
          onSubmit={this.submitSlidesOrder}
          showDrawer={this.showDrawer}
        />
        <ConfirmDialog
          title={'Eliminar Slide'}
          open={confirmDeleteOpen}
          description={`¿Estás seguro de eliminar el slide "${itemToDelete}"?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    )
  }
}

AdminSlidesContainer.defaultProps = {
  type: 'slides',
}

const mapStateToProps = (state, ownProps) => {
  const states = state.paginatorReducer
  return {
    paginator: { ...states[KEY_NAME] },
    ...state.menuDrawerReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResources,
      refreshResources,
      deleteResource,
      goTo,
      addSnackbar,
      updateResource,
    },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSlidesContainer);
