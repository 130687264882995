import { ADMIN_SCORM_CONFIG_READ_END, ADMIN_SCORM_CONFIG_READ_FAILED, ADMIN_SCORM_CONFIG_READ_START } from "../../actions";

const initialState = {
  error: null,
  loading: false,
  scormConfig: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_SCORM_CONFIG_READ_END:
      const { scormConfig } = action.payload;
      return {
        loading: false,
        scormConfig,
        error: null,
      };
    case ADMIN_SCORM_CONFIG_READ_START:
       return {
        loading: true,
        scormConfig: null,
        error: null,
      };
    case ADMIN_SCORM_CONFIG_READ_FAILED:
       const { message } = action.payload;
        return {
          scormConfig: null,
          error: message,
          loading: false,
        };
  }
  return {...initialState}
}
