import React, { Component } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import { IconButton, SnackbarContent, Snackbar } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'
import { SnackbarType } from '../../config/constants'
import classNames from 'classnames'

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    backgroundColor: blue[700],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

const variantIcon = {
  [SnackbarType.Generic]: null,
  [SnackbarType.Success]: CheckCircleIcon,
  [SnackbarType.Warning]: WarningIcon,
  [SnackbarType.Error]: ErrorIcon,
  [SnackbarType.Info]: InfoIcon,
}

const variantClass = {
  [SnackbarType.Generic]: '',
  [SnackbarType.Success]: 'success',
  [SnackbarType.Warning]: 'warning',
  [SnackbarType.Error]: 'error',
  [SnackbarType.Info]: 'info',
}

class SnackbarMessage extends Component {
  render() {
    const { open, classes, className, message, onClose, type, ...other } = this.props
    const Icon = variantIcon[type]

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
      >
        <SnackbarContent
          aria-live={'polite'}
          className={classNames(classes[variantClass[type]], className)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              {Icon != null ? (
                <Icon className={classNames(classes.icon, classes.iconVariant)} />
              ) : null}
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-hidden={true}
              color="inherit"
              className={classes.close}
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
          {...other}
        />
      </Snackbar>
    )
  }
}

export default withStyles(styles)(SnackbarMessage);
