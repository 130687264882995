/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable import/named */
import { push } from 'connected-react-router';
import {
  HOME,
  RESET_PASSWORD,
  ADMIN_HOME,
  COURSES_EXPLORE,
  COURSES_EXPLORE_CATEGORY,
  SEARCH,
  PROFILE_ME,
  CAREER_PLANS,
  ADMIN_CAREER_PLAN,
  EXPLORE_CAREER_PLANS,
  DETAILS_CAREER_PLANS,
  router, ADMIN_LOTS, EXTERNAL_COURSES_NEXT_EVENTS,
} from '../config/routes';

export function showResetPassword() {
  return async (dispatch) => {
    try {
      dispatch(push(RESET_PASSWORD));
    } catch (e) {
      console.log(e);
    }
  };
}

export function goHome() {
  return async (dispatch) => {
    try {
      dispatch(push(HOME));
    } catch (e) {
      console.log(e);
    }
  };
}

export function goAdmin() {
  return async (dispatch) => {
    try {
      dispatch(push(ADMIN_HOME));
    } catch (e) {
      console.log(e);
    }
  };
}

export function goTo(route) {
  return async (dispatch) => {
    try {
      dispatch(push(route));
    } catch (e) {
      console.log(e);
    }
  };
}

export function goToExploreCategories() {
  return async (dispatch) => {
    try {
      dispatch(push(COURSES_EXPLORE));
    } catch (e) {
      console.log(e);
    }
  };
}
export function goToCareerPlans() {
  return async (dispatch) => {
    try {
      dispatch(push(CAREER_PLANS));
    } catch (e) { }
  };
}

export function goToAdminCareerPlan() {
  return async (dispatch) => {
    try {
      dispatch(push(ADMIN_CAREER_PLAN));
    } catch (e) { }
  };
}

export function goToExploreMoreCareerPlans() {
  return async (dispatch) => {
    try {
      dispatch(push(EXPLORE_CAREER_PLANS));
    } catch (e) { }
  };
}

export function goToExternalCoursesNextEvents() {
  return async (dispatch) => {
    try {
      dispatch(push(EXTERNAL_COURSES_NEXT_EVENTS));
    } catch (e) { }
  };
}

// export function goToExploreAllCareerPlans() {
//   return async (dispatch) => {
//     try {
//       dispatch(push(EXPLORE_ALL_CAREER_PLANS));
//     } catch (e) { }
//   };
// }

export function goToDetailsCareerPlans(planId) {
  return async (dispatch) => {
    try {
      dispatch(push(router.getRoute(DETAILS_CAREER_PLANS, { planId })));
    } catch (e) {
      console.log(e);
    }
  };
}

export function goToSearch() {
  return async (dispatch) => {
    try {
      dispatch(push(SEARCH));
    } catch (e) {
      console.log({ error: e });
    }
  };
}

export function goToUserProfile() {
  return async (dispatch) => {
    try {
      dispatch(push(PROFILE_ME));
    } catch (e) {
      console.log({ error: e });
    }
  };
}

export function goToExploreCoursesByCategory(categoryId) {
  return async (dispatch) => {
    try {
      dispatch(push(router.getRoute(COURSES_EXPLORE_CATEGORY, { categoryId })));
    } catch (e) {
      console.log(e);
    }
  };
}

export function goToAdminLot() {
  return async (dispatch) => {
    try {
      dispatch(push(ADMIN_LOTS));
    } catch (e) { }
  };
}
