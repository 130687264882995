import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import userReducer from './userReducer'
import courseReducer from './courseReducer'
import userCommissionReducer from './userCommissions/userCommissionReducer'
import externalCourseReducer from './externalCourses/externalCourseReducer'
import categoryReducer from './categoryReducer'
import adminFilesReducer from './admin/adminFilesReducer'
import menuDrawerReducer from './menuDrawerReducer'
import editRowReducer from './editRowReducer'
import paginatorReducer from './paginatorReducer'
import infiniteScrollPaginatorReducer from './infiniteScrollPaginatorReducer'
import lazySelectInputReducer from './lazySelectInputReducer'
import snackbarMessageReducer from './snackbarMessageReducer'
import createRequestReducer from './createRequestReducer'
import searchRequestReducer from './searchRequestReducer'
import adminResourceReducer from './admin/adminResourceReducer'
import topTenCoursesReducer from './courses/topTenCoursesReducer'
import variablePriceCoursesReducer from './courses/variablePriceCoursesReducer'
import requiredCoursesReducer from './courses/requiredCoursesReducer'
import searchCoursesReducer from './courses/searchCoursesReducer'
import categoriesReducer from './categories/categoriesReducer'
import featuredCategoriesReducer from './categories/featuredCategoriesReducer'
import myFinishedCoursesReducer from './me/myFinishedCoursesReducer'
import myCertificate from './me/myCertificate'
import myInProgressCoursesReducer from './me/myInProgressCoursesReducer'
import mySharedCoursesReducer from './me/mySharedCoursesReducer'
import profileReducer from './profileReducer'
import careerPlansCoursesReducers from './careerPlans/careerPlansCoursesReducers'
import careerPlansCategoriesReducers from './careerPlans/careerPlansCategoriesReducers'
import activitiesReducer from './activities/activitiesReducer'
import careerPlanReducer from './careerPlans/careerPlanReducer'
import careerPlanAdminReducer from './careerPlans/careerPlanAdminReducer'
import careerPlansExploreReducers from './careerPlans/careerPlansExploreReducers'
import lotAdminReducer from './lots/lotAdminReducer'
import adminTopTenReducer from './admin/adminTopTenReducer'
import filterTemplateReducer from './filterTemplateReducer'
import externalCoursesAdminReducer from './externalCourses/externalCoursesAdminReducer'
import externalCoursesHomeReducer from './externalCourses/externalCoursesHomeReducer'
import serviceWorkerReducer from './serviceWorkerReducer'
import calendarReducer from './calendarReducer'
import slidesReducer from './slidesReducer'
import playlistsReducer from './playlists/playlistsReducer'
import playlistReducer from './playlists/playlistReducer'
import myHiddenCoursesReducer from './me/myHiddenCoursesReducer'
import selectedUsersReducer from './selectedUsersReducer'
import scormConfigReducer from './scormConfig/scormConfigReducer'
import institutionReducer from './institutions/institutionReducer'
import placeReducer from './place/placeReducer'
import placesReducer from './place/placesReducer'
import platformReducer from './platform/platformReducer'
import objectiveDplusIReducer from './courses/objectiveDplusIReducer'
import questionsTypesReducer from './questionsType/questionsTypes'

// Hey, add all application reducers here!
// const mainReducer = combineReducers({
// })

// export default mainReducer

export default history =>
  combineReducers({
    router: connectRouter(history),
    userReducer,
    courseReducer,
    userCommissionReducer,
    externalCourseReducer,
    externalCoursesHomeReducer,
    categoryReducer,
    filterTemplateReducer,
    featuredCategoriesReducer,
    categoriesReducer,
    menuDrawerReducer,
    editRowReducer,
    paginatorReducer,
    lazySelectInputReducer,
    snackbarMessageReducer,
    activitiesReducer,
    careerPlanReducer,
    searchRequestReducer,
    createRequestReducer,
    infiniteScrollPaginatorReducer,
    courses: combineReducers({
      required: requiredCoursesReducer,
      search: searchCoursesReducer,
      topTen: topTenCoursesReducer,
      variablePrice: variablePriceCoursesReducer,
      objectiveDplusI: objectiveDplusIReducer,
    }),
    careerPlansCoursesReducers,
    careerPlansCategoriesReducers,
    careerPlanAdminReducer,
    careerPlansExploreReducers,
    lotAdminReducer,
    profileReducer,
    adminTopTenReducer,
    externalCoursesAdminReducer,
    admin: combineReducers({
      resource: adminResourceReducer,
      files: adminFilesReducer,
    }),
    me: combineReducers({
      inProgress: myInProgressCoursesReducer,
      shared: mySharedCoursesReducer,
      finished: myFinishedCoursesReducer,
      certificate: myCertificate,
      hidden: myHiddenCoursesReducer,
    }),
    serviceWorkerReducer,
    calendarReducer,
    slidesReducer,
    playlistsReducer,
    playlistReducer,
    selectedUsersReducer,
    scormConfigReducer,
    institutionReducer,
    placeReducer,
    placesReducer,
    platformReducer,
    questionsTypesReducer,
  })
