import {
  PLACES_READ_START,
  PLACES_READ_END,
  PLACES_READ_FAILED,
} from '../../actions';

let initialState = {
  error: null,
  loading: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PLACES_READ_END:
      const { items } = action.payload;
      return {
        loading: false,
        items: items,
        error: null,
      };
    case PLACES_READ_START:
      return {
        items: [],
        error: null,
        loading: true,
      };
    case PLACES_READ_FAILED:
      const { message } = action.payload;
      return {
        items: [],
        error: message,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
