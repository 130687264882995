import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../../actions/navigator';
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource,
} from '../../../actions/admin';
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  router,
  ADMIN_LOTS,
} from '../../../config/routes';
import AdminResourceIndex from '../AdminResourceIndex';
import {
  FiltersFormat,
  EditInputType,
  SnackbarType, AdminItemViewType, LotStatusName, LotTypes, LotCategory,
} from '../../../config/constants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { addSnackbar } from '../../../actions/snackbar';


const KEY_NAME = 'admin-lots';

const DEFAULT_LOTS_ORDER = [
  {
    name: 'lot.id',
    order: 'ASC',
  },
];

class AdminLotsContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
    drawer: false,
    lotSelected: [],
    lotIdSelected: null,
  };

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size, undefined)
  };

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Nombre', source: 'name' },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(LotStatusName).map((k) => ({
          condition: k,
          result: LotStatusName[k],
        })),
      },
      {
        title: 'Tipo de asignación',
        source: 'type',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(LotTypes).map((k) => ({
          condition: k,
          result: LotTypes[k],
        })),
      },
      {
        title: 'Sección',
        source: 'category',
        type: AdminItemViewType.Conditional,
        conditions: Object.keys(LotCategory).map((k) => ({
          condition: k,
          result: LotCategory[k],
        })),
      },
    ];
  };

  onCreate = () => {
    this.props.goTo(ADMIN_LOTS + ADMIN_NEW);
  };

  onEditItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_LOTS + ADMIN_EDIT, { id: item.id })
    );
  };

  onDeleteItem = (item) => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.name,
    });
  };

  getActions = (item) => {
    return [
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
    ];
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'lot.name',
        placeholder: 'Nombre',
        format: FiltersFormat.Like,
      }
    ];
  };

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props;
    const item = this.state.deleteItem;
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Plan de carrera eliminado.', SnackbarType.Success);
        refreshResources(KEY_NAME);
      })
      .catch((err) => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error);
      });
  };

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
  };

  showDrawer = () => {
    this.setState((prevState) => ({ drawer: !prevState.drawer }));
  };

  handleDrawer = (typeDrawer) => {
    this.setState({ drawerActive: typeDrawer });
    this.showDrawer();
  };

  render() {
    const {
      confirmDeleteOpen,
      itemToDelete,
    } = this.state;

    return (
      <AdminResourceIndex
        isBooleanDocuement
        keyName={KEY_NAME}
        title={'GRUPOS'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        getOrderingConfig={() => DEFAULT_LOTS_ORDER}
      >
        <ConfirmDialog
          title={'Eliminar Grupo'}
          open={confirmDeleteOpen}
          description={`¿Estás seguro de eliminar el grupo ${itemToDelete}?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    );
  }
}

AdminLotsContainer.defaultProps = {
  type: 'lots',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.paginatorReducer;
  return {
    paginator: { ...states[KEY_NAME] },
    ...state.menuDrawerReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResources,
      refreshResources,
      deleteResource,
      goTo,
      addSnackbar,
      updateResource,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLotsContainer);
