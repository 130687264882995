import React, { Component } from 'react'
import SelectInput from '../../components/admin/inputs/SelectInput'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { init, fetchOptions } from '../../actions/lazy_select'
import { EditInputObjectModel } from '../../config/constants'
import api from '../../api/api'

class LazySelectInputContainer extends Component {
  componentDidMount() {
    this.props.init(this.props.keyName).then(() => this.handleFetch(this.props))
  }

  handleFetch(props) {
  }

  render() {
    const {
      keyName,
      placeholder,
      name,
      value,
      onChange,
      options = [],
      multiple = false,
      showEmpty = false,
      max = false,
    } = this.props
    return (
      <SelectInput
        keyName={keyName}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        multiple={multiple}
        showEmpty={showEmpty && !multiple}
        max={max}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.lazySelectInputReducer
  return {
    ...states[ownProps.keyName],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchOptions, init }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LazySelectInputContainer)
