import {
  DOWNLOAD_CERTIFICATE_START,
  DOWNLOAD_CERTIFICATE_END,
  DOWNLOAD_CERTIFICATE_FAILED,
} from '../../actions'

let initialState = {
  error: null,
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_CERTIFICATE_START:
      return {
        loading: false,
        error: null,
      }
    case DOWNLOAD_CERTIFICATE_END:
      return {
        error: null,
        loading: true,
      }
    case DOWNLOAD_CERTIFICATE_FAILED:
      const { message } = action.payload
      return {
        error: message,
        loading: false,
      }
    default:
      return { ...state, error: null }
  }
}
