import React from 'react'
import { useEffect, useState } from 'react'
import useNotificate from '../../../hooks/useNotificate'
import { getActivityScormEvents } from '../../../actions/admin'
import { Dialog, DialogContent, IconButton, Typography } from '@material-ui/core'
import { AdminItemViewType, SnackbarType } from '../../../config/constants'
import AdminResourceTable from '../AdminResourceTable'
import Loading from '../../ui/Loading'
import moment from 'moment'
import { CloseOutlined } from '@material-ui/icons'

const columns = [
  {
    title: 'Target',
    source: 'target',
    type: AdminItemViewType.Text,
  },
  {
    title: 'Evento',
    source: 'verb',
    type: AdminItemViewType.Text,
  },
  {
    title: 'Resultado',
    source: 'result',
    type: AdminItemViewType.Number,
  },
  {
    title: 'Minimo',
    source: 'minimum',
    type: AdminItemViewType.Number,
  },
  {
    title: 'Fecha',
    source: 'xstored',
    type: AdminItemViewType.Custom,
    renderItem: item => (item.xstored ? moment(item.xstored).format('DD/MM/YYYY') : ''),
  },
]

const ScormListModal = ({ user, scormIdToGetEvents, removeScormIdToGetEvents }) => {
  const { notificate } = useNotificate()
  const [scormEvents, setScormEvents] = useState()
  const [scormEventsIsLoading, setScormEventsIsLoading] = useState()

  const getActivityScormEventList = async () => {
    setScormEventsIsLoading(true)
    try {
      const response = await getActivityScormEvents(user?.id, scormIdToGetEvents)
      setScormEvents(response.data)
    } catch {
      setScormEvents(undefined)
      setScormEventsIsLoading(false)
      notificate('Error al obtener datos del scorm', SnackbarType.Error)
    }
  }

  useEffect(() => {
    if (scormEvents) setScormEventsIsLoading(false)
  }, [scormEvents])

  useEffect(() => {
    if (scormIdToGetEvents) getActivityScormEventList()
  }, [])

  return (
    <Dialog
      disableEnforceFocus={true}
      open={!!scormIdToGetEvents}
      onClose={removeScormIdToGetEvents}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '2rem',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            style={{ fontSize: '1.5rem', fontWeight: 600 }}
          >{`Eventos del Scorm ID ${scormIdToGetEvents}`}</Typography>
          <IconButton onClick={removeScormIdToGetEvents}>
            <CloseOutlined />
          </IconButton>
        </div>
        {scormEventsIsLoading && !scormEvents ? (
          <Loading fullWidth dark />
        ) : (
          <div
            style={{
              flex: 1,
              display: 'flex',
              maxHeight: '40rem',
              overflow: 'auto',
              padding: '0 1rem 1rem 0',
            }}
          >
            <div style={{ flex: 1 }}>
              {scormEvents?.length ? (
                <AdminResourceTable
                  key="scorm-events-user-table"
                  items={scormEvents ?? []}
                  columns={columns}
                  forceNoActions
                  isLoading={scormEventsIsLoading}
                />
              ) : (
                <Typography style={{ marginTop: '1.5rem' }}>
                  {`No hay eventos registrados para el usuario ${user?.full_name}`}
                </Typography>
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ScormListModal
