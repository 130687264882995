import React, { useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { addSnackbar } from '../../actions/snackbar'
import { SnackbarType } from '../../config/constants'
import EditPlaylistDialog from '../../components/playlists/EditPlaylistDialog'
import api from '../../api/api'

const EditPlaylistDialogContainer = ({ playlist, addSnackbar, open, onDismiss }) => {
  const onExit = useCallback(() => {
  }, [])

  const onUpdatePlaylist = useCallback(
    async name => {
      if(name.trim().length>0) {
        try {
          await api.Playlists.update(playlist.id, name)
          onDismiss()
          addSnackbar('Lista modificada', SnackbarType.Success)
        } catch (e) {
          console.log(e)
          addSnackbar('Ocurrió un error al modificar la lista', SnackbarType.Error)
        }
      }
    },
    [addSnackbar, onDismiss],
  )

  return (
    <EditPlaylistDialog
      playlist={playlist}
      open={open}
      handleClose={onDismiss}
      onExit={onExit}
      onUpdatePlaylist={onUpdatePlaylist}
    />
  )
}

const mapStateToProps = state => {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addSnackbar }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPlaylistDialogContainer))
