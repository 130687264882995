import { push } from 'connected-react-router'
import {
  APP_LOAD_TOKEN,
  USER_LOGIN_END,
  USER_LOGIN_START,
  USER_LOGIN_START_OB,
  USER_LOGIN_START_OB_QR,
  USER_READ_START,
  USER_LOGIN_ERROR,
  USER_READ_END,
  USER_LOGIN_FAILED,
  USER_LOGOUT_START,
  USER_LOGOUT_END,
  USER_LOGOUT_FAILED,
  USER_READ_FAILED,
  MY_PROFILE_START,
  MY_PROFILE_END,
  MY_PROFILE_FAILED,
  MY_SELECTED_USERS_START,
  MY_SELECTED_USERS_END,
  MY_SELECTED_USERS_FAILED,
  MY_COURSES_IN_PROGRESS_READ_START,
  MY_COURSES_IN_PROGRESS_READ_END,
  MY_COURSES_IN_PROGRESS_READ_FAILED,
  MY_COURSES_FINISHED_READ_START,
  MY_COURSES_FINISHED_READ_END,
  MY_COURSES_FINISHED_READ_FAILED,
  MY_COURSES_HIDDEN_READ_START,
  MY_COURSES_HIDDEN_READ_END,
  MY_COURSES_HIDDEN_READ_FAILED,
  MY_SHARED_COURSES_READ_START,
  MY_SHARED_COURSES_READ_END,
  MY_SHARED_COURSES_READ_FAILED,
  DOWNLOAD_CERTIFICATE_START,
  DOWNLOAD_CERTIFICATE_FAILED,
  DOWNLOAD_CERTIFICATE_END,
} from './index'
import api from '../api/api'
import { HOME } from '../config/routes'
import XLSX from 'xlsx'

/**
 * Action idForwardingLogin
 * @param {{token: string}} oneTimeToken
 * @returns hace una llamada a login para obtener el jwt
 */
export function idForwardingLogin(oneTimeToken) {
  return async dispatch => {
    try {
      const response = await api.Auth.getUserToken(oneTimeToken)
      const { token } = response.data
      api.setToken(token)
      await wordpressLogin()()
      dispatch({ type: USER_LOGIN_END, payload: { token } })
      return true
    } catch (e) {
      dispatch({ type: USER_LOGIN_FAILED, payload: e })
      return false
    }
  }
}

export function idForwardingLoginOB(oneTimeToken) {
  return async dispatch => {
    try {
      const response = await api.Auth.getUserTokenOB(oneTimeToken.token)
      const { token, reset_pasword } = response.data
      api.setToken(token)
      api.setReset_pasword(reset_pasword)
      await wordpressLogin()()
      dispatch({ type: USER_LOGIN_END, payload: { token, reset_pasword } })
      return true
    } catch (e) {
      dispatch({ type: USER_LOGIN_FAILED, payload: e })
      return false
    }
  }
}

/**
 * Action login
 * @param {{datos: string}} data
 * @returns hace una llamada a session2 para obtener el one time token y luego
 * ejecuta la funcion idForwardingLogin
 */
export function login(data) {
  return async dispatch => {
    dispatch({ type: USER_LOGIN_START })
    try {
      const response = await api.Auth.session(data)
      const { ott } = response.data
      dispatch(idForwardingLogin(ott))
    } catch (e) {
      dispatch({ type: USER_LOGIN_ERROR, payload: e.data })
      console.error(e)
    }
  }
}

export function loginOB(data) {
  return async dispatch => {
    try {
      const response = await api.Auth.sessionOB(data)
      const { ott, tfa_complete } = response.data
      if (tfa_complete) {
        dispatch({ type: USER_LOGIN_START_OB })
        const resp = await dispatch(idForwardingLoginOB({ token: ott }))
      } else {
        const { qr } = response.data
        return { qr }
      }
    } catch (e) {
      dispatch({ type: USER_LOGIN_ERROR, payload: e.data })
      return e
    }
  }
}

export function loginQr({ username, password, code, token }) {
  return async dispatch => {
    try {
      const responseQR = await api.Auth.sessionOBQR({ username, code })
      const { login } = responseQR.data
      if (login === 'success') {
        const response = await api.Auth.sessionOB({ username, password, token })
        const { ott, tfa_complete } = response.data
        if (tfa_complete) {
          dispatch({ type: USER_LOGIN_START_OB })
          const resp = await dispatch(idForwardingLoginOB({ token: ott }))
        }
      } else {
        return false
      }
    } catch (e) {
      console.error('error', e)
      dispatch({ type: USER_LOGIN_ERROR, payload: e.data })
      return false
    }
  }
}

export function changuePassOB({ password }) {
  return async dispatch => {
    try {
      const response = await api.Auth.changuePass({ password })
      return response.data.change
    } catch (e) {
      console.error('error', e)
      dispatch({ type: USER_LOGIN_ERROR, payload: e.data })
      return false
    }
  }
}

export function getSelectedUsers(data) {
  return async dispatch => {
    dispatch({ type: MY_SELECTED_USERS_START })
    try {
      const response = await api.withDispatch(dispatch).My.Courses.selectedUsers(data)
      dispatch({
        type: MY_SELECTED_USERS_END,
        payload: response.data,
      })
    } catch (e) {
      dispatch({
        type: MY_SELECTED_USERS_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function resetSelectedUsers() {
  return async dispatch => {
    dispatch({ type: MY_SELECTED_USERS_START })
  }
}

export function wordpressLogin() {
  return async () => {
    try {
      await api.Wordpress.login()
      return true
    } catch (e) {
      console.error(e)
    }
    return false
  }
}

export function getCurrent() {
  return async dispatch => {
    try {
      dispatch({ type: USER_READ_START })
      const response = await api.withDispatch(dispatch).Auth.getLoggedUser()
      dispatch({ type: USER_READ_END, payload: { user: response.data } })
    } catch (e) {
      dispatch({ type: USER_READ_FAILED, payload: e })
    }
  }
}

export function logout() {
  return async dispatch => {
    dispatch({ type: USER_LOGOUT_START })
    try {
      dispatch({ type: USER_LOGOUT_END, payload: {} })
      dispatch({ type: 'RESET' })
      dispatch(push(HOME))
    } catch (e) {
      dispatch({ type: USER_LOGOUT_FAILED, payload: e })
    }
  }
}

export function loadMyInProgress() {
  return async dispatch => {
    dispatch({ type: MY_COURSES_IN_PROGRESS_READ_START })
    try {
      const response = await api.withDispatch(dispatch).My.Courses.inProgress()
      dispatch({
        type: MY_COURSES_IN_PROGRESS_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: MY_COURSES_IN_PROGRESS_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadMyFinished() {
  return async dispatch => {
    dispatch({ type: MY_COURSES_FINISHED_READ_START })
    try {
      const response = await api.withDispatch(dispatch).My.Courses.completed()
      dispatch({
        type: MY_COURSES_FINISHED_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: MY_COURSES_FINISHED_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadMyHidden() {
  return async dispatch => {
    dispatch({ type: MY_COURSES_HIDDEN_READ_START })
    try {
      const response = await api.withDispatch(dispatch).My.Courses.hidden()
      dispatch({
        type: MY_COURSES_HIDDEN_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: MY_COURSES_HIDDEN_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function downloadCertificate(id) {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_CERTIFICATE_START })
    try {
      const response = await api.withDispatch(dispatch).My.Courses.downloadCertificate(id)
      dispatch({
        type: DOWNLOAD_CERTIFICATE_END,
      })
      return response
    } catch (e) {
      dispatch({
        type: DOWNLOAD_CERTIFICATE_FAILED,
        payload: { message: e.toString() },
      })
      return e
    }
  }
}

export function downloadCertificateCourse(id) {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_CERTIFICATE_START })
    try {
      const response = await api.withDispatch(dispatch).My.Courses.downloadCertificateCourse(id)
      dispatch({
        type: DOWNLOAD_CERTIFICATE_END,
      })
      return response
    } catch (e) {
      dispatch({
        type: DOWNLOAD_CERTIFICATE_FAILED,
        payload: { message: e.toString() },
      })
      return e
    }
  }
}

export function loadProfile() {
  return async dispatch => {
    dispatch({ type: MY_PROFILE_START })
    try {
      const response = await api.withDispatch(dispatch).My.Profile.get()
      dispatch({
        type: MY_PROFILE_END,
        payload: response.data,
      })
    } catch (e) {
      dispatch({
        type: MY_PROFILE_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function loadMySharedCourses() {
  return async dispatch => {
    dispatch({ type: MY_SHARED_COURSES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).My.Courses.shared()
      dispatch({
        type: MY_SHARED_COURSES_READ_END,
        payload: { items: response.data },
      })
    } catch (e) {
      dispatch({
        type: MY_SHARED_COURSES_READ_FAILED,
        payload: { message: e.toString() },
      })
    }
  }
}

export function downloadReportXls(data, header) {
  return async dispatch => {
    // web reference-> stackoverflow.com/questions/52215676/sheetjs-json-to-sheet-renaming-headers

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(wb, [header])
    //Starting in the second row to avoid overriding and skipping headers
    const ws = XLSX.utils.sheet_add_json(wb, data, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Report')
    XLSX.writeFile(wb, 'report.xlsx')

    // var Heading = [
    //   ["FirstName", "Last Name", "Email", "Tel", "saraza"],
    // ];

    // var keys = Object.keys(header);
    // var subkeys = Object.keys(header[keys[0]]);
    // console.log(subkeys);

    // ORIGIN VERSION
    //const ws = XLSX.utils.json_to_sheet(data);
    //const wb = XLSX.utils.book_new();
    //XLSX.utils.book_append_sheet(wb, ws, "Report");
    //XLSX.writeFile(wb, "report.xlsx")
  }
}
