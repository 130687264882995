import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid, Typography} from '@material-ui/core'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import EventListenerWrapper from '../ui/EventListenerWrapper'
import CheckIcon from '@material-ui/icons/CheckCircle'
import {CommissionsActions, CourseType, UserCommissionStatus} from '../../config/constants'
import CommissionCard from './CommissionCard'
import CategoryChip from '../../containers/ui/CategoryChip'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
  },
  content: {
    marginRight: '15%',
    marginLeft: '15%',
    marginTop: 32,
    marginBottom: 50,
  },
  sectionTitle: {
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 32,
  },
  categories: {
    float: 'left',
    width: '100%',
  },

  rating: {
    display: 'flex',
    width: '100%',
    paddingBottom: 16,
  },
  npsScoreTitle: {
    float: 'left',
    color: '#ffffff',
    marginLeft: 5,
    marginTop: 0,
    fontSize: 14,
  },
  ratingsCountLink: {
    float: 'left',
    color: '#ffffff',
    marginLeft: 5,
    marginTop: 0,
    fontSize: 14,
  },
  star: {
    fontSize: 20,
    color: '#1ed1a8',
  },
  check: {
    fontSize: 14,
    color: '#ffffff',
    marginBottom: -2,
  },
  section: {
    paddingTop: 16,
    float: 'left',
    width: '100%',
  },
  sectionOtherCommissions: {
    marginTop: 32,
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontWeight: 400,
  },
  warning: {
    color: '#d1771e',
    fontSize: 12,
    padding: 8,
  },
  subtitle: {
    color: 'white',
    fontSize: 14,
    fontWeight: 600,
  },
  courseLink: {
    textDecoration: 'underline',
    color: '#1ed1a8',
    cursor: 'pointer',
    paddingLeft: 4,
  },
  category: {
    fontSize: 12,
    height: '1.5em',
  },
  tag: {
    fontSize: 12,
    marginTop: 2,
  },
})

const Description = ({classes, course, onShowCourse}) => {
  return (
    <section className={classes.section}>
      <Typography component={'h2'} className={classes.sectionTitle}>
        Descripción del taller
      </Typography>
      <div className={classes.section} style={{paddingTop: 0}}>
        {course.rates_count > 0 && (
          <div className={classes.rating} role={'text'} tabIndex={0}>
            <React.Fragment>
              <StarOutlinedIcon iconname={'ic_star_outline'} className={classes.star}/>
              <Typography className={classes.npsScoreTitle}>NPS {parseInt(course.nps)}</Typography>
            </React.Fragment>
            {course.rates_count > 0 && (
              <Typography className={classes.npsScoreTitle}> | </Typography>
            )}
            <Typography
              className={course.rates_count > 0 ? classes.ratingsCountLink : classes.npsScoreTitle}
            >
              {course.rates_count === 1
                ? '1 valoración '
                : course.rates_count > 1
                  ? `${course.rates_count} valoraciones `
                  : null}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.categories}>
        {course.categories.map(c => (
          <CategoryChip className={classes.category} key={'c' + c.id} category={c} tabIndex={0}/>
        ))}
      </div>
      <div className={classes.section}>
        <Typography className={classes.text} tabIndex={0}>
          {course.description}
        </Typography>
      </div>
      {(course.required_courses.length > 0 || course.required_external_courses.length > 0) && (
        <div className={classes.section} tabIndex={0}>
          <Typography className={classes.subtitle} component={'p'}>Requisitos:</Typography>
          {course.required_courses.length > 0 && (
            <Typography className={classes.text} component={'p'} tabIndex={0} key={`required-courses`}>
              {course.required_courses.length === 1 ? 'Curso' : 'Cursos'}
              {' online: '}
              {course.required_courses.map((rc, i) => (
                <>
                  <EventListenerWrapper
                    tabIndex={0}
                    TagType={'span'}
                    role={'link'}
                    onEnter={() => onShowCourse({type: CourseType.Course, id: rc.id})}
                    onClick={() => onShowCourse({type: CourseType.Course, id: rc.id})}
                    className={classes.courseLink}
                  >
                    {rc.title} {rc.completed === 1 && <CheckIcon className={classes.check}/>}
                  </EventListenerWrapper>
                  {i !== course.required_courses.length - 1 ? ', ' : ' '}
                </>
              ))}
            </Typography>
          )}

          {course.required_external_courses.length > 0 && (
            <Typography className={classes.text} component={'p'} tabIndex={0} key={`required-external-courses`}>
              {course.required_external_courses.length === 1 ? 'Taller' : 'Talleres'}
              {' en vivo: '}
              {course.required_external_courses.map((rec, i) => (
                <EventListenerWrapper
                  tabIndex={0}
                  TagType={'span'}
                  role={'link'}
                  onEnter={() => onShowCourse({type: CourseType.ExternalCourse, id: rec.id})}
                  onClick={() => onShowCourse({type: CourseType.ExternalCourse, id: rec.id})}
                  className={classes.courseLink}
                >
                  {rec.title}
                  {rec.present && <CheckIcon className={classes.check}/>}
                  {i !== course.required_external_courses.length - 1 ? ', ' : ' '}
                </EventListenerWrapper>
              ))}
            </Typography>
          )}
        </div>
      )}
    </section>
  )
}

const NextCommission = ({
    classes,
    course,
    commission,
    onSubscribe,
    onUnsubscribe,
  }) => {
  const actions = course && commission ? actionsEnabled(course, commission) : []
  const deadline = new Date(commission?.registration_deadline);
  return (
    <section className={classes.section}>
      <Typography className={classes.sectionTitle} component={'h2'} tabIndex={0}>
        Próxima fecha disponible
      </Typography>
      {commission && (deadline > new Date()) ? (
          <CommissionCard
            highlighted={true}
            highlightColor={course.categories.length > 0 ? course.categories[0].color : false}
            commission={commission}
            actions={actions}
            onSubscribe={onSubscribe}
            onUnsubscribe={onUnsubscribe}
          />
        ) :
        <Typography className={classes.text}>
          En este momento no existen fechas disponibles para este taller, volvé a consultar más
          tarde.
        </Typography>

      }
    </section>
  )
}

const OtherCommissions = ({
    classes,
    course,
    commissions,
    onSubscribe,
    onUnsubscribe,
  }) => {
  const now = new Date();
  const validCommissions = commissions.filter(c => new Date(c.registration_deadline) > now);

  return (
    <section className={classes.section + ' ' + classes.sectionOtherCommissions}>
      <Typography className={classes.sectionTitle} component={'h2'} tabIndex={0}>
        Otras fechas
      </Typography>
      <Grid container className={classes.grid} spacing={8}>
        {validCommissions.length > 0 ?
          validCommissions.map((c, i) => (
            <Grid sm={12} md={6} item key={`commission-card-${i}`}>
              <CommissionCard
                commission={c}
                actions={actionsEnabled(course, c)}
                onSubscribe={onSubscribe}
                onUnsubscribe={onUnsubscribe}
              />
            </Grid>
          ))
          :
          <Typography className={classes.text}>
            En este momento no existen otras fechas disponibles para este taller.
          </Typography>
        }
      </Grid>
    </section>
  );
}

const extractCommissions = commissions => {
  let nearestCommission = null;
  let otherCommissions = [];

  const now = new Date();

  const unexpiredCommissions = commissions.filter(commission => new Date(commission.registration_deadline) > now);

  unexpiredCommissions.sort((a, b) => new Date(a.registration_deadline) - new Date(b.registration_deadline));

  if (unexpiredCommissions.length > 0) {
    nearestCommission = unexpiredCommissions[0];
    otherCommissions = unexpiredCommissions.slice(1);
  }

  return [nearestCommission, otherCommissions];
}

const requiredCoursesCompleted = course => {
  const incompleteExternalCourse = course.required_external_courses.find(rec => rec.present !== 1)
  const incompleteCourse = course.required_courses.find(rec => rec.completed !== 1)
  return !incompleteCourse && !incompleteExternalCourse
}

const actionsEnabled = (course, commission) => {
  const actions = []
  const hasRequiredCoursesCompleted = requiredCoursesCompleted(course)
  if (commission.user_commission_status === UserCommissionStatus.Present) {
    actions.push(CommissionsActions.Done)
    return actions
  }
  if (
    hasRequiredCoursesCompleted &&
    commission.quota > 0 &&
    commission.user_commission_status !== UserCommissionStatus.Subscribed
  ) {
    actions.push(CommissionsActions.Subscribe)
  }
  if (commission.user_commission_status === UserCommissionStatus.Subscribed) {
    actions.push(CommissionsActions.Unsubscribe)
  } else if (
    hasRequiredCoursesCompleted &&
    commission.quota === 0 &&
    !commission.user_commission_waiting_list_status
  ) {
    actions.push(CommissionsActions.SubscribeOnWaitingList)
  }
  return actions
}

const ContentExternalCourse = ({classes, course, onShowCourse, onSubscribe, onUnsubscribe}) => {
  let [firstCommission, otherCommissions] = extractCommissions(course.commissions)
  return (
    <main id="main" className={classes.root} tabIndex={-1}>
      <div className={classes.content}>
        <Grid container className={classes.grid} spacing={8}>
          <Grid sm={12} md={6} item>
            <Description course={course} classes={classes} onShowCourse={onShowCourse}/>
          </Grid>
          <Grid sm={12} md={6} item>
            <NextCommission
              commission={firstCommission}
              classes={classes}
              course={course}
              onSubscribe={onSubscribe}
              onUnsubscribe={onUnsubscribe}
            />
          </Grid>
        </Grid>
        {otherCommissions.length > 0 && (
          <OtherCommissions
            commissions={otherCommissions}
            classes={classes}
            course={course}
            onSubscribe={onSubscribe}
            onUnsubscribe={onUnsubscribe}
          />
        )}
      </div>
    </main>
  )
}
export default withStyles(styles)(ContentExternalCourse)
