import React from 'react'
import MultipleChoiceResolver from './MultipleChoiceResolver'
import Loading from '../../../../ui/Loading'
import TrueOrFalseResolver from './TrueOrFalseResolver'
import FillInTheBlanksResolver from './FillInTheBlanksResolver'
import DragTheWordsResolver from './DragTheWordsResolver'

const resolverData = {
  TrueOrFalse: {
    Component: TrueOrFalseResolver,
  },
  MultipleChoice: {
    Component: MultipleChoiceResolver,
  },
  FillInTheBlanks: {
    Component: FillInTheBlanksResolver,
  },
  DragTheWords: {
    Component: DragTheWordsResolver,
  },
}

const UserResolver = ({ questionTypeType, ...rest }) => {
  const { Component } = resolverData[questionTypeType]

  return questionTypeType && Component ? <Component {...rest} /> : <Loading />
}

export default UserResolver
