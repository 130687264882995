import React, { useEffect, useState, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loadCalendar } from '../../actions/calendar'
import { goTo } from '../../actions/navigator'
import NextEvents from '../../components/externalCourses/nextEvents/NextEvents'
import { EXTERNAL_COURSES_VIEW } from '../../config/routes'
import { router } from '../../config/routes'
import DocumentTitle from '../../components/ui/DocumentTitle'

const NextEventsContainer = ({ loadCalendar, calendar, goTo }) => {
  const [currentDate, updateDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  })

  const [filters, updateFilters] = useState([])

  useEffect(() => {
    loadCalendar(currentDate.year, currentDate.month)
  }, [currentDate, loadCalendar])

  const openExternalCourse = useCallback(
    id => {
      goTo(router.getRoute(EXTERNAL_COURSES_VIEW, { id }))
    },
    [goTo],
  )

  return (
    <React.Fragment>
      <DocumentTitle title={'Próximos talleres | Talleres | Academia'} />
      <NextEvents
        events={calendar.events}
        currentDate={currentDate}
        updateDate={updateDate}
        updateFilters={updateFilters}
        filters={filters}
        openExternalCourse={openExternalCourse}
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    calendar: { ...state.calendarReducer },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCalendar,
      goTo,
    },
    dispatch,
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NextEventsContainer))
