import React, { Component } from 'react'
import Content from './ProfileContent'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
    maxWidth: 'calc(100vw - 0%)',
  },
})

class Profile extends Component {
  render() {
    const {
      classes,
      user,
      loading,
      profile,
      onShowCourse,
      onShowCarrerPlan,
      onDownloadXls,
      onUnsusbcribeFromCommission,
      onRateCommission,
    } = this.props

    return (
      <div className={classes.root}>
        <Content
          user={user}
          loading={loading}
          profile={profile}
          onShowCourse={onShowCourse}
          onShowCarrerPlan={onShowCarrerPlan}
          onDownloadXls={onDownloadXls}
          onUnsusbcribeFromCommission={onUnsusbcribeFromCommission}
          onRateCommission={onRateCommission}
        />
      </div>
    )
  }
}
export default withStyles(styles)(Profile)
