import validator from 'validator';

const TITLE_LENGTH = {
  max: 80,
  min: 2,
};

const DESCRIPTION_LENGTH = {
  max: 600,
  min: 2,
};

const validateH5p = (form) => {
  let error = false;
  const errors = {
    name: '',
    description: '',
    external: '',
  };

  if (!form.external) {
    errors.external = 'Debes crear el contenido h5p';
    error = true;
  }

  if (!form.name || validator.isEmpty(form.name.trim())) {
    errors.name = 'Debes completar este campo';
    error = true;
  } else if (
    !validator.isLength(form.name.trim(), {
      min: TITLE_LENGTH.min,
      max: TITLE_LENGTH.max,
    })
  ) {
    errors.name = `Este campo debe tener entre ${TITLE_LENGTH.min} y ${TITLE_LENGTH.max} caracteres.`;
    error = true;
  }

  if (!form.description || validator.isEmpty(form.description.trim())) {
    errors.description = 'Debes completar este campo';
    error = true;
  } else if (
    !validator.isLength(form.description.trim(), {
      min: DESCRIPTION_LENGTH.min,
      max: DESCRIPTION_LENGTH.max,
    })
  ) {
    errors.description = `Este campo debe tener entre ${DESCRIPTION_LENGTH.min} y ${DESCRIPTION_LENGTH.max} caracteres.`;
    error = true;
  }

  return { errors: errors, isValid: !error };
};

export default validateH5p;
