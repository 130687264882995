import React, { useEffect, useState } from 'react'
import { Checkbox, CircularProgress } from '@material-ui/core'

const ActionCheckbox = ({ checked, onClick, editing }) => {
  const [loading, setLoading] = useState(false)
  const handleOnClick = async () => {
    setLoading(true)
    await onClick()
  }

  useEffect(() => {
    if (!editing) {
      setLoading(false)
    }
  }, [editing])

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '6rem',
      }}
    >
      <>
        {loading ? (
          <CircularProgress size={16} style={{ color: 'GrayText' }} />
        ) : (
          <Checkbox
            style={{ color: 'GrayText', padding: 'unset' }}
            checked={checked}
            onClick={handleOnClick}
          />
        )}
      </>
    </div>
  )
}

export default ActionCheckbox
