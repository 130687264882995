import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from './HeaderHome'
import TabBar from '../ui/TabBar'
import MyCurrentPlans from './MyCurrentPlans'
import MyPreviousCareerPlans from './MyPreviousCareerPlans'

const styles = theme => {
  return {
    root: {
      position: 'relative',
      backgroundColor: '#101010',
      width: '100%',
      maxWidth: 'calc(100vw - 0%)',
      height: '100hv',
    },
  }
}

class CareerPlansHome extends Component {
  state = {
    historyLoaded: false,
  }

  render() {
    const {
      classes,
      tabActive,
      tabs,
      onChageTab,
      currentCareerPlans,
      previousCareerPlans,
      onShowCourse,
      loadCarrerPlan,
      loadPreviousCareerPlan,
      onClickButtonBanner,
      onDeleteSharedCourse,
    } = this.props

    return (
      <div className={classes.root}>
        <Header
          title="Mi Ruta de Aprendizaje"
          labelButton="Explorar otras rutas"
          onClick={onClickButtonBanner}
        />
        <main id="main" tabIndex={-1}>
          <TabBar tabActive={tabActive} tabs={tabs} onChageTab={onChageTab} />
          {/* tabActive === 0  */}
          {tabActive === 0 && currentCareerPlans && currentCareerPlans.currentCareerPlans && (
            <MyCurrentPlans
              loadCarrerPlan={loadCarrerPlan}
              {...currentCareerPlans}
              onShowCourse={onShowCourse}
              onDeleteSharedCourse={onDeleteSharedCourse}
            />
          )}
          {/* tabActive === 1 &&  */}
          {tabActive === 1 &&
            previousCareerPlans.previousCareerPlans &&
            previousCareerPlans.previousCareerPlans.length > 0 && (
              <MyPreviousCareerPlans
                {...previousCareerPlans}
                loadPreviousCareerPlan={loadPreviousCareerPlan}
                onShowCourse={onShowCourse}
                onDeleteSharedCourse={onDeleteSharedCourse}
              />
            )}
        </main>
      </div>
    )
  }
}
export default withStyles(styles)(CareerPlansHome)
