import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import config from "../../config";

const styles = (theme, palette2) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    header: {
      position: 'relative',
      backgroundSize: 'cover',
      background: `center url("${config.BASE_URL}img/ac_bg_home_short.jpg") #434343`,
      height: '10%',
      minHeight: 320,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },

    headerContent: {
      paddingTop: 80,
      position: 'relative',
      textAlign: 'center',
      margin: 'auto',
      maxWidth: '50%',
    },
    gradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background:
          'linear-gradient(315deg, #b32b52 0%, #922343 6%, #6b1931 10%, #4a1121 17%, #2f0b15 24%, #1a060b 32%, #0b0205 59%, #020001 76%, #000000 100%)',
      opacity: 0.49,
    },
    title: {
      fontSize: 80,
      fontWeight: 10,
      color: '#ffffff',
      textShadow: '0 1.5px 3px rgba(0, 0, 0, 0.16)',
      fontFamily: 'Open Sans',
      lineHeight: 1.1,
      marginBottom: 20,
      [mediaLg]: {
        fontSize: 40,
      },
    },
  }
}

const HeaderPlaylists = ({ classes }) => {
  return (
    <header className={classes.header}>
      <div className={classes.gradient} />
      <div className={classes.headerContent}>
        <Typography component={'h1'} className={classes.title}>
          Listas de aprendizaje
        </Typography>
      </div>
    </header>
  )
}
export default withStyles(styles)(HeaderPlaylists)
