import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  activityText: {
    color: '#777777',
  },
};

class ActivityModuleScormContent extends Component {
  render() {
    return null;
  }
}

export default withStyles(styles)(ActivityModuleScormContent);
