import config from './index'

export const HOME = config.BASE_URL
export const TOKEN = `${config.BASE_URL}setToken`
export const VIEW = 'view/:id'
export const COURSES = `${config.BASE_URL}courses/`
export const EXTERNAL_COURSES = `${config.BASE_URL}external-courses/`
export const ME = 'me/'

export const PLAYLISTS = `${config.BASE_URL}` + 'playlists/'
export const CAREER_PLANS = `${config.BASE_URL}` + ME + 'career-plans'
export const EXPLORE_CAREER_PLANS = `${config.BASE_URL}career-plans`
export const DETAILS_CAREER_PLANS = `${config.BASE_URL}career-plans/:planId`

export const PLAYLIST_VIEW = PLAYLISTS + VIEW
export const RESET_PASSWORD = `${config.BASE_URL}resetPassword`
export const COURSES_VIEW = COURSES + VIEW
export const EXTERNAL_COURSES_VIEW = EXTERNAL_COURSES + VIEW
export const EXTERNAL_COURSES_NEXT_EVENTS = EXTERNAL_COURSES + 'next-events'
export const COURSES_EXPLORE = `${COURSES}explore`
export const COURSES_EXPLORE_CATEGORY = `${COURSES_EXPLORE}/:categoryId`
export const BASE_ADMIN = '/admin'
export const PROFILE_ME = `${config.BASE_URL}profile`

export const IDFW = `${config.BASE_URL}idfw`
export const IDFWOB = `${config.BASE_URL}idob`
export const IDFW_IN = `${config.IDFW_IN}`
export const IDFW_OUT = `${config.IDFW_OUT}`
export const IDFW_OB = `${config.IDFW_OB}`
export const ADMIN_VIEW = '/view/:id'
export const ADMIN_EDIT = '/edit/:id'
export const ADMIN_DUPLICATE = '/duplicate/:id'
export const ADMIN_NEW = '/new'
export const ADMIN_HOME = `${config.BASE_URL}admin`
export const ADMIN_CATEGORIES = `${config.BASE_URL}admin/categories`
export const ADMIN_SLIDES = `${config.BASE_URL}admin/slides`
export const ADMIN_CAREER_PLAN = `${config.BASE_URL}admin/career-plans`
export const ADMIN_LOTS = `${config.BASE_URL}admin/lots`

export const ADMIN_TAGS = `${config.BASE_URL}admin/tags`
export const ADMIN_COURSES = `${config.BASE_URL}admin/courses`
export const ADMIN_COURSES_BASIC = `${config.BASE_URL}admin/courses/basic`
export const ADMIN_COURSES_TOPTEN = `${config.BASE_URL}admin/courses/topten`
export const ADMIN_COURSES_BLOCK = `${config.BASE_URL}admin/courses/block`
export const ADMIN_ACTIVITIES = `${config.BASE_URL}admin/activities`
export const ADMIN_SCORMS = `${config.BASE_URL}admin/scorms`
export const ADMIN_USERS = `${config.BASE_URL}admin/users`
export const ADMIN_REPORTS = `${config.BASE_URL}admin/reports`
export const ADMIN_REPORTS_COURSE_ACTIVITIES = `${config.BASE_URL}admin/reports/course_activities`
export const ADMIN_REPORTS_QUIZ_TRIVIA_ACTIVITIES = `${config.BASE_URL}admin/reports/quiz_activities`
export const ADMIN_REPORTS_NPS_COMMISSIONS = `${config.BASE_URL}admin/reports/nps_commissions`
export const ADMIN_REPORTS_NPS_COURSES = `${config.BASE_URL}admin/reports/nps_courses`
export const ADMIN_REPORTS_CROSS = `${config.BASE_URL}admin/reports/cross_collaboration`
export const ADMIN_REPORTS_EXTERNAL_COURSES = `${config.BASE_URL}admin/reports/external_courses`
export const ADMIN_REPORTS_EXTERNAL_FORMATIONS = `${config.BASE_URL}admin/reports/external_formations`
export const ADMIN_REPORTS_EXTERNAL_COURSES_ROADMAP = `${config.BASE_URL}admin/reports/external_courses_roadmap`
export const ADMIN_REPORTS_COMMISSIONS = `${config.BASE_URL}admin/reports/commissions`
export const ADMIN_REPORTS_ONLINE_COURSES = `${config.BASE_URL}admin/reports/online_courses`
export const ADMIN_REPORTS_ONLINE_COURSES_QUESTIONNAIRE = `${config.BASE_URL}admin/reports/online_courses_questionnaire`
export const ADMIN_REPORTS_CAREER_PLANS = `${config.BASE_URL}admin/reports/career_plans`
export const ADMIN_REPORTS_CAREER_PLAN_COURSES = `${config.BASE_URL}admin/reports/career_plan_courses`
export const ADMIN_REPORTS_USER_CAREER_PLANS = `${config.BASE_URL}admin/reports/user_career_plans`
export const ADMIN_REPORTS_USER_GLOBAL_CAREER_PLANS = `${config.BASE_URL}admin/reports/user_global_career_plans`
export const ADMIN_REPORTS_NPS_COMMENTS = `${config.BASE_URL}admin/reports/nps_comments`
export const ADMIN_REPORTS_NPS_EXTERNAL_COURSES_COMMENTS = `${config.BASE_URL}admin/reports/nps_external_courses_comments`
export const ADMIN_JOBS = `${config.BASE_URL}admin/jobs`

export const ADMIN_EXTERNAL_COURSES = `${config.BASE_URL}admin/external_courses`
export const ADMIN_EXTERNAL_COURSES_ROADMAP = `${config.BASE_URL}admin/external_courses_roadmap`
export const ADMIN_EXTERNAL_COURSES_COMMISSIONS = `${config.BASE_URL}admin/external_courses/:externalCourseId/commissions`
export const ADMIN_COMMISSIONS = `${config.BASE_URL}admin/commissions`
export const ADMIN_FACILITATORS = `${config.BASE_URL}admin/facilitators`
export const ADMIN_REFERENTS_PROVIDERS = `${config.BASE_URL}admin/referents_providers`
export const ADMIN_COMMISSIONS_USERS = `${config.BASE_URL}admin/commissions/:commissionId/users`

export const SEARCH = `${config.BASE_URL}search`

export const router = {
  getRoute: (route, params) => {
    const keys = Object.keys(params)
    let url = route
    const query = []
    keys.forEach(k => {
      const newUrl = url.replace(`:${k}`, params[k])
      if (url === newUrl) {
        query.push(`${k}=${params[k]}`)
      } else {
        url = newUrl
      }
    })
    if (query.length > 0) {
      url += `?${query.join('&')}`
    }
    return url
  },
}
