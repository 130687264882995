import React, { Component } from 'react';
import GenericDialog from '../ui/GenericDialog';
import { Button, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Moment from 'moment';
import UserAvatar from '../ui/UserAvatar';

const styles = () => ({
  content: {
    minHeight: 150,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    margin: 'auto',
    padding: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  leftCol: {
    float: 'left',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  comment: {
    fontSize: 12,
    color: '#707070',
    fontStyle: 'italic',
    marginBottom: 15
  },
  date: {
    fontSize: 12,
    color: '#9a9a9a',
    paddingLeft: 15
  },
  userInfo: {
    marginBottom: 8,
  },
  userAvatar: {
    float: 'left',
  },
  userName: {
    float: 'left',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 14,
  },
  rating: {
    marginTop: 18,
  },
  totals: {
    minHeight: 50,
    marginTop: 10,
  },
  bigStars: {
    fontSize: 24,
    float: 'left',
  },
  stars: {
    float: 'left',
  },
  star: {
    fontSize: 16,
    float: 'left',
    marginTop: 0,
    marginRight: 3
  },
  npsScoreTitle: {
    color: '#a1a1a1',
    float: 'left',
    fontSize: 12,
    marginTop: 1,
  },
  ratingsCount: {
    marginLeft: 11,
    color: '#a1a1a1',
    float: 'left',
    fontSize: 12,
    marginTop: 0,
    textDecoration: 'underline',
  },
  userNpsScore: {
    fontSize: 12,
    color: '#9a9a9a',
    marginTop: 18,
  },
  contentTitle: {
    color: '#777777',
    paddingBottom: 11,
    borderBottom: '1px solid #a1a1a1',
    fontSize: 16,
    fontWeight: 700,
  },
  buttonRate: {
    color: '#a1a1a1',
    borderWidth: 1,
    borderColor: '#a1a1a1',
    borderStyle: 'solid',
    fontSize: 12,
  },
  itemComments: {
    display: 'flex',
    marginBottom: 15,
  },
  userComment: {
    fontSize: 12,
    color: '#9a9a9a',
    marginLeft: 15,
    marginTop: 3,
    width: '100%'
  },
  dateComment: {
    fontSize: 12,
    color: '#9a9a9a',
    right: 30,
    //marginTop: 3,
    position: 'absolute'
  },
  avatar: {
    heigth: 35,
    width: 35
  },
  nameSender: {
    marginBottom: 1,
    fontSize: 12,
    color: '#9a9a9a',
    marginLeft: 15,
    width: '100%'
  },
  commentsSection: {
    display: 'block'
  },
  buttonLabel: {
    textTransform: 'none',
  },
  actionRemove: {
    maxWidth: "50%",
    alignSelf: "flex-end",
    color: '#575454',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
});

class CourseSharedShowDialog extends Component {

  renderReviewItem = (item) => {
    const { classes } = this.props;
    return (
      <div key={item.id} className={classes.row}>
        <div className={classes.leftCol}>
          <Typography className={classes.comment}>"{item.comment}"</Typography>
        </div>
        <div className={classes.rightCol}>
          <Typography className={classes.date}>{Moment(item.created_at).format('DD-MM-YYYY')}</Typography>
          {/* <Typography className={classes.date}>{ item.created_at }</Typography> */}
        </div>
      </div>
    );
  };

  removeSharedCourse = (e) => {
    e.preventDefault();
    const { onRemoveSharedCourse } = this.props;
    onRemoveSharedCourse();
    e.stopPropagation();
  }

  renderContent = () => {
    const {
      classes,
      sharedCourses
    } = this.props;

    return (
      <div className={classes.content}>
        {sharedCourses && sharedCourses[0].first_name &&
          <div>
            {sharedCourses.map((item, index) => {
              return (
                <div className={classes.itemComments} key={index}>
                  <UserAvatar mini={true} user={item} />
                  <Typography className={classes.dateComment}>
                    {Moment(item.created_at).format('DD-MM-YYYY')}
                  </Typography>
                  <div className={classes.commentsSection}>
                    <Typography className={classes.nameSender}>
                      <strong>{item.first_name}, {item.last_name}</strong>
                    </Typography>

                    <Typography className={classes.userComment}>
                      {item.comments ?? '-'}
                    </Typography>
                  </div>
                </div>
              )
            })
            }
          </div>
        }
        {sharedCourses && sharedCourses[0].sender &&
          <div>
            {sharedCourses.map((item) => {
              return (
                <div className={classes.itemComments} key={'key-sender-' + item.id}>
                  <UserAvatar mini={true} user={item.sender} />
                  <Typography className={classes.dateComment}>
                    {Moment(item.created_at).format('DD-MM-YYYY')}
                  </Typography>
                  <div className={classes.commentsSection}>
                    <Typography className={classes.nameSender}>
                      <strong>{item.sender.first_name}, {item.sender.last_name}</strong>
                    </Typography>

                    <Typography className={classes.userComment}>
                      {item.comments}
                    </Typography>
                  </div>
                </div>
              )
            })
            }
          </div>
        }

        <Button
          variant={'outlined'}
          className={classes.actionRemove}
          classes={{ label: classes.buttonLabel }}
          onClick={(e) => this.removeSharedCourse(e)}
        >
          {'Descartar recomendación'}
        </Button>

      </div>
    );
  };

  render() {
    const { open, onDismiss, headerColor } = this.props;
    return (
      <GenericDialog
        miniHeader={true}
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        name={'TE LO RECOMENDARON'}
        icon={null}
        title={null}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}

export default withStyles(styles)(CourseSharedShowDialog);
