import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  input: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    fontWeight: 400,
    fontFamily: 'Open Sans',
    fontSize: 14,
  },
  helptext: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    marginTop: 2,
    fontWeight: 400,
    fontSize: 10,
  },
})

class FileInput extends Component {
  handleOnChange = event => {
    const { name, onChange } = this.props
    const value = event.target.files
    onChange(name, value)
  }

  render() {
    const { name, accept, value, placeholder, classes, helpText, style, ...others } = this.props
    delete others['onChange']
    return (
      <>
        <input
          accept={accept}
          className={classes.input}
          type="file"
          name={name}
          style={{ minWidth: '100%', ...style }}
          onChange={this.handleOnChange}
        />
        {helpText && <Typography className={classes.helptext}>{helpText}</Typography>}
      </>
    )
  }
}

export default withStyles(styles)(FileInput)
