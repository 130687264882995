import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import HorizontalSliderArrow from './HorizontalSliderArrow'
import { HorizontalSlideArrowType } from '../../config/constants'
import classNames from 'classnames'
import EventListenerWrapper from './EventListenerWrapper'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    title: {
      fontSize: 24,
      display: 'inline-block',
      color: '#fff',
      paddingTop: 16,
      [mediaLg]: {
        marginLeft: 16,
        fontSize: 16,
      },
    },
    zoomSlider: {
      gridColumn: '2 / 13',
      color: '#fff',
      '&__container::-webkit-scrollbar': {
        display: 'none',
      },
      '&_arrow': {},
      '&__container': {
        position: 'relative',
        scrollbarWidth: 'none',
        transition: 'transform 450ms',
        overflowY: 'hidden',
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
        display: 'flex',
        width: '100%',
        margin: 'auto',
        //gridTemplateColumns: 'repeat(20, 1fr)',
        columnGap: 0,
        '&:hover &--item': {
          opacity: 0.3,
          '&:hover': {
            transform: 'scale(1.25)',
            opacity: 1,
          },
        },
        '&:hover &--item:first-child:last-child': {
          paddingLeft: 250,
        },
        '&:hover &--item:first-child': {
          paddingLeft: 90,
        },
        '&:hover &--item:last-child': {
          paddingRight: 645,
        },
        '&:hover > *': {
          transform: 'translate3d(-90px, 0, 0)',
        },
        '&--item:hover ~ &--item': {
          transform: 'translate3d(90px, 0, 0)',
        },

        '&:focus &--item': {
          opacity: 0.3,
          '&:focus': {
            transform: 'scale(1.25)',
            opacity: 1,
          },
        },
        '&:focus &--item:first-child:last-child': {
          paddingLeft: 250,
        },
        '&:focus &--item:first-child': {
          paddingLeft: 90,
        },
        '&:focus &--item:last-child': {
          paddingRight: 645,
        },
        '&:focus > *': {
          transform: 'translate3d(-90px, 0, 0)',
        },
        '&--item:focus ~ &--item': {
          transform: 'translate3d(90px, 0, 0)',
        },
        '&--item': {
          position: 'relative',
          cursor: 'pointer',
          transition: 'all 450ms',
          transform: 'center left',
          paddingTop: 40,
          paddingBottom: 40,
          height: 180,
          minHeight: 0,
          [mediaLg]: {
            paddingTop: 16,
          },
          '&:not(:last-child)': {
            paddingRight: 4,
          },
          '&-image': {
            height: '100%',
          },
        },
      },
      [mediaLg]: {
        marginTop: 40,
      },
    },
    wrapper: {
      position: 'relative',
      '-ms-overflow-style': 'none',
    },
    arrow: {
      position: 'absolute',
    },
    arrowRight: {
      position: 'absolute',
      right: 0,
    },
  }
}

class HorizontalTopTen extends Component {
  constructor(props) {
    super(props)
    this.myScrollContainerRef = React.createRef()
    this.state = { rightVisible: false, leftVisible: false }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateArrows)
    this.myScrollContainerRef.current.addEventListener('scroll', this.updateArrows)
    this.updateArrows()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateArrows)
    this.myScrollContainerRef.current.removeEventListener('scroll', this.updateArrows)
  }

  getItems = items => {
    const { classes, itemCreator = () => null, itemWidth = 420, itemHeight = 320 } = this.props

    return items.map((i, index) => {
      return (
        <EventListenerWrapper
          tabIndex={0}
          key={'htt-item-' + index}
          className={classes.zoomSlider + '__container--item'}
          style={{ width: itemWidth, minWidth: itemWidth, height: itemHeight, borderRadius: 50 }}
        >
          {itemCreator(i)}
        </EventListenerWrapper>
      )
    })
  }

  scrollLeft = (element, change, duration) => {
    var start = element.scrollLeft,
      currentTime = 0,
      increment = 20
    const delta = this.calcMaxDelta(change)
    var animateScroll = () => {
      currentTime += increment
      const val = this.easeInOutQuad(currentTime, start, delta, duration)
      element.scrollLeft = val
      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
    }
    animateScroll()
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  easeInOutQuad = (t, b, c, d) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  slugify = str =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')

  handleScrollRight = () => {
    const view = this.myScrollContainerRef.current
    this.scrollLeft(view, 700, 400)
  }

  handleScrollLeft = () => {
    const view = this.myScrollContainerRef.current
    this.scrollLeft(view, -500, 400)
  }

  updateArrows = () => {
    const view = this.myScrollContainerRef.current
    const visibleWidth = view.clientWidth
    const totalWidth = view.scrollWidth
    const totalScrolled = view.scrollLeft
    const leftVisible = totalScrolled !== 0
    const rightVisible = visibleWidth + totalScrolled < totalWidth
    this.setState({ rightVisible, leftVisible })
  }

  calcMaxDelta = delta => {
    const view = this.myScrollContainerRef.current
    const totalScrolled = view.scrollLeft
    const variation = totalScrolled + delta
    const totalWidth = view.scrollWidth - view.clientWidth
    if (variation < 0) {
      return -totalScrolled
    } else if (variation > totalWidth) {
      return totalWidth - totalScrolled
    }
    return delta
  }

  render() {
    const { classes, items, title, customStyleTitle, icon = null } = this.props
    const slug = this.slugify(title)
    return (
      <section className={classes.zoomSlider} id={slug}>
        {(title || icon) && (
          <div>
            <Typography
              component={'h1'}
              tabIndex={0}
              className={`${classes.title} ${customStyleTitle}`}
            >
              {icon}
              {title}
            </Typography>
          </div>
        )}
        <div className={classes.wrapper}>
          {this.state.leftVisible && (
            <HorizontalSliderArrow
              className={classes.arrow}
              type={HorizontalSlideArrowType.left}
              onClick={this.handleScrollLeft}
            />
          )}
          {this.state.rightVisible && (
            <HorizontalSliderArrow
              className={classNames(classes.arrow, classes.arrowRight)}
              type={HorizontalSlideArrowType.right}
              onClick={this.handleScrollRight}
            />
          )}
          <div className={classes.zoomSlider + '__container'} ref={this.myScrollContainerRef}>
            {this.getItems(items)}
          </div>
        </div>
      </section>
    )
  }
}

export default withStyles(styles)(HorizontalTopTen)
