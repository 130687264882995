import {
  ADMIN_RESOURCE_READ_START,
  ADMIN_RESOURCE_READ_END,
  ADMIN_RESOURCE_READ_FAILED,
  ADMIN_RESOURCE_UPDATED,
} from '../../actions';

let initialState = {
  item: null,
  key: '',
  error: null,
  loading: false,
};

let states = {};

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key];
  } else {
    states[key] = { ...initialState, key: key };
    return states[key];
  }
}

export default (state = initialState, action) => {
  const key = action.key || state.key || '';
  const currentState = getStateFor(key);
  switch (action.type) {
    case ADMIN_RESOURCE_UPDATED:
      const { item } = action.payload;
      states[key] = {
        ...currentState,
        item: item,
        error: null,
        loading: false,
      };
      return states;
    case ADMIN_RESOURCE_READ_START:
      states[key] = {
        ...currentState,
        item: null,
        error: null,
        loading: true,
      };
      return states;
    case ADMIN_RESOURCE_READ_END: {
      const { item } = action.payload;
      states[key] = {
        ...currentState,
        item: item,
        error: null,
        loading: false,
      };
      return states;
    }
    case ADMIN_RESOURCE_READ_FAILED:
      const { message } = action.payload;
      states[key] = {
        ...currentState,
        item: null,
        error: message,
        loading: false,
      };
      return states;
    default:
      states[key] = { ...currentState };
      return states;
  }
};
