import React, { Component } from 'react'
import { Dialog, DialogContent, IconButton, withStyles } from '@material-ui/core'
import ActivityModalContent from './ActivityModalContent'
import { ActivitiesTypesList, ActivityType } from '../../../../config/constants'
import ActivityValidator from '../ActivityValidation/ActivityValidationByFormType'
import CloseIcon from '@material-ui/icons/Close'

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'right',
    width: '100%',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
  },
}

class ActivityModal extends Component {
  state = {
    content: null,
    results: {
      isValid: false,
      errors: {},
    },
  }

  validateForm = () => {
    const { activity } = this.props
    const { content } = this.state
    if (!content.description) {
      this.setState({ content: { ...content, description: '' } })
    }

    let results
    if (activity.type === ActivityType.Select) {
      results = ActivityValidator[content.selectedType](content)
    } else {
      results = ActivityValidator[activity.type](content)
    }
    this.setState({ results })
  }

  handleChange = content => {
    this.setState({ content }, this.validateForm)
  }

  handleSubmit = () => {
    const { activity, onSubmit, onClose } = this.props
    // eslint-disable-next-line no-unused-vars
    const { results, blockId, ...content } = this.state.content
    const dataToSubmit = {
      ...activity,
      content,
      type: content.type,
    }
    if (dataToSubmit.id < 0) delete dataToSubmit.id
    onSubmit(dataToSubmit)

    this.setState({
      content: null,
      results: {
        isValid: false,
        errors: {},
      },
    })
    onClose()
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({
      content: null,
      results: {
        isValid: false,
        errors: {},
      },
    })
    onClose()
  }

  render() {
    const { activity, onClose, classes } = this.props
    const { content, results } = this.state
    const activityName =
      activity && activity.type
        ? ActivitiesTypesList.find(activityType => activityType.type === activity.type).description
        : 'Actividad'

    return (
      <Dialog
        disableEnforceFocus={true}
        open={activity !== null}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent className={classes.content}>
          <ActivityModalContent
            activity={content ? { ...activity, ...content, results } : { ...activity, results }}
            onChange={this.handleChange}
            onCancel={this.handleClose}
            onSubmit={this.handleSubmit}
            actionButtons="2"
            title={
              <div className={classes.header}>
                {activity && activity.id > 0
                  ? `Editar actividad de ${activityName}`
                  : `Nueva actividad de ${activityName}`}
                <IconButton className={classes.closeIcon} onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            }
          />
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ActivityModal)
