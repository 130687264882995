export const KEY_KEYS = {
  ESCAPE: 'Escape',
  TAB: 'Tab',
  ENTER: 'Enter',
}

export const COLOR_KEYS = {
  PRIMARY: '#3ABF91',
}

export const INPUT_INT_NUMBER_ACCEPT_KEYS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
]

export const markForTrueOrFalse = ['0', '1']
