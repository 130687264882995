export const USER_LOGIN_END = 'USER_LOGIN_END'
export const USER_LOGIN_START = 'USER_LOGIN_START'
export const USER_LOGIN_START_OB = 'USER_LOGIN_START_OB'
export const USER_LOGIN_START_OB_QR = 'USER_LOGIN_START_OB_QR'
export const USER_QR = 'USER_QR'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'

export const USER_LOGOUT_END = 'USER_LOGOUT_END'
export const USER_LOGOUT_START = 'USER_LOGOUT_START'
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED'

export const USER_READ_END = 'USER_READ_END'
export const USER_READ_START = 'USER_READ_START'
export const USER_READ_FAILED = 'USER_READ_FAILED'

export const FILTER_TEMPLATE_READ_START = 'FILTER_TEMPLATE_READ_START'
export const FILTER_TEMPLATE_READ_END = 'FILTER_TEMPLATE_READ_END'
export const FILTER_TEMPLATE_READ_FAILED = 'FILTER_TEMPLATE_READ_FAILED'

export const APP_LOAD_TOKEN = 'APP_LOAD_TOKEN'
export const AUTH_ERROR = 'AUTH_ERROR'
export const GENERIC_ERROR = 'GENERIC_ERROR'

export const MENU_DRAWER_TOGGLE = 'MENU_DRAWER_TOGGLE'

export const EDIT_ROW_INIT = 'EDIT_ROW_INIT'
export const EDIT_ROW_TOGGLE_EDITING = 'EDIT_ROW_TOGGLE_EDITING'
export const EDIT_ROW_CHANGE_VALUE = 'EDIT_ROW_CHANGE_VALUE'
export const EDIT_ROW_START_SAVE = 'EDIT_ROW_START_SAVE'
export const EDIT_ROW_END_SAVE = 'EDIT_ROW_END_SAVE'
export const EDIT_ROW_FAIL_SAVE = 'EDIT_ROW_FAIL_SAVE'

export const LAZY_SELECT_INPUT_INIT = 'LAZY_SELECT_INPUT_INIT'
export const LAZY_SELECT_INPUT_FETCH_START = 'LAZY_SELECT_INPUT_FETCH_START'
export const LAZY_SELECT_INPUT_FETCH_END = 'LAZY_SELECT_INPUT_FETCH_END'
export const LAZY_SELECT_INPUT_FETCH_FAIL = 'LAZY_SELECT_INPUT_FETCH_FAIL'

export const PAGINATOR_PAGINATE_NEXT = 'PAGINATOR_PAGINATE_NEXT'
export const PAGINATOR_PAGINATE_TO = 'PAGINATOR_PAGINATE_TO'
export const PAGINATOR_PAGINATE_PREV = 'PAGINATOR_PAGINATE_PREV'
export const PAGINATOR_PAGINATE_START = 'PAGINATOR_PAGINATE_START'
export const PAGINATOR_INIT = 'PAGINATOR_INIT'
export const PAGINATOR_CHANGE_QUERY = 'PAGINATOR_CHANGE_QUERY'
export const PAGINATOR_PAGINATE_FAILED = 'PAGINATOR_FAILED'
export const PAGINATOR_PAGINATE_END = 'PAGINATOR_PAGINATE_END'
export const PAGINATOR_UPDATE_ITEM = 'PAGINATOR_UPDATE_ITEM'
export const PAGINATOR_REFRESH = 'PAGINATOR_REFRESH'

export const INFINITE_PAGINATE_LOAD_NEXT = 'INFINITE_PAGINATE_LOAD_NEXT'
export const INFINITE_PAGINATE_START = 'INFINITE_PAGINATE_START'
export const INFINITE_INIT = 'INFINITE_INIT'
export const INFINITE_RESET = 'INFINITE_RESET'
export const INFINITE_PAGINATE_FAILED = 'INFINITE_PAGINATE_FAILED'
export const INFINITE_PAGINATE_END = 'INFINITE_PAGINATE_END'
export const INFINITE_PAGINATE_UPDATE_ITEM = 'INFINITE_PAGINATE_UPDATE_ITEM'
export const INFINITE_PAGINATE_UPDATE_ITEMS = 'INFINITE_PAGINATE_UPDATE_ITEMS'

export const SEARCH_REQUEST_END = 'SEARCH_REQUEST_END'
export const SEARCH_REQUEST_START = 'SEARCH_REQUEST_START'
export const SEARCH_REQUEST_CHANGE_QUERY = 'SEARCH_REQUEST_CHANGE_QUERY'
export const SEARCH_REQUEST_FAILED = 'SEARCH_REQUEST_FAILED'
export const SEARCH_REQUEST_CLEAR = 'SEARCH_REQUEST_CLEAR'
export const SEARCH_REQUEST_INIT = 'SEARCH_REQUEST_INIT'

/* eslint operator-linebreak: ["error", "after"] */
export const CREATE_REQUEST_START = 'CREATE_REQUEST_START'
export const CREATE_REQUEST_END = 'CREATE_REQUEST_END'
export const CREATE_REQUEST_FAILED = 'CREATE_REQUEST_FAILED'

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN'
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE'

export const TOPTEN_COURSES_READ_START = 'TOPTEN_COURSES_READ_START'
export const TOPTEN_COURSES_READ_END = 'TOPTEN_COURSES_READ_END'
export const TOPTEN_COURSES_READ_FAILED = 'TOPTEN_COURSES_READ_FAILED'

export const ADMIN_RESOURCE_READ_END = 'ADMIN_RESOURCE_READ_END'
export const ADMIN_RESOURCE_READ_START = 'ADMIN_RESOURCE_READ_START'
export const ADMIN_RESOURCE_READ_FAILED = 'ADMIN_RESOURCE_READ_FAILED'
export const ADMIN_RESOURCE_UPDATED = 'ADMIN_RESOURCE_UPDATED'

export const TOPTEN_COURSES_READ_START_ADMIN = 'TOPTEN_COURSES_READ_START_ADMIN'
export const TOPTEN_COURSES_READ_END_ADMIN = 'TOPTEN_COURSES_READ_END_ADMIN'
export const TOPTEN_COURSES_READ_FAILED_ADMIN = 'TOPTEN_COURSES_READ_FAILED_ADMIN'

export const REQUIRED_COURSES_READ_START = 'REQUIRED_COURSES_READ_START'
export const REQUIRED_COURSES_READ_END = 'REQUIRED_COURSES_READ_END'
export const REQUIRED_COURSES_READ_FAILED = 'REQUIRED_COURSES_READ_FAILED'

export const OBJECTIVE_D_PLUS_I_COURSES_READ_START = 'OBJECTIVE_D_PLUS_I_COURSES_READ_START'
export const OBJECTIVE_D_PLUS_I_COURSES_READ_END = 'OBJECTIVE_D_PLUS_I_COURSES_READ_END'
export const OBJECTIVE_D_PLUS_I_COURSES_READ_FAILED = 'OBJECTIVE_D_PLUS_I_COURSES_READ_FAILED'

export const VARIABLE_PRICE_COURSES_READ_START = 'VARIABLE_PRICE_COURSES_READ_START'
export const VARIABLE_PRICE_COURSES_READ_END = 'VARIABLE_PRICE_COURSES_READ_END'
export const VARIABLE_PRICE_COURSES_READ_FAILED = 'VARIABLE_PRICE_COURSES_READ_FAILED'

export const FEATURED_CATEGORIES_READ_START = 'FEATURED_CATEGORIES_READ_START'
export const FEATURED_CATEGORIES_READ_END = 'FEATURED_CATEGORIES_READ_END'
export const FEATURED_CATEGORIES_READ_FAILED = 'FEATURED_CATEGORIES_READ_FAILED'

export const SEARCH_COURSES_CLEAR = 'SEARCH_COURSES_CLEAR'
export const SEARCH_COURSES_READ_START = 'SEARCH_COURSES_READ_START'
export const SEARCH_COURSES_READ_END = 'SEARCH_COURSES_READ_END'
export const SEARCH_COURSES_READ_FAILED = 'SEARCH_COURSES_READ_FAILED'

export const MY_PROFILE_END = 'MY_PROFILE_END'
export const MY_PROFILE_START = 'MY_PROFILE_START'
export const MY_PROFILE_FAILED = 'MY_PROFILE_FAILED'

export const MY_SELECTED_USERS_END = 'MY_SELECTED_USERS_END'
export const MY_SELECTED_USERS_START = 'MY_SELECTED_USERS_START'
export const MY_SELECTED_USERS_FAILED = 'MY_SELECTED_USERS_FAILED'

export const MY_COURSES_IN_PROGRESS_READ_START = 'MY_COURSES_IN_PROGRESS_READ_START'
export const MY_COURSES_IN_PROGRESS_READ_END = 'MY_COURSES_IN_PROGRESS_READ_END'
export const MY_COURSES_IN_PROGRESS_READ_FAILED = 'MY_COURSES_IN_PROGRESS_READ_FAILED'

export const MY_WISHED_COURSES_READ_START = 'MY_WISHED_COURSES_READ_START'
export const MY_WISHED_COURSES_READ_END = 'MY_WISHED_COURSES_READ_END'
export const MY_WISHED_COURSES_READ_FAILED = 'MY_WISHED_COURSES_READ_FAILED'

export const MY_SHARED_COURSES_READ_START = 'MY_SHARED_COURSES_READ_START'
export const MY_SHARED_COURSES_READ_END = 'MY_SHARED_COURSES_READ_END'
export const MY_SHARED_COURSES_READ_FAILED = 'MY_SHARED_COURSES_READ_FAILED'

export const MY_COURSES_FINISHED_READ_START = 'MY_COURSES_FINISHED_READ_START'
export const MY_COURSES_FINISHED_READ_END = 'MY_COURSES_FINISHED_READ_END'
export const MY_COURSES_FINISHED_READ_FAILED = 'MY_COURSES_FINISHED_READ_FAILED'

export const MY_COURSES_HIDDEN_READ_START = 'MY_COURSES_HIDDEN_READ_START'
export const MY_COURSES_HIDDEN_READ_END = 'MY_COURSES_HIDDEN_READ_END'
export const MY_COURSES_HIDDEN_READ_FAILED = 'MY_COURSES_HIDDEN_READ_FAILED'

export const DOWNLOAD_CERTIFICATE_START = 'DOWNLOAD_CERTIFICATE_START'
export const DOWNLOAD_CERTIFICATE_END = 'DOWNLOAD_CERTIFICATE_END'
export const DOWNLOAD_CERTIFICATE_FAILED = 'DOWNLOAD_CERTIFICATE_FAILED'

export const MY_CAREER_PLANS_START = 'MY_CAREER_PLANS_START'
export const MY_CAREER_PLANS_END = 'MY_CAREER_PLANS_END'
export const MY_CAREER_PLANS_FAILED = 'MY_CAREER_PLANS_FAILED'
export const MY_PREVIOUS_CAREER_PLANS_START = 'MY_PREVIOUS_CAREER_PLANS_START'
export const MY_PREVIOUS_CAREER_PLANS_END = 'MY_PREVIOUS_CAREER_PLANS_END'
export const MY_PREVIOUS_CAREER_PLANS_FAILED = 'MY_PREVIOUS_CAREER_PLANS_FAILED'

export const CATEGORY_READ_START = 'CATEGORY_READ_START'
export const CATEGORY_READ_END = 'CATEGORY_READ_END'
export const CATEGORY_READ_FAILED = 'CATEGORY_READ_FAILED'

export const CATEGORIES_READ_START = 'CATEGORIES_READ_START'
export const CATEGORIES_READ_END = 'CATEGORIES_READ_END'
export const CATEGORIES_READ_FAILED = 'CATEGORIES_READ_FAILED'

export const COURSE_READ_END = 'COURSE_READ_END'
export const COURSE_READ_FAILED = 'COURSE_READ_FAILED'
export const COURSE_READ_START = 'COURSE_READ_START'
export const COURSE_UPDATE_END = 'COURSE_UPDATE_END'

export const EXTERNAL_COURSE_READ_END = 'EXTERNAL_COURSE_READ_END'
export const EXTERNAL_COURSE_READ_FAILED = 'EXTERNAL_COURSE_READ_FAILED'
export const EXTERNAL_COURSE_READ_START = 'EXTERNAL_COURSE_READ_START'
export const EXTERNAL_COURSE_UPDATE_END = 'EXTERNAL_COURSE_UPDATE_END'

export const CAREER_PLANS_CATEGORIES_READ_START = 'CAREER_PLANS_CATEGORIES_START'
export const CAREER_PLANS_CATEGORIES_READ_END = 'CAREER_PLANS_CATEGORIES_END'
export const CAREER_PLANS_CATEGORIES_READ_FAILED = 'CAREER_PLANS_CATEGORIES_FAILED'

export const CAREER_PLANS_COURSE_READ_START = 'CAREER_PLANS_COURSE_READ_START'
export const CAREER_PLANS_COURSE_READ_END = 'CAREER_PLANS_COURSE_READ_END'
export const CAREER_PLANS_COURSE_READ_FAILED = 'CAREER_PLANS_COURSE_READ_FAILED'

export const EXPLORE_CAREER_PLANS_START = 'EXPLORE_CAREER_PLANS_START'
export const EXPLORE_CAREER_PLANS_END = 'EXPLORE_CAREER_PLANS_END'
export const EXPLORE_CAREER_PLANS_FAILED = 'EXPLORE_CAREER_PLANS_FAILED'
export const EXPLORE_CAREER_PLANS_CHANNELS_START = 'EXPLORE_CAREER_PLANS_CHANNELS_START'
export const EXPLORE_CAREER_PLANS_CHANNELS_END = 'EXPLORE_CAREER_PLANS_CHANNELS_END'
export const EXPLORE_CAREER_PLANS_CHANNELS_FAILED = 'EXPLORE_CAREER_PLANS_CHANNELS_FAILED'

export const DETAIL_CAREER_PLANS_START = 'DETAIL_CAREER_PLANS_START'
export const DETAIL_CAREER_PLANS_END = 'DETAIL_CAREER_PLANS_END'
export const DETAIL_CAREER_PLANS_FAILED = 'DETAIL_CAREER_PLANS_FAILED'

export const EXPLORE_CAREER_PLANS_BY_CHANNEL_START = 'EXPLORE_CAREER_PLANS_BY_CHANNEL_START'
export const EXPLORE_CAREER_PLANS_BY_CHANNEL_END = 'EXPLORE_CAREER_PLANS_BY_CHANNEL_END'
export const EXPLORE_CAREER_PLANS_BY_CHANNEL_FAILED = 'EXPLORE_CAREER_PLANS_BY_CHANNEL_FAILED'

export const CAREER_PLANS_PREVIOUS_COURSE_READ_START = 'CAREER_PLANS_PREVIOUS_COURSE_READ_START'
export const CAREER_PLANS_PREVIOUS_COURSE_READ_END = 'CAREER_PLANS_PREVIOUS_COURSE_READ_END'
export const CAREER_PLANS_PREVIOUS_COURSE_READ_FAILED = 'CAREER_PLANS_PREVIOUS_COURSE_READ_FAILED'

// SE DECLARAN ACTIONS DE ACTIVIDADES
export const ACTIVITY_READ_START = 'ACTIVITY_READ_START'
export const ACTIVITY_READ_END = 'ACTIVITY_READ_END'
export const ACTIVITY_READ_FAILED = 'ACTIVITY_READ_FAILED'

export const CAREER_PLAN_READ_START = 'CAREER_PLAN_READ_START'
export const CAREER_PLAN_READ_END = 'CAREER_PLAN_READ_END'
export const CAREER_PLAN_READ_FAILED = 'CAREER_PLAN_READ_FAILED'

// declare carrer plans actions
export const NEW_CAREER_PLANS_CHANNELS_READ_START = 'NEW_CAREER_PLANS_CHANNELS_READ_START'
export const NEW_CAREER_PLANS_CHANNELS_READ_END = 'NEW_CAREER_PLANS_CHANNELS_READ_END'
export const NEW_CAREER_PLANS_CHANNELS_READ_FAILED = 'NEW_CAREER_PLANS_CHANNELS_READ_FAILED'

export const NEW_CAREER_PLANS_SCALES_READ_START = 'NEW_CAREER_PLANS_SCALES_READ_START'
export const NEW_CAREER_PLANS_SCALES_READ_END = 'NEW_CAREER_PLANS_SCALES_READ_END'
export const NEW_CAREER_PLANS_SCALES_READ_FAILED = 'NEW_CAREER_PLANS_SCALES_READ_FAILED'

// Create new career plan
export const CREATE_NEW_CAREER_PLAN_READ_START = 'CREATE_NEW_CAREER_PLAN_READ_START'
export const CREATE_NEW_CAREER_PLAN_READ_END = 'CREATE_NEW_CAREER_PLAN_READ_END'
export const CREATE_NEW_CAREER_PLAN_READ_FAILED = 'CREATE_NEW_CAREER_PLAN_READ_FAILED'

// Get associates courses new career plan
export const GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_START =
  'GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_START'
export const GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_END =
  'GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_END'
export const GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_FAILED =
  'GET_ASSOCIATES_COURSES_NEW_CAREER_PLAN_READ_FAILED'

//Get josb positions new career plans

export const GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_START =
  'GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_START'
export const GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_END =
  'GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_END'
export const GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_FAILED =
  'GET_JOB_POSITIONS_NEW_CAREER_PLAN_READ_FAILED'

//Get organizations units
export const GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_START =
  'GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_START'
export const GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_END =
  'GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_END'
export const GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_FAILED =
  'GET_ORGANIZATIONS_UNITS_NEW_CAREER_PLAN_READ_FAILED'

// declare lots actions

// Create new lot
export const CREATE_NEW_LOT_READ_START = 'CREATE_NEW_LOT_READ_START'
export const CREATE_NEW_LOT_READ_END = 'CREATE_NEW_LOT_READ_END'
export const CREATE_NEW_LOT_READ_FAILED = 'CREATE_NEW_LOT_READ_FAILED'

// Get associates users new lot
export const GET_ASSOCIATES_USERS_NEW_LOT_READ_START = 'GET_ASSOCIATES_USERS_NEW_LOT_READ_START'
export const GET_ASSOCIATES_USERS_NEW_LOT_READ_END = 'GET_ASSOCIATES_USERS_NEW_LOT_READ_END'
export const GET_ASSOCIATES_USERS_NEW_LOT_READ_FAILED = 'GET_ASSOCIATES_USERS_NEW_LOT_READ_FAILED'

// Get associates courses new lot
export const GET_ASSOCIATES_COURSES_NEW_LOT_READ_START = 'GET_ASSOCIATES_COURSES_NEW_LOT_READ_START'
export const GET_ASSOCIATES_COURSES_NEW_LOT_READ_END = 'GET_ASSOCIATES_COURSES_NEW_LOT_READ_END'
export const GET_ASSOCIATES_COURSES_NEW_LOT_READ_FAILED =
  'GET_ASSOCIATES_COURSES_NEW_LOT_READ_FAILED'

//Get josb positions new lots
export const GET_JOB_POSITIONS_NEW_LOT_READ_START = 'GET_JOB_POSITIONS_NEW_LOT_READ_START'
export const GET_JOB_POSITIONS_NEW_LOT_READ_END = 'GET_JOB_POSITIONS_NEW_LOT_READ_END'
export const GET_JOB_POSITIONS_NEW_LOT_READ_FAILED = 'GET_JOB_POSITIONS_NEW_LOT_READ_FAILED'

//Get josb positions new lots
export const GET_COMPANY_NEW_LOT_READ_START = 'GET_COMPANY_NEW_LOT_READ_START'
export const GET_COMPANY_NEW_LOT_READ_END = 'GET_COMPANY_NEW_LOT_READ_END '
export const GET_COMPANY_NEW_LOT_READ_FAILED = 'GET_COMPANY_NEW_LOT_READ_FAILED'

//Get organizations units
export const GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_START =
  'GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_START'
export const GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_END = 'GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_END'
export const GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_FAILED =
  'GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_FAILED'

export const ADMIN_FILES_DOWNLOAD_START = 'ADMIN_FILES_DOWNLOAD_START'
export const ADMIN_FILES_DOWNLOAD_END = 'ADMIN_FILES_DOWNLOAD_END'
export const ADMIN_FILES_DOWNLOAD_FAILED = 'ADMIN_FILES_DOWNLOAD_FAILED'

export const EXTERNAL_COURSE_COURSES_START = 'EXTERNAL_COURSE_COURSES_START'
export const EXTERNAL_COURSE_COURSES_END = 'EXTERNAL_COURSE_COURSES_END'
export const EXTERNAL_COURSE_COURSES_FAILED = 'EXTERNAL_COURSE_COURSES_FAILED'

export const EXTERNAL_COURSE_ALL_START = 'EXTERNAL_COURSE_ALL_START'
export const EXTERNAL_COURSE_ALL_END = 'EXTERNAL_COURSE_ALL_END'
export const EXTERNAL_COURSE_ALL_FAILED = 'EXTERNAL_COURSE_ALL_FAILED'

export const SW_INIT = 'SW_INIT'
export const SW_UPDATE = 'SW_UPDATE'

export const EXTERNAL_COURSE_HOME_READ_END = 'EXTERNAL_COURSE_HOME_READ_END'
export const EXTERNAL_COURSE_HOME_READ_FAILED = 'EXTERNAL_COURSE_HOME_READ_FAILED'
export const EXTERNAL_COURSE_HOME_READ_START = 'EXTERNAL_COURSE_HOME_READ_START'
export const EXTERNAL_COURSE_HOME_UPDATE_END = 'EXTERNAL_COURSE_HOME_UPDATE_END'

export const EXTERNAL_COURSE_CLASSES_READ_END = 'EXTERNAL_COURSE_CLASSES_READ_END'
export const EXTERNAL_COURSE_CLASSES_READ_FAILED = 'EXTERNAL_COURSE_CLASSES_READ_FAILED'
export const EXTERNAL_COURSE_CLASSES_READ_START = 'EXTERNAL_COURSE_CLASSES_READ_START'

export const CALENDAR_READ_START = 'CALENDAR_READ_START'
export const CALENDAR_READ_END = 'CALENDAR_READ_END'
export const CALENDAR_READ_FAILED = 'CALENDAR_READ_FAILED'

export const SLIDES_READ_START = 'SLIDES_READ_START'
export const SLIDES_READ_END = 'SLIDES_READ_END'
export const SLIDES_READ_FAILED = 'SLIDES_READ_FAILED'

export const PLAYLISTS_READ_START = 'PLAYLISTS_READ_START'
export const PLAYLISTS_READ_END = 'PLAYLISTS_READ_END'
export const PLAYLISTS_READ_FAILED = 'PLAYLISTS_READ_FAILED'

export const PLAYLIST_READ_START = 'PLAYLIST_READ_START'
export const PLAYLIST_READ_END = 'PLAYLIST_READ_END'
export const PLAYLIST_READ_FAILED = 'PLAYLIST_READ_FAILED'

export const ADMIN_USER_COMMISSION_READ_END = 'ADMIN_USER_COMMISSION_READ_END'
export const ADMIN_USER_COMMISSION_READ_START = 'ADMIN_USER_COMMISSION_READ_START'
export const ADMIN_USER_COMMISSION_READ_FAILED = 'ADMIN_USER_COMMISSION_READ_FAILED'
export const ADMIN_USER_COMMISSION_UPDATE_END = 'ADMIN_USER_COMMISSION_UPDATE_END'

export const ADMIN_SCORM_CONFIG_READ_START = 'ADMIN_SCORM_CONFIG_READ_START'
export const ADMIN_SCORM_CONFIG_READ_END = 'ADMIN_SCORM_CONFIG_READ_END'
export const ADMIN_SCORM_CONFIG_READ_FAILED = 'ADMIN_SCORM_CONFIG_READ_FAILED'

export const ADMIN_SCORM_EVENTS_READ_START = 'ADMIN_SCORM_EVENTS_READ_START'
export const ADMIN_SCORM_EVENTS_READ_END = 'ADMIN_SCORM_EVENTS_READ_END'
export const ADMIN_SCORM_EVENTS_READ_FAILED = 'ADMIN_SCORM_EVENTS_READ_FAILED'

export const INSTITUTION_READ_START = 'INSTITUTION_READ_START'
export const INSTITUTION_READ_END = 'INSTITUTION_READ_END'
export const INSTITUTION_READ_FAILED = 'INSTITUTION_READ_FAILED'

export const PLATFORM_READ_START = 'PLATFORM_READ_START'
export const PLATFORM_READ_END = 'PLATFORM_READ_END'
export const PLATFORM_READ_FAILED = 'PLATFORM_READ_FAILED'

export const PLACES_READ_START = 'PLACES_READ_START'
export const PLACES_READ_END = 'PLACES_READ_END'
export const PLACES_READ_FAILED = 'PLACES_READ_FAILED'

export const PLACE_READ_START = 'PLACE_READ_START'
export const PLACE_READ_END = 'PLACE_READ_END'
export const PLACE_READ_FAILED = 'PLACE_READ_FAILED'

export const QUESTIONS_TYPES_READ_START = 'QUESTIONS_TYPES_READ_START'
export const QUESTIONS_TYPES_READ_END = 'QUESTIONS_TYPES_READ_END'
export const QUESTIONS_TYPES_READ_FAILED = 'QUESTIONS_TYPES_READ_FAILED'
