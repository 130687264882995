import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createResource, updateResource, getResource } from '../../../actions/admin'
import {
  AdminItemViewType,
  EditInputType,
  SlidesTemplates,
  SlidesTemplateType,
  SnackbarType,
} from '../../../config/constants'
import AdminResourceForm from '../../../components/admin/AdminResourceForm'
import { addSnackbar } from '../../../actions/snackbar'
import { ADMIN_SLIDES } from '../../../config/routes'
import { goTo } from '../../../actions/navigator'
import validator from 'validator'
import { getBase64, getFileAsDataUrl } from '../../../utils/FileHelper'

class AdminSlidesFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      template: null,
      title: '',
      description: '',
      image: null,
      originalImage: null,
      imageData: '',
      logo: null,
      originalLogo: null,
      logoData: '',
      default_logo: '0',
      name_button_one: '',
      url_button_one: '',
      open_new_tab_button_one: '0',
      name_button_two: '',
      url_button_two: '',
      open_new_tab_button_two: '0',
    },
    errors: {
      template: '',
      title: '',
      description: '',
      image: '',
      logo: '',
      name_button_one: '',
      url_button_one: '',
      name_button_two: '',
      url_button_two: '',
    },
  }

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData()
    }
  }

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props
    getResource(keyName, type, params.id)
  }

  componentWillReceiveProps = nextProps => {
    if (!this.props.item && nextProps.item) {
      const {
        template,
        title,
        description,
        image = null,
        logo = null,
        default_logo,
        name_button_one,
        url_button_one,
        open_new_tab_button_one,
        name_button_two,
        url_button_two,
        open_new_tab_button_two,
      } = nextProps.item
      this.setState(
        {
          form: {
            template: template || null,
            title: title || '',
            description: description || '',
            default_logo: default_logo ? '1' : '0',
            originalImage: image,
            originalLogo: logo,
            name_button_one: name_button_one || '',
            name_button_two: name_button_two || '',
            url_button_one: url_button_one || '',
            url_button_two: url_button_two || '',
            open_new_tab_button_one: open_new_tab_button_one ? '1' : '0',
            open_new_tab_button_two: open_new_tab_button_two ? '1' : '0',
          },
        },
        async () => {
          if (logo !== null) {
            await this.loadImageUrlAsUrlData(logo, 'logoData')
          }
          if (image !== null) {
            await this.loadImageUrlAsUrlData(image, 'imageData')
          }
          this.validateForm()
        },
      )
    }
  }

  onAttributeChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } }, () => this.validateForm())

    if (['image', 'logo'].includes(name)) {
      if (value[0]) {
        return this.loadFileAsUrlData(value[0], `${name}Data`)
      } else {
        this.setState({
          form: {
            ...this.state.form,
            [`${name}Data`]:
              this.state.form[`$original${name.charAt(0).toUpperCase() + name.slice(1)}`],
            [name]: null,
          },
        })
      }
    }
  }

  getColumns = () => {
    const columns = [
      {
        title: 'Plantilla',
        source: 'template',
        editable: true,
        editConfig: {
          type: EditInputType.Select,
          name: 'template',
          options: SlidesTemplates,
        },
      },
      {
        title: 'Imagen Fondo',
        source: 'image',
        editable: true,
        editConfig: {
          type: EditInputType.File,
          accept: '.jpg',
          name: 'image',
          helpText:
            'Dimensiones recomendadas 1920px por 500px - Exportado para web en formato .jpg',
        },
      },
    ]
    if (this.state.form.imageData) {
      columns.push({
        title: 'Vista Previa Fondo',
        source: 'imageData',
        editable: false,
        type: AdminItemViewType.Image,
      })
    }

    if(this.state.form.template !== SlidesTemplateType.ImageOnly) {

      if (this.state.form.template === SlidesTemplateType.LogoTextActions) {
        columns.push({
          title: '¿Usar logo de academia?',
          source: 'default_logo',
          editable: true,
          editConfig: {type: EditInputType.Boolean, name: 'default_logo'},
        })
        if (this.state.form.default_logo === '0') {
          columns.push({
            title: 'Logo',
            source: 'logo',
            editable: true,
            editConfig: {
              type: EditInputType.File,
              accept: '.png',
              name: 'logo',
              helpText:
                  'Dimensiones recomendadas  330px por 85px - en formato .png con fondo transparente',
            },
          })

          if (this.state.form.logoData) {
            columns.push({
              title: 'Vista Previa Logo',
              source: 'logoData',
              editable: false,
              type: AdminItemViewType.Image,
            })
          }
        }
      }
      if (
          this.state.form.template !== null &&
          this.state.form.template !== SlidesTemplateType.LogoTextActions
      ) {
        columns.push({
          title: 'Título',
          source: 'title',
          editable: true,
          editConfig: {type: EditInputType.Text, name: 'title'},
        })
      }
      columns.push({
        title: 'Descripción',
        source: 'description',
        editable: true,
        editConfig: {type: EditInputType.Text, name: 'description'},
      })

      columns.push({
        title: 'Texto Botón 1',
        source: 'name_button_one',
        editable: true,
        editConfig: {type: EditInputType.Text, name: 'name_button_one'},
      })
      columns.push({
        title: 'Link Botón 1',
        source: 'url_button_one',
        editable: true,
        editConfig: {type: EditInputType.Text, name: 'url_button_one'},
      })
      columns.push({
        title: '¿Abrir en nueva pestaña?',
        source: 'open_new_tab_button_one',
        editable: true,
        editConfig: {type: EditInputType.Boolean, name: 'open_new_tab_button_one'},
      })

      columns.push({
        title: 'Texto Botón 2',
        source: 'name_button_two',
        editable: true,
        editConfig: {type: EditInputType.Text, name: 'name_button_two'},
      })
      columns.push({
        title: 'Link Botón 2',
        source: 'url_button_two',
        editable: true,
        editConfig: {type: EditInputType.Text, name: 'url_button_two'},
      })
      columns.push({
        title: '¿Abrir en nueva pestaña?',
        source: 'open_new_tab_button_two',
        editable: true,
        editConfig: {type: EditInputType.Boolean, name: 'open_new_tab_button_two'},
      })
    }
    return {
      keys: [...columns],
    }
  }

  loadImageUrlAsUrlData = async (sourceUrl, targetData) => {
    this.setState({ form: { ...this.state.form, [targetData]: sourceUrl } })
  }

  loadFileAsUrlData = async (file, targetData) => {
    const data = await getFileAsDataUrl(file)
    this.setState({ form: { ...this.state.form, [targetData]: data } })
  }

  getRequestDataFromForm = async () => {
    const { form } = this.state
    const data = {
      template: form.template,
      title: form.title,
      description: form.description,
      name_button_one: form.name_button_one,
      url_button_one: form.url_button_one,
      open_new_tab_button_one: form.open_new_tab_button_one === '1',
      name_button_two: form.name_button_two,
      url_button_two: form.url_button_two,
      open_new_tab_button_two: form.open_new_tab_button_two === '1',
      default_logo: form.default_logo,
    }

    if (form.image) {
      data.image = await getBase64(form.image[0])
    }
    if (form.logo) {
      data.logo = await getBase64(form.logo[0])
    }

    return data
  }

  onSubmit = async () => {
    const { addSnackbar, goTo, createResource, updateResource, isEdit, item, type } = this.props

    const data = await this.getRequestDataFromForm()
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          addSnackbar('Slide editado exitosamente.', SnackbarType.Success)
          goTo(ADMIN_SLIDES)
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error)
        })
    } else {
      createResource(type, data)
        .then(() => {
          addSnackbar('Slide creado exitosamente.', '4')
          goTo(ADMIN_SLIDES)
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error)
        })
    }
  }

  validateForm = () => {
    const { isEdit } = this.props
    const form = this.state.form
    let error = false
    let errors = {
      template: '',
      title: '',
      description: '',
      image: '',
      logo: '',
      name_button_one: '',
      url_button_one: '',
      name_button_two: '',
      url_button_two: '',
    }

    if (!form.template) {
      errors.template = 'Debes seleccionar una plantilla'
      error = true
    }
    if (form.template !== SlidesTemplateType.ImageOnly) {
      if (
        form.template !== SlidesTemplateType.LogoTextActions &&
        validator.isEmpty(form.title.trim())
      ) {
        errors.title = 'Debes completar este campo'
        error = true
      } else if (!validator.isLength(form.title.trim(), { min: 0, max: 40 })) {
        errors.title = 'Debes completar hasta 40 caracteres.'
        error = true
      }

      if (!validator.isLength(form.description.trim(), { min: 0, max: 70 })) {
        errors.description = 'Puedes completar hasta 70 caracteres.'
        error = true
      }

      if (!validator.isLength(form.name_button_one.trim(), { min: 0, max: 20 })) {
        errors.name_button_one = 'Debes completar hasta 20 caracteres.'
        error = true
      }

      if (!validator.isEmpty(form.name_button_one.trim())) {
        if (validator.isEmpty(form.url_button_one.trim())) {
          errors.url_button_one = 'Debes completar este campo'
          error = true
        } else if (!validator.isLength(form.url_button_one.trim(), { min: 1, max: 250 })) {
          errors.url_button_one = 'Debes completar entre 1 y 250 caracteres.'
          error = true
        }
      }

      if (!validator.isLength(form.name_button_two.trim(), { min: 0, max: 20 })) {
        errors.name_button_two = 'Debes completar hasta 20 caracteres.'
        error = true
      }

      if (!validator.isEmpty(form.name_button_two.trim())) {
        if (validator.isEmpty(form.url_button_two.trim())) {
          errors.url_button_two = 'Debes completar este campo'
          error = true
        } else if (!validator.isLength(form.url_button_two.trim(), { min: 1, max: 250 })) {
          errors.url_button_two = 'Debes completar entre 1 y 250 caracteres.'
          error = true
        }
      }
    }
    if (!isEdit && !form.image) {
      errors.image = 'Debes seleccionar una imagen'
      error = true
    }

    this.setState({ errors: errors, isValid: !error })
    return !error
  }

  onGoBack = () => {
    this.props.goTo(ADMIN_SLIDES)
  }

  render() {
    const { loading, title } = this.props
    return (
      <AdminResourceForm
        keyName={'admin-slide-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    )
  }
}

AdminSlidesFormContainer.defaultProps = {
  type: 'slides',
}

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource
  return {
    ...states[ownProps.keyName],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResource, createResource, updateResource, addSnackbar, goTo },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSlidesFormContainer)
