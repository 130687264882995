import React, { useState, useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadCourseById, deleteSharedCourse } from '../../actions/courses'
import { appLoadToken } from '../../actions/app'
import { withRouter } from 'react-router-dom'
import Course from '../../components/courses/Course'
import { addSnackbar } from '../../actions/snackbar'
import { SnackbarType } from '../../config/constants'
import DocumentTitle from '../../components/ui/DocumentTitle'
import { loadUserQuestionsTypes } from '../../actions/quizTrivia'

const initialState = {
  courseDone: false,
  activityDone: false,
}

const CourseContainer = ({
  courseId,
  loadCourseById,
  appLoadToken,
  addSnackbar,
  course,
  deleteSharedCourse,
  loading,
  loadUserQuestionsTypes,
}) => {
  const [state, updateState] = useState(initialState)

  const loadCourse = useCallback(() => {
    appLoadToken().then(() => {
      loadCourseById(courseId)
    })
  }, [courseId, loadCourseById, appLoadToken])

  const removeSharedCourse = useCallback(
    async course => {
      try {
        await deleteSharedCourse(course.id)
        addSnackbar('Recomendación descartada', SnackbarType.Success)
        loadCourse()
      } catch (err) {
        addSnackbar('Error: ' + err.message, SnackbarType.Error)
      }
    },
    [deleteSharedCourse, addSnackbar, loadCourse, deleteSharedCourse],
  )

  const handleActivityDone = useCallback(() => {
    updateState({ ...state, activityDone: true })
  }, [state, updateState])

  const onLoadQuestionsTypes = () => {
    appLoadToken().then(() => {
      loadUserQuestionsTypes()
    })
  }

  useEffect(() => {
    loadCourse()
  }, [loadCourse])

  useEffect(() => {
    if (
      course &&
      course.user_courses &&
      course.user_courses.length === 1 &&
      !state.courseDone &&
      state.activityDone
    ) {
      let userInfo = course.user_courses[0]
      let progress = (userInfo.total_activities_done / (course.total_activities || 1)) * 100
      if (progress === 100) {
        updateState({ ...state, courseDone: true })
      }
    }
  }, [course, updateState, state])

  useEffect(() => {
    onLoadQuestionsTypes()
  }, [])

  return (
    <React.Fragment>
      {course && <DocumentTitle title={course.title + ' | Curso | Academia'} />}
      <Course
        course={course}
        loading={loading}
        handleCourseDone={handleActivityDone}
        onRemoveSharedCourse={removeSharedCourse}
        courseDone={state.courseDone}
        addSnackbar={addSnackbar}
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    ...state.courseReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCourseById,
      appLoadToken,
      addSnackbar,
      deleteSharedCourse,
      loadUserQuestionsTypes,
    },
    dispatch,
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseContainer))
