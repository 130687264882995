/* eslint-disable no-case-declarations */
import {
  ADMIN_USER_COMMISSION_READ_END,
  ADMIN_USER_COMMISSION_READ_FAILED,
  ADMIN_USER_COMMISSION_READ_START,
	ADMIN_USER_COMMISSION_UPDATE_END,
} from '../../actions';

const initialState = {
  error: null,
  loading: false,
  userCommission: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
    case ADMIN_USER_COMMISSION_READ_END:
      const { userCommission } = action.payload;
      return {
        loading: false,
        userCommission,
        error: null,
      };
    case ADMIN_USER_COMMISSION_READ_START:
      return {
        userCommission: null,
        error: null,
        loading: true,
      };
    case ADMIN_USER_COMMISSION_READ_FAILED:
      const { message } = action.payload;
      return {
        userCommission: null,
        error: message,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
