import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import config from '../../config';
import { IDFW_IN, IDFW_OUT, IDFW_OB } from '../../config/routes';
import WorldIcon from '@material-ui/icons/Public';
import HomeIcon from '@material-ui/icons/Home';

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    login: {
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      background: `no-repeat center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginContent: {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '4em',
    },
    logoAc: {
      height: 240,
      [mediaLg]: {
        height: 'auto',
      },
    },
    titleContainer: {
      display: 'flex',
      marginBottom: 32,
      alignItems: 'center',
      [mediaLg]: {
        flexDirection: 'column',
      },
    },
    title: {
      color: '#fff',
      fontSize: 22,
      fontWeight: 300,
      lineHeight: 0.9,
      [mediaLg]: {
        marginTop: 24,
        fontSize: 24,
        padding: '0 8px',
        width: '100%',
        wordBreak: 'break-word',
        boxSizing: 'border-box',
      },
    },
    subtitle: {
      fontSize: 22,
      //fontMod
      fontWeight: 700,
      color: '#ffffff',
      marginLeft: 8,
      [mediaLg]: {
        fontSize: 24,
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [mediaLg]: {
        flexDirection: 'column',
        width: '80%',
      },
    },
    button: {
      margin: '2px 16px',
      width: '110%',
      height: 56,
      borderRadius: 4,
      background: 'linear-gradient(150deg, #8b035b, #ef465d 100%)',
      boxShadow: '0 5px 8.2px 0 rgba(233, 37, 129, 0.25)',
      [mediaLg]: {
        marginTop: 16,
      },
    },
    buttonText: {
      color: '#fff',
      //fontMod
      fontWeight: 700,
      fontSize: 20,
    },
    iconButton: {
      color: '#fff',
      marginRight: 16,
      fontSize: 40,
    },
  };
};

class Login extends Component {
  openLink = (link) => {
    window.location.href = link;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.login}>
        <div className={classes.loginContent}>
          <img
            src={config.BASE_URL + 'img/ic_banner_academia.svg'}
            alt="logo banner"
            height={90}
            className={classes.logoAc}
          />
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>LOGRAR QUE LAS COSAS SUCEDAN</Typography>
            <Typography className={classes.subtitle}>¡DEPENDE DE VOS!</Typography>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={() => this.openLink(IDFW_IN)}
            >
              <WorldIcon className={classes.iconButton} />
              <Typography className={classes.buttonText}>DESDE LA RED</Typography>
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={() => this.openLink(IDFW_OUT)}
            >
              <HomeIcon className={classes.iconButton} />
              <Typography className={classes.buttonText}>FUERA DE LA RED</Typography>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Login);
