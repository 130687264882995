import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './ActivityModalStyles'
import AdminResourceForm from '../../AdminResourceForm'
import { EditInputType } from '../../../../config/constants'
import QuizTriviaTabs from '../../../courses/QuizTrivia/QuizTriviaTabs'
import { useState } from 'react'
import { blankQuestion } from '../../../courses/QuizTrivia/helpers'
import { useEffect } from 'react'
import { limitIntEntry, preventEvent } from '../../../../utils/AdminHelper'

const ActivityModalQuizTriviaContent = ({
  activity,
  title,
  results,
  onChange,
  classes,
  onSubmit,
  onCancel,
  actionButtons,
}) => {
  const [questions, setQuestions] = useState()
  const [totalScore, setTotalScore] = useState(0)

  const onAttributeChange = useCallback(
    (name, value) => {
      onChange({ ...activity, [name]: value })
    },
    [activity],
  )

  useEffect(() => {
    if (questions) {
      onAttributeChange('quizTrivia', questions)
    } else {
      setQuestions(activity?.quiz?.map(({ question }) => question) ?? [blankQuestion()])
    }
  }, [questions])

  useEffect(() => {
    onAttributeChange('totalScore', totalScore)
  }, [totalScore])

  useEffect(() => {
    const displaySolutionField = document.querySelector('#displaySolutionField')
    if (displaySolutionField) {
      displaySolutionField.insertAdjacentHTML(
        'beforebegin',
        '<div class="form-subtitle">Detalles de formulario</div>',
      )
      if (activity.quiz_editing_blocked) {
        displaySolutionField.insertAdjacentHTML(
          'afterend',
          '<div class="embeded-form-error">Los siguientes campos no se pueden editar porque al menos un usuario ha contestado el formulario</div>',
        )
      }
    }
  }, [])

  return (
    <AdminResourceForm
      title={title}
      keyName={'admin-activity-modal-quiz-form'}
      form={{
        ...activity,
        attemps: activity?.attemps?.toString(),
        passing_score: activity?.passing_score?.toString(),
      }}
      isValid={results && results.isValid}
      errors={results ? results.errors : []}
      loading={false}
      attributes={{
        keys: [
          {
            title: 'Nombre',
            editable: true,
            editConfig: { type: EditInputType.Text, name: 'name' },
          },
          {
            title: 'Descripción',
            editable: true,
            editConfig: {
              type: EditInputType.Text,
              name: 'description',
              multiline: 4,
            },
          },
          {
            title: 'Mostrar solución',
            editable: true,
            source: 'display_solution',
            editConfig: {
              id: 'displaySolutionField',
              type: EditInputType.RadioGroup,
              name: 'display_solution',
              row: true,
              require: true,
              options: [
                {
                  id: true,
                  description: 'Si',
                },
                {
                  id: false,
                  description: 'No',
                },
              ],
            },
          },
          {
            title: 'Cantidad máxima de intentos',
            source: 'attemps',
            editable: true,
            editConfig: {
              type: EditInputType.Number,
              name: 'attemps',
              onKeyDown: limitIntEntry,
              onPaste: preventEvent,
              disabled: !!activity.quiz_editing_blocked,
            },
          },
          {
            title: 'Resultado mínimo de aprobación',
            editable: true,
            editConfig: {
              type: EditInputType.Number,
              name: 'passing_score',
              max: 100,
              require: true,
              onKeyDown: limitIntEntry,
              onPaste: preventEvent,
              disabled: !!activity.quiz_editing_blocked,
            },
          },
          {
            title: 'Preguntas',
            editable: true,
            editConfig: {
              type: EditInputType.Custom,
              name: 'quizTrivia',
              renderItem: () => (
                <QuizTriviaTabs
                  quizEditingBlocked={activity.quiz_editing_blocked}
                  questions={questions}
                  setQuestions={setQuestions}
                  totalScore={totalScore}
                  setTotalScore={setTotalScore}
                />
              ),
            },
          },
        ],
      }}
      onAttributeChange={onAttributeChange}
      customClasses={classes}
      onSubmit={onSubmit}
      onCancel={onCancel}
      actionButtons={actionButtons}
    />
  )
}

export default withStyles(styles)(ActivityModalQuizTriviaContent)
