import {
  ADMIN_CATEGORIES,
  ADMIN_USERS,
  ADMIN_TAGS,
  ADMIN_COURSES,
  ADMIN_REPORTS,
  ADMIN_REPORTS_NPS_COURSES,
  ADMIN_REPORTS_NPS_COMMISSIONS,
  ADMIN_REPORTS_EXTERNAL_COURSES,
  ADMIN_REPORTS_EXTERNAL_COURSES_ROADMAP,
  ADMIN_REPORTS_COMMISSIONS,
  ADMIN_REPORTS_ONLINE_COURSES,
  ADMIN_REPORTS_CAREER_PLANS,
  ADMIN_REPORTS_USER_CAREER_PLANS,
  ADMIN_REPORTS_CAREER_PLAN_COURSES,
  ADMIN_REPORTS_NPS_COMMENTS,
  ADMIN_REPORTS_NPS_EXTERNAL_COURSES_COMMENTS,
  ADMIN_CAREER_PLAN,
  ADMIN_LOTS,
  ADMIN_JOBS,
  ADMIN_EXTERNAL_COURSES,
  ADMIN_FACILITATORS,
  ADMIN_SLIDES,
  ADMIN_REFERENTS_PROVIDERS,
  ADMIN_REPORTS_CROSS,
  ADMIN_REPORTS_COURSE_ACTIVITIES,
  ADMIN_REPORTS_EXTERNAL_FORMATIONS,
  ADMIN_REPORTS_QUIZ_TRIVIA_ACTIVITIES,
} from '../../../config/routes'

export const ITEM_GENERAL = 'ITEM_GENERAL'
export const ITEM_USERS = 'ITEM_USERS'
export const ITEM_CATEGORIES = 'ITEM_CATEGORIES'
export const ITEM_CAREER_PLAN = 'ITEM_CAREER_PLAN'
export const ITEM_LOTS = 'ITEM_LOTS'
export const ITEM_TAGS = 'ITEM_TAGS'
export const ITEM_COURSES = 'ITEM_COURSES'
export const ITEM_WORKSHOPS = 'ITEM_WORKSHOPS'
export const ITEM_REPORTS = 'ITEM_REPORTS'
export const ITEM_REPORTS_COURSES = 'ITEM_REPORTS_COURSES'
export const ITEM_REPORTS_NPS = 'ITEM_REPORTS_NPS'
export const ITEM_REPORTS_EXTERNAL_COURSES = 'ITEM_REPORTS_EXTERNAL_COURSES'
export const ITEM_EXTERNAL_COURSES = 'ITEM_EXTERNAL_COURSES'
export const ITEM_REPORTS_NPS_COURSES = 'ITEM_REPORTS_NPS_COURSES'
export const ITEM_REPORTS_CAREER_PLANS = 'ITEM_REPORTS_CAREER_PLANS'
export const ITEM_REPORTS_NPS_COMMENTS = 'ITEM_REPORTS_NPS_COMMENTS'
export const ITEM_JOBS = 'ITEM_JOBS'

export const menuItems = {
  items: [
    {
      title: 'Cursos online',
      icon: '',
      action: ITEM_COURSES,
      submenu: true,
      allowed_role_admin: true,
      items: [
        {
          title: 'Cursos',
          icon: 'school',
          submenu: false,
          route: ADMIN_COURSES,
          allowed_role_admin: true,
        },
        {
          title: 'Rutas de aprendizaje',
          icon: 'trending_up',
          submenu: false,
          route: ADMIN_CAREER_PLAN,
          allowed_role_admin: true,
        },
        {
          title: 'Grupos',
          icon: 'people',
          submenu: false,
          route: ADMIN_LOTS,
          allowed_role_admin: true,
        },
        {
          title: 'Referentes / Proveedores',
          icon: 'record_voice_over',
          submenu: false,
          route: ADMIN_REFERENTS_PROVIDERS,
          allowed_role_admin: true,
        },
      ],
    },
    {
      title: 'Talleres',
      icon: '',
      action: ITEM_WORKSHOPS,
      submenu: true,
      allowed_role_admin: true,
      items: [
        {
          title: 'Talleres',
          icon: 'school',
          submenu: false,
          route: ADMIN_EXTERNAL_COURSES,
          allowed_role_admin: true,
        },
        {
          title: 'Facilitadores',
          icon: 'record_voice_over',
          submenu: false,
          route: ADMIN_FACILITATORS,
          allowed_role_admin: true,
        },
      ],
    },
    {
      title: 'Generales',
      icon: '',
      action: ITEM_GENERAL,
      submenu: true,
      allowed_role_admin: true,
      allowed_role_admin_ob: true,
      allowed_role_reporting: true,
      allowed_role_reporting_ob: true,
      items: [
        {
          title: 'Usuarios',
          icon: 'person',
          submenu: false,
          route: ADMIN_USERS,
          allowed_role_admin: true,
          allowed_role_admin_ob: true,
        },
        {
          title: 'Reportes',
          icon: 'format_list_bulleted',
          submenu: true,
          action: ITEM_REPORTS,
          allowed_role_admin: true,
          allowed_role_reporting: true,
          allowed_role_reporting_ob: true,
          allowed_role_admin_ob: true,
          items: [
            {
              title: 'Cursos',
              icon: 'format_list_bulleted',
              submenu: true,
              action: ITEM_REPORTS_COURSES,
              allowed_role_admin: true,
              allowed_role_reporting: true,
              allowed_role_reporting_ob: true,
              allowed_role_admin_ob: true,
              items: [
                {
                  title: 'Actividad de usuarios',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                  allowed_role_admin_ob: true,
                },
                {
                  title: 'Actividades de cursos',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_COURSE_ACTIVITIES,
                  allowed_role_admin: true,
                  allowed_role_admin_ob: false,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: false,
                },
                {
                  title: 'Cursos Online',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_ONLINE_COURSES,
                  allowed_role_admin: true,
                  allowed_role_reporting: false,
                  allowed_role_admin_ob: false,
                },
                {
                  title: 'Formulario',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_QUIZ_TRIVIA_ACTIVITIES,
                  allowed_role_admin: true,
                  allowed_role_admin_ob: false,
                  allowed_role_reporting: false,
                  allowed_role_reporting_ob: false,
                },
              ],
            },
            {
              title: 'NPS',
              icon: 'format_list_bulleted',
              submenu: true,
              action: ITEM_REPORTS_NPS,
              allowed_role_admin: true,
              allowed_role_admin_ob: false,
              allowed_role_reporting: true,
              allowed_role_reporting_ob: false,
              items: [
                {
                  title: 'NPS por curso',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_NPS_COURSES,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                  allowed_role_admin_ob: true,
                },
                {
                  title: 'NPS comentarios',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_NPS_COMMENTS,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                  allowed_role_admin_ob: true,
                },
                {
                  title: 'NPS por comisiones',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_NPS_COMMISSIONS,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                  allowed_role_admin_ob: true,
                },
                {
                  title: 'NPS comentarios comisiones',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_NPS_EXTERNAL_COURSES_COMMENTS,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                  allowed_role_admin_ob: true,
                },
              ],
            },
            {
              title: 'Rutas de aprendizaje',
              icon: 'format_list_bulleted',
              submenu: true,
              action: ITEM_REPORTS_CAREER_PLANS,
              allowed_role_admin: true,
              allowed_role_reporting: true,
              allowed_role_reporting_ob: true,
              allowed_role_admin_ob: true,
              items: [
                {
                  title: 'Detalle Rutas de Aprendizaje',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_CAREER_PLANS,
                  allowed_role_admin: true,
                  allowed_role_reporting: false,
                  allowed_role_admin_ob: false,
                },
                {
                  title: 'Rutas asignadas por usuario',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_USER_CAREER_PLANS,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                  allowed_role_admin_ob: true,
                },
                /* {
                  title: 'Rutas globales por usuario',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_USER_GLOBAL_CAREER_PLANS,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                }, */
                {
                  title: 'Cursos por Rutas de Aprendizaje',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_CAREER_PLAN_COURSES,
                  allowed_role_admin: true,
                  allowed_role_reporting: false,
                  allowed_role_admin_ob: false,
                },
              ],
            },
            {
              title: 'Talleres',
              icon: 'format_list_bulleted',
              submenu: true,
              action: ITEM_REPORTS_EXTERNAL_COURSES,
              allowed_role_admin: true,
              allowed_role_reporting: true,
              allowed_role_reporting_ob: true,
              allowed_role_admin_ob: true,
              items: [
                {
                  title: 'Usuarios por Taller',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_EXTERNAL_COURSES,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_reporting_ob: true,
                  allowed_role_admin_ob: true,
                },
                {
                  title: 'Comisiones por Taller',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_COMMISSIONS,
                  allowed_role_admin: true,
                  allowed_role_reporting: true,
                  allowed_role_admin_ob: false,
                  allowed_role_reporting_ob: false,
                },
                {
                  title: 'Roadmap',
                  icon: 'format_list_bulleted',
                  submenu: false,
                  route: ADMIN_REPORTS_EXTERNAL_COURSES_ROADMAP,
                  allowed_role_admin: true,
                },
              ],
            },
            {
              title: 'Cross Collaboration',
              icon: 'format_list_bulleted',
              submenu: false,
              route: ADMIN_REPORTS_CROSS,
              allowed_role_admin: true,
              allowed_role_admin_ob: false,
              allowed_role_reporting: false,
              allowed_role_reporting_ob: false,
            },
            {
              title: 'Formaciones externas',
              icon: 'format_list_bulleted',
              submenu: false,
              route: ADMIN_REPORTS_EXTERNAL_FORMATIONS,
              allowed_role_admin: true,
              allowed_role_admin_ob: false,
              allowed_role_reporting: false,
              allowed_role_reporting_ob: false,
            },
          ],
        },
        {
          title: 'Etiquetas',
          icon: 'style',
          submenu: false,
          route: ADMIN_TAGS,
          allowed_role_admin: true,
        },

        {
          title: 'Slides',
          icon: 'view_carousel',
          submenu: false,
          route: ADMIN_SLIDES,
          allowed_role_admin: true,
        },

        {
          title: 'Importadores',
          icon: 'import_export',
          route: ADMIN_JOBS,
          submenu: false,
          allowed_role_admin: true,
        },
        {
          title: 'Categorías',
          icon: 'category',
          submenu: false,
          route: ADMIN_CATEGORIES,
          allowed_role_admin: true,
        },
      ],
    },
  ],
}
