import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { isEmpty } from 'lodash';

const styles = (theme) => ({
  root: {
    marginBottom: 30,
  },
  tableCourses: {
    marginTop: 20,
  },
  externalCourses: {
    marginTop: 20,
  },
  formControl: {
    width: '100%',
    margin: '20px 0',
    overflow: 'hidden',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  wrapperBoton: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  boton: {
    display: 'flex inline',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  autosuggest: {
    marginTop: 50,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  button: {
    marginRight: theme.spacing(1),
  }
});

function StepTwo({
  classes,
  externalCourseMetadata,
  handleChangeCourse,
  handleChangeExternalCourse,
  handleBack,
  handleSaveRequirementsAndPublish,
  handleSaveRequirements,
  isPublished,
  coursesSelected,
  externalCoursesSelected,
  form
}) {
  const { data: dataCourses, columns: columnsCourses } = externalCourseMetadata.courses;
  const { columns: columnsExternalCourses } = externalCourseMetadata.externalCourses;
  const dataExternalCourses = externalCourseMetadata.externalCourses.data.filter(ec => form.id !== ec.id);

  const [searchCourse, setSearchCourse] = useState();
  const [searchExternalCourse, setSearchExternalCourse] = useState();

  const handleClickCourse = (item) => {
    handleChangeCourse(item);
  };

  const handleClickExternalCourse = (item) => {
    handleChangeExternalCourse(item);
  };

  /* const disabledBtnPublish = [
    (externalCoursesSelected, coursesSelected),
  ].every((position) => !isEmpty(position)); */

  const setCourseRowsSelected = () => {
    return dataCourses
      .map((item, idx) => {
        if (coursesSelected.filter(e2 => item.id === e2.id).length > 0) {
          return idx;
        }
        return undefined;
      })
      .filter(item => item !== undefined);
  }

  const optionsCourses = () => ({
    download: false,
    print: false,
    rowsPerPage: 10,
    viewColumns: false,
    sortOrder: {
      name: "id",
      direction: "asc"
    },
    sort: true,
    filter: false,
    fixedHeader: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    searchText: searchCourse,
    customSearch: (searchQuery, currentRow, columns) => {
      setSearchCourse(searchQuery);
      let isFound = false;
      currentRow.forEach(col => {
        if (col?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
    onSearchClose: () => {
      setSearchCourse(false)
    },
    customSort: (data, colIndex, order) => {
      let rowsSelected = setCourseRowsSelected();
      return data.sort((a, b) => {
        var dir = order === "desc" ? 1 : -1;
        var isASelected = dataCourses.find(elem => {
          return rowsSelected.indexOf(a.index) !== -1;
        });
        var isBSelected = dataCourses.find(elem => {
          return rowsSelected.indexOf(b.index) !== -1;
        });
        if (isASelected && !isBSelected) return 1 * dir;
        if (!isASelected && isBSelected) return -1 * dir;
        return (a.data[colIndex] > b.data[colIndex] ? -1 : 1) * dir;
      });
    },
    rowsSelected: setCourseRowsSelected(),
    onRowsSelect: (currentRowSelected, allRowsSelected) => {
      const courseItem = allRowsSelected.map(item => {
        return dataCourses[item.dataIndex];
      });
      handleClickCourse(courseItem);
    },
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay registros",
        toolTip: "Orden",
        columnHeaderTooltip: column => `Orden para ${column.label}`
      },
      selectedRows: {
        text: "centro(s) de costos seleccionado(s)",
        delete: "Borrar",
        deleteAria: "Borrar seleccion",
      },
    }
  })

  const setExternalCourseRowsSelected = () => {
    return dataExternalCourses
      .map((item, idx) => {
        if (externalCoursesSelected.filter(e2 => item.id === e2.id).length > 0) {
          return idx;
        }
        return undefined;
      })
      .filter(item => item !== undefined);
  }

  const optionsExternalCourses = () => ({
    download: false,
    print: false,
    rowsPerPage: 10,
    filter: false,
    selectableRows: "multiple",
    viewColumns: false,
    sortOrder: {
      name: "id",
      direction: "asc"
    },
    sort: true,
    fixedHeader: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    rowsSelected: setExternalCourseRowsSelected(),
    searchText: searchExternalCourse,
    customSearch: (searchQuery, currentRow, columns) => {
      setSearchExternalCourse(searchQuery);
      let isFound = false;
      currentRow.forEach(col => {
        if (col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
    onSearchClose: () => {
      setSearchExternalCourse(false)
    },
    onRowsSelect: (currentRowSelected, allRowsSelected) => {
      const externalCourseItem = allRowsSelected.map(item => {
        return dataExternalCourses[item.dataIndex];
      });
      handleClickExternalCourse(externalCourseItem);
    },
    customSort: (data, colIndex, order) => {
      let rowsSelected = setExternalCourseRowsSelected();

      return data.sort((a, b) => {
        var dir = order === "desc" ? 1 : -1;
        var isASelected = dataExternalCourses.find(elem => {
          return rowsSelected.indexOf(a.index) !== -1;
        });
        var isBSelected = dataExternalCourses.find(elem => {
          return rowsSelected.indexOf(b.index) !== -1;
        });
        if (isASelected && !isBSelected) return 1 * dir;
        if (!isASelected && isBSelected) return -1 * dir;
        return (a.data[colIndex] > b.data[colIndex] ? -1 : 1) * dir;
      });
    },
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay registros",
        toolTip: "Orden",
        columnHeaderTooltip: column => `Orden para ${column.label}`
      },
      selectedRows: {
        text: "filas(s) seleccionadas"
      },
    }
  });

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.tableCourses}>
          <MUIDataTable
            title={'Cursos Online'}
            data={dataCourses}
            columns={columnsCourses}
            options={optionsCourses()}
          />
        </Grid>

        <Grid item xs={12} className={classes.externalCourses}>
          <MUIDataTable
            title={'Talleres'}
            data={dataExternalCourses}
            columns={columnsExternalCourses}
            options={optionsExternalCourses()}
          />
        </Grid>
      </Grid>

      <Grid className={classes.wrapperBoton}>
        <Button onClick={handleBack} className={classes.button}>
          Atrás
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleSaveRequirements}
        >
          {(isPublished) ? 'Guardar cambios' : 'Guardar Borrador'}
        </Button>
        {(!isPublished) &&
          <Button
            disabled={false}
            onClick={handleSaveRequirementsAndPublish}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Publicar
          </Button>}
      </Grid>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(StepTwo);
