import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import config from '../../config'
import HomeSlide from './HomeSlide'
import { COURSES_EXPLORE, SEARCH } from '../../config/routes'
import { SlidesTemplateType } from '../../config/constants'
import { Carousel } from 'react-responsive-carousel'

const styles = () => {
  return {
    slider: {
      zIndex: 10,
    },
  }
}

const HeaderHome = ({ classes, onGoTo, slides }) => {
  const slidesItems =
    slides.items.length > 0
      ? slides.items
      : [
          {
            template: SlidesTemplateType.LogoTextActions,
            description: 'REDESCUBRÍ LOS CURSOS QUE TENEMOS PARA VOS.',
            logo: `${config.BASE_URL}img/ic_banner_academia.svg`,
            image: `${config.BASE_URL}img/ac_bg_home.png`,
            name_button_one: 'POR CATEGORÍA',
            name_button_two: 'POR PALABRA CLAVE',
            url_button_one: COURSES_EXPLORE,
            url_button_two: SEARCH,
            open_new_tab_button_one: true,
            open_new_tab_button_two: false,
          },
        ]

  return (
    <header>
      <Carousel
        className={classes.slider}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        labels={{
          leftArrow: 'Diapositiva anterior',
          rightArrow: 'Diapositiva siguiente',
          item: 'Ir a diapositiva',
        }}
        transitionTime={2000}
        interval={10000}
      >
        {slidesItems.map((s, index) => (
          <HomeSlide slide={s} onGoTo={onGoTo} key={'slide-' + index} />
        ))}
      </Carousel>
    </header>
  )
}
export default withStyles(styles)(HeaderHome)
