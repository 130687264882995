import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../../actions/navigator';
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource
} from '../../../actions/admin';
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  router,
  ADMIN_FACILITATORS,
} from '../../../config/routes';
import AdminResourceIndex from '../AdminResourceIndex';
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType,
  FacilitatorTypes,
} from '../../../config/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { addSnackbar } from '../../../actions/snackbar';

class AdminFacilitatorsContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
  };

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size, undefined)
  };

  onRenderName = (item) => {
    if(item.user){
      return item.user.full_name;
    }else{
      return item.external_facilitator;
    }
  };

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Tipo', source: 'facilitator_type',
        type: AdminItemViewType.Conditional,
        conditions:  FacilitatorTypes.map((ft) => ({
          condition: `${ft.id}`,
          result: ft.description,
        })),},
      {
        title: 'Nombre',
        source: '',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderName
      },
    ];
  };

  onCreate = () => {
    this.props.goTo(ADMIN_FACILITATORS + ADMIN_NEW);
  };


  onEditItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_FACILITATORS + ADMIN_EDIT, { id: item.id })
    );
  };


  onDeleteItem = (item) => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: this.onRenderName(item),
    });
  };


  getActions = (item) => {
    return [
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
    ];
  };


  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Select,
        name: 'facilitator.facilitatorType',
        placeholder: 'Tipo',
        format: FiltersFormat.Plain,
        options: FacilitatorTypes,
      },
      {
        type: EditInputType.Text,
        placeholder: 'Nombre',
        format: FiltersFormat.Like,
        name: 'facilitator.description',
      },
    ];
  };

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props;
    const item = this.state.deleteItem;
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Facilitador eliminado.', SnackbarType.Success);
        refreshResources(this.getKeyName());
      })
      .catch(() => {
        addSnackbar('El facilitador no pudo eliminarse ya que está asociado a una comisión', SnackbarType.Error);
      });
  };

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
  };


  getKeyName = () => {
    return 'admin-facilitators';
  };

  render() {
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={'FACILITADORES'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        onCreateTopCourses={this.onCreateTopCourses}
      >
        <ConfirmDialog
          title={'Eliminar Facilitador'}
          open={this.state.confirmDeleteOpen}
          description={`¿Estás seguro de eliminar el facilitador "${this.state.itemToDelete}"?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    );
  }
}

AdminFacilitatorsContainer.defaultProps = {
  type: 'facilitators',
};

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResources, refreshResources, deleteResource, goTo, addSnackbar, updateResource },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminFacilitatorsContainer);
