import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../../actions/user'
import Loading from '../ui/Loading'
import config from '../../config'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  Input,
  FormGroup,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { login_theme } from '../../themes/login_theme'
import validator from 'validator'
import { withRouter } from 'react-router-dom'
import { SnackbarType } from '../../config/constants'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SnackbarMessage from '../ui/SnackbarMessage'
import { loginOB, loginQr, changuePassOB } from '../../actions/user'
import { withGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  const mediaSm = theme.breakpoints.down(theme.mediaQueries.sm)
  return {
    formContainer: {
      minWidth: 300,
      [mediaSm]: {
        minWidth: 200,
      },
    },
    formContainerChangePass: {
      minWidth: 300,
      [mediaSm]: {
        minWidth: 200,
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formGroupContainer: {
      width: 350,
    },
    login: {
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      background: `no-repeat center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    loginContent: {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '4em',
      justifyContent: 'center',
      flexGrow: 2,
    },
    logoAc: {
      width: '80%',
      maxWidth: 357,
    },

    buttonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 340,
      [mediaLg]: {
        flexDirection: 'column',
        minWidth: 300,
      },
      [mediaSm]: {
        minWidth: 200,
      },
      marginTop: 16,
    },
    button: {
      margin: '2px 16px',
      width: '110%',
      height: 56,
      borderRadius: 4,
      background: '#b7366c',
      boxShadow: '0 5px 8.2px 0 rgba(183, 54, 108, 0.25)',
      [mediaLg]: {
        marginTop: 16,
      },
      '&-disabled': {
        background: 'linear-gradient(150deg, #757070, #383232 100%)',
        boxShadow: 'none',
        color: '#444444 !important',
      },
    },
    buttonText: {
      color: '#fff',
      fontWeight: 700,
      fontSize: 20,
    },
    iconButton: {
      color: '#fff',
      marginRight: 16,
      fontSize: 40,
    },
    linkButton: {},
    linkButtonText: {
      color: '#fff',
      fontWeight: 500,
      fontSize: 16,
    },
    mesagge: {
      color: '#fff',
      fontWeight: 600,
      fontSize: 24,
      marginBottom: 20,
    },
    surveyButton: {
      backgroundColor: '#FFC300',
      '&:hover': {
        backgroundColor: '#cc9800',
      },
    },
    surveyButtonText: {
      color: '#1b1b1b',
      fontWeight: 700,
      fontSize: 16,
    },
    error: {
      color: '#ff0000',
    },
    linksContainer: {
      flexDirection: 'row',
      display: 'flex',
      flexGrow: 1,
      flexShrink: 0,
      alignItems: 'flex-end',
    },
    qrCode:{
      width:200,
      height:200,
      marginBottom:30,
    },
  }
}

/**
 * Login OpenBankLogin. Este login es para uso de OpenBankLogin
 * @return render login
 */

const OpenBankLogin = ({
  classes,
  onGoToResetPassword,
  onGoToRegister,
  onGoToSurvey,
  loading,
  login,
  googleReCaptchaProps
}) => {
  const dispach = useDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [token, setToken] = useState('')
  const [error, setError] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [isReCaptchaValid, setIsReCaptchaValid] = useState(false)
  const [linkQr, setLinkQr] = useState(null);
  const [status, setStatus] = useState('login');
  const [code, setCode] = useState('')
  const [notValidPassMessage, setNotValidPassMessage] = useState(false);
  const [mensajeError, setMensajeError] = useState('');

  const handleVerifyRecaptcha = async () => {
    const { executeRecaptcha } = googleReCaptchaProps;
    if (!executeRecaptcha) {
      setIsReCaptchaValid(false)
      return
    }
    const tokenGoogle = await executeRecaptcha('homepage')
    setIsReCaptchaValid(true)
    setToken(tokenGoogle)
    return tokenGoogle
  }

  useEffect(() => {
    const resultIsValid =
      username !== null &&
      validator.isEmail(username) &&
      password !== null &&
      !validator.isEmpty(password) &&
      validator.isLength(password, { min: 8, max: 16 })


    setIsValid(resultIsValid)
    if (resultIsValid && !isReCaptchaValid) handleVerifyRecaptcha()
  }, [username, password])

  useEffect(() => {
    let resultIsValid = code !== ''
    if (resultIsValid && !isReCaptchaValid) handleVerifyRecaptcha()
  }, [code])

  const handleUsernameChange = event => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handelSubmitLogin = event => {
    event.preventDefault()
    const response = dispach(loginOB({ username, password, token }))
    response.then((data)=>{
      if(data?.data?.error || data?.class === 'Error'){
        setError(true);

        let msjError = '';
        switch (data?.data?.error) {
          case '1':
            msjError = 'Usuario no valido'
            break;
          case '2':
            msjError = 'Usuario bloqueado'
            break;
          case '3':
            msjError = 'Supero el limite de intentos, Usuario bloqueado'
            break;
          case '4':
            msjError = 'Contraseña inválida, debe contener mayúscula, minúscula, numero y mínimo 8 caracteres'
            break;
          case '5':
          case '6':
            msjError = 'Error de Recaptcha'
            break;

          default:
            msjError = 'Hubo un error'
            break;
        }
        setMensajeError(msjError);

      }else if(data.qr){
        setLinkQr(data.qr);
        setStatus('qr');
        setIsReCaptchaValid('false');
      }
    })
    .finally(()=>{
      handleVerifyRecaptcha();
    })

  }

  const handelSubmitQr = async event => {
    event.preventDefault()
    const tokeng = await handleVerifyRecaptcha();
    const response = dispach(loginQr({ username, password, code, token:tokeng }))
    response.then((data)=>{
      if(!data){
        setError(true);
        setMensajeError('Codigo Invalido');
      }
    })
  }

  const handleQrChange = event => {
    setCode(event.target.value)
  }

  const notValidSession = (e)=>{
    e.stopPropagation();
    if(!isValid){
      setMensajeError('Contraseña inválida, debe contener mayúscula, minúscula, numero y mínimo 8 caracteres');
      setNotValidPassMessage(true)
    }
  }

  return (
    <>{
      !loading ?
        <div className={classes.login}>
          {status === 'login' &&
            <div className={classes.loginContent}>
              <img
                src={config.BASE_URL + 'img/ic_banner_academia.svg'}
                alt="logo banner"
                className={classes.logoAc}
              />
              <div className={classes.formContainer}>
                <MuiThemeProvider theme={login_theme}>
                  <form onSubmit={handelSubmitLogin} id={'login-form'}>
                    <FormGroup>
                      <FormControl>
                        <InputLabel htmlFor="username">Email</InputLabel>
                        <Input
                          disableUnderline={true}
                          name="username"
                          value={username}
                          onChange={handleUsernameChange}
                        />
                      </FormControl>
                      <FormControl>
                        <InputLabel htmlFor="password">Contraseña</InputLabel>
                        <Input
                          disableUnderline={true}
                          name="password"
                          value={password}
                          type={showPassword ? 'text' : 'password'}
                          onChange={handlePasswordChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                color={'primary'}
                                aria-label="Cambiar visibilidad de la contraseña"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </FormGroup>
                  </form>
                </MuiThemeProvider>
              </div>

              {error && (
                <SnackbarMessage
                  type={1}
                  open={error}
                  onClose={()=>{setError(false)}}
                  message={mensajeError}
                />
              )}
              {notValidPassMessage && (
                <SnackbarMessage
                  type={1}
                  open={notValidPassMessage}
                  message={mensajeError}
                  onClose={()=>{setNotValidPassMessage(false)}}
                />
                )}
              <div className={classes.buttonsContainer} onClick={notValidSession}>
                <Button
                  disabled={!isValid}
                  type={'submit'}
                  className={classes.button + (!isValid ? ' ' + classes.button + '-disabled' : '')}
                  variant="outlined"
                  form="login-form"
                >
                  <Typography className={classes.buttonText}>INGRESAR</Typography>
                </Button>
              </div>
            </div>
          }
          {status === 'qr' &&
            <div className={classes.loginContent}>
              <Typography className={classes.mesagge}>
                  Use el Microsoft Authenticator para escanear este código QR
                </Typography>
                <iframe id="qr" title="Visor QR" className={classes.qrCode} src={linkQr}></iframe>
                <MuiThemeProvider theme={login_theme}>
                    <form onSubmit={handelSubmitQr} id={'login-form-qr'}>
                      <FormGroup>
                        <FormControl>
                          <InputLabel htmlFor="code">Introduce el Codigo</InputLabel>
                          <Input
                            disableUnderline={true}
                            name="code"
                            value={code}
                            type={'text'}
                            onChange={handleQrChange}
                          />
                          <div className={classes.buttonsContainer}>
                            <Button
                              type={'submit'}
                              className={classes.button}
                              variant="outlined"
                              form="login-form-qr"
                            >
                              <Typography className={classes.buttonText}>ENVIAR</Typography>
                            </Button>
                          </div>
                        </FormControl>
                      </FormGroup>
                    </form>
                  </MuiThemeProvider>
                  {error && (
                    <SnackbarMessage
                      type={1}
                      open={error}
                      onClose={()=>{setError(false)}}
                      message={mensajeError}
                    />
              )}
              </div>
          }
          {<GoogleReCaptcha />}
        </div>

      :
        <Loading dark={true} />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const {error, loading} = state.userReducer;
  return {error, loading};
};

export default connect(
  mapStateToProps,
)(withGoogleReCaptcha(withRouter(withStyles(styles)(OpenBankLogin))));
