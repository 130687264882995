import React from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Popover, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  icon: {
    color: '#707070',
    marginRight: 16,
  },
  popover: {
    background: '#474747',
    maxWidth: '18em',
    padding: '1em',
    boxSizing: 'border-box',
    color: '#fff',
  },
};

const PopoverInput = ({ option, classes }) => {
  return (
    <PopupState variant="popover" popupId={option.id}>
      {(popupState) => (
        <div>
          <IconButton className={classes.icon}>
            <ErrorOutlineIcon
              variant="contained"
              {...bindTrigger(popupState)}
            />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Typography className={classes.popover}>
              {option.helperContent}
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default withStyles(styles)(PopoverInput);
