import React from 'react';
import CloseButton from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import CssBackgroundPreview from '../../ui/CssBackgroundPreview';

const backgrounds = [
  '#1ed1a8',
  '#d54f69',
  '#8f4c98',
  '#ff8c38',
  '#5ba1f4',
  '#00b2bb',
  '#fd8fe8',
  '#88e366',
  '#b47844',
  '#63648b',
  '#ed515d',
  '#6266c9',
  '#813947',
  'linear-gradient(78deg, #ff3100, #ff3402 0%, #ff5d1b 3%, #ff8030 7%, #ff9a40 9%, #ffad4b 13%, #ffb952 16%, #ffbd55 20%, #ffe515 30%, #00e9bf 47%, #3f76ff 63%, #9a00ff 75%, #d300ff 88%, #d4004d)',
];

const styles = () => ({
  box: {
    position: 'relative',
    maxWidth: 210,
    borderStyle: 'solid',
    borderColor: '#554d5633',
    borderRadius: 2,
    borderWidth: 1,
    padding: 12,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000,
    cursor: 'pointer',
    color: '#554d5655',
  },
  colorsBox: {
    width: '100%',
    height: '100%',
  },
  colorItem: {
    width: 34,
    height: 34,
    borderRadius: '50%',
    float: 'left',
    margin: 4,
    pointer: 'cursor',
  },
});
class BackgroundInput extends React.Component {
  state = {
    open: false,
  };

  handleOnChange = (color) => {
    const { name, onChange } = this.props;
    onChange(name, color);
    this.setState({ open: false });
  };

  render() {
    const { value, classes } = this.props;
    const { open } = this.state;
    return !open ? (
      <CssBackgroundPreview
        value={value}
        onClick={() => this.setState({ open: true })}
      />
    ) : (
      <div className={classes.box}>
        <CloseButton
          className={classes.closeButton}
          onClick={() => this.setState({ open: false })}
        />
        <div className={classes.colorsBox}>
          {backgrounds.map((bg) => (
            <div
              style={{ background: bg }}
              className={classes.colorItem}
              onClick={() => this.handleOnChange(bg)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BackgroundInput);
