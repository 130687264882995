import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goTo } from '../../../actions/navigator'
import {
  getResource,
  getResources,
  deleteResource,
  refreshResources,
  updateResource,
} from '../../../actions/admin'
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  ADMIN_VIEW,
  router,
  ADMIN_EXTERNAL_COURSES_COMMISSIONS,
  ADMIN_COMMISSIONS,
  ADMIN_COMMISSIONS_USERS,
  ADMIN_DUPLICATE,
} from '../../../config/routes'
import AdminResourceIndex from '../AdminResourceIndex'
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType,
  EditInputObjectModel,
  CommissionStatus,
  FacilitatorType,
  FacilitatorTypes,
  Modalities,
  CommissionStatusDescription,
} from '../../../config/constants'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/People'
import LinkIcon from '@material-ui/icons/Link'
import PublishedIcon from '@material-ui/icons/Visibility'
import UnpublishedIcon from '@material-ui/icons/VisibilityOff'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ConfirmDialog from '../../../components/ui/ConfirmDialog'
import { addSnackbar } from '../../../actions/snackbar'
import api from '../../../api/api'
import moment from 'moment'
import FileSaver from 'file-saver'
import SvgIcon from '../../../components/ui/SvgIcon'
import CommissionsIcon from '@material-ui/icons/Event'
import ClassesModal from '../../../components/admin/external_courses/ClassesModal/ClassesModal'

export function getClassesByCommisionId(id) {
  return api.AdminExternalCourse.getClassesByCommissionId(id)
}

const PARENT_EXTERNAL_COURSE_KEYNAME = 'external-course-resource'

class AdminExternalCoursesCommissionsContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
    commissionSelected: null,
    classItems: [],
    errorsDetail: '',
  }

  onFetch = (keyName, query, page, size) => {
    const { getResource, getResources, externalCourseId = '0' } = this.props
    getResource(PARENT_EXTERNAL_COURSE_KEYNAME, 'external_courses', externalCourseId)
    getResources(`external_courses/${externalCourseId}/commissions`, keyName, query, page, size)
  }

  onFetchClasses = commisionId => {
    if (this.state.openClasses) {
      return getClassesByCommisionId(commisionId)
    }
  }

  renderFacilitators = item => {
    if (item.facilitators) {
      const names = item.facilitators.map(f => {
        const type = FacilitatorTypes.find(ft => ft.id === f.facilitator_type).description
        if ([FacilitatorType.Multiplier, FacilitatorType.Speaker].includes(f.facilitator_type)) {
          return `${f.user.last_name} ${f.user.first_name} (${type})`
        } else {
          return `${f.external_facilitator} (${type})`
        }
      })
      return names.join(', ').toUpperCase()
    }
    return '-'
  }

  renderPlace = item => {
    if (item.place[0]) {
      return item.place[0].institution
    }
    return '-'
  }

  renderNps = item => {
    return item.nps === null ? '-' : item.nps
  }

  renderPlataform = item => {
    return item.platform?.name_type ?? '-'
  }

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Fecha y hora de inicio', source: 'start_date', type: AdminItemViewType.Datetime },
      { title: 'Duración (min)', source: 'duration' },
      {
        title: 'Fecha de cierre de inscripción',
        source: 'registration_deadline',
        type: AdminItemViewType.Datetime,
      },
      { title: 'Cupo mínimo', source: 'minimum_quota' },
      {
        title: 'Estado',
        source: 'status',
        type: AdminItemViewType.Conditional,
        conditions: CommissionStatusDescription.map(csd => ({
          condition: '' + csd.id,
          result: csd.description,
        })),
      },
      {
        title: 'Modalidad',
        source: 'modality',
        type: AdminItemViewType.Conditional,
        conditions: Modalities.map(m => ({
          condition: `${m.id}`,
          result: m.description,
        })),
      },
      {
        title: 'Institución',
        source: 'place',
        type: AdminItemViewType.Custom,
        renderItem: this.renderPlace,
      },
      {
        title: 'Plataforma',
        source: 'platform',
        type: AdminItemViewType.Custom,
        renderItem: this.renderPlataform,
      },
      {
        title: 'Cantidad de inscriptos',
        source: 'registered_count',
        type: AdminItemViewType.Number,
      },
      {
        title: 'Facilitadores',
        source: 'facilitators',
        type: AdminItemViewType.Custom,
        renderItem: this.renderFacilitators,
      },
      {
        title: 'NPS',
        source: 'nps',
        type: AdminItemViewType.Custom,
        renderItem: this.renderNps,
      },
      {
        title: 'Cantidad de clases',
        source: 'commission_dates_count',
        type: AdminItemViewType.Number,
      },
    ]
  }

  onCreate = () => {
    const { externalCourseId = '0' } = this.props
    this.props.goTo(
      router.getRoute(ADMIN_EXTERNAL_COURSES_COMMISSIONS + ADMIN_NEW, { externalCourseId }),
    )
  }

  onEditItem = item => {
    const { externalCourseId = '0' } = this.props
    this.props.goTo(
      router.getRoute(ADMIN_EXTERNAL_COURSES_COMMISSIONS + ADMIN_EDIT, {
        externalCourseId,
        id: item.id,
      }),
    )
  }

  onDuplicateCommission = item => {
    const { externalCourseId = '0' } = this.props
    this.props.goTo(
      router.getRoute(ADMIN_EXTERNAL_COURSES_COMMISSIONS + ADMIN_DUPLICATE, {
        externalCourseId,
        id: item.id,
      }),
    )
  }

  onViewItem = item => {
    this.props.goTo(router.getRoute(ADMIN_COMMISSIONS_USERS, { commissionId: item.id }))
  }

  onQRItem = async item => {
    const { externalCourse } = this.props
    var newDateFormat = item.start_date.replace(/(\d+[/])(\d+[/])/, '$2$1')
    var newDate = moment(new Date(newDateFormat)).format('DDMMYYYY HH:mm')
    await api.Admin.downloadCommissionQr(item.id).then(file =>
      FileSaver.saveAs(file, `QR ${externalCourse.item.name} ${newDate}.pdf`),
    )
  }

  onChangeVisibility = async item => {
    try {
      await api.AdminExternalCourse.changeVisibility(item.id)
      this.props.addSnackbar('Comisión actualizada.', SnackbarType.Success)
      this.props.refreshResources(this.getKeyName())
    } catch (e) {}
  }

  onDeleteItem = item => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.id,
    })
  }

  getActions = item => {
    const actions = []

    if (!item.canceled && item.start_date >= moment().format('YYYY-MM-DD HH:mm')) {
      actions.push({
        title: 'CANCELAR',
        action: this.onDeleteItem,
        icon: <CancelIcon />,
      })
    }

    if (!item.canceled && moment().isBefore(moment(item.start_date).subtract(2, 'h'))) {
      actions.push({
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      })
    }

    if (!item.canceled && item.status === CommissionStatus.RegistrationOpen) {
      actions.push({
        title: item.visible ? 'OCULTAR' : 'MOSTRAR',
        action: this.onChangeVisibility,
        icon: item.visible ? <UnpublishedIcon /> : <PublishedIcon />,
      })
    }

    actions.push({
      title: 'INSCRIPTOS',
      action: this.onViewItem,
      icon: <ListIcon />,
    })

    actions.push({
      title: 'QR',
      action: this.onQRItem,
      icon: <SvgIcon iconName={'ic_qrcode'} style={{ fontSize: 16 }} aria-hidden={true} />,
    })

    actions.push(
      {
        title: 'COPIAR LINK',
        action: item =>
          navigator.clipboard.writeText(
            `${window.location.origin}/profile?showPresent=1&commissionId=${item.id}`,
          ),
        icon: <LinkIcon />,
      },
      {
        title: 'DUPLICAR',
        action: this.onDuplicateCommission,
        icon: <FileCopyOutlinedIcon />,
      },
      {
        title: 'CLASES',
        action: this.onShowClasses,
        icon: <CommissionsIcon />,
      },
    )

    return actions
  }

  onShowItem = item => {
    this.props.goTo(router.getRoute(ADMIN_COMMISSIONS + ADMIN_VIEW, { id: item.id }))
  }

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'commission.id',
        placeholder: 'ID',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.CommissionPlaces,
        displayAttribute: 'name_type',
        name: 'commission.site',
        placeholder: 'Lugar',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Facilitators,
        displayAttribute: 'description',
        name: 'facilitator.id',
        placeholder: 'Facilitador',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Date,
        name: 'd.startDate',
        placeholder: 'Fecha',
        helpText: 'Fecha de inicio',
        format: FiltersFormat.Fecha,
      },
      {
        type: EditInputType.Date,
        name: 'commission.registration_deadline',
        placeholder: 'Fecha de cierre de inscripción',
        helpText: 'Fecha de cierre de inscripción',
        format: FiltersFormat.Fecha,
      },
      {
        type: EditInputType.Select,
        name: 'commission.status',
        placeholder: 'Estado',
        format: FiltersFormat.Plain,
        options: CommissionStatusDescription.map(csd => ({
          id: csd.id,
          description: csd.description,
        })),
      },
    ]
  }

  deleteItem = async () => {
    const { refreshResources, addSnackbar } = this.props
    const item = this.state.deleteItem
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
    try {
      await api.AdminExternalCourse.cancel(item.id)
      addSnackbar('Comisión cancelada.', SnackbarType.Success)
      refreshResources(this.getKeyName())
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    }
  }

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    })
  }

  getKeyName = () => {
    return 'admin-external-courses-commissions'
  }

  onShowClasses = async item => {
    let result = await getClassesByCommisionId(item.id)
    this.setState({
      commissionSelected: item.id,
      classItems: result.data,
      openClasses: true,
    })
  }

  render() {
    const { externalCourse } = this.props
    const title =
      'COMISIONES DEL TALLER' +
      (externalCourse && externalCourse.item ? `: "${externalCourse.item.name}"` : '')
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={title}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        onCreateTopCourses={this.onCreateTopCourses}
      >
        <ConfirmDialog
          title={'Cancelar comisión'}
          open={this.state.confirmDeleteOpen}
          description={`¿Estás seguro de cancelar la comisión "${this.state.itemToDelete}"?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
          cancelText={'NO'}
        />
        <ClassesModal
          open={this.state.openClasses}
          onClose={() => this.setState({ openClasses: false })}
          onFetch={this.state.classItems}
        />
      </AdminResourceIndex>
    )
  }
}

AdminExternalCoursesCommissionsContainer.defaultProps = {
  type: 'commissions',
}

const mapStateToProps = state => {
  const states = state.admin.resource
  return {
    externalCourse: { ...states[PARENT_EXTERNAL_COURSE_KEYNAME] },
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResource,
      getResources,
      refreshResources,
      deleteResource,
      goTo,
      addSnackbar,
      updateResource,
    },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminExternalCoursesCommissionsContainer)
