import React, { Component } from 'react';
import GenericDialog from '../../components/ui/GenericDialog';
import SvgIcon from '../../components/ui/SvgIcon';
import ColorButton from '../../components/ui/ColorButton';
import { Button, Typography, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/es/TextField/TextField';
import Switch from '@material-ui/core/Switch';
import validator from 'validator';
import NpsScore from '../ui/NpsScore';
import { FiltersFormat, EditInputType, EditInputObjectModel } from '../../config/constants';
import AdminResourceForm from '../admin/AdminResourceForm';


const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      // minHeight: 550,
      margin: 'auto',
      alignItems: 'center',
      marginBottom: 4,
      [mediaLg]: {
        marginBottom: 10,
      },
    },

    nps: {
      marginTop: 10,
      margin: 'auto',
      textAlign: 'center',
    },

    text: {
      textAlign: 'center',
      color: '#575757',
    },

    actions: {
      margin: 'auto',
      marginTop: 18,
      marginBottom: 18,
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
    },

    actionSave: {
      backgroundColor: '#575454',
      marginRight: 16,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'rgba(87,84,84,0.91)',
      },
    },

    actionCancel: {
      color: '#575454',
      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },

    buttonValorar: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
    },
    actionValorarSolo: {
      width: 203,
      height: 58,
      fontSize: 12,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.35,
      margin: 'auto',
      marginTop: 25,
      marginBottom: 25,
    },

    switchDiv: {
      fontSize: 14,
      [mediaLg]: {
        marginTop: 2,
      },
    },

    autorizo: {
      fontSize: 14,
      marginTop: 10,
      width: '70%',
      margin: 'auto',
      [mediaLg]: {
        marginTop: 2,
      },
    },

    buttonLabel: {
      textTransform: 'none',
    },

    buttonLabelSave: {
      textTransform: 'none',
      color: '#f0f0f0f0',
    },

    inputRoot: {
      fontSize: 14,
      width: 430,

      marginBottom: -7,
      [mediaLg]: {
        width: 'auto',
      },
    },

    textField: {
      marginBottom: 7,
      margin: 'auto',
    },

    Label: {
      fontSize: 14,
      textAlign: 'justify',
      marginLeft: -25,
      paddingRight: 25,
      color: '#575454',
    },

    titleValorar: {
      fontSize: 15,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      color: '#575454',
    },

    valorar: {
      marginTop: 15,
      textAlign: 'right',
    },

    actionValorar: {
      width: 203,
      height: 58,
    },

    share: {
      marginTop: 15,
      textAlign: 'left',
    },

    actionShare: {
      height: 58,
      color: '#ffffff'
    },

    LabelFirstText: {
      fontSize: 16.5,
      textAlign: 'center',
      fontWeight: 600,
      paddingLeft: 40,
      paddingRight: 40,
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },

    LabelSecondText: {
      fontSize: 12,
      textAlign: 'center',
      width: '60%',
      margin: 'auto',
      [mediaLg]: {
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
    },
    labelAutorizo: {
      fontSize: 12,
      color: '#575454',
    },

    error: {
      fontSize: 10,
      color: 'red',
    },
    switch: {
      marginLeft: '14%',
      width: '70%',
      display: 'inline-flex',
    },
    contentValorar: {
      margin: 2
    }
  };
};

const initialState = {
  isValid: false,
  initialRating: null,
  stars: false,
  enable_publish: false,
  comment: '',
  showValorar: false,
  showShare: false,
  errors: { comment: '', nps: '' },
};

class CourseDoneDialog extends Component {
  state = { ...initialState };

  static getDerivedStateFromProps(nextProps, prevState) {
    return { initialRating: nextProps.initialRating };
  }

  resetDialog = () => {
    this.setState({ ...initialState });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validateForm();
    });
  };

  handleSwitch = (event) => {
    this.setState({ [event.target.name]: event.target.checked }, () => {
      this.validateForm();
    });
  };

  handleNps = (valor) => {
    this.setState({ stars: valor }, () => {
      this.validateForm();
    });
  };


  validateForm = () => {
    let error = false;
    let errors = {
      comment: '',
      nps: '',
    };

    if (!validator.isLength(this.state.comment.trim(), { min: 40, max: 300 })) {
      errors.comment =
        'Este campo debe tener entre 40 y 300 caracteres.';
      error = true;
    }

    if (this.state.stars === false && this.state.initialRating == null) {
      errors.nps =
        'Este campo es obligatorio';
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  showValorar = () => {
    if (this.state.showShare) {
      this.setState({ showShare: !this.state.showShare });
    }
    this.setState({ showValorar: !this.state.showValorar });
  };



  style = (value) => {
    const {  headerColor } = this.props;
    if (value) {
      return { backgroundColor: headerColor, border: 'solid 1.5px' + headerColor, color: '#fff' };
    } else {
      return { color: headerColor, border: 'solid 1.5px' + headerColor };
    }
  };

  getColumns = () => {
    return [{
      type: EditInputType.Object,
      model: EditInputObjectModel.Users,
      name: 'user.id',
      placeholder: 'Usuario',
      multiple: true,
      format: FiltersFormat.In,
      displayAttribute: 'full_name',
    }];
  };

  find = () => {
  }
  change = () => {
  }

  renderContent = () => {
    const { classes, onSubmit, headerColor, course } = this.props;
    const { showValorar, showShare } = this.state;
    return (
      <div className={classes.content}>
        <div className={classes.switchDiv}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className={classes.LabelFirstText} style={{ color: headerColor }}>
                {'Terminaste el curso "' + course.title + '"'}
              </Typography>
            </Grid>
            <Grid style={{ marginBottom: !course.comments_enabled ? 25 : null}}>
              <Typography className={classes.LabelSecondText} style={{ color: headerColor }}>
                {'Recordá que si se agrega nuevo contenido a este curso se te informará por mail para que puedas completarlo nuevamente'}
              </Typography>
            </Grid>
          </Grid>

          {course.comments_enabled && (
          <Grid container spacing={3}>
            <Button
              style={this.style(this.state.showValorar)}
              variant={'outlined'}
              className={classes.actionValorarSolo}
              classes={{ label: classes.buttonValorar }}
              onClick={() => this.showValorar()}
            >
              {'VALORÁ ESTE CURSO'}
            </Button>
          </Grid>
          )}
        </div>
        {showValorar && !showShare &&
          <Grid container spacing={3} className={classes.contentValorar}>
            <div className={classes.nps}>
              <Typography className={classes.titleValorar}>
                {'Del 0 al 10, ¿cuánto recomendarías este curso a un colega?'}
              </Typography>
              <NpsScore className={classes.nps} color={headerColor} onChange={(s) => { this.handleNps(s); }} />
              <Typography className={classes.error}>
                {this.state.errors.nps}
              </Typography>
            </div>
            <div className={classes.nps}>
              <TextField
                id="comment"
                name="comment"
                placeholder={'Contanos qué te gustó y qué mejorarías? Tu feedback nos ayuda!'}
                value={this.state.comment}
                margin="normal"
                variant="outlined"
                type="text"
                multiline={true}
                rows={4}
                rowsMax={6}
                onChange={this.handleChange}
                className={classes.textField}
                InputProps={{
                  classes: { root: classes.inputRoot },
                }}
              />
              <Typography className={classes.error}>
                {this.state.errors.comment}
              </Typography>
            </div>
            <Grid item xs={12}>
              <div className={classes.switch}>
                <Switch
                  checked={this.state.enable_publish}
                  onChange={this.handleSwitch}
                  color="primary"
                  name="enable_publish"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography className={classes.labelAutorizo}>
                  {'Autorizo a que mi comentario sea identificado con nombre y apellido para ser contactado para dar feedback sobre el curso'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.actions}>
              <ColorButton
                disabled={!this.state.isValid}
                color={'#3abf91'}
                className={classes.actionSave}
                classes={{ label: classes.buttonLabel }}
                label={'Enviar'}
                onClick={() =>
                  onSubmit({
                    rating: this.state.stars || this.state.initialRating,
                    comment: this.state.comment.trim(),
                    enable_publish: this.state.enable_publish,
                  })
                }
              />
            </Grid>
          </Grid>
        }
        {!showValorar && showShare &&
          <Grid container spacing={3} className={classes.contentValorar}>
            <div className={classes.nps}>
              <Typography className={classes.titleValorar}>
                {'Recomenda el curso a un amigo'}
              </Typography>
            </div>
            <Grid item xs={12}>
              <AdminResourceForm
                title={'title'}
                keyName={'course-top'}
                form={[]}
                isValid={true}
                errors={[]}
                loading={false}
                attributes={{ keys: this.getColumns(classes) }}
                onSubmit={() => this.find()}
                onGoBack={false}
                onAttributeChange={() => this.change()}
                customClasses={classes}
              />
            </Grid>
            <Grid item xs={12} className={classes.actions}>
              <ColorButton
                disabled={!this.state.isValid}
                color={'#3abf91'}
                className={classes.actionSave}
                classes={{ label: classes.buttonLabel }}
                label={'Enviar'}
                onClick={() =>
                  onSubmit({
                    rating: this.state.stars || this.state.initialRating,
                    comment: this.state.comment.trim(),
                    enable_publish: this.state.enable_publish,
                  })
                }
              />
            </Grid>
          </Grid>
        }

      </div>
    );
  };

  render() {
    const { open, onDismiss, headerColor } = this.props;
    return (
      <GenericDialog
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        name={''}
        icon={
          <SvgIcon iconName={'ic_stars_3'} style={{ fontSize: 95 }} aria-hidden={true} />
        }
        congratulations={'¡Felicitaciones!'}
        onExit={this.resetDialog}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}
export default withStyles(styles)(CourseDoneDialog);
