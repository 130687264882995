export const new_dialog_styles = theme => ({
  root: {
    width: '100%',
  },
  error: {
    fontFamily: 'Roboto',
    color: '#be0000',
    margin: `${theme.spacing.unit}px`,
    textAlign: 'center'
  },
  dialogContent: {
    backgroundColor: '#ffffff',
    minHeight: 200,
    textAlign: 'center',
    overflowX: 'hidden'
  },
  dialogHeader: {
    color: '#000000',
    backgroundColor: '#ffffff',
    paddingLeft: 32,
    paddingRight: 32,
    minHeight: 72,
    position: 'relative',
    zIndex: 10
  },
  dialogHeaderUser: {
    float: 'left',
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 16,
    paddingBottom: 16,
  },
  headerIconCenter: {
    color: '#ed1818',
    position: 'absolute',
    right: 'calc(50% - 18px)',
    bottom: -16,
    width: 36
  },
  headerIconRight: {
    height: 32,
    width: 32,
    color: '#00010a',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0
  },
  headerIcon: {
    height: 16,
    width: 16,
  },

  userAvatar: {
    margin: 'auto',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.16)',
  },
  userInfoContent: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 8
  },
  userInfoItem: {
    marginTop: 16,
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  userName: {
    opacity: 0.47,
    fontWeight: 700,
    fontSize: 12,
    color: '#00010a0',
  },
  userInfo: {
    opacity: 0.47,
    fontWeight: 300,
    fontSize: 12,
    color: '#00010a0'
  },
  stepTitle: {
    margin: 'auto',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 25,
    maxWidth: 350,
    marginBottom: 8
  },

  headerAboutText: {
    color: '#848484',
    fontFamily: 'Roboto',
    fontSize: 15,
    textAlign: 'center',
    fontWeight: 400
  },

  headerText: {
    color: '#ffffff',
    fontFamily: 'Roboto',
    fontSize: 20,
    textAlign: 'center',
    marginRight: 40,
    marginLeft: 40,
    paddingTop: 16
  },
  searchField: {
    fontFamily: 'Roboto',
    textAlign: 'center'
  },
  detailsField: {
    marginTop: 16
  },
  searchFormControl: {
    marginBottom: 32,
    textAlign: 'center'
  },
  formControl: {
    maxWidth: 500,
    width: '100%',
    position: 'relative'
  },
  selectBehavior: {
    marginTop: `${theme.spacing(2)}px`
  },
  textField: {
    fontFamily: 'Roboto',
    margin: '8px 0 0 0',
  },
  input: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  chip: {
    margin: theme.spacing(1),
  },
  actions: {
    textAlign: 'center',
    marginBottom: 16
  },

  actionButton: {
    minWidth: 190,
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 8
  },
  whiteText: {
    color: '#ffffff',
    fontFamily: 'Roboto',
  },
  circle: {
    height: 8,
    width: 8,
    position: 'relative',
    top: 0,
    marginRight: 4
  },
  selectField: {
    width: '100%',
    margin: 'auto',
    maxWidth: 250,
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'Roboto',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
  },
});