export const removeDuplicates = (originalArray, prop) => {
  var newArray = []
  var lookupObject = {}

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i]
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i])
  }
  return newArray
}

export const shuffle = arr => {
  let newArray = arr
  const length = newArray.length

  for (let i = 0; i < length; i++) {
    const rand_index = Math.floor(Math.random() * length)

    const rand = newArray[rand_index]

    newArray[rand_index] = newArray[i]
    newArray[i] = rand
  }

  return newArray
}
