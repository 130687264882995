import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgress, Input } from '@material-ui/core'
import { KEY_KEYS } from '../../../constants'
import { EditOutlined, SaveOutlined } from '@material-ui/icons'

const ApprovedInput = ({ initialValue, editing, saveNewValue }) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [value, setValue] = useState()
  const handleSaveNewValue = useCallback(async () => {
    setDisabled(true)
    if (value !== initialValue) {
      setLoading(true)
      await saveNewValue(value)
    }
  }, [value])

  useEffect(() => {
    if (!editing) {
      setLoading(false)
    }
  }, [editing])

  useEffect(() => {
    setValue(initialValue ?? 0)
  }, [initialValue])

  return (
    <div
      style={{
        display: 'flex',
        width: '5.5rem',
        gap: '1rem',
        alignItems: 'center',
        height: '2rem',
      }}
    >
      <>
        {loading ? (
          <CircularProgress size={16} style={{ marginLeft: '1rem', color: 'GrayText' }} />
        ) : (
          <>
            <Input
              type="number"
              inputProps={{
                style: { textAlign: 'right' },
                max: 100,
                min: 0,
                type: 'number',
                step: '1',
              }}
              value={value}
              disabled={disabled}
              onChange={({ target }) => setValue(Number(target.value))}
              onKeyPress={e => {
                const { key } = e
                if (['.', ',', 'e'].includes(key)) {
                  e.preventDefault()
                }
                if (key === KEY_KEYS.ENTER) {
                  handleSaveNewValue()
                }
              }}
            />
            {disabled ? (
              <EditOutlined style={{ cursor: 'pointer' }} onClick={() => setDisabled(false)} />
            ) : (
              <SaveOutlined style={{ cursor: 'pointer' }} onClick={handleSaveNewValue} />
            )}
          </>
        )}
      </>
    </div>
  )
}

export default ApprovedInput
