import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ProgressWithIcon from './ProgressWithIcon'
import Dialog from '@material-ui/core/Dialog'
import background from '../../assets/images/pasaporte.png'
import escala1 from '../../assets/images/escala1.png'
import escala2 from '../../assets/images/escala2.png'
import escala3 from '../../assets/images/escala3.png'
import escala4 from '../../assets/images/escala4.png'
import escala5 from '../../assets/images/escala5.png'
import escala6 from '../../assets/images/escala6.png'
import Arrow from '@material-ui/icons/KeyboardArrowRight'
import { minutesToDurationText } from '../../utils/FormatHelper'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    containerHeaderMyCareerPlans: {
      backgroundColor: 'grey',
      justifyContent: 'center',
      minHeight: 108.5,
      paddingTop: 16,
      paddingBottom: 24,
      paddingLeft: '5.5%',
      paddingRight: '5.5%',
    },
    wrapperProgress: {
      marginTop: 15,
    },
    titleHeaderMyCareerPlans: {
      fontFamily: 'Open Sans',
      fontSize: 24,
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: '#ffffff',
    },
    durationTotalTitle: {
      marginBottom: '15px',
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 600,
      fontStyle: 'normal',
      color: '#ffffff',
    },
    durationTotalValor: {
      marginBottom: '15px',
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: '#ffffff',
    },
    chip: {
      height: 26,
      color: '#fff',
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'normal',
      marginRight: 5,
    },
    doubleArrow: {
      position: 'relative',
      bottom: '-2px',
      marginRight: 7,
      width: 20,
      height: 15,
    },
    buttonViewMap: {
      display: 'inline-flex',
      alignItems: 'center',
      width: 190,
      height: 60,
      padding: 0,
      marginTop: 15,
      borderRadius: 9,
      backgroundColor: '#ffffff',
      borderWidth: 0.5,
      borderStyle: 'solid',
      fontFamily: 'PlaylistScript',
      fontSize: 26,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'center',
      color: '#34353d',
      '&:hover': {
        backgroundColor: '#000000',
        color: '#ffffff',
        cursor: 'pointer',
      },
      [mediaLg]: {
        marginTop: '50px !important',
        marginRight: '30px',
      },
    },
    wrapperIcons: {
      margin: '10px 0px 0px 10px',
    },
    arrow: {
      position: 'relative',
      left: 10,
    },
    arrowNegative: {
      color: 'red',
    },
    modalContent: {
      backgroundImage: `url(${background})`,
      backgroundSize: '100%',
      width: 600,
      height: 444,
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    },
    EscalaModal: {
      flexFlow: 'column',
      paddingTop: 240,
    },
    modalLeft: {
      marginTop: -314,
      marginLeft: '8%',
      width: 600,
      height: 400,
    },
    modalRigth: {
      marginTop: -320,
      width: 600,
      height: 444,
    },
    IcoModal: {
      display: 'flex',
      paddingTop: 20,
    },
    iconPassaporte: {
      marginLeft: 15,
      marginRight: 15,
      marginTop: -18,
    },
    titleEscala: {
      marginLeft: 15,
    },
  }
}

function HeaderCareerPlansExpandedWithStyles({
  classes,
  name,
  channels,
  total_courses_duration,
  mandatory_courses_duration,
  suggested_courses_duration,
  map,
  user_progress,
  currentPlan,
  titleButton,
}) {
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [open, setOpen] = React.useState(false)
  const styleChip = background => {
    return {
      backgroundColor: background,
    }
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <>
      <Grid item container xs={12} className={classes.containerHeaderMyCareerPlans}>
        <Grid item xs={12} md={8} justify="center" alignItems="center" container direction="row">
          <Grid item xs={12}>
            <Typography component={'h2'} className={classes.titleHeaderMyCareerPlans}>
              Ruta de Aprendizaje: {name}.
            </Typography>
            <Typography role={'p'} className={classes.durationTotalTitle}>
              Duración Total:{' '}
              <span className={classes.durationTotalValor}>
                {minutesToDurationText(total_courses_duration) + '.'}
              </span>
            </Typography>

            {channels.map(channel => (
              <Chip
                role={'text'}
                label={channel.description + '.'}
                className={classes.chip}
                key={channel.id}
                style={styleChip(channel.color)}
              />
            ))}
          </Grid>
          <Grid item xs={12} className={classes.wrapperProgress}>
            <ProgressWithIcon
              {...user_progress.mandatory}
              title={'Necesarios'}
              duration={mandatory_courses_duration}
            />

            {user_progress.suggested.total > 0 && (
              <ProgressWithIcon
                {...user_progress.suggested}
                title={'Complementarios'}
                duration={suggested_courses_duration}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          justify="space-evenly"
          alignItems="flex-end"
          container
          direction="column"
        >
          {map && (
            <button
              role="link"
              className={classes.buttonViewMap}
              onClick={() => window.open(`${map}`)}
            >
              <div className={classes.wrapperIcons}>
                <Arrow className={classes.arrow} />
                <Arrow />
              </div>
              {titleButton}
            </button>
          )}
          <button
            aria-hidden="true"
            tabIndex={0}
            className={classes.buttonViewMap}
            onClick={handleOpen}
          >
            <div className={classes.wrapperIcons}>
              <Arrow className={classes.arrow} />
              <Arrow />
            </div>
            Pasaporte
          </button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <div className={classes.modalContent}>
          <div className={classes.modalLeft}>
            <Grid container className={classes.EscalaModal}>
              {currentPlan.scales.length > 0 &&
                currentPlan.scales.map(
                  itemScales =>
                    itemScales.id < 4 && (
                      <>
                        <div className={classes.titleEscala}>
                          {(itemScales.mandatory_completed === 1 ||
                            itemScales.suggested_completed === 1) && (
                            <>
                              {itemScales.id === 1 && (
                                <img src={escala1} width="120px" height="30px" alt={'x1'} />
                              )}
                              {itemScales.id === 2 && (
                                <img src={escala2} width="120px" height="30px" alt={'x2'} />
                              )}
                              {itemScales.id === 3 && (
                                <img src={escala3} width="120px" height="30px" alt={'x3'} />
                              )}
                            </>
                          )}
                        </div>

                        <Typography className={classes.IcoModal}>
                          {itemScales.mandatory_completed > 0 && (
                            <img
                              src={itemScales.stamp_mandatory}
                              width="75px"
                              height="75px"
                              className={classes.iconPassaporte}
                              alt={'mandatory'}
                            />
                          )}
                          {itemScales.suggested_completed > 0 && (
                            <img
                              src={itemScales.stamp_suggested}
                              width="75px"
                              height="75px"
                              className={classes.iconPassaporte}
                              alt={'suggested'}
                            />
                          )}
                        </Typography>
                      </>
                    ),
                )}
            </Grid>
          </div>
          <div className={classes.modalRigth}>
            <Grid container className={classes.EscalaModal}>
              {currentPlan.scales.length > 0 &&
                currentPlan.scales.map(
                  itemScales =>
                    itemScales.id > 3 && (
                      <>
                        <div className={classes.titleEscala}>
                          {(itemScales.mandatory_completed === 1 ||
                            itemScales.suggested_completed === 1) && (
                            <>
                              {itemScales.id === 4 && (
                                <img src={escala4} width="120px" height="30px" alt={'x4'} />
                              )}
                              {itemScales.id === 5 && (
                                <img src={escala5} width="120px" height="30px" alt={'x5'} />
                              )}
                              {itemScales.id === 6 && (
                                <img src={escala6} width="120px" height="30px" alt={'x6'} />
                              )}
                            </>
                          )}
                        </div>

                        <Typography className={classes.IcoModal}>
                          {itemScales.mandatory_completed > 0 && (
                            <img
                              src={itemScales.stamp_mandatory}
                              width="80px"
                              height="80px"
                              className={classes.iconPassaporte}
                              alt={'mandatory'}
                            />
                          )}
                          {itemScales.suggested_completed > 0 && (
                            <img
                              src={itemScales.stamp_suggested}
                              width="80px"
                              height="80px"
                              className={classes.iconPassaporte}
                              alt={'suggested'}
                            />
                          )}
                        </Typography>
                      </>
                    ),
                )}
            </Grid>
          </div>
        </div>
      </Dialog>
    </>
  )
}

HeaderCareerPlansExpandedWithStyles.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  map: PropTypes.string.isRequired,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  user_progress: PropTypes.shape({
    mandatory: PropTypes.shape({
      done: PropTypes.number,
      total: PropTypes.number,
    }).isRequired,
    suggested: PropTypes.shape({
      done: PropTypes.number,
      total: PropTypes.number,
    }).isRequired,
  }),
  titleButton: PropTypes.string.isRequired,
}

const HeaderPreviousCareerPlansExpanded = withStyles(styles)(HeaderCareerPlansExpandedWithStyles)

export default HeaderPreviousCareerPlansExpanded
