import api from '../api/api'
import { CALENDAR_READ_START, CALENDAR_READ_END, CALENDAR_READ_FAILED } from './index'

export function loadCalendar(year, month) {
  return async dispatch => {
    dispatch({ type: CALENDAR_READ_START })
    try {
      const response = await api.withDispatch(dispatch).ExternalCourses.calendar(year, month)
      dispatch({ type: CALENDAR_READ_END, payload: { events: response.data } })
    } catch (e) {
      dispatch({ type: CALENDAR_READ_FAILED,  payload: { message: e.toString() } })
    }
  }
}
