import React, { useState, useCallback, useEffect } from 'react'
import { Dialog } from '@material-ui/core'
import { withStyles, ThemeProvider } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import EventListenerWrapper from '../ui/EventListenerWrapper'
import TextField from '@material-ui/core/TextField'
import { create_playlist_theme } from '../../themes/create_playlist_theme'

const styles = theme => ({
  root: {
    width: '100%',
  },
  paperDialog: {
    borderRadius: 0,
    backgroundColor: '#101010',
    paddingTop: 8,
    paddingBottom: 8,
  },
  paperWidthSm: {
    maxWidth: 700,
  },

  playlistRow: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: 'solid 0.5px #707070',
    cursor: 'pointer',
    paddingRight: 32,
    paddingLeft: 32,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  playlistName: {
    color: '#ffffff',
    fontSize: 16,
    flexGrow: 1,
  },

  addIcon: {
    color: '#ffffff',
    fontSize: 20,
    marginRight: 8,
  },
})


const EditPlaylistItem = ({ classes, onUpdatePlaylist, playlist }) => {
  const [name, updateName] = useState( '')
  useEffect(()=>{
    if(playlist && playlist.name) {
      updateName(playlist.name)
    }
  },[playlist])

  const editPlaylist = useCallback(() => {
    onUpdatePlaylist(name)
  }, [name, onUpdatePlaylist, updateName])
  return (
    <React.Fragment>
      <div className={classes.playlistRow}>
        <AddIcon className={classes.addIcon} />
        <ThemeProvider theme={create_playlist_theme}>
          <EventListenerWrapper
            TagType={TextField}
            label="Modificar Nombre"
            fullWidth={true}
            value={name}
            onEnter={() => editPlaylist()}
            onChange={e => updateName(e.target.value)}
          />
        </ThemeProvider>
      </div>
    </React.Fragment>
  )
}

const EditPlaylistDialog = ({
  classes,
  playlist,
  open,
  handleClose,
  onExit,
  onUpdatePlaylist,
}) => {
  return (
    <Dialog
      onBackdropClick={handleClose}
      onEscapeKeyDown={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      className={classes.root}
      classes={{
        paper: classes.paperDialog,
        paperWidthSm: classes.paperWidthSm,
      }}
      open={open}
      onExit={onExit}
    >
      <div>
        <EditPlaylistItem
          key={'edit-playlist-item'}
          classes={classes}
          onUpdatePlaylist={onUpdatePlaylist}
          playlist={playlist}
          handleClose={handleClose}
        />
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(EditPlaylistDialog)
