import { getBase64 } from './FileHelper'

export const generateFileObject = async file => {
  return {
    file: await getBase64(file),
    file_extension: file.name.split('.').pop(),
    preview: URL.createObjectURL(file),
  }
}

export const convertToBase64 = async file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(file)
  })
}
