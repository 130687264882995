import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminResourceIndex from '../AdminResourceIndex';
import { getResources, refreshResources, cleanResource, unblockResource, downResource, activeResource } from '../../../actions/admin'
import { goTo } from '../../../actions/navigator';
import { ADMIN_USERS, ADMIN_VIEW, ADMIN_EDIT, router, ADMIN_NEW } from '../../../config/routes';
import EditIcon from '@material-ui/icons/Edit';
import {
  AdminItemViewType,
  FiltersFormat,
  EditInputType,
  EditInputObjectModel
} from '../../../config/constants';
import ViewIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import KeyIcon from '@material-ui/icons/VpnKey'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { SnackbarType } from '../../../config/constants';
import { isOpenBankUser } from './../../../utils/AdminHelper'
import { addSnackbar } from '../../../actions/snackbar';

class AdminUsersContainer extends Component {

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size, undefined );
  }

  getKeyName = () => {
    return 'admin-users'
  }

  getColumns = () => {
    const { me } = this.props

    return [
      {
        title: 'Nombre y Apellido',
        source: 'first_name last_name',
        type: AdminItemViewType.Formatted,
        format: '{1}, {0}',
      },
      {
        title: 'Usuario',
        source: isOpenBankUser(me.user) ? 'record' : 'username',
        type: AdminItemViewType.Text,
      },
      { title: 'Email', source: 'email' },
      {
        title: 'Roles',
        source: 'roles_as_string',
        type: AdminItemViewType.Text,
      },
      {
        title: 'Activo',
        source: 'active',
        type: AdminItemViewType.Boolean,
      },
      {
        title: isOpenBankUser(me.user) ? 'Area' : 'Centro de costos',
        source: 'organizational_unit.code',
        type: AdminItemViewType.Text,
      }
    ];
  };

  getActions = (item) => {
    const { me } = this.props

    if(isOpenBankUser(me.user)){
      return [
            {
              title: 'VER',
              action: this.onShowItem,
              icon: <ViewIcon />,
            },
            {
              title: 'EDITAR',
              action: this.onEditOpenBankItem,
              icon: <EditIcon />,
            },
            {
              title: 'DESBLOQUEO',
              action: this.onUnblockItem,
              icon: <LockOpenIcon />,
            },
            {
              title: 'BLANQUEO',
              action: this.onCleanItem,
              icon: <KeyIcon />,
            },
            {
              title: item.active ? 'BAJA' : 'ACTIVAR',
              action: item.active ? this.onDownItem : this.onActiveItem,
              icon: item.active ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />,
            },
      ]
    }

    return [
      {
        title: 'VER',
        action: this.onShowItem,
        icon: <ViewIcon />,
      },
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      }
    ];
  };

  onUnblockItem = (item) => {
    const { unblockResource, addSnackbar} = this.props
    unblockResource('users',item.id)
    .then(() => {
      addSnackbar('Usuario desbloqueado exitosamente.', SnackbarType.Success)
    })
    .catch((err) => {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    });
  };

  onCleanItem = (item) => {
    const { cleanResource, addSnackbar } = this.props
    cleanResource('users',item.id)
    .then(() => {
      addSnackbar('Usuario blanqueado exitosamente.', SnackbarType.Success)
    })
    .catch((err) => {
       addSnackbar('Error: ' + err.message, SnackbarType.Error)
    });
  };

  onDownItem = (item) => {
    const { downResource, addSnackbar, refreshResources } = this.props
    downResource('users',item.id)
     .then(() => {
      addSnackbar('Usuario dado de baja exitosamente.', SnackbarType.Success)      
      refreshResources(this.getKeyName())
    })
    .catch((err) => {
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    });
  };

  onActiveItem = (item) => {
    const { activeResource, addSnackbar, refreshResources } = this.props
    activeResource('users',item.id)
     .then(() => {
      addSnackbar('Usuario dado de alta exitosamente.', SnackbarType.Success)      
      refreshResources(this.getKeyName())
    })
    .catch((err) => {
      addSnackbar('Error: ' + err.title, SnackbarType.Error)
    });
  };

  onShowItem = (item) => {
    this.props.goTo(router.getRoute(ADMIN_USERS + ADMIN_VIEW, { id: item.id }));
  };

  onEditItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_USERS + ADMIN_EDIT, { id: item.id })
    );
  };

  onEditOpenBankItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_USERS + ADMIN_EDIT, { id: item.id })
    );
  };

  onCreate = () => {
    this.props.goTo(ADMIN_USERS + ADMIN_NEW);
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Nombre, Apellido o Email',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'full_name',
        fields: ['firstName', 'lastName', 'username'],
      },
      {
        type: EditInputType.Boolean,
        placeholder: 'Activo',
        format: FiltersFormat.Plain,
        name: 'user.active',
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.OrganizationalUnit,
        name: 'user.organizationalUnit',
        placeholder: 'Centro de Costos',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'code',
      }
    ];
  };

  render() {
    const { me } = this.props
    
    return (
      <AdminResourceIndex
        keyName={'admin-users'}
        title={'USUARIOS'}
        getFilterConfig={this.getFilterConfig}
        getActions={this.getActions}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={isOpenBankUser(me.user) ? this.onCreate : null}
      />
    );
  }
}

AdminUsersContainer.defaultProps = {
  type: 'users',
};

const mapStateToProps = state => {
  return {
    me: { ...state.userReducer },
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getResources,
    refreshResources,
    activeResource,
    goTo, 
    cleanResource,
    unblockResource,
    downResource,
    addSnackbar
   }, dispatch);
    
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminUsersContainer);
