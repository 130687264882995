import {
  GET_ASSOCIATES_USERS_NEW_LOT_READ_START,
  GET_ASSOCIATES_USERS_NEW_LOT_READ_END,
  GET_ASSOCIATES_USERS_NEW_LOT_READ_FAILED,
  GET_ASSOCIATES_COURSES_NEW_LOT_READ_START,
  GET_ASSOCIATES_COURSES_NEW_LOT_READ_END,
  GET_ASSOCIATES_COURSES_NEW_LOT_READ_FAILED,
  GET_JOB_POSITIONS_NEW_LOT_READ_START,
  GET_JOB_POSITIONS_NEW_LOT_READ_END,
  GET_JOB_POSITIONS_NEW_LOT_READ_FAILED,
  GET_COMPANY_NEW_LOT_READ_START,
  GET_COMPANY_NEW_LOT_READ_END,
  GET_COMPANY_NEW_LOT_READ_FAILED,
  GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_START,
  GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_END,
  GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_FAILED,
} from '../../actions';

let initialState = {
  error: null,
  loading: false,
  types: [],
  courses: { data:[], columns: coursesColumns},
  users: { data:[], columns: usersColumns,},
  jobPositions:  { data:[], columns: columnsAssociatedJobPositions},
  organizationalUnits:  { data:[], columns:columnsAssociatedOrganizationsUnits},
  company:  { data:[], columns:columnsCompany},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSOCIATES_USERS_NEW_LOT_READ_START:
      return {
        ...state,
        users: [],
        error: null,
        loading: true,
      };
    case GET_ASSOCIATES_USERS_NEW_LOT_READ_END:
      return {
        ...state,
        error: null,
        loading: false,
        users: {
          metadata: action.metadata,
          columns: usersColumns,
          data: action.users.map((user) =>
            mapUserData(user)
          ),
        },
      };
    case GET_ASSOCIATES_USERS_NEW_LOT_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case GET_ASSOCIATES_COURSES_NEW_LOT_READ_START:
      return {
        ...state,
        courses:  { data:[], columns:[]},
        error: null,
        loading: true,
      };
    case GET_ASSOCIATES_COURSES_NEW_LOT_READ_END:
      return {
        ...state,
        error: null,
        loading: false,
        courses: {
          columns: coursesColumns,
          data: action.courses.map((course) =>
            mapCourseData(course)
          ),
        },
      };
    case GET_ASSOCIATES_COURSES_NEW_LOT_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case GET_JOB_POSITIONS_NEW_LOT_READ_END:
      return {
        ...state,
        loading: false,
        jobPositions: {
          columns: columnsAssociatedJobPositions,
          data: action.jobPositions.map((jobPositions) => {
            return createDataAssociateJobPositions(jobPositions)
          }
          ),
          error: null,
        },
      };
    case GET_COMPANY_NEW_LOT_READ_END:
      return {
        ...state,
        loading: false,
        company: {
          columns: columnsCompany,
          data: action.company.map((company) => {
            return createDataCompany(company)
          }
          ),
          error: null,
        },
      };
    case GET_JOB_POSITIONS_NEW_LOT_READ_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_COMPANY_NEW_LOT_READ_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_JOB_POSITIONS_NEW_LOT_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case GET_COMPANY_NEW_LOT_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_END:
      return {
        ...state,
        loading: false,
        organizationalUnits: {
          columns: columnsAssociatedOrganizationsUnits,
          data: action.organizationalUnits.map((OrganizationsUnits) =>
            createDataAssociateOrganizationsUnits(OrganizationsUnits)
          ),
          error: null,
        },
      };
    case GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};

const usersColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "username",
    label: "Usuario",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "first_name",
    label: "Nombre",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "last_name",
    label: "Apellido",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "email",
    label: "email",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
];

const coursesColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "title",
    label: "Título",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "categories",
    label: "Categoría",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "status",
    label: "Estado",
    options: {
      filter: true,
      sort: false
    },
  },
];
const columnsAssociatedJobPositions = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "code",
    label: "Código",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
];

const columnsCompany = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "code",
    label: "Código",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "name",
    label: "Descripción",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
];

const columnsAssociatedOrganizationsUnits = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "code",
    label: "Código",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: "internal_area",
    label: "Área cliente interno",
    options: {
      filter: false,
      sort: false,
      sortDescFirst: false,
    },
  },
];

//method to parsed data from services
function mapCourseData({ id, title, categories, status }) {
  return {
    id,
    title,
    categories,
    status
  };
}

function mapUserData({ id, username, first_name, last_name, email }) {
  return {
    id,
    username,
    first_name,
    last_name,
    email
  };
}

function createDataAssociateJobPositions({ id, code, description }) {
  return {
    id,
    code,
    description,
  };
}

function createDataCompany({ id, code, name }) {
  return {
    id,
    code,
    name,
  };
}

function createDataAssociateOrganizationsUnits({ id, code, description, internal_area }) {
  return {
    id,
    code,
    description,
    internal_area: internal_area ? internal_area : '',
  };
}
