import {
  MY_PREVIOUS_CAREER_PLANS_START,
  MY_PREVIOUS_CAREER_PLANS_END,
  MY_PREVIOUS_CAREER_PLANS_FAILED,
  MY_CAREER_PLANS_START,
  MY_CAREER_PLANS_END,
  MY_CAREER_PLANS_FAILED,
  EXPLORE_CAREER_PLANS_START,
  EXPLORE_CAREER_PLANS_END,
  EXPLORE_CAREER_PLANS_FAILED,
  EXPLORE_CAREER_PLANS_CHANNELS_START,
  EXPLORE_CAREER_PLANS_CHANNELS_END,
  EXPLORE_CAREER_PLANS_CHANNELS_FAILED,
  DETAIL_CAREER_PLANS_START,
  DETAIL_CAREER_PLANS_END,
  DETAIL_CAREER_PLANS_FAILED,
  EXPLORE_CAREER_PLANS_BY_CHANNEL_START,
  EXPLORE_CAREER_PLANS_BY_CHANNEL_END,
  EXPLORE_CAREER_PLANS_BY_CHANNEL_FAILED
} from './index';

import api from '../api/api';

export function loadMyCareerPlan(query) {
  return async (dispatch) => {
    dispatch({ type: MY_CAREER_PLANS_START });
    try {
      const response = (query === undefined) ? await api.withDispatch(dispatch).CareerPlan.get() : await api.withDispatch(dispatch).CareerPlan.get(`${query}`);
      dispatch({ type: MY_CAREER_PLANS_END, payload: { currentCareerPlans: response.data } });
    } catch (e) {
      dispatch({ type: MY_CAREER_PLANS_FAILED })
    }
  };
}

export function loadMyPreviousCareerPlan(query) {

  return async (dispatch) => {
    dispatch({ type: MY_PREVIOUS_CAREER_PLANS_START });
    try {
      const response = (query === undefined) ? await api.withDispatch(dispatch).CareerPlan.history() : await api.withDispatch(dispatch).CareerPlan.history(`${query}`);
      dispatch({ type: MY_PREVIOUS_CAREER_PLANS_END, payload: { previousCareerPlans: response.data } });
    } catch (e) {
      dispatch({ type: MY_PREVIOUS_CAREER_PLANS_FAILED })
    }
  };
}

export function loadAllChannels() {
  return async (dispatch) => {
    dispatch({ type: EXPLORE_CAREER_PLANS_CHANNELS_START });
    try {
      const response = await api.withDispatch(dispatch).CareerPlan.getChannelsToExplore();
      dispatch({ type: EXPLORE_CAREER_PLANS_CHANNELS_END, payload: { allChannels: response.data } });
    } catch (e) {
      dispatch({ type: EXPLORE_CAREER_PLANS_CHANNELS_FAILED })
    }
  };
}

export function loadAllCareerPlan() {
  return async (dispatch) => {
    dispatch({ type: EXPLORE_CAREER_PLANS_START });
    try {
      const response = await api.withDispatch(dispatch).CareerPlan.allCareerPlan();
      dispatch({ type: EXPLORE_CAREER_PLANS_END, payload: { allCareerPlans: response.data } });
    } catch (e) {
      dispatch({ type: EXPLORE_CAREER_PLANS_FAILED })
    }
  };
}

export function loadCareerPlanByChannel(id) {
  return async (dispatch) => {
    dispatch({ type: EXPLORE_CAREER_PLANS_BY_CHANNEL_START });
    try {
      const response = await api.withDispatch(dispatch).CareerPlan.allCareerPlanByChannel(id);
      dispatch({ type: EXPLORE_CAREER_PLANS_BY_CHANNEL_END, payload: { allCareerPlans: response.data } });
    } catch (e) {
      dispatch({ type: EXPLORE_CAREER_PLANS_BY_CHANNEL_FAILED })
    }
  };
}

export function loadPlanById(id, filters) {
  return async (dispatch) => {
    dispatch({ type: DETAIL_CAREER_PLANS_START });
    try {
      const response = await api.withDispatch(dispatch).CareerPlan.getDetail(id, filters);
      dispatch({ type: DETAIL_CAREER_PLANS_END, payload: { detailCareerPlans: response.data } });
    } catch (e) {
      dispatch({ type: DETAIL_CAREER_PLANS_FAILED })
    }
  };
}
