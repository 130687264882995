import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EventListenerWrapper from './EventListenerWrapper'

const styles = theme => ({
  root: {
    height: '100%',
    backgroundColor: '#000000',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    '&_icon': {
      backgroundColor: 'transparent',
      color: '#ffffff',
      height: 60,
      transform: 'rotate(16deg)',
      top: 'calc(50% - 30px)',
      right: 8,
      position: 'absolute',
      transition: 'transform 350ms',
    },
    '&:hover &_icon': {
      transform: 'translateY(15px)',
      transition: 'transform 350ms',
    },
    '&:focus &_icon': {
      transform: 'translateY(15px)',
      transition: 'transform 350ms',
    },
    '&_subtitle': {
      paddingLeft: 16,
      transition: 'all 0.35s ease-out',
      opacity: 0,
      marginTop: 5,
      backgroundColor: 'transparent',
      fontSize: 10,
      textAlign: 'left',
      color: 'white',
      maxWidth: '72%',
    },
    '&:hover &_subtitle': {
      opacity: 1,
      transition: 'all 0.35s ease-out',
    },
    '&:focus &_subtitle': {
      opacity: 1,
      transition: 'all 0.35s ease-out',
    },
    '&--overlay': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: '#000000',
      opacity: 0.25,
      zIndex: 5,
      transition: 'all 0.45s ease-out',
    },
    '&:hover &--overlay': {
      opacity: 0,
    },
    '&:focus &--overlay': {
      opacity: 0,
    },
  },
  content: {
    zIndex: 10,
    position: 'relative',
    textAlign: 'left',
    width: 'calc(100% - 16px)',
  },
  miniTitle: {
    //fontMod
    fontWeight: 700,
    width: '66%',
    paddingTop: 16,
    paddingLeft: 16,
    textAlign: 'left',
    color: '#ffffff',
    fontSize: 20,
    lineHeight: '1.2',
  },
})

class CategoryItem extends Component {
  trimEllip = function (text, length) {
    return text.length > length ? text.substring(0, length) + '...' : text
  }

  render() {
    const { classes, item, onItemClick } = this.props

    return (
      <EventListenerWrapper
        TagType={'h2'}
        role={'link'}
        className={classes.root}
        style={{ background: item.color }}
        onEnter={() => onItemClick()}
        tabIndex={0}
      >
        <div className={classes.root + '--overlay'} />
        <img
          className={classes.root + ' ' + classes.root + '_icon'}
          src={item.icon}
          alt={''}
          aria-hidden={true}
        />
        <div className={classes.content}>
          <Typography component={'h3'} className={classes.miniTitle}>
            {item.name}
          </Typography>
          <Typography component={'p'} className={classes.root + ' ' + classes.root + '_subtitle'}>
            {this.trimEllip(item.description, 80)}
          </Typography>
        </div>
      </EventListenerWrapper>
    )
  }
}

export default withStyles(styles)(CategoryItem)
