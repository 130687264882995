import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  srOnly: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
})

const DocumentTitle = ({ classes, title }) => {
  document.title = title
  return title ? (
    <p
      id={'document-title'}
      className={classes.srOnly}
      role="log"
      aria-live={'polite'}
      aria-relevant="all"
      aria-atomic="true"
    >
      {title}
    </p>
  ) : null
}

export default withStyles(styles)(DocumentTitle)
