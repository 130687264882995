import {
  ADMIN_FILES_DOWNLOAD_START, ADMIN_FILES_DOWNLOAD_END, ADMIN_FILES_DOWNLOAD_FAILED
} from '../../actions';

let initialState = {
  error: null,
  loading: false,
  file: null,
  filename: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_FILES_DOWNLOAD_END:
      const { file } = action.payload;
      return {
        ...state,
        loading: false,
        file: file,
        error: null,
      };
    case ADMIN_FILES_DOWNLOAD_START:
      return {
        ...state,
        file: null,
        filename: action.payload.filename,
        error: null,
        loading: true,
      };
    case ADMIN_FILES_DOWNLOAD_FAILED:
      const { message } = action.payload;
      return {
        ...state,
        file: null,
        error: message,
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};
