import React, { useState } from 'react'
import { IconButton, TextField, Typography } from '@material-ui/core'
import { convertNumberToId, editOption, removeOption } from '../../../helpers'
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  DeleteOutline,
  EditOutlined,
} from '@material-ui/icons'
import '../../../styles.css'

const MultipleChoiceOption = ({
  question,
  options_text,
  correct_answer,
  handler,
  index,
  quizEditingBlocked,
}) => {
  const [editing, setEditing] = useState(false)

  return (
    <div className="row" style={{ alignItems: 'center', gap: '0.5rem', minHeight: '2rem' }}>
      <div
        className={`row option ${correct_answer}`}
        style={{
          backgroundColor: editing ? '#ececec' : undefined,
          height: 'fit-content',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            height: '100%',
            padding: '0.75rem',
            gap: '0.5rem',
          }}
          onClick={() => {
            if (!quizEditingBlocked) {
              handler(
                question,
                editOption(
                  question,
                  { options_text: options_text?.trim(), correct_answer: !correct_answer },
                  index,
                ),
              )
            }
          }}
        >
          <Typography style={{ fontSize: '1rem', fontWeight: 700 }}>
            {convertNumberToId(index)}
          </Typography>
          {editing ? (
            <EditOutlined />
          ) : correct_answer ? (
            <CheckBoxOutlined />
          ) : (
            <CheckBoxOutlineBlank />
          )}
        </div>
        <TextField
          fullWidth
          defaultValue={options_text}
          onClick={e => {
            e.stopPropagation()
          }}
          InputProps={{ readOnly: quizEditingBlocked }}
          multiline
          value={editing ? undefined : options_text}
          placeholder="Complete la opción"
          readOnly={!editing}
          onFocus={() => {
            if (!quizEditingBlocked) setEditing(true)
          }}
          onBlur={({ target }) => {
            if (!quizEditingBlocked) {
              setEditing(false)
              handler(
                question,
                editOption(
                  question,
                  { correct_answer, options_text: target.value?.replaceAll('~', '-').trim() },
                  index,
                ),
              )
            }
          }}
          onKeyDown={({ target, key }) => {
            if (!quizEditingBlocked) {
              if (key === 'Enter') {
                setEditing(false)
                handler(
                  question,
                  editOption(
                    question,
                    { correct_answer, options_text: target.value?.replaceAll('~', '-').trim() },
                    index,
                  ),
                )
              }
            }
          }}
        />
      </div>
      {!quizEditingBlocked && (
        <IconButton
          style={{
            padding: '0.5rem',
            visibility: editing || question?.options?.length < 2 ? 'hidden' : undefined,
          }}
          onClick={() => handler(question, removeOption(question, index))}
        >
          <DeleteOutline />
        </IconButton>
      )}
    </div>
  )
}

export default MultipleChoiceOption
