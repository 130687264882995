import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResources, processReportOffline, downloadReport } from '../../../actions/admin'
import AdminResourceIndex from '../AdminResourceIndex';
import { addSnackbar } from '../../../actions/snackbar'
import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  AdminItemViewType,
  CourseProgress,
  CourseStatus,
	SnackbarType
} from '../../../config/constants';

const KEY_NAME = 'reports/online_courses'

class AdminReportsOnlineCoursesContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources, me } = this.props
    getResources(type, keyName, query, page, size)
  }

  onRenderNps = item => {
    return item.nps === null ? '-' : item.nps
  }

  getColumns = () => {
    let columns = [
      { title: 'ID Curso', source: 'course_id' },
      { title: 'Nombre', source: 'name' },
      // {title:'Descripción', source:'description'},
      { title: 'Duración (min)', source: 'duration' },
      { title: 'Categorías', source: 'categories' },
      { title: 'Etiquetas', source: 'tags' },
      {
        title: 'NPS',
        source: 'nps',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderNps,
      },
      //{title:'NPS', source:'nps'},
      { title: 'Estado', source: 'status' },
      { title: 'Fecha Publicación', source: 'publish_date' },
      { title: 'Requerido', source: 'normative' },
    ]
    return columns
  }

  getFilterConfig = () => {
    var response = [
      {
        type: EditInputType.Text,
        name: 'course.id',
        placeholder: 'ID',
        multiple: false,
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Text,
        name: 'course.title',
        placeholder: 'Nombre',
        multiple: false,
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Categories,
        name: 'category.id',
        placeholder: 'Categoría',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Tags,
        name: 'tag.id',
        placeholder: 'Etiqueta',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Number,
        name: 'course.npsScoreFrom',
        placeholder: 'NPS Desde',
        format: FiltersFormat.DesdeNps,
        queryName: 'course.npsScore',
      },
      {
        type: EditInputType.Number,
        name: 'course.npsScoreTo',
        placeholder: 'NPS Hasta',
        format: FiltersFormat.HastaNps,
        queryName: 'course.npsScore',
      },
      {
        type: EditInputType.Select,
        name: 'course.status',
        placeholder: 'Estado',
        format: FiltersFormat.Plain,
        options: Object.keys(CourseStatus).map(k => ({
          id: k,
          description: CourseStatus[k],
        })),
      },
      {
        title: 'Requeridos',
        source: 'required',
        type: AdminItemViewType.Boolean,
      },
    ]

    return response
  }

  onGenerateReport = async () => {
    const {
      processReportOffline,
      paginator: { query },
      addSnackbar,
    } = this.props
    processReportOffline(query, KEY_NAME).then(() => {
      addSnackbar('El reporte se le enviará por mail', SnackbarType.Info)
    })
  }

  onDownload = async () => {
    const {
      downloadReport,
      paginator: { query },
    } = this.props
    downloadReport(query, KEY_NAME)
  }

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTE CURSOS ONLINE'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onGenerateReport={this.onGenerateReport}
        onDownload={this.onDownload}
      />
    )
  }
}

AdminReportsOnlineCoursesContainer.defaultProps = {
  type: KEY_NAME,
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  if (
    paginatorState &&
    paginatorState.user_courses &&
    paginatorState.user_courses.items
  ) {
    paginatorState.user_courses.items.map((course) =>
      course.completed_at && course.completed_at.length ?
        (course.completed = CourseProgress.true) :
        (course.completed = CourseProgress.false)
    );
  }
  return {
    me: { ...state.userReducer },
    paginator: { ...paginatorState[KEY_NAME] },
  };
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
    { getResources, processReportOffline, downloadReport, addSnackbar },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsOnlineCoursesContainer);
