/* eslint-disable no-case-declarations */
import {
  INFINITE_INIT,
  INFINITE_PAGINATE_END,
  INFINITE_PAGINATE_FAILED,
  INFINITE_PAGINATE_LOAD_NEXT,
  INFINITE_PAGINATE_UPDATE_ITEM,
  INFINITE_RESET,
  INFINITE_PAGINATE_UPDATE_ITEMS,
} from '../actions';

const initialState = {
  key: 'default',
  error: null,
  loading: false,
  items: [],
  page: 1,
  total: 1,
  size: 1,
  meta: null,
  query: null,
  hasMore: true,
};

const states = {};

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key];
  }
  states[key] = { ...initialState, key };
  return states[key];
}

export default (state = initialState, action) => {
  const key = action.key || state.key || 'default';
  const currentState = getStateFor(key);
  switch (action.type) {
    case INFINITE_INIT: {
      states[key] = { ...currentState };
      return states;
    }
    case INFINITE_RESET: {
      states[key] = { ...initialState, key };
      return states;
    }
    case INFINITE_PAGINATE_FAILED:
      const { message } = action.payload;
      states[key] = {
        ...currentState,
        error: message,
        query: null,
        meta: null,
        loading: false,
      };
      return states;
    case INFINITE_PAGINATE_LOAD_NEXT: {
      states[key] = {
        ...currentState,
        page: currentState.page + 1,
        error: null,
        loading: true,
        hasMore: false,
      };
      return states;
    }

    case INFINITE_PAGINATE_END: {
      const {
        items,
        meta: {
          pagination: { size, total },
        },
      } = action.payload;
      const lastPage = Math.ceil(total / size);
      states[key] = {
        ...currentState,
        items: [...currentState.items, ...items],
        error: null,
        loading: false,
        hasMore: currentState.page <= lastPage,
        total: lastPage,
      };
      return states;
    }
    case INFINITE_PAGINATE_UPDATE_ITEM: {
      return (states[key] = { ...currentState });
    }
    case INFINITE_PAGINATE_UPDATE_ITEMS: {
      const { items } = action.payload;
      const newState = { ...currentState };
      newState.items = currentState.items.map((block) => {
        const updated = items.find((i) => i.id === block.id);
        return updated || block;
      });
      states[key] = { ...newState };
      return states;
    }
    default:
      states[key] = { ...currentState };
      return states;
  }
};
