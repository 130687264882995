import React, { Component } from 'react'
import GenericDialog from '../../components/ui/GenericDialog'
import ColorButton from '../../components/ui/ColorButton'
import { Typography, Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import EventIcon from '@material-ui/icons/Event'
import moment from 'moment'
import { generateGoogleCalendarLink, generateOutlookCalendarLink } from '../../utils/CalendarHelper'

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg)
  return {
    content: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      marginBottom: 16,
      alignItems: 'center',
    },

    row: {
      marginTop: 10,
      marginBottom: 10,
      width: '80%',
      margin: 'auto',
      textAlign: 'center',
      [mediaLg]: {
        width: '90%',
      },
    },

    actions: {
      margin: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
      marginRight: 10,
    },

    title: {
      fontSize: 20,
      fontWeight: 600,
      marginBottom: 8,
      marginTop: 8,
      color: 'rgb(30, 209, 168)',
    },

    text: {
      fontSize: 16,
      fontWeight: 400,
      margin: 'auto',
      marginBottom: 8,
      marginTop: 8,
      width: '100%',
      maxWidth: 450,
      color: 'rgb(30, 209, 168)',
    },

    helpText: {
      fontSize: 12,
      fontWeight: 400,
      margin: 'auto',
      marginBottom: 8,
      marginTop: 8,
      width: '100%',
      maxWidth: 450,
      color: 'rgb(30, 209, 168)',
    },
    headerIcon: {
      color: '#ffffff',
      height: 60,
    },
    dateContainer:{
      justifyContent: 'center',
      marginBottom: '3%',
    },
    buttonsContainer: {
      display: 'flex',
    }
  }
}

const initialState = {}

class ExternalCourseInscriptionDialog extends Component {
  state = { ...initialState }

  handleSubmit = (type, [dateFrom, dateTo]) => {
    const { onSubmit, commission, course } = this.props

    let event = { name: course.name, from: dateFrom, to: dateTo }

    if(commission.place_url){
      event.place = commission.place_url;
    }else if(commission.place){
      let place = '';
      if(commission.place[0].institution){
        place = place + ' - ' + commission.place[0].institution
      }
      if(commission.place[0].address){
        place = place + ' - ' + commission.place[0].address
      }
      if(commission.place[0].classroom){
        place = place + ' - ' + commission.place[0].classroom
      }
      event.place = place;
    }

    const details = []
    if (commission.place_url) {
      details.push(
        `Para ingresar al taller hacé clic en el siguiente enlace: <a href="${commission.place_url}">${commission.place_url}</a>`,
      )
    }
    if (commission.email_text) {
      details.push(commission.email_text)
    }
    event.description = details.join('<br><br>')

    const link = type == 1 ? generateGoogleCalendarLink(event) : generateOutlookCalendarLink(event)
    window.open(link, '_blank')
  }

  renderContent = () => {
    const { classes, commission, course } = this.props

    return commission ? (
      <aside className={classes.content}>
        <Grid container spacing={1} className={classes.contentValorar}>
          <div className={classes.row}>
            <Typography className={classes.title} component={'h1'}>
              {`Te inscribiste en el Taller "${course.name}"`}
            </Typography>
            {commission &&
              commission.dates &&
              commission.dates.length > 0 &&
              commission.dates.map(date => {
                const dateTime = new Date(date.start_date)
                const to = moment(
                  new Date(dateTime.setMinutes(dateTime.getMinutes() + date.duration)),
                )
                return(
                  <Grid container className={classes.dateContainer} justify="space-between">
                    <Grid item span={12}>
                      <Typography className={classes.title} component={'h2'}>
                        {moment(date.start_date).format('DD/MM/YYYY - HH:mm') + 'hs'}
                      </Typography>
                    </Grid>
                    <Grid item span={12} className={classes.buttonsContainer}>
                      <Grid item span={12} className={classes.actions}>
                        <ColorButton
                          color={'#3abf91'}
                          extraView={<EventIcon style={{ fontSize: 20 }} />}
                          label={
                            <span style={{ paddingLeft: 4, marginTop: -2 }}>AGENDAR EN GOOGLE</span>
                          }
                          onClick={() => this.handleSubmit(1, [date.start_date, to])}
                        />
                      </Grid>
                      <Grid item span={12} className={classes.actions}>
                        <ColorButton
                          color={'#3abf91'}
                          extraView={<EventIcon style={{ fontSize: 20 }} />}
                          label={
                            <span style={{ paddingLeft: 4, marginTop: -2 }}>AGENDAR EN OUTLOOK</span>
                          }
                          onClick={() => this.handleSubmit(2, [date.start_date, to])}
                        />
                      </Grid>
                    </Grid>
                  </Grid>                  
                )
              })
          }
          </div>
          <div className={classes.row}>
            <Typography className={classes.text} component={'h2'}>
              {'¡Tu lugar está confirmado! Agendalo en tu calendario a continuación.'}
            </Typography>
          </div>

          <div className={classes.row}>
            <Typography className={classes.helpText}>
              En caso de no poder asistir, recordá cancelar tu inscripción. Esto nos permitirá
              avisarles a las personas que están en la lista de espera.
            </Typography>
          </div>
        </Grid>
      </aside>
    ) : null
  }

  render() {
    const { open, onDismiss, classes } = this.props
    return (
      <GenericDialog
        open={open}
        onDismiss={onDismiss}
        title={'¡Inscripción Exitosa!'}
        icon={
          <img
            className={classes.headerIcon}
            src={'/img/commission_subscription_stars.svg'}
            alt={''}
          />
        }
      >
        {this.renderContent()}
      </GenericDialog>
    )
  }
}

export default withStyles(styles)(ExternalCourseInscriptionDialog)
