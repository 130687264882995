import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  Paper,
} from '@material-ui/core';
import { itemView } from '../../utils/AdminHelper';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 330,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  toolbar: {
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '0 0 auto',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  cell: {
    width: '50%',
  },
});

class KeyValueTable extends React.Component {
  render() {
    const { classes, attributes, item, actions = [] } = this.props;
    return (
      <Paper className={classes.root}>
        {attributes.title && (
          <Toolbar className={classes.toolbar}>
            <div className={classes.title}>
              <Typography id="tableTitle">{attributes.title}</Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>{actions}</div>
          </Toolbar>
        )}
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableBody>
              {attributes.keys.map((k, i) => {
                return (
                  <TableRow hover key={k.title + '-' + i}>
                    <TableCell className={classes.cell}>{k.title}</TableCell>
                    <TableCell className={classes.cell}>
                      {itemView(k, item)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(KeyValueTable);
