import React, { Component } from 'react';
import AutocompleteSearch from '../../containers/ui/AutocompleteSearch';
import { MenuItem, Typography } from '@material-ui/core';

class AutocompleteSearchSimpleObject extends Component {
  getSuggestionValue = (item) => {
    const { displayAttribute } = this.props;
    return (displayAttribute ? item[displayAttribute] : item.name) || '';
  };

  renderSuggestion = (item) => {
    const { displayAttribute } = this.props;
    const styleName = {
      float: 'left',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      color: '#707070',
      fontSize: 12,
    };

    const borderLine = {
      borderBottom: ' 0.5px solid rgba(112,112,112,0.24)',
    };

    const displayStatus = (status) => {
      return (status === 1) ? 'Borrador' : 'Publicado';
    }

    const displayText = (item) => {
      const { displayAttribute } = this.props;
      let text = '';
      if (Array.isArray(displayAttribute)) {
        displayAttribute.forEach(element => {
          if (element == 'status') {
            text = text + "  (" + displayStatus(item[element]) + ")";
          } else {
            text = text + " " + item[element];
          }
        });
      } else {
        text = item[displayAttribute];
      }

      return text;
    };

    return (
      <MenuItem style={borderLine} component="div">
        <div>
          <Typography style={styleName}>
            {displayAttribute ? displayText(item) : item.name}
          </Typography>
        </div>
      </MenuItem>
    );
  };

  render() {
    const {
      keyName,
      onItemSelected,
      placeholder,
      onFetch,
      onCreate,
      value,
      autoFocus,
      displayAttribute,
      customFilter,
      renderInputComponent
    } = this.props;

    return (
      <AutocompleteSearch
        keyName={keyName}
        onFetch={onFetch}
        onCreate={onCreate}
        placeholder={placeholder}
        handleSuggestionSelected={onItemSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={renderInputComponent}
        value={value}
        autoFocus={autoFocus}
        displayAttribute={displayAttribute || 'name'}
        customFilter={customFilter}
      />
    );
  }
}

export default AutocompleteSearchSimpleObject;
