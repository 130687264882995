import React from 'react'
import moment from 'moment'
import {
  Avatar,
  Button,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@material-ui/core'
import ImageIcon from '@material-ui/icons/ImageOutlined'
import CKEditor from 'ckeditor4-react'
import { BASE_ADMIN } from '../config/routes'
import { AdminItemViewType, EditInputType, Roles } from '../config/constants'
import Popover from '../components/admin/inputs/Popover'
import TextInput from '../components/admin/inputs/TextInput'
import SelectInput from '../components/admin/inputs/SelectInput'
import SwitchInput from '../components/admin/inputs/SwitchInput'
import FileInput from '../components/admin/inputs/FileInput'
import ColorInput from '../components/admin/inputs/ColorInput'
import BackgroundInput from '../components/admin/inputs/BackgroundInput'
import BooleanInput from '../components/admin/inputs/BooleanInput'
import AutocompleteSimpleObjectInput from '../components/admin/inputs/AutocompleteSimpleObjectInput'
import CssBackgroundPreview from '../components/ui/CssBackgroundPreview'
import Dropzone from '../components/admin/inputs/Dropzone'
import LazySelectInput from '../containers/ui/LazySelectInput'
import { INPUT_INT_NUMBER_ACCEPT_KEYS } from '../constants'

// eslint-disable-next-line no-undef
const format = require('string-format')

export function isAdmin(user) {
  return user != null && (user.is_admin || user.roles.includes(Roles.OpenBankadmin))
}

export function isReporting(user) {
  return user != null && (user.is_reporting || user.roles.includes(Roles.OpenBankReporing))
}

export function hasBackOfficeAccess(user) {
  return (
    user != null &&
    (user.is_reporting ||
      user.is_admin ||
      user.roles.includes('REPORTING_OPEN_BANK') ||
      user.roles.includes('ADMIN_OPEN_BANK'))
  )
}

export function isOpenBankUser(user) {
  if (Array.isArray(user.roles)) {
    return user.roles.join(',').includes('OPEN_BANK')
  }
  return user.roles.includes('OPEN_BANK')
}

export function isOpenBankAdmin(user) {
  return user.roles.includes(Roles.OpenBankadmin)
}

export function isOpenBankReporting(user) {
  return user.roles.includes(Roles.OpenBankReporing)
}

export function hasDojoAccess(user) {
  return user != null && user.dojo_access
}

export function isAdminLocation(location) {
  return location.pathname.includes(BASE_ADMIN)
}

export function getValue(path, obj, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export function resolve(column, obj, separator = '.') {
  let values = []
  if (column.type === AdminItemViewType.Fixed) {
    values =
      typeof column.value !== 'undefined' && column.value instanceof Array
        ? column.value.map(v => `${v}`)
        : [`${column.value}`]
  } else {
    const paths = column.source.split(' ')
    values = paths.map(p => getValue(p, obj, separator))
    if (column.type === AdminItemViewType.Date) {
      const { format = 'DD/MM/YYYY' } = column
      values = values.map(v => (v ? moment(v).format(format) : '-'))
    } else if (column.type === AdminItemViewType.Datetime) {
      const { format = 'DD/MM/YYYY HH:mm' } = column
      values = values.map(v => (v ? moment(v).format(format) : '-'))
    } else if (column.type === AdminItemViewType.Formatted) {
      const columnFormat = column.format
      return format(columnFormat, ...values)
    } else if (column.type === AdminItemViewType.Boolean) {
      values = values.map(v => (v === null || v === undefined ? '' : v ? 'Sí' : 'No'))
    } else if (column.type === AdminItemViewType.Conditional) {
      values = values.map(v => {
        if (v !== undefined) {
          const condition = column.conditions.find(cond => `${v}` === cond.condition)
          return condition ? condition.result : ''
        }
        return ''
      })
    }
  }

  return values.join(' ')
}

export function itemView(column, item) {
  const value = resolve(column, item)
  switch (column.type) {
    case AdminItemViewType.Image:
      return (
        value && (
          <img
            src={value}
            style={{
              maxWidth: 50,
              maxHeight: 50,
              borderRadius: 3,
              background: '#c5c5c5',
            }}
            alt={`${column.source}`}
          />
        )
      )
    case AdminItemViewType.Background:
      return <CssBackgroundPreview value={value} />
    case AdminItemViewType.Avatar:
      return <Avatar src={value} />
    case AdminItemViewType.Action:
      return itemAction(item, column.action, column.source)
    case AdminItemViewType.Custom:
      return column.renderItem ? column.renderItem(item) : value
    default:
      return <Typography style={{ fontSize: 14 }}>{value}</Typography>
  }
}

export function itemAction(item, action, key) {
  if (action.icon) {
    return (
      <Tooltip title={action.title} key={`tooltip-action-${key}`}>
        <IconButton
          onClick={() => {
            action.action(item)
          }}
          label={action.title}
        >
          {action.icon}
        </IconButton>
      </Tooltip>
    )
  }
  return (
    <Button
      key={`action-${key}`}
      onClick={() => {
        action.action(item)
      }}
    >
      {action.title}
    </Button>
  )
}

export const limitIntEntry = e => {
  if (!INPUT_INT_NUMBER_ACCEPT_KEYS.includes(e.key)) {
    e.preventDefault()
  }
}

export const preventEvent = e => {
  e.preventDefault()
}

export function itemEdit(editConfig, value, onChange) {
  const customFilter =
    editConfig && editConfig.customFilter !== undefined ? editConfig.customFilter : ''
  switch (editConfig.type) {
    case EditInputType.Text:
      return (
        <TextInput
          tabIndex={0}
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          multiline={editConfig.multiline || false}
          helpText={editConfig.helpText}
        />
      )
    case EditInputType.Number:
      return (
        <TextInput
          tabIndex={0}
          name={editConfig.name}
          type="number"
          min={editConfig.min ?? '0'}
          max={editConfig.max || undefined}
          value={value ? value : editConfig.default || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          helpText={editConfig.helpText}
          onPaste={editConfig.onPaste}
          onKeyDown={editConfig.onKeyDown}
          disabled={editConfig.disabled}
        />
      )
    case EditInputType.Date:
      return (
        <TextInput
          tabIndex={0}
          name={editConfig.name}
          type="date"
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          helpText={editConfig.helpText}
          max={editConfig.max}
          min={editConfig.min}
          style={{ cursor: 'pointer' }}
        />
      )
    case EditInputType.Datetime:
      return (
        <TextInput
          tabIndex={0}
          name={editConfig.name}
          type="datetime-local"
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          helpText={editConfig.helpText}
        />
      )
    case EditInputType.Password:
      return (
        <TextInput
          tabIndex={0}
          name={editConfig.name}
          type="password"
          autoComplete="new-password"
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          helpText={editConfig.helpText}
        />
      )
    case EditInputType.Object: {
      return (
        <AutocompleteSimpleObjectInput
          tabIndex={0}
          model={editConfig.model}
          name={editConfig.name}
          creatable={editConfig.creatable}
          value={value}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          multiple={editConfig.multiple}
          autoFocus={(editConfig.autoFocus && true) || false}
          displayAttribute={editConfig.displayAttribute || 'name'}
          customFilter={customFilter}
        />
      )
    }
    case EditInputType.LazySelect:
      return (
        <LazySelectInput
          tabIndex={0}
          keyName={editConfig.keyName || editConfig.model}
          name={editConfig.name}
          model={editConfig.model}
          value={value || (editConfig.multiple ? [] : '')}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          multiple={editConfig.multiple}
          showEmpty={editConfig.showEmpty || false}
          max={editConfig.max || false}
        />
      )
    case EditInputType.Select:
      return (
        <SelectInput
          tabIndex={0}
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          options={editConfig.options}
          helpText={editConfig.helpText}
          showEmpty={editConfig.showEmpty}
          disabled={editConfig.disabled}
        />
      )
    case EditInputType.MultipleSelect:
      return (
        <SelectInput
          tabIndex={0}
          name={editConfig.name}
          value={value || []}
          max={editConfig.max || false}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          options={editConfig.options}
          multiple={true}
          customFilter={customFilter}
        />
      )
    case EditInputType.File:
      return (
        <FileInput
          tabIndex={0}
          name={editConfig.name}
          value={value || ''}
          accept={editConfig.accept || '*/*'}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          helpText={editConfig.helpText}
        />
      )
    case EditInputType.Color:
      return (
        <ColorInput
          tabIndex={0}
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      )
    case EditInputType.Background:
      return (
        <BackgroundInput
          tabIndex={0}
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      )
    case EditInputType.Boolean:
      return (
        <BooleanInput
          tabIndex={0}
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      )
    case EditInputType.RadioGroup:
      return (
        <>
          {editConfig.title && <Typography>{editConfig.title}</Typography>}
          <RadioGroup row={editConfig.row} value={value} tabIndex={0}>
            {' '}
            {editConfig.options.map(option => (
              <div
                key={option.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FormControlLabel
                  value={option.id}
                  control={
                    editConfig.renderControl ? (
                      editConfig.renderControl(option)
                    ) : (
                      <Radio color="primary" />
                    )
                  }
                  label={option.description}
                  labelPlacement="end"
                  checked={value === option.id}
                  onChange={() => onChange(editConfig.name, option.id)}
                  key={option.id}
                />
                {option.helperContent && <Popover option={option} />}
              </div>
            ))}
          </RadioGroup>
        </>
      )
    case EditInputType.Switch:
      return (
        <SwitchInput
          tabIndex={0}
          value={editConfig.name || ''}
          checked={value || false}
          onChange={() => onChange(editConfig.name, !value)}
          label={editConfig.label || ''}
        />
      )
    case EditInputType.Dropzone:
      return (
        <Dropzone
          tabIndex={0}
          dropDetail={editConfig.dropDetail || ''}
          accept={editConfig.accept || '*/*'}
          maxSize={editConfig.maxSize ? editConfig.maxSize : 3}
          multiple={editConfig.multiple ? editConfig.multiple : false}
          icon={<ImageIcon />}
          key="dropzone-file"
          text={editConfig.text || ''}
          onDrop={(acceptedFiles, failedFiles) =>
            onChange(editConfig.name, { acceptedFiles, failedFiles })
          }
          onRemove={file =>
            onChange(editConfig.name, {
              acceptedFiles: editConfig.multiple
                ? value.filter(item => item.name !== file.name)
                : [],
            })
          }
          currentFiles={value}
        />
      )
    case EditInputType.TextEditor:
      return (
        <CKEditor
          tabIndex={0}
          type="classic"
          onChange={e => onChange(editConfig.name, e.editor.getData())}
          height="25em"
          data={value || ''}
        />
      )
    case EditInputType.Custom:
      return editConfig.renderItem ? editConfig.renderItem(value, onChange) : value
    default:
      return null
  }
}
