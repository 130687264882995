import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ColorButton from '../../ui/ColorButton'
import { EditInputType, SnackbarType } from '../../../config/constants'
import OpenButton from '@material-ui/icons/ArrowDropUp'
import CloseButton from '@material-ui/icons/ArrowDropDown'
import _ from 'lodash'
import { itemEdit } from '../../../utils/AdminHelper'
import classnames from 'classnames'
import {
  Button,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  Typography,
  Paper,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  content: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  grid: {
    flexGrow: 1,
    flexDirection: 'row',
  },

  filterChip: {
    marginTop: 8,
  },
  filterButton: {
    marginTop: 12,
    marginRight: 8,
    boxShadow: 'none',
  },

  header: {
    position: 'relative',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    height: 60,
    backgroundColor: '#868686',
    color: '#fff',
  },
  headerClosed: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  headerIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#fff',
  },
  title: {
    color: '#fff',
    fontSize: 20,
    paddingTop: 16,
    paddingLeft: 16,
  },
  filter: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterContent: {
    width: '100%',
    minHeight: 60,
    backgroundColor: '#fff',
  },
  alignRight: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
})

class AdminFilters extends Component {
  state = {
    open: false,
    filters: {},
    filtersEmpties: true,
    templateSelected: false,
    filterTemplateSelected: {},
    filterTemplates: false,
    newTemplateDialogOpen: false,
    templateDialogOpen: false,
    newFilterName: '',
  }

  getFilterByConfig = f => {
    return itemEdit(f, this.state.filters[f.name], this.handleOnChange)
  }

  componentDidMount = () => {
    this.loadFilters(this.props)
  }

  componentWillReceiveProps = nextProps => {
    const { filterTemplates, filters = {} } = this.props
    if (!_.isEqual(filters, this.state.filters) && !this.state.templateSelected) {
      this.loadFilters(nextProps)
    }

    if (this.state.filterTemplates !== nextProps.filterTemplates || nextProps.filterTemplates !== filterTemplates) {
      const newFilterTemplates = nextProps.filterTemplates
        ? nextProps.filterTemplates.filter(el => el.model === this.props.keyName.replace('/', '_'))
        : null
      this.setState({
        filterTemplates: newFilterTemplates,
      })
    }
  }

  saveNewFilterTemplate = () => {
    const { onSaveNewTemplate, keyName, addSnackbar } = this.props

    var filtersData = []
    for (const key in this.state.filters) {
      const value = this.state.filters[key]
      filtersData.push({
        field: key,
        value: typeof value === 'object' ? JSON.stringify(value) : value,
      })
    }

    var data = {
      "name": this.state.newFilterName,
      "model": keyName.replace('/', '_'),
      "filters": filtersData
    };

    onSaveNewTemplate(data)
      .then(() => {
        addSnackbar('Plantilla guardada', SnackbarType.Success)
      })
      .catch(err => {
        addSnackbar('Error al generar plantilla: ' + err.message, SnackbarType.Error)
      })

    this.setState({
      newFilterName: '',
    })

    this.closeNewTemplateDialog()
  }

  openTemplateDialog = () => {
    const { addSnackbar, getTemplatesConfig, keyName } = this.props

    if (!this.state.filterTemplates || !this.state.filterTemplates.length) {
      getTemplatesConfig(keyName)
        .then(() => {
          if (
            !this.state.filterTemplates ||
            typeof this.state.filterTemplates === 'undefined' ||
            this.state.filterTemplates.length === 0
          ) {
            addSnackbar('No hay plantillas para mostrar', SnackbarType.Error)
            this.setState({ templateDialogOpen: false })
            return
          }
          this.setState({ templateDialogOpen: true })
        })
        .catch(err => {
          addSnackbar('Error buscar plantillas: ' + err.message, SnackbarType.Error)
          return
        })
      return
    }
    this.setState({ templateDialogOpen: true })
  }

  closeTemplateDialog = () => {
    this.setState({
      templateDialogOpen: false,
    })
  }

  openNewTemplateDialog = () => {
    this.setState({
      newTemplateDialogOpen: true,
    })
  }

  closeNewTemplateDialog = () => {
    this.setState({
      newTemplateDialogOpen: false,
    })
  }

  setValuesInForm = filterTemplateId => {
    const { addSnackbar } = this.props
    const currentFilterValues = this.state.filterTemplates.find(value => {
      return value.id === filterTemplateId
    })

    var savedFilters = {}
    currentFilterValues.filter_template_detail.forEach(el => {
      if (el.value.indexOf('{"') === 0 || el.value.indexOf('[{"') === 0) {
        el.value = JSON.parse(el.value.replace(/'/g, '"'))
      }
      savedFilters[el.field] = el.value
    })
    this.setState({
      filters: savedFilters,
      templateSelected: true,
    })
    addSnackbar('Plantilla cargada', SnackbarType.Success)
    this.closeTemplateDialog()
  }

  onDeleteFilterTemplate = templateId => {
    const { onDeleteFilterTemplate, addSnackbar } = this.props

    onDeleteFilterTemplate(templateId)
      .then(() => {
        addSnackbar('Plantilla eliminada', SnackbarType.Success)
      })
      .catch(err => {
        addSnackbar('Error al eliminar plantilla: ' + err.message, SnackbarType.Error)
      })

    this.closeTemplateDialog()
  }

  loadFilters = props => {
    const { filters = false, getFilterConfig} = props
    if (filters !== false && getFilterConfig ) {
      const fixedFilters = getFilterConfig().filter(f => f.type === EditInputType.Fixed)
      fixedFilters.forEach(f => (filters[f.name] = f.fixedValue))
      this.setState({ filters: filters }, () => {
        this.applyFilters()
      })
    }
  }

  handleOnChange = (name, value) => {
    const { filters } = { ...this.state }
    const currentState = filters
    if (value == null || value === '') {
      delete currentState[name]
    } else if (Array.isArray(value)) {
      currentState[name] = value.length ? [...value] : undefined
    } else if (typeof value === 'object') {
      currentState[name] = { ...value }
    } else {
      currentState[name] = value
    }
    this.setState({
      filtersEmpties: Object.entries(currentState).length === 0,
      filters: currentState,
    })
  }

  applyFilters = () => {
    const { onFiltersChange } = this.props
    onFiltersChange(this.state.filters)
  }

  clearFilters = () => {
    this.setState({ filters: {} }, () => this.applyFilters())
  }

  handleNewTemplateNameChange = event => {
    this.setState({ newFilterName: event.target.value })
  }

  resetFiltersAndTemplateData = () => {
    const { getTemplatesConfig, keyName } = this.props
    getTemplatesConfig(keyName)
    this.setState({
      filterTemplates: false,
      templateSelected: false,
      filterTemplateSelected: {},
      open: true,
    })
  }

  render() {
    const { getFilterConfig, classes } = this.props
    const { open } = this.state
    const filtersConfig = getFilterConfig && getFilterConfig()

    const filtersComponent = filtersConfig && (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ borderBottom: 'none' }}>
              <Grid container spacing={2} style={{ padding: 16 }}>
                {filtersConfig
                  .filter(f => f.type !== EditInputType.Fixed)
                  .map(f => {
                    return (
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={4}
                        xs={6}
                        key={'key-filter-' + f.name}
                        className={classes.filter}
                      >
                        {this.getFilterByConfig(f)}
                      </Grid>
                    )
                  })}
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell style={{ borderBottom: 'none' }}>
              {/* <TableBody style={{ paddingTop: 16, paddingBottom: 16, display: 'block' }}> */}
              <Grid container style={{ padding: 16 }}>
                <Grid item lg={5} md={5} sm={5} xs={5}>
                  <ColorButton
                    className={classes.filterButton}
                    color={'#3abf91'}
                    onClick={this.applyFilters}
                    label={'Aplicar'}
                  />
                  <ColorButton
                    className={classes.filterButton}
                    onClick={this.clearFilters}
                    color={'#4b4b4b'}
                    label={'Limpiar'}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} className={classes.alignRight}>
                  <ColorButton
                    className={classes.filterButton}
                    onClick={this.openTemplateDialog}
                    color={'#ff8b61'}
                    label={'Mostrar Plantillas'}
                  />
                  {!this.state.filtersEmpties && (
                    <ColorButton
                      className={classes.filterButton}
                      onClick={this.openNewTemplateDialog}
                      color={'#4b4b4bd1'}
                      label={'Guardar Plantilla'}
                    />
                  )}
                  <Dialog
                    open={this.state.newTemplateDialogOpen}
                    onClose={this.closeNewTemplateDialog}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">Guardar plantilla de filtros</DialogTitle>
                    <DialogContent>
                      {/* <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates
                              occasionally.</DialogContentText> */}
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nombre de plantilla"
                        type="email"
                        fullWidth
                        value={this.state.newFilterName}
                        onChange={this.handleNewTemplateNameChange}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeNewTemplateDialog} color="primary">
                        Cancelar
                      </Button>
                      <Button onClick={this.saveNewFilterTemplate} color="primary">
                        Guardar
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={this.state.templateDialogOpen}
                    onClose={this.closeTemplateDialog}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">Aplicar plantilla de filtros</DialogTitle>
                    <DialogContent>
                      <List className={classes.root}>
                        {this.state.filterTemplates &&
                          this.state.filterTemplates.map(value => {
                            return (
                              <ListItem
                                key={value.id}
                                role={undefined}
                                dense
                                button
                                onClick={() => this.setValuesInForm(value.id)}
                              >
                                <ListItemText id={value.id} primary={value.name} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => this.onDeleteFilterTemplate(value.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })}
                      </List>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeTemplateDialog} color="primary">
                        Cancelar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
              {/* </TableBody> */}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    )

    const table = (
      <Paper>
        <div
          className={classnames(classes.header, {
            [classes.headerClosed]: !open,
          })}
          onClick={() => this.setState({ open: !open })}
        >
          <Typography className={classes.title}>FILTROS</Typography>
          <IconButton
            className={classes.headerIcon}
            onClick={() => {
              if (!open) this.resetFiltersAndTemplateData()
            }}
          >
            {open ? <OpenButton /> : <CloseButton />}
          </IconButton>
        </div>
        {open && <div className={classes.filterContent}>{filtersComponent}</div>}
      </Paper>
    )

    return filtersConfig && table
  }
}

export default withStyles(styles)(AdminFilters)
