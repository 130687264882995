/* eslint-disable no-extra-boolean-cast */
import React, { useState, Fragment, useEffect, useCallback } from 'react'
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons'
import Loading from '../../ui/Loading'
import { Button, IconButton, Typography } from '@material-ui/core'
import './styles.css'
import { COLOR_KEYS, STYLE_KEYS, SnackbarType } from '../../../config/constants'
import { answerQuizTrivia } from '../../../actions/admin'
import { addSnackbar } from '../../../actions/snackbar'
import UserResolver from './components/UserResolver'
import { store } from '../../../store'
import { getQuestionTypeType } from './helpers'
import QuizTriviaInfoField from './components/QuizTriviaInfoField'
import ResultsView from './components/ResultsView'
import ApprovalStatus from './components/ApprovalStatus'
import NoDisplaySolutionMsg from './components/noDisplaySolutionsMsg'
import ResizableImage from "../../ui/ResizableImage";

const analizeReadyConditions = payload =>
  !payload?.some(
    ({ question }) =>
      !question.options.length ||
      question.options[0].answered_text
        ?.split('*')
        .filter((string, index) => !!(index % 2))
        .some(string => !string.length),
  )

const verificationForQuestionWithOptions = ({ selectedOptions, currentQuestion }) => {
  const selectedOptionsId = selectedOptions.map(({ id }) => id)
  const hasIncorrectSelectedOptions = currentQuestion.options
    .filter(({ correct_answer }) => !correct_answer)
    .some(({ id }) => selectedOptionsId.includes(id))
  if (hasIncorrectSelectedOptions) {
    return undefined
  }
  const correctOptions = currentQuestion.options.filter(({ correct_answer }) => correct_answer)
  const correctOptionsAndSelectionHaveSameLength = correctOptions.length && selectedOptionsId.length
  if (!correctOptionsAndSelectionHaveSameLength) {
    return undefined
  }
  const isCorrect = !!correctOptions.every(({ id }) => selectedOptionsId.includes(id))

  return isCorrect ? currentQuestion : undefined
}

const verificationForQuestionWithFields = ({ selectedOptions, currentQuestion }) => {
  return !currentQuestion.options
    .map(
      ({ options_text, id }) =>
        !options_text.localeCompare(
          selectedOptions.find(selectedOption => selectedOption.id === id).answered_text,
          undefined,
          { sensitivity: 'base' },
        ),
    )
    .some(item => !item)
    ? currentQuestion
    : undefined
}

const verificationByQuestionType = {
  TrueOrFalse: verificationForQuestionWithOptions,
  MultipleChoice: verificationForQuestionWithOptions,
  FillInTheBlanks: verificationForQuestionWithFields,
  DragTheWords: verificationForQuestionWithFields,
}

const correctQuestionVerification = ({ answer, quiz, questionTypes }) => {
  const currentQuestion = quiz.find(({ question }) => answer.id === question.id).question
  const questionType = questionTypes.find(({ id }) => id === currentQuestion.questions_type).type
  const { options: selectedOptions } = answer

  return verificationByQuestionType[questionType]({ selectedOptions, currentQuestion })
}

const getResults = ({ answered, quiz, questionTypes, passing_score }) => {
  const correct_questions = answered
    .map(({ question }) => {
      const correctQuestion = correctQuestionVerification({ answer: question, quiz, questionTypes })
      if (correctQuestion) {
        return { id: question.id, score: correctQuestion.score }
      }
    })
    .filter(item => !!item)
  const score_obtained = !!correct_questions?.length
    ? correct_questions
        .map(({ score }) => score)
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue
        }, 0)
    : 0
  const results = {
    correct_questions,
    score_obtained,
    approved: score_obtained >= passing_score,
  }
  return results
}

const QuizTriviaActivity = ({ activity, markActivityAsDone }) => {
  const [activeQuestion, setActiveQuestion] = useState(0)
  const [questions, setQuestions] = useState()
  const [isLastQuestion, setIsLastQuestion] = useState(false)
  const [payload, setPayload] = useState()
  const [questionTypes, setQuestionTypes] = useState()
  const [readyToSubmit, setReadyToSubmit] = useState()
  const [submitting, setSubmitting] = useState()
  const [activityState, setActivityState] = useState()

  const reset = () => {
    setSubmitting(undefined)
    setActiveQuestion(0)
  }

  const updateActivityState = newValues => {
    setActivityState(state => ({ ...state, ...newValues }))
  }

  const submit = () => {
    setSubmitting(true)
  }

  const submitSimulation = useCallback(
    ({ answered, quiz }) => {
      const results = getResults({
        answered,
        quiz,
        questionTypes,
        passing_score: activity.passing_score,
      })
      const done =
        results.score_obtained >= activity.passing_score ||
        (!!activity.attemps && activityState.attempts_count + 1 === activity.attemps)
      updateActivityState({
        results,
        attempts_count: activityState.attempts_count + 1,
        done,
        answered_quiz: done ? answered : null,
      })

      setPayload([])
    },
    [activity, activityState, questionTypes],
  )
  const cleanPayload = payload =>
    payload.map(({ question: { id, options } }) => ({
      question: {
        id,
        // eslint-disable-next-line no-unused-vars
        options: options.map(({ strings, ...option }) => {
          return option
        }),
      },
    }))
  const handleOnClick = useCallback(() => {
    if (readyToSubmit) {
      if (!!activity.preview) {
        // if in preview mode
        submitSimulation({
          answered: cleanPayload(payload),
          quiz: activity.quiz,
        })
      } else {
        submit()
      }
    }
  }, [activity, payload, readyToSubmit])

  const onSubmitting = useCallback(async () => {
    try {
      const { quiz, ...results } = await answerQuizTrivia(activityState.id, cleanPayload(payload))
      updateActivityState({
        answered_quiz: cleanPayload(payload),
        results,
        quiz: quiz ?? activity.quiz,
        attempts_count: activityState.attempts_count + 1,
        done:
          results.approved ||
          (!!activity.attemps && activityState.attempts_count + 1 === activity.attemps),
      })
      setPayload(cleanPayload(payload))
    } catch (err) {
      setSubmitting(undefined)
      addSnackbar('Error: ' + err.message, SnackbarType.Error)
    }
  }, [activityState, payload, activity])

  useEffect(() => {
    if (payload) {
      setReadyToSubmit(analizeReadyConditions(payload))
    }
  }, [payload])

  useEffect(() => {
    if (activityState) {
      if (!!activity.preview && activityState.answered_quiz) {
        setPayload(activityState.answered_quiz)
      } else if (activity.done) {
        setPayload(activity.answered_quiz)
      } else if (!activityState.done && questions) {
        setPayload(
          questions.map(question => ({
            question: {
              id: question.id,
              options: [],
            },
          })),
        )
      }
    }
  }, [questions])

  useEffect(() => {
    setIsLastQuestion(questions?.length ? activeQuestion + 1 === questions.length : false)
  }, [questions, activeQuestion])

  useEffect(() => {
    if (submitting) {
      onSubmitting()
    }
  }, [submitting])

  useEffect(() => {
    if (activityState) {
      if (activityState.quiz) {
        setQuestions(activityState.quiz.map(({ question }) => question))
      }
      if (!questionTypes || !questionTypes.length) {
        setQuestionTypes(store.getState().questionsTypesReducer?.items)
      }
      if (!activity.done && !!activityState.done) {
        markActivityAsDone()
      }
      if (activity.attempts_count !== activityState.attempts_count) {
        setSubmitting(false)
      }
    }
  }, [activityState])

  useEffect(() => {
    setActivityState(activity)
    if (!questionTypes) {
      setQuestionTypes(store.getState().questionsTypesReducer?.items)
    }
  }, [])

  return (
    <div className="col doing-trivia-wrapper">
      {!questions || !payload || !questionTypes?.length ? (
        <Loading />
      ) : (
        <>
          <div className="col doing-trivia-questions-container">
            {submitting === undefined ? (
              <>
                <div className="col" style={{ justifyContent: 'space-between', gap: '0.5rem' }}>
                  <QuizTriviaInfoField
                    label="Puntaje mínimo de aprobación"
                    value={`${activity.passing_score ?? 0} de 100`}
                  />
                  {!!activityState.attempts_count && !!activity.display_solution && (
                    <>
                      <QuizTriviaInfoField
                        label={
                          activityState.done
                            ? 'Respuestas correctas'
                            : 'Respuestas correctas del último intento'
                        }
                        value={`${activityState.results.correct_questions?.length ?? 0} de ${
                          activityState.quiz?.length ?? 0
                        }`}
                      />
                      <QuizTriviaInfoField
                        style={{
                          color:
                            ((activityState.results.score_obtained >= activity.passing_score) || activityState.results.approved)
                              ? COLOR_KEYS.CORRECT
                              : COLOR_KEYS.INCORRECT,
                        }}
                        label={
                          activityState.done
                            ? 'Puntaje obtenido'
                            : 'Puntaje obtenido en el último intento'
                        }
                        value={activityState.results.score_obtained ?? 0}
                      />
                    </>
                  )}
                  {!activityState.done ? (
                    <QuizTriviaInfoField
                      style={{ fontSize: '0.85rem' }}
                      label="Reintentos disponibles"
                      value={
                        !activityState.attemps
                          ? 'Ilimitados'
                          : activityState.attempts_count
                          ? activityState.attemps - activityState.attempts_count
                          : activityState.attemps
                      }
                    />
                  ) : (
                    <ApprovalStatus activityState={activityState} />
                  )}
                </div>
                {!activityState.done || (activityState.done && !!activity.display_solution) ? (
                  <div className="col doing-trivia-question-container">
                    <div className="col doing-trivia-question-wrapper">
                      {questions.map((question, index) => {
                        if (activeQuestion === index) {
                          return (
                            <Fragment key={'question' + question.id}>
                              <div className="col doing-trivia-header">
                                <div
                                  className="row doing-trivia-info"
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div className="row" style={{ flexWrap: 'wrap', gap: '0.25rem' }}>
                                    <Typography
                                      style={{
                                        ...STYLE_KEYS.QUESTIONS_LABEL,
                                        alignSelf: 'flex-end',
                                      }}
                                    >
                                      Pregunta
                                    </Typography>
                                    <Typography
                                      style={{
                                        ...STYLE_KEYS.QUESTIONS_LABEL,
                                        fontWeight: 700,
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {index + 1} de {questions.length}
                                    </Typography>
                                  </div>
                                  {activity.display_solution ? (
                                    <div
                                      className="row"
                                      style={{
                                        flexWrap: 'wrap',
                                        gap: '0.25rem',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          ...STYLE_KEYS.QUESTIONS_LABEL,
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        Valor de la pregunta:
                                      </Typography>
                                      <Typography
                                        style={{
                                          ...STYLE_KEYS.QUESTIONS_LABEL,
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        <span style={{ fontWeight: 700 }}>{question.score}</span>{' '}
                                        puntos
                                      </Typography>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                                <div className="row doing-trivia-questions-text-container">
                                  <Typography className="doing-trivia-questions-text">
                                    {question.question_text}
                                  </Typography>
                                </div>
                                {!!question.link && (
                                  <a
                                    href={
                                      question.link.startsWith('http://') ||
                                      question.link.startsWith('https://')
                                        ? question.link
                                        : `http://${question.link}`
                                    }
                                    target="_blank"
                                    style={{ width: 'fit-content' }}
                                    rel="noreferrer"
                                  >
                                    Link
                                  </a>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap-reverse',
                                  gap: '1.5rem',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <UserResolver
                                  questionTypeType={getQuestionTypeType(
                                    question.questions_type,
                                    questionTypes,
                                  )}
                                  question={question}
                                  payload={payload}
                                  setPayload={setPayload}
                                  activityState={activityState}
                                  correctlyAnswered={
                                    !!activityState.results?.correct_questions?.find(
                                      ({ id }) => id === question.id,
                                    )
                                  }
                                />
                                {!!question.image && (
                                  <ResizableImage src={question.image} imgClassName='question-img' className='row doing-trivia-questions-image-container'/>
                                )}
                              </div>
                            </Fragment>
                          )
                        }
                      })}
                    </div>
                    <div className="row doing-trivia-nav">
                      <div className="row nav-button-container left">
                        <IconButton
                          className="quiz-arrow"
                          disabled={activeQuestion === 0}
                          onClick={() => setActiveQuestion(state => state - 1)}
                        >
                          <ChevronLeftOutlined />
                        </IconButton>
                      </div>
                      <div className="row question-indicator-container">
                        {questions?.map((question, index) => (
                          <div
                            key={'question-indicator' + question.id}
                            className={`question-indicator ${
                              index === activeQuestion ? 'active' : ''
                            } ${
                              !activityState.done
                                ? ''
                                : !activityState.results?.correct_questions?.find(
                                    ({ id }) => id === question.id,
                                  )
                                ? 'incorrect'
                                : 'correct'
                            }`}
                            onClick={() => setActiveQuestion(index)}
                          />
                        ))}
                      </div>
                      <div className="row nav-button-container right">
                        <IconButton
                          className="quiz-arrow"
                          disabled={isLastQuestion}
                          onClick={() => setActiveQuestion(state => state + 1)}
                        >
                          <ChevronRightOutlined />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <NoDisplaySolutionMsg />
                )}
                {!activityState.done && (
                  <Button
                    style={{
                      transition: 'ease-in-out all 300ms',
                      width: '100%',
                      opacity: readyToSubmit ? 1 : 0.4,
                      filter: readyToSubmit ? 'saturate(1)' : 'saturate(0)',
                      transform: readyToSubmit ? 'scale(1.01)' : 'scale(0.99)',
                      backgroundColor: COLOR_KEYS.PRIMARY,
                    }}
                    variant="contained"
                    disabled={!readyToSubmit}
                    onClick={handleOnClick}
                  >
                    <Typography style={{ fontWeight: 600 }}>Finalizar</Typography>
                  </Button>
                )}
              </>
            ) : (
              <div className="col results-view">
                {!activityState.results || submitting ? (
                  <Loading />
                ) : (
                  <ResultsView
                    confettiColors={questionTypes?.map(({ color }) => color) ?? undefined}
                    activityState={activityState}
                    retry={reset}
                    showSolution={reset}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default QuizTriviaActivity
