import React from 'react'
import { IconButton } from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBack'

const GoBackButton = ({ onClick }) => (
  <IconButton style={{ height: 56, width: 56 }} onClick={onClick ?? (() => window.history.back())}>
    <BackIcon style={{ height: 32, width: 32 }} />
  </IconButton>
)

export default GoBackButton
