import React from 'react'
import { Typography } from '@material-ui/core'
import AsterisksQuestionOption from './components/AsterisksQuestionOption'

const QuestionWithAsterisks = ({ handler, question, quizEditingBlocked }) => {
  return (
    <div className="col" style={{ gap: '2rem' }}>
      {question?.options && (
        <AsterisksQuestionOption
          {...{ ...question?.options[0], handler, question, quizEditingBlocked }}
        />
      )}
      <Typography style={{ fontSize: '0.75rem', padding: '2rem' }}>
        Para completar el campo escriba entre asteriscos(*) las palabras o fragmentos a responder.{' '}
        <br />
        Ejemplo: <i>Éste es un ejemplo de *respuesta 1* y *respuesta 2*</i>
      </Typography>
    </div>
  )
}

export default QuestionWithAsterisks
