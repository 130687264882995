/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import CheckOutlined from '@material-ui/icons/CheckOutlined'
import CloseOutlined from '@material-ui/icons/CloseOutlined'

const ResolverWrapper = ({ activityState, correctlyAnswered, children, className, type }) => {
  return (
    <div
      className={`col ${className} doing-trivia-content ${
        activityState.done ? `${!!correctlyAnswered ? 'correct' : 'incorrect'}` : ''
      }`}
    >
      <div className={`col doing-trivia-content-${type}`}>
        {!!activityState.done && (
          <>
            {!!correctlyAnswered ? (
              <CheckOutlined className="answer-mark correct" />
            ) : (
              <CloseOutlined className="answer-mark incorrect" />
            )}
          </>
        )}
        {children}
      </div>
    </div>
  )
}

export default ResolverWrapper
