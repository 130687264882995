import {
  GET_ASSOCIATES_USERS_NEW_LOT_READ_START,
  GET_ASSOCIATES_USERS_NEW_LOT_READ_END,
  GET_ASSOCIATES_USERS_NEW_LOT_READ_FAILED,
  GET_ASSOCIATES_COURSES_NEW_LOT_READ_START,
  GET_ASSOCIATES_COURSES_NEW_LOT_READ_END,
  GET_ASSOCIATES_COURSES_NEW_LOT_READ_FAILED,
  GET_JOB_POSITIONS_NEW_LOT_READ_START,
  GET_JOB_POSITIONS_NEW_LOT_READ_END,
  GET_JOB_POSITIONS_NEW_LOT_READ_FAILED,
  GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_START,
  GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_END,
  GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_FAILED,
  GET_COMPANY_NEW_LOT_READ_START,
  GET_COMPANY_NEW_LOT_READ_END,
  GET_COMPANY_NEW_LOT_READ_FAILED,
} from '.';
import api from '../api/api';

export function loadUsersForLot(filters) {
  return async (dispatch) => {
    dispatch({ type: GET_ASSOCIATES_USERS_NEW_LOT_READ_START });
    try {
      const { data, metadata } = await api
        .withDispatch(dispatch)
        .Lot.getUsersForLot(filters);
      dispatch({
        type: GET_ASSOCIATES_USERS_NEW_LOT_READ_END,
        users: data,
        metadata: metadata
      });
    } catch (error) {
      dispatch({
        type: GET_ASSOCIATES_USERS_NEW_LOT_READ_FAILED,
        error,
      });
    }
  };
}

export function loadCoursesForLot() {
  return async (dispatch) => {
    dispatch({ type: GET_ASSOCIATES_COURSES_NEW_LOT_READ_START });
    try {
      const { data } = await api
        .withDispatch(dispatch)
        .Lot.getAssociateCourseToLot();
      dispatch({
        type: GET_ASSOCIATES_COURSES_NEW_LOT_READ_END,
        courses: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSOCIATES_COURSES_NEW_LOT_READ_FAILED,
        error,
      });
    }
  };
}

export function loadJobPositionsForLot() {
  return async (dispatch) => {
    dispatch({ type: GET_JOB_POSITIONS_NEW_LOT_READ_START });
    try {
      const { data } = await api.withDispatch(dispatch).Lot.getJobPositions()
      dispatch({
        type: GET_JOB_POSITIONS_NEW_LOT_READ_END,
        jobPositions: data,
      });
    } catch (error) {
      dispatch({
        type: GET_JOB_POSITIONS_NEW_LOT_READ_FAILED,
        error,
      });
    }
  };
}

export function loadCompanyForLot() {
  return async (dispatch) => {
    dispatch({ type: GET_COMPANY_NEW_LOT_READ_START });
    try {
      const { data } = await api.withDispatch(dispatch).Lot.getCompany()
      dispatch({
        type: GET_COMPANY_NEW_LOT_READ_END,
        company: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_NEW_LOT_READ_FAILED,
        error,
      });
    }
  };
}

export function loadOrganizationUnitsForLot() {
  return async (dispatch) => {
    dispatch({ type: GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_START });
    try {
      const { data } = await api.withDispatch(dispatch).Lot.getOrganizationalUnits();
      dispatch({
        type: GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_END,
        organizationalUnits: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORGANIZATIONS_UNITS_NEW_LOT_READ_FAILED,
        error,
      });
    }
  };
}
