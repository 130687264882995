import React, { Component } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },


      grouped: {
        minWidth: 40,
        [mediaLg]: {
          minWidth: 5,
        },
      },


    }
  }
}


class NpsScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonsStates: {}
    };
  }

  handleNpm(score) {
    let buttonsStates = {};
    for (let i = 0; i <= 10; ++i) {
      buttonsStates[i] = score === i;
    }
    this.setState({ buttonsStates: buttonsStates });
  }

  componentDidMount() {
    this.handleNpm(null);
  }

  handleClick(score) {
    const { onChange } = this.props;
    onChange(score)
    this.handleNpm(score);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ButtonGroup size="small" aria-label="small outlined button group">
          {this.renderButtons()}
        </ButtonGroup>
      </div>
    )
  };

  renderButtons() {

    const { color } = this.props;
    let buttons = []
    for (let i = 0; i <= 10; ++i) {
      buttons.push(<Button key={i} style={this.state.buttonsStates[i] ? { backgroundColor: color, minWidth: 5 } : null} onClick={() => this.handleClick(i)}>{i}</Button>)
    }
    return buttons;
  }
}

export default withStyles(styles)(NpsScore);
