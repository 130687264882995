import React from 'react'
import { connect } from 'react-redux'
import {Redirect, Route} from 'react-router-dom'
import {HOME} from '../../../config/routes'
import { hasBackOfficeAccess } from '../../../utils/AdminHelper';

const mapStateToProps = (state, ownProps) => {
    return {
        me: { ...state.userReducer },
        ...ownProps
    }
};

function AdminRoute({ component: Component, me, ...rest }) {
    return (
        <Route {...rest} render={(props) => (
          hasBackOfficeAccess(me.user)
                ? <Component {...props} />
                : <Redirect to={HOME} />
        )} />
    )
}

export default connect(mapStateToProps)(AdminRoute);
