import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '../../assets/images/check_green.png'
import EventListenerWrapper from './EventListenerWrapper'
import RequiredIcon from '../../assets/images/ico_necesario.png'
import ComplementaryIcon from '../../assets/images/ico_complementario.png'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'

const styles = theme => ({
  root: {
    height: '100%',
    backgroundColor: '#000000',
    display: 'block',
    position: 'relative',
    '&_content': {
      zIndex: 10,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      float: 'left',
      '&_bigContent': {
        opacity: 0,
      },
      '&_miniContent': {
        opacity: 1,
        transition: 'all 0.45s ease-out',
        transitionDelay: '300ms',
        backgroundImage: 'linear-gradient(rgba(84, 84, 84, 0),#000000)',
      },
    },
    '&:hover > &_content_bigContent': {
      opacity: 1,
      transition: 'all 0.45s ease-out',
      transitionDelay: '300ms',
      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0),#000000)',
    },
    '&:hover > &_content_miniContent': {
      opacity: 0,
    },

    '&:focus > &_content_bigContent': {
      opacity: 1,
      transition: 'all 0.45s ease-out',
      transitionDelay: '300ms',
      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0),#000000)',
    },
    '&:focus > &_content_miniContent': {
      opacity: 0,
    },
    '&--overlay': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: '#000000',
      opacity: 0.25,
      zIndex: 5,
      transition: 'all 0.45s ease-out',
    },
    '&:hover &--overlay': {
      opacity: 0,
    },
    '&:focus &--overlay': {
      opacity: 0,
    },
  },
  contentPadding: {
    padding: 8,
  },
  miniTitle: {
    color: '#ffffff',
    fontSize: 16,
    paddingBottom: 4,
  },
  bigTitle: {
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 4,
  },
  bigSubtitle: {
    color: '#ffffff',
    fontSize: 12,
  },

  check: {
    position: 'absolute',
    bottom: 8,
    right: 12,
    fontWeight: 600,
    fontSize: 8,
    borderRadius: 2,
    backgroundImage: 'none !important',
  },

  image: {
    height: '100%',
  },

  ceilItem: {
    position: 'absolute',
    float: 'left',
    marginBottom: 3,
    top: 8,
    right: 0,
    zIndex: 10,
    backgroundImage: 'none !important',
  },
  childCeilItem: {
    display: 'flex',
    float: 'left',
    fontWeight: 400,
    color: '#fff',
    fontSize: 10,
    borderRadius: 40,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
    backgroundColor: 'rgba(0,0,0,0.7)',
    transform: 'scale(0.8)',
  },
  childCeilItemIcon: {
    display: 'flex',
    float: 'left',
    fontWeight: 700,
    color: '#fff',
    fontSize: 8,
    borderRadius: 2,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
    backgroundImage: 'none !important',
    cursor: 'pointer',
  },
  categories: {
    width: '100%',
    float: 'left',
  },
  category: {
    color: '#fff',
    fontSize: 10,
    float: 'left',
    marginRight: 4,
    marginBottom: 4,
  },
  npsScore: {
    color: '#ffffff',
    fontSize: 12,
    float: 'left',
  },
  star: {
    fontSize: 14,
    marginBottom: -2,
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  titleProgressContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mandatory: {
    position: 'absolute',
    opacity: 1,
    top: 0,
    left: 8,
    fontWeight: 700,
    color: '#fff',
    fontSize: 8,
    borderRadius: 2,
    paddingTop: 1,
    paddingBottom: 4,
    backgroundImage: 'none !important',
  },
})

class ExternalCourseItem extends Component {
  constructor(props) {
    super(props)
  }

  handleOpenExternalCourse = (e, redirect = false) => {
    const { item, itemClick } = this.props
    if (redirect) {
      itemClick(item)
    }
  }

  render() {
    const { classes, item, showFlags = false } = this.props
    return (
      <EventListenerWrapper
        TagType={'div'}
        className={classes.root}
        tabIndex={0}
        role={'link'}
        aria-label={item.name}
        onEnter={e => this.handleOpenExternalCourse(e, true)}
        onClick={e => {
          this.handleOpenExternalCourse(e, true)
        }}
      >
        <div className={classes.root + '--overlay'} />
        <div
          className={classes.imageWrapper}
          style={{ backgroundImage: `url('${item.image_url}')` }}
        />
        <div className={classes.root + '_content ' + classes.root + '_content_bigContent'}>
          <div className={classes.contentPadding}>
            {item.rates_count > 0 && (
              <React.Fragment>
                <Typography className={classes.npsScore}>
                  <StarOutlinedIcon iconname={'ic_star_outline'} className={classes.star} />
                  NPS {item.nps}
                </Typography>
              </React.Fragment>
            )}
            <div className={classes.titleProgressContainer}>
              <Typography component={'h2'} className={classes.bigTitle}>
                {item.name}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.ceilItem + ' ' + classes.root + '_content_bigContent'}>
          <Typography className={classes.childCeilItem}>TALLER EN VIVO</Typography>
        </div>
        {showFlags && (
          <Typography className={classes.mandatory}>
            {item.required && (
              <img src={RequiredIcon} width="20px" height="28px" alt={'Necesario'} />
            )}
            {!item.required && (
              <img src={ComplementaryIcon} width="20px" height="28px" alt={'Complementario'} />
            )}
          </Typography>
        )}

        <div
          aria-hidden={true}
          className={classes.ceilItem + ' ' + classes.root + '_content_miniContent'}
        >
          <Typography className={classes.childCeilItem}>TALLER EN VIVO</Typography>
        </div>
        <div
          aria-hidden={true}
          className={classes.root + '_content ' + classes.root + '_content_miniContent'}
        >
          <div className={classes.contentPadding}>
            <Typography className={classes.miniTitle}>{item.name}</Typography>
            <Typography className={classes.check}>
              {item.present === 1 && (
                <img src={CheckCircleIcon} width="30px" height="30px" alt={'Progress'} />
              )}
            </Typography>
          </div>
        </div>
      </EventListenerWrapper>
    )
  }
}

export default withStyles(styles)(ExternalCourseItem)
