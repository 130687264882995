import { SNACKBAR_CLOSE, SNACKBAR_OPEN } from './index';
import { SnackbarType } from '../config/constants';

export function addSnackbar(message, type = SnackbarType.Success) {
  return async (dispatch) => {
    dispatch({
      type: SNACKBAR_OPEN,
      payload: { message, type },
    });
  };
}

export function closeSnackbar() {
  return async (dispatch) => {
    dispatch({ type: SNACKBAR_CLOSE });
  };
}
