import {
  CATEGORIES_READ_END,
  CATEGORIES_READ_START,
  CATEGORIES_READ_FAILED,
  EXTERNAL_COURSE_COURSES_START,
  EXTERNAL_COURSE_COURSES_END,
  EXTERNAL_COURSE_COURSES_FAILED,
  EXTERNAL_COURSE_CLASSES_READ_START,
  EXTERNAL_COURSE_CLASSES_READ_END,
  EXTERNAL_COURSE_CLASSES_READ_FAILED,
  EXTERNAL_COURSE_ALL_START,
  EXTERNAL_COURSE_ALL_END,
  EXTERNAL_COURSE_ALL_FAILED,
} from '../../actions';

let initialState = {
  error: null,
  loading: false,
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXTERNAL_COURSE_COURSES_START:
      return {
        ...state,
        courses: [],
        error: null,
        loading: true,
      };
    case EXTERNAL_COURSE_COURSES_END:
      return {
        ...state,
        error: null,
        loading: false,
        courses: {
          columns: coursesColumns,
          data: action.courses.map((course) =>
            mapCourseData(course)
          ),
        },
      };
    case EXTERNAL_COURSE_COURSES_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case EXTERNAL_COURSE_CLASSES_READ_START:
      return {
        ...state,
        classes: [],
        error: null,
        loading: true,
      };
    case EXTERNAL_COURSE_CLASSES_READ_END:
      return {
        ...state,
        error: null,
        loading: false,
        classes: {
          columns: classesColumns,
          data: action.classes.map((classDate) =>
            mapClassesDate(classDate)
          ),
        },
      };
    case EXTERNAL_COURSE_CLASSES_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };  
    case EXTERNAL_COURSE_ALL_START:
      return {
        ...state,
        externalCourses: [],
        error: null,
        loading: true,
      };
    case EXTERNAL_COURSE_ALL_END:
      return {
        ...state,
        error: null,
        loading: false,
        externalCourses: {
          columns: externalCoursesColumns,
          data: action.externalCourses.map((externalCourse) =>
            mapExternalCourseData(externalCourse)
          ),
        },
      };
    case EXTERNAL_COURSE_ALL_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    case CATEGORIES_READ_END:
      return {
        ...state,
        loading: false,
        categories: action.categories,
        error: null,
      };
    case CATEGORIES_READ_START:
      return {
        ...state,
        error: null,
        categories: [],
        loading: true,
      };
    case CATEGORIES_READ_FAILED:
      return {
        ...state,
        error: 'message de error',
        loading: false,
      };
    default:
      return { ...state, error: null };
  }
};

const coursesColumns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: 'title',
    label: 'Título',
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: 'categories',
    label: 'Categoría',
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: 'condition',
    label: 'Estado',
    options: {
      filter: true,
      sort: false
    },
  }
];

const classesColumns = [
  {
    name: 'start_date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: true
    },
  },
  {
    name: 'duration',
    label: 'Duración',
    options: {
      filter: false,
      sort: false
    },
  }
];

const externalCoursesColumns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
      sortDescFirst: false,
    },
  },
  {
    name: 'title',
    label: 'Título',
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: 'categories',
    label: 'Categoría',
    options: {
      filter: true,
      sort: false
    },
  },
  {
    name: 'condition',
    label: 'Estado',
    options: {
      filter: true,
      sort: false
    },
  }
];

//method to parsed data from services
function mapCourseData({ id, title, categories, condition, mandatory, scale }) {
  return {
    id,
    title,
    categories,
    condition,
    mandatory,
    scale: '',
  };
}

function mapClassesDate({ start_date, duration}) {
  return {
    start_date,
    duration
  };
}

function mapExternalCourseData({ id, title, categories, condition }) {
  return {
    id,
    title,
    categories,
    condition
  };
}
