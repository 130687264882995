/* eslint-disable react/prop-types */
import React, {useState} from 'react'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles'
import Main from './Main'
import { app_theme } from '../themes/app_theme'
import FrontNavBarContainer from '../containers/ui/FrontNavBarContainer'
import FooterContainer from '../containers/ui/FooterContainer'
import SnackbarMessageContainer from '../containers/ui/SnackbarMessage'
import MenuDrawerContainer from '../containers/menu/MenuDrawer'
import { Typography } from '@material-ui/core'
import { useEffect } from 'react'
import ResetPassOB from './session/ResetPassOB'


const theme = createMuiTheme(app_theme())

const FOOTER_HEIGHT = '80px'

const styles = () => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    minHeight: `calc(100vh - ${FOOTER_HEIGHT})`,
    maxWidth: '100vw',
  },
  jumpToMain: {
    color: 'white',
    position: 'absolute',
    top: 64,
    left: 32,
    padding: 16,
    border: '2px white solid',
    backgroundColor: 'black',
    zIndex: -10,
    opacity: 0,
    '&:focus': {
      zIndex: 15000,
      opacity: 1,
    },
  },
  srOnly: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
})

const Layout = ({classes, me, location}) => {
  const [isResetPasswordNecessary, setIsResetPasswordNecessary] = useState(window.sessionStorage.getItem('reset_pasword'))
  const jumpToContent = () => {
    const element = document.getElementById('main')
    if (element) {
      element.focus()
    }
  }

  const changeToView = () => {
    setIsResetPasswordNecessary(false)
    window.sessionStorage.setItem('reset_pasword', false);
  }

  return (
    <>
    {isResetPasswordNecessary === 'true'
        ?
        <ResetPassOB changeToView={changeToView}/>
        :
        <MuiThemeProvider theme={theme}>
          <Typography
            className={classes.jumpToMain}
            role={'link'}
            onClick={() => jumpToContent()}
            tabIndex={0}
          >
            SALTAR AL CONTENIDO
          </Typography>

          {me && <FrontNavBarContainer location={location} />}
          <div className={classes.root}>
            <MenuDrawerContainer me={me} />
            <Main me={me} />
          </div>
          {me && <FooterContainer />}
          <SnackbarMessageContainer />
        </MuiThemeProvider>
    }
    </>

  )
}

export default withStyles(styles)(Layout)

