import {
  PROFILE_ME,
  BASE_ADMIN,
  COURSES_EXPLORE,
  CAREER_PLANS,
} from '../../config/routes';

export const ITEM_PROFILE_ME = 'ITEM_PROFILE_ME';
export const ITEM_ADMIN = 'ITEM_ADMIN';
export const ITEM_CATEGORIES = 'ITEM_CATEGORIES';
export const ITEM_CAREER_PLANS = 'ITEM_CAREER_PLANS';

export const menuItems = {
  items: [
    {
      title: 'Perfil',
      icon: 'person',
      action: ITEM_PROFILE_ME,
      submenu: false,
      route: PROFILE_ME,
    },
    {
      title: 'Administrador',
      icon: 'settings',
      action: ITEM_ADMIN,
      submenu: false,
      route: BASE_ADMIN,
    },
    {
      title: 'Categorías',
      icon: 'list',
      action: ITEM_CATEGORIES,
      submenu: false,
      route: COURSES_EXPLORE,
    },
    {
      title: 'Rutas de Aprendizaje',
      icon: 'trending_up',
      action: ITEM_CAREER_PLANS,
      submenu: false,
      route: CAREER_PLANS,
    },
  ],
};
