/* eslint-disable no-extra-boolean-cast */
import { Typography } from '@material-ui/core'
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'
import React from 'react'
import { classOptionSetter } from '../../../../../../utils/ActivitiesHelper'
import ResolverWrapper from './ResolverWrapper'
import { markForTrueOrFalse } from '../../../../../../constants'

const getSelected = (array, question, optionId) =>
  array
    .find(({ question: { id } }) => id === question.id)
    ?.question.options.find(option => optionId === option.id)

const ResolverForOptionsQuestion = ({
  question,
  payload,
  setPayload,
  activityState,
  correctlyAnswered,
  setter,
}) => {
  return (
    <ResolverWrapper
      activityState={activityState}
      correctlyAnswered={correctlyAnswered}
      type="options"
    >
      {question.options.map(({ options_text, id: optionId, correct_answer, mark }, index) => {
        const selected = !!activityState.done
          ? getSelected(activityState.answered_quiz, question, optionId)
          : getSelected(payload, question, optionId)

        return (
          <div
            key={'option' + question.id + options_text + index}
            className={`row option ${selected ? 'selected' : 'not-selected'} ${classOptionSetter(
              activityState.done,
              correct_answer ?? selected?.correct_answer,
            )}`}
            style={{ userSelect: 'none' }}
            onClick={
              activityState.done
                ? undefined
                : () => {
                    setPayload(state =>
                      state.map(({ question: { id, options } }) => {
                        return id === question.id
                          ? {
                              question: {
                                id,
                                options: setter({ options, optionId }),
                              },
                            }
                          : { question: { id, options } }
                      }),
                    )
                  }
            }
          >
            {mark && !markForTrueOrFalse.includes(mark) && (
              <Typography style={{ fontSize: '1rem', fontWeight: 700, marginLeft: '0.35rem' }}>
                {mark}
              </Typography>
            )}
            {selected ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}
            <Typography className="option-text">{options_text}</Typography>
          </div>
        )
      })}
    </ResolverWrapper>
  )
}

export default ResolverForOptionsQuestion
