import React, {useState, useEffect} from 'react';
import {Link} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ColorButton from '../../../ui/ColorButton';
import Iframe from "../../../courses/Iframe";


const styles = {
  button: {
    fontWeight: 700,
  },
  icon: {
    marginRight: 4,
    fontSize: 16,
  },
  iframe: {
    width: "100%",
    height: "1000px"
  },
};

const ActivityModuleFileContent = ({content, classes, color}) => {
  const [isInlinePDF, setIsInlinePDF] = useState(false);
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    fetch(content.url, {
      method: 'HEAD'
    })
      .then(response => {
        if (response.ok) {
          const contentType = response.headers.get('Content-Type');
          setIsInlinePDF(contentType === 'application/pdf');
        }
      })
      .catch(error => {
        console.log('Error al recuperar encabezados de archivo:', error);
      });
  }, [content.url]);

  return (
    <div>
      {content.url &&  content.url.endsWith && content.url.endsWith('.pdf') && isInlinePDF ? (
          showIframe ?
            <div className={classes.content}>
              {
                <React.Fragment>
                  (<Iframe open={true}
                           onClose={() => window.location.reload()}
                           activity={content}
                />)
                </React.Fragment>
              }
            </div>
            :
            <ColorButton
              label={'VISUALIZAR'}
              color={color}
              onClick={() => setShowIframe(true)}
              className={classes.button}
              extraView={<VisibilityIcon className={classes.icon}/>}
            />
        ) :
        <div>
          <Link underline={'none'} href={content.url} target={'_blank'}>
            <ColorButton
              label={'DESCARGAR'}
              color={color}
              onClick={() => {
              }}
              extraView={<DownloadIcon className={classes.icon}/>}
              className={classes.button}
            />
          </Link>
        </div>
      }
    </div>
  );
};

export default withStyles(styles)(ActivityModuleFileContent);
