import React, { useCallback, useState, useMemo } from 'react'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import moment from 'moment'
import 'moment/locale/es'
import classNames from 'classnames'
import { calendar_table_theme } from '../../../themes/calendar_table_theme'
import Loading from '../../ui/Loading'
import { getMonthName } from '../../../utils/CalendarHelper'
import EventListenerWrapper from '../../ui/EventListenerWrapper'
import {
  CommissionStatus,
  CommissionStatusName,
  EditInputObjectModel,
} from '../../../config/constants'
import AutocompleteSimpleObjectInput from '../../admin/inputs/AutocompleteSimpleObjectInput'
import { TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import SuggestedIcon from '@material-ui/icons/AssistantPhoto';
import DirectedIcon from '@material-ui/icons/Announcement';

const styles = theme => {
  const minimalTableWidth = theme.breakpoints.up(940)
  return {
    root: {
      display: 'flex',
      flexFlow: 'column',
    },
    content: {
      marginRight: '5%',
      marginLeft: '5%',
      marginTop: 32,
      marginBottom: 50,
    },
    rootTableContainer: {
      overflowX: 'auto',
      [minimalTableWidth]: {
        overflowX: 'hidden',
      },
    },
    table: {
      minWidth: 630,
    },
    tableCellRoot: {
      borderBottom: 'none',
    },
    dayCell: {
      minHeight: 100,
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      flexGrow: 0,
    },
    dayHeader: {
      width: '100%',
      textAlign: 'center',
    },
    dayName: {
      marginTop: 4,
      outline: 'none',
      cursor: 'pointer',
      borderRadius: '50%',
      height: 24,
      lineHeight: '24px',
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.3,
      display: 'inline-block',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      width: 'max-content',
      color: '#3c4043',
      minWidth: 24,
      '&:hover': {
        marginTop: 4,
        outline: 'none',
        cursor: 'pointer',
        backgroundColor: '#f1f3f4',
        borderRadius: '50%',
        height: 24,
        lineHeight: '24px',
      },
    },
    dayInPast: {
      color: '#929699',
    },

    dayToday: {
      color: '#ffffff',
      marginTop: 4,
      outline: 'none',
      cursor: 'pointer',
      backgroundColor: '#3e57da',
      borderRadius: '50%',
      height: 24,
      lineHeight: '24px',
      '&:hover': {
        marginTop: 4,
        outline: 'none',
        cursor: 'pointer',
        backgroundColor: '#4261ff',
        borderRadius: '50%',
        height: 24,
        lineHeight: '24px',
      },
    },
    dayEvents: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      flexGrow: 0,
    },
    eventChip: {
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 1,
      flexGrow: 0,
    },
    eventRoot: {
      fontFamily: 'Open Sans',
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
      cursor: 'pointer',
      padding: 3,
      borderRadius: 2,
      '&:hover': {
        backgroundColor: '#cccccc',
      },
      '&:focus': {
        backgroundColor: '#cccccc',
      },
    },
    eventInPast: {
      opacity: 0.8,
    },
    eventInFilters:{
      border: '2px solid #00dab2'
    },
    eventHighlighted: {
      backgroundColor: '#ffbff4',
    },
    eventSubscribed: {
      backgroundColor: '#c4fff7',
    },
    eventTime: {
      lineHeight: '16px',
      fontWeight: 500,
      marginRight: 4,
      color: '#3c4043',
      fontSize: 12,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      flexGrow: 0,
      flexShrink: 0,
    },
    eventName: {
      lineHeight: '16px',
      fontWeight: 700,
      marginRight: 4,
      color: '#3c4043',
      fontSize: 12,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      flexGrow: 0,
      flexShrink: 1,
    },

    eventIcon:{
    color: 'red'
    },
    datePicker: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
    datePickerDate: {
      fontFamily: 'Open Sans',
      color: '#ffffff',
      fontSize: 24,
      fontWeight: 700,
      margin: 16,
      textTransform: 'uppercase',
    },

    datePickerButton: {
      color: '#ffffff',
    },
    cardRoot: {
      maxWidth: 345,
    },
    cardMedia: {
      height: 140,
    },
    cardButtonText: {
      color: '#000000',
      marginLeft: 'auto',
    },
    statusText: {
      width: '100%',
      textAlign: 'right',
      fontSize: 14,
      color: '#666666',
    },

    commissionsFilter: {
      display: 'flex',
      justifyContent: 'center',
      margin: 16,
    },

    commissionsFilterInputWrapper: {
      backgroundColor: '#fff',
      borderRadius: '5px',
      padding: '10px',
      minWidth: 400,
      maxWidth: '80%',
    },
    customSearchFormControlRoot: {
      backgroundColor: '#fff',
      borderRadius: '5px',
      minWidth: 'unset',
    },

    customSearchInputRoot: {
      '&:after': {
        border: 'none',
        transition: 'none',
      },
      '&:before': {
        border: 'none',
        transition: 'none',
        content: 'none',
      },
      '&:hover': {
        border: 'none',
      },
    },
  }
}

const generateDayWithEvents = (dayMoment, dayEvents) => {
  if (dayMoment) {
    return { name: dayMoment.date(), events: dayEvents, date: dayMoment }
  }
  return { name: '', events: [], date: null }
}

const generateCalendar = (events, currentDate) => {
  const year = currentDate.year
  const month = currentDate.month - 1 //0 Enero
  const currentMonth = moment(new Date(year, month, 1))
  let monthFirstDay = currentMonth.day()
  monthFirstDay = monthFirstDay > 0 ? monthFirstDay - 1 : 6
  const daysInMonth = currentMonth.daysInMonth()
  const weeks = []
  let currentWeek = []
  for (let prevMonthDay = 0; prevMonthDay < monthFirstDay; prevMonthDay++) {
    currentWeek.push(generateDayWithEvents(null))
  }
  for (let day = 0; day < daysInMonth; day++) {
    const weekDay = (day + monthFirstDay) % 7
    const dayMoment = moment(new Date(year, month, day + 1))
    const dayKey = dayMoment.format('YYYY-MM-DD')
    const dayEvents = events[dayKey] || []
    currentWeek.push(generateDayWithEvents(dayMoment, dayEvents))
    if (weekDay === 6 || day === daysInMonth - 1) {
      weeks.push(currentWeek)
      currentWeek = []
    }
  }
  return weeks
}

const EventChip = ({ classes, event, inPast, openExternalCourse, filters }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const time = moment(event.date).format('HH:mm')
  const id = open ? event.commission_id : undefined

  const inFilters = filters.find( f => f.commissions.find( c => c.id === event.commission_id) !== undefined ) !== undefined

  return (
    <>
      <div className={classNames(classes.eventChip, { [classes.eventInPast]: inPast })}>
        <EventListenerWrapper
          TagType={'span'}
          role={'button'}
          onClick={handleClick}
          onEnter={handleClick}
          className={classNames(classes.eventRoot, {
            [classes.eventHighlighted]: event.highlight,
            [classes.eventSubscribed]: event.subscribed,
            [classes.eventInFilters]: inFilters
          })}
        >
          <span className={classes.eventTime}>{time}</span>
          <span className={classes.eventName}>{event.external_course.name}</span>
          { event.is_directed && <span className={classes.eventIcon}>{event.is_suggested ? <SuggestedIcon titleAccess={'Comisión sugerida'} /> : <DirectedIcon titleAccess={'Comisión dirigida'} />}</span> }
        </EventListenerWrapper>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card className={classes.cardRoot}>
          <CardActionArea>
            <CardMedia
              className={classes.cardMedia}
              image={event.external_course.image_url}
              title={event.external_course.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {event.external_course.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {event.external_course.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            {event.status === CommissionStatus.RegistrationOpen && (
              <Button
                size="small"
                color="primary"
                role={'link'}
                classes={{ textPrimary: classes.cardButtonText }}
                onClick={() => openExternalCourse(event.external_course.id)}
              >
                Ver más
              </Button>
            )}
            {event.status !== CommissionStatus.RegistrationOpen && (
              <Typography component={'p'} className={classes.statusText}>
                {event.status === CommissionStatus.Finished
                  ? 'Finalizado'
                  : CommissionStatusName[event.status]}
              </Typography>
            )}
          </CardActions>
        </Card>
      </Popover>
    </>
  )
}

const CalendarDay = ({ classes, day, openExternalCourse, filters }) => {
  const isToday = moment(day.date).isSame(new Date(), 'day')
  const isInThePast = moment(day.date).isBefore(new Date(), 'day')
  const dayLabel = day.date
    ? moment(day.date).locale('es').format('dddd DD [de] MMMM [de] YYYY')
    : undefined
  return (
    day.date && (
      <div
        role={'heading'}
        className={classes.dayCell}
        tabIndex={isToday || day.events.length > 0 ? 0 : -1}
        aria-label={dayLabel}
      >
        <div className={classes.dayHeader} aria-hidden={true}>
          <Typography
            component="p"
            className={classNames(classes.dayName, {
              [classes.dayInPast]: isInThePast,
              [classes.dayToday]: isToday,
            })}
          >
            {day.name}
          </Typography>
        </div>
        <div
          className={classes.dayEvents}
          aria-label={day.events.length > 0 ? undefined : 'No hay eventos'}
        >
          {day.events.map(e => (
            <EventChip
              classes={classes}
              event={e}
              inPast={isInThePast}
              openExternalCourse={openExternalCourse}
              filters={filters}
            />
          ))}
        </div>
      </div>
    )
  )
}

const CalendarDatePicker = ({ classes, updateDate, currentDate }) => {
  const prevMonth = useCallback(() => {
    if (currentDate.month === 1) {
      currentDate.month = 12
      currentDate.year--
    } else {
      currentDate.month--
    }
    updateDate({ ...currentDate })
  }, [updateDate, currentDate])

  const nextMonth = useCallback(() => {
    if (currentDate.month === 12) {
      currentDate.month = 1
      currentDate.year++
    } else {
      currentDate.month++
    }
    updateDate({ ...currentDate })
  }, [updateDate, currentDate])

  const prevMonthName = useMemo(() => {
    const date = { ...currentDate }
    if (date.month === 1) {
      date.month = 12
      date.year--
    } else {
      date.month--
    }
    return getMonthName(date.month) + ' ' + date.year
  }, [currentDate, getMonthName])

  const nextMonthName = useMemo(() => {
    const date = { ...currentDate }
    if (date.month === 12) {
      date.month = 1
      date.year++
    } else {
      date.month++
    }
    return getMonthName(date.month) + ' ' + date.year
  }, [currentDate, getMonthName])

  const currentDateLabel = getMonthName(currentDate.month) + ' ' + currentDate.year
  return (
    <div className={classes.datePicker}>
      <Button className={classes.datePickerButton} onClick={() => prevMonth()} role={'link'}>
        {prevMonthName}
      </Button>
      <h2
        className={classes.datePickerDate}
        aria-live={'polite'}
        aria-atomic={true}
        aria-relevant={'all'}
        id={'currentMonthLabel'}
        aria-label={`Calendario de talleres ${currentDateLabel}`}
      >
        {currentDateLabel}
      </h2>
      <Button className={classes.datePickerButton} onClick={() => nextMonth()} role={'link'}>
        {nextMonthName}
      </Button>
    </div>
  )
}

const Calendar = ({ classes, calendar, openExternalCourse, noEvents, filters }) => {
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        classes={{ root: classes.rootTableContainer }}
        aria-label={noEvents ? 'No hay talleres para el mes seleccionado' : undefined}
      >
        <Table className={classes.table} aria-describedby={'currentMonthLabel'}>
          <TableHead>
            <TableRow>
              <TableCell component="th">Lunes</TableCell>
              <TableCell component="th">Martes</TableCell>
              <TableCell component="th">Miercoles</TableCell>
              <TableCell component="th">Jueves</TableCell>
              <TableCell component="th">Viernes</TableCell>
              <TableCell component="th">Sábado</TableCell>
              <TableCell component="th">Domingo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendar.map((week, index) => (
              <TableRow key={'week-' + index}>
                {week.map(day => (
                  <TableCell>
                    <CalendarDay
                      classes={classes}
                      day={day}
                      openExternalCourse={openExternalCourse}
                      filters={filters}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

const SearchInputComponent = ({ classes, ...props }) => {
  return (
    <TextField
      tabIndex={-1}
      {...props}
      type="search"
      aria-hidden={true}
      placeholder="Buscar"
      fullWidth={true}
      disableUnderline={true}
      classes={{
        root: classes.customSearchFormControlRoot,
      }}
      InputProps={{
        classes: {
          root: classes.customSearchInputRoot,
        },
        endAdornment: <SearchIcon />,
      }}
      inputProps={{
        tabIndex: -1,
        'aria-hidden': true,
      }}
    />
  )
}

const Filters = ({ filters = [], classes, updateFilters }) => {
  return (
    <div className={classes.commissionsFilter}>
      <div className={classes.commissionsFilterInputWrapper}>
        <AutocompleteSimpleObjectInput
          model={EditInputObjectModel.PublicExternalCourses}
          name={'filteredCommissions'}
          creatable={false}
          value={filters}
          onChange={(name, value) => updateFilters(value)}
          multiple={true}
          autoFocus={false}
          displayAttribute={'name'}
          reverse={true}
          renderInputComponent={inputProps => (
            <SearchInputComponent {...inputProps} classes={classes} />
          )}
        />
      </div>
    </div>
  )
}

const ContentNextEvents = ({
  classes,
  events,
  updateDate,
  currentDate,
  openExternalCourse,
  filters,
  updateFilters,
}) => {
  const calendar = events && currentDate ? generateCalendar(events, currentDate) : null
  return (
    <main id="main" className={classes.root} tabIndex={-1}>
      <div className={classes.content}>
        <MuiThemeProvider theme={calendar_table_theme}>
          <Filters classes={classes} filters={filters} updateFilters={updateFilters} />
          <CalendarDatePicker classes={classes} updateDate={updateDate} currentDate={currentDate} />
          {!calendar && <Loading />}
          {calendar && (
            <Calendar
              classes={classes}
              openExternalCourse={openExternalCourse}
              calendar={calendar}
              noEvents={events && events.length === 0}
              filters={filters}
            />
          )}
        </MuiThemeProvider>
      </div>
    </main>
  )
}
export default withStyles(styles)(ContentNextEvents)
