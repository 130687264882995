import { PLAYLIST_READ_END, PLAYLIST_READ_FAILED, PLAYLIST_READ_START } from '../../actions'

const initialState = {
  error: null,
  loading: false,
  playlist: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PLAYLIST_READ_START:
      return {
        playlist: null,
        error: null,
        loading: true,
      }

    case PLAYLIST_READ_END: {
      const { playlist = null } = action.payload
      return {
        playlist,
        error: null,
        loading: false,
      }
    }

    case PLAYLIST_READ_FAILED: {
      const { message = 'ERROR' } = action.payload
      return {
        playlist: null,
        error: message,
        loading: false,
      }
    }

    default:
      return state;
  }
  return { ...state }
}
