import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

const EventListenerWrapper = ({
  children,
  onFocus,
  onEnter,
  tabIndex = 0,
  TagType = 'div',
  ...others
}) => {
  const selfRef = useRef(null)

  const onKeyPressedEvent = useCallback(
    event => {
      if (event.keyCode === 13) {
        onEnter(event)
      }
    },
    [onEnter],
  )

  useEffect(() => {
    const currentRef = selfRef.current
    currentRef.addEventListener('keydown', onKeyPressedEvent)
    return () => currentRef.removeEventListener('keydown', onKeyPressedEvent)
  }, [selfRef, onKeyPressedEvent])

  return (
    <TagType {...others} tabIndex={tabIndex} ref={selfRef}>
      {[children]}
    </TagType>
  )
}

EventListenerWrapper.propTypes = {
  onFocus: PropTypes.func,
  onEnter: PropTypes.func.isRequired,
}

export default EventListenerWrapper
