import {
  QUESTIONS_TYPES_READ_END,
  QUESTIONS_TYPES_READ_FAILED,
  QUESTIONS_TYPES_READ_START,
} from '.'
import api from '../api/api'

export function loadQuestionsTypes() {
  return async dispatch => {
    dispatch({ type: QUESTIONS_TYPES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).QuizTriviaData.questionsTypes()
      dispatch({ type: QUESTIONS_TYPES_READ_END, payload: { items: response.data } })
    } catch (e) {
      dispatch({ type: QUESTIONS_TYPES_READ_FAILED, payload: { message: e } })
    }
  }
}

export function loadUserQuestionsTypes() {
  return async dispatch => {
    dispatch({ type: QUESTIONS_TYPES_READ_START })
    try {
      const response = await api.withDispatch(dispatch).QuizTriviaData.userQuestionsTypes()
      dispatch({ type: QUESTIONS_TYPES_READ_END, payload: { items: response.data } })
    } catch (e) {
      dispatch({ type: QUESTIONS_TYPES_READ_FAILED, payload: { message: e } })
    }
  }
}
