/* eslint-disable no-case-declarations */
import {
  EXTERNAL_COURSE_READ_END,
  EXTERNAL_COURSE_READ_FAILED,
  EXTERNAL_COURSE_READ_START,
  EXTERNAL_COURSE_UPDATE_END,
} from '../../actions'

const initialState = {
  error: null,
  loading: false,
  course: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case EXTERNAL_COURSE_READ_END:
      const { course } = action.payload
      return {
        loading: false,
        course,
        error: null,
      }
    case EXTERNAL_COURSE_READ_START:
      return {
        course: null,
        error: null,
        loading: true,
      }
    case EXTERNAL_COURSE_READ_FAILED:
      const { message } = action.payload
      return {
        course: null,
        error: message,
        loading: false,
      }
    case EXTERNAL_COURSE_UPDATE_END:
      const { updatedCourse } = action.payload
      return {
        loading: false,
        course: { ...updatedCourse },
        error: null,
      }
    default:
      return { ...state, error: null }
  }
}
