import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import CareerPlansExpanded from './CareerPlansExpanded'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}

function MyCurrentPlans({
  classes,
  loadCarrerPlan,
  onShowCourse,
  currentCareerPlans,
  onDeleteSharedCourse,
}) {
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={10}>
        <CareerPlansExpanded
          isExpanded
          loadData={loadCarrerPlan}
          currentCareerPlans={currentCareerPlans}
          onShowCourse={onShowCourse}
          onDeleteSharedCourse={onDeleteSharedCourse}
        />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(MyCurrentPlans)
