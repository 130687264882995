import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { paginateCourseReviews } from '../../actions/courses';
import { init } from '../../actions/infinite_paginator';
import { addSnackbar } from '../../actions/snackbar';
import { deleteSharedCourse } from '../../actions/courses';
import CourseSharedShowDialog from '../../components/courses/CourseSharedShowDialog';
import { SnackbarType } from "../../config/constants";

class CourseSharedShowDialogContainer extends Component {
  constructor(props) {
    super(props);
    props.init(props.keyName);
  }

  onFetch = () => {
    const { keyName, course, paginateCourseReviews, page } = this.props;
    paginateCourseReviews(course.id, keyName, page, 20);
  };

  removeSharedCourse = async () => {
    try {
      const { onRemoveSharedCourse, onDismiss = () => { }, course } = this.props;
      await onRemoveSharedCourse(course);
      addSnackbar(
        `Recomendación descartada`,
        SnackbarType.Success
      );
      onDismiss();
    } catch (err) {
    }
  };

  render() {
    const {
      open,
      onDismiss = () => { },
      headerColor,
      sharedCourses,
      course,
      hasMore,
      loading,
      items
    } = this.props;

    return (
      <CourseSharedShowDialog
        sharedCourses={sharedCourses}
        course={course}
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        onRemoveSharedCourse={this.removeSharedCourse}
        onFetchMore={this.onFetch}
        hasMore={hasMore}
        loading={loading}
        items={items}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const infiniteState = state.infiniteScrollPaginatorReducer;
  return {
    ...infiniteState[props.keyName],
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ paginateCourseReviews, init, addSnackbar, deleteSharedCourse }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CourseSharedShowDialogContainer)
);
