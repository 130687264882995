import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CareerPlansExpanded from './CareerPlansExpanded';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },

  containerHeaderMyPreviousCareerPlans: {
    backgroundColor: 'grey',
    justifyContent: 'center',
    minHeight: 108.5,
    paddingTop: 16,
    paddingBottom: 24,
    paddingLeft: '5.5%',
    paddingRight: '5.5%',
  },
  titleHeaderMyPreviousCareerPlans: {
    marginBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: 24,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#ffffff',
  },
  chip: {
    height: 26,
    backgroundColor: '#1ed1a8',
    color: '#fff',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
  },
  buttonViewMap: {
    width: 225,
    height: 75,
    padding: 0,
    borderRadius: 5,
    backgroundColor: '#838282',
    borderColor: '#fff',
    borderWidth: 0.5,
    borderStyle: 'solid',
    fontFamily: 'PlaylistScript',
    fontSize: 35,
    fontWeight: 'bold',
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    '&:hover': {
      zIndex: 10,
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
};

const MyPreviousCareerPlans = withStyles(styles)(
  ({ classes, previousCareerPlans, loadPreviousCareerPlan, onShowCourse, onDeleteSharedCourse }) => {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} md={10}>
          {previousCareerPlans &&
            previousCareerPlans.map((itemPreviousCareer) => {
              return (
                <>
                  <CareerPlansExpanded
                    isExpanded={false}
                    key={itemPreviousCareer.id}
                    onShowCourse={onShowCourse}
                    loadData={loadPreviousCareerPlan}
                    currentCareerPlans={itemPreviousCareer}
                    onDeleteSharedCourse={onDeleteSharedCourse}
                  />
                </>
              );
            })}
        </Grid>
      </Grid>
    );
  }
);
export default MyPreviousCareerPlans;
