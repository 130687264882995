import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../../actions/navigator';
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource,
} from '../../../actions/admin';
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  router,
  ADMIN_CATEGORIES,
} from '../../../config/routes';
import AdminResourceIndex from '../AdminResourceIndex';
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType,
  CategoryTypes,
} from '../../../config/constants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ViewList from '@material-ui/icons/ViewList';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { addSnackbar } from '../../../actions/snackbar';
import CategoriesDrawer from '../../../components/admin/categories/CategoriesDrawer';
import CoursesDrawer from '../../../components/admin/categories/CoursesDrawer';
import api from '../../../api/api';

const KEY_NAME = 'admin-categories';

const DEFAULT_CATEGORIES_ORDER = [
  {
    name: 'category.sort',
    order: 'ASC',
  },
];

const TYPE_DRAWERS = {
  categories: 'categories',
  courses: 'courses',
};

class AdminCategoriesContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
    drawer: false,
    drawerActive: TYPE_DRAWERS.categories,
    categorieSelected: [],
    categoryIdSelected: null,
  };

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size, undefined)
  };

  onRenderCustomItemIcon = (item) => {
    return (
      <div
        style={{
          borderStyle: 'solid',
          borderColor: '#554d5633',
          borderRadius: 2,
          borderWidth: 1,
          width: 36,
          height: 36,
          maxWidth: 36,
          maxHeight: 36,
          padding: 0,
          background: item.color,
        }}
      >
        <img
          style={{
            width: 34,
            height: 34,
            padding: 1,
          }}
          src={item.icon}
          alt={''}
        />
      </div>
    );
  };

  onRenderTypes = (item) => {
    let types = item.types.map((t) => {
      if (t == 1) return 'Cursos online';
      if (t == 2) return 'Talleres';
    });
    return types.join('/');
  }

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Nombre', source: 'name' },
      { title: 'Descripción', source: 'description' },
      {
        title: 'Diseño',
        source: 'icon',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderCustomItemIcon,
      },
      { title: 'Visible', source: 'visible', type: AdminItemViewType.Boolean },
      {
        title: 'Destacada',
        source: 'featured',
        type: AdminItemViewType.Boolean,
      },
      {
        title: 'Tipo/s',
        source: 'types',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderTypes,
      },
    ];
  };

  handleTypeDrawer = (type) => {
    this.setState({ drawerActive: type });
  };

  onCreate = () => {
    this.props.goTo(ADMIN_CATEGORIES + ADMIN_NEW);
  };

  onEditItem = (item) => {
    this.props.goTo(
      router.getRoute(ADMIN_CATEGORIES + ADMIN_EDIT, { id: item.id })
    );
  };

  onDeleteItem = (item) => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.name,
    });
  };

  onViewCourses = async (item) => {
    this.handleDrawer(TYPE_DRAWERS.courses);
    try {
      const { data } = await api.Courses.searchCoursesByCategory(item.id);
      this.setState({ categoryIdSelected: item.id });
      this.setState({ categorieSelected: data });
    } catch (error) {
      console.log(error);
    }
  };

  getActions = (item) => {
    return [
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />,
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />,
      },
      {
        title: 'ORDENAR CURSOS',
        action: this.onViewCourses,
        icon: <ViewList />,
      },
    ];
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'category.name',
        placeholder: 'Nombre',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Text,
        name: 'category.description',
        placeholder: 'Descripción',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Boolean,
        name: 'category.visible',
        placeholder: 'Visible',
        format: FiltersFormat.Plain,
      },
      {
        type: EditInputType.Select,
        placeholder: 'Tipo/s',
        format: FiltersFormat.Like,
        name: 'category.types',
        max: 5,
        multiple: true,
        options: CategoryTypes.map((t) => {
          return { id: t.value, description: t.title };
        }),
      },
    ];
  };

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props;
    const item = this.state.deleteItem;
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Categoría eliminada.', SnackbarType.Success);
        refreshResources(KEY_NAME);
      })
      .catch((err) => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error);
      });
  };

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null,
    });
  };

  showDrawer = () => {
    this.setState((prevState) => ({ drawer: !prevState.drawer }));
  };

  handleDrawer = (typeDrawer) => {
    this.setState({ drawerActive: typeDrawer });
    this.showDrawer();
  };

  submitCategoriesOrder = async (categories) => {
    const { updateResource, addSnackbar, type, paginator } = this.props;
    await updateResource(`${type}`, null, { categories });
    addSnackbar(
      'Ordenamiento de categorías actualizado exitosamente.',
      SnackbarType.Success
    );
    this.showDrawer();
    this.onFetch(KEY_NAME, paginator.query, paginator.page, paginator.size);
  };

  submitCoursesOrder = async (courses) => {
    const { addSnackbar } = this.props;
    const { categoryIdSelected } = this.state;
    const data = courses.map(course => ({ id: course.id, type: course.type }));
    await new api.Courses.updateOrderCourses(categoryIdSelected, data);
    this.setState({ categorieSelected: [] });
    addSnackbar(
      'Ordenamiento de cursos actualizado exitosamente.',
      SnackbarType.Success
    );
    this.showDrawer();
  };

  render() {
    const {
      confirmDeleteOpen,
      itemToDelete,
      drawer,
      drawerActive,
      categorieSelected,
    } = this.state;

    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'CATEGORÍAS'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        sort={() => this.handleDrawer(TYPE_DRAWERS.categories)}
        getOrderingConfig={() => DEFAULT_CATEGORIES_ORDER}
      >
        <CoursesDrawer
          drawer={drawer && drawerActive === TYPE_DRAWERS.courses}
          onSubmit={this.submitCoursesOrder}
          showDrawer={this.showDrawer}
          courses={categorieSelected}
        />
        <CategoriesDrawer
          drawer={drawer && drawerActive === TYPE_DRAWERS.categories}
          categories={this.props.paginator.items}
          onSubmit={this.submitCategoriesOrder}
          showDrawer={this.showDrawer}
        />
        <ConfirmDialog
          title={'Eliminar Categoría'}
          open={confirmDeleteOpen}
          description={`¿Estás seguro de eliminar la categoría ${itemToDelete}?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    );
  }
}

AdminCategoriesContainer.defaultProps = {
  type: 'categories',
};

const mapStateToProps = (state, ownProps) => {
  const states = state.paginatorReducer;
  return {
    paginator: { ...states[KEY_NAME] },
    ...state.menuDrawerReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResources,
      refreshResources,
      deleteResource,
      goTo,
      addSnackbar,
      updateResource,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCategoriesContainer);
