import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
  TextField,
  Grid,
  FormControl,
  FormLabel,
  Button,
  FormControlLabel,
  RadioGroup,
  InputLabel,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import SelectInput from '../inputs/SelectInput';
import {LotCategory} from '../../../config/constants';

const styles = (theme) => ({
  root: {
    width: '100%',
    margin: '20px 0',
  },
  textField: {
    width: '100%',
  },
  selects: {
    marginTop: '15px',
  },
  inputFile: {
    marginTop: '30px',
    display: 'none',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  uploadIcon: {
    marginLeft: 10,
  },
  iconSmall: {
    fontSize: 20,
  },
  savedIcon: {
    marginLeft: 10,
  },
  wrapperButtonPdf: {
    marginTop: 10,
  },
  buttonViewPdfSelected: {
    marginRight: 10,
  },
  wrapperIconValuePdf: {
    position: 'relative',
  },
  buttonDeletePdf: {
    position: 'absolute',
    right: 0,
    bottom: 12,
  },
});

const StepOne = ({
                   classes,
                   handleBack,
                   handleNext,
                   lotProps,
                   handleChangeLotName,
                   handleChangeLotTypes,
                   handleChangeLotCategory
                 }) => {

  const disableNextStepButton = () => {
    if (lotProps.name == '' || !lotProps.type || !lotProps.category) return true;
    return (lotProps.id === undefined);
  };
  return (
    <>
      <Grid container justify="center" className={classes.root} spacing={4}>
        <Grid item xs={12}>
          <InputLabel component="category">Nombre</InputLabel>
            <TextField
              id="name"
              type="text"
              placeholder={'Ingresar nombre del grupo'}
              fullWidth
              value={lotProps.name}
              onChange={(event) => handleChangeLotName(event.target.value)}
              className={classes.textField}
              margin="normal"
              variant="standard"
            />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>Tipo de asignación</FormLabel>
            <RadioGroup
              name="lot-type"
            >
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    color="default"
                    checked={parseInt(lotProps.type) === 1}
                    onChange={(event) => handleChangeLotTypes(event.target.value)}
                  />}
                label="Puestos de trabajo / Centro de costos"/>
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    color="default"
                    checked={parseInt(lotProps.type) === 2}
                    onChange={(event) => handleChangeLotTypes(event.target.value)}
                  />}
                label="Usuarios"/>
              <FormControlLabel
                value="3"
                control={
                  <Radio
                    color="default"
                    checked={parseInt(lotProps.type) === 3}
                    onChange={(event) => handleChangeLotTypes(event.target.value)}
                  />}
                label="Empresa"/>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <InputLabel component="category">Sección</InputLabel>
          <SelectInput
            name={'category'}
            value={'' + lotProps.category}
            placeholder={'Sección'}
            onChange={(name, value) => {
              handleChangeLotCategory(value);
            }}
            options={Object.keys(LotCategory).map(lc => {
              return {id: lc, description: LotCategory[lc]};
            })}
          />
        </Grid>
      </Grid>
      <Grid className={classes.wrapperBoton}>
        <Button onClick={handleBack} className={classes.button}>
          Atrás
        </Button>
        <Button
          disabled={disableNextStepButton()}
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          Continuar
        </Button>
      </Grid>
    </>
  );
};

StepOne.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StepOne);
