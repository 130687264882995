/* eslint-disable react/prop-types */

const injectBot = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.id = 'botScript'
  script.src = 'https://go.botmaker.com/rest/webchat/p/RALU4VYXSK/init.js'
  document.body.appendChild(script)
}

const handleBot = () => {
  const botDisplayed = document.querySelector('[name=Botmaker]')
  const script = document.querySelector('#botScript')

  const isBasePath = window.location.pathname === '/' || window.location.pathname === ''

  if (isBasePath) {
    if (!botDisplayed) {
      injectBot()
    }
  } else {
    if (botDisplayed) {
      botDisplayed.parentElement.remove()
    }
    if (script) {
      script.remove()
    }
  }

}

export default handleBot
