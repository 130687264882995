import React, { Component } from 'react';
import AutocompleteSearch from '../../components/ui/AutocompleteSearch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeQuery, clear, init } from '../../actions/autocomplete';

const NEW_ITEM_ID = 'newItem';
class AutocompleteSearchContainer extends Component {
  state = {
    currentQuery: '',
    typing: false,
    typingTimeout: 0
  };

  componentDidMount() {
    const { keyName } = this.props;
    this.props.init(keyName);
  }

  onChange = (query, customFilter = '') => {
    const { keyName } = this.props;
    this.setState(
      {
        currentQuery: query,
      },
      () => this.props.changeQuery(keyName, query, customFilter)
    );
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { keyName, onFetch, customFilter } = this.props;
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      typing: false,
      typingTimeout: setTimeout(function () {
        onFetch(keyName, value, customFilter);
      }, 850)
    });
  };

  handleSuggestionsClearRequested = () => {
    const { keyName } = this.props;
    this.props.clear(keyName);
  };

  handleSuggestionHighlighted = ({ suggestion, ...all }) => {
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    const { keyName, onCreate, changeQuery, customFilter, displayAttribute } = this.props;
    if (onCreate && suggestion.id === NEW_ITEM_ID) {
      onCreate(keyName, suggestion.query);
      this.props.handleSuggestionSelected({
        id: `${suggestion.id}_${suggestion.query}`,
        [displayAttribute || "name"]: suggestion.query,
      });
    } else {
      this.props.handleSuggestionSelected(suggestion);
    }
    this.setState({ currentQuery: '' }, () => changeQuery(keyName, '', customFilter));
  };

  getSuggestedItems = () => {
    const {
      items,
      value,
      onCreate,
      query,
      placeholder,
      displayAttribute,
    } = this.props;
    const { currentQuery } = this.state;

    let suggestedItems = items || [];
    if (value && Array.isArray(value) && value.length && items) {
      suggestedItems = items.filter(
        (item) =>
          !value.some(
            (suggestedItem) =>
              suggestedItem.id === item.id ||
              (query && suggestedItem[displayAttribute || 'name'].toLowerCase() === query.toLowerCase())
          )
      );
    }

    if (onCreate) {
      const suggestedNames = suggestedItems.map(
        (suggestedItem) => suggestedItem.name
      );
      const queryCondition = query && query.length > 2;
      const notInSuggestedCondition = !suggestedNames.some(
        (name) => name.toLowerCase() === query.toLowerCase()
      );
      const notInValueCondition =
        !value ||
        (value && value.length === 0) ||
        (value && query &&
          value.length &&
          !value.some(
            (item) =>
              item[displayAttribute || 'name'].toLowerCase() === query.toLowerCase()
          ));
	
      if (queryCondition && notInSuggestedCondition && notInValueCondition) {
        suggestedItems.push({
          id: NEW_ITEM_ID,
          name: `Crear ${placeholder} "${currentQuery}"...`,
          query: currentQuery,
        });
      }
    }

    return suggestedItems;
  };

  render() {
    const {
      keyName,
      getSuggestionValue,
      renderSuggestion,
      query,
      placeholder,
      autoFocus,
      customFilter,
      renderInputComponent
    } = this.props;

    return (
      <AutocompleteSearch
        keyName={keyName}
        placeholder={placeholder}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={this.handleSuggestionSelected}
        onSuggestionHighlighted={this.handleSuggestionHighlighted}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onChange={this.onChange}
        suggestions={this.getSuggestedItems()}
        query={query || ''}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        autoFocus={autoFocus}
        customFilter={customFilter}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.searchRequestReducer;
  return {
    ...states[ownProps.keyName],
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeQuery, clear, init }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutocompleteSearchContainer);
