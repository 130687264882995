import {
  EXTERNAL_COURSE_COURSES_START,
  EXTERNAL_COURSE_COURSES_END,
  EXTERNAL_COURSE_COURSES_FAILED,
  EXTERNAL_COURSE_ALL_START,
  EXTERNAL_COURSE_ALL_END,
  EXTERNAL_COURSE_ALL_FAILED
} from '.';
import api from '../api/api';

export function loadCourses() {
  return async (dispatch) => {
    try {
      dispatch({ type: EXTERNAL_COURSE_COURSES_START });
      const { data } = await api.withDispatch(dispatch).AdminExternalCourse.getAssociateCourse();
      dispatch({
        type: EXTERNAL_COURSE_COURSES_END,
        courses: data,
      });
    } catch (error) {
      dispatch({
        type: EXTERNAL_COURSE_COURSES_FAILED,
        error,
      });
    }
  };
}

export function loadExternalCourses() {
  return async (dispatch) => {
    try {
      dispatch({ type: EXTERNAL_COURSE_ALL_START });
      const { data } = await api.withDispatch(dispatch).AdminExternalCourse.getAll();
      dispatch({
        type: EXTERNAL_COURSE_ALL_END,
        externalCourses: data,
      });
    } catch (error) {
      dispatch({
        type: EXTERNAL_COURSE_ALL_FAILED,
        error,
      });
    }
  };
}
