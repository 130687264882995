/* eslint-disable no-case-declarations */
import {
  LAZY_SELECT_INPUT_FETCH_END,
  LAZY_SELECT_INPUT_FETCH_FAIL,
  LAZY_SELECT_INPUT_FETCH_START,
  LAZY_SELECT_INPUT_INIT,
} from '../actions';

const initialState = {
  key: 'default',
  error: null,
  loading: false,
  options: [],
  fetch: true,
};

const states = {};

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key];
  }
  states[key] = { ...initialState, key };
  return states[key];
}

export default (state = initialState, action) => {
  const key = action.key || state.key || 'default';
  const currentState = getStateFor(key);
  switch (action.type) {
    case LAZY_SELECT_INPUT_INIT: {
      states[key] = { ...currentState };
      return states;
    }
    case LAZY_SELECT_INPUT_FETCH_FAIL:
      const { message } = action.payload;
      states[key] = {
        ...currentState,
        options: [],
        error: message,
        loading: false,
        fetch: false,
      };
      return states;
    case LAZY_SELECT_INPUT_FETCH_START: {
      states[key] = {
        ...currentState,
        options: [],
        error: null,
        loading: true,
        fetch: false,
      };
      return states;
    }
    case LAZY_SELECT_INPUT_FETCH_END: {
      const { options } = action.payload;
      states[key] = {
        ...currentState,
        options,
        error: null,
        loading: false,
        fetch: false,
      };
      return states;
    }
    default:
      states[key] = { ...currentState };
      return states;
  }
};
